import React from 'react';
import { useStore } from '../../../../../../stores/root';

import { faCheck } from '@fortawesome/pro-light-svg-icons';



import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import { EmployeeBankAccountService } from '../../../../../../services/users/bankAccount';
import { IEmployedBankAccountResourceShort } from '../../../../../../props/users/user';

interface IDeleteEmloyeeBankAccountFormProps {
  bankAccount: IEmployedBankAccountResourceShort;
  onDismissed(refresh?: boolean): void;
  employeeId: string
}

const DeleteEmloyeeBankAccountForm: React.FC<IDeleteEmloyeeBankAccountFormProps> = (props: IDeleteEmloyeeBankAccountFormProps) => {
  const { banner } = useStore();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      await EmployeeBankAccountService.delete(props.bankAccount.employeeId, props.bankAccount.id);

      banner.add({
        key: 'delete_employee_success',
        variant: 'success',
        icon: faCheck,
        text: `Bank Account "${props.bankAccount.bankName}" deleted successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  return <Panel headerText={"Delete Bank Account"}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Delete"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting Bank Account ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      <Text>Are you sure you want to delete Bank Account with details below?</Text>
      <Stack.Item>
        <Label>Name</Label>
        <Text>{props.bankAccount.bankName}</Text>
      </Stack.Item>
    </Stack>
  </Panel>
};

export default DeleteEmloyeeBankAccountForm;
