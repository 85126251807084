import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import CompanyService from '../../../../services/users/company';
import PermissionsService from '../../../../services/permissions';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';

// components
import { ActionButton, Link, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import CompanyForm from '../../../users/companies/general/form';
import { IUserResourceShortProps } from '../../../../props/users/user';
import CustomerForm from '../../../users/customers/general/form';
import UserService from '../../../../services/users/user';

type SelectCustomerFieldProps = {
    companyId?: string;
    selected?: IUserResourceShortProps;
    options?: IUserResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IUserResourceShortProps): void;
}

const SelectCustomerField: React.FC<SelectCustomerFieldProps> = (props: SelectCustomerFieldProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IUserResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    const _onSearchUser = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));
            } else {
                if (!props.companyId) {
                    const qs: string[] = props.qs || [];
                    qs.push(`rolesGroup=customer`);
                    if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                    const options = (await UserService.retrieve(qs.join("&"))).data.map((item) => ({
                        key: item.id,
                        name: item.name,
                        metadata: item
                    }));

                    return options;
                } else {
                    const options = (await CompanyService.getUsers(props.companyId)).map((item) => ({
                        key: item.id,
                        name: item.name,
                        metadata: item
                    }));

                    return options;
                }
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Customer"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onResolveSuggestions={_onSearchUser}
                onEmptyResolveSuggestions={() => _onSearchUser('')}
            />
            {props.allowCreate && PermissionsService.hasPermission(['customers.create'], user.permissions) ? <Text size={'small'}>Cannot find customer? <Link onClick={() => {setActiveSurface('create')}}>Click here</Link> to create ship</Text> : null}
        </> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack>
                <Label size={'small'}>{selected.name}</Label>
                <Text size={'small'}>{selected.email}</Text>
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
        {activeSurface === 'create' ? <CustomerForm onDismissed={(refresh, customer) => {
            setActiveSurface(undefined);
            props.onChange(customer);
        }} /> : null}
    </Stack>;
};

export default SelectCustomerField;
