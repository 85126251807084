import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import SubsidiaryDetails from '../../../components/settings/subsidiaries/general/details';

type SubsidiaryDetailsPageProps = {
}

const SubsidiaryDetailsPage: React.FC<SubsidiaryDetailsPageProps> = (props: SubsidiaryDetailsPageProps) => {
    const { topbar } = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Subsidiaries", [
            { key: "home", icon: faHome, href: "/" },
            { key: "subsidiaries", text: 'Subsidiaries' }
        ]);
    }, []);

    return <Stack>
        <SubsidiaryDetails subsidiaryId={params.subsidiaryId} />
    </Stack>;
};

export default SubsidiaryDetailsPage;
