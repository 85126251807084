import { Stack } from '@fluentui/react';
import React, { } from 'react';

// assets

import styles from './styles.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './../../typography/text';
import { NavLink } from 'react-router-dom';

export type TTagVariant = 'default' | 'active' | 'success' | 'light' | 'error' | 'warning' | 'transparent' | 'primary';

type ITagProps = React.PropsWithChildren<{
    key?: string;
    variant?: TTagVariant;
    text?: string;
    icon?: IconProp;
    href?: string;
    goIcon?: boolean;
    size?: 'small' | 'medium';
}>

const Tag: React.FC<ITagProps> = (props: ITagProps) => {
    let textSize: any = 'small';
    let iconSize: number = 12;

    if (props.size === 'small') {
        textSize = 'xsmall';
        iconSize = 10;
    }


    const component = <Stack horizontal tokens={{childrenGap: 5}} verticalAlign='center' className={`${styles.container} ${styles[props.size || 'medium']} ${styles[props.variant || 'default']}`}>
        {props.icon ? <FontAwesomeIcon icon={props.icon} fontSize={iconSize} style={{marginTop: -2}} /> : null}
        {props.text && !props.children ? <Text size={textSize}>{props.text}</Text> : null}
        {props.children ? props.children : null}
    </Stack>;
    
    return <>
        {props.href ? <NavLink className={styles.link} to={props.href}>{component}</NavLink> : null}
        {!props.href ? component : null}
    </>
};

export default Tag;
