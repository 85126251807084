import React from 'react';

// import styles
import parentStyles from './../style.module.scss';

// import router
import { RouteComponentProps } from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../../props/user';

// import services
import UserService from '../../services/users/user';

// import pages

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import LoadingComponent from '../../components/feedbacks/loading/index';
import { IRenderMessageBarProps } from '../../props/general';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import GeneralService from '../../services/general';
import SimpleMessageBarComponent from '../../components/feedbacks/simpleMessageBar';
import { ActionButton } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

interface IVerifyEmailProps {
    user: IUserResourceShortProps;
    route: RouteComponentProps;
}

interface IVerifyEmailState {
    sending?: boolean;
    verifying?: boolean;
    redirecting?: boolean;
    error?: boolean;
    messageBar?: IRenderMessageBarProps;
}

export default class VerifyEmail extends React.Component<IVerifyEmailProps, IVerifyEmailState> {
    constructor(props: IVerifyEmailProps) {
        super(props);
        this.state = {}
    }

    public componentDidMount = async () => {
        const params: any = this.props.route;
        const url = params.location.search.substring(1, params.location.search.length);
        if (url) {
            try {
                this.setState({ verifying: true })
                await UserService.verify(url);
                this.setState({
                    verifying: false,
                    redirecting: true
                });

                setTimeout(() => {
                    window.location.href = "/";
                }, 5000);
            } catch (e) {
                this.setState({
                    error: true,
                    verifying: false
                });
            }
        } else {
            this.setState({
                error: true
            })
        }
    }

    public render() {
        const { user } = this.props;

        return (
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack className={parentStyles.header}>
                    <Text className={parentStyles.text} as={"h2"} variant={"medium"}>Verifikasi Email</Text>
                </Stack>
                {this.state.messageBar ? <SimpleMessageBarComponent properties={this.state.messageBar} onDismiss={() => { this.setState({ messageBar: undefined }) }} /> : null}
                {this.state.verifying ? <LoadingComponent label={"Memverifikasi email ..."} labelPosition={"right"} /> : null}
                {
                    !this.state.verifying && this.state.redirecting && !this.state.error ? (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Terima Kasih!</Text>
                            <Text style={{ textAlign: "center" }}>Email berhasil diverifikasi. Kami akan meneruskan anda ke portal pelanggan Jejak Logistik dalam beberapa saat.</Text>
                            <LoadingComponent label={""} />
                        </Stack>
                    ) : null
                }
                {
                    this.state.error && !this.state.verifying ? (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Verifikasi Gagal!</Text>
                            <Text style={{ textAlign: "center" }}>Maaf, alamat yang digunakan untuk verifikasi salah. Silahkan coba lagi atau kontak tim terkait apabila masalah berlanjut.</Text>
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }
}