import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props

// components
import { ActionButton, CommandBar, ICommandBarItemProps, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import Label from '../../../../../typography/label';
import Tag from '../../../../../uiframeworks/tag';
import Text from '../../../../../typography/text';
import moment from 'moment';
import OrderService from '../../../../../../services/orders/order';
import GeneralService from '../../../../../../services/general';
import ErrorService from '../../../../../../services/general/error';
import { observer } from 'mobx-react';
import PermissionsService from '../../../../../../services/permissions';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import IncomeInvoiceDocumentsList from '../../documents/general/list';
import IncomeInvoicePaymentList from '../../payments/general/list';
import IncomeInvoicePaymentForm from '../../payments/general/form';

type IncomeInvoiceDetailsProps = {
    invoiceId: string;
    onDismissed(refresh?: boolean): void;
}

const IncomeInvoiceDetails: React.FC<IncomeInvoiceDetailsProps> = observer((props: IncomeInvoiceDetailsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [invoice, setInvoice] = React.useState<IIncomeInvoiceResourceShortProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [downloading, setDownloading] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(false);
        const _invoice = await IncomeInvoiceService.get(props.invoiceId);
        setInvoice(_invoice);
        setLoaded(true);
    }

    const getCommandBarItems = (_invoice: IIncomeInvoiceResourceShortProps) => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'download',
            text: 'Download',
            onRender: () => {
                return <PrimaryButton text={"Download"} iconProps={{ iconName: "Download" }} onClick={() => {
                    _onDownloadInvoice('docx');
                }} styles={{ root: { marginRight: 20 } }} />
            }
        });

        const hasRecordPermission = PermissionsService.hasPermission(['incomes.invoices.payments.record'], user.permissions);
        if (_invoice.unpaid > 0 && hasRecordPermission) {
            items.push({
                key: 'recordPayment',
                text: 'Record Payment',
                onRender: () => {
                    return <ActionButton text={"Record Payment"} iconProps={{ iconName: "Add" }} onClick={() => setActiveSurface('payment.record')} />
                }
            });
        }

        /*const hasDeletePermission = PermissionsService.hasPermission(['incomes.invoices.cancel'], user.roles);
        if (hasDeletePermission) {
            farItems.push({
                key: 'delete',
                text: 'Delete',
                onRender: () => {
                    return <ActionButton text={"Delete"} iconProps={{ iconName: "Delete" }} onClick={() => setActiveSurface('delete')} />
                }
            });
        }*/

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        if (activeSurface === 'delete' && refresh) {
            props.onDismissed(true);
        }

        setActiveSurface(undefined);

        if (refresh) { init(); }
    }

    const _onDownloadInvoice = async (format: string) => {
        try {
            setDownloading(true);
            const name = IncomeInvoiceService.getInvoiceNumber(props.invoiceId);
            await IncomeInvoiceService.download(props.invoiceId, format, name);

            banner.add({
                key: 'download_invoice_success',
                text: `Invoice "${name}" downloaded successfully`,
                icon: faCheck,
                variant: 'success'
            });
            setDownloading(false);
        } catch (e) {
            setDownloading(false);
            banner.add({
                key: 'download_invoice_error',
                text: 'Failed to download invoice. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Panel headerText={'Invoice Details'}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(false)}>
        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Retrieving invoice details ..."} /></Stack> : null}
            {
                loaded && invoice ? <>
                    <CommandBar {...getCommandBarItems(invoice)} styles={{ root: { margin: 0, padding: 0 } }} />
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label>Status</Label>
                            <Stack horizontal><Tag text={invoice.unpaid <= 0 ? 'Paid' : 'Unpaid'} variant={invoice.unpaid <= 0 ? 'success' : 'error'} /></Stack>
                        </Stack>
                        {invoice.unpaid > 0 ? <Stack>
                            <Label>Due Date</Label>
                            <Text>{moment(invoice.dueDate).format('DD/MM/YYYY')}</Text>
                        </Stack> : null}
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label>Invoice Number</Label>
                            <Text>{IncomeInvoiceService.getInvoiceNumber(invoice.id)}</Text>
                        </Stack>
                        <Stack>
                            <Label>Order Number</Label>
                            <Text>{OrderService.getOrderNumber(invoice.order.orderNumber)}</Text>
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '33.3%' } }}>
                            <Label>Amount</Label>
                            <Text>Rp. {GeneralService.getNumberWithSeparator(invoice.amount)}</Text>
                        </Stack>
                        <Stack styles={{ root: { width: '33.3%' } }}>
                            <Label>Paid</Label>
                            <Text className='color-green'>Rp. {GeneralService.getNumberWithSeparator(invoice.paid)}</Text>
                        </Stack>
                        <Stack styles={{ root: { width: '33.3%' } }}>
                            <Label>Unpaid</Label>
                            <Text className='color-red'>Rp. {GeneralService.getNumberWithSeparator(invoice.unpaid)}</Text>
                        </Stack>
                    </Stack>
                    <Stack className='divider'></Stack>
                    <Pivot className={'linePivot'}>
                        <PivotItem key={'aymentsList'} headerText={"Payments List"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <IncomeInvoicePaymentList variant='plain' invoiceId={invoice.id} qs={['invoice_id=' + invoice.id]} hideCommandBar={true} />
                            </Stack>
                        </PivotItem>
                        <PivotItem key={'documentList'} headerText={"Document List"}>
                            <Stack styles={{ root: { padding: 10 } }}>
                                <IncomeInvoiceDocumentsList variant='plain' orderInvoiceId={invoice.id} />
                            </Stack>
                        </PivotItem>
                    </Pivot>
                </> : null
            }
        </Stack>
        {downloading ? <LoadingDialogComponent title='Downloading Invoice' secondaryText="Please wait. Your invoice will be downloaded momentarily." /> : null}
        {activeSurface === 'payment.record' ? <IncomeInvoicePaymentForm invoiceId={props.invoiceId} onDismissed={_onSurfaceDismissed} /> : null}
        {/*activeSurface === 'delete' && invoice ? <DeleteOrderInvoiceForm invoice={invoice} onDismissed={_onSurfaceDismissed} /> : null*/}
    </Panel>
});

export default IncomeInvoiceDetails;
