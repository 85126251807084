import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props
import { ISalesRecapResourceShortProps, IUserResourceShortProps } from '../../../../../../props/users/user';

// components
import { Dropdown, Stack } from '@fluentui/react';
import UserService from '../../../../../../services/users/user';
import moment from 'moment';
import Text from '../../../../../typography/text';
import Label from '../../../../../typography/label';
import LoadingComponent from '../../../../../feedbacks/loading';
import GeneralService from '../../../../../../services/general';
import PermissionsService from '../../../../../../services/permissions';

interface AdministratorMonthlyRecapProps {
    userId: string;
}

interface MonthlyRecapProps {
    newCustomers?: number;
    newCompanies?: number;
    totalProfitByStartDate?: number;
    totalProfitByInvoiceDate?: number;
    totalCommissions?: number;
}

const AdministratorMonthlyRecap: React.FC<AdministratorMonthlyRecapProps> = (props: AdministratorMonthlyRecapProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [recap, setRecap] = React.useState<MonthlyRecapProps | undefined>();
    const [administrator, setAdministrator] = React.useState<IUserResourceShortProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selectedDate, setSelectedDate] = React.useState<{ startDate: string, endDate: string }>({
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString()
    });

    useEffect(() => {
        init();
    }, [selectedDate]);

    const init = async () => {
        try {
            setLoaded(false);

            const qs = [];
            qs.push(`start_date=${selectedDate.startDate}`);
            qs.push(`end_date=${selectedDate.endDate}`);
            let salesRecap: ISalesRecapResourceShortProps | undefined = undefined;

            const isCurrentUser = user.data?.id === props.userId;

            if (isCurrentUser || PermissionsService.hasPermission(['superuser', 'admin', 'finance'], user.roles)) {
                salesRecap = await UserService.salesRecap(props.userId, qs.join('&'));
            }

            setRecap({
                newCustomers: salesRecap?.newCustomers,
                newCompanies: salesRecap?.newCompanies,
                totalProfitByInvoiceDate: salesRecap?.profitsByInvoiceDate,
                totalProfitByStartDate: salesRecap?.profitsByOrderDate,
                totalCommissions: salesRecap?.totalCommissions
            });
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_sales_recap_error',
                text: 'Failed to get sales recap. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded && recap ? <>
            <Stack>
                <Dropdown selectedKey={moment(selectedDate.startDate).format("MM")} options={[
                    { key: moment().format("MM"), text: 'This month' },
                    { key: moment().add(-1, 'month').format("MM"), text: 'Last month' }
                ]} onChange={(ev, opt) => {
                    const month = opt?.key as string;
                    const _selectedDate = {
                        startDate: moment(month, "MM").startOf('month').toISOString(),
                        endDate: moment(month, "MM").endOf('month').toISOString()
                    }

                    setSelectedDate({ ..._selectedDate });
                }} />
            </Stack>
            {recap.newCustomers !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Text size={'small'}>New Customers</Text>
                <Label>{recap.newCustomers}</Label>
            </Stack> : null}
            {recap.newCompanies !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Text size={'small'}>New Companies</Text>
                <Label>{recap.newCompanies}</Label>
            </Stack> : null}
            {recap.totalProfitByStartDate !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Stack tokens={{childrenGap: 2}}>
                    <Text size={'small'}>Total Profit</Text>
                    <Text size={'xsmall'}>(by actual start date)</Text>
                </Stack>
                <Label>Rp. {GeneralService.getNumberWithSeparator(recap.totalProfitByStartDate)}</Label>
            </Stack> : null}
            {recap.totalProfitByInvoiceDate !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Stack tokens={{childrenGap: 2}}>
                    <Text size={'small'}>Total Profit</Text>
                    <Text size={'xsmall'}>(by final invoice date)</Text>
                </Stack>
                <Label>Rp. {GeneralService.getNumberWithSeparator(recap.totalProfitByInvoiceDate)}</Label>
            </Stack> : null}
            {recap.totalCommissions !== undefined ? <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 3 }}>
                <Stack tokens={{childrenGap: 2}}>
                    <Text size={'small'}>Commissions</Text>
                </Stack>
                <Label>Rp. {GeneralService.getNumberWithSeparator(recap.totalCommissions)}</Label>
            </Stack> : null}
        </> : null}
    </Stack>;
};

export default AdministratorMonthlyRecap;
