import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services

// props

// components
import { Dropdown, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';
import { IOutcomeCategoryResourceShortProps } from '../../../../props/finance/outcomes/categories';
import OutcomeCategoryService from '../../../../services/finance/outcomes/categories';

type SelectOutcomeCategoryProps = {
    selected?: IOutcomeCategoryResourceShortProps;
    selectedKey?: string;
    options?: IOutcomeCategoryResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    errorMessage?: string;
    onChange(selected?: IOutcomeCategoryResourceShortProps): void;
}

const SelectOutcomeCategory: React.FC<SelectOutcomeCategoryProps> = (props: SelectOutcomeCategoryProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IOutcomeCategoryResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IOutcomeCategoryResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        setSelected(options.find((opt) => opt.id === props.selectedKey));
    }, [props.selectedKey]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _options = await OutcomeCategoryService.retrieve();
        let _selected = props.selected;

        if (props.selectedKey) {
            _selected = _options.find((opt) => opt.id === props.selectedKey)
        }

        setOptions(_options);
        setSelected(_selected);
        setLoaded(true);
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Outcome category"}</Label>}
        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'center'}>
            {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
            <Stack grow={1}>
                <Dropdown disabled={props.disabled || !loaded}
                    selectedKey={selected?.id}
                    options={options.map((opt) => {
                        return { key: opt.id, text: opt.name }
                    })}
                    onChange={(evt, opt) => {
                        const selected = options.find((o) => o.id === opt?.key as string);
                        props.onChange(selected);
                    }} />
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectOutcomeCategory;
