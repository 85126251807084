import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, Checkbox, DatePicker, DefaultButton, DetailsList, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { FormDataProps } from '../../props';
import FileService from '../../../../../../../../services/general/file';
import OutcomeService from '../../../../../../../../services/finance/outcomes';
import LoadingComponent from '../../../../../../../feedbacks/loading';
import Text from '../../../../../../../typography/text';
import Label from '../../../../../../../typography/label';
import GeneralService from '../../../../../../../../services/general';
import OutcomeTypes from '../../../../../../../../manifests/outcomeTypes';
import ValidationService from '../../../../../../../../services/validation';
import { PPhPercentageOptions, PPNPercentageOptions } from '../../../../../../../../manifests/taxPercentage';
import moment from 'moment';
import VendorField from '../../../../../../../uiframeworks/forms/vendor';
import ShipField from '../../../../../../../uiframeworks/forms/ship';
import ShipScheduleField from '../../../../../../../uiframeworks/forms/shipSchedule';
import SelectTruck from '../../../../../../../uiframeworks/forms/selectTruck';
import { IVendorResourceShortProps } from '../../../../../../../../props/data/vendors';
import LoadingDialogComponent from '../../../../../../../feedbacks/loadingDialog';
import SelectTrain from '../../../../../../../uiframeworks/forms/selectTrain';
import SelectTrainSchedule from '../../../../../../../uiframeworks/forms/selectTrainSchedule';
import InvoiceOrderSubform from '../order';
import { IOutcomeOrderResourceShortProps } from '../../../../../../../../props/finance/outcomes/orders';
import SelectOrder from '../../../../../../../uiframeworks/forms/selectOrder';
import { IOrderResourceShortProps } from '../../../../../../../../props/orders/order';
import SelectBankAccount from '../../../../../../../uiframeworks/forms/selectBankAccount';
import SelectOutcomeCategory from '../../../../../../../uiframeworks/forms/selectOutcomeCategory';
import SelectInternalBankAccount from '../../../../../../../uiframeworks/forms/bankaccounts/internal';
import SelectSubsidiary from '../../../../../../../uiframeworks/forms/selectSubsidiary';


type InvoiceDetailsSubformProps = {
    disabled?: boolean;
    data?: Partial<FormDataProps>;
    onDismissed(data?: Partial<FormDataProps>): void;
}

const InvoiceDetailsSubform: React.FC<InvoiceDetailsSubformProps> = (props: InvoiceDetailsSubformProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(props.data !== undefined);
    const [data, setData] = React.useState<Partial<FormDataProps>>(props.data || { id: GeneralService.guid() });
    const [error, setError] = React.useState<any>({});
    const [preview, setPreview] = React.useState<string[] | undefined>();
    const [loadingPreview, setLoadingPreview] = React.useState<boolean>(true);
    const [extracting, setExtracting] = React.useState<boolean>(false);

    const [showAddOrder, setShowAddOrder] = React.useState<boolean>(false);
    const [activeOrder, setActiveOrder] = React.useState<Partial<IOutcomeOrderResourceShortProps> | undefined>();

    useEffect(() => {
        if (props.data?.systemFile) {
            _onPreviewFile(props.data.systemFile)
        } else {
            setLoadingPreview(false);
        }
    }, []);

    useEffect(() => {
        setData({ ...props.data });
        if ((props.data?.systemBase64 || []).length > 0) {
            setPreview((props.data?.systemBase64 || []));
            setLoadingPreview(false);
        }
        setLoaded(props.data !== undefined);
    }, [props.data]);

    const _onPreviewFile = async (file: File) => {
        setPreview(undefined);
        setLoadingPreview(true);

        const results = await FileService.getBase64(file);

        setLoadingPreview(false);
        setPreview(results);
    }

    const extract = async (file: File): Promise<{
        invoiceNumber?: string;
        totalAmount?: number;
        subtotalAmount?: number;
        name?: string;
        dueDate?: string;
        createdDate?: string;
        ppnAmount?: number;
        pphAmount?: number;
        includePPn?: boolean;
        includePPh?: boolean;
        ppnPercentage?: number;
        pphPercentage?: number;
        vendor?: IVendorResourceShortProps;
    } | undefined> => {
        try {
            const images = await FileService.getBase64(file);

            const fd = new FormData();
            images.forEach((img) => fd.append("base64[]", img));

            const result = await OutcomeService.extract(fd);
            return result;
        } catch (e) {
            return;
        }
    }

    const _onFileChange = async (_files: FileList | File[]) => {
        if (!window.confirm("Are you sure you want to replace current invoice file?")) {
            return;
        }

        data.systemFile = _files[0];
        setData({ ...data });

        if (data.systemFile) {
            setExtracting(true);
            const result = await extract(data.systemFile);

            const _data: Partial<FormDataProps> = {
                ...data,
                id: data.id || GeneralService.guid(),
                originalAmount: (result?.subtotalAmount || result?.totalAmount || 0) + "",
                invoiceNumber: result?.invoiceNumber || "",
                name: result?.name || "",
                dueDate: result?.dueDate || moment().add(3, 'days').toISOString(),
                invoiceDate: result?.createdDate || moment().toISOString(),
                ppn: (result?.ppnAmount || 0) + "",
                pph: (result?.pphAmount || 0) + "",
                ppnPercentage: result?.ppnPercentage ? (result?.ppnPercentage || "") + "" : "0",
                pphPercentage: result?.pphPercentage ? (result?.pphPercentage || "") + "" : "0",
                includePPh: result?.includePPn || result?.includePPh || false,
                includePPn: result?.includePPn || false,
                vendor: result?.vendor,
                aiGenerated: JSON.stringify(result || {})
            };

            const _price = OutcomeService.calculatePrice(_data);
            data.totalAmount = (_price.totalAmount || 0) + "";
            data.amount = (_price.amount || 0) + "";

            const _orders = manipulateOrderPrice(data);
            data.orders = _orders;

            setData({ ...data });
            setExtracting(false);
            _onPreviewFile(data.systemFile);
        }
    }

    const isSubmitButtonDisabled = () => {
        if ((data.type || "").trim() === "" || (data.name || "").trim() === "" || ((data.amount || "") + "").trim() === "") {
            return true;
        } else if (error.name || error.ppn || error.pph || error.notes || error.invoiceNumber || error.amount) {
            return true;
        }

        return false;
    }

    const manipulateOrderPrice = (details: Partial<FormDataProps>) => {
        if (details.orders && details.orders.length > 1) {

        } else if (details.orders && details.orders.length > 0) {
            (details.orders || []).forEach((o) => {
                o.amount = (details.totalAmount || 0) + "";
            })
        }

        return details.orders;
    }

    return <Panel headerText={data?.name || "Invoice Details"}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'} verticalAlign='center'>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={() => {
                        const hasFile = data.systemBase64 !== undefined || data.systemFile !== undefined;
                        let confirmed = hasFile || false;

                        if (!hasFile) {
                            confirmed = window.confirm("Are you sure to submit without any invoice uploaded?")
                        }

                        if (confirmed) {
                            const _data = data;
                            _data.systemVerified = true;

                            props.onDismissed(_data);
                        }
                    }} />
                    <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
                </Stack>
            </Stack>;
        }}>
        {extracting ? <LoadingDialogComponent title='Extracting Invoice' secondaryText="Please wait while we extract invoice details ..." /> : null}
        {!loaded ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded ? <Stack tokens={{ childrenGap: 40 }} horizontal>
            <Stack grow={1} styles={{ root: { width: '50%' } }} tokens={{ childrenGap: 10 }}>
                <input type={'file'} onChange={(evt) => _onFileChange(evt?.target.files || [])} value={[]} />
                <Stack className={styles.preview}>
                    {loadingPreview ? <LoadingComponent label={'Generating file preview ...'} size={SpinnerSize.medium} /> : null}
                    {preview && !loadingPreview ? preview.map((preview) => {
                        return <img src={preview} className={styles.image} />;
                    }) : null}
                    {!preview && !loadingPreview ? <Text size={'small'} className={'color-white'}>No invoice selected</Text> : null}
                </Stack>
            </Stack>
            <Stack className={styles.properties}>
                <Pivot className={'linePivot'}>
                    <PivotItem key={'details'} headerText='Details'>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 15 } }}>
                            <SelectSubsidiary label={'Subsidiary'}
                                required={true}
                                selected={data.subsidiary}
                                onChange={(selected) => {
                                    data.subsidiary = selected;
                                    data.bankAccount = undefined;
                                    
                                    setData({ ...data });
                                }} />
                            <SelectOutcomeCategory label={"Category"}
                                required={true}
                                selectedKey={data.type}
                                onChange={(selected) => {
                                    data.type = selected?.id;
                                    data.vendor = undefined;
                                    data.ship = undefined;
                                    data.shipSchedule = undefined;
                                    data.train = undefined;
                                    data.trainSchedule = undefined;
                                    data.truck = undefined;

                                    setData({ ...data });
                                }} />
                            <Stack.Item>
                                <TextField label={"Name"}
                                    required={true}
                                    value={data.name}
                                    onChange={(evt, value) => {
                                        data.name = value || "";

                                        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                        error.name = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }}
                                    errorMessage={error.name} />
                            </Stack.Item>
                            <Stack className={'divider'}></Stack>
                            <Stack>
                                <TextField label={"Price (as per invoice)"}
                                    prefix='Rp'
                                    required={true}
                                    value={(data.originalAmount || "") + ""}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            data.originalAmount = (value || "");

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.amount = validation.message;

                                            const _price = OutcomeService.calculatePrice(data);
                                            data.totalAmount = (_price.totalAmount || 0) + "";
                                            data.amount = (_price.amount || 0) + "";

                                            const _orders = manipulateOrderPrice(data);
                                            data.orders = _orders;

                                            if (!error.amount) {
                                                let ordersAmount = 0;
                                                (data.orders || []).forEach((o) => ordersAmount += Number(o.amount));

                                                if (ordersAmount > (_price.totalAmount || 0)) {
                                                    error.amount = "Total amount in related orders is more than the invoice total amount"
                                                }
                                            }

                                            setData({ ...data });
                                            setError({ ...error });
                                            //calculateAllPrice(data);
                                        }
                                    }}
                                    errorMessage={error.amount} />
                            </Stack>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 20 }} horizontal>
                                <Stack.Item>
                                    <Checkbox checked={data.includePPn}
                                        label='Include PPn'
                                        onChange={(ev, checked) => {
                                            data.includePPn = checked;

                                            const _price = OutcomeService.calculatePrice(data);
                                            data.totalAmount = (_price.totalAmount || 0) + "";
                                            data.amount = (_price.amount || 0) + "";

                                            const _orders = manipulateOrderPrice(data);
                                            data.orders = _orders;

                                            setData({ ...data });
                                        }} />
                                </Stack.Item>
                                <Stack.Item>
                                    <Checkbox checked={data.includePPh}
                                        label='Include PPh'
                                        onChange={(ev, checked) => {
                                            data.includePPh = checked;

                                            const _price = OutcomeService.calculatePrice(data);
                                            data.totalAmount = (_price.totalAmount || 0) + "";
                                            data.amount = (_price.amount || 0) + "";

                                            const _orders = manipulateOrderPrice(data);
                                            data.orders = _orders;

                                            setData({ ...data });
                                        }} />
                                </Stack.Item>
                            </Stack>
                            <Stack tokens={{ childrenGap: 2 }}>
                                <Label size={'xsmall'} required>PPn</Label>
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <Stack.Item>
                                        <Dropdown options={PPNPercentageOptions}
                                            selectedKey={data.ppnPercentage}
                                            onChange={(evt, opt) => {
                                                if (opt && opt.key !== 'custom') {
                                                    data.ppn = ((Number(opt.key) / 100 * Number(data.originalAmount)).toFixed());
                                                }
                                                data.ppnPercentage = (opt?.key || "") as string;

                                                const _price = OutcomeService.calculatePrice(data);
                                                data.totalAmount = (_price.totalAmount || 0) + "";
                                                data.amount = (_price.amount || 0) + "";

                                                const _orders = manipulateOrderPrice(data);
                                                data.orders = _orders;

                                                setData({ ...data });
                                            }}
                                            styles={{ root: { width: 100 } }} />
                                    </Stack.Item>
                                    <Stack.Item grow={1}>
                                        <TextField value={(data.ppn || "0") + ""}
                                            disabled={data.ppnPercentage !== "custom"}
                                            onChange={(evt, value) => {
                                                if (!isNaN(Number(value))) {
                                                    data.ppn = (value || "");

                                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                                    error.ppn = validation.message;

                                                    const _price = OutcomeService.calculatePrice(data);
                                                    data.totalAmount = (_price.totalAmount || 0) + "";
                                                    data.amount = (_price.amount || 0) + "";

                                                    const _orders = manipulateOrderPrice(data);
                                                    data.orders = _orders;

                                                    setData({ ...data });
                                                    setError({ ...error });
                                                }
                                            }}
                                            prefix={"Rp"}
                                            errorMessage={error.ppn} />
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                            <Stack tokens={{ childrenGap: 2 }}>
                                <Label size={'xsmall'} required>PPh</Label>
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <Stack.Item>
                                        <Dropdown options={PPhPercentageOptions}
                                            selectedKey={data.pphPercentage}
                                            onChange={(evt, opt) => {
                                                if (opt && opt.key !== 'custom') {
                                                    data.pph = ((Number(opt.key) / 100 * Number(data.originalAmount)).toFixed());
                                                }
                                                data.pphPercentage = (opt?.key || "") as string;

                                                const _price = OutcomeService.calculatePrice(data);
                                                data.totalAmount = (_price.totalAmount || 0) + "";
                                                data.amount = (_price.amount || 0) + "";

                                                const _orders = manipulateOrderPrice(data);
                                                data.orders = _orders;

                                                setData({ ...data });
                                            }}
                                            styles={{ root: { width: 100 } }} />
                                    </Stack.Item>
                                    <Stack.Item grow={1}>
                                        <TextField value={(data.pph || "0") + ""}
                                            disabled={data.pphPercentage !== "custom"}
                                            onChange={(evt, value) => {
                                                if (!isNaN(Number(value))) {
                                                    data.pph = (value || "");

                                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                                    error.pph = validation.message;

                                                    const _price = OutcomeService.calculatePrice(data);
                                                    data.totalAmount = (_price.totalAmount || 0) + "";
                                                    data.amount = (_price.amount || 0) + "";

                                                    const _orders = manipulateOrderPrice(data);
                                                    data.orders = _orders;

                                                    setData({ ...data });
                                                    setError({ ...error });
                                                }
                                            }}
                                            prefix={"Rp"}
                                            errorMessage={error.pph} />
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                            <Stack className={'divider'}></Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
                                <Stack styles={{ root: { width: 300 } }}>
                                    <Label size={'xsmall'}>Total Price</Label>
                                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.totalAmount || 0)}</Text>
                                </Stack>
                                <Stack styles={{ root: { marginTop: 20 } }}>
                                    <Text size={'xsmall'}>({GeneralService.convertNumberToWords(Number(data.totalAmount || "0"))})</Text>
                                </Stack>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
                                <Stack styles={{ root: { width: 300 } }}>
                                    <Label size={'xsmall'}>Total price paid to vendor</Label>
                                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.amount || 0)}</Text>
                                </Stack>
                                <Stack styles={{ root: { marginTop: 20 } }}>
                                    <Text size={'xsmall'}>({GeneralService.convertNumberToWords(Number(data.amount || "0"))})</Text>
                                </Stack>
                            </Stack>
                            <Stack className={'divider'}></Stack>
                            <Stack.Item>
                                <TextField label={"Notes"}
                                    value={data.notes}
                                    multiline
                                    rows={3}
                                    resizable={false}
                                    autoAdjustHeight
                                    onChange={(evt, value) => {
                                        data.notes = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                        error.notes = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }}
                                    errorMessage={error.notes} />
                            </Stack.Item>
                            <Stack.Item>
                                <SelectInternalBankAccount selected={data.bankAccount}
                                    disabled={!data.subsidiary}
                                    subsidiaryId={data.subsidiary?.id}
                                    label={"Transfer from"}
                                    allowCreate={true}
                                    onChange={(account) => {
                                        data.bankAccount = account;
                                        setData({ ...data });
                                    }} />
                            </Stack.Item>
                            <Stack className='divider'></Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <Stack.Item styles={{ root: { width: '33%' } }}>
                                    <TextField label={"Invoice Number"}
                                        prefix={'#'}
                                        value={data.invoiceNumber}
                                        onChange={(evt, value) => {
                                            data.invoiceNumber = value || "";

                                            const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                            error.invoiceNumber = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }}
                                        errorMessage={error.invoiceNumber} />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { width: '33%' } }}>
                                    <DatePicker label={"Invoice Date"}
                                        isRequired={true}
                                        value={data.invoiceDate !== "" ? moment(data.invoiceDate).toDate() : undefined}
                                        onSelectDate={(date) => {
                                            data.invoiceDate = date ? moment(date).toISOString() : data.invoiceDate;
                                            setData({ ...data });
                                        }}
                                        formatDate={GeneralService.formatDate} />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { width: '33%' } }}>
                                    <DatePicker label={"Due Date"}
                                        isRequired={true}
                                        value={moment(data.dueDate).toDate()}
                                        onSelectDate={(date) => {
                                            data.dueDate = date ? moment(date).toISOString() : data.dueDate;
                                            setData({ ...data });
                                        }}
                                        formatDate={GeneralService.formatDate} />
                                </Stack.Item>
                            </Stack>
                            <Stack className='divider'></Stack>
                            <Label size={'small'}>Vendor Details</Label>
                            <VendorField selected={data.vendor}
                                allowCreate={true}
                                onChange={(vendor) => {
                                    data.vendor = vendor;
                                    data.ship = undefined;
                                    data.shipSchedule = undefined;
                                    data.train = undefined;
                                    data.trainSchedule = undefined;
                                    data.truck = undefined;

                                    setData({ ...data });
                                }} />
                            {data.type === 'shipping' || data.type === 'train' || data.type === 'trucking' ? <>
                                <Stack className={'divider'}></Stack>
                                {data.type === 'shipping' ? <>
                                    <Stack>
                                        <ShipField selected={data.ship}
                                            allowCreate={true}
                                            vendor={data.vendor}
                                            onChange={(ship) => {
                                                data.ship = ship;
                                                if (!data.ship) {
                                                    data.shipSchedule = undefined;
                                                }

                                                setData({ ...data });
                                            }} />
                                    </Stack>
                                    <Stack>
                                        <ShipScheduleField selected={data.shipSchedule}
                                            disabled={!data.ship}
                                            ship={data.ship}
                                            allowCreate={true}
                                            onChange={(schedule) => {
                                                data.shipSchedule = schedule;

                                                setData({ ...data });
                                            }} />
                                    </Stack>
                                </> : null}
                                {data.type === 'train' ? <>
                                    <Stack>
                                        <SelectTrain selected={data.train}
                                            allowCreate={true}
                                            vendor={data.vendor}
                                            onChange={(train) => {
                                                data.train = train;
                                                if (!data.train) {
                                                    data.trainSchedule = undefined;
                                                }

                                                setData({ ...data });
                                            }} />
                                    </Stack>
                                    <Stack>
                                        <SelectTrainSchedule selected={data.trainSchedule}
                                            disabled={!data.train}
                                            train={data.train}
                                            allowCreate={true}
                                            onChange={(schedule) => {
                                                data.trainSchedule = schedule;

                                                setData({ ...data });
                                            }} />
                                    </Stack>
                                </> : null}
                                {data.type === 'trucking' ? <>
                                    <SelectTruck selected={data.truck}
                                        vendor={data.vendor}
                                        allowCreate={true}
                                        onChange={(truck) => {
                                            data.truck = truck;
                                            setData({ ...data });
                                        }} />
                                </> : null}
                            </> : null}
                            <Stack className='divider'></Stack>
                            <Stack tokens={{ childrenGap: 5 }}>
                                <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign={'center'} horizontalAlign={'space-between'}>
                                    <Label size={'small'}>Related orders</Label>
                                    <ActionButton iconProps={{ iconName: "Add" }} text={'Add order'} onClick={() => { setShowAddOrder(true) }} />
                                </Stack>
                                {(data.orders || []).length > 0 ? <DetailsList items={data.orders || []}
                                    selectionMode={SelectionMode.none}
                                    columns={[
                                        {
                                            key: 'order',
                                            name: 'Order',
                                            minWidth: 100,
                                            onRender: (item: IOutcomeOrderResourceShortProps, idx?: number) => {
                                                return <SelectOrder selected={item.order} hideAddress={true} hideLabel disabled={true} onChange={() => { }} />;
                                            }
                                        },
                                        {
                                            key: 'amount',
                                            name: 'Amount',
                                            minWidth: 125,
                                            maxWidth: 125,
                                            onRender: (item: IOutcomeOrderResourceShortProps, idx?: number) => {
                                                return <Stack styles={{ root: { paddingTop: 8 } }}>
                                                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(item.amount)}</Text>
                                                </Stack>;
                                            }
                                        },
                                        {
                                            key: 'action',
                                            name: '',
                                            minWidth: 70,
                                            maxWidth: 75,
                                            onRender: (item: IOutcomeOrderResourceShortProps, idx?: number) => {
                                                return <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => {
                                                    const _data = data || [];
                                                    _data.orders = _data.orders || [];

                                                    const idx = _data.orders.findIndex((o) => o.id === item.id);
                                                    if (idx > -1) {
                                                        _data.orders.splice(idx, 1);
                                                        _data.orders = manipulateOrderPrice(_data);

                                                        if (error.amount) {
                                                            let ordersAmount = 0;
                                                            (data.orders || []).forEach((o) => ordersAmount += Number(o.amount));

                                                            if (ordersAmount > Number(_data.totalAmount || "0")) {
                                                                error.amount = "Total amount in related orders is more than the invoice total amount"
                                                            } else {
                                                                error.amount = undefined;
                                                            }
                                                        }

                                                        setData({ ..._data });
                                                    }
                                                }} />
                                            }
                                        }
                                    ]} /> : null}
                                {(data.orders || []).length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>This invoice currently not related to any orders</Text> : null}
                            </Stack>
                        </Stack>
                    </PivotItem>
                </Pivot>
            </Stack>
        </Stack> : null}
        {showAddOrder ? <InvoiceOrderSubform data={data} order={activeOrder} onDismissed={(order?: IOutcomeOrderResourceShortProps) => {
            if (order) {
                const _data = data;
                _data.orders = _data.orders || [];
                const idx = _data.orders.findIndex((o) => o.id === order.id);

                if (idx < 0) {
                    _data.orders.push(order);
                } else {
                    _data.orders[idx] = order;
                }

                setData({ ..._data });
            }

            setShowAddOrder(false);
        }} /> : null}
    </Panel>
};

export default InvoiceDetailsSubform;
