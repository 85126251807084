import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import SalesComissionList from '../../../components/users/salesComissions/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../../stores/root';

// assets


type SalesComissionListPageProps = {
}

const SalesComissionListPage: React.FC<SalesComissionListPageProps> = (props: SalesComissionListPageProps) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Sales Commissions", [
      { key: "home", icon: faHome, href: "/" },
      { key: "commissions", text: 'Commissions', href: "/sales_comissions" },
    ])
  }, []);

  return (
    <Stack>
      <SalesComissionList />
    </Stack>
  );
};

export default SalesComissionListPage;
