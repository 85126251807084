import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderTruckService from '../../../../../services/orders/trucks';
import GeneralService from '../../../../../services/general';
import GoodsService from '../../../../../services/goods/goods';
import ErrorService from '../../../../../services/general/error';

// props
import { IGoodsResourceShortProps } from '../../../../../props/goods/goods';
import { IOrderTruckResourceShortProps } from '../../../../../props/orders/trucks';
import { IOrderTruckGoodsResourceShortProps } from '../../../../../props/orders/trucks/goods';

// components
import { ActionButton, DefaultButton, IColumn, Link, Panel, PanelType, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack, TagPicker, TextField } from '@fluentui/react';
import Text from './../../../../typography/text';
import Label from '../../../../typography/label';
import LoadingComponent from '../../../../feedbacks/loading';
import GoodsForm from '../../../../goods/general/form';

interface TOrderTruckGoodsFormProps {
    orderId: string;
    truck: IOrderTruckResourceShortProps;
    type: string;
    goods?: IOrderTruckGoodsResourceShortProps[];
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    id: string;
    goods?: IGoodsResourceShortProps;
    quantityAmount: string;
    quantityUnit: string;
    length: string;
    width: string;
    height: string;
    price: string;
    weightAmount: string;
    weightUnit: string;
    useTotalVolume: boolean;
}

const OrderTruckGoodsForm: React.FC<TOrderTruckGoodsFormProps> = (props: TOrderTruckGoodsFormProps) => {
    const { banner } = useStore();
    const { truck } = props;
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [goods, setGoods] = React.useState<Partial<IOrderTruckGoodsResourceShortProps>[]>(props.goods || []);
    const [data, setData] = React.useState<FormDataProps[]>([{
        id: GeneralService.guid(),
        quantityAmount: "",
        quantityUnit: "",
        length: "",
        width: "",
        height: "",
        weightAmount: "",
        weightUnit: "",
        price: "",
        useTotalVolume: false
    }]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const usedColumns = {
        ftl: ['idx', 'goods', 'quantity', 'size', 'volume', 'weight', 'totalWeight', 'action'],
        ltl: ['idx', 'goods', 'quantity', 'size', 'volume', 'weight', 'totalWeight', 'price', 'action']
    };

    useEffect(() => {
        let _data: FormDataProps[] = [];
        _data = (props.goods || []).map((good) => {
            return {
                id: good.id || GeneralService.guid(),
                goods: good.goods,
                quantityAmount: (good.quantityAmount || "") + "",
                quantityUnit: good.goods.quantityUnit || "",
                length: Number((good.length || "")) + "",
                width: Number((good.width || "")) + "",
                height: Number((good.height || "")) + "",
                weightAmount: (good.weightAmount || "") + "",
                weightUnit: good.weightUnit || good.goods?.weightUnit || "",
                useTotalVolume: good.useTotalVolume || false,
                price: ""
            }
        })
        setData([
            ..._data,
            {
                id: GeneralService.guid(),
                quantityAmount: "",
                quantityUnit: "",
                length: "",
                width: "",
                height: "",
                weightAmount: "",
                weightUnit: "",
                price: "",
                useTotalVolume: false
            }
        ]);
    }, []);

    const columns: IColumn[] = [
        {
            key: "idx",
            name: "No.",
            minWidth: 30,
            maxWidth: 30,
            onRender: (item: FormDataProps, idx?: number) => {
                return <Stack styles={{ root: { padding: '16px 0px' } }}>
                    <Text>{(idx || 0) + 1}</Text>
                </Stack>
            }
        },
        {
            key: "goods",
            name: "Goods",
            minWidth: 180,
            onRender: (item: FormDataProps) => {
                return <>
                    {!item.goods ? <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{childrenGap: 3}}>
                        <TagPicker selectedItems={[]}
                            removeButtonAriaLabel='Remove'
                            itemLimit={1}
                            onItemSelected={(tag: any) => {
                                const value = tag ? tag.metadata : undefined;
                                _onGoodsChanged(item.id || "", value);
                                return null;
                            }}
                            onChange={(tags) => {
                                if (tags && tags.length < 1) {
                                    _onGoodsChanged(item.id || "", undefined);
                                }
                            }}
                            onResolveSuggestions={_onSearchGoods}
                            onEmptyResolveSuggestions={() => _onSearchGoods('')}
                            disabled={submitting} />
                        <Text size={'small'}>Cannot find goods? <Link onClick={() => {setActiveSurface('createGoods')}}>Click here</Link> to create goods.</Text>
                    </Stack> : null}
                    {item.goods ? <Stack styles={{ root: { padding: '16px 0px' } }}><Label size={'small'}>{item.goods.name}</Label></Stack> : null}
                </>
            }
        },
        {
            key: "quantity",
            name: "Quantity",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <TextField value={item.quantityAmount}
                        suffix={item.quantityUnit}
                        disabled={submitting || !item.goods}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.quantityAmount = value || "";
                                setData([..._data]);
                            }
                        }} />
                </Stack>
            }
        },
        {
            key: "size",
            name: "Size (length x width x height)",
            minWidth: 300,
            maxWidth: 300,
            onRender: (item: FormDataProps) => {
                return <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { padding: '4px 0px' } }}>
                    <TextField value={item.length}
                        disabled={submitting || !item.goods}
                        suffix={"cm"}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.length = value || "";
                                setData([..._data]);
                            }
                        }} />
                    <TextField value={item.width}
                        disabled={submitting || !item.goods}
                        suffix={"cm"}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.width = value || "";
                                setData([..._data]);
                            }
                        }} />
                    <TextField value={item.height}
                        disabled={submitting || !item.goods}
                        suffix={"cm"}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.height = value || "";
                                setData([..._data]);
                            }
                        }} />
                </Stack>
            }
        },
        {
            key: "volume",
            name: "Volume (m3)",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '16px 0px' } }}>
                    <Text>{GeneralService.getNumberWithSeparator(GeneralService.calculateVolume(item.length, item.width, item.height))} m<sup>3</sup></Text>
                </Stack>
            }
        },
        {
            key: "weight",
            name: "Weight (per qty)",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <TextField value={item.weightAmount}
                        disabled={submitting || !item.goods}
                        suffix={item.weightUnit}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.weightAmount = value || "";
                                setData([..._data]);
                            }
                        }} />
                </Stack>
            }
        },
        {
            key: "totalWeight",
            name: "Total Weight",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: FormDataProps) => {
                const weight = item.weightAmount !== "" ? Number(item.weightAmount) : 0;
                const quantity = item.quantityAmount !== "" ? Number(item.quantityAmount) : 0;
                const totalWeight = weight * quantity;
                
                return <Stack styles={{ root: { padding: '16px 0px' } }}>
                    {item.weightAmount !== "" ? <Text>{GeneralService.getNumberWithSeparator(GeneralService.getWeightInKg(totalWeight, item.weightUnit))} kg</Text> : null}
                    {item.weightAmount === "" ? <Text>0 kg</Text> : null}
                </Stack>
            }
        },
        {
            key: "price",
            name: "Price",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <TextField value={item.weightAmount}
                        disabled={submitting || !item.goods}
                        prefix={"Rp"}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.price = value || "";
                                setData([..._data]);
                            }
                        }} />
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: FormDataProps) => {
                return <Stack.Item styles={{ root: { padding: '16px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        {item.goods ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                            const _data = data.filter((d) => d.id !== item.id);
                            setData([..._data]);
                        }} /> : null}
                    </Stack.Item>
                </Stack.Item>;
            }
        }
    ];

    const _onSearchGoods = async (keyword: string) => {
        try {
            const qs: string[] = [];
            qs.push(`status=current`);
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await GoodsService.retrieve.get(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_goods_error",
                text: "Failed to search related goods. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onGoodsChanged = (id: string, item?: IGoodsResourceShortProps) => {
        const _data = data;
        const _related = _data.find((g) => g.id === id);
        if (_related) {
            _related.goods = item;
            _related.quantityAmount = item?.quantityAmount ? item.quantityAmount + "" : "";
            _related.quantityUnit = item?.quantityUnit || "";
            _related.length = Number((item?.length || "")) + "";
            _related.width = Number((item?.width || "")) + "";
            _related.height = Number((item?.height || "")) + "";
            _related.weightAmount = item?.weightAmount ? item.weightAmount + "" : "";
            _related.weightUnit = item?.weightUnit || "";
        }

        if (item) {
            _data.push({
                id: GeneralService.guid(),
                quantityAmount: "",
                quantityUnit: "",
                length: "",
                width: "",
                height: "",
                weightAmount: "",
                weightUnit: "",
                price: "",
                useTotalVolume: false
            })
        }
        setData([..._data]);
    }

    const _onGoodsCreated = (refresh?: boolean, goods?: IGoodsResourceShortProps) => {
        if (goods) {
            const id = (data.find((d) => !d.goods))?.id;
            if (id) {_onGoodsChanged(id, goods);}
        }

        setActiveSurface(undefined);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            data.filter((d) => d.goods).forEach((d) => {
                const {goods} = d;
                const metadata = {
                    ...d,
                    goodsId: goods?.id,
                    length: d.length !== "" ? d.length : 0,
                    width: d.width !== "" ? d.width : 0,
                    height: d.height !== "" ? d.height : 0,
                    quantityAmount: d.quantityAmount !== "" ? d.quantityAmount : 0,
                    weightAmount: d.weightAmount !== "" ? d.weightAmount : 0,
                    truckId: props.truck.id,
                    price: (d.price || "").trim() !== "" ? d.price : null
                }
                fd.append("goods[]", JSON.stringify(metadata));
            });

            await OrderTruckService.update(props.orderId, props.truck.id, fd);
            banner.add({
                key: 'update_truck_goods_list_success',
                variant: 'success',
                icon: faCheck,
                text: `Order truck goods list updated successfully`
            });
            props.onDismissed(true);
        } catch(e) {
            setSubmitting(false);
            banner.add({
                key: 'update_truck_goods_list_success',
                variant: 'error',
                icon: faXmarkCircle,
                text: 'Failed to retrieve order truck goods list. Error: ' + ErrorService.getMessage(e),
            });
        }
    }

    return <Panel headerText={`Update Truck Goods List`}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(true)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {!submitting ? <>
                    <PrimaryButton text={"Save"} onClick={() => { _onSubmit() }} />
                    <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                </> : null}
                {submitting ? <LoadingComponent label='Saving goods list ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            </Stack>;
        }}>
        <Stack>
            <ShimmeredDetailsList
                setKey="items"
                items={data}
                columns={columns.filter((col) => ((usedColumns as any)[props.type]).indexOf(col.key) > -1)}
                selectionMode={SelectionMode.none}
                enableShimmer={false}
                onShouldVirtualize={() => false}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details" />
        </Stack>
        {activeSurface === 'createGoods' ? <GoodsForm onDismissed={_onGoodsCreated} /> : null}
    </Panel>
};

export default OrderTruckGoodsForm;