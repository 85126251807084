import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import { IPublicNewsResourceShort } from '../../../../../props/data/news';
import PublicNewsService from '../../../../../services/data/publicNews';
import { NavLink } from 'react-router-dom';
import PublicNewsDetails from '../details';
import ErrorService from '../../../../../services/general/error';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

interface INewsesListProps {
}

const NewsesList: React.FC<INewsesListProps> = (props: INewsesListProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [news, setNews] = React.useState<IPublicNewsResourceShort[]>([]);
    const [activeNews, setActiveNews] = React.useState<IPublicNewsResourceShort | undefined>();
    const [pagination, setPagingation] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setNewsword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: 'subject',
            name: 'Subject',
            minWidth: 250,
            onRender: (item: IPublicNewsResourceShort) => {
                return (
                    <Stack>
                        <Link onClick={() => {
                            setActiveSurface('news.details');
                            setActiveNews(item);
                        }}><Text>{item.subject}</Text></Link>
                    </Stack>
                );
            },
        },
        {
            key: 'isFeatured',
            name: 'Is Featured',
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IPublicNewsResourceShort) => {
                return (
                    <Stack>
                        <Text>{item.isFeatured ? "Yes" : "No"}</Text>
                    </Stack>
                );
            },
        }
    ];

    useEffect(() => {
        _onRetrieveNewss();
    }, []);

    useEffect(() => {
        _onRetrieveNewss();
    }, [keyword]);

    const _onRetrieveNewss = async (pageNumber?: number,) => {
        setLoaded(false);

        const qs: string[] = [];
        if (pageNumber) { qs.push(`page=${pageNumber}`); }
        if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }
        try {
            const _news = await PublicNewsService.retrieve(qs.join("&"))
            setNews(_news.data)
            setPagingation(_news.pagination);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "retrieve_public_news_error",
                text: "Failed to retrieve public news. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const _onNewswordChanged = (value?: string) => {
        setLoaded(true);
        setNewsword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onNewswordChanged} />
            }
        });

        farItems.push({
            key: "register",
            text: "Create News",
            iconProps: { iconName: "Add" },
            onRender: () => {
                return <NavLink to={'/settings/news/create'}>
                    <PrimaryButton text={'Create News'} iconProps={{iconName: "Add"}} />
                </NavLink>;
            }
        });

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveNews(undefined);

        if (refresh) { _onRetrieveNewss() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack className={styles.list}>
            {
                !loaded || (loaded && news.length > 0) ? (
                    <ShimmeredDetailsList
                        items={news}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        enableShimmer={!loaded}
                        onShouldVirtualize={() => false}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details" />
                ) : null
            }
            {loaded && news.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                <Text>News(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeNews && activeSurface === 'news.details' ? <PublicNewsDetails newsId={activeNews.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default NewsesList;
