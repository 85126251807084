import { IPaginationResourceShort } from '../../props/general';
import { IEmployedBankAccountResourceShort } from '../../props/users/user';
import Request from '../request';

const EmployeeBankAccountService = {
  retrieve: async (employeeId: string, qs?: string): Promise<{ data: IEmployedBankAccountResourceShort[], pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/employees/${employeeId}/bankAccounts?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  read: async (employeeId: string, id: string): Promise<IEmployedBankAccountResourceShort> => {
    try {
      const results = await Request.get(`/api/employees/${employeeId}/bankAccounts/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (employeeId: string, data: FormData): Promise<IEmployedBankAccountResourceShort> => {
    try {
      const result = await Request.post(`/api/employees/${employeeId}/bankAccounts`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (employeeId: string, id: string, data: FormData): Promise<IEmployedBankAccountResourceShort> => {
    try {
      const result = await Request.post(`/api/employees/${employeeId}/bankAccounts/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (employeeId: string, id: string): Promise<void> => {
    try {
      await Request.delete(`/api/employees/${employeeId}/bankAccounts/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

export { EmployeeBankAccountService };
