import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import FreightCategoriesService from '../../../../../services/orders/freightCategory';

// props
import { IFreightCategoryResourceShortProps } from '../../../../../props/orders/freightCategory';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import Text from '../../../../typography/text';
import FreightCategoryForm from '../form';

interface IFreightCategoriesListProps {
}

const FreightCategoriesList: React.FC<IFreightCategoriesListProps> = (props: IFreightCategoriesListProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [freightCategories, setFreightCategories] = React.useState<IFreightCategoryResourceShortProps[]>([]);
    const [activeFreightCategory, setActiveFreightCategory] = React.useState<IFreightCategoryResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "uniqueKey",
            fieldName: "key",
            name: "Unique Key",
            minWidth: 120,
            maxWidth: 120,
            onRender: (item: IFreightCategoryResourceShortProps) => {
                return <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
                    <Text>{item.key}</Text>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IFreightCategoryResourceShortProps) => {
                return <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: [
                                {key: "update", text: "Update", iconProps: {iconName: "Edit"}, onClick: () => {
                                    setActiveFreightCategory(item);
                                    setActiveSurface('update');
                                }}
                            ]
                        }} />
                    </Stack.Item>
                </Stack>;
            }
        },
        {
            key: "name",
            fieldName: "name",
            name: "Name",
            minWidth: 0,
            onRender: (item: IFreightCategoryResourceShortProps) => {
                return <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
                    <Text>{item.name}</Text>
                </Stack>;
            }
        },
    ];

    useEffect(() => {
        _onRetrieveFreightCategories();
    }, []);

    const _onRetrieveFreightCategories = async (pageNumber?: number, ) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = [];

            const results = await FreightCategoriesService.retrieve();
            setFreightCategories(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_freight_categories_error',
                text: 'Failed to retrieve freight categories. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?:string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        farItems.push({
            key: "register",
            text: "Create Freight Category",
            iconProps: { iconName: "Add" },
            onRender: () => { 
                return <PrimaryButton text={"Create Freight Category"} 
                    iconProps={{ iconName: "Add" }} 
                    onClick={() => {setActiveSurface('create') }} 
                    styles={{ root: { marginLeft: 10 } }} />;
            }
        });

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveFreightCategory(undefined);

        if (refresh) {_onRetrieveFreightCategories()}
    }

    return <Stack className={styles.container} tokens={{childrenGap: 20}}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{root: {
                padding: 0,
                height: 'unset',
                backgroundColor: 'transparent'
            }}}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack className={styles.list}>
            {
                !loaded || (loaded && freightCategories.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={freightCategories}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && freightCategories.length < 1 ? <Stack styles={{root: {padding: 10}}}>
                <Text>Freight category(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <FreightCategoryForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeFreightCategory ? <FreightCategoryForm freightCategory={activeFreightCategory} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default FreightCategoriesList;
