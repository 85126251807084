import React from 'react';
import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../../../../stores/root';
import { SalesCommissionService } from '../../../../../services/users/team';
import { ISalesCommissionResourceShort } from '../../../../../props/users/team';

interface IDeleteSalesComissionFormProps {
    salesComission: ISalesCommissionResourceShort;
    onDismissed(refresh?: boolean): void;
}

const DeleteSalesComissionForm: React.FC<IDeleteSalesComissionFormProps> = (props: IDeleteSalesComissionFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await SalesCommissionService.delete(props.salesComission.id);

            banner.add({
                key: 'delete_salesComission_success',
                variant: 'success',
                icon: faCheck,
                text: `Sales Comission "${props.salesComission.sales?.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Sales Comission"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting Sales Comission ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete Sales Comission with details below?</Text>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.salesComission.sales?.name}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteSalesComissionForm;
