import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import { TSelectedFilterKey } from '.';
import DeliveredGoodsType from '../../../../../manifests/deliveredGoodsType';

type OrdersDashboardChartFilterGoodsTypeProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: string[]): void;
}

const OrdersDashboardChartFilterGoodsType: React.FC<OrdersDashboardChartFilterGoodsTypeProps> = (props: OrdersDashboardChartFilterGoodsTypeProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<string[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniqueGoodsTypes = (): string[] => {
        const goodsTypes: string[] = [];

        props.orders.forEach((order) => {
            const exist = goodsTypes.find((c) => c === order.goodsType);
            if (!exist) {
                goodsTypes.push(order.goodsType);
            }
        });

        return goodsTypes;
    }

    const renderOption = (goodsType: string) => {
        const isChecked = selected.findIndex((s) => s === goodsType) > -1;
        const text = (DeliveredGoodsType.find((gt) => gt.key === goodsType)?.text) || goodsType;
        const totalItems = props.filteredOrders.filter((o) => o.goodsType === goodsType).length;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, goodsType)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{text} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, goodsType: string) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s !== goodsType);
        } else {
            _selected.push(goodsType);
        }

        setSelected([..._selected]);
        props.onChange('goodsType', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Goods type</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search goods ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueGoodsTypes().filter((f) => f.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((goodsType) => {
                return renderOption(goodsType);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterGoodsType;
