import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IPaginationResourceShort } from '../../../../../props/general';

import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from '../../../../typography/text';
import Pagination from '../../../../uiframeworks/pagination';
import { ActiveSurfaceType } from '../../../../../props/general/panel';
import { ISalesCommissionResourceShort } from '../../../../../props/users/team';
import { SalesCommissionService } from '../../../../../services/users/team';
import SalesCommissionForm from '../form';
import DeleteSalesComissionForm from '../delete';
import { IUserResourceShortProps } from '../../../../../props/user';
import GeneralService from '../../../../../services/general';
import moment from 'moment';

interface ISalesComissionListProps {
    qs?: string[];
    hideSearch?: boolean;
    hideCreateButton?: boolean;
    isHeaderVisible?: boolean;
    columns?: TSalesCommissionColumn[];
}

export type TSalesCommissionColumn = 'sales' | 'actions' | 'percentage' | 'amount';

const SalesComissionList: React.FC<ISalesComissionListProps> = (props: ISalesComissionListProps) => {
    const shownColumns = props.columns || [
        'sales',
        'actions',
        'percentage',
        'amount'
    ];

    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [salesComissions, setSalesComissions] = React.useState<ISalesCommissionResourceShort[]>([]);
    const [activeSalesComission, setActiveSalesComission] = React.useState<ISalesCommissionResourceShort | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<ActiveSurfaceType | undefined>();

    const columns: IColumn[] = [
        {
            key: "sales",
            fieldName: "sales",
            name: "Sales Name",
            minWidth: 200,
            onRender: (item: ISalesCommissionResourceShort) => {
                return <Stack>
                    <Text>{item.sales?.name}</Text>
                </Stack>;
            }
        },
        {
            key: "percentage",
            name: "Commission",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ISalesCommissionResourceShort) => {
                return <Stack>
                    <Text>{Number(item.commissionPercentage)} %</Text>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 30,
            maxWidth: 30,
            onRender: (item: ISalesCommissionResourceShort) => {
                const menuItems: IContextualMenuItem[] = [
                    {
                        key: "update",
                        text: "Update details",
                        iconProps: { iconName: "Edit" },
                        onClick: () => {
                            setActiveSalesComission(item);
                            setActiveSurface('update');
                        }
                    },
                    {
                        key: "delete",
                        text: "Delete",
                        iconProps: { iconName: "Delete" },
                        onClick: () => {
                            setActiveSalesComission(item);
                            setActiveSurface('delete');
                        }
                    },
                ];

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item>
                </Stack.Item>;
            },
        },
        {
            key: "amount",
            fieldName: "amount",
            name: "Amount",
            minWidth: 300,
            onRender: (item: ISalesCommissionResourceShort) => {
                const inFuture = moment(item.startDate).diff(moment(), 'days') > 0;
                return <Stack tokens={{ childrenGap: 5 }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Text>Rp {GeneralService.getNumberWithSeparator(Number(item.startAmount))}</Text>
                        <Text>-</Text>
                        {item.endAmount ? <Text>Rp {GeneralService.getNumberWithSeparator(Number(item.endAmount))}</Text> : null}
                        {!item.endAmount ? <Text style={{ fontStyle: 'italic' }}>Unlimited</Text> : null}
                    </Stack>
                    {inFuture || item.endDate ? <Text size={'small'} style={{ fontStyle: 'italic' }}>
                        {inFuture && !item.endDate ? `(active from ${moment(item.startDate).format("DD/MM/YYYY")})` : null}
                        {inFuture && item.endDate ? `(active from ${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(item.endDate).format("DD/MM/YYYY")})` : null}
                        {!inFuture && item.endDate ? `(active until ${moment(item.endDate).format("DD/MM/YYYY")})` : null}
                    </Text> : null}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        _onRetrieveSalesComissions();
    }, []);

    useEffect(() => {
        _onRetrieveSalesComissions();
    }, [keyword]);

    const _onRetrieveSalesComissions = async (pageNumber?: number,) => {
        setLoaded(false);
        try {
            const qs: string[] = props.qs || [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await SalesCommissionService.retrieve(qs.join("&"));
            setSalesComissions(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'administrators_list_error',
                text: 'Failed to retrieve administrators list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton) {
            farItems.push({
                key: "register",
                text: "Create Sales Commission",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Sales Commission"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveSalesComission(undefined);

        if (refresh) { _onRetrieveSalesComissions() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideSearch && !props.hideCreateButton ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && salesComissions.length > 0 ? <Pagination goToPage={_onRetrieveSalesComissions} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " administrator(s) found"} /> : null}
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && salesComissions.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                isHeaderVisible={props.isHeaderVisible}
                                setKey="items"
                                items={salesComissions}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TSalesCommissionColumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                shimmerLines={3}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && salesComissions.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Sales Comission(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && salesComissions.length > 0 ? <Pagination goToPage={_onRetrieveSalesComissions} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'create' ? <SalesCommissionForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeSalesComission ? <SalesCommissionForm salesComission={activeSalesComission} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && activeSalesComission ? <DeleteSalesComissionForm salesComission={activeSalesComission} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default SalesComissionList;
