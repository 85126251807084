import { makeObservable, observable, action } from "mobx";
import RootStore from './root';
import moment from 'moment';
import { BannerProps } from "../components/uiframeworks/banner";

export class BannerStore {
    private rootStore: RootStore;
    public items: BannerProps[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            items: observable,
            add: action.bound,
            remove: action.bound
        })
    }

    public add = (props: BannerProps) => {
        const key: string = props.key || moment().toISOString();
        const items = this.items;
        items.push({
            key,
            ...props
        });
        this.items = items;

        if (!props.timeout || (props.timeout && props.timeout !== 0)) {
            setTimeout(() => {
                this.remove(key);
            }, props.timeout || 5000);
        }
    }

    public remove = (key: string) => {
        this.items = this.items.filter((item) => item.key !== key);
    }
}