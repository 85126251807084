import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import ValidationService from '../../../../../../services/validation';
import VendorsService from '../../../../../../services/data/vendors';
import ShipsService from '../../../../../../services/data/ships';

// props
import { IRoleResourceShortProps } from '../../../../../../props/users/role';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';

// components
import { DatePicker, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import moment from 'moment';
import ShipSchedulesService from '../../../../../../services/data/ships/shipSchedules';
import GeneralService from '../../../../../../services/general';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../../../../props/data/ships';
import ShipField from '../../../../../uiframeworks/forms/ship';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IShipScheduleFormProps {
    shipId: string;
    scheduleId?: string;
    onDismissed(refresh?: boolean, schedule?: IShipScheduleResourceShort): void;
}

type FormDataProps = {
    voy: string;
    pod: string;
    pol: string;
    status: string;
    estimatedDeparture: string;
    estimatedArrival: string;
    actualDeparture?: string;
    actualArrival?: string;
}

type FormDataErrorProps = {
    voy?: string;
    pod?: string;
    pol?: string;
}

const ShipScheduleForm: React.FC<IShipScheduleFormProps> = (props: IShipScheduleFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [ship, setShip] = React.useState<IShipResourceShortProps | undefined>();
    const [data, setData] = React.useState<FormDataProps>({
        voy: "",
        pod: "",
        pol: "",
        status: "scheduled",
        estimatedDeparture: moment().toISOString(),
        estimatedArrival: moment().add(1, 'days').toISOString()
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.scheduleId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['ships.schedules.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['ships.schedules.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _ship = await ShipsService.get(props.shipId);
        setShip(_ship);

        if (props.scheduleId) {
            const _schedule = await ShipSchedulesService.get(props.scheduleId);
            setData({
                voy: _schedule.voy,
                pol: _schedule.originAddress,
                pod: _schedule.destinationAddress,
                status: _schedule.status || "scheduled",
                estimatedDeparture: _schedule.estimatedDeparture,
                estimatedArrival: _schedule.estimatedArrival,
                actualDeparture: _schedule.actualDeparture,
                actualArrival: _schedule.actualArrival
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.voy || error.pod || error.pol) {
            return true;
        } else if (data.voy === '' || data.pod === '' || data.pol === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { voy, pod, pol, status, estimatedArrival, estimatedDeparture, actualArrival, actualDeparture } = data;

            // create form data
            let fd = new FormData();
            fd.append("shipId", props.shipId);
            fd.append("voy", voy);
            fd.append("status", status);
            fd.append("originAddress", pol.toUpperCase());
            fd.append("destinationAddress", pod.toUpperCase());
            fd.append("estimatedArrival", estimatedArrival);
            fd.append("estimatedDeparture", estimatedDeparture);
            if (actualArrival) {
                fd.append("actualArrival", actualArrival);
            }
            if (actualDeparture) {
                fd.append("actualDeparture", actualDeparture);
            }

            let schedule;
            if (props.scheduleId === undefined) {
                schedule = await ShipSchedulesService.new(fd);
            } else {
                schedule = await ShipSchedulesService.update(props.scheduleId, fd);
            }
            banner.add({
                key: mode + '_ship_schedule_success',
                variant: 'success',
                icon: faCheck,
                text: `Ship schedule voy "${voy}" ${mode}ed successfully`
            });
            props.onDismissed(true, schedule);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Ship Schedule' : 'Update Ship Schedule'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating ship schedule ..." : "Updating ship schedule ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <ShipField required={true}
                            selected={ship}
                            disabled={true}
                            onChange={() => {}} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Voy"}
                            required={true}
                            value={data.voy}
                            onChange={(evt, value) => {
                                data.voy = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.voy = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.voy}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Port of Loading (POL)"}
                            required={true}
                            value={data.pol}
                            multiline={true}
                            resizable={false}
                            autoAdjustHeight
                            rows={1}
                            onChange={(evt, value) => {
                                data.pol = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.pol = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.pol}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Port of Discharge (POD)"}
                            required={true}
                            value={data.pod}
                            multiline={true}
                            resizable={false}
                            autoAdjustHeight
                            rows={1}
                            onChange={(evt, value) => {
                                data.pod = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.pod = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.pod}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 20}}>
                        <Stack.Item grow={1}>
                            <DatePicker label='Estimated Departure (ETD)'
                                value={moment(data.estimatedDeparture).toDate()}
                                isRequired
                                minDate={moment().add(-1, 'month').toDate()}
                                onSelectDate={(date) => {
                                    if (date) {
                                        data.estimatedDeparture = moment(date).toISOString();
                                        setData({ ...data });
                                    }
                                }} formatDate={GeneralService.formatDate} />
                        </Stack.Item>
                        <Stack styles={{root: {marginTop: 35}}}><FontAwesomeIcon icon={faArrowRight} /></Stack>
                        <Stack.Item grow={1}>
                            <DatePicker label='Estimated Arrival (ETA)'
                                value={moment(data.estimatedArrival).toDate()}
                                isRequired
                                minDate={data.estimatedDeparture ? moment(data.estimatedDeparture).toDate() : undefined}
                                onSelectDate={(date) => {
                                    if (date) {
                                        data.estimatedArrival = moment(date).toISOString();
                                        setData({ ...data });
                                    }
                                }} formatDate={GeneralService.formatDate} />
                        </Stack.Item>
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default ShipScheduleForm;