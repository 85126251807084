import { IndonesianCities } from '../../../manifests/cities';
import { ICompanyResourceShort } from '../../../props/data/company';
import Request from './../../request';

type OptionProps = {
    key: string;
    name: string;
}

const CityService = {
  getOptions: (category: 'indonesia' | 'international', keyword: string, searchBy?: 'city' | 'province', selectedProvinces?: string[]): OptionProps[] => {
    let options: OptionProps[] = [];

    if (category === 'indonesia' && searchBy === 'province') {
        const provinces = IndonesianCities;
        options = provinces.filter((pr) => pr.province.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((pr) => {
            return {key: pr.province, name: pr.province};
        });
    } else if (category === 'indonesia' && searchBy === 'city') {
        const provinces = selectedProvinces ? IndonesianCities.filter((pr) => selectedProvinces.indexOf(pr.province) > -1) : IndonesianCities;
        provinces.forEach((pr) => {
            pr.cities.forEach((city) => {
                if (city.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                    options.push({key: city, name: city});
                }
            })
        });
    }

    return options;
  }
};

export default CityService;
