import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../stores/root';
import { useParams } from 'react-router-dom';
import OrderDetails from '../../components/orders/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import NewOrdersCard from '../../components/orders/dashboard/cards/newOrders';
import OngoingOrdersCard from '../../components/orders/dashboard/cards/ongoingOrders';
import UnpaidOrdersCard from '../../components/orders/dashboard/cards/unpaidOrders';
import NewCompanyOrdersCard from '../../components/orders/dashboard/cards/newCompanyOrders';
import OrdersDashboardChart from '../../components/orders/dashboard/charts';

type OrderDashboardPageProps = {
}

const OrderDashboardPage: React.FC<OrderDashboardPageProps> = (props: OrderDashboardPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Orders", [
            {key: "home", icon: faHome, href: "/"},
            {key: "orders", text: 'Orders'}
        ]);
    }, []);

    return <Stack tokens={{childrenGap: 20}}>
        <Stack horizontal tokens={{childrenGap: 20}}>
            <NewOrdersCard style={{width: '25%'}} />
            {/*<OngoingOrdersCard style={{width: '25%'}} />
            <UnpaidOrdersCard style={{width: '25%'}} />
<NewCompanyOrdersCard style={{width: '25%'}} />*/}
            <Stack styles={{root: {width: '25%'}}}></Stack>
            <Stack styles={{root: {width: '25%'}}}></Stack>
            <Stack styles={{root: {width: '25%'}}}></Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <OrdersDashboardChart />
    </Stack>;
};

export default OrderDashboardPage;
