import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import TrainScheduleService from '../../../../../../services/data/trains/trainScedule';
import TrainScheduleDelaysService from '../../../../../../services/data/trains/trainScheduleDelays';

// props
import { ITrainScheduleResourceShort } from '../../../../../../props/data/trains';

// components
import { DatePicker, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import moment from 'moment';

interface ITrainScheduleDelayFormProps {
    scheduleId: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    estimatedDeparture?: string;
    estimatedArrival?: string;
}

const TrainScheduleDelayForm: React.FC<ITrainScheduleDelayFormProps> = (props: ITrainScheduleDelayFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [schedule, setSchedule] = React.useState<ITrainScheduleResourceShort | undefined>();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _schedule = await TrainScheduleService.get(props.scheduleId);
        setSchedule(_schedule);

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (!data.estimatedDeparture || !data.estimatedArrival) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { estimatedArrival, estimatedDeparture } = data;

            if (estimatedDeparture && estimatedArrival && schedule) {
                // create form data
                let fd = new FormData();
                fd.append("scheduleId", props.scheduleId);
                fd.append("newEstimatedArrival", estimatedArrival);
                fd.append("newEstimatedDeparture", estimatedDeparture);

                await TrainScheduleDelaysService.new(fd);
                banner.add({
                    key: 'create_train_schedule_delay_success',
                    variant: 'success',
                    icon: faCheck,
                    text: `Train schedule voy "${schedule?.voy}" delayed successfully`
                });
                props.onDismissed(true);
            } else {
                setSubmitting(false);
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delay Train Schedule"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Delaying train schedule ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && schedule ? <>
                    <Stack.Item>
                        <Label>Current Estimated Departure</Label>
                        <Text>{moment(schedule.estimatedDeparture).format("DD/MM/YYYY")}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Current Estimated Arrival</Label>
                        <Text>{moment(schedule.estimatedArrival).format("DD/MM/YYYY")}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label='New Estimated Departure'
                            value={data.estimatedDeparture ? moment(data.estimatedDeparture).toDate() : undefined}
                            onSelectDate={(date) => {
                                if (date) {
                                    data.estimatedDeparture = moment(date).toISOString();
                                    setData({ ...data });
                                }
                            }} formatDate={GeneralService.formatDate} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label='New Estimated Arrival'
                            value={data.estimatedArrival ? moment(data.estimatedArrival).toDate() : undefined}
                            onSelectDate={(date) => {
                                if (date) {
                                    data.estimatedArrival = moment(date).toISOString();
                                    setData({ ...data });
                                }
                            }} formatDate={GeneralService.formatDate} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default TrainScheduleDelayForm;