import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import moment from 'moment';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';
import OutcomeService from '../../../../../../services/finance/outcomes';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';

// props
import { IBankAccountResourceShortProps, IVendorBankAccountResourceShortProps } from '../../../../../../props/general/bankAccount';
import { IOutcomeResourceShorterProps, IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';

// components
import { ActionButton, DatePicker, DefaultButton, DetailsList, Dropdown, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { VendorBankAccountService } from '../../../../../../services/general/bankAccount';
import { IOutcomePaymentResourceProps } from '../../../../../../props/finance/outcomes/payments';
import InvoiceOrderSubform from '../../../invoices/general/form/subforms/order';
import { IOutcomePaymentInvoiceResourceShortProps } from '../../../../../../props/finance/outcomes/payments/invoice';
import SelectVendorBankAccount from '../../../../../uiframeworks/forms/selectVendorBankAccount';
import SelectInvoice from '../../../../../uiframeworks/forms/selectInvoice';
import SelectExternalBankAccount from '../../../../../uiframeworks/forms/bankaccounts/external';

interface IOutcomePaymentFormProps {
    //invoiceIds?: string[];
    invoiceId?: string;
    paymentId?: string;
    onDismissed(refresh?: boolean): void;
}

/*type FormDataProps = {
    method: string;
    amount: string;
    totalAmount: string;
    notes: string;
    tax: string;
    targetBankAccount?: string;
    targetVirtualAccountNumber: string;
    dueDate: string;
}

type FormDataErrorProps = {
    method?: string;
    amount?: string;
    ppn?: string;
    pph?: string;
    totalAmount?: string;
    notes?: string;
    tax?: string;
}*/

export type FormDataProps = IOutcomePaymentResourceProps & {

};

const OutcomePaymentForm: React.FC<IOutcomePaymentFormProps> = (props: IOutcomePaymentFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<FormDataProps>>({
        id: GeneralService.guid(),
        method: "transfer"
    });
    const [showAddInvoice, setShowAddInvoice] = React.useState<boolean>(false);
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _data = data;

            /*if (props.invoiceIds) {
                const _invoices = await Promise.all(props.invoiceIds.map(async (invoiceId) => {
                    return await OutcomeService.get(invoiceId);
                }));
                
                let amount = 0;
                let paymentInvoices: IOutcomePaymentInvoiceResourceShortProps[] = [];
                
                _invoices.forEach((_invoice) => {
                    amount += Number(_invoice.unpaid || "0") - Number(_invoice.pendingPayment || "0");
                    paymentInvoices.push({id: GeneralService.guid(), invoice: _invoice, amount: amount + ""})
                })
                
                _data.invoices = paymentInvoices;
                _data.totalAmount = amount + "";
                _data.amount = amount + "";
                _data.vendor = _invoices[0]?.vendor;
            }*/

            if (props.invoiceId) {
                const _invoice = await OutcomeService.get(props.invoiceId);

                _data.invoice = _invoice;
                _data.totalAmount = _invoice.amount + "";
                _data.amount = (Number(_invoice.unpaid) - Number(_invoice.pendingPayment)) + "";
                _data.vendor = _invoice.vendor;
            }

            setData({ ..._data });
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_invoices_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to get invoices. Error: ${ErrorService.getMessage(e)}`
            });
            props.onDismissed();
        }
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.amount || error.notes) {
            return true;
        } else if (data.amount === '' || !data.amount) {
            return true;
        } else if (data.method === 'transfer' && !data.targetBankAccount) {
            return true;
        } else if (data.method === 'virtualaccount' && (data.targetVirtualAccountNumber || "").trim() === "") {
            return true;
        } else if (!data.invoice) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            const confirmed = window.confirm("Are you sure you want to submit this payment?");

            if (confirmed) {
                setSubmitting(true);
                const { amount, method, notes, dueDate, totalAmount, targetVirtualAccountNumber, targetBankAccount, invoice } = data;

                // create form data
                let fd = new FormData();
                if (notes) {
                    fd.append('notes', notes || "");
                }

                fd.append('amount', amount || "0");
                fd.append('dueDate', dueDate || "");
                fd.append('totalAmount', totalAmount || "0");

                fd.append('method', method || "Cash");
                if (method === 'transfer' && targetBankAccount) {
                    fd.append('targetBankAccountId', targetBankAccount.id);
                } else if (method === 'virtualaccount' && (targetVirtualAccountNumber || "").trim() !== "") {
                    fd.append('targetVirtualAccountNumber', (targetVirtualAccountNumber || ""));
                }

                fd.append('invoiceId', invoice?.id || "");

                const payment = await OutcomePaymentService.create(fd);

                banner.add({
                    key: 'create_outcome_payment_success',
                    variant: 'success',
                    icon: faCheck,
                    text: `Payment Rp. ${GeneralService.getNumberWithSeparator(amount || "0")} recorded successfully`
                });
                props.onDismissed(true);
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={'Record Payment'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Request"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <SelectInvoice label={'Related invoice'}
                        selected={data.invoice}
                        required={true}
                        onChange={(invoice) => {
                            data.invoice = invoice;
                            data.amount = invoice?.unpaid || "0";
                            setData({ ...data });
                        }}
                        disabled={props.invoiceId !== undefined || submitting} />
                    <Stack className='divider'></Stack>
                    <TextField label={"Requested payment amount"}
                        prefix='Rp'
                        required={true}
                        value={(data.amount || "") + ""}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.amount = (value || "");

                                const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 18, max: Number(data.invoice?.unpaid || "0") });
                                error.amount = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.amount} />
                    <Stack className='divider'></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: data.method === 'cash' ? '100%' : '50%' } }}>
                            <Dropdown label={"Payment Method"}
                                required={true}
                                disabled={submitting}
                                selectedKey={data.method}
                                options={PaymentMethods}
                                onChange={(evt, opt) => {
                                    data.method = opt ? opt.key as string : "";
                                    data.targetBankAccount = undefined;
                                    data.targetVirtualAccountNumber = undefined;
                                    setData({ ...data });
                                }} />
                        </Stack>
                        {data.method === 'transfer' ? <Stack styles={{ root: { width: '50%' } }}>
                            <SelectExternalBankAccount label={"Select bank account"}
                                allowCreate={true}
                                selected={data.targetBankAccount}
                                vendorId={data.vendor?.id}
                                required
                                onChange={(bankAccount) => {
                                    data.targetBankAccount = bankAccount;
                                    setData({ ...data });
                                }} />
                        </Stack> : null}
                        {data.method === 'virtualaccount' ? <Stack styles={{ root: { width: '50%' } }}>
                            <TextField label={'Virtual Account Number'}
                                required
                                value={data.targetVirtualAccountNumber}
                                onChange={(evt, value) => {
                                    data.targetVirtualAccountNumber = value || "";
                                    setData({ ...data });
                                }} />
                        </Stack> : null}
                    </Stack>
                    <Stack className='divider'></Stack>
                    <Stack.Item>
                        <DatePicker label={"Due Date"}
                            isRequired={true}
                            value={moment(data.dueDate).toDate()}
                            onSelectDate={(date) => {
                                data.dueDate = date ? moment(date).toISOString() : data.dueDate;
                                setData({ ...data });
                            }}
                            formatDate={GeneralService.formatDate}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OutcomePaymentForm;
