import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';

// props
import { IUserResourceShortProps } from '../../../../props/users/user';
import { IPaginationResourceShort } from '../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, Persona, PersonaSize, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from './../../../typography/text';
import Label from '../../../typography/label';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import { IOrderResourceProps, IOrderResourceShortProps } from '../../../../props/orders/order';
import GeneralService from '../../../../services/general';
import FileService from '../../../../services/general/file';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import OrderService from '../../../../services/orders/order';
import OrderForm from '../form';
import OrderListFilterButton, { TFilterBy } from './filter';
import { FilterOptionProps } from '../../../uiframeworks/filters/panel';
import SelectedFilter from '../../../uiframeworks/filters/selected';
import Pagination from '../../../uiframeworks/pagination';
import GlobalConfig from '../../../../config';
import { IOrderStatusResourceShortProps } from '../../../../props/orders/orderStatus';
import OrderStatusService from '../../../../services/orders/orderStatus';
import PivotButton from '../../../uiframeworks/buttons/pivot';
import PermissionsService from '../../../../services/permissions';
import Permissions from '../../../../permissions';

interface IOrdersListProps {
    qs?: string[];
    orders?: IOrderResourceShortProps[];
    columns?: TOrderColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateOrder?: boolean;
    hideFilter?: boolean;
    filters?: TFilterBy[];
    variant?: 'card' | 'plain';
}

export type TOrderColumn = 'orderNumber' | 'actions' | 'sales' | 'customer' | 'batch' | 'deliveryType' | 'deliveryDetails' | 'price' | 'orderStatus' | 'paymentStatus' | 'loads';

const OrdersList: React.FC<IOrdersListProps> = (props: IOrdersListProps) => {
    const shownColumns = props.columns || [
        'orderNumber',
        'actions',
        'customer',
        'batch',
        'deliveryType',
        'deliveryDetails',
        'price',
        'orderStatus',
        'paymentStatus',
        'sales'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [orders, setOrders] = React.useState<IOrderResourceShortProps[]>(props.orders || []);
    const [activeOrder, setActiveOrder] = React.useState<IOrderResourceShortProps | undefined>();
    const [orderStatus, setOrderStatus] = React.useState<IOrderStatusResourceShortProps[]>([]);
    const [selectedStatus, setSelectedStatus] = React.useState<string>("active");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = React.useState<string | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterOptionProps[]>([]);
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "orderNumber",
            name: "Order Number",
            fieldName: "orderNumber",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderResourceProps) => {
                const splitted = item.orderNumber.split('.');
                splitted.shift();
                splitted.shift();

                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/orders/${item.id}`}><Text>#{splitted.join('.')}</Text></NavLink>
                </Stack>
            }
        },
        {
            key: "customer",
            name: "Customer",
            fieldName: "customer",
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            onRender: (item: IOrderResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/users/customers/${item.customer.id}`}>
                        <Text>{item.customer.name}</Text>
                    </NavLink>
                    {item.company ? <NavLink to={`/users/companies/${item.company.id}`}>
                        <Text size={'small'}>({item.company.name})</Text>
                    </NavLink> : null}
                </Stack>
            }
        },
        {
            key: "sales",
            name: "Sales",
            fieldName: "sales",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOrderResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                    {item.sales && item.sales.length > 0 ? item.sales.map((sales) => {
                        return <NavLink to={`/users/administrators/${sales.sales.id}`}>
                            <Text>{sales.sales.name}</Text>
                        </NavLink>
                    }) : <Text>-</Text>}
                </Stack>
            }
        },
        {
            key: "batch",
            name: "Batch",
            fieldName: "batch",
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            onRender: (item: IOrderResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.batch || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "deliveryType",
            name: "Delivery Type",
            fieldName: "deliveryType",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOrderResourceProps) => {
                const type = DeliveredGoodsType.find((t) => t.key === item.goodsType);
                return <Stack tokens={{ childrenGap: 3 }} styles={{ root: { padding: '4px 0px' } }}>
                    <Label size={'small'}>{item.freightCategory.name}</Label>
                    <Text size={'small'}>{item.deliveryType.name} - {type?.text}</Text>
                </Stack>
            }
        },
        {
            key: "deliveryDetails",
            name: "Address",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOrderResourceProps) => {
                const origin = OrderService.getAddress(item.originAddresses[0].type, item.originAddresses[0].address, item.originAddresses[0].city, item.originAddresses[0].province);
                const destination = OrderService.getAddress(item.destinationAddresses[0].type, item.destinationAddresses[0].address, item.destinationAddresses[0].city, item.destinationAddresses[0].province);

                return <Stack tokens={{ childrenGap: 5 }} styles={{ root: { padding: '4px 0px' } }}>
                    <Stack>
                        <Label size={'small'}>Pick-up Address:</Label>
                        {item.originAddresses.length > 1 ? <ol style={{padding: '0px 12px', margin: 0}}>
                            {item.originAddresses.map((address) => {
                                const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                return <Text size={'small'}><li>{text}</li></Text>
                            })}
                        </ol> : null}
                        {item.originAddresses.length < 2 && item.originAddresses.length > 0 ? <Text size={'small'}>{origin}</Text> : null}
                    </Stack>
                    <Stack>
                        <Label size={'small'}>Delivery Address:</Label>
                        {item.destinationAddresses.length > 1 ? <ol style={{padding: '0px 12px', margin: 0}}>
                            {item.destinationAddresses.map((address) => {
                                const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                return <Text size={'small'}><li>{text}</li></Text>
                            })}
                        </ol> : null}
                        {item.destinationAddresses.length < 2 && item.destinationAddresses.length > 0 ? <Text size={'small'}>{destination}</Text> : null}
                    </Stack>
                </Stack>
            }
        },
        {
            key: 'loads',
            name: 'Loads',
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IOrderResourceProps) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                    {item.containers.map((container, idx) => <Tag text={"Kontainer " + container.containerNumber || ("#" + (idx + 1))} />)}
                </Stack>
            }
        },
        {
            key: "price",
            name: "Total Price",
            fieldName: "price",
            minWidth: 135,
            maxWidth: 135,
            onRender: (item: IOrderResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(item.finalPrice + "", '.')}</Text>
                </Stack>
            }
        },
        {
            key: "orderStatus",
            name: "Status",
            fieldName: "orderStatus",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IOrderResourceProps) => {
                let tagVariant: TTagVariant = 'warning';
                if (item.status.key === 'completed') { tagVariant = 'success'; }
                else if (item.status.key === 'cancelled') { tagVariant = 'error'; }
                else if (item.status.key === 'active') { tagVariant = 'active'; }

                return <Stack horizontal>
                    <Tag variant={tagVariant} text={item.status.name} />
                </Stack>
            }
        },
        {
            key: "paymentStatus",
            name: "Paid/Unpaid",
            minWidth: 110,
            maxWidth: 110,
            onRender: (item: IOrderResourceProps) => {
                let tagVariant: TTagVariant = 'error';
                let tagText: string = 'Unpaid';
                if (item.paymentCompleted) {
                    tagVariant = 'success';
                    tagText = 'Paid'
                }

                return <Stack horizontal>
                    {item.finalPrice > 0 ? <Tag variant={tagVariant} text={tagText} /> : <Text>-</Text>}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.orders && orderStatus.length < 1) {
            init();
        } else if (!props.orders && orderStatus.length > 0) {
            _onRetrieveOrders();
        } else {
            setLoaded(true);
        }
    }, [keyword, selectedFilter, selectedStatus, selectedPaymentStatus]);

    const getQueryString = (pageNumber?: number) => {
        const qs: string[] = props.qs || [];
        qs.push(`top=${GlobalConfig.defaultTop}`);
        qs.push(`order_status=${selectedStatus}`);
        if (selectedPaymentStatus) { qs.push(`payment_status=${selectedPaymentStatus}`); }
        if (pageNumber) { qs.push(`page=${pageNumber}`); }
        if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

        const groupedFilter: { key: string, options: FilterOptionProps[] }[] = [];
        selectedFilter.map((sel) => {
            const gfidx = groupedFilter.findIndex((gf) => gf.key === sel.filterby);
            if (gfidx > -1) {
                groupedFilter[gfidx].options.push(sel);
            } else {
                groupedFilter.push({
                    key: sel.filterby,
                    options: [sel]
                });
            }
        });

        groupedFilter.forEach((gf) => {
            qs.push(`${gf.key}=${gf.options.map((opt) => opt.key).join(';')}`);
        });

        return qs.join("&");
    }

    const init = async () => {
        setLoaded(false);
        await _onRetrieveOrderStatus();
        await _onRetrieveOrders();
    }

    const _onRetrieveOrderStatus = async () => {
        try {
            const results = await OrderStatusService.retrieve();
            setOrderStatus(results);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_status_error',
                text: 'Failed to retrieve order status. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onRetrieveOrders = async (pageNumber?: number) => {
        try {
            setLoaded(false);
            const qs = getQueryString(pageNumber);
            const results = await OrderService.retrieve(qs);
            setOrders(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_orders_list_error',
                text: 'Failed to retrieve orders list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} styles={{ root: { marginRight: 10 } }} />
                }
            });
        }

        if (!props.hideFilter) {
            items.push({
                key: "filter",
                onRender: () => {
                    return <Stack styles={{ root: { marginRight: 10 } }}>
                        <OrderListFilterButton selected={selectedFilter}
                            filters={props.filters}
                            onChange={(selected) => {
                                setSelectedFilter([...selected]);
                            }} />
                    </Stack>
                }
            });
        }

        if (!props.hideCreateOrder && PermissionsService.hasPermission(Permissions.order.create, user.roles)) {
            farItems.push({
                key: "register",
                text: "Create Order",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Order"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveOrder(undefined);

        if (refresh) { _onRetrieveOrders() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        {selectedFilter.length > 0 ? <SelectedFilter selected={selectedFilter} onChange={(selected) => {
            setSelectedFilter(selected);
        }} /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && orders.length > 0 ? <Pagination goToPage={_onRetrieveOrders} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " order(s) found"} /> : null}
            <Stack tokens={{ childrenGap: 20 }} horizontal wrap>
                {orderStatus.length > 0 ? <PivotButton buttons={orderStatus.map((status) => {
                    return {
                        key: status.key,
                        text: status.name,
                        active: selectedStatus === status.key,
                        onClick: () => {
                            setSelectedStatus(status.key);
                        }
                    }
                })} /> : null}
                <PivotButton buttons={[
                    {
                        key: "all",
                        text: "All",
                        active: selectedPaymentStatus === undefined,
                        onClick: () => {
                            setSelectedPaymentStatus(undefined);
                        }
                    },
                    {
                        key: "paid",
                        text: "Paid",
                        active: selectedPaymentStatus === 'paid',
                        onClick: () => {
                            setSelectedPaymentStatus('paid');
                        }
                    },
                    {
                        key: "unpaid",
                        text: "Unpaid",
                        active: selectedPaymentStatus === 'unpaid',
                        onClick: () => {
                            setSelectedPaymentStatus('unpaid');
                        }
                    }
                ]} />
            </Stack>
            <Stack className={styles[props.variant || 'card']}>
                {
                    !loaded || (loaded && orders.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={orders}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderColumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && orders.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Order(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && orders.length > 0 ? <Pagination goToPage={_onRetrieveOrders} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderForm onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrdersList;
