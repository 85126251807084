import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import Text from '../../../../typography/text';
import { IUserResourceShortProps } from '../../../../../props/users/user';
import { NavLink } from 'react-router-dom';
import Tag from '../../../../uiframeworks/tag';
import UserService from '../../../../../services/users/user';
import CustomerForm from '../form';
import ResetCustomerPasswordForm from '../resetPassword';
import GlobalConfig from '../../../../../config';
import Pagination from '../../../../uiframeworks/pagination';
import PermissionsService from '../../../../../services/permissions';

interface ICustomersListProps {
    qs?: string[];
}

const CustomersList: React.FC<ICustomersListProps> = (props: ICustomersListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [customers, setCustomers] = React.useState<IUserResourceShortProps[]>([]);
    const [activeCustomer, setActiveCustomer] = React.useState<IUserResourceShortProps | undefined>();
    const [pagination, setPagingation] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "email",
            fieldName: "email",
            name: "Email",
            minWidth: 300,
            maxWidth: 300,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.email}>
                        <NavLink to={`/users/customers/${item.id}`}>
                            <Text>{item.email}</Text>
                        </NavLink>
                    </TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IUserResourceShortProps) => {
                let menuItems: IContextualMenuItem[] = []

                if (PermissionsService.hasPermission(['customers.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveCustomer(item);
                            setActiveSurface('update');
                        }
                    });
                    
                    menuItems.push({
                        key: "resetPassword", text: "Reset password", iconProps: { iconName: "Permissions" }, onClick: () => {
                            setActiveCustomer(item);
                            setActiveSurface('resetPassword');
                        }
                    });
                }

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: "name",
            fieldName: "name",
            name: "Nama",
            minWidth: 200,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.name}><Text>{item.name}</Text></TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "company",
            name: "Company",
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }}>
                    {item.companies.map((company) => {
                        return <Tag>{company.name}</Tag>
                    })}
                </Stack>;
            }
        },
        {
            key: "phoneNumber",
            fieldName: "phoneNumber",
            name: "No. Telepon",
            minWidth: 150,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.phoneNumber}><Text>{item.phoneNumber || "-"}</Text></TooltipHost>
                </Stack.Item>;
            }
        }
    ];

    useEffect(() => {
        _onRetrieveCustomers();
    }, [keyword]);

    const _onRetrieveCustomers = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            const qs: string[] = props.qs || [];
            qs.push(`rolesGroup=customer`);
            qs.push(`top=${GlobalConfig.defaultTop}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await UserService.retrieve(qs.join("&"));
            setCustomers(results.data);
            setPagingation(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_customers_error',
                text: 'Failed to retrieve customers list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['customers.read.all', 'customers.read.me'], user.permissions)) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }
        
        if (PermissionsService.hasPermission(['customers.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Customer",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Customer"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveCustomer(undefined);

        if (refresh) { _onRetrieveCustomers() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && customers.length > 0 ? <Pagination goToPage={_onRetrieveCustomers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " customer(s) found"} /> : null}
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && customers.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={customers}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && customers.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Customer(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && customers.length > 0 ? <Pagination goToPage={_onRetrieveCustomers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'create' ? <CustomerForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeCustomer ? <CustomerForm customerId={activeCustomer.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'resetPassword' && activeCustomer ? <ResetCustomerPasswordForm user={activeCustomer} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default CustomersList;
