import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../../services/general';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';

// props
import { IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import { FilterOptionProps } from '../../../../../uiframeworks/filters/panel';
import DocumentService from '../../../../../../services/documents/document';

interface IDownloadPaymentsReportProps {
    start?: string;
    end?: string;
    filters?: FilterOptionProps[];
    onDismissed(refresh?: boolean): void;
}

const DownloadPaymentsReport: React.FC<IDownloadPaymentsReportProps> = (props: IDownloadPaymentsReportProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [payments, setPayments] = useState<IOutcomePaymentResourceShortProps[]>([])

    const _onDownload = async () => {
        setDownloading(true)
        try {
            await DocumentService.dowonloadPaymentsReport(payments)
            setDownloading(false)
            props.onDismissed()
        } catch (error) {
            setDownloading(false)
        }
    }

    const getPayments = async () => {
        try {
            const qs: string[] = [];
            qs.push(`completeData=1`);
            const result = (await OutcomePaymentService.retrieve(qs.join("&"))).data;
            setPayments(result)
            setLoaded(true)
        } catch (error) {

        }
    }

    useEffect(() => {
        getPayments()
        return () => { }
    }, [])


    return <Panel headerText={"Download Payments Report"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !downloading ? (
                        <>
                            <PrimaryButton disabled={!loaded} text={"Download"} onClick={_onDownload} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {downloading ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"export payment report..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Preparing form ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Text>Are you sure you want to download payments report with details below?</Text>
                {props.start || props.end ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {props.start ? <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Start Date</Label>
                        <Text>{moment(props.start).format("DD/MM/YYYY")}</Text>
                    </Stack.Item> : null}
                    {props.end ? <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>End Date</Label>
                        <Text>{moment(props.end).format("DD/MM/YYYY")}</Text>
                    </Stack.Item> : null}
                </Stack> : null}
            </> : null}
        </Stack>
    </Panel>
};

export default DownloadPaymentsReport;
