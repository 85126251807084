import React from 'react';

// assets

// services
import GeneralService from '../../../../../../services/general';

// props

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from '@fluentui/react';
import { IOrderAddressResourceShortProps } from '../../../../../../props/orders/address';
import CityService from '../../../../../../services/data/cities';
import Label from '../../../../../typography/label';

type RequestOfferAddressSubformProps = {
    headerTitle: string;
    orderNumber: number;
    target: string;
    onDismissed(data?: IOrderAddressResourceShortProps): void;
}

type FormDataErrorProps = {
    address?: string;
}

const RequestOfferAddressSubform: React.FC<RequestOfferAddressSubformProps> = (props: RequestOfferAddressSubformProps) => {
    const [data, setData] = React.useState<IOrderAddressResourceShortProps>({
        id: GeneralService.guid(),
        address: "",
        orderNumber: props.orderNumber,
        target: props.target,
        type: "door"
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    const isSubmitButtonDisabled = () => {
        return false;
    }

    return <Panel headerText={props.headerTitle}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={() => props.onDismissed(data)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            <Dropdown label={'Type'}
                selectedKey={data.type}
                options={[
                    { key: 'door', text: 'Door' },
                    { key: 'yard', text: 'Container yard' },
                    { key: 'port', text: 'Port' }
                ]}
                onChange={(evt, opt) => {
                    const value = opt ? opt.key as string : 'door';
                    data.type = value;
                    setData({ ...data });
                }} />
            <TextField label={'Address'}
                multiline
                rows={3}
                autoAdjustHeight
                resizable={false}
                required={true}
                onChange={(evt, value) => {
                    data.address = value || "";
                    setData({ ...data });
                }}
                errorMessage={error.address} />
            <Stack horizontal tokens={{childrenGap: 20}}>
                <Stack styles={{root: {width: '50%'}}}>
                    <Label size={'xsmall'} required>Province</Label>
                    <TagPicker selectedItems={(data.province || "").trim() !== '' ? [{ key: data.province || "", name: data.province || "" }] : []}
                        removeButtonAriaLabel='Remove'
                        itemLimit={1}
                        onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                        onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')}
                        onChange={(items) => {
                            const value = items && items[0] ? items[0].name : "";
                            data.province = value;
                            setData({...data});
                        }} />
                </Stack>
                <Stack styles={{root: {width: '50%'}}}>
                    <Label size={'xsmall'} required>City</Label>
                    <TagPicker selectedItems={(data.city || "").trim() !== '' ? [{ key: data.city || "", name: data.city || "" }] : []}
                        removeButtonAriaLabel='Remove'
                        itemLimit={1}
                        disabled={!data.province}
                        onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [data.province || ""])}
                        onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province', [data.province || ""])}
                        onChange={(items) => {
                            const value = items && items[0] ? items[0].name : "";
                            data.city = value;
                            setData({...data});
                        }} />
                </Stack>
            </Stack>
        </Stack>
    </Panel>
};

export default RequestOfferAddressSubform;
