import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import QuantityUnitsList from '../../../components/settings/quantityUnits/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IQuantityUnitsListPageProps {
}

const QuantityUnitsListPage: React.FC<IQuantityUnitsListPageProps> = (props: IQuantityUnitsListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Quantity Units", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "quantityUnits", text: 'Quantity Units'}
        ])
    }, []);

    return <Stack>
        <QuantityUnitsList />
    </Stack>;
};

export default QuantityUnitsListPage;
