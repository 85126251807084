/**
 * Import all your stores
 */

import React from "react";
import { TopbarStore } from "./topbar";
import { BannerStore } from "./banner";
import { RouteStore } from "./route";
import { UserStore } from "./user";

/**
 * Root Store Class with
 */
export default class RootStore {
  topbar: TopbarStore;
  banner: BannerStore;
  route: RouteStore;
  user: UserStore;

  constructor() {
    this.topbar = new TopbarStore(this);
    this.banner = new BannerStore(this);
    this.route = new RouteStore(this);
    this.user = new UserStore(this);
  }
}

const rootStore = new RootStore();
export const rootStoreContext = React.createContext(rootStore);
export const useStore = () => React.useContext(rootStoreContext);