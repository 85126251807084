import Request from './../request';

// import props
import { IPaginationResourceShort } from '../../props/general';
import { IGoodsResourceShortProps, IGoodsResourceProps } from '../../props/goods/goods';

const GoodsService = {
    create: async (data:FormData):Promise<IGoodsResourceProps> => {
        try {
            const result = await Request.post(`/api/goods`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id: string, data: FormData):Promise<IGoodsResourceShortProps> => {
        try {
            const result = await Request.post(`/api/goods/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    get: async (id: string):Promise<IGoodsResourceProps> => {
        try {
            const result = await Request.get(`/api/goods/${id}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    retrieve: {
        get: async (qs?: string):Promise<IGoodsResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/goods?${qs || ""}`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        getPaged: async (qs?: string):Promise<{data: IGoodsResourceShortProps[], pagination: IPaginationResourceShort}> => {
            try {
                const results = await Request.get(`/api/goods?paged=1&${qs || ""}`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/goods/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
};

export default GoodsService;