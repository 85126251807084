import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import NewsesList from '../../../components/settings/news/general/list';

interface IPublicNewsPageProps {
}

const PublicNewsPage: React.FC<IPublicNewsPageProps> = (props: IPublicNewsPageProps) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("News", [
      { key: "home", icon: faHome, href: "/" },
      { key: "settings", text: 'Settings', href: "/settings" },
      { key: "news", text: 'Public News' }
    ])
  }, []);

  return (
    <Stack>
      <NewsesList />
    </Stack>
  );
};

export default PublicNewsPage;
