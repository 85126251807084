import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import OrderAddOnService from '../../../../../services/orders/odrerAddOn';

// props

// components
import { Checkbox, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import GeneralService from '../../../../../services/general';
import { PPNPercentageOptions, PPhPercentageOptions } from '../../../../../manifests/taxPercentage';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';

interface IOrderAdditionalChargeFormProps {
    orderId: string;
    additionalChargeId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    notes: string;
    price: string;
    ppn: string;
    pph: string;
    includePPN?: boolean;
    includePPh?: boolean;
    ppnPercentage: string;
    pphPercentage: string;
    finalPrice: string;
    nettPrice: string;
    invoicePrice: string;
}

type FormDataErrorProps = {
    name?: string;
    notes?: string;
    price?: string;
    ppn?: string;
    pph?: string;
}

const OrderAdditionalChargeForm: React.FC<IOrderAdditionalChargeFormProps> = (props: IOrderAdditionalChargeFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        notes: "",
        price: "",
        ppn: "0",
        pph: "0",
        ppnPercentage: "0",
        pphPercentage: "0",
        finalPrice: "0",
        nettPrice: "0",
        invoicePrice: "0"
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.additionalChargeId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.additionalChargeId) {
            const _additionalCharge = await OrderAddOnService.get(props.orderId, props.additionalChargeId);

            setData({
                name: _additionalCharge.name,
                notes: _additionalCharge.notes || "",
                price: _additionalCharge.price + "",
                ppn: Number(_additionalCharge.ppn) + "",
                pph: Number(_additionalCharge.pph) + "",
                ppnPercentage: Number(_additionalCharge.ppnPercentage) + "",
                pphPercentage: Number(_additionalCharge.pphPercentage) + "",
                finalPrice: _additionalCharge.totalPrice + "",
                nettPrice: _additionalCharge.nettPrice + "",
                invoicePrice: _additionalCharge.invoicePrice + "",
                includePPN: _additionalCharge.includePPN,
                includePPh: _additionalCharge.includePPh
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.notes || error.price) {
            return true;
        } else if (data.name === '' || data.price === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, notes, price, ppn, pph, includePPN, includePPh, ppnPercentage, pphPercentage, finalPrice, nettPrice, invoicePrice } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("notes", notes);
            fd.append("totalPrice", finalPrice);
            fd.append("finalPrice", finalPrice);
            fd.append("nettPrice", nettPrice);
            fd.append("invoicePrice", invoicePrice);
            fd.append("price", price);
            fd.append("ppn", ppn);
            fd.append("pph", pph);
            fd.append("includePPN", includePPN + "");
            fd.append("includePPh", includePPh + "");
            fd.append("ppnPercentage", ppnPercentage + "");
            fd.append("pphPercentage", pphPercentage + "");

            if (props.additionalChargeId === undefined) {
                await OrderAddOnService.new(props.orderId, fd);
            } else {
                await OrderAddOnService.update(props.orderId, props.additionalChargeId, fd);
            }
            banner.add({
                key: mode + '_order_additional_charge_success',
                variant: 'success',
                icon: faCheck,
                text: `Additional charge "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const calculateAllPrice = (_data: FormDataProps) => {
        let originalPrice = "0";
        
        if (_data.includePPN) {
            if (_data.ppnPercentage && data.ppnPercentage !== 'custom') {
                originalPrice = (Number(_data.price) * 100 / (100 + Number(_data.ppnPercentage))).toFixed();
            } else {
                originalPrice = (Number(_data.price) - Number(_data.ppn)).toFixed();
            }
        } else if (!_data.includePPN) {
            originalPrice = _data.price;
        }

        let ppn = "0";
        if (_data.includePPN && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(originalPrice) * (Number(_data.ppnPercentage) / 100)).toFixed();
        } else if (!_data.includePPN && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(_data.price) * (Number(_data.ppnPercentage) / 100)).toFixed();
        }
        _data.ppn = ppn;

        let pph = "0";
        if (_data.pphPercentage && data.pphPercentage !== 'custom') {
            pph = (Number(originalPrice) * (Number(_data.pphPercentage) / 100)).toFixed();
        }
        _data.pph = pph;

        let finalPrice = 0;
        if (_data.includePPN && _data.includePPh) {
            finalPrice = Number(_data.price);
        } else if (_data.includePPN && !_data.includePPh) {
            finalPrice = Number(_data.price) + Number(_data.pph);
        } else if (!_data.includePPN && _data.includePPh) {
            finalPrice = Number(_data.price) + Number(_data.ppn);
        } else  {
            finalPrice = Number(_data.price) + Number(_data.ppn) + Number(_data.pph);
        }
        _data.finalPrice = finalPrice + "";

        let nettPrice = finalPrice;
        let invoicePrice = finalPrice;
        if (_data.includePPN) {
            nettPrice = nettPrice - Number(_data.ppn);
        }
        if (_data.includePPh) {
            nettPrice = nettPrice - Number(_data.pph);
        }
        _data.invoicePrice = invoicePrice + "";

        setData({ ...data });
    }

    return <Panel headerText={mode === 'create' ? 'Create Additional Charge' : 'Update Additional Charge'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating additional charge ..." : "Updating additional charge ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.name = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={false}
                            value={data.notes}
                            onChange={(evt, value) => {
                                data.notes = value || "";
                                
                                const validation = ValidationService.combination(value, ['limit'], {maxChars: 100});
                                error.notes = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Price"}
                            value={data.price}
                            onChange={(evt, value) => {
                                if (!isNaN(Number(value))) {
                                    data.price = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.price = validation.message;

                                    setError({ ...error });

                                    calculateAllPrice(data);
                                }
                            }}
                            prefix={"Rp"}
                            description={data.price !== "" ? GeneralService.convertNumberToWords(Number(data.price)) : undefined}
                            errorMessage={error.price}
                            disabled={submitting}
                            required={true} />
                    </Stack.Item>
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <Stack.Item>
                            <Checkbox checked={data.includePPN}
                                label='Include PPN'
                                onChange={(ev, checked) => {
                                    data.includePPN = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox checked={data.includePPh}
                                label='Include PPh'
                                onChange={(ev, checked) => {
                                    data.includePPh = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPN</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPNPercentageOptions}
                                    selectedKey={data.ppnPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.ppn = (Number(opt.key) / 100 * Number(data.price)).toFixed();
                                        }
                                        data.ppnPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.ppn}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.ppn = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.ppn = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.ppn}
                                    disabled={submitting || data.ppnPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPh</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPhPercentageOptions}
                                    selectedKey={data.pphPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.pph = (Number(opt.key) / 100 * Number(data.price)).toFixed();
                                        }
                                        data.pphPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.pph}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.pph = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.pph = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.pph}
                                    disabled={submitting || data.pphPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack.Item>
                        <Label size={'small'}>Final Price</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(data.finalPrice)}</Text>
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderAdditionalChargeForm;
