import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, Checkbox, DefaultButton, IconButton, Panel, PanelType, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import { FilterOptionProps } from '../panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

interface ISelectedFilterProps {
    selected: FilterOptionProps[];
    onChange(selected: FilterOptionProps[]): void;
}

const SelectedFilter: React.FC<ISelectedFilterProps> = (props: ISelectedFilterProps) => {
    const [selected, setSelected] = React.useState<FilterOptionProps[]>(props.selected);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    const renderSelectedFilter = (_selected: FilterOptionProps) => {
        return <Stack className={styles.item} horizontal tokens={{childrenGap: 10}} verticalAlign='center'>
            <Stack horizontal tokens={{childrenGap: 5}} verticalAlign='center'>
                <Text>{_selected.filterbyText}:</Text>
                <Label size={'small'}>{_selected.title}</Label>
            </Stack>
            <Stack className={styles.action}>
                <IconButton className={styles.button} styles={{root: {minHeight: 'unset', height: 'unset', padding: '0px !important', background: 'transparent !important'}}} onClick={() => _onDelete(_selected)}>
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            </Stack>
        </Stack>
    }

    const _onDelete = (_selected: FilterOptionProps) => {
        const filtered = ([...selected.filter((sel) => (sel.key !== _selected.key || sel.filterby !== _selected.filterby))]);
        props.onChange(filtered);
    }

    return <Stack className={styles.container} horizontal tokens={{childrenGap: 10}} wrap>
        {selected.map(renderSelectedFilter)}
    </Stack>
};

export default SelectedFilter;
