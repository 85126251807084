import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../services/general';

// props
import { IOrderTruckGoodsResourceShortProps } from '../../../../../props/orders/trucks/goods';

// components
import { IColumn, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';

interface TOrderTruckGoodsListProps {
    orderId: string;
    truckId: string;
    qs?: string[];
    goods?: IOrderTruckGoodsResourceShortProps[];
    columns?: TOrderTruckGoodsListColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderTruckGoodsListColumn = 'idx' | 'goods' | 'quantity' | 'size' | 'volume' | 'weight' | 'totalWeight';

const OrderTruckGoodsList: React.FC<TOrderTruckGoodsListProps> = (props: TOrderTruckGoodsListProps) => {
    const shownColumns: TOrderTruckGoodsListColumn[] = props.columns || [
        'idx',
        'goods',
        'quantity',
        'size',
        'volume',
        'weight',
        'totalWeight'
    ];

    const { banner } = useStore();
    const [goods, setGoods] = React.useState<IOrderTruckGoodsResourceShortProps[]>(props.goods || []);

    const columns: IColumn[] = [
        {
            key: "idx",
            name: "No.",
            minWidth: 30,
            maxWidth: 30,
            onRender: (item: IOrderTruckGoodsResourceShortProps, idx?: number) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{(idx || 0) + 1}</Text>
                </Stack>
            }
        },
        {
            key: "goods",
            name: "Goods",
            minWidth: 150,
            onRender: (item: IOrderTruckGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.goods.name}</Text>
                </Stack>
            }
        },
        {
            key: "quantity",
            name: "Quantity",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOrderTruckGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.quantityAmount} {item.goods.quantityUnit}</Text>
                </Stack>
            }
        },
        {
            key: "size",
            name: "Size",
            minWidth: 300,
            maxWidth: 300,
            onRender: (item: IOrderTruckGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{GeneralService.getNumberWithSeparator(Number(item.length))}cm x {GeneralService.getNumberWithSeparator(Number(item.width))}cm x {GeneralService.getNumberWithSeparator(Number(item.height))}cm</Text>
                </Stack>
            }
        },
        {
            key: "volume",
            name: "Volume (m3)",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOrderTruckGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{GeneralService.getNumberWithSeparator(GeneralService.calculateVolume(item.length + "", item.width + "", item.height + ""))} m<sup>3</sup></Text>
                </Stack>
            }
        },
        {
            key: "weight",
            name: "Weight",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOrderTruckGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.quantityAmount} {item.weightUnit}</Text>
                </Stack>
            }
        },
        {
            key: "totalWeight",
            name: "Total Weight",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOrderTruckGoodsResourceShortProps) => {
                const weight = Number(item.weightAmount);
                const quantity = Number(item.quantityAmount);
                const totalWeight = weight * quantity;

                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{GeneralService.getNumberWithSeparator(GeneralService.getWeightInKg(totalWeight, item.weightUnit))} kg</Text>
                </Stack>
            }
        }
    ];

    return <Stack>
        {goods.length > 0 ? <ShimmeredDetailsList
            setKey="items"
            items={goods}
            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderTruckGoodsListColumn) > -1)}
            selectionMode={SelectionMode.none}
            enableShimmer={false}
            onShouldVirtualize={() => false}
            ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid="Item details" /> : null}
        {goods.length <= 0 ? <Text>Goods not found</Text> : null}
    </Stack>;
};

export default OrderTruckGoodsList;
