import React from 'react';

// assets

// services
import ValidationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// props

// components
import { ActionButton, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { OfferedPriceFormData, OfferedPriceItemData } from '.';
import VendorsService from '../../../../../services/data/vendors';
import { IQuantityUnitResourceShortProps } from '../../../../../props/general/quantityUnit';
import { FormDataProps } from '..';
import containersType from '../../../../../manifests/containersType';
import trucksType from '../../../../../manifests/trucksType';

type OfferedPriceIncludedExcludedItemFormProps = {
    data: OfferedPriceFormData;
    title: string;
    onDismissed(): void;
    onSubmit(item: {name: string}): void;
}

type FormDataErrorProps = {
    name?: string;
}

const OfferedPriceIncludedExcludedItemForm: React.FC<OfferedPriceIncludedExcludedItemFormProps> = (props: OfferedPriceIncludedExcludedItemFormProps) => {
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [data, setData] = React.useState<{
        name: string;
    }>({
        name: ""
    });

    const isSubmitButtonDisabled = () => {
        if (error.name || (data.name || "").trim() === "") {
            return true;
        }

        return false;
    }

    return <Panel headerText={props.title}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={"Add"} disabled={isSubmitButtonDisabled()} onClick={() => props.onSubmit(data)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <TextField label={"Name"}
                value={data.name}
                required={true}
                onChange={(evt, value) => {
                    data.name = value || "";

                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                    error.name = validation.message;

                    if (!error.name) {
                        const exist = props.data.included.findIndex((inc) => inc.name.toLowerCase() === data.name.toLowerCase()) > -1;
                        if (exist) {
                            error.name = "Included item already exist"
                        }
                    }

                    setData({ ...data });
                    setError({ ...error });
                }}
                errorMessage={error.name} />
        </Stack>
    </Panel >
};

export default OfferedPriceIncludedExcludedItemForm;
