import Request from '../request';
import { IPublicClientResourceshort } from '../../props/users/client';

const ClientService = {
  retrieve: async (qs?: string): Promise<IPublicClientResourceshort[]> => {
    try {
      const results = await Request.get(`/api/clients?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicClientResourceshort> => {
    try {
      const results = await Request.get(`/api/clients/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<IPublicClientResourceshort> => {
    try {
      const result = await Request.post(`/api/clients`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IPublicClientResourceshort> => {
    try {
      const result = await Request.post(`/api/clients/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/clients/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

export default ClientService;
