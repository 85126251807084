import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';
import CompanyService from '../../../../services/users/company';
import GeneralService from '../../../../services/general';
import FreightCategoryService from '../../../../services/orders/freightCategory';
import DeliveryTypeService from '../../../../services/orders/deliveryType';
import OrderService from '../../../../services/orders/order';
import ValidationService from '../../../../services/validation';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';
import { IDeliveryTypeResourceShortProps } from '../../../../props/deliveryType';
import { IFreightCategoryResourceShortProps } from '../../../../props/freightCategories';
import { IUserResourceShortProps } from '../../../../props/users/user';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';

// components
import { ActionButton, DefaultButton, Dropdown, IColumn, IDropdownOption, NormalPeoplePicker, Panel, PanelType, Persona, PersonaSize, PrimaryButton, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../services/data/subsidiaries';
import ISalesTeamResourceShort from '../../../../props/users/team';
import TeamService from '../../../../services/users/team';
import { IOrderResourceProps } from '../../../../props/orders/order';
import SalesTeamService from '../../../../services/users/team';
import { IOrderResourceShortProps } from '../../../../props/order';

interface OrderSalesFormProps {
    order: IOrderResourceProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    id: string;
    sales?: IUserResourceShortProps;
    feePercentage: string;
    feeAmount: string;
    type: string;
}

type FormDataErrorProps = {
}

const OrderSalesForm: React.FC<OrderSalesFormProps> = (props: OrderSalesFormProps) => {
    const { banner, route } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [freightCategories, setFreightCategories] = React.useState<IFreightCategoryResourceShortProps[]>([]);
    const [deliveryTypes, setDeliveryTypes] = React.useState<IDeliveryTypeResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps[]>([]);
    const [error, setError] = React.useState<FormDataErrorProps>({});

    const columns: IColumn[] = [
        {
            key: "idx",
            name: "No.",
            minWidth: 30,
            maxWidth: 30,
            onRender: (item: FormDataProps, idx?: number) => {
                return <Stack styles={{ root: { padding: '16px 0px' } }}>
                    <Text>{(idx || 0) + 1}</Text>
                </Stack>
            }
        },
        {
            key: "sales",
            name: "Sales",
            minWidth: 180,
            onRender: (item: FormDataProps) => {
                return <>
                    {!item.sales ? <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                        <NormalPeoplePicker selectedItems={[]}
                            removeButtonAriaLabel='Remove'
                            itemLimit={1}
                            onItemSelected={(user: any) => {
                                const value = user ? user.metadata : undefined;
                                _onSalesChanged(item.id || "", value);
                                return null;
                            }}
                            onChange={(tags) => {
                                if (tags && tags.length < 1) {
                                    _onSalesChanged(item.id || "", undefined);
                                }
                            }}
                            onResolveSuggestions={_onSearchSales}
                            onEmptyResolveSuggestions={() => _onSearchSales('')}
                            disabled={submitting} />
                    </Stack> : null}
                    {item.sales ? <Stack styles={{ root: { padding: '16px 0px' } }}>
                        <Persona imageInitials={GeneralService.getInitial(item.sales.name)} text={item.sales.name} secondaryText={item.sales.email} size={PersonaSize.size24} />
                    </Stack> : null}
                </>
            }
        },
        {
            key: "type",
            name: "Type",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Dropdown selectedKey={item.type}
                        onChange={(evt, opt) => {
                            const value = opt ? opt.key as string : item.type;
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related) {
                                if (value === 'amount') {
                                    _related.feePercentage = "";
                                } else {
                                    _related.feeAmount = "";
                                }

                                _related.type = value;
                                setData([..._data]);
                            }
                        }}
                        options={[
                            { key: "percentage", text: "Percentage" },
                            { key: "amount", text: "Fixed Amount" }
                        ]}
                        styles={{root: {width: 150}}} />
                </Stack>
            }
        },
        {
            key: "feePercentage",
            name: "Fee (%)",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <TextField disabled={item.type === "amount"}
                        suffix={"%"}
                        value={item.feePercentage}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.feePercentage = value || "";
                                setData([..._data]);
                            }
                        }} />
                </Stack>
            }
        },
        {
            key: "feeAmount",
            name: "Fee (Amount)",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: FormDataProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <TextField disabled={item.type === "percentage"}
                        prefix={"Rp"}
                        value={item.feeAmount}
                        onChange={(evt, value) => {
                            const _data = data;
                            const _related = _data.find((d) => d.id === item.id);
                            if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                _related.feeAmount = value || "";
                                setData([..._data]);
                            }
                        }} />
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: FormDataProps) => {
                return <Stack.Item styles={{ root: { padding: '16px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        {item.sales ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                            const _data = data.filter((d) => d.id !== item.id);
                            setData([..._data]);
                        }} /> : null}
                    </Stack.Item>
                </Stack.Item>;
            }
        }
    ];

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setData([
            {
                id: GeneralService.guid(),
                feeAmount: "",
                feePercentage: "",
                type: "percentage"
            }
        ])
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        return false;
    }

    const _onSalesChanged = (id: string, item?: IUserResourceShortProps) => {
        const _data = data;
        const _related = _data.find((g) => g.id === id);
        if (_related) {
            _related.sales = item;
        }

        if (item) {
            _data.push({
                id: GeneralService.guid(),
                feeAmount: "",
                feePercentage: "",
                type: "percentage"
            })
        }
        setData([..._data]);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            // create form data
            let fd = new FormData();
            data.forEach((d) => {
                if (d.sales) {
                    const md = {
                        salesId: d.sales.id,
                        feeAmount: d.feeAmount !== "" ? d.feeAmount : "0",
                        feePercentage: d.feePercentage !== "" ? d.feePercentage : "0"
                    }

                    fd.append("sales[]", JSON.stringify(md));
                }
            });

            await OrderService.update(props.order.id, fd);

            banner.add({
                key: 'update_order_sales_success',
                variant: 'success',
                icon: faCheck,
                text: `Order sales updated successfully`
            });

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'update_order_sales_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to update order sales. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    const _onSearchSales = async (keyword: string) => {
        try {
            keyword = keyword.toLowerCase();
            const qs: string[] = [];
            qs.push(`search=${keyword}`);
            qs.push(`rolesGroup=backoffice`);

            const users = (await UserService.retrieve(qs.join("&"))).data;
            return users.filter((user, idx) => {
                return idx < 5;
            }).map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (error) {
            banner.add({
                key: "search_sales_error",
                text: "Failed to search related sales. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    return <Panel headerText={"Update Order Sales Team"}
        isOpen={true}
        type={PanelType.custom}
        customWidth={"1020px"}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating order sales team ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Stack>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={data}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={false}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderSalesForm;
