import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../../services/orders/order';

// props
import { IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { ActionButton, Checkbox, CommandBar, DatePicker, Dropdown, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TSelectedFilterKey } from '.';
import { IFreightCategoryResourceShortProps } from '../../../../../props/orders/freightCategory';

type OrdersDashboardChartFilterFreightCategoryProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: IFreightCategoryResourceShortProps[]): void;
}

const OrdersDashboardChartFilterFreightCategory: React.FC<OrdersDashboardChartFilterFreightCategoryProps> = (props: OrdersDashboardChartFilterFreightCategoryProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IFreightCategoryResourceShortProps[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniqueFreightCategories = (): IFreightCategoryResourceShortProps[] => {
        const freightCategories: IFreightCategoryResourceShortProps[] = [];

        props.orders.forEach((order) => {
            const exist = freightCategories.find((c) => c.id === order.freightCategory.id);
            if (!exist) {
                freightCategories.push(order.freightCategory);
            }
        });

        return freightCategories;
    }

    const renderOption = (freightCategory: IFreightCategoryResourceShortProps) => {
        const isChecked = selected.findIndex((s) => s.id === freightCategory.id) > -1;
        const totalItems = props.filteredOrders.filter((o) => o.freightCategory.id === freightCategory.id).length;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, freightCategory)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{freightCategory.name} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, freightCategory: IFreightCategoryResourceShortProps) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== freightCategory.id);
        } else {
            _selected.push(freightCategory);
        }

        setSelected([..._selected]);
        props.onChange('freightCategory', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Freight categories</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search freight ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueFreightCategories().filter((f) => f.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((freightCategory) => {
                return renderOption(freightCategory);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterFreightCategory;
