import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import TruckDetails from '../../../components/operationals/trucks/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface ITruckDetailsPageProps {
}

const TruckDetailsPage: React.FC<ITruckDetailsPageProps> = (props: ITruckDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Trucks", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "trucks", text: 'Trucks'}
        ]);
    }, []);

    return <Stack>
        <TruckDetails truckId={params.truckId} />
    </Stack>;
};

export default TruckDetailsPage;
