import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import { IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import { IPaginationResourceShort } from '../../../../../../props/general';
import Pagination from '../../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../../config';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import { NavLink } from 'react-router-dom';
import OrderService from '../../../../../../services/orders/order';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../services/general';
import Label from '../../../../../typography/label';
import IncomeInvoiceForm from '../form';
import PermissionsService from '../../../../../../services/permissions';
import IncomeInvoiceDetails from '../details';

interface IncomeInvoicesListProps {
    qs?: string[];
    orderId?: string;
    invoices?: IIncomeInvoiceResourceShortProps[];
    columns?: TIncomeInvoiceListCoumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TIncomeInvoiceListCoumn = 'invoiceNumber' | 'orderNumber' | 'status' | 'paid' | 'unpaid' | 'amount' | 'dueDate' | 'notes';

const IncomeInvoicesList: React.FC<IncomeInvoicesListProps> = (props: IncomeInvoicesListProps) => {
    const shownColumns: TIncomeInvoiceListCoumn[] = props.columns || [
        'invoiceNumber',
        'orderNumber',
        'amount',
        'paid',
        'unpaid',
        'status',
        'notes',
        'dueDate'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [invoices, setInvoices] = React.useState<IIncomeInvoiceResourceShortProps[]>(props.invoices || []);
    const [activeInvoice, setActiveInvoice] = React.useState<IOutcomeResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "invoiceNumber",
            name: "Invoice Number",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveInvoice(item);
                        setActiveSurface('details');
                    }}><Text>#{IncomeInvoiceService.getInvoiceNumber(item.id || "")}</Text></Link>
                </Stack>
            }
        },
        {
            key: "orderNumber",
            name: "Order Number",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={"/orders/" + item.order?.id}>
                        <Text>#{OrderService.getOrderNumber(item.order?.orderNumber || "")}</Text>
                    </NavLink>
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IIncomeInvoiceResourceShortProps) => {
                let statusVariant: TTagVariant = 'success';
                let statusText: string = 'Paid';
                if (item.unpaid > 0 && item.paid > 0) {
                    statusText = 'Partially Paid';
                    statusVariant = 'warning';
                } else if (item.unpaid > 0) {
                    statusText = 'Unpaid';
                    statusVariant = 'warning';
                }

                return <Stack horizontal>
                    <Tag variant={statusVariant} text={statusText} />
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IIncomeInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "dueDate",
            name: "Due Date",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IIncomeInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text className={item.unpaid > 0 ? 'color-red' : undefined}>{GeneralService.getFriendlyDateFormat(item.dueDate)}</Text>
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Amount",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IIncomeInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(item.amount)}</Text>
                </Stack>
            }
        },
        {
            key: "paid",
            name: "Paid",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IIncomeInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text className={'color-green'}>Rp. {GeneralService.getNumberWithSeparator(item.paid)}</Text>
                </Stack>
            }
        },
        {
            key: "unpaid",
            name: "Unpaid",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IIncomeInvoiceResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text className={'color-red'}>Rp. {GeneralService.getNumberWithSeparator(item.unpaid)}</Text>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.invoices) {
            _onRetrieveInvoices();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveInvoices = async (pageNumber?: number) => {
        try {
            setLoaded(false);

            const qs: string[] = props.qs || [];
            qs.push(`top=${GlobalConfig.defaultTop}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await IncomeInvoiceService.retrieve(qs.join("&"));
            setInvoices(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcomes_list_error',
                text: 'Failed to retrieve outcomes list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        const hasCreatePermission = PermissionsService.hasPermission(['incomes.invoices.create'], user.permissions);
        if (!props.hideCreateButton && hasCreatePermission) {
            farItems.push({
                key: "create",
                text: "Create Invoice",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Invoice"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveInvoice(undefined);

        if (refresh) { _onRetrieveInvoices() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && invoices.length > 0 ? <Pagination goToPage={_onRetrieveInvoices} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " invoice(s) found"} /> : null}
            <Stack className={styles[props.variant || 'card']}>
                {
                    !loaded || (loaded && invoices.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={invoices}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TIncomeInvoiceListCoumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && invoices.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                    <Text>Invoice(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && invoices.length > 0 ? <Pagination goToPage={_onRetrieveInvoices} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'details' && activeInvoice ? <IncomeInvoiceDetails invoiceId={activeInvoice.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'create' ? <IncomeInvoiceForm orderId={props.orderId} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default IncomeInvoicesList;
