import { DatePicker, Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import moment from 'moment';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import OutcomeInvoicesList from '../../../components/finances/outcomes/invoices/general/list';
import DetailsStack, { IStackSectionProps } from '../../../components/uiframeworks/detailsStack';
import ButtonCards from '../../../components/buttons/card';
import Heading from '../../../components/typography/heading';
import Label from '../../../components/typography/label';
import GeneralService from '../../../services/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome } from '@fortawesome/pro-light-svg-icons';
import OutcomesSummary from '../../../components/finances/outcomes/summary';

interface IOutcomeDashboardPageProps {
}

const OutcomeDashboardPage: React.FC<IOutcomeDashboardPageProps> = (props: IOutcomeDashboardPageProps) => {
    const { topbar } = useStore();
    const [selectedDate, setSelectedDate] = React.useState<{ start: string, end: string }>({
        start: moment().startOf('month').toISOString(),
        end: moment().endOf('month').toISOString()
    });

    useEffect(() => {
        topbar.show("Outcomes", [
            { key: "home", icon: faHome, href: "/" },
            { key: "finance", text: 'Finance', href: "/finance" },
            { key: "outcomes", text: 'Outcomes' }
        ])
    }, []);

    useEffect(() => {
        
    }, [selectedDate]);

    const getRightSection = (): IStackSectionProps => {
        return {
            key: 'details',
            isCard: false,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <ButtonCards buttons={[
                    {
                        variant: 'light',
                        key: 'invoices',
                        text: "Invoices",
                        link: "/finance/outcomes/invoices",
                        shadow: true
                    },
                    {
                        variant: 'light',
                        key: 'payments',
                        text: "Payments",
                        link: "/finance/outcomes/payments",
                        shadow: true
                    }
                ]} />
            </Stack>
        }
    }

    return <Stack>
        {/*<DetailsStack left={[getLeftSection()]} right={getRightSection()} />*/}
        <OutcomesSummary />
    </Stack>;
};

export default OutcomeDashboardPage;
