import React, { useEffect } from 'react';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Dropdown, IDropdownOption, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import RoleService from '../../../../../services/role';
import ValidationService from '../../../../../services/validation';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import SubsidiariesService from '../../../../../services/data/subsidiaries';
import ISalesTeamResourceShort from '../../../../../props/users/team';
import GeneralService from '../../../../../services/general';
import TeamService from '../../../../../services/users/team';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import Label from '../../../../typography/label';

interface ISubsidiariesFormProps {
    subsidiariesId?: string;
    onDismissed(refresh?: boolean, item?: ISubsidiariesResourceShort): void;
    team?: ISalesTeamResourceShort
}

type FormDataProps = {
    name: string;
    npwp: string;
    phoneNumber: string;
    address: string;
    colorCode: string;
}

const SubsidiariesForm: React.FC<ISubsidiariesFormProps> = (props: ISubsidiariesFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        npwp: "",
        phoneNumber: "",
        address: "",
        colorCode: '#E48826'
    });
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.subsidiariesId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.subsidiariesId) {
            const _subsidiaries = await SubsidiariesService.get(props.subsidiariesId);
            setData({
                name: _subsidiaries.name,
                npwp: _subsidiaries.npwp || '',
                phoneNumber: _subsidiaries.phoneNumber || "",
                address: _subsidiaries.address || '',
                colorCode: _subsidiaries.colorCode || '#E48826'
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name) {
            return true;
        } else if (data.name === '') {
            return true;
        }
        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, npwp, colorCode, address, phoneNumber } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("npwp", npwp);
            fd.append("phoneNumber", phoneNumber);
            fd.append("address", address);
            fd.append("colorCode", colorCode);

            let result;
            if (props.subsidiariesId === undefined) {
                result = await SubsidiariesService.new(fd);
            } else {
                result = await SubsidiariesService.update(props.subsidiariesId, fd);
            }

            banner.add({
                key: 'create_subsidiary_success',
                variant: 'success',
                icon: faCheck,
                text: `Subsidiary "${name}" created successfully`
            });
            
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }


    return <Panel headerText={mode === 'create' ? "Create Subsidiary" : "Update Subsidiary"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating subsidiary ..." : "Updating subsidiary ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField
                            label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Phone number"}
                            value={data.phoneNumber}
                            onChange={(evt, value) => {
                                data.phoneNumber = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.phoneNumber}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Address"}
                            value={data.address}
                            onChange={(evt, value) => {
                                data.address = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.address = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.address}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <Label size={'small'}>Legal</Label>
                    <Stack.Item>
                        <TextField
                            label={"NPWP"}
                            required={true}
                            value={data.npwp}
                            onChange={(evt, value) => {
                                data.npwp = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.npwp = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.npwp}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default SubsidiariesForm;
