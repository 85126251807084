import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GoodsService from '../../../../../services/goods/goods';

// props
import { IGoodsResourceShortProps } from '../../../../../props/goods/goods';

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import LoadingComponent from '../../../../feedbacks/loading';
import { IOrderContainerResourceCompleteProps, IOrderContainerResourceShortProps } from '../../../../../props/orders/containers';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import OrderContainerGoodsList from '../../goods/general/list';

interface IOrderContainerDetailsPropertiesProps {
    container: IOrderContainerResourceCompleteProps;
}

const OrderContainerDetailsProperties: React.FC<IOrderContainerDetailsPropertiesProps> = (props: IOrderContainerDetailsPropertiesProps) => {
    const { container } = props;
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const getSubtotal = () => {
        let subtotal = container.totalPrice;

        if (container.includePPN) {
            subtotal -= container.ppn;
        }
        if (container.includePPh) {
            subtotal -= container.pph;
        }

        return subtotal;
    }

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Container Number</Text>
                <Label size={'small'}>{container.containerNumber || "-"}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Size</Text>
                <Label size={'small'}>{container.size || "-"}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Seal Number</Text>
                <Label size={'small'}>{container.sealNumber || "-"}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>RO Number</Text>
                <Label size={'small'}>{container.roNumber || "-"}</Label>
            </Stack.Item>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Text size={"small"}>Subtotal (before tax)</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getSubtotal())}</Label>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Text size={"small"}>PPn</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(container.ppn || "0"))}</Label>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Text size={"small"}>PPh</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(container.pph || "0"))}</Label>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Text size={"small"}>Total (after tax)</Text>
                <Label size={'small'} className={'color-green'}>Rp. {GeneralService.getNumberWithSeparator(Number(container.totalPrice || "0"))}</Label>
            </Stack>
        </Stack>
        <Stack className='divider'></Stack>
        <Stack>
            <Label size={'small'}>Goods List</Label>
            <OrderContainerGoodsList orderId={props.container.orderId} containerId={props.container.id} goods={props.container.goods} />
        </Stack>
    </Stack>
};

export default OrderContainerDetailsProperties;
