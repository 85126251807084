import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import GlobalConfig from '../../../../../../../config';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../../services/general';
import ErrorService from '../../../../../../../services/general/error';


// props
import { IPaginationResourceShort } from '../../../../../../../props/general';


// components
import { ActionButton, CommandBar, DefaultButton, IColumn, ICommandBarItemProps, IContextualMenuItem, Label, Link, Panel, PanelType, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useStore } from '../../../../../../../stores/root';
import Text from '../../../../../../typography/text';
import Pagination from '../../../../../../uiframeworks/pagination';
import { NavLink } from 'react-router-dom';
import PermissionsService from '../../../../../../../services/permissions';
import Tag, { TTagVariant } from '../../../../../../uiframeworks/tag';
import EmployedPaymentForm from '../form';
import { IOutcomeSalaryPaymentResourceShortProps } from '../../../../../../../props/finance/outcomes/salaries';
import { OutcomeSalaryPaymentService } from '../../../../../../../services/finance/outcomes/salaries';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import ApproveInvoicePaymentForm from '../form/approvePayment';
import RejectInvoicePaymentForm from '../form/rejectPayment';
import RejectInvoicePaymentDetailsForm from '../form/details';

interface EmployeePaymentListPanelProps {
  employeeId: string;
  onDismissed(refresh?: boolean): void;
  salaryId: string
}

const EmployeePaymentListPanel: React.FC<EmployeePaymentListPanelProps> = (props: EmployeePaymentListPanelProps) => {
  const { banner, user } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showingMore, setShowingMore] = React.useState<boolean>(false);
  const [payments, setPayments] = React.useState<IOutcomeSalaryPaymentResourceShortProps[]>([]);
  const [activePayment, setActivePayment] = React.useState<IOutcomeSalaryPaymentResourceShortProps | undefined>();
  const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
  const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

  const columns: IColumn[] = [
    {
      key: "paymentMonth",
      name: "Paid/Rejected On",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Link onClick={() => {
            setActiveSurface('details')
            setActivePayment(item)
          }}>
            <Text>{moment(item.processedAt).format("DD MMMM YYYY")}</Text>
          </Link>
        </Stack.Item>;
      }
    },
    {
      key: "actions",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
        let menuItems: IContextualMenuItem[] = [
          {
            key: "update", text: "Approve", iconProps: { iconName: "Check" }, onClick: () => {
              setActivePayment(item);
              setActiveSurface('approve');
            }
          },
          {
            key: "resetPassword", text: "Reject", iconProps: { iconName: "XMark" }, onClick: () => {
              setActivePayment(item);
              setActiveSurface('reject');
            }
          }
        ];

        return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
          <Stack.Item className={"detailsListActionRow"}>
            <ActionButton className={'detailsListActionButton'} menuProps={{
              items: menuItems
            }} />
          </Stack.Item>
        </Stack.Item>;
      }
    },
    {
      key: "status",
      name: "Status",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
        let tagVariant: TTagVariant = 'active';
        let tagText: string = item.paymentStatus;

        if (item.paymentStatus.toLowerCase() === 'requested') {
          tagVariant = 'warning';
          tagText = 'Requested'
        } else if (item.paymentStatus.toLowerCase() === 'rejected') {
          tagVariant = 'error';
          tagText = 'Rejected'
        } else if (item.paymentStatus.toLowerCase() === 'approved') {
          tagText = 'Complete'
          tagVariant = 'success';
        }

        return <Stack horizontal styles={{ root: { padding: '2px 0px' } }}>
          <Tag variant={tagVariant} text={tagText} />
        </Stack>;
      }
    },
    // {
    //   key: "baseSalary",
    //   name: "Salary + Bonus",
    //   minWidth: 100,
    //   onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
    //     return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
    //       <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.salaryAmount) + Number(item.bonusAmount))}</Text>
    //     </Stack.Item>;
    //   }
    // },
    // {
    //   key: "insurance",
    //   name: "Insurance + Tax",
    //   minWidth: 150,
    //   maxWidth: 150,
    //   onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
    //     return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
    //       <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.healthInsuranceAmount) + Number(item.workingInsuranceAmount) + Number(item.taxAmount))}</Text>
    //     </Stack.Item>;
    //   }
    // },
    // {
    //   key: "allowance",
    //   name: "Total Allowance",
    //   minWidth: 150,
    //   maxWidth: 150,
    //   onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
    //     return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
    //       <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalAllowance))}</Text>
    //     </Stack.Item>;
    //   }
    // },
    // {
    //   key: "deduction",
    //   name: "Total Deduction",
    //   minWidth: 150,
    //   maxWidth: 150,
    //   onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
    //     return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
    //       <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalDeduction))}</Text>
    //     </Stack.Item>;
    //   }
    // },
    // {
    //   key: "commission",
    //   name: "Total Commission",
    //   minWidth: 150,
    //   maxWidth: 150,
    //   onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
    //     return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
    //       <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalCommission))}</Text>
    //     </Stack.Item>;
    //   }
    // },
    {
      key: "total",
      name: "Amount",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
        </Stack.Item>;
      }
    },
    {
      key: "method",
      name: "Method",
      minWidth: 200,
      maxWidth: 200,
      isMultiline: true,
      onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Stack>
            <Text>{PaymentMethods.find(f => f.key === item.paymentMethod)?.text}</Text>
            {item.paymentMethod === 'transfer' && (
              <Stack styles={{ root: { width: '50%' } }}>
                <Text>{`${item.targetBankAccount?.accountNumber} - ${item.targetBankAccount?.bankName} (${item.targetBankAccount?.bankCity})`}</Text>
              </Stack>
            )}
            {item.paymentMethod === 'virtualaccount' && (
              <Stack styles={{ root: { width: '50%' } }}>
                <Text>{`${item.targetVirtualAccontNumber}`}</Text>
              </Stack>
            )}
          </Stack>
        </Stack.Item>;
      }
    },
    {
      key: "notes",
      name: "Notes",
      minWidth: 200,
      maxWidth: 200,
      isMultiline: true,
      onRender: (item: IOutcomeSalaryPaymentResourceShortProps) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Text>{item.justification}</Text>
        </Stack.Item>;
      }
    },
  ];

  useEffect(() => {
    _onRetrievePayments();
  }, []);

  const _onRetrievePayments = async (pageNumber?: number,) => {
    try {
      setLoaded(false);
      const qs: string[] = [];
      qs.push(`top=${GlobalConfig.defaultTop}`);
      qs.push(`salaryId=${props.salaryId}`);
      if (pageNumber) { qs.push(`page=${pageNumber}`); }

      const results = await OutcomeSalaryPaymentService.retrieve(props.salaryId, qs.join("&"));
      setPayments(results.data);
      setPagination(results.pagination);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'retrieve_payments_error',
        text: 'Failed to retrieve payments list. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const getCommandBarItems = () => {
    let items: ICommandBarItemProps[] = [];
    let farItems: ICommandBarItemProps[] = [];

    if (PermissionsService.hasPermission(['superuser', 'finance', 'hr'], user.roles)) {
      items.push({
        key: 'createPayment',
        onRender: () => {
          return <PrimaryButton onClick={() => {
            setActiveSurface('create')
          }} text={'Request Payment'} iconProps={{ iconName: "Add" }} />
        }
      })
    }

    return { items, farItems };
  }

  const _onSurfaceDismissed = (refresh?: boolean) => {
    setActiveSurface(undefined);

    if (refresh) { _onRetrievePayments() }
  }

  return <Panel headerText={"Payments"}
    isOpen={true}
    type={PanelType.large}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
      </Stack>;
    }}>

    <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
      <CommandBar
        items={getCommandBarItems().items}
        farItems={getCommandBarItems().farItems}
        styles={{
          root: {
            padding: 0,
            height: 'unset',
            backgroundColor: 'transparent'
          }
        }}
        ariaLabel="Use left and right arrow keys to navigate between commands" />
      <Stack tokens={{ childrenGap: 10 }}>
        {pagination && payments.length > 0 ? <Pagination goToPage={_onRetrievePayments} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " payments(s) found"} /> : null}
        <Stack className={styles.list}>
          {
            !loaded || (loaded && payments.length > 0) ? (
              <>
                <ShimmeredDetailsList
                  setKey="items"
                  items={payments}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  enableShimmer={!loaded}
                  onShouldVirtualize={() => false}
                  ariaLabelForShimmer="Content is being fetched"
                  ariaLabelForGrid="Item details" />
              </>
            ) : null
          }
          {loaded && payments.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
            <Text size={'small'} style={{fontStyle: 'italic'}}>Payments not found</Text>
          </Stack> : null}
        </Stack>
        {pagination && payments.length > 0 ? <Pagination goToPage={_onRetrievePayments} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        {activeSurface === 'create' && <EmployedPaymentForm salaryId={props.salaryId} employeeId={props.employeeId} onDismissed={_onSurfaceDismissed} />}
        {activePayment && activeSurface === 'approve' && <ApproveInvoicePaymentForm salaryId={props.salaryId} onDismissed={_onSurfaceDismissed} paymentId={activePayment.id} />}
        {activePayment && activeSurface === 'reject' && <RejectInvoicePaymentForm salaryId={props.salaryId} onDismissed={_onSurfaceDismissed} paymentId={activePayment.id} />}
        {activePayment && activeSurface === 'details' && <RejectInvoicePaymentDetailsForm salaryId={props.salaryId} onDismissed={_onSurfaceDismissed} paymentId={activePayment.id} />}
      </Stack>
    </Stack>
  </Panel>
};

export default EmployeePaymentListPanel;
