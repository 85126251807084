const GlobalConfig = {
  apiHostname: "https://api.jejakgroup.com",
  publicDashboardHostname: "https://admin.jejakgroup.com",
  assetsHostname: "https://api.jejakgroup.com",
  /*apiHostname: 'http://localhost:8000',
  publicDashboardHostname: 'http://localhost:3006',
  assetsHostname: 'http://localhost:8000/storage',*/
  mapAPIKey: 'AIzaSyD1zku1HyO1m5PDoRRcn24zTGWR6TbaIfw',
  tinymceKey: 'jc7ioz7j4lbup161umtl8bumaimfmu6m1uw7iw4w4ghs8pca',
  defaultTop: 20,
  allowedFormats: {
    image: ['image/jpg', 'image/jpeg', 'image/png'],
  },
  maxFileSize: {
    image: 2500000,
  },
};

export default GlobalConfig;