import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { IColumn, Stack, IContextualMenuItem, ActionButton, ICommandBarItemProps, SearchBox, CommandBar, ShimmeredDetailsList, SelectionMode, Text } from '@fluentui/react';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import GlobalConfig from '../../../../../../config';
import { IPaginationResourceShort } from '../../../../../../props/general';
import { IUserResourceShortProps } from '../../../../../../props/user';
import ErrorService from '../../../../../../services/general/error';
import { useStore } from '../../../../../../stores/root';
import Pagination from '../../../../../uiframeworks/pagination';
import GeneralService from '../../../../../../services/general';
import { IEmployedContractResourceShort } from '../../../../../../props/users/user';
import { EmployeeContractService } from '../../../../../../services/users/employee';
import EmployedContractForm from '../form';
import moment from 'moment';
import DeleteContractForm from '../form/delete';
import PermissionsService from '../../../../../../services/permissions';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import InactivateContractForm from '../form/inactivate';
import ActivateContractForm from '../form/activate';

interface IContractListProps {
  employeeId: string;
  qs?: string[];
  hideCommandBar?: boolean;
  hideSearch?: boolean;
  hideCreateButton?: boolean;
  isHeaderVisible?: boolean;
  columns?: TContractsListColumn[];
}

type TContractsListColumn = 'contractNumber' | 'actions' | 'status' | 'date'

const ContractList: React.FC<IContractListProps> = (props: IContractListProps) => {
  const { banner, user } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showingMore, setShowingMore] = React.useState<boolean>(false);
  const [contracts, setContracts] = React.useState<IEmployedContractResourceShort[]>([]);
  const [activeContract, setActiveContract] = React.useState<IEmployedContractResourceShort | undefined>();
  const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
  const [keyword, setKeyword] = React.useState<string>("");
  const [activeSurface, setActiveSurface] = React.useState<'create' | 'update' | 'delete' | 'activate' | 'inactivate' | undefined>();

  const columns: IColumn[] = [
    {
      key: "contractNumber",
      fieldName: "contractNumber",
      name: "ContractNumber",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IEmployedContractResourceShort) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Text>{item.contractNumber}</Text>
        </Stack.Item>;
      }
    },
    {
      key: "actions",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: IEmployedContractResourceShort) => {
        let menuItems: IContextualMenuItem[] = [];

        if (PermissionsService.hasPermission(['superuser', 'hr'], user.roles)) {
          menuItems.push({
            key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
              setActiveContract(item);
              setActiveSurface('update');
            }
          });

          menuItems.push({
            key: "delete", text: "Delete", iconProps: { iconName: "Trash" }, onClick: () => {
              setActiveContract(item);
              setActiveSurface('delete');
            }
          })

          if (item.status.toLowerCase() === 'active') {
            menuItems.push({
              key: "inactivate", text: "Set as Inactive", iconProps: { iconName: "Cancel" }, onClick: () => {
                setActiveContract(item);
                setActiveSurface('inactivate');
              }
            })
          } else if (item.status.toLowerCase() === 'inactive' && moment().diff(moment(item.endDate)) < 0) {
            menuItems.push({
              key: "activate", text: "Set as Active", iconProps: { iconName: "CheckMark" }, onClick: () => {
                setActiveContract(item);
                setActiveSurface('activate');
              }
            })
          }
        }

        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Stack.Item className={"detailsListActionRow"}>
            <ActionButton className={'detailsListActionButton'} menuProps={{
              items: menuItems
            }} />
          </Stack.Item>
        </Stack.Item>;
      }
    },
    {
      key: "total",
      fieldName: "total",
      name: "Status",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: IEmployedContractResourceShort) => {
        let tagVariant: TTagVariant = 'active';
        let tagText: string = item.status;

        if (moment().diff(moment(item.endDate)) > 0) {
          tagVariant = 'error';
          tagText = 'Expired';
        } else if (item.status.toLowerCase() === 'active') {
          tagVariant = 'active';
        } else if (item.status.toLowerCase() === 'inactive') {
          tagVariant = 'error';
        }

        return <Stack horizontal styles={{ root: { padding: '2px 0px' } }}>
          <Tag variant={tagVariant} text={tagText} />
        </Stack>;
      }
    },
    {
      key: "date",
      fieldName: "date",
      name: "Active Date",
      minWidth: 200,
      onRender: (item: IEmployedContractResourceShort) => {
        return <Stack horizontal tokens={{childrenGap: 8}} styles={{ root: { padding: '4px 0px' } }}>
          <Text>{moment(item.startDate).format('DD/MM/YYYY')}</Text>
          <Text>-</Text>
          <Text>{moment(item.endDate).format('DD/MM/YYYY')}</Text>
        </Stack>;
      }
    }
  ];

  useEffect(() => {
    _onRetrieveContracts();
  }, [keyword]);

  const _onRetrieveContracts = async (pageNumber?: number,) => {
    try {
      setLoaded(false);
      const qs: string[] = props.qs || [];
      qs.push(`top=${GlobalConfig.defaultTop}`);
      if (pageNumber) { qs.push(`page=${pageNumber}`); }
      if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

      const results = await EmployeeContractService.retrieve(props.employeeId, qs.join('&'));
      setContracts(results.data);
      setPagination(results.pagination);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'contract_list_error',
        text: 'Failed to retrieve contract list. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const _onKeywordChanged = (value?: string) => {
    setLoaded(true);
    setKeyword(value || "");
  }

  const getCommandBarItems = () => {
    let items: ICommandBarItemProps[] = [];
    let farItems: ICommandBarItemProps[] = [];

    // items.push({
    //   key: "search",
    //   onRender: () => {
    //     return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
    //   }
    // });

    return { items, farItems };
  }

  const _onSurfaceDismissed = (refresh?: boolean) => {
    setActiveSurface(undefined);
    setActiveContract(undefined);

    if (refresh) { _onRetrieveContracts() }
  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    {!props.hideCommandBar ? <CommandBar
      items={getCommandBarItems().items}
      farItems={getCommandBarItems().farItems}
      styles={{
        root: {
          padding: 0,
          height: 'unset',
          backgroundColor: 'transparent'
        }
      }}
      ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
    <Stack tokens={{ childrenGap: 10 }}>
      {pagination && contracts.length > 0 ? <Pagination goToPage={_onRetrieveContracts} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " contract(s) found"} /> : null}
      <Stack className={styles.list}>
        {
          !loaded || (loaded && contracts.length > 0) ? (
            <>
              <ShimmeredDetailsList
                setKey="items"
                items={contracts}
                columns={PermissionsService.hasPermission(['superuser', 'hr'], user.roles) ? columns : columns.filter(f => f.key !== 'actions')}
                selectionMode={SelectionMode.none}
                enableShimmer={!loaded}
                shimmerLines={5}
                onShouldVirtualize={() => false}
                isHeaderVisible={props.isHeaderVisible}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details" />
            </>
          ) : null
        }
        {loaded && contracts.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
          <Text>Contract(s) not found</Text>
        </Stack> : null}
      </Stack>
      {pagination && contracts.length > 0 ? <Pagination goToPage={_onRetrieveContracts} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
    </Stack>
    {activeSurface === 'create' ? <EmployedContractForm onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'update' && activeContract ? <EmployedContractForm employeeId={props.employeeId} contractId={activeContract.id} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'delete' && activeContract ? <DeleteContractForm contract={activeContract} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'inactivate' && activeContract ? <InactivateContractForm contract={activeContract} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'activate' && activeContract ? <ActivateContractForm contract={activeContract} onDismissed={_onSurfaceDismissed} /> : null}
  </Stack>;
};

export default ContractList;
