import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { DefaultButton, Dropdown, IDropdownOption, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, Text, TextField } from '@fluentui/react';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import { useStore } from '../../../../../stores/root';
import SubsidiariesService from '../../../../../services/data/subsidiaries';

interface IDeleteSubsidiariesFormProps {
    subsidiaries: ISubsidiariesResourceShort;
    onDismissed(refresh?: boolean): void;
}

const DeleteSubsidiariesForm: React.FC<IDeleteSubsidiariesFormProps> = (props: IDeleteSubsidiariesFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await SubsidiariesService.delete(props.subsidiaries.id);

            banner.add({
                key: 'delete_subsidiaries_success',
                variant: 'success',
                icon: faCheck,
                text: `Subsidiary "${props.subsidiaries.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Subsidiary"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting subsidiary ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete subsidiary with details below?</Text>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.subsidiaries.name}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>NPWP</Label>
                <Text>{props.subsidiaries.npwp}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteSubsidiariesForm;
