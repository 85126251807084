import React, { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../../services/general/error';

// props
import Tag, { TTagVariant } from '../../../../../../uiframeworks/tag';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../../../typography/text';
import UserService from '../../../../../../../services/users/user';
import LoadingComponent from '../../../../../../feedbacks/loading';
import Label from '../../../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../../../uiframeworks/detailsStack';
import SalesCommissionList from '../../../../../salesComissions/general/list';
import PermissionsService from '../../../../../../../services/permissions';
import { IOutcomeSalaryPaymentResourceShortProps } from '../../../../../../../props/finance/outcomes/salaries';
import { OutcomeSalaryPaymentService } from '../../../../../../../services/finance/outcomes/salaries';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';

interface IEmployedPaymentDetailsProps {
  salaryId: string;
  payment?: IOutcomeSalaryPaymentResourceShortProps
  paymentId: string
}

const EmployedPaymentDetails: React.FC<IEmployedPaymentDetailsProps> = (props: IEmployedPaymentDetailsProps) => {
  const { banner, topbar, user, route } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<IOutcomeSalaryPaymentResourceShortProps>();
  const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

  useEffect(() => {
    _onGetPayment();
  }, []);

  const _onGetPayment = async () => {
    if (props.payment === undefined) {
      try {
        setLoaded(false);
        const _payment = await OutcomeSalaryPaymentService.read(props.salaryId, props.paymentId);
        setPayment(_payment);

        topbar.show(_payment.id, [
          { key: "home", icon: faHome, href: "/" },
          { key: "users", text: 'Users' },
          { key: "payments", text: 'Payments', href: "/users/payments" },
        ]);

        setLoaded(true);
      } catch (e) {
        banner.add({
          key: 'get_payment_error',
          text: 'Failed to get payment details. Error: ' + ErrorService.getMessage(e),
          icon: faXmarkCircle,
          variant: 'error'
        });
      }
    } else {
      setPayment(props.payment)
      setLoaded(true)
    }
  }

  const getCommandBarItems = () => {
    const items: ICommandBarItemProps[] = [];
    const farItems: ICommandBarItemProps[] = [];

    items.push({
      key: 'update',
      text: 'Update details',
      onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
    });

    items.push({
      key: 'reset_password',
      text: 'Reset Password',
      onRender: () => <ActionButton text={'Reset Password'} iconProps={{ iconName: "Permissions" }} onClick={() => setActiveSurface('resetPassword')} styles={{ root: { marginLeft: 20 } }} />
    });

    farItems.push({
      key: 'delete',
      text: 'Delete',
      onRender: () => <ActionButton text={'Delete'} iconProps={{ iconName: "Delete" }} styles={{ root: { marginLeft: 20 } }} onClick={() => setActiveSurface('delete')} />
    });

    return { items, farItems };
  }

  const renderStatus = () => {
    if (payment) {
      let tagVariant: TTagVariant = 'active';
      let tagText: string = payment.paymentStatus;

      if (payment.paymentStatus.toLowerCase() === 'rejected') {
        tagVariant = 'error';
        tagText = 'Rejected'
      } else if (payment.paymentStatus.toLowerCase() === 'approved') {
        tagText = 'Complete'
        tagVariant = 'success';
      } else {
        {
          tagVariant = 'warning';
          tagText = 'Requested'
        }
      }


      return (
        <Stack styles={{ root: { width: '50%' } }}>
          <Text>Status</Text>
          <Stack horizontal>
            <Tag variant={tagVariant} text={tagText} />
          </Stack>
        </Stack>
      )

    }
  }

  const getUserPropertiesSection = (_payment: IOutcomeSalaryPaymentResourceShortProps): IStackSectionProps => {
    return {
      header: {
        title: "Details"
      },
      key: 'user_details',
      isCard: true,
      content: <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'small'}>Method</Label>
            <Text>{PaymentMethods.find(p => p.key === _payment.paymentMethod)?.text || '-'}</Text>
          </Stack>
          {_payment.paymentMethod === 'transfer' && (
            <Stack styles={{ root: { width: '50%' } }}>
              <Label size={'small'}>Bank</Label>
              <Text>{`${_payment.targetBankAccount?.accountNumber} - ${_payment.targetBankAccount?.bankName} (${_payment.targetBankAccount?.bankCity})`}</Text>
            </Stack>
          )}
          {_payment.paymentMethod === 'virtualaccount' && (
            <Stack styles={{ root: { width: '50%' } }}>
              <Label size={'small'}>Virtual Account</Label>
              <Text>{`${_payment.targetVirtualAccontNumber}`}</Text>
            </Stack>
          )}
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'small'}>Status</Label>
            <Text>{_payment.paymentStatus}</Text>
          </Stack>
        </Stack>
      </Stack>
    }
  }

  const renderDetailsStack = (_payment: IOutcomeSalaryPaymentResourceShortProps) => {
    const leftSections = [];
    leftSections.push(getUserPropertiesSection(_payment));

    return <Stack className={styles.details} tokens={{ childrenGap: 20 }}>
      <DetailsStack left={leftSections} />
    </Stack>;
  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    {/* {!loaded ? <LoadingComponent label='Retrieving payment details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
    {loaded && payment ? <>
      {PermissionsService.hasPermission(['superuser', 'admin'], user.roles) ? <CommandBar
        items={getCommandBarItems().items}
        farItems={getCommandBarItems().farItems}
        styles={{
          root: {
            padding: 0,
            height: 'unset',
            backgroundColor: 'transparent'
          }
        }}
        ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
      {renderDetailsStack(payment)}
    </> : null} */}
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack styles={{ root: { width: '50%' } }}>
        <Label size={'small'}>Method</Label>
        <Text>{PaymentMethods.find(p => p.key === payment?.paymentMethod)?.text || '-'}</Text>
      </Stack>
      {payment?.paymentMethod === 'transfer' && (
        <Stack styles={{ root: { width: '50%' } }}>
          <Label size={'small'}>Bank</Label>
          <Text>{`${payment?.targetBankAccount?.accountNumber} - ${payment?.targetBankAccount?.bankName} (${payment?.targetBankAccount?.bankCity})`}</Text>
        </Stack>
      )}
      {payment?.paymentMethod === 'virtualaccount' && (
        <Stack styles={{ root: { width: '50%' } }}>
          <Label size={'small'}>Virtual Account</Label>
          <Text>{`${payment?.targetVirtualAccontNumber}`}</Text>
        </Stack>
      )}
    </Stack>
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      {renderStatus()}
      {/* <Stack styles={{ root: { width: '50%' } }}>
        <Label size={'small'}>Status</Label>
        <Text>{payment?.paymentStatus}</Text>
      </Stack> */}
    </Stack>
  </Stack>;
};

export default EmployedPaymentDetails;
