import Request from './request';

// import props
import {
    IUserResourceShortProps
} from './../props/user';
import { IPaginationResourceShort } from '../props/general';

export default class UserService {
    public retrieve = async (rolesGroup?: string, rolesKey?: string, roleId?: string, keyword?: string, status?: string, page?: number): Promise<{ data: IUserResourceShortProps[], pagination: IPaginationResourceShort }> => {
        try {
            let queries: string[] = [];
            if (rolesGroup && rolesGroup.trim() !== "") { queries.push(`rolesGroup=${rolesGroup}`); }
            if (rolesKey && rolesKey.trim() !== "") { queries.push(`rolesKey=${rolesKey}`); }
            if (roleId && roleId.trim() !== "") { queries.push(`roleId=${roleId}`); }
            if (keyword && keyword.trim() !== "") { queries.push(`keyword=${keyword}`); }
            if (status && status.trim() !== "") { queries.push(`status=${status}`); }
            if (page) { queries.push(`page=${page}`); }
            const results = await Request.get(`/api/users?${queries.join('&')}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }

    public get = async (id: string): Promise<IUserResourceShortProps> => {
        try {
            const results = await Request.get(`/api/users/${id}`);
            return results;
        } catch (e) {
            throw (e);
        }
    }

    public new = async (data: FormData): Promise<IUserResourceShortProps> => {
        try {
            const result = await Request.post(`/api/users`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    }

    public update = async (id: string, data: FormData): Promise<IUserResourceShortProps> => {
        try {
            const result = await Request.post(`/api/users/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    }

    public delete = async (id: string): Promise<void> => {
        try {
            await Request.delete(`/api/users/${id}`);
            return;
        } catch (e) {
            throw (e);
        }
    }

    public isVerified = async (): Promise<boolean> => {
        try {
            await Request.get(`/api/users/me/isVerified`);
            return true;
        } catch (e: any) {
            if (e.indexOf("not verified") > -1 || e.indexOf("403") > -1 || e.indexOf("401") > -1) {
                return false;
            }
            throw (e);
        }
    }

    public resetPassword = async (email: string): Promise<IUserResourceShortProps> => {
        try {
            const result = await Request.post(`/api/users/${email}/resetPassword`);
            return result;
        } catch (e) {
            throw (e);
        }
    }

    public updatePassword = async (data: FormData): Promise<IUserResourceShortProps> => {
        try {
            const result = await Request.post(`/api/password/update`, data);
            return result;
        } catch (e) {
            throw (e);
        }
    }

    public currentUser = async (): Promise<IUserResourceShortProps> => {
        try {
            const result = await Request.get(`/api/users/me`);
            return result;
        } catch (e) {
            throw (e);
        }
    }
}