import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import GlobalConfig from '../../../../../../config';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import ErrorService from '../../../../../../services/general/error';
import OutcomeSalaryService from '../../../../../../services/finance/outcomes/salaries';

// props
import { IPaginationResourceShort } from '../../../../../../props/general';
import { IOutcomeSalaryResourceShortProps } from '../../../../../../props/finance/outcomes/salaries';

// components
import { CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import Text from '../../../../../typography/text';
import Pagination from '../../../../../uiframeworks/pagination';
import { NavLink } from 'react-router-dom';
import PermissionsService from '../../../../../../services/permissions';

interface EmployeePayslipsListProps {
    employeeId: string;
}

const EmployeePayslipsList: React.FC<EmployeePayslipsListProps> = (props: EmployeePayslipsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [payslips, setPayslips] = React.useState<IOutcomeSalaryResourceShortProps[]>([]);
    const [activePayslip, setActivePayslip] = React.useState<IOutcomeSalaryResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "paymentMonth",
            name: "Payment Month",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/users/administrators/${props.employeeId}/payslips/${item.id}`}><Text>{moment(item.paymentMonth).format("MMMM YYYY")}</Text></NavLink>
                </Stack.Item>;
            }
        },
        {
            key: "baseSalary",
            name: "Salary + Bonus",
            minWidth: 100,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.salaryAmount) + Number(item.bonusAmount))}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "insurance",
            name: "Insurance + Tax",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.healthInsuranceAmount) + Number(item.workingInsuranceAmount) + Number(item.taxAmount))}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "allowance",
            name: "Total Allowance",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalAllowance))}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "deduction",
            name: "Total Deduction",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalDeduction))}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "commission",
            name: "Total Commission",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalCommission))}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "total",
            name: "Total Salary",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeSalaryResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalSalary))}</Text>
                </Stack.Item>;
            }
        },
    ];

    useEffect(() => {
        _onRetrievePayslips();
    }, []);

    const _onRetrievePayslips = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            const qs: string[] = [];
            qs.push(`top=${GlobalConfig.defaultTop}`);
            qs.push(`employee_id=${props.employeeId}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }

            const results = await OutcomeSalaryService.retrieve(qs.join("&"));
            setPayslips(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_payslips_error',
                text: 'Failed to retrieve payslips list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['superuser', 'finance', 'hr'], user.roles)) {
            items.push({
                key: 'createPayslip',
                onRender: () => {
                    return <NavLink to={`/hr/payroll/salaryslip?employeeId=${props.employeeId}`}>
                        <PrimaryButton text={'Create Payslip'} iconProps={{iconName: "Add"}} />
                    </NavLink>
                }
            })
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);

        if (refresh) { _onRetrievePayslips() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && payslips.length > 0 ? <Pagination goToPage={_onRetrievePayslips} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " payslip(s) found"} /> : null}
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && payslips.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={payslips}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && payslips.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Payslips not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && payslips.length > 0 ? <Pagination goToPage={_onRetrievePayslips} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
    </Stack>;
};

export default EmployeePayslipsList;
