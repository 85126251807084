import { Stack } from '@fluentui/react';
import React, { } from 'react';
import styles from './styles.module.scss';

// assets

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './../../typography/text';
import { NavLink } from 'react-router-dom';
import Label from '../../typography/label';

import NoPermissionVector from './../../../assets/vectors/no-permission.webp';

type NoPermissionProps = {}

const NoPermission: React.FC<NoPermissionProps> = (props: NoPermissionProps) => {
    
    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <img className={styles.image} src={NoPermissionVector} />
        <Stack>
            <Label size={'large'} className={styles.text}>No Permission</Label>
            <Text size={'small'} className={styles.text}>Sorry, you don't have permission to access this page.</Text>
        </Stack>
    </Stack>
};

export default NoPermission;
