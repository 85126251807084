import { IInternalBankAccountResourceProps, IInternalBankAccountResourceShortProps } from '../../../props/data/bankaccounts/internal';
import Request from './../../request';

// import props

const InternalBankAccountService = {
    retrieve: async (qs?: string): Promise<{data: IInternalBankAccountResourceShortProps[]}> => {
        try {
            const results = await Request.get(`/api/data/bankaccounts/internal?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IInternalBankAccountResourceProps> => {
        try {
            const result = await Request.post(`/api/data/bankaccounts/internal`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (accountId: string, data: FormData): Promise<IInternalBankAccountResourceProps> => {
        try {
            const result = await Request.post(`/api/data/bankaccounts/internal/${accountId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (id: string): Promise<IInternalBankAccountResourceShortProps> => {
        try {
            const result = await Request.get(`/api/data/bankaccounts/internal/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    }
};

export default InternalBankAccountService;