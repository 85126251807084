import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../services/validation';
import TrainScheduleService from '../../../../../../services/data/trains/trainScedule';
import GeneralService from '../../../../../../services/general';

// props

// components
import { DatePicker, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import moment from 'moment';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import { ITrainScheduleResourceShort } from '../../../../../../props/data/trains';

interface ITrainScheduleFormProps {
    trainId: string;
    scheduleId?: string;
    onDismissed(refresh?: boolean, schedule?: ITrainScheduleResourceShort): void;
}

type FormDataProps = {
    voy: string;
    loadingStation: string;
    unloadingStation: string;
    status: string;
    estimatedDeparture: string;
    estimatedArrival: string;
    actualDeparture?: string;
    actualArrival?: string;
}

type FormDataErrorProps = {
    voy?: string;
    unloadingStation?: string;
    loadingStation?: string;
}

const TrainScheduleForm: React.FC<ITrainScheduleFormProps> = (props: ITrainScheduleFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        voy: "",
        loadingStation: "",
        unloadingStation: "",
        status: "scheduled",
        estimatedDeparture: moment().toISOString(),
        estimatedArrival: moment().add(1, 'days').toISOString()
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.scheduleId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['trains.schedules.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['trains.schedules.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.scheduleId) {
            const _schedule = await TrainScheduleService.get(props.scheduleId);
            setData({
                voy: _schedule.voy,
                loadingStation: _schedule.originAddress,
                unloadingStation: _schedule.destinationAddress,
                status: _schedule.status || "scheduled",
                estimatedDeparture: _schedule.estimatedDeparture,
                estimatedArrival: _schedule.estimatedArrival,
                actualDeparture: _schedule.actualDeparture,
                actualArrival: _schedule.actualArrival
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.voy || error.loadingStation || error.unloadingStation) {
            return true;
        } else if (data.voy === '' || data.loadingStation === '' || data.unloadingStation === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { voy, loadingStation, unloadingStation, status, estimatedArrival, estimatedDeparture, actualArrival, actualDeparture } = data;

            // create form data
            let fd = new FormData();
            fd.append("trainId", props.trainId);
            fd.append("voy", voy);
            fd.append("status", status);
            fd.append("originAddress", loadingStation.toUpperCase());
            fd.append("destinationAddress", unloadingStation.toUpperCase());
            fd.append("estimatedArrival", estimatedArrival);
            fd.append("estimatedDeparture", estimatedDeparture);
            if (actualArrival) {
                fd.append("actualArrival", actualArrival);
            }
            if (actualDeparture) {
                fd.append("actualDeparture", actualDeparture);
            }

            let result: ITrainScheduleResourceShort | undefined;
            if (props.scheduleId === undefined) {
                result = await TrainScheduleService.new(fd);
            } else {
                result = await TrainScheduleService.update(props.scheduleId, fd);
            }
            banner.add({
                key: mode + '_train_schedule_success',
                variant: 'success',
                icon: faCheck,
                text: `Train schedule voy "${voy}" ${mode}ed successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Train Schedule' : 'Update Train Schedule'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating train schedule ..." : "Updating train schedule ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <TextField label={"Voy"}
                            required={true}
                            value={data.voy}
                            onChange={(evt, value) => {
                                data.voy = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.voy = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.voy}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Loading Station"}
                            required={true}
                            value={data.loadingStation}
                            multiline={true}
                            resizable={false}
                            autoAdjustHeight
                            rows={4}
                            onChange={(evt, value) => {
                                data.loadingStation = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.loadingStation = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.loadingStation}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Unloading Station"}
                            required={true}
                            value={data.unloadingStation}
                            multiline={true}
                            resizable={false}
                            autoAdjustHeight
                            rows={4}
                            onChange={(evt, value) => {
                                data.unloadingStation = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.unloadingStation = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.unloadingStation}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label='Estimated Departure'
                            value={moment(data.estimatedDeparture).toDate()}
                            onSelectDate={(date) => {
                                if (date) {
                                    data.estimatedDeparture = moment(date).toISOString();
                                    setData({ ...data });
                                }
                            }} formatDate={GeneralService.formatDate} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label='Estimated Arrival'
                            value={moment(data.estimatedArrival).toDate()}
                            onSelectDate={(date) => {
                                if (date) {
                                    data.estimatedArrival = moment(date).toISOString();
                                    setData({ ...data });
                                }
                            }} formatDate={GeneralService.formatDate} />
                    </Stack.Item>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default TrainScheduleForm;