import GeneralService from "./general";

interface IReturnProps {
    error: boolean;
    message?: string;
    code?: string;
}

export interface IAllowedProps {
    min?: number;
    max?: number;
    maxSize?: number;
    maxChars?: number;
    maxItems?: number;
    allowedFormats?: string[];
}

export type TValidationType = 'required' | 'email' | 'number' | 'file' | 'files' | 'phoneNumber' | 'limit';

const ValidationService = {
    combination: (value:any, validations:TValidationType[], props:IAllowedProps):IReturnProps => {
        let result:IReturnProps = {error: false};
        for (var ctr=0; ctr<validations.length; ctr++) {
            const validation = validations[ctr];
            if (validation === 'required') {
                result = ValidationService.required(value);
            } else if (validation === 'limit') {
                result = ValidationService.limit(value, (props.maxChars !== undefined || props.maxItems !== undefined ? (props.maxChars || props.maxItems || 255) : 255));
            } else if (validation === 'email') {
                result = ValidationService.email(value);
            } else if (validation === 'phoneNumber') {
                result = ValidationService.phoneNumber(value);
            } else if (validation === 'number') {
                result = ValidationService.number(value, props.min, props.max);
            } else if (validation === 'file') {
                result = ValidationService.file(value, props.maxSize, props.allowedFormats);
            } else if (validation === 'files') {
                result = ValidationService.files(value, props.maxSize, props.allowedFormats);
            }

            if (result && result.error) {
                break;
            }
        }
        return result;
    },
    required: (value?:any):IReturnProps => {
        if (!value) {
            return {error: true, message: "This field is required.", code: 'required'};
        } else if (value && typeof(value) === 'string' && value.trim() === '') {
            return {error: true, message: "This field is required.", code: 'required'};
        } else if (value && Array.isArray(value) && value.length < 1) {
            return {error: true, message: "This field is required.", code: 'required'};
        }

        return {error: false};
    },
    limit: (value:string, max:number) => {
        if (Array.isArray(value)) {
            if (value.length > max) {
                return {error: true, message: `Exceeding number of items limit (${value.length} out of ${max} max. items)`, code: 'required'};
            }
        } else if (value && value.trim() && value.length > max) {
            return {error: true, message: `Exceeding number of characters limit (${value.trim().length} out of ${max} max. characters)`, code: 'required'};
        }

        return {error: false};
    },
    email: (email:string):IReturnProps => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!regex.test(email)) {
            return {error: true, message: "Email is invalid.", code: 'invalid'};
        }
        return {error: false};
    },
    phoneNumber: (phoneNumber:string):IReturnProps => {
        const regex = /^[0-9]{0,15}$/g;
        if (!regex.test(phoneNumber)) {
            return {error: true, message: "Phone number is invalid.", code: 'invalid'};
        }
        return {error: false};
    },
    number: (number:string, min?:number, max?:number):IReturnProps => {
        if (number !== "") {
            const regex = /^\d+(.\d+)*$/;
            if (!regex.test(number)) {
                return {error: true, message: "This field can only contains number.", code: 'invalid'};
            } else if (min && max && (parseFloat(number) < min || parseFloat(number) > max)) {
                return {error: true, message: `Value can only between ${GeneralService.getNumberWithSeparator(min)} and ${GeneralService.getNumberWithSeparator(max)}`, code: 'exceedLimit'};
            } else if (min && parseInt(number) < min) {
                return {error: true, message: `Value can not be less than ${GeneralService.getNumberWithSeparator(min)}`, code: 'exceedLimit'};
            } else if (max && parseInt(number) > max) {
                return {error: true, message: `Value can not be more than ${GeneralService.getNumberWithSeparator(max)}`, code: 'exceedLimit'};
            }
        }
        return {error: false};
    },
    file: (file:any, maxSize?:number, allowedFormats?:string[]):IReturnProps => {
        if (file && maxSize && file.size > maxSize) {
            return {error: true, message: `File could not exceed maximum allowed size: ${maxSize/1000000}MB`, code: 'exceedLimit'};
        } else if (file && allowedFormats) {
            allowedFormats = allowedFormats.map((format) => {return format.toLowerCase();});
            if (allowedFormats.indexOf(file.type.toLowerCase()) < 0) {
                return {error: true, message: `File format is not supported. Supported format(s) are ${allowedFormats.join(', ')}`, code: 'invalid'};
            }
        }
        return {error: false};
    },
    files: (files:any, maxSize?:number, allowedFormats?:string[]):IReturnProps => {
        let message:IReturnProps = {error: false};
        files = Array.from(files)

        for (var ctr=0; ctr<files.length; ctr++) {
            const file = files[ctr];
            if (maxSize && file.size > maxSize) {
                message = {error: true, message: `File "${file.name}" exceed maximum allowed size: ${maxSize/1000000}MB`, code: 'exceedLimit'};
                break;
            } else if (allowedFormats) {
                allowedFormats = allowedFormats.map((format) => {return format.toLowerCase();});
                if (allowedFormats.indexOf(file.type.toLowerCase()) < 0) {
                    message = {error: true, message: `File "${file.name}" format is not supported. Supported format(s) are ${allowedFormats.join(', ')}`, code: 'invalid'};
                    break;
                }
            }
        }
        return message;
    }
};

export default ValidationService;