import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import ClientService from '../../../../../services/users/clients';
import ImageUpload from '../../../../uploadImage';
import GlobalConfig from '../../../../../config';

interface IClientFormProps {
    clientId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    image_url: string
}

type FormDataErrorProps = {
    name?: string;
}

const ClientForm: React.FC<IClientFormProps> = (props: IClientFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        image_url: ''
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.clientId === undefined ? 'create' : 'update';
    const [imageData, setImageData] = useState<File>()

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.clientId) {
            const _client = await ClientService.get(props.clientId);
            setData({
                name: _client.name,
                image_url: _client.image_url
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name) {
            return true;
        } else if (data.name.trim() === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            // setSubmitting(true);
            const { name } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            imageData && fd.append('image', imageData)

            if (props.clientId === undefined) {
                await ClientService.new(fd);
            } else {
                await ClientService.update(props.clientId, fd);
            }
            banner.add({
                key: 'create_client_success',
                variant: 'success',
                icon: faCheck,
                text: `Client "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }



    return <Panel headerText={mode === 'create' ? 'Create Client' : 'Update Client'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating client ..." : "Updating client ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? (
                    <>
                        <Stack.Item>
                            <ImageUpload
                                previewImageUrl={data.image_url.trim() !== '' ? data.image_url : undefined}
                                onSelectedFile={(file) => setImageData(file)}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label={"Name"}
                                required={true}
                                value={data.name}
                                onChange={(evt, value) => {
                                    data.name = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.name = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.name}
                                disabled={submitting}
                            />
                        </Stack.Item>
                    </>
                ) : null
            }
        </Stack>
    </Panel>
};

export default ClientForm;
