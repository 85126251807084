import Request from './../request';

// import props
import {
    IFreightCategoryResourceShortProps
} from './../../props/orders/freightCategory';
import { IPaginationResourceShort } from '../../props/general';

const FreightCategoryService = {
    retrieve: async ():Promise<IFreightCategoryResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/freightCategories`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    get: async (id: string):Promise<IFreightCategoryResourceShortProps> => {
        try {
            const results = await Request.get(`/api/freightCategories/${id}`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    new: async (data:FormData):Promise<IFreightCategoryResourceShortProps> => {
        try {
            const result = await Request.post(`/api/freightCategories`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id:string, data:FormData):Promise<IFreightCategoryResourceShortProps> => {
        try {
            const result = await Request.post(`/api/freightCategories/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/freightCategories/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
}

export default FreightCategoryService;