import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../../stores/root';
import { useParams } from 'react-router-dom';
import ShipScheduleDetails from '../../../../components/operationals/ships/schedules/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IShipScheduleDetailsPageProps {
}

const ShipScheduleDetailsPage: React.FC<IShipScheduleDetailsPageProps> = (props: IShipScheduleDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Ships", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "ships", text: 'Ships'}
        ]);
    }, []);

    return <Stack>
        <ShipScheduleDetails scheduleId={params.scheduleId} />
    </Stack>;
};

export default ShipScheduleDetailsPage;
