import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, IContextualMenuItem, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import { IOutcomeResourceShorterProps, IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import moment from 'moment';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Bar, BarChart, Tooltip } from 'recharts';
import Tag from '../../../../../uiframeworks/tag';
import OutcomesInvoiceList from '../../../invoices/general/list';

type OutcomeInvoiceGroupingChartProps = {
    invoices: IOutcomeResourceShortProps[];
}

const OutcomeInvoiceGroupingChart: React.FC<OutcomeInvoiceGroupingChartProps> = (props: OutcomeInvoiceGroupingChartProps) => {
    const { banner } = useStore();
    const [groupBy, setGroupBy] = React.useState<string>('Vendor');
    const [invoices, setInvoices] = React.useState<IOutcomeResourceShortProps[]>(props.invoices);

    useEffect(() => {
        setInvoices(props.invoices);
    }, [props.invoices]);

    const groupData = () => {
        const data: {key: string; label: string; amount: number}[] = [];

        props.invoices.forEach((invoice) => {
            let key: string | undefined;
            let label: string | undefined;
            let amount = Number(invoice.totalAmount || '0');

            if (groupBy === 'Vendor') {
                key = invoice.vendor?.id;
                label = invoice.vendor?.name;
            } else if (groupBy === 'Category') {
                key = invoice.category?.id;
                label = invoice.category?.name;
            } else if (groupBy === 'Transfer from') {
                key = invoice.bankAccount?.id;
                label = invoice.bankAccount?.accountName;
            }

            const idx = data.findIndex((d) => d.key === (key || 'sys_empty'));
            if (idx > -1) {
                data[idx].amount += amount;
            } else if (key && label) {
                data.push({key, label, amount});
            } else {
                data.push({ key: 'sys_empty', label: '<Empty>', amount})
            }
        })

        return data.sort((a, b) => a.amount - b.amount).reverse();
    }

    return <Stack tokens={{childrenGap: 5}}>
        <Stack horizontal tokens={{childrenGap: 5}} verticalAlign={'center'}>
            <Text>Group by</Text>
            <Label size={'small'}>{groupBy}</Label>
            <ActionButton menuProps={{items: [
                {key: 'Vendor', text: 'Vendor', onClick: () => setGroupBy('Vendor')},
                {key: 'Category', text: 'Category', onClick: () => setGroupBy('Category')},
                {key: 'Transfer from', text: 'Transfer from', onClick: () => setGroupBy('Transfer from')}
            ]}} />
        </Stack>
        <Stack className={styles.card} tokens={{childrenGap: 10}}>
            {groupData().map((d, idx) => {
                return <>
                    {idx > 0 ? <Stack className={'divider'}></Stack> : null}
                    <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'baseline'} horizontalAlign={'space-between'}>
                        <Text size={'small'}>{d.label}</Text>
                        <Stack styles={{root: {width: 100}}} horizontalAlign={'end'}>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(d.amount)}</Text>
                        </Stack>
                    </Stack>
                </>
            })}
        </Stack>
    </Stack>
};

export default OutcomeInvoiceGroupingChart;
