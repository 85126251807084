import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';
import { useParams } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { Stack } from '@fluentui/react';
import EmployeebankAccountDetails from '../../../../components/users/administrators/bankAccounts/general/details';

type TypeBankAccountDetailsPageProps = {
  children?: React.ReactNode;
};

const BankAccountDetailsPage: React.FC<TypeBankAccountDetailsPageProps> = (props) => {
  const { topbar } = useStore();
  const params: any = useParams();
  const employeeId = params.employeeId
  const bankAccountId = params.bankAccountId

  useEffect(() => {
    topbar.show("Bank Accounts", [
      { key: "home", icon: faHome, href: "/" },
      { key: "users", text: 'Users' },
      { key: "bankAccounts", text: 'Bank Accounts' }
    ]);
  }, []);

  return (
    <Stack>
      <EmployeebankAccountDetails bankAccountId={bankAccountId} employeeId={employeeId} />
    </Stack>
  )
};

export default BankAccountDetailsPage;