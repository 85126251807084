import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ContactService from '../../../../../services/users/contact';
import ValidationService from '../../../../../services/validation';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import ImageUpload from '../../../../uploadImage';
import GlobalConfig from '../../../../../config';

interface IContactFormProps {
    contactId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    alias: string;
    phone_number: string;
    order_number: string;
    is_featured: string;
    profile_picture_url: string
}

type FormDataErrorProps = {
    name?: string;
    alias?: string;
    phone_number?: string;
    profile_picture_url?: string
}

const ContactForm: React.FC<IContactFormProps> = (props: IContactFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        alias: "",
        phone_number: "",
        order_number: "",
        is_featured: "",
        profile_picture_url: ''
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.contactId === undefined ? 'create' : 'update';
    const [imageData, setImageData] = useState<File>()

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.contactId) {
            const _contact = await ContactService.get(props.contactId);
            setData({
                name: _contact.name,
                alias: _contact.alias || "",
                phone_number: _contact.phone_number || "",
                order_number: String(_contact.order_number),
                is_featured: String(_contact.is_featured),
                profile_picture_url: _contact.profile_picture_url || ''
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.alias || error.phone_number) {
            return true;
        } else if (data.name.trim() === '' || data.alias.trim() === '' || data.phone_number.trim() === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, alias, phone_number, order_number, is_featured } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("alias", alias);
            fd.append("phone_number", phone_number);
            fd.append("order_number", order_number || '0');
            fd.append("is_featured", is_featured || '0');
            imageData && fd.append('image', imageData)

            if (props.contactId === undefined) {
                await ContactService.new(fd);
            } else {
                await ContactService.update(props.contactId, fd);
            }
            banner.add({
                key: 'create_contact_success',
                variant: 'success',
                icon: faCheck,
                text: `Contact "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Contact' : 'Update Contact'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating contact ..." : "Updating contact ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <ImageUpload
                            previewImageUrl={data.profile_picture_url.trim() !== '' ? data.profile_picture_url : undefined}
                            onSelectedFile={(file) => setImageData(file)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Alias"}
                            required={true}
                            value={data.alias}
                            resizable={false}
                            onChange={(evt, value) => {
                                data.alias = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.alias = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.alias}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Phone Number"}
                            required={true}
                            value={data.phone_number}
                            onChange={(evt, value) => {
                                if ((value || "").trim() !== "" && !isNaN(Number(value))) {
                                    data.phone_number = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.phone_number = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.phone_number}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Order Number"}
                            required={true}
                            value={data.order_number}
                            type='number'
                            onChange={(evt, value) => {
                                data.order_number = value || "";
                                setData({ ...data });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle checked={data.is_featured === '1' ? true : false} label="Is Featured" onChange={(event, checked) => {
                            if (checked) {
                                data.is_featured = '1'
                            } else {
                                data.is_featured = '0'
                            }
                            setData({ ...data });
                        }} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default ContactForm;
