import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, IDropdownOption, Link, Stack, TagPicker } from '@fluentui/react';
import { IVendorResourceShortProps } from '../../../../props/data/vendors';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import VendorsService from '../../../../services/data/vendors';
import VendorForm from '../../../operationals/vendors/general/form';
import PermissionsService from '../../../../services/permissions';
import { useStore } from '../../../../stores/root';

type VendorFieldProps = {
    selected?: IVendorResourceShortProps;
    options?: IVendorResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    disabled?: boolean;
    required?: boolean;
    allowCreate?: boolean;
    errorMessage?: string;
    onChange(selected?: IVendorResourceShortProps): void;
}

const VendorField: React.FC<VendorFieldProps> = (props: VendorFieldProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IVendorResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    const _onSearchVendor = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                const options = (await VendorsService.retrieve(qs.join("&"))).data.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Vendor"}</Label>}
        {!selected ? <>
            <TagPicker
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onRenderSuggestionsItem={(props: any) => {
                    const md = props.metadata as IVendorResourceShortProps;

                    return <Stack styles={{ root: { padding: 5 }}} horizontalAlign='baseline'>
                        <Label size={'small'}>{md.name}</Label>
                        {(md.npwp || "").trim() !== "" ? <Text size={'small'}>{md.npwp}</Text> : null}
                    </Stack>;
                }}
                onResolveSuggestions={_onSearchVendor}
                onEmptyResolveSuggestions={() => _onSearchVendor('')}
            />
            {props.allowCreate && PermissionsService.hasPermission(['vendors.create'], user.permissions) ? <Text size={'small'}>Cannot find vendor? <Link onClick={() => {setActiveSurface('create')}}>Click here</Link> to create vendor</Text> : null}
        </> : null}
        {props.errorMessage ? <Text style={{ marginTop: 3 }} size={'xsmall'} className={'color-red'}>{props.errorMessage}</Text> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack>
                <Label size={'small'}>{selected.name}</Label>
                {(selected.npwp || "").trim() !== "" ? <Text size={'small'}>{selected.npwp}</Text> : null}
                {selected.phoneNumber ? <Text size={'small'}>{selected.phoneNumber}</Text> : null}
                {selected.address ? <Text size={'small'}>{selected.address}</Text> : null}
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
        {activeSurface === 'create' ? <VendorForm onDismissed={(refresh, vendor) => {
            setActiveSurface(undefined);
            props.onChange(vendor);
        }} /> : null}
    </Stack>;
};

export default VendorField;
