import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import CompanyService from '../../../../services/users/company';
import PermissionsService from '../../../../services/permissions';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';

// components
import { ActionButton, Link, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import CompanyForm from '../../../users/companies/general/form';

type CompanyFieldProps = {
    selected?: ICompanyResourceShort;
    options?: ICompanyResourceShort[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: ICompanyResourceShort): void;
}

const CompanyField: React.FC<CompanyFieldProps> = (props: CompanyFieldProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<ICompanyResourceShort | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    const _onSearchCompany = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                const options = (await CompanyService.retrieve(qs.join("&"))).data.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Company"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onResolveSuggestions={_onSearchCompany}
                onEmptyResolveSuggestions={() => _onSearchCompany('')}
            />
            {props.allowCreate && PermissionsService.hasPermission(['companies.create'], user.permissions) ? <Text size={'small'}>Cannot find company? <Link onClick={() => {setActiveSurface('create')}}>Click here</Link> to create ship</Text> : null}
        </> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack>
                <Label size={'small'}>{selected.name}</Label>
                {selected.phoneNumber ? <Text size={'small'}>{selected.phoneNumber}</Text> : null}
                {!selected.phoneNumber ? <Text style={{fontStyle: 'italic'}} size={'small'}>Phone number not available</Text> : null}
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
        {activeSurface === 'create' ? <CompanyForm onDismissed={(refresh, ship) => {
            setActiveSurface(undefined);
            props.onChange(ship);
        }} /> : null}
    </Stack>;
};

export default CompanyField;
