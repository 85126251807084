const Translations = {
    generic: {
        "point": "koma",
        "january": "january",
        "february": "february",
        "march": "maret",
        "april": "april",
        "may": "mei",
        "june": "juni",
        "july": "juli",
        "august": "agustus",
        "september": "september",
        "october": "oktober",
        "november": "november",
        "december": "desember",
        "zero": "nol",
        "one": "satu",
        "two": "dua",
        "three": "tiga",
        "four": "empat",
        "five": "lima",
        "six": "enam",
        "seven": "tujuh",
        "eight": "delapan",
        "nine": "sembilan",
        "ten": "sepuluh",
        "eleven": "sebelah",
        "twelve": "dua belas",
        "thirteen": "tiga belas",
        "fourteen": "empat belas",
        "fifteen": "limat belas",
        "sixteen": "enam belas",
        "seventeen": "tujuh belas",
        "eighteen": "delapan belas",
        "nineteen": "sembilan belas",
        "twenty": "dua puluh",
        "thirty": "tiga puluh",
        "fourty": "empat puluh",
        "forty": "empat puluh",
        "fifty": "lima puluh",
        "sixty": "enam puluh",
        "seventy": "tujuh puluh",
        "eighty": "delapan puluh",
        "ninety": "sembilan puluh",
        "hundred": "ratus",
        "hundreds": "ratus",
        "thousand": "ribu",
        "thousands": "ribu",
        "million": "juta",
        "millions": 'juta',
        "billion": "miliar",
        "billions": "miliar"
    },
    special: {
        "one hundred": "seratus",
        "one thousand": "seribu",
    }
};

export default Translations;