import Request from './../../request';

// import props
import { IDocumentProps, IPaginationResourceShort } from '../../../props/general';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../props/data/ships';

const ShipsService = {
  retrieve: async (qs?: string): Promise<IShipResourceShortProps[]> => {
    const queries: string[] = [];
    try {
      const results = await Request.get(`/api/operationals/ships?${qs}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IShipResourceShortProps> => {
    try {
      const item = await Request.get(`/api/operationals/ships/${id}`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  getSchedules: async (shipId: string): Promise<IShipScheduleResourceShort[]> => {
    try {
      const item = await Request.get(`/api/operationals/ships/${shipId}/schedules`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<IShipResourceShortProps> => {
    try {
      const result = await Request.post(`/api/operationals/ships`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IShipResourceShortProps> => {
    try {
      const result = await Request.post(`/api/operationals/ships/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/operationals/ships/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  documents: {
    retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
      try {
        const results = await Request.get(`/api/operationals/ships/${id}/documents?${qs || ""}`);
        return results;
      } catch (e) {
        throw (e);
      }
    },
    new: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/ships/${id}/documents`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw (e);
      }
    },
    delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/ships/${id}/delete_documents`, data);
        return result;
      } catch (e) {
        throw (e);
      }
    }
  },
};

export default ShipsService;
