import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import ComingSoon from '../../../components/uiframeworks/comingSoon';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import OutcomeInvoiceCreateForm from '../../../components/finances/outcomes/invoices/general/form/create';

type OutcomeInvoiceCreatePageProps = {
}

const OutcomeInvoiceCreatePage: React.FC<OutcomeInvoiceCreatePageProps> = (props: OutcomeInvoiceCreatePageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Create Invoice", [
            {key: "home", icon: faHome, href: "/"},
            {key: "finance", text: 'Finance', href: "/finance"},
            {key: "invoices", text: 'Invoices', href: "/finance/outcomes/invoices"},
            {key: "create", text: 'Create'}
        ])
    }, []);

    return <Stack>
        <OutcomeInvoiceCreateForm />
    </Stack>;
};

export default OutcomeInvoiceCreatePage;
