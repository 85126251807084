import React, { useEffect, useState } from 'react';
import { useStore } from '../../../stores/root';
import InternalDocument from '../../../components/operationals/documents/documents/general/list';
import { useHistory, useLocation } from 'react-router-dom';
import { Stack } from '@fluentui/react';
import useFolders from '../../../components/hooks/getFolders';

interface IInternalDocumentPageProps { }

const InternalDocumentPage: React.FC<IInternalDocumentPageProps> = (
    props: IInternalDocumentPageProps
) => {
    const { topbar } = useStore();
    const folders = useFolders()

    useEffect(() => {
        setTopbar();
        return () => { }
    }, [folders]);

    function setTopbar() {
        if (folders) {
            const breadcrumbs = [
                { key: 'home', text: 'Home', href: '/' },
                { key: 'operationals', text: 'Operationals' },
                { key: 'documents', text: 'Documents', href: '/operationals/documents' },
                ...folders.map((f, i) => {
                    const filter = folders.filter((f, ix) => {
                        const index = folders.findIndex(a => a === f);
                        return i >= index
                    });
                    const href = `/operationals/documents/${filter.join('/')}`;
                    return ({
                        key: `f${i}`,
                        text: f,
                        href: href
                    })
                })
            ];

            topbar.show('Internal Document', breadcrumbs);
        }
    }


    return (
        <Stack>
            <InternalDocument />
        </Stack>
    );
};

export default InternalDocumentPage;
