import Request from './../request';

const OpenAIService = {
  extractDocument: async (data: FormData): Promise<any> => {
    try {
        const response = await Request.post(`/api/systems/openai/extract_document`, data, 'multipart/form-data');
        let result: any = response;
  
        return result;
    } catch (e) {
      throw e;
    }
  }
}

export default OpenAIService;