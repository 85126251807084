import { TTagVariant } from "../components/uiframeworks/tag";

type ShipScheduleStatusesKeyType = 'scheduled' | 'sailing' | 'arrived' | 'cancelled';
type TrainScheduleStatusesKeyType = 'scheduled' | 'in progress' | 'arrived' | 'cancelled';

type StatusType = {
  key: ShipScheduleStatusesKeyType | TrainScheduleStatusesKeyType;
  text: string;
  type: TTagVariant;
};

const shipScheduleStatuses: StatusType[] = [
  { key: 'scheduled', text: 'Scheduled', type: 'default' },
  { key: 'sailing', text: 'Sailing', type: 'warning' },
  { key: 'arrived', text: 'Arrived', type: 'success' },
  { key: 'cancelled', text: 'Cancelled', type: 'error' },
];

const trainScheduleStatuses: StatusType[] = [
  { key: 'scheduled', text: 'Scheduled', type: 'default' },
  { key: 'in progress', text: 'In Progress', type: 'warning' },
  { key: 'arrived', text: 'Arrived', type: 'success' },
  { key: 'cancelled', text: 'Cancelled', type: 'error' },
];

export { shipScheduleStatuses, trainScheduleStatuses };
export type { StatusType, ShipScheduleStatusesKeyType, TrainScheduleStatusesKeyType };
