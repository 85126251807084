/*** src/Store.js ***/

import { makeObservable, observable, action } from "mobx";
import RootStore from './root';
import { ReactNode } from "react";
import { IBreadcrumbItemProps } from "../components/navigations/breadcrumb";

export class TopbarStore {
    private rootStore: RootStore;
    public hidden: boolean = false;
    public title: string | null = null;
    public breadcrumb: IBreadcrumbItemProps[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            hidden: observable,
            title: observable,
            breadcrumb: observable,
            show: action.bound,
            hide: action.bound,
            setTitle: action.bound,
            setBreadcrumb: action.bound
        })
    }

    public hide = () => {
        this.title = null;
        this.breadcrumb = [];
        this.hidden = true;
    }

    public show = (title: string | null, breadcrumb: IBreadcrumbItemProps[]) => {
        this.title = title;
        this.breadcrumb = breadcrumb;
        this.hidden = false;
    }

    public setTitle = (title: string | null) => {
        this.title = title;
    }

    public setBreadcrumb = (breadcrumb: IBreadcrumbItemProps[]) => {
        this.breadcrumb = breadcrumb;
    }
}