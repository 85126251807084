import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import ErrorService from '../../../../services/general/error';

// props
import { IPaginationResourceShort } from '../../../../props/general';

// components
import { CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from './../../../typography/text';
import GeneralService from '../../../../services/general';
import { FilterOptionProps } from '../../../uiframeworks/filters/panel';
import SelectedFilter from '../../../uiframeworks/filters/selected';
import Pagination from '../../../uiframeworks/pagination';
import GlobalConfig from '../../../../config';
import { IOfferResourceProps } from '../../../../props/offers';
import OfferService from '../../../../services/offers';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import Label from '../../../typography/label';
import moment from 'moment';
import PivotButton from '../../../uiframeworks/buttons/pivot';
import PermissionsService from '../../../../services/permissions';
import RequestOfferForm from './../request';
import OrderService from '../../../../services/orders/order';
import NoAccess from '../../../uiframeworks/noAccess';

interface OffersListProps {
    qs?: string[];
    offers?: IOfferResourceProps[];
    columns?: TOfferColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateOffer?: boolean;
    hideFilter?: boolean;
    //filters?: TFilterBy[];
    variant?: 'card' | 'plain';
}

export type TOfferColumn = 'offerNumber' | 'actions' | 'createdAt' | 'customer' | 'sales' | 'details' | 'totalCost' | 'totalPrice' | 'profit' | 'status';

const OffersList: React.FC<OffersListProps> = (props: OffersListProps) => {
    const shownColumns: TOfferColumn[] = props.columns || [
        'offerNumber',
        'actions',
        'customer',
        'createdAt',
        'details',
        'totalPrice',
        'profit',
        'status'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [offers, setOffers] = React.useState<IOfferResourceProps[]>(props.offers || []);
    const [activeOffer, setActiveOffer] = React.useState<IOfferResourceProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterOptionProps[]>([]);
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selectedStatus, setSelectedStatus] = React.useState<string>('new');

    const hasPermission = PermissionsService.hasPermission(['offers.read.all', 'offers.read.me'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "offerNumber",
            name: "Offer Number",
            fieldName: "offerNumber",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOfferResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/offers/${item.id}/view`}><Text>#{OfferService.getOfferNumber(item.offerNumber)}</Text></NavLink>
                </Stack>
            }
        },
        {
            key: "customer",
            name: "Customer",
            fieldName: "customer",
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            onRender: (item: IOfferResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/users/customers/${item.customer.id}`}>
                        <Text>{item.customer.name}</Text>
                    </NavLink>
                    {item.company ? <NavLink to={`/users/companies/${item.company.id}`}>
                        <Text size={'small'}>({item.company.name})</Text>
                    </NavLink> : null}
                </Stack>
            }
        },
        {
            key: "createdAt",
            name: "Created at",
            fieldName: "createdAt",
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            onRender: (item: IOfferResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
                </Stack>
            }
        },
        {
            key: "details",
            name: "Details",
            fieldName: "details",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOfferResourceProps) => {
                const origin = OrderService.getAddress(item.originAddresses[0].type, item.originAddresses[0].address, item.originAddresses[0].city, item.originAddresses[0].province);
                const destination = OrderService.getAddress(item.destinationAddresses[0].type, item.destinationAddresses[0].address, item.destinationAddresses[0].city, item.destinationAddresses[0].province);

                const goodsType = DeliveredGoodsType.find((gt) => gt.key === item.goodsType);

                return <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: '4px 0px' } }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack styles={{ root: { width: 75, minWidth: 75 } }} horizontal horizontalAlign={'space-between'}>
                            <Label size={'xsmall'}>Sales</Label>
                            <Label size={'xsmall'}>:</Label>
                        </Stack>
                        {item.sales ? <NavLink to={`/users/administrators/${item.sales.id}/offers`}>
                            <Text size={'small'}>{item.sales.name}</Text>
                        </NavLink> : null}
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Text>{item.freightCategory.name} - {item.deliveryType.name} - {goodsType?.text}</Text>
                    <Stack className={'divider'}></Stack>
                    <Stack>
                        <Label size={'small'}>Pick-up Address:</Label>
                        {item.originAddresses.length > 1 ? <ol style={{ padding: '0px 12px', margin: 0 }}>
                            {item.originAddresses.map((address) => {
                                const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                return <Text size={'small'}><li>{text}</li></Text>
                            })}
                        </ol> : null}
                        {item.originAddresses.length < 2 && item.originAddresses.length > 0 ? <Text size={'small'}>{origin}</Text> : null}
                    </Stack>
                    <Stack>
                        <Label size={'small'}>Delivery Address:</Label>
                        {item.destinationAddresses.length > 1 ? <ol style={{ padding: '0px 12px', margin: 0 }}>
                            {item.destinationAddresses.map((address) => {
                                const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                return <Text size={'small'}><li>{text}</li></Text>
                            })}
                        </ol> : null}
                        {item.destinationAddresses.length < 2 && item.destinationAddresses.length > 0 ? <Text size={'small'}>{destination}</Text> : null}
                    </Stack>
                </Stack>
            }
        },
        {
            key: "totalCost",
            name: "Total Cost",
            fieldName: "totalCost",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOfferResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalCost))}</Text>
                </Stack>
            }
        },
        {
            key: "totalPrice",
            name: "Offered Price",
            fieldName: "totalPrice",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOfferResourceProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Text>
                </Stack>
            }
        },
        {
            key: "profit",
            name: "Profit",
            fieldName: "profit",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOfferResourceProps) => {
                let tagVariant: TTagVariant = 'success';
                if (item.profitPercentage <= 0) {
                    tagVariant = 'error';
                } else if (item.profitPercentage < 1.5) {
                    tagVariant = 'warning';
                }

                return <Stack horizontal tokens={{ childrenGap: 3 }}>
                    <Tag variant={tagVariant} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(item.profitAmount))}`} />
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOfferResourceProps) => {
                let tagVariant: TTagVariant = 'active'
                let tagText: string = 'Requested';
                if (item.status === 'approved') {
                    tagVariant = 'success';
                    tagText = 'Approved';
                } else if (item.status === 'rejected') {
                    tagVariant = 'error';
                    tagText = 'Rejected';
                } else if (item.status === 'converted') {
                    tagVariant = 'primary';
                    tagText = 'Order Created';
                } else if (item.status === 'waitingapproval') {
                    tagVariant = 'active';
                    tagText = 'Waiting Approval';
                } else if (item.status === 'cancelled') {
                    tagVariant = 'error';
                    tagText = 'Cancelled';
                }

                return <Stack horizontal tokens={{ childrenGap: 3 }}>
                    <Tag variant={tagVariant} text={tagText} />
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.offers && hasPermission) {
            _onRetrieveOffers();
        } else {
            setLoaded(true);
        }
    }, [keyword, selectedFilter, selectedStatus]);

    const getQueryString = (pageNumber?: number) => {
        const qs: string[] = props.qs || [];
        qs.push(`top=${GlobalConfig.defaultTop}`);
        qs.push(`status=${selectedStatus}`);
        if (pageNumber) { qs.push(`page=${pageNumber}`); }
        if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

        const groupedFilter: { key: string, options: FilterOptionProps[] }[] = [];
        selectedFilter.map((sel) => {
            const gfidx = groupedFilter.findIndex((gf) => gf.key === sel.filterby);
            if (gfidx > -1) {
                groupedFilter[gfidx].options.push(sel);
            } else {
                groupedFilter.push({
                    key: sel.filterby,
                    options: [sel]
                });
            }
        });

        groupedFilter.forEach((gf) => {
            qs.push(`${gf.key}=${gf.options.map((opt) => opt.key).join(';')}`);
        });

        return qs.join("&");
    }

    const _onRetrieveOffers = async (pageNumber?: number) => {
        try {
            setLoaded(false);
            const qs = getQueryString(pageNumber);
            const results = await OfferService.retrieve(qs);
            setOffers(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_offers_list_error',
                text: 'Failed to retrieve offers list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch && hasPermission) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} styles={{ root: { marginRight: 10 } }} />
                }
            });
        }

        if (!props.hideFilter) {
            {/*items.push({
                key: "filter",
                onRender: () => {
                    return <Stack styles={{ root: { marginRight: 10 } }}>
                        <OrderListFilterButton selected={selectedFilter}
                            filters={props.filters}
                            onChange={(selected) => {
                                setSelectedFilter([...selected]);
                            }} />
                    </Stack>
                }
            });*/}
        }

        if (!props.hideCreateOffer) {
            if (PermissionsService.hasPermission(['offers.create'], user.permissions)) {
                farItems.push({
                    key: "register",
                    text: "Create Offer",
                    iconProps: { iconName: "Add" },
                    onRender: () => {
                        return <NavLink to={'/offers/create'}>
                            <PrimaryButton text={"Create Offer"}
                                iconProps={{ iconName: "Add" }}
                                styles={{ root: { marginLeft: 10 } }} />
                        </NavLink>
                    }
                });
            }

            if (PermissionsService.hasPermission(['offers.request'], user.permissions)) {
                farItems.push({
                    key: "request",
                    text: "Request Offer",
                    iconProps: { iconName: "Add" },
                    onRender: () => {
                        return <PrimaryButton text={"Request Offer"}
                            iconProps={{ iconName: "Add" }}
                            styles={{ root: { marginLeft: 10 } }}
                            onClick={() => {
                                setActiveSurface('request');
                            }} />
                    }
                });
            }
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveOffer(undefined);

        if (refresh) { _onRetrieveOffers() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        {selectedFilter.length > 0 ? <SelectedFilter selected={selectedFilter} onChange={(selected) => {
            setSelectedFilter(selected);
        }} /> : null}
        {hasPermission ? <Stack tokens={{ childrenGap: 10 }}>
            {pagination && offers.length > 0 ? <Pagination goToPage={_onRetrieveOffers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " order(s) found"} /> : null}
            <PivotButton buttons={[
                {
                    key: "requested",
                    text: "Requested",
                    active: selectedStatus === 'new',
                    onClick: () => { setSelectedStatus('new'); }
                },
                {
                    key: "waitingApproval",
                    text: "Waiting Approval",
                    active: selectedStatus === 'waitingapproval',
                    onClick: () => { setSelectedStatus('waitingapproval'); }
                },
                {
                    key: "approved",
                    text: "Approved",
                    active: selectedStatus === 'approved',
                    onClick: () => { setSelectedStatus('approved'); }
                },
                {
                    key: "rejected",
                    text: "Rejected",
                    active: selectedStatus === 'rejected',
                    onClick: () => { setSelectedStatus('rejected'); }
                },
                {
                    key: "orderCreated",
                    text: "Order Created",
                    active: selectedStatus === 'converted',
                    onClick: () => { setSelectedStatus('converted'); }
                },
                {
                    key: "cancelled",
                    text: "Cancelled",
                    active: selectedStatus === 'cancelled',
                    onClick: () => { setSelectedStatus('cancelled'); }
                }
            ]} />
            <Stack className={styles[props.variant || 'card']}>
                {
                    !loaded || (loaded && offers.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={offers}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TOfferColumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && offers.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Offer(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && offers.length > 0 ? <Pagination goToPage={_onRetrieveOffers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack> : <NoAccess />}
        {activeSurface === 'request' ? <RequestOfferForm onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OffersList;
