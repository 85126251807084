import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';
import CompanyService from '../../../../services/users/company';

// props
import { IUserResourceShortProps } from '../../../../props/users/user';

// components
import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import RoleService from '../../../../services/role';
import ValidationService from '../../../../services/validation';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import Tag from '../../../uiframeworks/tag';

interface IDeleteUserFormProps {
    user: IUserResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteUserForm: React.FC<IDeleteUserFormProps> = (props: IDeleteUserFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await UserService.delete(props.user.id);

            banner.add({
                key: 'delete_user_success',
                variant: 'success',
                icon: faCheck,
                text: `User "${props.user.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete User"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting user ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete user with details below?</Text>
            <Stack.Item>
                <Label>Email Address</Label>
                <Text>{props.user.email}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.user.name}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Address</Label>
                <Text>{props.user.address || "-"}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Phone Number</Label>
                <Text>{props.user.phoneNumber || "-"}</Text>
            </Stack.Item>
            {props.user.roles && props.user.roles.length > 0 ? <Stack.Item>
                <Label>Roles</Label>
                <Stack horizontal tokens={{childrenGap: 10}} wrap>
                    {props.user.roles.map((role) => <Tag text={role.name} />)}
                </Stack>
            </Stack.Item> : null}
            {props.user.companies && props.user.companies.length > 0 ? <Stack.Item>
                <Label>Related Companies</Label>
                <Stack horizontal tokens={{childrenGap: 10}} wrap>
                    {props.user.companies.map((company) => <Tag text={company.name} />)}
                </Stack>
            </Stack.Item> : null}
        </Stack>
    </Panel>
};

export default DeleteUserForm;
