import Request, { IRequestFilterProps } from './../request';

// import props
import { IOrderInquiryResourceShortProps } from './../../props/orders/orderInquiry';
import { IPaginationResourceShort } from '../../props/general';
import { IWarehouseResourceProps, IWarehouseResourceShortProps } from '../../props/warehouses/warehouse';

const WarehouseService = {
    create: async (data:FormData):Promise<IWarehouseResourceProps> => {
        try {
            const result = await Request.post(`/api/warehouses`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id: string, data: FormData):Promise<IWarehouseResourceProps> => {
        try {
            const result = await Request.post(`/api/warehouses/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    get: async (id: string):Promise<IWarehouseResourceProps> => {
        try {
            const result = await Request.get(`/api/warehouses/${id}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    retrieve: {
        get: async (qs?: string):Promise<IWarehouseResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/warehouses?${qs || ""}`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        getPaged: async (qs?: string):Promise<{data: IWarehouseResourceShortProps[], pagination: IPaginationResourceShort}> => {
            try {
                const results = await Request.get(`/api/warehouses?${qs || ""}`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/warehouses/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
};

export default WarehouseService;