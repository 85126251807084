import React, { useEffect, useState } from 'react';
import { DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../../../../../../stores/root';
import ValidationService from '../../../../../../../services/validation';
import { IUserResourceShortProps } from '../../../../../../../props/user';
import GeneralService from '../../../../../../../services/general';
import authService from '../../../../../../../services/users/user';
import ErrorService from '../../../../../../../services/general/error';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserService from '../../../../../../../services/users/user';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import { EmployeeBankAccountService } from '../../../../../../../services/users/bankAccount';
import { IEmployedBankAccountResourceShort } from '../../../../../../../props/users/user';
import { OutcomeSalaryPaymentService } from '../../../../../../../services/finance/outcomes/salaries';

interface IEmployedPaymentFormProps {
  employeeId: string;
  onDismissed(refresh?: boolean): void;
  salaryId: string;
}

type FormDataProps = {
  amount: string
  notes?: string
  paymentMethod?: string
  bankAccountId?: string
  targetVirtualAccontNumber?: string
  date?: Date
}

type FormDataErrorProps = {
  amount?: string
  notes?: string
  targetVirtualAccontNumber?: string
}

const EmployedPaymentForm: React.FC<IEmployedPaymentFormProps> = (props: IEmployedPaymentFormProps) => {
  const { banner } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [employee, setEmployee] = React.useState<IUserResourceShortProps | undefined>();
  const [data, setData] = React.useState<FormDataProps>({
    amount: '',
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});
  const [bankAccounts, setBankAccounts] = useState<IEmployedBankAccountResourceShort[]>([])
  const mode: 'create' | 'update' = props.salaryId === undefined ? 'create' : 'update';

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    getBankAccounts()

  }

  const isSubmitButtonDisabled = (): boolean => {
    const { amount, paymentMethod, date } = data
    const { amount: errorAmount } = error
    if (amount.trim() === '' || paymentMethod === undefined || date === undefined) {
      return true
    }
    if (errorAmount !== undefined) {
      return true
    }
    return false;
  }

  const _onSubmit = async () => {
    setSubmitting(true);
    const { amount, paymentMethod, bankAccountId, notes, date, targetVirtualAccontNumber } = data;
    try {

      const fd = new FormData();

      fd.append('employeeId', props.employeeId)
      fd.append('amount', amount)
      paymentMethod && fd.append('paymentMethod', paymentMethod)
      notes && fd.append('justification', notes)
      bankAccountId && fd.append('targetBankAccountId', bankAccountId)
      targetVirtualAccontNumber && fd.append('targetVirtualAccontNumber', targetVirtualAccontNumber)
      props.salaryId && fd.append('outcomeSalaryId', props.salaryId)
      date && fd.append('paymentMonth', moment(date).toISOString())

      await OutcomeSalaryPaymentService.create(fd, props.salaryId);
      if (props.salaryId) {
      } else {
        // await OutcomeSalaryPaymentService.(props.employeeId, props.salaryId, fd);
      }
      banner.add({
        key: 'create_employee_salaries_success',
        variant: 'success',
        icon: faCheck,
        text: `Request Payment for ${employee?.name} added successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  const getBankAccounts = async () => {
    try {
      const items = (await EmployeeBankAccountService.retrieve(props.employeeId)).data
      setBankAccounts(items)
      setLoaded(true);

    } catch (error) {

    }
  }

  return <Panel headerText={mode === 'create' ? "Add Request Payment" : "Update Request Payment"}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Adding commission ..." : "Updating commission ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
      {
        loaded ? <>
          <Stack.Item>
            <TextField
              required
              label={"Amount"}
              type='number'
              prefix='RP'
              value={data.amount}
              onChange={(evt, value) => {
                data.amount = value || "";
                const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 20 });
                error.amount = validation.message;
                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.amount}
              disabled={submitting} />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <Stack styles={{ root: { width: data.paymentMethod === 'virtualaccount' || data.paymentMethod === 'transfer' ? '50%' : '100%' } }}>
                <Dropdown selectedKey={data.paymentMethod} onChange={(e, opt, i) => { setData({ ...data, paymentMethod: opt?.key as string || '', targetVirtualAccontNumber: "", bankAccountId: '' }) }} options={PaymentMethods} label='Payment Method' />
              </Stack>
              {data.paymentMethod === 'transfer' && <Stack styles={{ root: { width: '50%' } }}>
                <Dropdown selectedKey={data.bankAccountId} onChange={(e, opt, i) => { setData({ ...data, bankAccountId: opt?.key as string || '' }) }} options={bankAccounts.map(ba => ({ key: ba.id, text: `${ba.accountNumber} - ${ba.bankName} (${ba.bankCity})` }))} label='Bank Account' />
              </Stack>}
              {data.paymentMethod === 'virtualaccount' && <Stack styles={{ root: { width: '50%' } }}>
                <TextField
                  required
                  label={"Virtual Account Number"}
                  type='number'
                  value={data.targetVirtualAccontNumber}
                  onChange={(evt, value) => {
                    data.targetVirtualAccontNumber = value || "";
                    const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 20 });
                    error.targetVirtualAccontNumber = validation.message;
                    setData({ ...data });
                    setError({ ...error });
                  }}
                  errorMessage={error.targetVirtualAccontNumber}
                  disabled={submitting} />
              </Stack>}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <DatePicker value={data.date} label='Due Date' onSelectDate={date => {
              if (date) {
                setData({ ...data, date })
              }
            }} formatDate={date => moment(date).format('DD/MM/YYYY')} />
          </Stack.Item>
          <Stack.Item>
            <TextField
              required
              label={"Notes"}
              value={data.notes}
              multiline
              rows={3}
              onChange={(evt, value) => {
                data.notes = value || "";
                const validation = ValidationService.combination(value, ['limit'], { maxChars: 2000 });
                error.notes = validation.message;
                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.notes}
              disabled={submitting} />
          </Stack.Item>
        </> : null
      }
    </Stack>
  </Panel>
};

export default EmployedPaymentForm;
