import Request from './../request';

// import props
import { IOrderGoodsResourceShortProps, IOrderGoodsResourceProps } from '../../props/orders/orderGoods';
import { IPaginationResourceShort } from '../../props/general';

const OrderGoodsService = {
    retrieve: {
        get: async (orderId: string, qs?: string):Promise<IOrderGoodsResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/orders/${orderId}/goods?${qs || ""}`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
        getPaged: async (orderId: string, qs?: string):Promise<{data: IOrderGoodsResourceShortProps[], pagination: IPaginationResourceShort}> => {
            try {
                const results = await Request.get(`/api/orders/${orderId}/goods?paged=1&${qs || ""}`);
                return results;
            } catch(e) {
                throw(e);
            }
        },
    },
    create: async (orderId: string, data: FormData): Promise<IOrderGoodsResourceProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/goods`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (orderId: string, id: string, data: FormData):Promise<IOrderGoodsResourceProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/goods/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    get: async (orderId: string, id: string):Promise<IOrderGoodsResourceProps> => {
        try {
            const result = await Request.get(`/api/orders/${orderId}/goods/${id}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (orderId: string, id: string):Promise<void> => {
        try {
            await Request.delete(`/api/orders/${orderId}/goods/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
}

export default OrderGoodsService;