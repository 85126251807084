import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import { IOutcomeResourceShorterProps } from '../../../../../../props/finance/outcomes';
import moment from 'moment';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Bar, BarChart, Tooltip } from 'recharts';
import Tag from '../../../../../uiframeworks/tag';

type OutcomeInvoiceSummaryChartProps = {
    invoices?: IOutcomeResourceShorterProps[];
    groupBy: string;
}

const OutcomeInvoiceSummaryChart: React.FC<OutcomeInvoiceSummaryChartProps> = (props: OutcomeInvoiceSummaryChartProps) => {
    const { banner } = useStore();
    const [data, setData] = React.useState<{
        name: string;
        paid: number;
        unpaid: number;
        pending: number;
        totalInvoices: number;
    }[]>([]);

    useEffect(() => {
        setData(groupData());
    }, [props.invoices]);

    const groupData = () => {
        const data: {
            name: string;
            paid: number;
            unpaid: number;
            pending: number;
            totalInvoices: number;
        }[] = [];

        let monthly: { key: string, invoices: IOutcomeResourceShorterProps[] }[] = [];

        // group all orders based on month
        (props.invoices || []).forEach((invoice) => {
            let date: string | undefined = undefined;
            if (props.groupBy === 'invoiceDate') {
                date = invoice.invoiceDate;
            } else if (props.groupBy === 'paymentDate') {
                date = invoice.paymentDate;
            } else if (props.groupBy === 'createdAt') {
                date = invoice.createdAt;
            }

            if (date) {
                const invoiceMonth = moment(date).format("YYYYMM");
                const idx = monthly.findIndex((m) => m.key === invoiceMonth);
                if (idx < 0) {
                    monthly.push({
                        key: invoiceMonth,
                        invoices: [invoice]
                    });
                } else if (monthly[idx]) {
                    monthly[idx].invoices.push(invoice);
                }
            }
        });

        // sort group based on month
        monthly = monthly.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
        monthly.forEach((month) => {
            const name = moment(month.key + '', 'YYYYMM').format("MMM YYYY").toUpperCase();
            let _data = { name, paid: 0, unpaid: 0, pending: 0, totalInvoices: month.invoices.length };
            month.invoices.forEach((invoice) => {
                _data.paid += Number(invoice.paid || "0");
                _data.unpaid += Number(invoice.unpaid || "0");
                _data.pending += Number(invoice.pendingPayment || "0");
            });

            data.push(_data);
        });

        return data;
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        return <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='center'>
            {payload.map((entry: any, index: number) => {
                let text = 'Paid';
                let variant = '#218d21';

                if (entry.value === 'paid') {
                    text = 'Paid';
                    variant = '#218d21';
                } else if (entry.value === 'unpaid') {
                    text = 'Unpaid';
                    variant = '#ae383e';
                } else if (entry.value === 'pending') {
                    text = 'Pending';
                    variant = '#d79e1a';
                }

                return <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Stack.Item className={styles.indicator} styles={{ root: { backgroundColor: variant } }}> </Stack.Item>
                    <Text size={'small'}>{text}</Text>
                </Stack>
            })}
        </Stack>;
    }

    const renderXAxisTick = (props: any) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={12} y={-4} dy={16} textAnchor="end" fill="#666" fontSize={12}>
                    {payload.value}
                </text>
            </g>
        );
    }

    const renderYAxisTick = (props: any) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={-12} dy={16} textAnchor="end" fill="#666" fontSize={12}>
                    {payload.value < 750000000 ? `Rp. ${GeneralService.getNumberWithSeparator(payload.value / 1000000)} jt` : null}
                    {payload.value >= 750000000 ? `Rp. ${GeneralService.getNumberWithSeparator(payload.value / 1000000000)} m` : null}
                    {payload.value >= 750000000000 ? `Rp. ${GeneralService.getNumberWithSeparator(payload.value / 1000000000000)} t` : null}
                </text>
            </g>
        );
    }

    const renderTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            const paid = (props.payload.find((p: any) => p.dataKey === 'paid')?.value || '0');
            const unpaid = (props.payload.find((p: any) => p.dataKey === 'unpaid')?.value || '0');
            const pending = (props.payload.find((p: any) => p.dataKey === 'pending')?.value || '0');
            const totalInvoices = (props.payload.find((p: any) => p.dataKey === 'totalInvoices')?.value || '0');

            return <Stack tokens={{ childrenGap: 10 }} className={styles.tooltip}>
                <Label>{moment(props.label, "MMM").format("MMMM").toUpperCase()}</Label>
                <Stack className='divider'></Stack>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Paid amount</Label>
                        <Text size={'small'} className='color-green'>Rp. {GeneralService.getNumberWithSeparator(paid)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Unpaid amount</Label>
                        <Text size={'small'} className='color-red'>Rp. {GeneralService.getNumberWithSeparator(unpaid)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Pending amount</Label>
                        <Text size={'small'} className='color-yellow'>Rp. {GeneralService.getNumberWithSeparator(pending)}</Text>
                    </Stack>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                    <Label size={'xsmall'}>Total amount</Label>
                    <Stack horizontal><Tag text={`Rp. ${GeneralService.getNumberWithSeparator(Number(paid) + Number(unpaid))}`} /></Stack>
                </Stack>
            </Stack>;
        }

        return <Stack></Stack>;
    };

    return <Stack className={styles.container} styles={{ root: { width: '100%', height: 450 } }}>
        <ResponsiveContainer>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={renderXAxisTick} dataKey="name" />
                <YAxis tick={renderYAxisTick} />
                <Tooltip content={renderTooltip} />
                <Legend content={renderLegend} />
                <Bar dataKey="paid" stackId={'invoices'} fill="#218d21" />
                <Bar dataKey="unpaid" stackId={'invoices'} fill="#ae383e" />
                <Bar dataKey="pending" stackId={'invoices'} fill="#d79e1a" />
            </BarChart>
        </ResponsiveContainer>
    </Stack>
};

export default OutcomeInvoiceSummaryChart;
