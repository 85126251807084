import React from 'react';

// assets

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

// props

// components
import { ActionButton, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { InsuranceCostFormData } from '.';
import VendorsService from '../../../../../../services/data/vendors';
import VendorField from '../../../../../uiframeworks/forms/vendor';

type InsuranceCostingFormProps = {
    data?: Partial<InsuranceCostFormData>;
    onDismissed(): void;
    onSubmit(data: InsuranceCostFormData): void;
}

type FormDataErrorProps = {
    name?: string;
    price?: string;
    goodsValue?: string;
    notes?: string;
    percentage?: string;
}

const InsuranceCostingForm: React.FC<InsuranceCostingFormProps> = (props: InsuranceCostingFormProps) => {
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [data, setData] = React.useState<Partial<InsuranceCostFormData>>(props.data ? {
        ...props.data,
        price: Number(props.data?.price) + "",
        totalPrice: Number(props.data?.price) + "",
        goodsValue: Number(props.data?.goodsValue) + "",
        percentage: Number(props.data?.percentage) + ""
    } : {
        id: GeneralService.guid(),
        name: '',
        priceType: 'percentage',
        goodsValue: '',
        percentage: '1',
        price: '',
        totalPrice: '',
        notes: ''
    });

    const isSubmitButtonDisabled = () => {
        return false;
    }

    const calculateTotalPrice = (_data: Partial<InsuranceCostFormData>) => {
        let totalPrice = Number(_data.price);
        return totalPrice.toFixed(2);
    }

    const calculatePrice = (_data: Partial<InsuranceCostFormData>) => {
        let price = 0;

        if (_data.priceType === 'percentage' && (_data.goodsValue || "").trim() !== '' && (_data.percentage || "").trim() !== '') {
            return (Number(_data.goodsValue) * Number(_data.percentage) / 100).toFixed(2);
        } else if (_data.priceType === 'custom') {
            return _data.price;
        }

        return price.toFixed(2);
    }

    return <Panel headerText={`${props.data ? 'Update' : 'Add'} Insurance`}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={props.data ? "Update" : "Add"} disabled={isSubmitButtonDisabled()} onClick={() => props.onSubmit(data as InsuranceCostFormData)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <Stack.Item>
                <VendorField required
                    selected={data.vendor}
                    allowCreate
                    onChange={(vendor) => {
                        data.vendor = vendor;
                        setData({ ...data });
                    }} />
            </Stack.Item>
            <Stack.Item>
                <TextField label={"Goods name"}
                    value={data.name}
                    required={true}
                    onChange={(evt, value) => {
                        data.name = value || "";

                        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                        error.name = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.name} />
            </Stack.Item>
            <Stack.Item>
                <TextField label={"Notes"}
                    value={data.notes}
                    multiline={true}
                    rows={1}
                    autoAdjustHeight
                    resizable={false}
                    onChange={(evt, value) => {
                        data.notes = value || "";

                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                        error.notes = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.notes} />
            </Stack.Item>
            <Stack className={'divider'}></Stack>
            <Stack.Item>
                <Dropdown label={"Price type"}
                    selectedKey={data.priceType}
                    options={[
                        { key: 'percentage', text: 'Percentage' },
                        { key: 'custom', text: 'Custom' }
                    ]}
                    required={true}
                    onChange={(evt, opt) => {
                        data.priceType = opt ? opt.key as any : 'percentage';
                        data.price = calculatePrice(data);
                        data.totalPrice = calculateTotalPrice(data);

                        setData({ ...data });
                    }} />
            </Stack.Item>
            <Stack.Item>
                <TextField label={"Goods price"}
                    value={data.goodsValue}
                    required={true}
                    prefix={'Rp'}
                    onChange={(evt, value) => {
                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                            data.goodsValue = value || "";
                            data.price = calculatePrice(data);
                            data.totalPrice = calculateTotalPrice(data);

                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                            error.goodsValue = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }
                    }}
                    errorMessage={error.goodsValue} />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                {data.priceType === 'percentage' ? <Stack styles={{root: {width: 100}}}>
                    <TextField label={"Percentage"}
                        value={data.percentage}
                        required={true}
                        suffix={'%'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.percentage = value || "";
                                data.price = calculatePrice(data);
                                data.totalPrice = calculateTotalPrice(data);

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                error.percentage = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.percentage} />
                </Stack> : null}
                <Stack grow={1}>
                    <TextField label={"Price"}
                        value={data.priceType === 'percentage' ? GeneralService.getNumberWithSeparator(Number(data.price || '0')) : data.price}
                        required={true}
                        prefix={'Rp'}
                        disabled={data.priceType === 'percentage'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.price = value || "";
                                data.price = calculatePrice(data);
                                data.totalPrice = calculateTotalPrice(data);

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                error.price = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.price} />
                </Stack>
            </Stack>
        </Stack>
    </Panel>
};

export default InsuranceCostingForm;
