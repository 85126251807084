import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import OrderService from '../../../../../../services/orders/order';
import OutcomeOrderService from '../../../../../../services/finance/outcomes/orders';
import GeneralService from '../../../../../../services/general';

// props
import { IOutcomeOrderResourceShortProps } from '../../../../../../props/finance/outcomes/orders';

// components
import { IColumn, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import Label from '../../../../../typography/label';
import { NavLink } from 'react-router-dom';

interface IOutcomeOrdersListProps {
    qs?: string[];
    invoiceId: string;
    outcomeOrders?: IOutcomeOrderResourceShortProps[];
    columns?: TOutcomeColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOutcomeColumn = 'order' | 'amount' | 'notes';

const OutcomeOrdersList: React.FC<IOutcomeOrdersListProps> = (props: IOutcomeOrdersListProps) => {
    const shownColumns: TOutcomeColumn[] = props.columns || [
        'order',
        'amount',
        'notes'
    ];

    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [outcomeOrders, setOutcomeOrders] = React.useState<IOutcomeOrderResourceShortProps[]>(props.outcomeOrders || []);

    const columns: IColumn[] = [
        {
            key: "order",
            name: "Order",
            minWidth: 300,
            maxWidth: 300,
            isMultiline: true,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                return <Stack horizontalAlign='baseline' styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/orders/${item.orderId}`}><Label size={'small'}>#{OrderService.getOrderNumber(item.order.orderNumber)}</Label></NavLink>
                    {item.order.company ? <Text size={'small'}>{item.order.company.name} - {item.order.customer.name}</Text> : null}
                    {!item.order.company ? <Text size={'small'}>{item.order.customer.name}</Text> : null}
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Amount",
            minWidth: 175,
            maxWidth: 175,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.outcomeOrders) {
            _onRetrieveOutcomeOrders();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveOutcomeOrders = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];

            const results = await OutcomeOrderService.retrieve(props.invoiceId, qs.join("&"));
            setOutcomeOrders(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcome_orders_list_error',
                text: 'Failed to retrieve outcome orders list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && outcomeOrders.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={outcomeOrders}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOutcomeColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && outcomeOrders.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text>Related order(s) not found</Text>
            </Stack> : null}
        </Stack>
    </Stack>;
};

export default OutcomeOrdersList;
