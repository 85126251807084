import React from 'react';
import { useStore } from '../../../../../../stores/root';
import { EmployeeSalaryService } from '../../../../../../services/users/employee';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField } from '@fluentui/react';
import { IEmployedSalaryResourceShort } from '../../../../../../props/users/user';
import moment from 'moment';
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

interface InactivateSalaryFormProps {
    salary: IEmployedSalaryResourceShort;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    reason: string;
}

type FormDataErrorProps = {
    reason?: string;
}

const InactivateSalaryForm: React.FC<InactivateSalaryFormProps> = (props: InactivateSalaryFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        reason: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            fd.append('reason', data.reason);

            await EmployeeSalaryService.inactivate(props.salary.employeeId, props.salary.id, fd);

            banner.add({
                key: 'activate_salary_success',
                variant: 'success',
                icon: faCheck,
                text: `Salary Rp. ${GeneralService.getNumberWithSeparator(Number(props.salary.amount))} is now active`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const isSubmitButtonDisabled = () => {
        return error.reason !== undefined || data.reason.trim() === "";
    }

    return <Panel headerText={"Inactivate Salary"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Inactivate"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Setting salary as inactive ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to inactivate salary with details below?</Text>
            <Stack.Item>
                <Label>Amount</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(Number(props.salary.amount))}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Active Date</Label>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Text>{moment(props.salary.startDate).format('DD/MM/YYYY')}</Text>
                    <Text>-</Text>
                    <Text>{moment(props.salary.endDate).format('DD/MM/YYYY')}</Text>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <TextField required
                    multiline
                    rows={5}
                    autoAdjustHeight
                    resizable={false}
                    label={"Reason"}
                    value={data.reason}
                    onChange={(evt, value) => {
                        data.reason = value || "";
                        const validation = ValidationService.combination(value, ['required', 'limit',], { maxChars: 1000 });
                        error.reason = validation.message;
                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.reason}
                    disabled={submitting} />
            </Stack.Item>
            <Stack className='divider'></Stack>
        </Stack>
    </Panel>
};

export default InactivateSalaryForm;
