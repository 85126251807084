import { ITrainScheduleDelayResourceShort } from '../../../props/data/trains';
import Request from '../../request';

// import props

const TrainScheduleDelaysService = {
  retrieve: async (id: string): Promise<ITrainScheduleDelayResourceShort[]> => {
    try {
      const results = await Request.get(`/api/data/trains/schedules/${id}/delays`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ITrainScheduleDelayResourceShort> => {
    try {
      const result = await Request.post(`/api/data/trainScheduleDelays`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ITrainScheduleDelayResourceShort> => {
    try {
      const result = await Request.post(
        `/api/data/trainScheduleDelays/${id}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/data/trainScheduleDelays/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

export default TrainScheduleDelaysService;
