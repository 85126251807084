import { DefaultButton, Icon, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import GeneralService from '../../../../../services/general';
import moment from 'moment';
import { IInternalDocumentResourceShort } from '../../../../../props/internalDocuments';
import RenderImage from '../renderImage';

type IDeleteInternalFilesPanelProps = {
	title?: string;
	files: IInternalDocumentResourceShort[];
	onDelete(files: any): void;
	onCancel(): void;
}

const DeleteInternalFilesPanel: React.FC<IDeleteInternalFilesPanelProps> = (props: IDeleteInternalFilesPanelProps) => {
	const [deleting, setDeleting] = React.useState<boolean>();
	const [files, setFiles] = React.useState<IInternalDocumentResourceShort[]>(props.files);

	useEffect(() => {
		setFiles([...props.files]);
	}, [props.files]);

	const renderFileCard = (file: IInternalDocumentResourceShort) => {
		const type = GeneralService.getFileType(file.name);

		return <Stack className={styles.item} horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
			<Stack.Item className={`${styles.icon} ${styles['bg' + type]}`} align="stretch">
				<Stack styles={{ root: { width: '100%', height: '100%' } }} verticalAlign='center' horizontalAlign='center'>
					{type === 'word' ? <Icon className={styles.image} iconName='WordDocument' /> : null}
					{type === 'excel' ? <Icon className={styles.image} iconName='ExcelDocument' /> : null}
					{type === 'powerpoint' ? <Icon className={styles.image} iconName='PowerPointDocument' /> : null}
					{type === 'pdf' ? <Icon className={styles.image} iconName='PDF' /> : null}
					{type === 'file' ? <FontAwesomeIcon className={styles.image} icon={faFile} /> : null}
					{type === 'image' ? <RenderImage path={file.path} className={styles.imagePreview} /> : null}
				</Stack>
			</Stack.Item>
			<Stack grow={1} className={styles.label}>
				<Label>{file.name}</Label>
				<Stack horizontal tokens={{ childrenGap: 5 }}>
					{file.createdAt ? <>
						<Text size={'small'}>{moment(file.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
						<Text size={'small'}>|</Text>
					</> : null}
					{file.type === 'document' && <Text size={'small'}>{GeneralService.getFriendlyFileSizeText(file.size)}</Text>}
				</Stack>
			</Stack>
		</Stack>
	}

	const _onDelete = async () => {
		try {
			setDeleting(true);
			props.onDelete(files);
		} catch (e) {

		}
	}

	return <Panel headerText={props.title || "Delete File(s)"}
		isOpen={true}
		type={PanelType.medium}
		onDismiss={() => props.onCancel()}
		isFooterAtBottom={true}
		onRenderFooterContent={() => {
			return <Stack horizontal tokens={{ childrenGap: 10 }}>
				{!deleting ? <>
					<PrimaryButton text={'Delete'} onClick={_onDelete} />
					<DefaultButton text={'Cancel'} onClick={() => props.onCancel()} />
				</> : null}
				{deleting ? <LoadingComponent label='Deleting ...' labelPosition='right' spinnerPosition='baseline' /> : null}
			</Stack>;
		}}>
		<Stack className={styles.container} tokens={{ childrenGap: 20 }}>
			<Text>Are you sure to delete all files below?</Text>
			<Stack className={styles.list} tokens={{ childrenGap: 10 }}>
				{files.map((file) => renderFileCard(file))}
			</Stack>
		</Stack>
	</Panel>;
};

export default DeleteInternalFilesPanel;
