import React, { useEffect } from 'react';

import { Dropdown, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';
import { PPNPercentageOptions } from '../../../../../../manifests/taxPercentage';

type PPnCostingProps = {
    data?: PPnFormData;
    submitting?: boolean;
    mode?: string;
    onChange(data?: PPnFormData): void;
}

export type PPnFormData = {
    percentage?: string;
    amount?: string;
    notes?: string;
}

export type PPnFormDataError = {
    price?: string;
    notes?: string;
}

const PPnCosting: React.FC<PPnCostingProps> = (props: PPnCostingProps) => {
    const [error, setError] = React.useState<PPnFormDataError>({});
    const [data, setData] = React.useState<PPnFormData | undefined>(props.data);

    useEffect(() => {
        setData({ ...props.data });
    }, [props.data]);

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack className={'divider'}></Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item styles={{ root: { width: data?.percentage === 'custom' ? 225 : '100%' } }}>
                {props.mode === 'view' ? <Stack>
                    <Text size={'small'}>PPn Percentage</Text>
                    <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(data?.percentage || '0'))}%</Label>
                </Stack> : null}
                {props.mode !== 'view' ? <Dropdown label={'PPn Percentage'}
                    options={PPNPercentageOptions}
                    selectedKey={data?.percentage}
                    onChange={(evt, opt) => {
                        let _data = data || {};
                        _data.percentage = (opt?.key || "") as string;
                        props.onChange(_data);
                    }}
                    styles={{ root: { width: '100%' } }} /> : null}
            </Stack.Item>
            {data?.percentage === 'custom' ? <Stack.Item grow={1}>
                {props.mode === 'view' ? <Stack>
                    <Text size={'small'}>Amount</Text>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data?.amount || '0'))}</Label>
                </Stack> : null}
                {props.mode !== 'view' ? <TextField label={"Amount"}
                    value={data?.amount}
                    prefix={'Rp'}
                    disabled={data?.percentage !== 'custom'}
                    onChange={(evt, value) => {
                        let _data = data || {};
                        if (value === "" || !isNaN(Number(value))) {
                            _data.amount = value || "";
                            props.onChange(_data);
                        }
                    }} /> : null}
            </Stack.Item> : null}
        </Stack>
    </Stack>
}

export default PPnCosting;