import Request from './../request';

// import props
import { IOrderStatusResourceShortProps } from '../../props/orders/orderStatus';

const OrderStatusService = {
    retrieve: async ():Promise<IOrderStatusResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/orderStatus`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id:string, data:FormData):Promise<IOrderStatusResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orderStatus/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
}

export default OrderStatusService;