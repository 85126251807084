import Request from './../request';

// import props
import { IOrderAddonResourceShortProps, IOrderAddonResourceProps } from '../../props/orders/orderAddon';

const OrderAddOnService = {
    retrieve: async (orderId:string, qs?: string):Promise<IOrderAddonResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/orders/${orderId}/addons?${qs || ''}`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    new: async (orderId: string, data: FormData): Promise<IOrderAddonResourceProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/addons`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (orderId: string, addOnId: string, data: FormData):Promise<IOrderAddonResourceProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/addons/${addOnId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    get: async (orderId: string, addOnId: string):Promise<IOrderAddonResourceProps> => {
        try {
            const result = await Request.get(`/api/orders/${orderId}/addons/${addOnId}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (orderId: string, addOnId: string):Promise<void> => {
        try {
            await Request.delete(`/api/orders/${orderId}/addons/${addOnId}`);
            return;
        } catch(e) {
            throw(e);
        }
    },
}

export default OrderAddOnService;