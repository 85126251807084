import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderTruckService from '../../../../../services/orders/trucks';
import ErrorService from '../../../../../services/general/error';

// props
import { IOrderTruckResourceShortProps } from '../../../../../props/orders/trucks';

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack } from '@fluentui/react';
import LoadingComponent from '../../../../feedbacks/loading';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import OrderTruckDetailsProperties from './properties';
import OrderTruckForm from '../form';
import OrderTruckGoodsForm from '../../goods/form';
import { IOrderResourceShortProps } from '../../../../../props/orders/order';
import OrderService from '../../../../../services/orders/order';
import PermissionsService from '../../../../../services/permissions';
import DeleteOrderTruckForm from '../form/delete';

interface IOrderTruckDetailsProps {
    orderId: string;
    truckId: string;
    onDismissed(refresh?: boolean): void;
}

const OrderTruckDetails: React.FC<IOrderTruckDetailsProps> = (props: IOrderTruckDetailsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [order, setOrder] = React.useState<IOrderResourceShortProps | undefined>();
    const [truck, setTruck] = React.useState<IOrderTruckResourceShortProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);

            const _truck = await OrderTruckService.get(props.orderId, props.truckId);
            setTruck(_truck);
            
            const _order = await OrderService.get(props.orderId);
            setOrder(_order);

            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_order_truck_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                text: 'Update',
                onRender: () => {
                    return <PrimaryButton text={'Update'}
                        menuProps={{
                            items: [
                                {
                                    key: 'update_details', text: "Update Details", onClick: () => {
                                        setActiveSurface('update.details');
                                    }
                                },
                                {
                                    key: 'update_goods', text: "Update Goods", onClick: () => {
                                        setActiveSurface('update.goods');
                                    }
                                },
                            ]
                        }}
                        styles={{ root: { marginRight: 20 } }} />
                }
            });
        }

        items.push({
            key: 'download',
            text: 'Download Packing List',
            onRender: () => {
                return <ActionButton iconProps={{ iconName: "Download" }} text={"Packing List"} />
            }
        });

        if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: 'delete',
                text: 'Delete',
                onRender: () => {
                    return <ActionButton iconProps={{ iconName: "Delete" }} text={"Delete"} onClick={() => setActiveSurface('delete')} />
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { init(); }
    }

    return <Panel headerText={truck && truck.name ? truck.name : "Truck Details"}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(true)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(true) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && truck ? <>
                <CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />
                <Pivot className={'linePivot'}>
                    <PivotItem key={'details'} headerText={"Details"}>
                        <Stack styles={{ root: { padding: 10 } }}><OrderTruckDetailsProperties truck={truck} /></Stack>
                    </PivotItem>
                    <PivotItem key={'trackings'} headerText={"Trackings"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                    <PivotItem key={'documents'} headerText={"Documents"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                </Pivot>
            </> : null}
        </Stack>
        {activeSurface === 'update.details' ? <OrderTruckForm orderId={props.orderId} truckId={props.truckId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update.goods' && truck && order ? <OrderTruckGoodsForm type={order.goodsType} goods={truck.goods} orderId={props.orderId} truck={truck} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && truck ? <DeleteOrderTruckForm truck={truck} onDismissed={props.onDismissed} /> : null}
    </Panel>
};

export default OrderTruckDetails;
