import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import FreightCategoriesList from '../../../components/settings/freightCategories/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IFreightCategoriesListPageProps {
}

const FreightCategoriesListPage: React.FC<IFreightCategoriesListPageProps> = (props: IFreightCategoriesListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Freight Categories", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "freightCategories", text: 'Freight Categories'}
        ])
    }, []);

    return <Stack>
        <FreightCategoriesList />
    </Stack>;
};

export default FreightCategoriesListPage;
