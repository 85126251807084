import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../services/general';
import OrderAddOnService from '../../../../../services/orders/odrerAddOn';

// props
import { IOrderAddonResourceShortProps } from '../../../../../props/orders/orderAddon';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';

interface IDeleteOrderAdditionalChargeFormProps {
    additionalCharge: IOrderAddonResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteOrderAdditionalChargeForm: React.FC<IDeleteOrderAdditionalChargeFormProps> = (props: IDeleteOrderAdditionalChargeFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await OrderAddOnService.delete(props.additionalCharge.orderId, props.additionalCharge.id);

            banner.add({
                key: 'delete_order_additional_charge_success',
                variant: 'success',
                icon: faCheck,
                text: `Additional charge "${props.additionalCharge.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Additional Charge"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting additional charge ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete additional charge with details below?</Text>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.additionalCharge.name}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Notes</Label>
                <Text>{props.additionalCharge.notes || "-"}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Price</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(props.additionalCharge.price)}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteOrderAdditionalChargeForm;
