import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import TeamMemberList from '../../../components/users/teamMembers/general/list';

interface ITeamMemberListPageProps {
}

const TeamMemberListPage: React.FC<ITeamMemberListPageProps> = (props: ITeamMemberListPageProps) => {
    const { topbar } = useStore();

    useEffect(() => {
        topbar.show("TeamMembers", [
            { key: "home", icon: faHome, href: "/" },
            { key: "users", text: 'Users' },
            { key: "administrators", text: 'TeamMembers' }
        ])
    }, []);

    return (
        <Stack>
            <TeamMemberList />
        </Stack>
    );
};

export default TeamMemberListPage;
