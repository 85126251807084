import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IUserResourceShortProps } from '../../../../../props/users/user';

// components
import { DatePicker, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import EmployeeContractService from '../../../../../services/employees/contracts';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import GeneralService from '../../../../../services/general';
import DateTimePicker from '../../../../uiframeworks/forms/dateTimePicker';
import moment from 'moment';
import ValidationService from '../../../../../services/validation';
import { IEmployeeContractResourceProps } from '../../../../../props/employees/contracts/contract';
import UserService from '../../../../../services/users/user';
import SubsidiariesService from '../../../../../services/data/subsidiaries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmployeeContractJobdeskForm from './subform/jobdesk';
import { IEmployeeContractJobdeskResourceShortProps } from '../../../../../props/employees/contracts/jobdesk';

type EmployeeContractFormProps = {
    contractId?: string;
    employeeId?: string;
    subsidiaryId?: string;
    onDismissed(refresh?: boolean, item?: IEmployeeContractResourceProps): void;
}

type FormDataProps = {
    id: string;
    employee?: IUserResourceShortProps;
    subsidiary?: ISubsidiariesResourceShort;
    startDate: string;
    endDate: string;
    salary: string;
    notes: string;
    jobdesks: IEmployeeContractJobdeskResourceShortProps[];
}

type FormDataErrorProps = {
    salary?: string;
    employee?: string;
    subsidiary?: string;
    endDate?: string;
}

const EmployeeContractForm: React.FC<EmployeeContractFormProps> = (props: EmployeeContractFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        id: GeneralService.makeid(25, true, 'CTR'),
        startDate: moment().toISOString(),
        endDate: moment().add(1, 'year').toISOString(),
        salary: '',
        notes: '',
        jobdesks: []
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.contractId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _data = data;

        if (props.contractId) {
            const _contract = await EmployeeContractService.read(props.contractId);
            _data = {
                id: _contract.id,
                employee: _contract.employee,
                endDate: _contract.endDate,
                startDate: _contract.startDate,
                salary: Number(_contract.salary) + '',
                subsidiary: _contract.subsidiary,
                notes: '',
                jobdesks: _contract.jobdesks
            };
        } else {
            if (props.employeeId) {
                const _employee = await UserService.get(props.employeeId);
                _data.employee = _employee;
            }

            if (props.subsidiaryId) {
                const _subsidiary = await SubsidiariesService.get(props.subsidiaryId);
                _data.subsidiary = _subsidiary;
            }
        }

        setData({ ...data });
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { id, subsidiary, employee, startDate, endDate, salary } = data;

            const fd = new FormData();
            fd.append('id', id);
            fd.append('subsidiaryId', subsidiary?.id || "");
            fd.append('employeeId', employee?.id || "");
            fd.append('startDate', startDate);
            fd.append('endDate', endDate);
            fd.append('salary', salary);

            let result;
            if (props.contractId === undefined) {
                result = await EmployeeContractService.create(fd);
            } else {
                result = await EmployeeContractService.update(props.contractId, fd);
            }

            props.onDismissed(true, result);
        } catch (e) {
            banner.add({
                key: 'create_contract_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to create contract. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? "Create Contract" : "Update Contract"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating contract ..." : "Updating contract ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <TextField label={"Contract number"}
                            prefix={'#'}
                            value={data.id}
                            disabled />
                        <SelectEmployee label={'Select employee'}
                            required={true}
                            selected={data.employee}
                            errorMessage={error.employee}
                            disabled={submitting || props.employeeId !== undefined}
                            onChange={(employee) => {
                                data.employee = employee;

                                const validation = ValidationService.combination(employee, ['required'], {});
                                error.employee = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                        <SelectSubsidiary label={'Select subsidiary'}
                            required={true}
                            selected={data.subsidiary}
                            errorMessage={error.subsidiary}
                            disabled={submitting || props.subsidiaryId !== undefined}
                            onChange={(subsidiary) => {
                                data.subsidiary = subsidiary;

                                const validation = ValidationService.combination(subsidiary, ['required'], {});
                                error.subsidiary = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                        <Stack className={"divider"}></Stack>
                        <TextField label={'Base salary'}
                            prefix={'Rp'}
                            required
                            suffix={(data.salary || "").trim() !== "" && data.salary !== "0" ? GeneralService.convertNumberToWords(Number(data.salary)) : undefined}
                            errorMessage={error.salary}
                            disabled={submitting}
                            value={data.salary}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.salary = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.salary = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                        <Stack className={"divider"}></Stack>
                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                            <Stack grow={1}>
                                <DateTimePicker label={'Start date'}
                                    value={data.startDate}
                                    required
                                    disabled={submitting}
                                    onChange={(date) => {
                                        data.startDate = date || data.startDate;
                                        setData({ ...data });
                                    }} />
                            </Stack>
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: 38 }} />
                            <Stack grow={1}>
                                <DateTimePicker label={'End date'}
                                    value={data.endDate}
                                    disabled={submitting}
                                    required
                                    onChange={(date) => {
                                        data.endDate = date || data.endDate;
                                        setData({ ...data });
                                    }} />
                            </Stack>
                        </Stack>
                        <Stack className={"divider"}></Stack>
                        <EmployeeContractJobdeskForm value={data.jobdesks} 
                            onChange={(jobdesks: IEmployeeContractJobdeskResourceShortProps[]) => {
                                data.jobdesks = jobdesks;
                                setData({...data});
                            }} />
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default EmployeeContractForm;
