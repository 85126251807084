import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import ValidationService from '../../../../../../services/validation';
import VendorsService from '../../../../../../services/data/vendors';
import ShipsService from '../../../../../../services/data/ships';

// props
import { IRoleResourceShortProps } from '../../../../../../props/users/role';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';

// components
import { Checkbox, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import { IShipResourceShortProps } from '../../../../../../props/data/ships';
import { IOutcomeCategoryResourceProps, IOutcomeCategoryResourceShortProps } from '../../../../../../props/finance/outcomes/categories';
import OutcomeCategoryService from '../../../../../../services/finance/outcomes/categories';
import RoleService from '../../../../../../services/roles';

type OutcomeCategoryFormProps = {
    categoryId?: string;
    onDismissed(refresh?: boolean, category?: IOutcomeCategoryResourceShortProps): void;
}

const OutcomeCategoryForm: React.FC<OutcomeCategoryFormProps> = (props: OutcomeCategoryFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [data, setData] = React.useState<Partial<IOutcomeCategoryResourceProps>>({});
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.categoryId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['outcomes.categories.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['outcomes.categories.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _data = data;

        const _roles = (await RoleService.retrieve()).filter((role) => role.group === 'backoffice');

        if (props.categoryId) {
            const _category = await OutcomeCategoryService.get(props.categoryId);
            _data = {
                id: _category.id,
                name: _category.name,
                roles: _category.roles
            };
        }

        setData({ ..._data });
        setRoles(_roles);
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.roles) {
            return true;
        } else if ((data.name || "").trim() === '' || (data.roles || []).length < 1) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { id, name, roles } = data;

            // create form data
            let fd = new FormData();
            fd.append("id", id || "");
            fd.append("name", name || "");
            (roles || []).forEach((role) => {
                fd.append("roles[]", role.id);
            })

            let result;
            if (props.categoryId === undefined) {
                result = await OutcomeCategoryService.create(fd);
            } else {
                result = await OutcomeCategoryService.update(props.categoryId, fd);
            }

            banner.add({
                key: mode + '_outcome_category_success',
                variant: 'success',
                icon: faCheck,
                text: `Outcome category "${name}" ${mode}ed successfully`
            });

            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Ship' : 'Update Ship'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating ship ..." : "Updating ship ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                data.id = data.name.split(" ").join("").toLowerCase();

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Key"}
                            required={true}
                            value={data.id}
                            disabled={true} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <Stack.Item>
                        <Label required size={'xsmall'}>Select which roles has access (min. 1 role)</Label>
                        <Stack tokens={{ childrenGap: 10 }} styles={{root: {marginTop: 3}}}>
                            {roles.map((role) => {
                                const checked = (data.roles || []).findIndex((r) => r.id === role.id) > -1;
                                return <Checkbox checked={checked} label={role.name} onChange={(ev, checked) => {
                                    let _roles = data.roles || [];

                                    const idx = _roles.findIndex((r) => r.id === role.id);
                                    if (idx > -1) {
                                        _roles.splice(idx, 1);
                                    } else {
                                        _roles.push(role);
                                    }

                                    data.roles = _roles;
                                    setData({ ...data });
                                }} />
                            })}
                        </Stack>
                    </Stack.Item>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default OutcomeCategoryForm;
