import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../stores/root';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import moment from 'moment';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../../services/validation';
import GeneralService from '../../../../../../../services/general';
import IncomePaymentService from '../../../../../../../services/finance/incomes/payments/payment';

// props
import { IOrderResourceShortProps } from '../../../../../../../props/orders/order';
import { IBankAccountResourceShortProps } from '../../../../../../../props/general/bankAccount';

// components
import { DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';
import ErrorService from '../../../../../../../services/general/error';
import Label from '../../../../../../typography/label';
import Text from '../../../../../../typography/text';
import UploadFilesComponent from '../../../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../../../props/general';
import PermissionsService from '../../../../../../../services/permissions';
import NoAccess from '../../../../../../uiframeworks/noAccess';
import IncomeInvoicePaymentService from '../../../../../../../services/finance/incomes/invoices/payment';

type IncomeInvoicePaymentFormProps = {
    invoiceId: string;
    paymentId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    method: string;
    amount: string;
    paymentDate: string;
    notes: string;
}

type FormDataErrorProps = {
    method?: string;
    amount?: string;
    paymentDate?: string;
    notes?: string;
}

const IncomeInvoicePaymentForm: React.FC<IncomeInvoicePaymentFormProps> = (props: IncomeInvoicePaymentFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [invoice, setInvoice] = React.useState<IIncomeInvoiceResourceShortProps | undefined>();
    const [files, setFiles] = React.useState<IFileDetailsProps[]>([]);
    const [bankAccounts, setBankAccounts] = React.useState<IBankAccountResourceShortProps[]>([]);
    const [order, setOrder] = React.useState<IOrderResourceShortProps>();
    const [data, setData] = React.useState<FormDataProps>({
        method: "cash",
        amount: "",
        paymentDate: moment().toISOString(),
        notes: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    const hasPermission = PermissionsService.hasPermission(['incomes.invoices.payments.record'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _invoice = await IncomeInvoiceService.get(props.invoiceId);

            setInvoice(_invoice);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_invoice_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to get invoice "${IncomeInvoiceService.getInvoiceNumber(props.invoiceId)}". Error: ${ErrorService.getMessage(e)}`
            });
            props.onDismissed();
        }
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.amount || error.notes) {
            return true;
        } else if (data.amount === '') {
            return true;
        } else if (files.length < 1) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { amount, method, paymentDate, notes } = data;

            // create form data
            let fd = new FormData();
            fd.append('notes', notes);
            fd.append('amount', amount);
            fd.append('method', method);
            fd.append('paymentDate', paymentDate);
            fd.append('invoiceId', props.invoiceId);

            files.map(f => {
                const file = f.data as File
                const allMetaData = {
                    name: file.name,
                    size: file.size,
                }
                fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, type: f.type, ...allMetaData }))
            });
            files.map(f => fd.append('documents[]', f.data))

            const payment = await IncomeInvoicePaymentService.create(fd);

            banner.add({
                key: 'create_income_payment_success',
                variant: 'success',
                icon: faCheck,
                text: `Payment Rp. ${GeneralService.getNumberWithSeparator(amount)} recorded successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onUploadDocuments = async (files: IFileDetailsProps[]) => {
        setFiles(files);
    }

    return <Panel headerText={'Record Payment'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && invoice && hasPermission ? <>
                    <Stack.Item>
                        <Label>Unpaid Amount</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(invoice.unpaid)}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown label={"Method"}
                            required={true}
                            disabled={submitting}
                            selectedKey={data.method}
                            options={PaymentMethods}
                            onChange={(evt, opt) => {
                                data.method = opt ? opt.key as string : "";
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Amount"}
                            value={data.amount}
                            required={true}
                            prefix={'Rp'}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.amount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.amount = validation.message;

                                    if (!validation.message && invoice.unpaid < Number(value)) {
                                        error.amount = `Amount cannot be greater than invoice's unpaid amount (Rp. ${GeneralService.getNumberWithSeparator(invoice.unpaid)})`
                                    }

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.amount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label={"Payment Date"}
                            isRequired={true}
                            value={moment(data.paymentDate).toDate()}
                            onSelectDate={(date) => {
                                data.paymentDate = date ? moment(date).toISOString() : data.paymentDate;
                                setData({ ...data });
                            }}
                            formatDate={GeneralService.formatDate}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.notes = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.notes = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack styles={{ root: { maxHeight: 600 } }}>
                        <Label required={true}>Proof of Payments</Label>
                        <UploadFilesComponent onUpload={_onUploadDocuments} documentTypes={[
                            { key: "document", text: "Document" }
                        ]} />
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default IncomeInvoicePaymentForm;
