import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import EmployeePayslipDetails from '../../../../components/users/administrators/payslips/general/details';
import { useParams } from 'react-router-dom';

interface EmployeePayslipDetailsPageProps {
}

const EmployeePayslipDetailsPage: React.FC<EmployeePayslipDetailsPageProps> = (props: EmployeePayslipDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Administrators", [
            {key: "home", icon: faHome, href: "/"},
            {key: "users", text: 'Users'},
            {key: "administrators", text: 'Administrators'}
        ])
    }, []);

    return <Stack>
        <EmployeePayslipDetails employeeId={params.administratorId} payslipId={params.payslipId} />
    </Stack>;
};

export default EmployeePayslipDetailsPage;
