import React from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import TrackingTypes from '../../../../../manifests/trackingTypes';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faBoxArchive, faContainerStorage, faShip, faTrain, faTruck } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

// services

// props
import { IOrderTrackingResourceShortProps } from '../../../../../props/orders/trackings/orderTracking';

// components
import { ActionButton, IContextualMenuItem, Link, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import Label from '../../../../typography/label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavLink } from 'react-router-dom';
import FilesList from '../../../../uiframeworks/files/list';

interface IOrderTrackingDetailsCardProps {
    key?: string;
    tracking: IOrderTrackingResourceShortProps;
    showAsTimeline?: boolean;
    onRenderLeftContainer?(): JSX.Element;
    onOpenSurface?(key: string): void;
}

const OrderTrackingDetailsCard: React.FC<IOrderTrackingDetailsCardProps> = (props: IOrderTrackingDetailsCardProps) => {
    const { banner } = useStore();

    const render = () => {
        const tracking = props.tracking;
        const actions: IContextualMenuItem[] = [];
        const { orderTruck, shippingDetails, dooringDetails, trainDetails, loadDetails, unloadingDetails, container, notes, documents } = tracking;
        const type = TrackingTypes.find((t) => t.key === tracking.type);
        let statusVariant: TTagVariant = 'active';
        let statusText: string = 'Estimated';

        actions.push({
            key: "update",
            text: "Update details",
            onClick: () => {
                if (props.onOpenSurface) {
                    props.onOpenSurface("update.details");
                }
            }
        });

        actions.push({
            key: "add_supporting_files",
            text: "Upload file(s)",
            onClick: () => {
                if (props.onOpenSurface) {
                    props.onOpenSurface("upload.files");
                }
            }
        });

        if (dooringDetails) {
            if (dooringDetails.status === 'in progress') {
                statusText = 'In progress';
                statusVariant = 'warning';
            } else if (dooringDetails.status === 'completed') {
                statusText = 'Completed';
                statusVariant = 'success';
            }

            actions.push({
                key: "update",
                text: "Update status",
                onClick: () => {
                    if (props.onOpenSurface) {
                        props.onOpenSurface("update.status");
                    }
                }
            });
        } else if (loadDetails || unloadingDetails) {
            if (tracking.status === 'completed') {
                statusText = 'Completed';
                statusVariant = 'success';
            } else if (tracking.status === 'in progress') {
                statusText = 'In Progress';
                statusVariant = 'warning';
            }

            actions.push({
                key: "update",
                text: "Update status",
                onClick: () => {
                    if (props.onOpenSurface) {
                        props.onOpenSurface("update.status");
                    }
                }
            });
        } else if (shippingDetails) {
            if (shippingDetails.schedule.status === 'sailing') {
                statusText = 'Sailing';
                statusVariant = 'warning';
            } else if (shippingDetails.schedule.status === 'arrived') {
                statusText = 'Completed';
                statusVariant = 'success';
            } else if (shippingDetails.schedule.status === 'cancelled') {
                statusText = 'Cancelled';
                statusVariant = 'error';
            }

            actions.push({
                key: "update",
                text: "Update ship status",
                onClick: () => {
                    if (props.onOpenSurface) {
                        props.onOpenSurface("update.ship.status");
                    }
                }
            });
        } else if (trainDetails) {
            if (trainDetails.schedule.status === 'in progress') {
                statusText = 'On the Move';
                statusVariant = 'warning';
            } else if (trainDetails.schedule.status === 'arrived') {
                statusText = 'Completed';
                statusVariant = 'success';
            } else if (trainDetails.schedule.status === 'cancelled') {
                statusText = 'Cancelled';
                statusVariant = 'error';
            }

            actions.push({
                key: "update",
                text: "Update train status",
                onClick: () => {
                    if (props.onOpenSurface) {
                        props.onOpenSurface("update.train.status");
                    }
                }
            });
        }

        actions.push({
            key: "delete",
            text: "Delete",
            onClick: () => {
                if (props.onOpenSurface) {
                    props.onOpenSurface("delete");
                }
            }
        });

        return <Stack key={props.key} horizontal tokens={{ childrenGap: 10 }} className={styles.item}>
            {props.showAsTimeline ? <>
                <Stack className={styles.line}></Stack>
                <FontAwesomeIcon icon={faCircle as IconProp} fontSize={9} className={styles.dot} />
            </> : null}
            {props.onRenderLeftContainer ? props.onRenderLeftContainer() : null}
            <Stack className={styles.content} tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center' horizontalAlign='space-between'>
                    <Stack grow={1} horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
                        <Label>{type?.text}</Label>
                        <ActionButton styles={{ root: { height: 'unset', minHeight: 'unset' } }} menuIconProps={{ iconName: "ChevronDown" }} menuProps={{ items: actions }} />
                    </Stack>
                    <Tag text={statusText} variant={statusVariant} />
                </Stack>
                <Stack className={styles.divider}></Stack>
                {orderTruck ? <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <FontAwesomeIcon icon={faBoxArchive} width={20} style={{ marginTop: 3 }} />
                        <Link onClick={() => {
                            if (props.onOpenSurface) {
                                props.onOpenSurface("ordertruck.details");
                            }
                        }}>
                            <Label size={'small'}>{orderTruck.name || "Truck"}</Label>
                        </Link>
                    </Stack>
                    <Stack className={styles.divider}></Stack>
                </> : null}
                {container ? <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <FontAwesomeIcon icon={faContainerStorage} width={20} style={{ marginTop: 5 }} />
                        <Link onClick={() => {
                            if (props.onOpenSurface) {
                                props.onOpenSurface("container.details");
                            }
                        }}>
                            <Label size={'small'}>Container #{container.containerNumber || "Unknown number"}</Label>
                        </Link>
                    </Stack>
                    <Stack className={styles.divider}></Stack>
                </> : null}
                {dooringDetails ? <>
                    {dooringDetails.truck ? <>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <FontAwesomeIcon icon={faTruck} style={{ marginTop: 3 }} width={20} />
                            <NavLink to={`/operationals/trucks/${dooringDetails.truck.id}`} target='_blank'>
                                <Stack>
                                    <Label size={'xsmall'}>{dooringDetails.truck.name} ({dooringDetails.truck.registrationNumber})</Label>
                                    {dooringDetails.driverName || dooringDetails.driverPhone ? <Text size={'small'}>{dooringDetails.driverName} ({dooringDetails.driverPhone})</Text> : null}
                                </Stack>
                            </NavLink>
                        </Stack>
                        <Stack className={styles.divider}></Stack>
                    </> : null}
                    <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Text size={'small'}>{dooringDetails.originAddress}</Text>
                            {dooringDetails.actualDepartureDate ? <Text size={'xsmall'}>{moment(dooringDetails.actualDepartureDate).format("DD/MM/YYYY HH:mm")} (Actual)</Text> : null}
                            {!dooringDetails.actualDepartureDate ? <Text size={'xsmall'}>{moment(dooringDetails.estimatedDepartureDate).format("DD/MM/YYYY HH:mm")} (Est.)</Text> : null}
                        </Stack>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Text size={'small'}>{dooringDetails.destinationAddress}</Text>
                            {dooringDetails.actualArrivalDate ? <Text size={'xsmall'}>{moment(dooringDetails.actualArrivalDate).format("DD/MM/YYYY HH:mm")} (Actual)</Text> : null}
                            {!dooringDetails.actualArrivalDate ? <Text size={'xsmall'}>{moment(dooringDetails.estimatedArrivalDate).format("DD/MM/YYYY HH:mm")} (Est.)</Text> : null}
                        </Stack>
                    </Stack>
                </> : null}
                {loadDetails ? <>
                    <Stack.Item>
                        <Label size={'xsmall'}>Address</Label>
                        <Text size={'small'}>{loadDetails.address}</Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label size={'xsmall'}>Start time</Label>
                            {loadDetails.actualStartDate ? <Text size={'small'}>{moment(loadDetails.actualStartDate).format("DD/MM/YYYY HH:mm")} (Actual)</Text> : null}
                            {!loadDetails.actualStartDate ? <Text size={'small'}>{moment(loadDetails.estimatedStartDate).format("DD/MM/YYYY HH:mm")} (Est.)</Text> : null}
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label size={'xsmall'}>End time</Label>
                            {loadDetails.actualEndDate ? <Text size={'small'}>{moment(loadDetails.actualEndDate).format("DD/MM/YYYY HH:mm")} (Actual)</Text> : null}
                            {!loadDetails.actualEndDate ? <Text size={'small'}>{moment(loadDetails.estimatedEndDate).format("DD/MM/YYYY HH:mm")} (Est.)</Text> : null}
                        </Stack>
                    </Stack>
                </> : null}
                {unloadingDetails ? <>
                    <Stack.Item>
                        <Label size={'xsmall'}>Address</Label>
                        <Text size={'small'}>{unloadingDetails.address}</Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label size={'xsmall'}>Start time</Label>
                            {unloadingDetails.actualStartDate ? <Text size={'small'}>{moment(unloadingDetails.actualStartDate).format("DD/MM/YYYY HH:mm")} (Actual)</Text> : null}
                            {!unloadingDetails.actualStartDate ? <Text size={'small'}>{moment(unloadingDetails.estimatedStartDate).format("DD/MM/YYYY HH:mm")} (Est.)</Text> : null}
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label size={'xsmall'}>End time</Label>
                            {unloadingDetails.actualEndDate ? <Text size={'small'}>{moment(unloadingDetails.actualEndDate).format("DD/MM/YYYY HH:mm")} (Actual)</Text> : null}
                            {!unloadingDetails.actualEndDate ? <Text size={'small'}>{moment(unloadingDetails.estimatedEndDate).format("DD/MM/YYYY HH:mm")} (Est.)</Text> : null}
                        </Stack>
                    </Stack>
                </> : null}
                {shippingDetails ? <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <FontAwesomeIcon icon={faShip} style={{ marginTop: 3 }} width={20} />
                        <NavLink to={`/operationals/ships/${shippingDetails.ship.id}/schedules/${shippingDetails.schedule.id}`} target='_blank'>
                            <Stack>
                                <Label size={'small'}>{shippingDetails.ship.name} ({shippingDetails.ship.registrationNumber})</Label>
                                <Text size={'small'}>Voy {shippingDetails.schedule.voy}</Text>
                            </Stack>
                        </NavLink>
                    </Stack>
                    <Stack className={styles.divider}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Text size={'small'}>{shippingDetails.schedule.originAddress}</Text>
                            {shippingDetails.schedule.actualDeparture ? <Text size={'xsmall'}>{moment(shippingDetails.schedule.actualDeparture).format("DD/MM/YYYY")} (Actual)</Text> : null}
                            {!shippingDetails.schedule.actualDeparture ? <Text size={'xsmall'}>{moment(shippingDetails.schedule.estimatedDeparture).format("DD/MM/YYYY")} (Est.)</Text> : null}
                        </Stack>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Text size={'small'}>{shippingDetails.schedule.destinationAddress}</Text>
                            {shippingDetails.schedule.actualArrival ? <Text size={'xsmall'}>{moment(shippingDetails.schedule.actualArrival).format("DD/MM/YYYY")} (Actual)</Text> : null}
                            {!shippingDetails.schedule.actualArrival ? <Text size={'xsmall'}>{moment(shippingDetails.schedule.estimatedArrival).format("DD/MM/YYYY")} (Est.)</Text> : null}
                        </Stack>
                    </Stack>
                </> : null}
                {trainDetails ? <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <FontAwesomeIcon icon={faTrain} style={{ marginTop: 3 }} width={20} />
                        <NavLink to={`/operationals/trains/${trainDetails.train.id}/schedules/${trainDetails.schedule.id}`} target='_blank'>
                            <Stack>
                                <Label size={'small'}>{trainDetails.train.name}</Label>
                                <Text size={'small'}>Voy {trainDetails.schedule.voy}</Text>
                            </Stack>
                        </NavLink>
                    </Stack>
                    <Stack className={styles.divider}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Text size={'small'}>{trainDetails.schedule.originAddress}</Text>
                            {trainDetails.schedule.actualDeparture ? <Text size={'xsmall'}>{moment(trainDetails.schedule.actualDeparture).format("DD/MM/YYYY")} (Actual)</Text> : null}
                            {!trainDetails.schedule.actualDeparture ? <Text size={'xsmall'}>{moment(trainDetails.schedule.estimatedDeparture).format("DD/MM/YYYY")} (Est.)</Text> : null}
                        </Stack>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Text size={'small'}>{trainDetails.schedule.destinationAddress}</Text>
                            {trainDetails.schedule.actualArrival ? <Text size={'xsmall'}>{moment(trainDetails.schedule.actualArrival).format("DD/MM/YYYY")} (Actual)</Text> : null}
                            {!trainDetails.schedule.actualArrival ? <Text size={'xsmall'}>{moment(trainDetails.schedule.estimatedArrival).format("DD/MM/YYYY")} (Est.)</Text> : null}
                        </Stack>
                    </Stack>
                </> : null}
                {notes && notes.trim() !== "" ? <>
                    <Stack className={styles.divider}></Stack>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{notes}</Text>
                </> : null}
                {documents && documents.length > 0 ? <>
                    <Stack className={styles.divider}></Stack>
                    <FilesList files={documents} 
                        size={'small'} 
                        documentTypes={[]} 
                        hideCommandBar loaded />
                </> : null}
            </Stack>
        </Stack>;
    }

    return render();
};

export default OrderTrackingDetailsCard;
