import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import QuantityUnitsList from '../../../components/settings/quantityUnits/general/list';
import WarehousesList from '../../../components/settings/warehouses/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IWarehousesListPageProps {
}

const WarehousesListPage: React.FC<IWarehousesListPageProps> = (props: IWarehousesListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Warehouses", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "warehouses", text: 'Warehouses'}
        ])
    }, []);

    return <Stack>
        <WarehousesList />
    </Stack>;
};

export default WarehousesListPage;
