import React, { useEffect } from 'react';

import { ActionButton, DetailsRow, IColumn, IconButton, SelectionMode, ShimmeredDetailsList, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';

import styles from './../styles.module.scss';
import ContainerShippingCostingForm from './form';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../../../../props/data/ships';
import { IQuantityUnitResourceShortProps } from '../../../../../../props/general/quantityUnit';

type ContainerShippingCostingProps = {
    quantityUnits: IQuantityUnitResourceShortProps[];
    data?: ContainerShippingCostFormData[];
    submitting?: boolean;
    mode?: string;
    onChange(data?: ContainerShippingCostFormData[]): void;
}

export type ContainerShippingCostFormData = {
    id: string;
    thcOrigin: string;
    thcDestination: string;
    loloOrigin: string;
    loloDestination: string;
    doc: string;
    seal: string;
    lss: string;
    apbs: string;
    cleaning: string;
    oceanFreight: string;
    dutyStamp: string;
    ops: string;
    priceType: 'lumpsum' | 'perquantity';
    price: string;
    quantity: string;
    quantityUnit?: IQuantityUnitResourceShortProps;
    totalPrice: string;
    vendor?: IVendorResourceShortProps;
    ship?: IShipResourceShortProps;
    schedule?: IShipScheduleResourceShort;
    notes?: string;
    pol: string;
    pod: string;
}

const ContainerShippingCosting: React.FC<ContainerShippingCostingProps> = (props: ContainerShippingCostingProps) => {
    const [data, setData] = React.useState<ContainerShippingCostFormData[]>(props.data || []);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [activeData, setActiveData] = React.useState<ContainerShippingCostFormData | undefined>();

    const columns: IColumn[] = [
        {
            key: "idx",
            name: 'No.',
            minWidth: 25,
            maxWidth: 25,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>{(idx || 0) + 1}</Text> : null}
                </Stack>
            }
        },
        {
            key: "ship",
            name: 'Vendor / Ship / Schedule',
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <>
                        <Label size={'xsmall'}>{item.vendor?.name}</Label>
                        {item.ship ? <Text size={'small'}>{item.ship?.name}</Text> : null}
                        {item.schedule ? <Text size={'small'}>Voy #{item.schedule.voy}</Text> : null}
                    </> : null}
                </Stack>
            }
        },
        {
            key: "notes",
            name: 'Notes',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    <Text size={'small'}>{item.notes || ""}</Text>
                </Stack>
            }
        },
        {
            key: "priceBreakdown",
            name: 'Price Breakdown',
            minWidth: 150,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {(item.oceanFreight || "").trim() !== "" && Number(item.oceanFreight) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>OF</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.oceanFreight)}</Label>
                    </Stack> : null}
                    {(item.oceanFreight || "").trim() !== "" && Number(item.lss) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>LSS</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.lss)}</Label>
                    </Stack> : null}
                    {(item.thcOrigin || "").trim() !== "" && Number(item.thcOrigin) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>THC POL</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.thcOrigin)}</Label>
                    </Stack> : null}
                    {(item.loloOrigin || "").trim() !== "" && Number(item.loloOrigin) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>LOLO POL</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.loloOrigin)}</Label>
                    </Stack> : null}
                    {(item.thcDestination || "").trim() !== "" && Number(item.thcDestination) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>THC POD</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.thcDestination)}</Label>
                    </Stack> : null}
                    {(item.loloOrigin || "").trim() !== "" && Number(item.loloOrigin) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>LOLO POD</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.loloDestination)}</Label>
                    </Stack> : null}
                    {(item.apbs || "").trim() !== "" && Number(item.apbs) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>APBS</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.apbs)}</Label>
                    </Stack> : null}
                    {(item.cleaning || "").trim() !== "" && Number(item.cleaning) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>Cleaning</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.cleaning)}</Label>
                    </Stack> : null}
                    {(item.doc || "").trim() !== "" && Number(item.doc) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>Doc</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.doc)}</Label>
                    </Stack> : null}
                    {(item.seal || "").trim() !== "" && Number(item.seal) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>Seal</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.seal)}</Label>
                    </Stack> : null}
                    {(item.dutyStamp || "").trim() !== "" && Number(item.dutyStamp) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>Stamp</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.dutyStamp)}</Label>
                    </Stack> : null}
                    {(item.ops || "").trim() !== "" && Number(item.ops) > 0 ? <Stack horizontal tokens={{childrenGap: 10}}>
                        <Stack styles={{root: {width: 75}}}><Text size={'small'}>Ops</Text></Stack>
                        <Label size={'xsmall'}>: Rp. {GeneralService.getNumberWithSeparator(item.ops)}</Label>
                    </Stack> : null}
                </Stack>
            }
        },
        {
            key: "price",
            name: 'Price per Quantity',
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.priceType === 'perquantity' ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.price))}</Text> : null}
                    {item.priceType === 'lumpsum' ? <Text size={'small'} style={{ fontStyle: 'italic' }}>(lumpsum price)</Text> : null}
                </Stack>
            }
        },
        {
            key: "quantity",
            name: 'Qty',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}</Text> : null}
                </Stack>
            }
        },
        {
            key: "totalPrice",
            name: 'Total Price',
            minWidth: 125,
            maxWidth: 125,
            headerClassName: styles.priceColumn,
            className: styles.priceColumn,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Text> : null}
                    {item.id === 'footer' ? <>
                        <Text size={'xsmall'}>TOTAL</Text>
                        <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getTotalCost())}</Label>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "action",
            name: '',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item: ContainerShippingCostFormData, idx?: number) => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {item.id !== 'footer' && props.mode !== 'view' ? <>
                        <IconButton iconProps={{ iconName: 'Edit' }}
                            onClick={() => {
                                setActiveData(item);
                                setActiveSurface('form');
                            }} />
                        <IconButton iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                _onDelete(item);
                            }} />
                    </> : null}
                </Stack>
            }
        }
    ];

    const getTotalCost = () => {
        let totalCost = 0;
        data.forEach((d) => totalCost += Number(d.totalPrice));
        return totalCost;
    }

    const _onSurfaceDismissed = () => {
        setActiveSurface(undefined);
    }

    const _onSubmit = (cost: ContainerShippingCostFormData) => {
        let _data = data;

        if (!activeData) {
            _data = [...data, cost];
        } else {
            _data = [...data.map((d) => d.id === cost.id ? cost : d)];
        }

        props.onChange(_data);
        setActiveSurface(undefined);
    }

    const _onDelete = (cost: ContainerShippingCostFormData) => {
        const text = `Are you sure to remove container shipping cost Rp. ${GeneralService.getNumberWithSeparator(Number(cost.totalPrice))} by ${cost.vendor?.name}?`;
        if (window.confirm(text)) {
            const _data = data.filter((d) => d.id !== cost.id);
            props.onChange(_data);
        }
    }

    useEffect(() => {
        setData(props.data ? [...props.data] : []);
    }, [props.data]);

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal horizontalAlign={'space-between'}>
                <Label>Container Shipping</Label>
                {props.mode !== 'view' ? <ActionButton iconProps={{ iconName: "Add" }}
                    text={'Add container shipping'}
                    onClick={() => {
                        setActiveData(undefined);
                        setActiveSurface('form');
                    }} /> : null}
            </Stack>
            <Stack>
                {data.length > 0 ? <ShimmeredDetailsList items={data}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderDetailsFooter={(props) => {
                        return <DetailsRow
                            selectionMode={SelectionMode.none}
                            columns={props?.columns}
                            item={{
                                id: 'footer'
                            }}
                            itemIndex={data.length}
                        />
                    }} /> : null}
                {data.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No cost related to container shipping</Text> : null}
            </Stack>
        </Stack>
        {activeSurface === 'form' ? <ContainerShippingCostingForm quantityUnits={props.quantityUnits} data={activeData} onDismissed={_onSurfaceDismissed} onSubmit={_onSubmit} /> : null}
    </Stack>
}

export default ContainerShippingCosting;