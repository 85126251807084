import React from 'react';

// import styles
import parentStyles from './../style.module.scss';

// import router
import { RouteComponentProps } from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../../props/user';

// import services
import UserService from '../../services/users/user';
import ValidationService from '../../services/validation';

// import pages

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import LoadingComponent from '../../components/feedbacks/loading/index';
import { IRenderMessageBarProps } from '../../props/general';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import GeneralService from '../../services/general';
import SimpleMessageBarComponent from '../../components/feedbacks/simpleMessageBar';
import { ActionButton, TextField } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import LoadingDialogComponent from '../../components/feedbacks/loadingDialog';

interface IResetPasswordProps {
    user: IUserResourceShortProps;
    route: RouteComponentProps;
}

interface IResetPasswordState {
    loaded?: boolean;
    processing?: boolean;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    messageBar?: IRenderMessageBarProps;
    redirecting?: boolean;
    errorOldPassword?: string;
    errorNewPassword?: string;
    errorConfirmPassword?: string;
}

export default class ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordState> {
    constructor(props: IResetPasswordProps) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    }

    public componentDidMount = async () => {
        try {
            this.setState({ loaded: true });
        } catch (e) {
            this.setState({ loaded: true });
        }
    }

    private _onOldPasswordValueChanged = (evt?: any, value?: string) => {
        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
        this.setState({ oldPassword: value || "", errorOldPassword: validation.message });
    }

    private _onNewPasswordValueChanged = (evt?: any, value?: string) => {
        let { errorConfirmPassword } = this.state;
        let validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
        if ((value || "") !== this.state.confirmPassword && !validation.message && !errorConfirmPassword) {
            errorConfirmPassword = "Password baru tidak sama."
        } else if (!validation.message && (value || "") === this.state.confirmPassword) {
            errorConfirmPassword = undefined;
        }
        this.setState({ newPassword: value || "", errorNewPassword: validation.message, errorConfirmPassword });
    }

    private _onConfirmPasswordValueChanged = (evt?: any, value?: string) => {
        let validation = ValidationService.combination(value, ['required'], {});
        let errorMessage = validation.message;
        if ((value || "") !== this.state.newPassword && !errorMessage) {
            errorMessage = "Password baru tidak sama."
        } else if (!errorMessage && (value || "") === this.state.newPassword) {
            errorMessage = undefined;
        }
        this.setState({ confirmPassword: value || "", errorConfirmPassword: errorMessage });
    }

    private isSubmitButtonDisabled = () => {
        const { oldPassword, newPassword, confirmPassword, errorOldPassword, errorNewPassword, errorConfirmPassword } = this.state;
        if (errorConfirmPassword || errorNewPassword || errorOldPassword) {
            return true;
        } else if (oldPassword.trim() === "" || newPassword.trim() === "" || confirmPassword.trim() === "") {
            return true;
        }

        return false;
    }

    private _onSubmit = async () => {
        try {
            this.setState({ processing: true });

            const fd = new FormData();
            fd.append("oldPassword", this.state.oldPassword);
            fd.append("newPassword", this.state.newPassword);
            fd.append("confirmPassword", this.state.confirmPassword);

            const result = await UserService.updatePassword(fd);
            if (result && result.error && result.error === 'oldpassword') {
                this.setState({
                    messageBar: {
                        content: <Text variant={"small"}>Passowrd lama tidak sesuai.</Text>,
                        type: MessageBarType.error
                    },
                    errorOldPassword: "Password lama tidak sesuai.",
                    processing: false
                });
            } else {
                this.setState({ redirecting: true });
                setTimeout(() => {
                    window.location.href = "/";
                }, 4000);
            }
        } catch (e) {
            this.setState({
                messageBar: {
                    content: <Text variant={"small"}>{GeneralService.generateErrorMessage(e)}</Text>,
                    type: MessageBarType.error
                },
                processing: false
            });
        }
    }

    public render() {
        const { user } = this.props;
        const { processing, oldPassword, newPassword, confirmPassword, errorOldPassword, errorNewPassword, errorConfirmPassword } = this.state;

        return (
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack className={parentStyles.header}>
                    <Text className={parentStyles.text} as={"h2"} variant={"medium"}>Ubah Password</Text>
                </Stack>
                {this.state.messageBar ? <SimpleMessageBarComponent properties={this.state.messageBar} onDismiss={() => { this.setState({ messageBar: undefined }) }} /> : null}
                {!this.state.loaded ? <LoadingComponent label={"Harap tunggu ..."} /> : null}
                {
                    this.state.loaded ? (
                        <>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Halo {user.name}!</Text>
                                <Text style={{ textAlign: "center" }}>Password yang anda gunakan merupakan password sementara. Demi keamanan akun anda, maka password anda harus diubah sebelum dapat mengakses portal administrasi Jejak Logistik.</Text>
                            </Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Stack.Item>
                                    <TextField label={"Password Lama"} value={oldPassword}
                                        type={"password"}
                                        onChange={this._onOldPasswordValueChanged}
                                        errorMessage={errorOldPassword}
                                        required />
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField label={"Password Baru"} value={newPassword}
                                        type={"password"}
                                        onChange={this._onNewPasswordValueChanged}
                                        errorMessage={errorNewPassword}
                                        required />
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField label={"Ulangi Password Baru"} value={confirmPassword}
                                        type={"password"}
                                        onChange={this._onConfirmPasswordValueChanged}
                                        errorMessage={errorConfirmPassword}
                                        required />
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                {processing ? <LoadingComponent label={"Mengubah password ..."} labelPosition={"right"} spinnerPosition={"baseline"} /> : null}
                                {!processing ? <PrimaryButton text={"Ubah Password"} disabled={this.isSubmitButtonDisabled()} onClick={this._onSubmit} /> : null}
                            </Stack>
                        </>
                    ) : null
                }
                {this.state.redirecting && this.props.user ? <LoadingDialogComponent title={`Halo ${this.props.user.name}!`} secondaryText={"Password anda telah berhasil diubah. Harap tunggu sementara kami meneruskan anda ke halaman utama portal administrasi Jejak Logistik."} /> : null}
            </Stack>
        );
    }
}