import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import ExternalBankAccountService from '../../../../services/data/bankaccounts/external';

// props
import { IExternalBankAccountResourceShortProps } from '../../../../props/data/bankaccounts/external';

// components
import { Dropdown, Link, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import ExternalBankAccountForm from '../../../data/bankaccounts/external/form';

type SelectExternalBankAccountProps = {
    vendorId?: string;
    employeeId?: string;
    selected?: IExternalBankAccountResourceShortProps;
    options?: IExternalBankAccountResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IExternalBankAccountResourceShortProps): void;
}

const SelectExternalBankAccount: React.FC<SelectExternalBankAccountProps> = (props: SelectExternalBankAccountProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IExternalBankAccountResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IExternalBankAccountResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const qs: string[] = [];
        if (props.vendorId) { qs.push(`vendor_id=${props.vendorId}`) }
        if (props.employeeId) { qs.push(`employee_id=${props.employeeId}`) }

        const results = await ExternalBankAccountService.retrieve(qs.join("&"));
        setOptions(results.data);
        setLoaded(true);
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack tokens={{childrenGap: 3}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack grow={1}>
                    <Dropdown disabled={props.disabled || !loaded}
                        selectedKey={selected?.id}
                        options={options.map((ba) => {
                            return { key: ba.id, text: `${ba.bank.name} - ${ba.accountNumber}` }
                        })}
                        onChange={(evt, opt) => {
                            const selected = options.find((o) => o.id === opt?.key as string);
                            props.onChange(selected);
                        }} />
                </Stack>
            </Stack>
            {props.allowCreate && props.vendorId ? <Text size={'small'}>Cannot find bank account? <Link onClick={() => { setActiveSurface('create') }}>Click here</Link> to create bank account</Text> : null}
        </Stack>
        {activeSurface === 'create' ? <ExternalBankAccountForm vendorId={props.vendorId} employeeId={props.employeeId} onDismissed={async (refresh, bankAccount) => {
            setActiveSurface(undefined);
            setLoaded(false);
            await init();
            props.onChange(bankAccount);
        }} type={props.vendorId ? 'vendor' : 'employee'} /> : null}
    </Stack>;
};

export default SelectExternalBankAccount;
