import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// props

// components
import { ActionButton, CommandBar, DatePicker, Dropdown, ICommandBarItemProps, IconButton, Stack } from '@fluentui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '../../../../typography/text';
import Heading from '../../../../typography/heading';
import Label from '../../../../typography/label';
import moment from 'moment';
import ErrorService from '../../../../../services/general/error';
import { faCalendar, faHashtag, faHome, faLocationDot, faShip, faTag, faTrain, faTruck, faUser, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ActivitiesService from '../../../../../services/activities/activities';
import { IActivityResourceShortProps } from '../../../../../props/activities';
import OrderService from '../../../../../services/orders/order';
import TrackingTypes from '../../../../../manifests/trackingTypes';
import { NavLink } from 'react-router-dom';
import GeneralService from '../../../../../services/general';
import Tag from '../../../../uiframeworks/tag';

export type DashboardScheduleListViewProps = {
    activities: IActivityResourceShortProps;
    startDate: string;
    endDate: string;
    onSelectedDateChange(startDate: string, endDate: string): void;
};

type CalendarItemProps = {
    date: string;
    link?: string;
    title?: string;
    content?: JSX.Element;
    backgroundColor?: string;
    items?: {
        icon?: IconProp;
        title?: string;
        value: string;
        link?: string;
    }[];
}

const DashboardScheduleListView: React.FC<DashboardScheduleListViewProps> = observer((props: DashboardScheduleListViewProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [items, setItems] = React.useState<CalendarItemProps[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<{ startDate: string, endDate: string }>({
        startDate: props.startDate,
        endDate: props.endDate
    });

    useEffect(() => {
        init();
    }, [props.activities, props.startDate, props.endDate]);

    const init = () => {
        const _items = convertToCalendarItems(props.activities);
        setItems(_items);
        setSelectedDate({ startDate: props.startDate, endDate: props.endDate });
    }

    const convertToCalendarItems = (activity: IActivityResourceShortProps): CalendarItemProps[] => {
        const items: CalendarItemProps[] = [];

        (activity.loading || []).forEach((loading) => {
            const details = loading.loadDetails;
            const backgroundColor = TrackingTypes.find((tt) => tt.key === loading.type)?.color || '#C8C6C4';
            if (details) {
                const data: CalendarItemProps = {
                    date: details.actualStartDate || details.estimatedStartDate || "",
                    title: 'Loading',
                    link: `/orders/${loading.orderId}/trackings`,
                    backgroundColor,
                    items: [
                        { value: "Go to order", icon: faTag, title: 'Order Number', link: `/orders/${loading.orderId}` },
                        { value: details.address, icon: faLocationDot, title: 'Location' }
                    ]
                };

                items.push(data);
            }
        });

        (activity.unloading || []).forEach((unloading) => {
            const details = unloading.unloadingDetails;
            const backgroundColor = TrackingTypes.find((tt) => tt.key === unloading.type)?.color || '#C8C6C4';
            if (details) {
                const data: CalendarItemProps = {
                    date: details.actualStartDate || details.estimatedStartDate || "",
                    title: 'Unloading',
                    link: `/orders/${unloading.orderId}/trackings`,
                    backgroundColor,
                    items: [
                        { value: "Go to order", icon: faTag, title: 'Order Number', link: `/orders/${unloading.orderId}` },
                        { value: details.address, icon: faLocationDot, title: 'Location' }
                    ]
                };

                items.push(data);
            }
        });

        (activity.dooring || []).forEach((dooring) => {
            const details = dooring.dooringDetails;
            if (details) {
                const departureDate = details.actualDepartureDate || details.estimatedDepartureDate;
                const arrivalDate = details.actualArrivalDate || details.estimatedArrivalDate;
                const backgroundColor = TrackingTypes.find((tt) => tt.key === dooring.type)?.color || '#C8C6C4';
                if (moment(departureDate).format("DDMMYYYY") === moment(arrivalDate).format("DDMMYYYY")) {
                    const data: CalendarItemProps = {
                        date: moment(departureDate).toISOString(),
                        title: 'Trucking',
                        link: `/orders/${dooring.orderId}/trackings`,
                        backgroundColor,
                        items: [
                            { value: "Go to order", icon: faTag, title: 'Order Number', link: `/orders/${dooring.orderId}` },
                            { value: details.originAddress, icon: faLocationDot, title: "Departing From" },
                            { value: details.destinationAddress, icon: faLocationDot, title: "Arriving At" }
                        ]
                    };

                    if (details.truck) {
                        data.items?.push({ value: `${details.truck.registrationNumber} (${details.truck.vendor.name})`, icon: faTruck, title: "Truck", link: `/operationals/trucks/${details.truck.id}` });

                        if (details.driverName || details.driverPhone) {
                            data.items?.push({ value: `${(details.driverName || "") + " "}${details.driverPhone ? `(${details.driverPhone})` : ""}`, icon: faUser, title: "Driver" });
                        }
                    }

                    items.push(data);
                } else {
                    const departureData: CalendarItemProps = {
                        date: moment(departureDate).toISOString(),
                        title: 'Truck Departing',
                        link: `/orders/${dooring.orderId}/trackings`,
                        backgroundColor,
                        items: [
                            { value: "Go to order", icon: faTag, title: 'Order Number', link: `/orders/${dooring.orderId}` },
                            { value: details.originAddress, icon: faLocationDot, title: "Departing From" },
                            { value: details.destinationAddress, icon: faLocationDot, title: "Arriving At" }
                        ]
                    };

                    const arrivalData: CalendarItemProps = {
                        date: moment(arrivalDate).toISOString(),
                        title: 'Truck Arriving',
                        link: `/orders/${dooring.orderId}/trackings`,
                        backgroundColor,
                        items: [
                            { value: "Go to order", icon: faTag, title: 'Order Number', link: `/orders/${dooring.orderId}` },
                            { value: details.originAddress, icon: faLocationDot, title: "Departing From" },
                            { value: details.destinationAddress, icon: faLocationDot, title: "Arriving At" }
                        ]
                    };

                    if (details.truck) {
                        departureData.items?.push({ value: `${details.truck.registrationNumber} (${details.truck.vendor.name})`, icon: faTruck, title: "Truck", link: `/operationals/trucks/${details.truck.id}` });
                        arrivalData.items?.push({ value: `${details.truck.registrationNumber} (${details.truck.vendor.name})`, icon: faTruck, title: "Truck", link: `/operationals/trucks/${details.truck.id}` });

                        if (details.driverName || details.driverPhone) {
                            departureData.items?.push({ value: `${(details.driverName || "") + " "}${details.driverPhone ? `(${details.driverPhone})` : ""}`, icon: faUser, title: "Driver" });
                            arrivalData.items?.push({ value: `${(details.driverName || "") + " "}${details.driverPhone ? `(${details.driverPhone})` : ""}`, icon: faUser, title: "Driver" });
                        }
                    }

                    items.push(departureData);
                    items.push(arrivalData);
                }


            }
        });

        (activity.shipSchedules || []).forEach((schedule) => {
            const departureDate = schedule.actualDeparture || schedule.estimatedDeparture;
            const arrivalDate = schedule.actualArrival || schedule.estimatedArrival;
            const backgroundColor = TrackingTypes.find((tt) => tt.key === 'ship')?.color || '#C8C6C4';
            if (moment(departureDate).format("DDMMYYYY") === moment(arrivalDate).format("DDMMYYYY")) {
                const data: CalendarItemProps = {
                    date: moment(departureDate).toISOString(),
                    title: `Ship`,
                    link: `/operationals/ships/${schedule.ship.id}/schedules/${schedule.id}`,
                    backgroundColor,
                    items: [
                        { value: `${schedule.ship.name} (${schedule.ship.vendor.name})`, icon: faShip, title: 'Ship', link: `/operationals/ships/${schedule.ship.id}` },
                        { value: schedule.voy, icon: faHashtag, title: 'Voy', link: `/operationals/ships/${schedule.ship.id}/schedules/${schedule.id}` },
                        { value: schedule.originAddress, icon: faHome, title: 'Port of Loading' },
                        { value: schedule.destinationAddress, icon: faLocationDot, title: 'Port of Discharge' }
                    ]
                };

                items.push(data);
            } else {
                const departureData: CalendarItemProps = {
                    date: moment(departureDate).toISOString(),
                    title: `Ship Departing`,
                    link: `/operationals/ships/${schedule.ship.id}/schedules/${schedule.id}`,
                    backgroundColor,
                    items: [
                        { value: `${schedule.ship.name} (${schedule.ship.vendor.name})`, icon: faShip, title: 'Ship', link: `/operationals/ships/${schedule.ship.id}` },
                        { value: schedule.voy, icon: faHashtag, title: 'Voy', link: `/operationals/ships/${schedule.ship.id}/schedules/${schedule.id}` },
                        { value: schedule.originAddress, icon: faHome, title: 'Port of Loading' },
                        { value: schedule.destinationAddress, icon: faLocationDot, title: 'Port of Discharge' },
                        { value: moment(schedule.estimatedArrival).format("DD/MM/YYYY"), icon: faCalendar, title: "ETA" }
                    ]
                };

                const arrivalData: CalendarItemProps = {
                    date: moment(arrivalDate).toISOString(),
                    title: `Ship Arriving`,
                    link: `/operationals/ships/${schedule.ship.id}/schedules/${schedule.id}`,
                    backgroundColor,
                    items: [
                        { value: `${schedule.ship.name} (${schedule.ship.vendor.name})`, icon: faShip, title: 'Ship', link: `/operationals/ships/${schedule.ship.id}` },
                        { value: schedule.voy, icon: faHashtag, title: 'Voy', link: `/operationals/ships/${schedule.ship.id}/schedules/${schedule.id}` },
                        { value: schedule.originAddress, icon: faHome, title: 'Port of Loading' },
                        { value: schedule.destinationAddress, icon: faLocationDot, title: 'Port of Discharge' }
                    ]
                };

                if (schedule.actualDeparture) {
                    arrivalData.items?.push({ value: moment(schedule.actualDeparture).format("DD/MM/YYYY"), icon: faCalendar, title: "Departure Date" });
                } else {
                    arrivalData.items?.push({ value: moment(schedule.estimatedDeparture).format("DD/MM/YYYY"), icon: faCalendar, title: "ETD" });
                }

                items.push(departureData);
                items.push(arrivalData);
            }
        });

        (activity.trainSchedules || []).forEach((schedule) => {
            const departureDate = schedule.actualDeparture || schedule.estimatedDeparture;
            const arrivalDate = schedule.actualArrival || schedule.estimatedArrival;
            const backgroundColor = TrackingTypes.find((tt) => tt.key === 'train')?.color || '#C8C6C4';
            if (moment(departureDate).format("DDMMYYYY") === moment(arrivalDate).format("DDMMYYYY")) {
                const data: CalendarItemProps = {
                    date: moment(departureDate).toISOString(),
                    title: `Train`,
                    link: `/operationals/trains/${schedule.train.id}/schedules/${schedule.id}`,
                    backgroundColor,
                    items: [
                        { value: `${schedule.train.name} (${schedule.train.vendor.name})`, icon: faShip, title: 'Train', link: `/operationals/trains/${schedule.train.id}` },
                        { value: schedule.voy, icon: faHashtag, title: 'Voy', link: `/operationals/trains/${schedule.train.id}/schedules/${schedule.id}` },
                        { value: schedule.originAddress, icon: faHome, title: 'Station of Loading' },
                        { value: schedule.destinationAddress, icon: faLocationDot, title: 'Station of Unloading' }
                    ]
                };

                items.push(data);
            } else {
                const departureData: CalendarItemProps = {
                    date: moment(departureDate).toISOString(),
                    title: `Train Departing`,
                    link: `/operationals/trains/${schedule.train.id}/schedules/${schedule.id}`,
                    backgroundColor,
                    items: [
                        { value: `${schedule.train.name} (${schedule.train.vendor.name})`, icon: faShip, title: 'Train', link: `/operationals/trains/${schedule.train.id}` },
                        { value: schedule.voy, icon: faHashtag, title: 'Voy', link: `/operationals/trains/${schedule.train.id}/schedules/${schedule.id}` },
                        { value: schedule.originAddress, icon: faHome, title: 'Station of Loading' },
                        { value: schedule.destinationAddress, icon: faLocationDot, title: 'Station of Unloading' },
                        { value: moment(schedule.estimatedArrival).format("DD/MM/YYYY"), icon: faCalendar, title: "ETA" }
                    ]
                };

                const arrivalData: CalendarItemProps = {
                    date: moment(arrivalDate).toISOString(),
                    title: `Train Arriving`,
                    link: `/operationals/trains/${schedule.train.id}/schedules/${schedule.id}`,
                    backgroundColor,
                    items: [
                        { value: `${schedule.train.name} (${schedule.train.vendor.name})`, icon: faShip, title: 'Train', link: `/operationals/trains/${schedule.train.id}` },
                        { value: schedule.voy, icon: faHashtag, title: 'Voy', link: `/operationals/trains/${schedule.train.id}/schedules/${schedule.id}` },
                        { value: schedule.originAddress, icon: faHome, title: 'Station of Loading' },
                        { value: schedule.destinationAddress, icon: faLocationDot, title: 'Station of Unloading' },
                        { value: moment(schedule.estimatedArrival).format("DD/MM/YYYY"), icon: faCalendar, title: "Departure Date" }
                    ]
                };

                items.push(departureData);
                items.push(arrivalData);
            }
        });

        return items;
    }

    const renderList = (): JSX.Element => {
        const list: JSX.Element[] = [];
        const diff = moment(selectedDate.endDate).diff(moment(selectedDate.startDate), 'days');
        for (var counter = 0; counter < diff; counter++) {
            const currentDate = moment(selectedDate.startDate).add(counter, 'days');
            const date = currentDate.format("dddd, DD MMM YYYY");
            const activities = items.filter((item) => moment(item.date).format("DDMMYYYY") === currentDate.format("DDMMYYYY"));
            const isToday = currentDate.format("DDMMYYYY") === moment().format("DDMMYYYY");

            list.push(<Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'} horizontalAlign={'space-between'}>
                    <Label size={'small'}>{date}</Label>
                    {isToday ? <Tag variant={'warning'} text={'Today'} /> : null}
                </Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    {activities.length > 0 ? activities.map((act, idx) => {
                        return <Stack tokens={{ childrenGap: 10 }} className={styles.card}>
                            <Stack key={`${currentDate.format('DDMMYYYY')}_${idx}`} horizontal tokens={{ childrenGap: 8 }}>
                                {act.backgroundColor ? <Stack className={styles.pipe} styles={{ root: { backgroundColor: act.backgroundColor } }}> </Stack> : null}
                                {!act.link ? <Label>{act.title || ""}</Label> : null}
                                {act.link ? <NavLink target={'_blank'} to={act.link}><Label>{act.title || ""}</Label></NavLink> : null}
                            </Stack>
                            {act.items && act.items.length > 0 ? <Stack tokens={{ childrenGap: 5 }} horizontal wrap>
                                {act.items.map((item) => {
                                    return <Stack className={styles.content} horizontal tokens={{ childrenGap: 3 }}>
                                        {item.icon ? <Stack styles={{ root: { width: 20, marginTop: 2 } }}><FontAwesomeIcon icon={item.icon} fontSize={12} /></Stack> : null}
                                        <Stack>
                                            {item.title ? <Label size='xsmall'>{item.title}</Label> : null}
                                            {!item.link ? <Text size={'xsmall'}>{item.value}</Text> : null}
                                            {item.link ? <NavLink target={'_blank'} to={item.link}><Text size={'xsmall'}>{item.value}</Text></NavLink> : null}
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack> : null}
                        </Stack>
                    }) : null}
                    {activities.length < 1 ? <Text size={'small'}>No schedule on this day</Text> : null}
                </Stack>
            </Stack>);
        }

        return <>{list}</>;
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <DatePicker value={moment(props.startDate).toDate()}
                    formatDate={GeneralService.formatDate}
                    onSelectDate={(date) => {
                        const startDate = moment(date).startOf('day').toISOString();
                        const endDate = moment(startDate).add(4, 'day').endOf('day').toISOString();

                        props.onSelectedDateChange(startDate, endDate);
                    }}
                    styles={{ root: { width: 200 } }} />
            }
        });

        return { items, farItems };
    }

    return <Stack className={styles.container}>
        <Stack className={styles.list} tokens={{ childrenGap: 20 }}>
            <CommandBar {...getCommandBarItems()}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }} />
            {renderList()}
        </Stack>
    </Stack>;
});

export default DashboardScheduleListView;