import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import { shipScheduleStatuses } from '../../../../../../utils/status';
import moment from 'moment';

// assets
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import ShipSchedulesService from '../../../../../../services/data/ships/shipSchedules';

// props
import { IShipScheduleResourceShort } from '../../../../../../props/data/ships';
import { IOrderResourceProps } from '../../../../../../props/orders/order';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../../uiframeworks/detailsStack';
import ComingSoon from '../../../../../uiframeworks/comingSoon';
import Tag from '../../../../../uiframeworks/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShipScheduleForm from './../form';
import { NavLink } from 'react-router-dom';
import OrdersList from '../../../../../orders/general/list';
import ShipScheduleDelaysList from '../../delays/list';
import ShipScheduleDocumentsList from '../../documents/general/list';

interface IShipScheduleDetailsProps {
    scheduleId: string;
}

const ShipScheduleDetails: React.FC<IShipScheduleDetailsProps> = (props: IShipScheduleDetailsProps) => {
    const { banner, topbar } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [schedule, setSchedule] = React.useState<IShipScheduleResourceShort>();
    const [orders, setOrders] = React.useState<IOrderResourceProps[]>([]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onGetSchedule();
    }, []);

    const _onGetSchedule = async () => {
        try {
            const _schedule = await ShipSchedulesService.get(props.scheduleId);
            setSchedule(_schedule);

            const _orders = await ShipSchedulesService.getOrders(props.scheduleId);
            setOrders(_orders);

            topbar.show(_schedule.ship.name + " - Voy " + _schedule.voy, [
                { key: "home", icon: faHome, href: "/" },
                { key: "operationals", text: 'Operationals' },
                { key: "vendors", text: 'Vendors', href: "/operationals/vendors" },
                { key: _schedule.ship.vendor.id, text: _schedule.ship.vendor.name, href: "/operationals/vendors/" + _schedule.ship.vendor.id },
                { key: _schedule.ship.id, text: _schedule.ship.name, href: "/operationals/ships/" + _schedule.ship.id },
                { key: _schedule.id, text: "Voy " + _schedule.voy }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_ship_schedule_error',
                text: 'Failed to get ship schedule details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'update',
            text: 'Update Details',
            onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
        });

        items.push({
            key: 'updateStatus',
            text: 'Update Status',
            onRender: () => <ActionButton text={'Update Status'} onClick={() => setActiveSurface('updatestatus')} />
        });

        if (schedule && schedule.status !== 'arrived' && schedule.status !== 'cancelled') {
            items.push({
                key: 'updateStatus',
                text: 'Delay Schedule',
                onRender: () => <ActionButton text={'Delay Schedule'} onClick={() => setActiveSurface('delay')} />
            });
        }

        return { items, farItems };
    }

    const getPropertiesSection = (_schedule: IShipScheduleResourceShort): IStackSectionProps => {
        const statusProps = shipScheduleStatuses.find((st) => st.key === _schedule.status);

        return {
            header: {
                title: "Details"
            },
            key: 'schedule_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Status</Label>
                        <Stack horizontal><Tag text={statusProps?.text || 'Scheduled'} variant={statusProps?.type || 'default'} /></Stack>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Voy</Label>
                        <Text>{_schedule.voy}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Port of Loading (POL)</Label>
                        <Text>{_schedule.originAddress}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Port of Discharge (POD)</Label>
                        <Text>{_schedule.destinationAddress}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Departure Date</Label>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            {_schedule.actualDeparture ? <>
                                <Text>{moment(_schedule.actualDeparture).format("DD/MM/YYYY")}</Text>
                                <Text style={{ fontStyle: 'italic' }}>(Actual)</Text>
                            </> : null}
                            {!_schedule.actualDeparture ? <>
                                <Text>{moment(_schedule.estimatedDeparture).format("DD/MM/YYYY")}</Text>
                                <Text style={{ fontStyle: 'italic' }}>(Estimated)</Text>
                            </> : null}
                        </Stack>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Arrival Date</Label>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            {_schedule.actualArrival ? <>
                                <Text>{moment(_schedule.actualArrival).format("DD/MM/YYYY")}</Text>
                                <Text style={{ fontStyle: 'italic' }}>(Actual)</Text>
                            </> : null}
                            {!_schedule.actualArrival ? <>
                                <Text>{moment(_schedule.estimatedArrival).format("DD/MM/YYYY")}</Text>
                                <Text style={{ fontStyle: 'italic' }}>(Estimated)</Text>
                            </> : null}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getShipDetails = (_schedule: IShipScheduleResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Ship Details"
            },
            key: 'ship_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack>
                    <Label size={'small'}>Name</Label>
                    <NavLink to={'/operationals/ships/' + _schedule.ship.id}><Text>{_schedule.ship.name}</Text></NavLink>
                </Stack>
                <Stack>
                    <Label size={'small'}>Registration Number</Label>
                    <Text>{_schedule.ship.name}</Text>
                </Stack>
                <Stack>
                    <Label size={'small'}>Vendor</Label>
                    <NavLink to={'/operationals/vendors/' + _schedule.ship.vendor.id}><Text>{_schedule.ship.vendor.name}</Text></NavLink>
                </Stack>
            </Stack>
        }
    }

    const getTabsSection = (_schedule: IShipScheduleResourceShort): IStackSectionProps => {
        return {
            key: 'schedule_tabs',
            isCard: true,
            tabs: [
                {
                    key: 'related_orders',
                    title: 'Related Order(s)',
                    content: <Stack>
                        <OrdersList variant='plain' columns={['orderNumber', 'customer', 'batch', 'loads']} hideCommandBar={true} orders={orders} />
                    </Stack>
                },
                {
                    key: 'related_documents',
                    title: 'Related Document(s)',
                    content: <Stack>
                        <ShipScheduleDocumentsList shceduleId={_schedule.id} />
                    </Stack>
                },
                {
                    key: 'delay_history',
                    title: 'Delay Histories',
                    content: <Stack>
                        <ShipScheduleDelaysList scheduleId={_schedule.id} />
                    </Stack>
                },
                {
                    key: 'payments_list',
                    title: 'Payment(s) List',
                    content: <Stack>

                    </Stack>
                }
            ]
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetSchedule() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving customer details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && schedule ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getPropertiesSection(schedule)]} right={getShipDetails(schedule)} />
                <DetailsStack left={[getTabsSection(schedule)]} />
            </Stack>
            {activeSurface === 'update' ? <ShipScheduleForm shipId={schedule.ship.id} scheduleId={schedule.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default ShipScheduleDetails;
