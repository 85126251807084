import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { ActionButton, Link, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { IVendorResourceShortProps } from '../../../../props/data/vendors';
import PermissionsService from '../../../../services/permissions';
import { useStore } from '../../../../stores/root';
import { ITrainResourceShort } from '../../../../props/data/trains';
import TrainForm from '../../../operationals/trains/general/form';
import TrainsService from '../../../../services/data/trains';

type SelectTrainProps = {
    vendor?: IVendorResourceShortProps;
    selected?: ITrainResourceShort;
    options?: ITrainResourceShort[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: ITrainResourceShort): void;
}

const SelectTrain: React.FC<SelectTrainProps> = (props: SelectTrainProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<ITrainResourceShort | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    const _onSearchTrain = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                if (props.vendor) { qs.push(`vendor_id=${props.vendor.id}`) }
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                const options = (await TrainsService.retrieve(qs.join("&"))).map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Train"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onRenderSuggestionsItem={(props: any) => {
                    const md = props.metadata as ITrainResourceShort;

                    return <Stack styles={{ root: { padding: 5 }}} horizontalAlign='baseline'>
                        <Label size={'small'}>{md.name}</Label>
                        <Text size={'small'}>{md.vendor.name}</Text>
                    </Stack>;
                }}
                onResolveSuggestions={_onSearchTrain}
                onEmptyResolveSuggestions={() => _onSearchTrain('')}
            />
            {props.allowCreate && PermissionsService.hasPermission(['trains.create'], user.permissions) ? <Text size={'small'}>Cannot find train? <Link onClick={() => {setActiveSurface('create')}}>Click here</Link> to create train</Text> : null}
        </> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack>
                <Label size={'small'}>{selected.name}</Label>
                <Text size={'small'}>{selected.name}</Text>
                <Text size={'small'}>{selected.vendor.name}</Text>
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
        {activeSurface === 'create' ? <TrainForm vendorId={props.vendor?.id} onDismissed={(refresh, train) => {
            setActiveSurface(undefined);
            props.onChange(train);
        }} /> : null}
    </Stack>;
};

export default SelectTrain;
