import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { IColumn, Stack, IContextualMenuItem, ActionButton, ICommandBarItemProps, SearchBox, CommandBar, ShimmeredDetailsList, SelectionMode, Text } from '@fluentui/react';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import GlobalConfig from '../../../../../../config';
import { IPaginationResourceShort } from '../../../../../../props/general';
import { IUserResourceShortProps } from '../../../../../../props/user';
import ErrorService from '../../../../../../services/general/error';
import { useStore } from '../../../../../../stores/root';
import Pagination from '../../../../../uiframeworks/pagination';
import GeneralService from '../../../../../../services/general';
import { IEmployedSalaryResourceShort } from '../../../../../../props/users/user';
import { EmployeeSalaryService } from '../../../../../../services/users/employee';
import EmployedSalaryForm from '../form';
import moment from 'moment';
import DeleteSalaryForm from '../form/delete';
import PermissionsService from '../../../../../../services/permissions';
import InactivateSalaryForm from '../form/inactivate';
import ActivateSalaryForm from '../form/activate';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';

interface ISalaryListProps {
  employeeId: string;
  qs?: string[];
  hideCommandBar?: boolean;
  hideSearch?: boolean;
  hideCreateButton?: boolean;
  isHeaderVisible?: boolean;
  columns?: TSalariesListColumn[];
}

type TSalariesListColumn = 'sales' | 'amount' | 'action' | 'date';

const SalaryList: React.FC<ISalaryListProps> = (props: ISalaryListProps) => {
  const { banner, user } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showingMore, setShowingMore] = React.useState<boolean>(false);
  const [salaries, setSalaries] = React.useState<IEmployedSalaryResourceShort[]>([]);
  const [activeSalary, setActiveSalary] = React.useState<IEmployedSalaryResourceShort | undefined>();
  const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
  const [keyword, setKeyword] = React.useState<string>("");
  const [activeSurface, setActiveSurface] = React.useState<'create' | 'update' | 'delete' | 'activate' | 'inactivate' | undefined>();

  const shownColumns = props.columns || [
    'sales',
    'amount',
    'action',
    'date'
  ];

  const columns: IColumn[] = [
    {
      key: "amount",
      fieldName: "amount",
      name: "Amount",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IEmployedSalaryResourceShort) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Text>Rp {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
        </Stack.Item>;
      }
    },
    {
      key: "actions",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: IEmployedSalaryResourceShort) => {
        let menuItems: IContextualMenuItem[] = [];

        if (PermissionsService.hasPermission(['superuser', 'hr'], user.roles)) {
          menuItems.push({
            key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
              setActiveSalary(item);
              setActiveSurface('update');
            }
          });

          menuItems.push({
            key: "delete", text: "Delete", iconProps: { iconName: "Trash" }, onClick: () => {
              setActiveSalary(item);
              setActiveSurface('delete');
            }
          })

          if (item.status.toLowerCase() === 'active') {
            menuItems.push({
              key: "inactivate", text: "Set as Inactive", iconProps: { iconName: "Cancel" }, onClick: () => {
                setActiveSalary(item);
                setActiveSurface('inactivate');
              }
            })
          } else if (item.status.toLowerCase() === 'inactive' && moment().diff(moment(item.endDate)) < 0) {
            menuItems.push({
              key: "activate", text: "Set as Active", iconProps: { iconName: "CheckMark" }, onClick: () => {
                setActiveSalary(item);
                setActiveSurface('activate');
              }
            })
          }
        }

        return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
          <Stack.Item className={"detailsListActionRow"}>
            <ActionButton className={'detailsListActionButton'} menuProps={{
              items: menuItems
            }} />
          </Stack.Item>
        </Stack.Item>;
      }
    },
    {
      key: "status",
      fieldName: "status",
      name: "Status",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: IEmployedSalaryResourceShort) => {
        let tagVariant: TTagVariant = 'active';
        let tagText: string = item.status;

        if (moment().diff(moment(item.endDate)) > 0) {
          tagVariant = 'error';
          tagText = 'Expired';
        } else if (item.status.toLowerCase() === 'active') {
          tagVariant = 'active';
        } else if (item.status.toLowerCase() === 'inactive') {
          tagVariant = 'error';
        }

        return <Stack horizontal styles={{ root: { padding: '2px 0px' } }}>
          <Tag variant={tagVariant} text={tagText} />
        </Stack>;
      }
    },
    {
      key: "date",
      fieldName: "date",
      name: "Active Date",
      minWidth: 200,
      onRender: (item: IEmployedSalaryResourceShort) => {
        return <Stack horizontal tokens={{childrenGap: 8}} styles={{ root: { padding: '4px 0px' } }}>
          <Text>{moment(item.startDate).format('DD/MM/YYYY')}</Text>
          <Text>-</Text>
          <Text>{moment(item.endDate).format('DD/MM/YYYY')}</Text>
        </Stack>;
      }
    }
  ];

  useEffect(() => {
    _onRetrieveSalaries();
  }, [keyword]);

  const _onRetrieveSalaries = async (pageNumber?: number,) => {
    try {
      setLoaded(false);
      const qs: string[] = props.qs || [];
      qs.push(`top=${GlobalConfig.defaultTop}`);
      if (pageNumber) { qs.push(`page=${pageNumber}`); }
      if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

      const results = await EmployeeSalaryService.retrieve(props.employeeId, qs.join('&'));
      setSalaries(results.data);
      setPagination(results.pagination);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'salary_list_error',
        text: 'Failed to retrieve salary list. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const _onKeywordChanged = (value?: string) => {
    setLoaded(true);
    setKeyword(value || "");
  }

  const getCommandBarItems = () => {
    let items: ICommandBarItemProps[] = [];
    let farItems: ICommandBarItemProps[] = [];

    // items.push({
    //   key: "search",
    //   onRender: () => {
    //     return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
    //   }
    // });

    return { items, farItems };
  }

  const _onSurfaceDismissed = (refresh?: boolean) => {
    setActiveSurface(undefined);
    setActiveSalary(undefined);

    if (refresh) { _onRetrieveSalaries() }
  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    {!props.hideCommandBar ? <CommandBar
      items={getCommandBarItems().items}
      farItems={getCommandBarItems().farItems}
      styles={{
        root: {
          padding: 0,
          height: 'unset',
          backgroundColor: 'transparent'
        }
      }}
      ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
    <Stack tokens={{ childrenGap: 10 }}>
      {pagination && salaries.length > 0 ? <Pagination goToPage={_onRetrieveSalaries} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " salary(s) found"} /> : null}
      <Stack className={styles.list}>
        {
          !loaded || (loaded && salaries.length > 0) ? (
            <>
              <ShimmeredDetailsList
                setKey="items"
                items={salaries}
                columns={PermissionsService.hasPermission(['superuser', 'hr'], user.roles) ? columns : columns.filter(f => f.key !== 'actions')}
                selectionMode={SelectionMode.none}
                enableShimmer={!loaded}
                shimmerLines={5}
                onShouldVirtualize={() => false}
                isHeaderVisible={props.isHeaderVisible}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details" />
            </>
          ) : null
        }
        {loaded && salaries.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
          <Text>Salary(s) not found</Text>
        </Stack> : null}
      </Stack>
      {pagination && salaries.length > 0 ? <Pagination goToPage={_onRetrieveSalaries} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
    </Stack>
    {activeSurface === 'create' ? <EmployedSalaryForm onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'update' && activeSalary ? <EmployedSalaryForm employeeId={props.employeeId} salaryId={activeSalary.id} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'delete' && activeSalary ? <DeleteSalaryForm salary={activeSalary} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'inactivate' && activeSalary ? <InactivateSalaryForm salary={activeSalary} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'activate' && activeSalary ? <ActivateSalaryForm salary={activeSalary} onDismissed={_onSurfaceDismissed} /> : null}
  </Stack>;
};

export default SalaryList;
