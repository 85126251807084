import { Stack } from '@fluentui/react';
import React, { } from 'react';
import styles from './styles.module.scss';

// assets

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './../../typography/text';
import { NavLink } from 'react-router-dom';
import Label from '../../typography/label';

import NoAccessVector from './../../../assets/vectors/no-access.webp';

type NoAccessProps = {}

const NoAccess: React.FC<NoAccessProps> = (props: NoAccessProps) => {
    
    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <img className={styles.image} src={NoAccessVector} />
        <Stack>
            <Label size={'large'} className={styles.text}>No Access</Label>
            <Text size={'small'} className={styles.text}>Looks like you don't have access to view this page.</Text>
        </Stack>
    </Stack>
};

export default NoAccess;
