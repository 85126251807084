const OutcomeTypes = [
    {key: "shipping", text: "Shipping"},
    {key: "trucking", text: "Trucking"},
    {key: "train", text: "Train"},
    {key: "office", text: "Office"},
    {key: "salary", text: "Salary"},
    {key: "entertainment", text: "Entertainment"},
    {key: "reimbursement", text: "Reimbursement"},
    {key: "others", text: "Others"}
];

export default OutcomeTypes;