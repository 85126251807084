import React, { useEffect } from 'react';

import Logo from './../../../../../assets/logo/logo-dark.webp';
import { Link, Stack } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';
import { IOfferResourceProps } from '../../../../../props/offers';
import LoadingComponent from '../../../../feedbacks/loading';
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../services/general/error';
import OfferService from '../../../../../services/offers';
import Label from '../../../../typography/label';
import Heading from '../../../../typography/heading';
import Text from '../../../../typography/text';
import DeliveredGoodsType from '../../../../../manifests/deliveredGoodsType';
import GeneralService from '../../../../../services/general';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import OrderService from '../../../../../services/orders/order';
import { IOfferOriginTruckingCostResourceShortProps } from '../../../../../props/offers/originTruckingCost';
import { IOfferLoadingLabourCostResourceShortProps } from '../../../../../props/offers/loadingLabourCost';
import { IOfferDestinationTruckingCostResourceShortProps } from '../../../../../props/offers/destinationTruckingCost';
import { IOfferUnloadingLabourCostResourceShortProps } from '../../../../../props/offers/unloadingLabourCost';
import { IOfferContainerShippingCostResourceShortProps } from '../../../../../props/offers/containerShippingCost';
import { IOfferCharteredShipCostResourceShortProps } from '../../../../../props/offers/charteredShipCost';
import { IOfferInsuranceCostResourceShortProps } from '../../../../../props/offers/insuranceCost';
import { IOfferAdditionalCostResourceShortProps } from '../../../../../props/offers/additionalCost';
import QRCode from 'react-qr-code';


interface OfferCostSummaryProps {
    offerId: string;
}

const OfferCostSummary: React.FC<OfferCostSummaryProps> = (props: OfferCostSummaryProps) => {
    const { banner, route, topbar } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [offer, setOffer] = React.useState<IOfferResourceProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _offer = await OfferService.read(props.offerId);
            setOffer(_offer);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'read_offer_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to read offer form. Message: ${ErrorService.getMessage(e)}`
            });
        }
    }

    const renderProfitText = (_offer: IOfferResourceProps) => {
        let textColor: string = 'green';
        if (_offer.profitPercentage <= 0) {
            textColor = 'red';
        } else if (_offer.profitPercentage < 1.5) {
            textColor = 'yellow';
        }

        return <Label className={'color-' + textColor}>Rp. {GeneralService.getNumberWithSeparator(Number(_offer.profitAmount))} ({_offer.profitPercentage}%)</Label>;
    }

    const renderAddress = (_offer: IOfferResourceProps) => {
        const origin = OrderService.getAddress(_offer.originAddresses[0].type, _offer.originAddresses[0].address, _offer.originAddresses[0].city, _offer.originAddresses[0].province);
        const destination = OrderService.getAddress(_offer.destinationAddresses[0].type, _offer.destinationAddresses[0].address, _offer.destinationAddresses[0].city, _offer.destinationAddresses[0].province);

        return <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text>Alamat Muat</Text>
                {_offer.originAddresses.length > 1 ? <ol style={{ padding: '0px 12px', margin: 0 }}>
                    {_offer.originAddresses.map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <li><Text size={'small'}>{text}</Text></li>
                    })}
                </ol> : null}
                {_offer.originAddresses.length < 2 && _offer.originAddresses.length > 0 ? <Text size={'small'}>{origin}</Text> : null}
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text>Alamat Bongkar</Text>
                {_offer.destinationAddresses.length > 1 ? <ol style={{ padding: '0px 12px', margin: 0 }}>
                    {_offer.destinationAddresses.map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <li><Text size={'small'}>{text}</Text></li>
                    })}
                </ol> : null}
                {_offer.destinationAddresses.length < 2 && _offer.destinationAddresses.length > 0 ? <Text size={'small'}>{destination}</Text> : null}
            </Stack>
        </Stack>;
    }

    const renderTitle = (_offer: IOfferResourceProps) => {
        const originCities = _offer.originAddresses.filter((address) => (address.city || "").trim() !== "").map((address) => address.city || "");
        const destinationCities = _offer.destinationAddresses.filter((address) => (address.city || "").trim() !== "").map((address) => address.city || "");

        return <Heading variant={'h5'}>RINCIAN HARGA PENGIRIMAN {originCities.join(", ")} KE {destinationCities.join(", ")}</Heading>;
    }

    const renderOriginTruckingContent = (item: IOfferOriginTruckingCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Quantity</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
            </Stack>
        </Stack>
    }

    const renderLoadingLabourContent = (item: IOfferLoadingLabourCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Quantity</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
            </Stack>
        </Stack>
    }

    const renderUnloadingLabourContent = (item: IOfferUnloadingLabourCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Quantity</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
            </Stack>
        </Stack>
    }

    const renderDestinationTruckingContent = (item: IOfferDestinationTruckingCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Quantity</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
            </Stack>
        </Stack>
    }

    const renderContainerShippingContent = (item: IOfferContainerShippingCostResourceShortProps) => {
        return <Stack tokens={{ childrenGap: 10 }}>
            <Stack>
                {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                    <Text size={'small'}>Vendor</Text>
                    <Text size={'small'}>:</Text>
                    <Label size={'small'}>{item.vendor.name}</Label>
                </Stack> : null}
                <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                    <Text size={'small'}>Quantity</Text>
                    <Text size={'small'}>:</Text>
                    <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
                </Stack>
            </Stack>
            <Stack>
                <Label size={'xsmall'}>Price breakdown</Label>
                {(item.oceanFreight || "").trim() !== "" && Number(item.oceanFreight) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>OF</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.oceanFreight))}</Label>
                </Stack> : null}
                {(item.oceanFreight || "").trim() !== "" && Number(item.lss) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>LSS</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.lss))}</Label>
                </Stack> : null}
                {(item.thcOrigin || "").trim() !== "" && Number(item.thcOrigin) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>THC POL</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.thcOrigin))}</Label>
                </Stack> : null}
                {(item.loloOrigin || "").trim() !== "" && Number(item.loloOrigin) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>LOLO POL</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.loloOrigin))}</Label>
                </Stack> : null}
                {(item.thcDestination || "").trim() !== "" && Number(item.thcDestination) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>THC POD</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.thcDestination))}</Label>
                </Stack> : null}
                {(item.loloOrigin || "").trim() !== "" && Number(item.loloOrigin) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>LOLO POD</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.loloDestination))}</Label>
                </Stack> : null}
                {(item.apbs || "").trim() !== "" && Number(item.apbs) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>APBS</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.apbs))}</Label>
                </Stack> : null}
                {(item.cleaning || "").trim() !== "" && Number(item.cleaning) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>Cleaning</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.cleaning))}</Label>
                </Stack> : null}
                {(item.doc || "").trim() !== "" && Number(item.doc) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>Doc</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.doc))}</Label>
                </Stack> : null}
                {(item.seal || "").trim() !== "" && Number(item.seal) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>Seal</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.seal))}</Label>
                </Stack> : null}
                {(item.dutyStamp || "").trim() !== "" && Number(item.dutyStamp) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>Stamp</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.dutyStamp))}</Label>
                </Stack> : null}
                {(item.ops || "").trim() !== "" && Number(item.ops) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: 75 } }}><Text size={'small'}>Ops</Text></Stack>
                    <Label size={'xsmall'}>:  Rp. {GeneralService.getNumberWithSeparator(Number(item.ops))}</Label>
                </Stack> : null}
            </Stack>
        </Stack>
    }

    const renderCharteredShipContent = (item: IOfferCharteredShipCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Quantity</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
            </Stack>
        </Stack>
    }

    const renderInsuranceCostContent = (item: IOfferInsuranceCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Goods value</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.goodsValue))}</Label>
            </Stack>
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Percentage</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.percentage))}%</Label>
            </Stack>
        </Stack>
    }

    const renderAdditionalCostsContent = (item: IOfferAdditionalCostResourceShortProps) => {
        return <Stack>
            {item.vendor ? <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Vendor</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{item.vendor.name}</Label>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Quantity</Text>
                <Text size={'small'}>:</Text>
                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}{item.priceType === 'perquantity' ? ` @ Rp. ${GeneralService.getNumberWithSeparator(Number(item.price))}` : ''}</Label>
            </Stack>
        </Stack>
    }

    return <Stack>
        <Stack className={styles.container}>
            {!loaded ? <LoadingComponent label={'Retrieving offer ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
            {loaded && offer ? <Stack tokens={{ childrenGap: 20 }}>
                <Stack tokens={{ childrenGap: 20 }} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} className={styles.header}>
                    <img src={Logo} className={styles.logo} />
                    <Heading variant={'h1'}>RINCIAN HARGA</Heading>
                </Stack>
                <Stack className={'divider'}></Stack>
                <Stack tokens={{ childrenGap: 20 }} horizontal horizontalAlign={'space-between'} verticalAlign='center'>
                    <Stack>
                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <Stack styles={{ root: { width: 125 } }} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ childrenGap: 20 }}>
                                <Text>Penawaran #</Text>
                                <Text>:</Text>
                            </Stack>
                            <Label size={'small'}><Link target={'_blank'} href={`/offers/${offer.id}`}>#{offer.offerNumber}</Link></Label>
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <Stack styles={{ root: { width: 125 } }} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ childrenGap: 20 }}>
                                <Text>Detail</Text>
                                <Text>:</Text>
                            </Stack>
                            <Label size={'small'}>{offer.freightCategory.name} - {offer.deliveryType.name} - {DeliveredGoodsType.find((dg) => dg.key === offer.goodsType)?.text}</Label>
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <Stack styles={{ root: { width: 125 } }} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ childrenGap: 20 }}>
                                <Text>Subsidiary</Text>
                                <Text>:</Text>
                            </Stack>
                            <Label size={'small'}>{offer.subsidiary.name}</Label>
                        </Stack>
                        {offer.sales ? <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <Stack styles={{ root: { width: 125 } }} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ childrenGap: 20 }}>
                                <Text>Sales</Text>
                                <Text>:</Text>
                            </Stack>
                            <Label size={'small'}>{offer.sales.name} {(offer.sales.phoneNumber || "").trim() !== "" ? `(${offer.sales.phoneNumber})` : ""}</Label>
                        </Stack> : null}
                    </Stack>
                    <Stack>
                        <QRCode size={85}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 85 85`}
                        />
                    </Stack>
                </Stack>
                <Stack className={'divider'}></Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Text>Customer</Text>
                        <Label size={'small'}>{offer.company.name}</Label>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Text>Customer (PIC)</Text>
                        <Label size={'small'}>{offer.customer.name} {(offer.customer.phoneNumber || "").trim() !== "" ? `(${offer.customer.phoneNumber})` : ""}</Label>
                    </Stack>
                </Stack>
                <Stack className={'divider'}></Stack>
                {renderAddress(offer)}
                <Stack className={'divider'}></Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 2 }} styles={{ root: { width: '25%' } }}>
                        <Text size={'small'}>Modal All-in</Text>
                        <Label className={'color-red'}>Rp. {GeneralService.getNumberWithSeparator(Number(offer.totalCost))}</Label>
                    </Stack>
                    {offer.totalPrice > 0 ? <Stack tokens={{ childrenGap: 2 }} styles={{ root: { width: '25%' } }}>
                        <Text size={'small'}>Harga yang Ditawarkan</Text>
                        <Label className={'color-blue'}>Rp. {GeneralService.getNumberWithSeparator(Number(offer.totalPrice))}</Label>
                    </Stack> : null}
                    {offer.totalPrice > 0 ? <Stack tokens={{ childrenGap: 2 }} styles={{ root: { width: '50%' } }}>
                        <Text size={'small'}>Profit</Text>
                        {renderProfitText(offer)}
                    </Stack> : null}
                </Stack>
                <Stack className={'divider'}></Stack>
                <Stack>
                    <Text size={'small'}>Harga sudah termasuk</Text>
                    {(offer.includedItems || []).length > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                        {(offer.includedItems || []).map((included) => {
                            return <Tag text={included.name} />
                        })}
                    </Stack> : null}
                    {(offer.includedItems || []).length < 1 ? <Text style={{fontStyle: 'italic'}}>Harga yang ditawarkan belum termasuk apapun</Text> : null}
                </Stack>
                {(offer.excludedItems || []).length > 0 ? <Stack>
                    <Text size={'small'}>Harga BELUM termasuk</Text>
                    <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                        {(offer.excludedItems || []).map((excluded) => {
                            return <Tag text={excluded.name} />
                        })}
                    </Stack>
                </Stack> : null}
                <Stack className={'divider'}></Stack>
                <Label>Costing Table</Label>
                <table className={styles.costingTable}>
                    <tr>
                        <td width={175}><Label size={'medium'}>Nama</Label></td>
                        <td><Label size={'medium'}>Detail</Label></td>
                        <td width={175} className={styles.priceColumn}><Label size={'medium'}>Harga</Label></td>
                    </tr>
                    {(offer.originTruckingCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.originTruckingCosts || []).length}>
                                <Text>Dooring muat</Text>
                            </td> : null}
                            <td>{renderOriginTruckingContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.loadingLabourCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.loadingLabourCosts || []).length}>
                                <Text>Buruh muat</Text>
                            </td> : null}
                            <td>{renderLoadingLabourContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.destinationTruckingCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.destinationTruckingCosts || []).length}>
                                <Text>Dooring bongkar</Text>
                            </td> : null}
                            <td>{renderDestinationTruckingContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.unloadingLabourCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.unloadingLabourCosts || []).length}>
                                <Text>Buruh bongkar</Text>
                            </td> : null}
                            <td>{renderUnloadingLabourContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.containerShippingCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.containerShippingCosts || []).length}>
                                <Text>Pelayaran</Text>
                            </td> : null}
                            <td>{renderContainerShippingContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.charteredShipCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.charteredShipCosts || []).length}>
                                <Text>Charter kapal</Text>
                            </td> : null}
                            <td>{renderCharteredShipContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.insuranceCosts || []).map((item, idx) => {
                        return <tr>
                            {idx === 0 ? <td rowSpan={(offer.insuranceCosts || []).length}>
                                <Text>Asuransi</Text>
                            </td> : null}
                            <td>{renderInsuranceCostContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {(offer.additionalCosts || []).map((item, idx) => {
                        return <tr>
                            <td>
                                <Text>{item.name}</Text>
                            </td>
                            <td>{renderAdditionalCostsContent(item)}</td>
                            <td className={styles.priceColumn}>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Label>
                            </td>
                        </tr>
                    })}
                    {offer.ppnAmount && offer.ppnAmount > 0 ? <tr>
                        <td>
                            <Text>PPn</Text>
                        </td>
                        <td>
                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
                                <Text size={'small'}>Percentage</Text>
                                <Text size={'small'}>:</Text>
                                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(offer.ppnPercentage))}%</Label>
                            </Stack>
                        </td>
                        <td className={styles.priceColumn}>
                            <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(offer.ppnAmount))}</Label>
                        </td>
                    </tr> : null}
                    {offer.pphAmount && offer.pphAmount > 0 ? <tr>
                        <td>
                            <Text>PPh</Text>
                        </td>
                        <td>
                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
                                <Text size={'small'}>Percentage</Text>
                                <Text size={'small'}>:</Text>
                                <Label size={'small'}>{GeneralService.getNumberWithSeparator(Number(offer.pphPercentage))}%</Label>
                            </Stack>
                        </td>
                        <td className={styles.priceColumn}>
                            <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(offer.pphAmount))}</Label>
                        </td>
                    </tr> : null}
                    <tr>
                        <td colSpan={2}></td>
                        <td className={styles.priceColumn}>
                            <Text size={'small'}>TOTAL</Text>
                            <Label>Rp. {GeneralService.getNumberWithSeparator(Number(offer.totalCost))}</Label>
                        </td>
                    </tr>
                </table>
            </Stack> : null}
        </Stack>
    </Stack>
};

export default OfferCostSummary;
