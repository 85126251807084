import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import { useParams } from 'react-router-dom';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import { IOutcomeCategoryResourceShortProps } from '../../../../../../props/finance/outcomes/categories';
import OutcomeCategoryService from '../../../../../../services/finance/outcomes/categories';
import Tag from '../../../../../uiframeworks/tag';
import OutcomeCategoryForm from '../form';

type OutcomeCategoriesListProps = {
    qs?: string[];
    categories?: IOutcomeCategoryResourceShortProps[];
    columns?: TOutcomeCategoryColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOutcomeCategoryColumn = 'id' | 'actions' | 'name' | 'roles';

const OutcomeCategoriesList: React.FC<OutcomeCategoriesListProps> = (props: OutcomeCategoriesListProps) => {
    const params: any = useParams();
    const shownColumns: TOutcomeCategoryColumn[] = props.columns || [
        'id',
        'actions',
        'name',
        'roles'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [categories, setCategories] = React.useState<IOutcomeCategoryResourceShortProps[]>(props.categories || []);
    const [activeCategory, setActiveCategory] = React.useState<IOutcomeCategoryResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.read.all'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "id",
            name: "Key",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IOutcomeCategoryResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.id}</Text>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IOutcomeCategoryResourceShortProps) => {
                const menuItems = [];

                const hasUpdatePermission = PermissionsService.hasPermission(['outcomes.categories.update.all'], user.permissions);

                if (hasUpdatePermission) {
                    menuItems.push({
                        key: "update", text: "Update", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveCategory(item);
                            setActiveSurface('categories.update');
                        }
                    });
                }

                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: "name",
            name: "Name",
            minWidth: 250,
            maxWidth: 250,
            onRender: (item: IOutcomeCategoryResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.name}</Text>
                </Stack>
            }
        },
        {
            key: "roles",
            name: "Roles with Access",
            minWidth: 100,
            onRender: (item: IOutcomeCategoryResourceShortProps) => {
                return <Stack horizontal tokens={{childrenGap: 5}}>
                    {item.roles.map((role) => role.name).sort().map((role) => <Tag text={role} />)}
                </Stack>
            }
        },
    ];

    useEffect(() => {
        if (!props.categories) {
            _onRetrieveCategories();
        } else {
            setLoaded(true);
        }
    }, []);

    useEffect(() => {
        _onRetrieveCategories();
    }, [keyword]);

    const _onRetrieveCategories = async () => {
        try {
            setLoaded(false);

            if (params.invoiceId) {
                setActiveSurface('details');
            }

            const qs: string[] = props.qs || [];
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await OutcomeCategoryService.retrieve(qs.join("&"));
            setCategories(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcomes_list_error',
                text: 'Failed to retrieve outcomes list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['outcomes.categories.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create category",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create category"}
                        iconProps={{ iconName: "Add" }}
                        styles={{ root: { marginLeft: 10 } }}
                        onClick={() => {
                            setActiveSurface('categories.create')
                        }} />
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveCategory(undefined);

        if (refresh) { _onRetrieveCategories() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            {!props.hideCommandBar ? <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack className={styles[props.variant || 'card']}>
                    {
                        !loaded || (loaded && categories.length > 0) ? (
                            <>
                                <ShimmeredDetailsList
                                    setKey="items"
                                    items={categories}
                                    columns={columns.filter((col) => shownColumns.indexOf(col.key as TOutcomeCategoryColumn) > -1)}
                                    selectionMode={SelectionMode.none}
                                    enableShimmer={!loaded}
                                    onShouldVirtualize={() => false}
                                    ariaLabelForShimmer="Content is being fetched"
                                    ariaLabelForGrid="Item details" />
                            </>
                        ) : null
                    }
                    {loaded && categories.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                        <Text>Categories not found</Text>
                    </Stack> : null}
                </Stack>
            </Stack>
            {activeSurface === 'categories.create' ? <OutcomeCategoryForm onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'categories.update' && activeCategory ? <OutcomeCategoryForm categoryId={activeCategory.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {!hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default OutcomeCategoriesList;
