import { ActionButton, IconButton, Panel, PanelType, Stack } from '@fluentui/react';
import React, { } from 'react';

// assets
import Logo from './../../../assets/logo/logo-dark.webp';

import styles from './styles.module.scss';
import { SidebarFarMenu, MenuGroupProps, MenuItemProps, SidebarMenu, SidebarMenuProps } from '../../../manifests/sidebar';
import Label from '../../typography/label';
import Text from '../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/root';
import PermissionsService from '../../../services/permissions';
import Tag from '../../uiframeworks/tag';

interface ISidebarProps {
}

const Sidebar: React.FC<ISidebarProps> = observer((props: ISidebarProps) => {
    const {user} = useStore();

    const [menu, setMenu] = React.useState<SidebarMenuProps>(SidebarMenu);
    const [farMenu, setFarMenu] = React.useState<SidebarMenuProps>(SidebarFarMenu);
    const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

    const _onGroupExpanded = (group: MenuGroupProps) => {
        menu.groups.forEach((_group) => {
            if (_group.key === group.key) { group.expanded = !(group.expanded || false) }
        });
        farMenu.groups.forEach((_group) => {
            if (_group.key === group.key) { group.expanded = !(group.expanded || false) }
        });
        setMenu({ ...menu });
        setFarMenu({ ...farMenu });
    }

    const renderMenuGroup = (group: MenuGroupProps) => {
        const subitems = group.items.filter((item) => {
            return !item.permissions || PermissionsService.hasPermission(item.permissions || [], user.permissions);
        });

        if (subitems.length > 0) {
            const component = <Stack key={group.key} className={styles.group} tokens={{ childrenGap: 10 }}>
                {group.label || group.collapsible ? <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='space-between' verticalAlign='center'>
                    {group.label ? <Label size={'small'}>{group.label}</Label> : null}
                    {group.collapsible ? <IconButton styles={{
                        root: { background: 'transparent !important', paddingTop: '5px !important', paddingBottom: '5px !important', height: 'unset !important' }
                    }} onRenderIcon={() => {
                        return <FontAwesomeIcon icon={group.expanded ? faChevronUp : faChevronDown} />
                    }} onClick={() => _onGroupExpanded(group)} /> : null}
                </Stack> : null}
                {group.expanded ? <Stack tokens={{ childrenGap: 10 }}>
                    {subitems.map(renderMenuItem)}
                </Stack> : null}
            </Stack>;

            return component;
        }

        return null;
    }

    const renderMenuItem = (item: MenuItemProps) => {
        const component = <Stack key={item.key} className={`${styles.item}`} horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
            <Stack className={styles.icon} horizontalAlign={'center'}><FontAwesomeIcon icon={item.icon} fontSize={14} /></Stack>
            <Text>{item.text}</Text>
        </Stack>;

        if (item.href) {
            return <NavLink key={item.key + "_link"} to={item.href} onClick={() => setShowMobileMenu(false)}>{component}</NavLink>
        } else {
            return component;
        }
    }

    return <Stack className={styles.container}>
        <Stack className={styles.header} horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
            <img src={Logo} className={styles.logo} />
            <ActionButton className={styles.mobile} onClick={() => setShowMobileMenu(true)}>
                <FontAwesomeIcon icon={faBars} fontSize={30} />
            </ActionButton>
        </Stack>
        <Stack className={styles.division}>
            <Text style={{color: 'white'}}>Logistic Services</Text>
        </Stack>
        <Stack className={`${styles.top} ${styles.desktop}`} tokens={{ childrenGap: 20 }}>
            <Stack className={styles.menu} tokens={{ childrenGap: 20 }}>
                {menu.groups.map(renderMenuGroup)}
            </Stack>
        </Stack>
        <Stack className={`${styles.bottom} ${styles.desktop}`}>
            <Stack className={styles.menu} tokens={{ childrenGap: 20 }}>
                {farMenu.groups.map(renderMenuGroup)}
            </Stack>
        </Stack>
        <Panel headerText={"Menu"}
            className={styles.mobile}
            styles={{ 
                content: { padding: 0 }, 
                footerInner: { padding: 0 }, 
                header: { paddingLeft: 15 } 
            }}
            isOpen={showMobileMenu}
            type={PanelType.medium}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack className={`${styles.bottom} ${styles.desktop}`}>
                    <Stack className={styles.menu} tokens={{ childrenGap: 20 }}>
                        {farMenu.groups.map(renderMenuGroup)}
                    </Stack>
                </Stack>
            }}
            onDismiss={() => setShowMobileMenu(false)} >
            <Stack className={styles.top} tokens={{ childrenGap: 20 }}>
                <Stack className={styles.menu} tokens={{ childrenGap: 20 }}>
                    {menu.groups.map(renderMenuGroup)}
                </Stack>
            </Stack>
        </Panel>
    </Stack>;
});

export default Sidebar;
