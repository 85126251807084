import React, { useEffect } from 'react';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField } from '@fluentui/react';
import ValidationService from '../../../../services/validation';
import { useStore } from '../../../../stores/root';
import authService from '../../../../services/users/user';


interface ILogoutFormProps {
  onDismissed(refresh?: boolean): void;
}

const LogoutForm: React.FC<ILogoutFormProps> = (props: ILogoutFormProps) => {
  const { banner } = useStore();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    setSubmitting(true)
    try {
      await authService.logout()
      setSubmitting(false)
      window.location.href = '/auth/login';
    } catch (error) {

    }
  }

  return <Panel headerText={'Logout'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Logout"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      <Text>Are you sure you want to logout?</Text>
    </Stack>
  </Panel>
};

export default LogoutForm;
