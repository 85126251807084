import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import TrainsService from '../../../../../services/data/trains';

// props
import { ITrainResourceShort } from '../../../../../props/data/trains';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import TrainForm from './../form';
import TrainSchedulesList from '../../schedules/general/list';
import DeleteTrainForm from '../delete';
import TrainDocumentsList from '../../documents/general/list';
import PermissionsService from '../../../../../services/permissions';

interface ITrainDetailsProps {
    trainId: string;
}

const TrainDetails: React.FC<ITrainDetailsProps> = (props: ITrainDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [train, setTrain] = React.useState<ITrainResourceShort>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onGetTrain();
    }, []);

    const _onGetTrain = async () => {
        try {
            const _train = await TrainsService.get(props.trainId);
            setTrain(_train);

            topbar.show(_train.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "operationals", text: 'Operationals' },
                { key: "vendors", text: 'Vendors', href: "/operationals/vendors" },
                { key: _train.vendor.id, text: _train.vendor.name, href: "/operationals/vendors/" + _train.vendor.id },
                { key: props.trainId, text: _train.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_train_error',
                text: 'Failed to get train details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['trains.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                text: 'Update details',
                onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
            });
        }

        /*farItems.push({
            key: 'delete',
            text: 'Delete Train',
            onRender: () => <ActionButton text={'Delete Train'} iconProps={{ iconName: "Delete" }} onClick={() => setActiveSurface('delete')} />
        });*/

        return { items, farItems };
    }

    const getPropertiesSection = (_train: ITrainResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'train_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_train.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Name</Label>
                        <Text>{_train.name}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Vendor</Label>
                        <NavLink to={'/operationals/vendors/' + _train.vendor.id}><Text>{_train.vendor.name || "-"}</Text></NavLink>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getNextScheduleSection = (_train: ITrainResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Next Schedule"
            },
            key: 'next_schedule',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <ComingSoon />
            </Stack>
        }
    }

    const getTabsSection = (_train: ITrainResourceShort): IStackSectionProps => {
        const tabs: ISectionTabProps[] = [];
        
        if (PermissionsService.hasPermission(['trains.schedules.read.all'], user.permissions)) {
            tabs.push({
                key: 'train_schedules',
                title: 'Schedules',
                content: <Stack>
                    <TrainSchedulesList trainId={_train.id} />
                </Stack>
            });
        }

        tabs.push({
            key: 'related_documents',
            title: 'Related Document(s)',
            content: <Stack>
                <TrainDocumentsList trainId={props.trainId} />
            </Stack>
        });

        return {
            key: 'train_tabs',
            isCard: true,
            tabs
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetTrain() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving customer details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && train ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getPropertiesSection(train)]} right={getNextScheduleSection(train)} />
                <DetailsStack left={[getTabsSection(train)]} />
            </Stack>
            {activeSurface === 'update' ? <TrainForm trainId={train.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' ? <DeleteTrainForm train={train} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default TrainDetails;
