import React, { useEffect, useState } from 'react';
import DocumentService from '../../../../services/documents/document';
import LoadingComponent from '../../../feedbacks/loading';

type TypeRenderImageProps = {
  children?: React.ReactNode;
  path: string
  className?: string
  alt?: string
  style?: React.CSSProperties
};

const RenderImage: React.FC<TypeRenderImageProps> = (props) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const getIamgeData = async () => {
    try {
      const blob = (await DocumentService.get(props.path)).data.data
      setImageSrc(blob)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIamgeData()
    return () => {

    }
  }, [])


  return (
    <>
      {!imageSrc ? <LoadingComponent label={''} /> : null}
      {imageSrc ? <img style={props.style} className={props.className} src={`data:image/jpeg;base64,${imageSrc}`} alt={props.alt || "Gambar"} {...props} /> : null}
    </>
  );
};

export default RenderImage;
