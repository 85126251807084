import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services
import ValidationService from '../../../../../services/validation';

// props

// components
import { Stack, TextField } from '@fluentui/react';
import { FormDataProps } from '.';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import VendorsService from '../../../../../services/data/vendors';
import LoadingComponent from '../../../../feedbacks/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';

type VendorDetailsFormProps = {
    data: Partial<FormDataProps>;
    onChange(data: Partial<FormDataProps>): void;
}

const VendorDetailsForm: React.FC<VendorDetailsFormProps> = (props: VendorDetailsFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [checking, setChecking] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [similarNames, setSimilarNames] = React.useState<string[]>([]);
    const [data, setData] = React.useState<Partial<FormDataProps>>(props.data);
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow={1}>
                    <TextField label={"Name"}
                        required={true}
                        value={data.name}
                        onChange={(evt, value) => {
                            data.name = value || "";

                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                            error.name = validation.message;

                            setError({ ...error });
                            props.onChange(data);
                        }}
                        onBlur={async (evt) => {
                            const name = data.name || "";
                            if (name.trim() !== "") {
                                setChecking(true);
                                setSimilarNames([]);
                                try {
                                    const _similarNames = await VendorsService.findSimilar(`name=${name}`);
                                    setSimilarNames(_similarNames || []);
                                } catch (e) { }

                                setChecking(false);
                            }
                        }}
                        errorMessage={error.name}
                        disabled={submitting || checking} />
                </Stack.Item>
                {checking ? <Stack styles={{ root: { marginTop: 30 } }}><LoadingComponent label={""} /></Stack> : null}
            </Stack>
            {similarNames.length > 0 ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                <FontAwesomeIcon icon={faTriangleExclamation} fontSize={12} style={{marginTop: 2}} className={'color-red'} />
                <Text size={'small'} className={'color-red'}>We found {similarNames.length} vendor with similar name. Make sure you are not creating any of these vendors: {similarNames.join(", ").toUpperCase()}.</Text>
            </Stack> : null}
        </Stack>
        <Stack.Item>
            <TextField label={"Address"}
                required={false}
                multiline
                rows={5}
                value={data.address}
                errorMessage={error.address}
                onChange={(evt, value) => {
                    data.address = value || "";

                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                    error.address = validation.message;

                    setError({ ...error });
                    props.onChange(data);
                }}
                disabled={submitting} />
        </Stack.Item>
        <Stack.Item>
            <TextField label={"Phone Number"}
                required={false}
                value={data.phoneNumber}
                errorMessage={error.phoneNumber}
                onChange={(evt, value) => {
                    if (value === "" || !isNaN(Number(value))) {
                        data.phoneNumber = value || "";

                        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                        error.phoneNumber = validation.message;

                        setError({ ...error });
                        props.onChange(data);
                    }
                }}
                disabled={submitting} />
        </Stack.Item>
        <Stack className='divider'></Stack>
        <Stack.Item>
            <TextField label={"NPWP"}
                value={data.npwp}
                onChange={(evt, value) => {
                    data.npwp = value || "";

                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                    error.npwp = validation.message;

                    setError({ ...error });
                    props.onChange(data);
                }}
                errorMessage={error.npwp}
                disabled={submitting} />
        </Stack.Item>
        <Stack className='divider'></Stack>
        <Label size={'small'}>Owner Details</Label>
        <Stack.Item>
            <TextField label={"Owner name"}
                value={data.ownerName}
                onChange={(evt, value) => {
                    data.ownerName = value || "";

                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                    error.ownerName = validation.message;

                    setError({ ...error });
                    props.onChange(data);
                }}
                errorMessage={error.ownerName}
                disabled={submitting} />
        </Stack.Item>
        <Stack.Item>
            <TextField label={"Owner ID number"}
                value={data.ownerIdentification}
                onChange={(evt, value) => {
                    data.ownerIdentification = value || "";

                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                    error.ownerIdentification = validation.message;

                    setError({ ...error });
                    props.onChange(data);
                }}
                errorMessage={error.ownerIdentification}
                disabled={submitting} />
        </Stack.Item>
        <Stack.Item>
            <TextField label={"Owner address"}
                value={data.ownerAddress}
                onChange={(evt, value) => {
                    data.ownerAddress = value || "";

                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                    error.ownerAddress = validation.message;

                    setError({ ...error });
                    props.onChange(data);
                }}
                errorMessage={error.ownerAddress}
                disabled={submitting} />
        </Stack.Item>
    </Stack>
};

export default VendorDetailsForm;
