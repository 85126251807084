import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import { IOutcomeResourceShorterProps } from '../../../../../../props/finance/outcomes';

type TotalOutcomeInvoiceAmountCardProps = {
    style?: any;
    grow?: number | boolean | "inherit" | "initial" | "unset" | undefined;
    variant?: 'primary' | 'default';
    invoices?: IOutcomeResourceShorterProps[];
}

const TotalOutcomeInvoiceAmountCard: React.FC<TotalOutcomeInvoiceAmountCardProps> = (props: TotalOutcomeInvoiceAmountCardProps) => {
    const { banner } = useStore();
    const [totalAmount, setTotalAmount] = React.useState<number>();

    useEffect(() => {
        calculateTotalAmount();
    }, [props.invoices]);

    const calculateTotalAmount = () => {
        let totalAmount = 0;

        (props.invoices || []).forEach((invoice) => {
            totalAmount += Number(invoice.totalAmount || '0');
        })

        setTotalAmount(totalAmount);
    }

    return <Stack grow={props.grow} tokens={{ childrenGap: 20 }} className={`${styles.card} ${styles.variant}`} style={props.style}>
        <Stack>
            <Label size={'small'}>Total amount</Label>
        </Stack>
        <Stack>
            {!props.invoices ? <LoadingComponent labelPosition='right' label={'Retrieving ...'} spinnerPosition={'baseline'} size={SpinnerSize.small} /> : null}
            {props.invoices ? <>
                <Label className={'color-blue'} size={'large'}>Rp. {GeneralService.getNumberWithSeparator(totalAmount + '')}</Label>
            </> : null}
        </Stack>
    </Stack>;
};

export default TotalOutcomeInvoiceAmountCard;
