import moment from 'moment';
import { IRenderMessageBarProps } from '../props/general';
import ExtenstionIcons from '../manifests/extensionIcons';
import GeneralConfig from './../config';
import { ToWords } from 'to-words';
import Translations from '../manifests/translation';
import { v4 as uuid } from 'uuid';
import FileFormats, { TFileType } from '../manifests/fileFormats';
import { IOutcomePaymentResourceShortProps } from '../props/finance/outcomes/payments';
import DocumentService from './documents/document';
import ExcelJS from 'exceljs';

type TWeightMultipier = 'kg' | 'gr' | 'mg';
const weightMultiplier = {
    kg: 1000000,
    gr: 1000,
    mg: 1
};
const toWords = new ToWords({
    localeCode: "en-US",
    converterOptions: {
        currency: false,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
})

const GeneralService = {
    guid: () => {
        return uuid();
    },
    getFileType: (name: string): TFileType => {
        const extension = "." + name.split('.').pop();

        let type: TFileType = 'file';
        if (FileFormats.excel.indexOf(extension) > -1) {
            type = 'excel';
        } else if (FileFormats.word.indexOf(extension) > -1) {
            type = 'word';
        } else if (FileFormats.powerpoint.indexOf(extension) > -1) {
            type = 'powerpoint';
        } else if (FileFormats.pdf.indexOf(extension) > -1) {
            type = 'pdf';
        } else if (FileFormats.image.indexOf(extension) > -1) {
            type = 'image';
        }

        return type;
    },
    getFriendlyFileSizeText: (size: number, noDecimal?: boolean) => {
        let sizeValue = 0;
        let sizeText = 'bytes';

        if (size < 1000) {
            sizeValue = size;
            sizeText = 'bytes';
        } else if (size < 1000000) {
            sizeValue = size / 1000;
            sizeText = 'KB';
        } else if (size < 1000000000) {
            sizeValue = size / 1000000;
            sizeText = 'MB';
        } else {
            sizeValue = size / 1000000000;
            sizeText = 'GB';
        }

        if (noDecimal) {
            return `${Math.ceil(sizeValue)} ${sizeText}`
        } else {
            return `${sizeValue} ${sizeText}`
        }
    },
    getFriendlyDateFormat: (date: string, limit?: number) => {
        const now = moment().startOf('day');
        const target = moment(date).startOf('day');
        const diff = target.diff(now, 'day');
        if (diff === 0) {
            return 'Hari ini';
        } else if (diff === 1) {
            return 'Besok';
        } else if (diff === 2) {
            return 'Besok lusa';
        } else if (diff === -1) {
            return 'Kemarin';
        } else if (diff === -2) {
            return 'Kemarin lusa';
        } else if (diff > 2 && (!limit || diff < limit)) {
            return diff + ' hari lagi';
        } else if (diff < -2 && (!limit || (diff * -1) < limit)) {
            return (diff * -1) + ' hari lalu';
        } else {
            return target.format("DD/MM/YYYY");
        }
    },
    calculateAmountAfterTax: {
        string: (amount?: string, tax?: string) => {
            if (amount && tax && amount !== "" && tax !== "") {
                const floatAmount = parseFloat(amount);
                const floatTax = parseFloat(tax);

                return (floatAmount * floatTax / 100) + floatAmount;
            } else {
                return 0;
            }
        },
        number: (amount?: number, tax?: number) => {
            if (amount != undefined && tax != undefined) {
                const floatAmount = amount;
                const floatTax = tax;

                return (floatAmount * floatTax / 100) + floatAmount;
            } else {
                return 0;
            }
        }
    },
    getWeightInKg: (amount: number, unit: string) => {
        return (amount * weightMultiplier[unit as TWeightMultipier]) / 1000000;
    },
    getFileUrl: async (url: string) => {
        const blob = (await DocumentService.get(url)).data.data
        return `data:image/jpeg;base64,${blob}`;
    },
    getExtensionsIcon: (extension: string) => {
        let icon: string = (ExtenstionIcons as any)[extension] || 'Page';
        return icon;
    },
    getInitial: (name: string) => {
        const splitted: string[] = name.split(" ");
        if (splitted.length > 1) {
            return splitted[0][0] + splitted[1][0];
        } else {
            return splitted[0][0] + splitted[0][1];
        }
    },
    getNumberWithSeparator: (number: string | number, separator?: string) => {
        number = number + '';
        if (parseFloat(number || "0") >= 1) {
            number = (number || "0");//.split(".").join("");
            number = number.replaceAll(".", ",");
            const decimal = number.split(",")[1];
            return number.split(",")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (decimal ? "," + decimal : "");
        } else if (parseFloat(number || "0") >= 0 && parseFloat(number || "0") < 1) {
            number = (number || "0");//.split(".").join("");
            number = number.replaceAll(".", ",");
            return number;
        } else if (parseFloat(number || "0") < 0) {
            number = (parseFloat(number || "0") * -1) + "";
            number = number.replaceAll(".", ",");
            const decimal = number.split(",")[1];
            return "-" + number.split(",")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (decimal ? "," + decimal : "");
        } else {
            return number;
        }
    },
    getParameterByName: (name: string, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    makeid: (length?: number, useSeparator?: boolean, prefix?: string): string => {
        const date = moment().format("DDMMYYYY.HHmm");
        var result = (prefix || '') + (useSeparator ? '.' : '') + date;
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var numbers = '0123456789';
        if (useSeparator) { result += '.'; }
        for (var i = 0; i < 2; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        if (useSeparator) { result += '.'; }
        for (var i = 0; i < 4; i++) {
            result += (characters + numbers).charAt(Math.floor(Math.random() * (characters + numbers).length));
        }
        if (useSeparator) { result += '.'; }
        for (var i = 0; i < 4; i++) {
            result += (characters + numbers).charAt(Math.floor(Math.random() * (characters + numbers).length));
        }
        return length && result.length > length ? result.substring(0, length) : result;
    },
    calculateVolume: (length?: string, width?: string, height?: string): number => {
        if (width && width.trim() !== "" && length && length.trim() !== "" && height && height.trim() !== "") {
            const volume = parseFloat(((parseFloat(width.split(",").join(".")) / 100) * (parseFloat(length.split(",").join(".")) / 100) * (parseFloat(height.split(",").join(".")) / 100)).toFixed(4));
            return Number(volume.toFixed(4));
        } else {
            return 0;
        }
    },
    getVolume: (length?: number, width?: number, height?: number): number => {
        if (length && width && height) {
            return Number(((length * width * height) / 1000000).toFixed(4));
        } else {
            return 0;
        }
    },
    formatDate: (date?: Date | null, format?: string) => {
        return date ? moment(date).format(format || "DD/MM/YYYY") : "";
    },
    generateErrorMessage: (e: any): string => {
        return `Maaf, terjadi kesalahan pada sistem. Error: ${e}`;
    },
    convertUrlToFileObject: (url: string) => {
        const fileName = url.split('/').pop();
        return {
            url,
            name: fileName,
            type: (fileName || "").split(".").pop()
        }
    },
    convertNumberToWords: (number: number) => {
        let words = toWords.convert(number);
        words = GeneralService.translate(words);
        return GeneralService.titleCase(words);
    },
    titleCase: (str: string) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    },
    translate: (text: string) => {
        text = text.toLowerCase();

        // replace special words 
        Object.keys(Translations.special).forEach((key) => {
            text = text.replaceAll(key, (Translations.special as any)[key] || key);
        });

        const splitted = text.split(" ");
        const results = splitted.map((t) => {
            return (Translations.generic as any)[t] || t;
        }).join(" ");
        return results;
    },
    convertWeightToKg: (weight: number, unit: string) => {
        return (weight * weightMultiplier[unit as TWeightMultipier]) / 1000000;
    },

};

export default GeneralService;