import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import styles from './styles.module.scss';
import RenderImage from '../uiframeworks/files/renderImage';


interface ImageUploadProps {
  previewImageUrl?: string;
  onSelectedFile?: (file: File) => void
}

const ImageUpload: React.FC<ImageUploadProps> = props => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);


  useEffect(() => {
    if (props.previewImageUrl) {
      setPreviewUrl(props.previewImageUrl);
    }
  }, [props.previewImageUrl]);

  useEffect(() => {
    props.onSelectedFile && selectedFile && props.onSelectedFile(selectedFile)
    return () => { }
  }, [selectedFile])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Stack horizontal verticalAlign='end'>
      {previewUrl?.startsWith('data:image/') && <img src={previewUrl} alt="Preview" style={{ width: '100px', }} />}
      {previewUrl && !previewUrl.startsWith('data:image/') && <RenderImage path={previewUrl} alt="Preview" style={{ width: '100px', }} />}
      <input type="file" onChange={handleFileChange} className={styles.fileInput} ref={fileInputRef} />
      <DefaultButton styles={{ root: { marginLeft: previewUrl ? '10px' : 'unset' } }}
        iconProps={{ iconName: 'Image' }} text={previewUrl ? 'Change' : 'Upload'} onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }} />
    </Stack>
  );
};

export default ImageUpload;
