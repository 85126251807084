import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import { IOutcomeResourceShorterProps, IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import moment from 'moment';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Bar, BarChart, Tooltip } from 'recharts';
import Tag from '../../../../../uiframeworks/tag';
import OutcomesInvoiceList from '../../../invoices/general/list';

type OutcomeInvoiceListChartProps = {
    invoices: IOutcomeResourceShortProps[];
    groupBy: string;
}

const OutcomeInvoiceListChart: React.FC<OutcomeInvoiceListChartProps> = (props: OutcomeInvoiceListChartProps) => {
    const { banner } = useStore();
    const [invoices, setInvoices] = React.useState<IOutcomeResourceShortProps[]>(props.invoices);

    useEffect(() => {
        setInvoices(props.invoices);
    }, [props.invoices, props.groupBy]);

    return <Stack>
        <OutcomesInvoiceList invoices={invoices}
            hideCommandBar={true}
            columns={['name', 'type', 'transferFrom', 'amount', 'status']} />
    </Stack>
};

export default OutcomeInvoiceListChart;
