import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import GeneralService from '../../../../../services/general';
import OrderAddOnService from '../../../../../services/orders/odrerAddOn';

// props
import { IOrderAddonResourceShortProps } from '../../../../../props/orders/orderAddon';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import OrderAdditionalChargeForm from '../form';
import DeleteOrderAdditionalChargeForm from '../delete';
import PermissionsService from '../../../../../services/permissions';

interface IOrderAdditionalChargesProps {
    orderId: string;
    qs?: string[];
    additionalCharges?: IOrderAddonResourceShortProps[];
    columns?: TOrderColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderColumn = 'name' | 'actions' | 'notes' | 'price';

const OrderAdditionalChargesList: React.FC<IOrderAdditionalChargesProps> = (props: IOrderAdditionalChargesProps) => {
    const shownColumns: TOrderColumn[] = props.columns || [
        'name',
        'actions',
        'notes',
        'price'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [additionalCharges, setAdditionalCharges] = React.useState<IOrderAddonResourceShortProps[]>(props.additionalCharges || []);
    const [activeAdditionalCharge, setActiveAdditionalCharge] = React.useState<IOrderAddonResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            minWidth: 250,
            maxWidth: 250,
            isMultiline: true,
            onRender: (item: IOrderAddonResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.name || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IOrderAddonResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        {PermissionsService.hasPermission(['orders.update.all'], user.permissions) ? <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: [
                                {key: "update", text: "Update", iconProps: {iconName: "Edit"}, onClick: () => {
                                    setActiveAdditionalCharge(item);
                                    setActiveSurface('update');
                                }},
                                {key: "delete", text: "Delete", iconProps: {iconName: "Delete"}, onClick: () => {
                                    setActiveAdditionalCharge(item);
                                    setActiveSurface('delete');
                                }}
                            ]
                        }} /> : null}
                    </Stack.Item>
                </Stack>;
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 150,
            isMultiline: true,
            onRender: (item: IOrderAddonResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "price",
            name: "Price",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderAddonResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(item.price)}</Text>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.additionalCharges) {
            _onRetrieveAdditionalCharges();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveAdditionalCharges = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await OrderAddOnService.retrieve(props.orderId, qs.join("&"));
            setAdditionalCharges(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_additional_charges_list_error',
                text: 'Failed to retrieve order additional charges list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Additional Charge",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Additional Charge"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveAdditionalCharge(undefined);

        if (refresh) { _onRetrieveAdditionalCharges() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && additionalCharges.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={additionalCharges.filter((ac) => {
                                const search = (keyword || "").trim().toLowerCase();
                                return ac.name.toLowerCase().indexOf(search) > -1 || (ac.notes || "").toLowerCase().indexOf(search) > -1
                            })}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && additionalCharges.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text>Additional charge(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderAdditionalChargeForm orderId={props.orderId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeAdditionalCharge ? <OrderAdditionalChargeForm orderId={props.orderId} additionalChargeId={activeAdditionalCharge.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && activeAdditionalCharge ? <DeleteOrderAdditionalChargeForm additionalCharge={activeAdditionalCharge} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrderAdditionalChargesList;
