import React, { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services
import GeneralService from '../../../../services/general';

// props
import OutcomeTypes from '../../../../manifests/outcomeTypes';
import { IOutcomeResourceProps, IOutcomeResourceShortProps } from '../../../../props/finance/outcomes';

// components
import { CommandBar, Dropdown, ICommandBarItemProps, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { IOrderResourceShortProps } from '../../../../props/orders/order';
import OrderService from '../../../../services/orders/order';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import OutcomeService from '../../../../services/finance/outcomes';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import LoadingComponent from '../../../feedbacks/loading';

interface IFinanceMonthlySummaryProps {
    variant?: 'card' | 'plain';
    hideCommandBar?: boolean;
    hideFilterPeriod?: boolean;
}

const FinanceMonthlySummary: React.FC<IFinanceMonthlySummaryProps> = (props: IFinanceMonthlySummaryProps) => {
    const { variant } = props;
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [orders, setOrders] = React.useState<IOrderResourceShortProps[]>([]);
    const [outcomes, setOutcomes] = React.useState<IOutcomeResourceShortProps[]>([]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selectedPeriod, setSelectedPeriod] = React.useState<string>('thisMonth');
    const [dateRange, setDateRange] = React.useState<{ start: string; end: string; }>({
        start: moment().add(-3, 'months').toISOString(),
        end: moment().toISOString()
    });

    useEffect(() => {
        init();
    }, [dateRange]);

    const init = async () => {
        try {
            setLoaded(false);

            const qs: string[] = [];
            qs.push(`start_date=${dateRange.start}`);
            qs.push(`end_date=${dateRange.end}`);
            qs.push(`top=all`);

            const _orders = await OrderService.retrieve(qs.join('&'));
            const _outcomes = await OutcomeService.retrieve(qs.join('&'));
            setOrders(_orders.data);
            setOutcomes(_outcomes.data);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_orders_summary_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to retrieve order summary. Message: ${GeneralService.generateErrorMessage(e)}`
            });
        }
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideFilterPeriod) {
            items.push({
                key: "filter_period",
                onRender: () => {
                    return <Dropdown selectedKey={selectedPeriod}
                        options={[
                            { key: 'thisMonth', text: 'This Month' },
                            { key: 'last3Months', text: 'Last 3 Months' },
                            { key: 'last6Months', text: 'Last 6 Months' },
                            { key: 'thisYear', text: 'This Year' },
                            { key: 'lastYear', text: 'Last Year' },
                            { key: 'custom', text: 'Custom' }
                        ]} styles={{ root: { minWidth: 150 } }}
                        onChange={(evt, opt) => {
                            setSelectedPeriod((opt?.key || 'last3Months') as string);
                            if (opt?.key === 'last3Months') {
                                setDateRange({ start: moment().startOf('month').add(-2, 'months').toISOString(), end: moment().endOf('month').toISOString() })
                            } else if (opt?.key === 'last6Months') {
                                setDateRange({ start: moment().startOf('month').add(-5, 'months').toISOString(), end: moment().endOf('month').toISOString() })
                            } else if (opt?.key === 'thisYear') {
                                setDateRange({ start: moment().startOf('year').toISOString(), end: moment().endOf('year').toISOString() })
                            } else if (opt?.key === 'lastYear') {
                                setDateRange({ start: moment().startOf('year').add(-1, 'year').toISOString(), end: moment().endOf('year').add(-1, 'year').toISOString() })
                            } else if (opt?.key === 'thisMonth') {
                                setDateRange({ start: moment().startOf('month').toISOString(), end: moment().endOf('month').toISOString() })
                            }
                        }} />
                }
            });
        }

        return { items, farItems };
    }

    const groupData = (_orders: IOrderResourceShortProps[], _outcomes: IOutcomeResourceShortProps[]) => {
        const data: {
            name: string;
            paidIncomes: number;
            unpaidIncomes: number;
            paidOutcomes: number;
            unpaidOutcomes: number;
        }[] = [];

        let monthly: { key: number, orders: IOrderResourceShortProps[], outcomes: IOutcomeResourceShortProps[] }[] = [];

        // group all orders based on month
        _orders.forEach((order) => {
            const orderMonth = Number(moment(order.createdAt).format("M"));
            const idx = monthly.findIndex((m) => m.key === orderMonth);
            if (idx < 0) {
                monthly.push({
                    key: orderMonth,
                    orders: [order],
                    outcomes: []
                });
            } else if (monthly[idx]) {
                monthly[idx].orders.push(order);
            }
        });

        // group all outcomes based on month
        _outcomes.forEach((outcome) => {
            const outcomeMonth = Number(moment(outcome.accountingDate).format("M"));
            const idx = monthly.findIndex((m) => m.key === outcomeMonth);
            if (idx < 0) {
                monthly.push({
                    key: outcomeMonth,
                    orders: [],
                    outcomes: [outcome]
                });
            } else if (monthly[idx]) {
                monthly[idx].outcomes.push(outcome);
            }
        });

        // sort group based on month
        monthly = monthly.sort((a, b) => a.key - b.key);

        monthly.forEach((month) => {
            const name = moment(month.key + '', 'M').format("MMM").toUpperCase();
            let _data = { name, paidIncomes: 0, unpaidIncomes: 0, paidOutcomes: 0, unpaidOutcomes: 0 };

            month.orders.forEach((order) => {
                _data.paidIncomes += order.paidAmount;
                _data.unpaidIncomes += order.unpaidAmount;
            });

            month.outcomes.forEach((outcome) => {
                _data.paidOutcomes += Number(outcome.paid);
                _data.unpaidOutcomes += Number(outcome.unpaid);
            });

            data.push(_data);
        });

        console.log(data);

        return data;
    }

    const renderTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            const paidIncomes = props.payload.find((p: any) => p.dataKey === 'paidIncomes');
            const unpaidIncomes = props.payload.find((p: any) => p.dataKey === 'unpaidIncomes');
            const paidOutcomes = props.payload.find((p: any) => p.dataKey === 'paidOutcomes');
            const unpaidOutcomes = props.payload.find((p: any) => p.dataKey === 'unpaidOutcomes');

            const profit = ((paidIncomes?.value || 0) + (unpaidIncomes?.value || 0)) - ((paidOutcomes?.value || 0) + (unpaidOutcomes?.value || 0));

            let profitVariant: TTagVariant = 'warning';
            if (profit < 0) {
                profitVariant = 'error';
            } else if (profit > 250000) {
                profitVariant = 'success';
            }

            return <Stack tokens={{ childrenGap: 10 }} className={styles.tooltip}>
                <Label>{moment(props.label, "MMM").format("MMMM").toUpperCase()}</Label>
                <Stack className='divider'></Stack>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Paid Income(s)</Label>
                        <Text size={'small'} className='color-green'>Rp. {GeneralService.getNumberWithSeparator(paidIncomes?.value)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Unpaid Income(s)</Label>
                        <Text size={'small'} className='color-green'>Rp. {GeneralService.getNumberWithSeparator(unpaidIncomes?.value)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Paid Outcomes(s)</Label>
                        <Text size={'small'} className='color-red'>Rp. {GeneralService.getNumberWithSeparator(paidOutcomes?.value)}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                        <Label size={'xsmall'}>Unpaid Outcomes(s)</Label>
                        <Text size={'small'} className='color-red'>Rp. {GeneralService.getNumberWithSeparator(unpaidOutcomes?.value)}</Text>
                    </Stack>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign='space-between' verticalAlign='center'>
                    <Label size={'xsmall'}>Profit</Label>
                    <Stack horizontal><Tag text={`Rp. ${GeneralService.getNumberWithSeparator(profit)}`} variant={profitVariant} /></Stack>
                </Stack>
            </Stack>;
        }

        return null;
    };

    const renderLegend = (props: any) => {
        const { payload } = props;

        return <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='center'>
            {payload.map((entry: any, index: number) => {
                let text = 'Paid Income(s)';
                let variant = '#218d21';

                if (entry.value === 'unpaidIncomes') {
                    text = 'Unpaid Incomes';
                    variant = '#caeaca';
                } else if (entry.value === 'paidOutcomes') {
                    text = 'Paid Outcomes';
                    variant = '#ae383e';
                } else if (entry.value === 'unpaidOutcomes') {
                    text = 'Unpaid Outcomes';
                    variant = '#f0d3d4';
                }

                return <Stack horizontal tokens={{childrenGap: 5}}>
                    <Stack.Item className={styles.indicator} styles={{root: {backgroundColor: variant}}}> </Stack.Item>
                    <Text size={'small'}>{text}</Text>
                </Stack>
            })}
        </Stack>;
    }

    const renderXAxisTick = (props: any) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={12} y={-4} dy={16} textAnchor="end" fill="#666" fontSize={12}>
                    {payload.value}
                </text>
            </g>
        );
    }

    const renderYAxisTick = (props: any) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={-12} dy={16} textAnchor="end" fill="#666" fontSize={12}>
                    Rp. {GeneralService.getNumberWithSeparator(payload.value / 1000000)} jt
                </text>
            </g>
        );
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <Stack className={styles[props.variant || 'card']} tokens={{ childrenGap: 10 }}>
            <Label>Monthly Summary</Label>
            <Stack className='divider'></Stack>
            {!props.hideCommandBar ? <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        marginTop: 5,
                        marginBottom: 5,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
            {!loaded ? <Stack verticalAlign='center' horizontalAlign='center' styles={{root: {height: 300}}}>
                <LoadingComponent label='Retrieving order(s) summary ...' labelPosition='bottom' />
            </Stack> : null}
            {loaded ? <Stack styles={{ root: { width: '100%', height: 300 } }}>
                <ResponsiveContainer>
                    <BarChart data={groupData(orders, outcomes)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis tick={renderXAxisTick} dataKey="name" />
                        <YAxis tick={renderYAxisTick} />
                        <Tooltip content={renderTooltip} />
                        <Legend content={renderLegend} />
                        <Bar dataKey="paidIncomes" stackId={'incomes'} fill="#218d21" />
                        <Bar dataKey="unpaidIncomes" stackId={'incomes'} fill="#caeaca" />
                        <Bar dataKey="paidOutcomes" stackId={'outcomes'} fill="#ae383e" />
                        <Bar dataKey="unpaidOutcomes" stackId={'outcomes'} fill="#f0d3d4" />
                    </BarChart>
                </ResponsiveContainer>
            </Stack> : null}
        </Stack>
    </Stack>;
};

export default FinanceMonthlySummary;
