import React from 'react';

// import styles
import parentStyles from './../style.module.scss';

// import router
import { RouteComponentProps } from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../../props/user';

// import services
import UserService from '../../services/users/user';

// import pages

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import LoadingComponent from '../../components/feedbacks/loading/index';
import { IRenderMessageBarProps } from '../../props/general';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import GeneralService from '../../services/general';
import SimpleMessageBarComponent from '../../components/feedbacks/simpleMessageBar';
import { ActionButton } from 'office-ui-fabric-react';

interface ILogoutProps {
    user: IUserResourceShortProps;
    route: RouteComponentProps;
}

interface ILogoutState {
    loggingOut: boolean;
    messageBar?: IRenderMessageBarProps;
}

export default class Logout extends React.Component<ILogoutProps, ILogoutState> {
    constructor(props: ILogoutProps) {
        super(props);
        this.state = {
            loggingOut: true
        }
    }

    private logout = async () => {
        try {
            this.setState({loggingOut: true});
            await UserService.logout();
            this.setState({
                messageBar: {
                    content: <Text variant={"small"}>Logout berhasil. Harap tunggu sementara kami mengarahkan ke halaman login.</Text>,
                    type: MessageBarType.success
                }
            });

            setTimeout(() => {
                window.location.href = "/auth/login";
            }, 4000);
        } catch (e) {
            this.setState({
                loggingOut: false,
                messageBar: {
                    content: <Text variant={"small"}>{GeneralService.generateErrorMessage(e)}</Text>,
                    type: MessageBarType.error
                }
            })
        }
    }

    public componentDidMount = async () => {
        this.logout();
    }

    public render() {
        const { user } = this.props;

        return (
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack className={parentStyles.header}>
                    <Text className={parentStyles.text} as={"h2"} variant={"medium"}>Logout</Text>
                </Stack>
                {this.state.messageBar ? <SimpleMessageBarComponent properties={this.state.messageBar} onDismiss={() => { this.setState({ messageBar: undefined }) }} /> : null}
                {
                    this.state.loggingOut ? (
                        <>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Halo {user.name}!</Text>
                                <Text style={{ textAlign: "center" }}>Harap tunggu sementara kami memproses permintaan logout anda. Setelah logout, anda akan diarahkan ke halaman login dalam beberapa saat.</Text>
                            </Stack>
                            <LoadingComponent label={""} />
                        </>
                    ) : null
                }
                {
                    !this.state.loggingOut ? (
                        <Stack horizontalAlign={"center"}>
                            <ActionButton text={"Coba Lagi"} iconProps={{iconName: "Refresh"}} onClick={this.logout} />
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }
}