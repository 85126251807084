import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { observer } from 'mobx-react';
import { useStore } from '../../stores/root';
import ComingSoon from '../../components/uiframeworks/comingSoon';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import DashboardSchedule from '../../components/dashboard/schedule';

interface IDashboardPageProps {
}

const DashboardPage: React.FC<IDashboardPageProps> = (props: IDashboardPageProps) => {
    const store = useStore();

    useEffect(() => {
        store.topbar.show('Dashboard', [
            { key: 'home', icon: faHome }
        ]);
    }, []);

    return <Stack>
        <DashboardSchedule />
    </Stack>;
};

export default DashboardPage;
