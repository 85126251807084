import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props
// components
import { CommandBar, DefaultButton, ICommandBarItemProps, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import { IDocumentProps, IFileDetailsProps } from '../../../../../../props/general';
import ErrorService from '../../../../../../services/general/error';
import { useStore } from '../../../../../../stores/root';
import DeleteFilesPanel from '../../../../../uiframeworks/files/delete';
import FilesList from '../../../../../uiframeworks/files/list';
import UploadFilesPanel from '../../../../../uiframeworks/files/uploads/panel';
import TrainsService from '../../../../../../services/data/trains';
import PermissionsService from '../../../../../../services/permissions';

interface ITrainDocumentsListProps {
    trainId: string;
    qs?: string[];
    documents?: IDocumentProps[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideDeleteButton?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

const TrainDocumentsList: React.FC<ITrainDocumentsListProps> = (props: ITrainDocumentsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [documents, setDocuments] = React.useState<IDocumentProps[]>(props.documents || []);
    const [selected, setSelected] = React.useState<IDocumentProps[]>([]);
    const [activeDocument, setActiveDocument] = React.useState<IDocumentProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        if (!props.documents) {
            _onRetrieveDocuments();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveDocuments = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            setSelected([]);
            const qs: string[] = props.qs || [];
            const result = await TrainsService.documents.retrieve(props.trainId);
            setDocuments(result);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_train_documents_list_error',
                text: 'Failed to retrieve train documents list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideDeleteButton && selected.length > 0 && PermissionsService.hasPermission(['trains.update.all'], user.permissions)) {
            items.push({
                key: "delete",
                text: "Delete Document(s)",
                iconProps: { iconName: "Delete" },
                onRender: () => {
                    return <DefaultButton text={"Delete Document(s)"}
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => { setActiveSurface('delete') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            })
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['trains.update.all'], user.permissions)) {
            farItems.push({
                key: "upload",
                text: "Upload Document(s)",
                iconProps: { iconName: "Upload" },
                onRender: () => {
                    return <PrimaryButton text={"Upload Document(s)"}
                        iconProps={{ iconName: "Upload" }}
                        onClick={() => { setActiveSurface('upload') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveDocument(undefined);

        if (refresh) { _onRetrieveDocuments() }
    }

    const _onUploadDocuments = async (files: IFileDetailsProps[]) => {
        try {
            const names: string[] = [];
            const fd = new FormData();

            files.map(f => {
                const file = f.data as File
                const allMetaData = {
                    name: file.name,
                    size: file.size,
                }
                fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, type: f.type, ...allMetaData }))
            })
            files.map(f => fd.append('documents[]', f.data))

            await TrainsService.documents.new(props.trainId, fd)

            banner.add({
                key: "upload_train_documents_success",
                variant: 'success',
                icon: faCheck,
                text: `Document(s) ${names.join(", ")} uploaded successfully.`
            });

            setActiveSurface(undefined);
            setActiveDocument(undefined);
            _onRetrieveDocuments();
        } catch (e) {
            throw (e);
        }
    }

    const _onDeleteDocuments = async (files: IDocumentProps[]) => {
        try {
            const names: string[] = [];
            const fd = new FormData();
            files.forEach((file) => {
                fd.append('deleteDocumentIds[]', file.id);
                names.push(`"${file.basename}"`);
            });
            await TrainsService.documents.delete(props.trainId, fd);

            banner.add({
                key: "delete_train_documents_success",
                variant: 'success',
                icon: faCheck,
                text: `Document(s) ${names.join(", ")} deleted successfully.`
            });

            setActiveSurface(undefined);
            setActiveDocument(undefined);
            _onRetrieveDocuments();
        } catch (e) {
            throw (e);
        }
    }

    const _onSelect = (_selected: IDocumentProps[]) => {
        setSelected([..._selected]);
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            <FilesList documentTypes={[
                { key: "document", text: "Document" }
            ]} files={documents.filter((doc) => doc.path.toLowerCase().indexOf(keyword.toLowerCase()) > -1)}
                loaded={loaded}
                onSelectFile={_onSelect}
                selected={selected} />
        </Stack>
        {activeSurface === 'upload' ? <>
            <UploadFilesPanel multiple={true}
                existings={documents}
                title={'Upload Related Document(s)'}
                onCancel={_onSurfaceDismissed}
                onUpload={_onUploadDocuments} />
        </> : null}
        {activeSurface === 'delete' && selected.length > 0 ? <>
            <DeleteFilesPanel title={'Delete Document(s)'}
                files={selected}
                onCancel={_onSurfaceDismissed}
                onDelete={_onDeleteDocuments} />
        </> : null}
    </Stack>;
};

export default TrainDocumentsList;
