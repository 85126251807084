import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../../stores/root';
import { useParams } from 'react-router-dom';
import TrainScheduleDetails from '../../../../components/operationals/trains/schedules/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface ITrainScheduleDetailsPageProps {
}

const TrainScheduleDetailsPage: React.FC<ITrainScheduleDetailsPageProps> = (props: ITrainScheduleDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Trains", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "trains", text: 'Trains'}
        ]);
    }, []);

    return <Stack>
        <TrainScheduleDetails scheduleId={params.scheduleId} />
    </Stack>;
};

export default TrainScheduleDetailsPage;
