import React, { useEffect, useState } from 'react';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services

// props

// components
import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import ValidationService from '../../../../services/validation';
import { useStore } from '../../../../stores/root';
import UserService from '../../../../services/user';

const userService = new UserService;

interface IChangePasswordFormProps {
  onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

type FormDataErrorProps = {
  currentPassword?: string;
  newPassword?: string;
  repeatPassword?: string;
}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = (props: IChangePasswordFormProps) => {
  const { banner } = useStore();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [data, setData] = React.useState<FormDataProps>({
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});
  const [errorMessage, setErrorMessage] = useState<string>()

  const _onSubmit = async () => {
    const fd = new FormData
    setSubmitting(true)
    fd.append('oldPassword', data.currentPassword)
    fd.append('newPassword', data.newPassword)
    fd.append('confirmPassword', data.repeatPassword)
    try {
      await userService.updatePassword(fd)
      props.onDismissed(true)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      setErrorMessage(error as string)
    }
  }

  const isSubmitButtonDisabled = (): boolean => {
    if (error.currentPassword || error.newPassword || error.repeatPassword) {
      return true;
    } else if (data.currentPassword.trim() === '' || data.newPassword.trim() === '' || data.repeatPassword.trim() === '') {
      return true;
    }

    return false;
  }

  return <Panel headerText={'Update Password'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Submit"} onClick={_onSubmit} disabled={isSubmitButtonDisabled()} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating Password ..."} /> : null}
      </Stack>;
    }}>
    {errorMessage &&
      <MessageBar
        messageBarType={MessageBarType.error}
        // isMultiline={true}
        onDismiss={() => { }}
        dismissButtonAriaLabel="Close"
      >
        {errorMessage}
      </MessageBar>
    }
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>

      <Stack.Item>
        <TextField label={"Current Password"}
          required={true}
          value={data.currentPassword}
          onChange={(evt, value) => {
            data.currentPassword = value || "";

            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
            error.currentPassword = validation.message;

            setData({ ...data });
            setError({ ...error });
          }}
          errorMessage={error.currentPassword}
          disabled={submitting}
          type='password'
        />
      </Stack.Item>
      <Stack.Item>
        <TextField label={"New Password"}
          required={true}
          value={data.newPassword}
          onChange={(evt, value) => {
            data.newPassword = value || "";

            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
            error.newPassword = validation.message;

            setData({ ...data });
            setError({ ...error });
          }}
          errorMessage={error.newPassword}
          disabled={submitting}
          type='password'
        />
      </Stack.Item>
      <Stack.Item>
        <TextField label={"Repeat New Password"}
          required={true}
          value={data.repeatPassword}
          onChange={(evt, value) => {
            data.repeatPassword = value || "";

            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
            error.repeatPassword = validation.message;

            setData({ ...data });
            setError({ ...error });
          }}
          errorMessage={error.repeatPassword}
          disabled={submitting}
          type='password'
        />
      </Stack.Item>
    </Stack>
  </Panel>
};

export default ChangePasswordForm;
