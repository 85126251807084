import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../../../typography/text';
import Label from '../../../../../../typography/label';
import { TSelectedFilterKey } from '.';
import { IOutcomeResourceShorterProps } from '../../../../../../../props/finance/outcomes';
import { IOutcomeCategoryResourceShortProps } from '../../../../../../../props/finance/outcomes/categories';

type OutcomeInvoicesSummaryFilterCategoryProps = {
    invoices: IOutcomeResourceShorterProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: IOutcomeCategoryResourceShortProps[]): void;
}

const OutcomeInvoicesSummaryFilterCategory: React.FC<OutcomeInvoicesSummaryFilterCategoryProps> = (props: OutcomeInvoicesSummaryFilterCategoryProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IOutcomeCategoryResourceShortProps[]>([]);

    useEffect(() => {

    }, [props.invoices]);

    const getUniqueCategories = (): IOutcomeCategoryResourceShortProps[] => {
        const categories: IOutcomeCategoryResourceShortProps[] = [];

        props.invoices.forEach((invoice) => {
            const exist = categories.find((c) => c.id === invoice.category?.id);
            if (!exist && invoice.category) {
                categories.push(invoice.category);
            }
        });

        return categories;
    }

    const renderOption = (category: IOutcomeCategoryResourceShortProps) => {
        const isChecked = selected.findIndex((s) => s.id === category.id) > -1;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, category)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{category.name}</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, category: IOutcomeCategoryResourceShortProps) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== category.id);
        } else {
            _selected.push(category);
        }

        setSelected([..._selected]);
        props.onChange('category', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Categories</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search category ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueCategories().filter((c) => c.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((cat) => {
                return renderOption(cat);
            })}
        </Stack>
    </Stack>;
};

export default OutcomeInvoicesSummaryFilterCategory;
