import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import OrderGoodsService from '../../../../../services/orders/orderGoods';

// props
import { IOrderGoodsResourceProps } from '../../../../../props/orders/orderGoods';

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack } from '@fluentui/react';
import LoadingComponent from '../../../../feedbacks/loading';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import OrderGoodsForm from '../form';
import OrderGoodsDetailsProperties from './properties';
import DeleteOrderGoodsForm from '../delete';
import PermissionsService from '../../../../../services/permissions';

interface IOrderGoodsDetailsProps {
    orderId: string;
    orderGoodsId: string;
    onDismissed(refresh?: boolean): void;
}

const OrderGoodsDetails: React.FC<IOrderGoodsDetailsProps> = (props: IOrderGoodsDetailsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [orderGoods, setOrderGoods] = React.useState<IOrderGoodsResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);
            const _orderGoods = await OrderGoodsService.get(props.orderId, props.orderGoodsId);
            setOrderGoods(_orderGoods);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_order_goods_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                text: 'Update',
                onRender: () => {
                    return <PrimaryButton text={'Update Details'} styles={{ root: { marginRight: 20 } }} onClick={() => setActiveSurface('details.update')} />
                }
            });
        }

        if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: 'delete',
                text: 'Delete',
                onRender: () => {
                    return <ActionButton iconProps={{ iconName: "Delete" }} text={"Delete"} onClick={() => setActiveSurface('delete')} />
                }
            })
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        if (activeSurface === 'delete' && refresh) {
            props.onDismissed(true);
            setActiveSurface(undefined);
        } else {
            setActiveSurface(undefined);
            if (refresh) { init(); }
        }
    }

    return <Panel headerText={orderGoods ? orderGoods.goods.name : "Order Goods Details"}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(true)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(true) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && orderGoods ? <>
                <CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />
                <Pivot className={'linePivot'}>
                    <PivotItem key={'details'} headerText={"Details"}>
                        <Stack styles={{ root: { padding: 10 } }}><OrderGoodsDetailsProperties orderGoods={orderGoods} /></Stack>
                    </PivotItem>
                    <PivotItem key={'reports'} headerText={"Reports"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                    <PivotItem key={'trackings'} headerText={"Trackings"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                    <PivotItem key={'documents'} headerText={"Documents"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                </Pivot>
            </> : null}
        </Stack>
        {activeSurface === 'details.update' ? <OrderGoodsForm orderId={props.orderId} orderGoodsId={props.orderGoodsId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && orderGoods ? <DeleteOrderGoodsForm orderId={props.orderId} orderGoods={orderGoods} onDismissed={_onSurfaceDismissed} /> : null}
    </Panel>
};

export default OrderGoodsDetails;
