import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { IColumn, Stack, IContextualMenuItem, ActionButton, ICommandBarItemProps, SearchBox, CommandBar, ShimmeredDetailsList, SelectionMode, Text, PrimaryButton, Link } from '@fluentui/react';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import GlobalConfig from '../../../../../../config';
import { IPaginationResourceShort } from '../../../../../../props/general';
import { IUserResourceShortProps } from '../../../../../../props/user';
import ErrorService from '../../../../../../services/general/error';
import { useStore } from '../../../../../../stores/root';
import Pagination from '../../../../../uiframeworks/pagination';
import GeneralService from '../../../../../../services/general';
import EmployedBankAccountForm from '../form';
import moment from 'moment';
import PermissionsService from '../../../../../../services/permissions';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import { IEmployedBankAccountResourceShort } from '../../../../../../props/users/user';
import { EmployeeBankAccountService } from '../../../../../../services/users/bankAccount';
import DeleteEmloyeeBankAccountForm from '../form/delete';
import SetDefaultEmloyeeBankAccountForm from '../form/setDefault';

interface IBankAccountListProps {
  employeeId: string;
  qs?: string[];
  hideCommandBar?: boolean;
  hideSearch?: boolean;
  hideCreateButton?: boolean;
  isHeaderVisible?: boolean;
  columns?: TBankAccountsListColumn[];
}

type TBankAccountsListColumn = 'bankAccountNumber' | 'actions' | 'status' | 'date'

const BankAccountList: React.FC<IBankAccountListProps> = (props: IBankAccountListProps) => {
  const { banner, user } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showingMore, setShowingMore] = React.useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = React.useState<IEmployedBankAccountResourceShort[]>([]);
  const [activeBankAccount, setActiveBankAccount] = React.useState<IEmployedBankAccountResourceShort | undefined>();
  const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
  const [keyword, setKeyword] = React.useState<string>("");
  const [activeSurface, setActiveSurface] = React.useState<'create' | 'update' | 'delete' | 'setDefault' | undefined>();

  const columns: IColumn[] = [
    {
      key: "bankName",
      fieldName: "bankName",
      name: "Bank Name",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IEmployedBankAccountResourceShort) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
            <Link href={`/users/employments/${item.employeeId}/bank_accounts/${item.id}`}>
              <Text>{item.bankName}</Text>
            </Link>
            {item.isDefault && <Tag>Is Default</Tag>}
          </Stack>
        </Stack.Item>;
      }
    },
    {
      key: "actions",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: IEmployedBankAccountResourceShort) => {
        let menuItems: IContextualMenuItem[] = [];

        if (PermissionsService.hasPermission(['superuser', 'hr'], user.roles)) {

          menuItems.push({
            key: "setDefault", text: "Set as default", iconProps: { iconName: "Check" }, onClick: () => {
              setActiveBankAccount(item);
              setActiveSurface('setDefault');
            }
          })
        }

        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Stack.Item className={"detailsListActionRow"}>
            <ActionButton className={'detailsListActionButton'} menuProps={{
              items: menuItems
            }} />
          </Stack.Item>
        </Stack.Item>;
      }
    },
    {
      key: "accountNumber",
      fieldName: "accountNumber",
      name: "Account Number",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IEmployedBankAccountResourceShort) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Text>{item.accountNumber}</Text>
        </Stack.Item>;
      }
    },
    {
      key: "bankCity",
      fieldName: "bankCity",
      name: "Bank City",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IEmployedBankAccountResourceShort) => {
        return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
          <Text>{item.bankCity}</Text>
        </Stack.Item>;
      }
    },
  ];

  useEffect(() => {
    _onRetrieveBankAccounts();
  }, [keyword]);

  const _onRetrieveBankAccounts = async (pageNumber?: number,) => {
    try {
      setLoaded(false);
      const qs: string[] = props.qs || [];
      qs.push(`top=${GlobalConfig.defaultTop}`);
      if (pageNumber) { qs.push(`page=${pageNumber}`); }
      if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

      const results = await EmployeeBankAccountService.retrieve(props.employeeId, qs.join('&'));
      setBankAccounts(results.data);
      setPagination(results.pagination);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'bankAccount_list_error',
        text: 'Failed to retrieve bankAccount list. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const _onKeywordChanged = (value?: string) => {
    setLoaded(true);
    setKeyword(value || "");
  }

  const getCommandBarItems = () => {
    let items: ICommandBarItemProps[] = [];
    let farItems: ICommandBarItemProps[] = [];

    // items.push({
    //   key: "search",
    //   onRender: () => {
    //     return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
    //   }
    // });

    farItems.push({
      key: "register",
      text: "Create Bank Account",
      iconProps: { iconName: "Add" },
      onRender: () => {
        return <PrimaryButton text={"Create Bank Account"}
          iconProps={{ iconName: "Add" }}
          onClick={() => { setActiveSurface('create') }}
          styles={{ root: { marginLeft: 10 } }} />;
      }
    });

    return { items, farItems };
  }

  const _onSurfaceDismissed = (refresh?: boolean) => {
    setActiveSurface(undefined);
    setActiveBankAccount(undefined);

    if (refresh) { _onRetrieveBankAccounts() }
  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    {!props.hideCommandBar ? <CommandBar
      items={getCommandBarItems().items}
      farItems={getCommandBarItems().farItems}
      styles={{
        root: {
          padding: 0,
          height: 'unset',
          backgroundColor: 'transparent'
        }
      }}
      ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
    <Stack tokens={{ childrenGap: 10 }}>
      {pagination && bankAccounts.length > 0 ? <Pagination goToPage={_onRetrieveBankAccounts} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " bankAccount(s) found"} /> : null}
      <Stack className={styles.list}>
        {
          !loaded || (loaded && bankAccounts.length > 0) ? (
            <>
              <ShimmeredDetailsList
                setKey="items"
                items={bankAccounts}
                columns={PermissionsService.hasPermission(['superuser', 'hr'], user.roles) ? columns : columns.filter(f => f.key !== 'actions')}
                selectionMode={SelectionMode.none}
                enableShimmer={!loaded}
                shimmerLines={5}
                onShouldVirtualize={() => false}
                isHeaderVisible={props.isHeaderVisible}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details" />
            </>
          ) : null
        }
        {loaded && bankAccounts.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
          <Text>BankAccount(s) not found</Text>
        </Stack> : null}
      </Stack>
      {pagination && bankAccounts.length > 0 ? <Pagination goToPage={_onRetrieveBankAccounts} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
    </Stack>
    {activeSurface === 'create' ? <EmployedBankAccountForm onDismissed={_onSurfaceDismissed} employeeId={props.employeeId} /> : null}
    {activeSurface === 'update' && activeBankAccount ? <EmployedBankAccountForm employeeId={props.employeeId} bankAccount={activeBankAccount} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'delete' && activeBankAccount ? <DeleteEmloyeeBankAccountForm bankAccount={activeBankAccount} onDismissed={_onSurfaceDismissed} employeeId={props.employeeId} /> : null}
    {activeSurface === 'setDefault' && activeBankAccount ? <SetDefaultEmloyeeBankAccountForm bankAccount={activeBankAccount} onDismissed={_onSurfaceDismissed} employeeId={props.employeeId} /> : null}
  </Stack>;
};

export default BankAccountList;
