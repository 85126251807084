import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import CardButton from '../../../components/uiframeworks/buttons/card';
import { faGlobe, faHome, faScreenUsers, faTruckFast, faUsers } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../../stores/root';

type TypeTeamPageProps = {
  children?: React.ReactNode;
};

const TeamPage: React.FC<TypeTeamPageProps> = (props) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Teams", [
      { key: "home", icon: faHome, href: "/" },
      { key: "teams", text: 'Teams' },
    ])
  }, []);

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <CardButton
        horizontal
        gap={20}
        buttons={[
          {
            icon: faUsers,
            text: 'Sales Teams',
            description: 'List of all sales teams',
            variant: 'light',
            link: '/users/teams/sales',
            shadow: true,
            width: 'calc(25% - 10px)'
          },
        ]}
      />
    </Stack>
  )
};

export default TeamPage;