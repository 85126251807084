import React from 'react';
import styles from './style.module.scss';

// import assets
import Logo from './../assets/logo/logo-dark.webp';
import LogoSquareLight from './../assets/logo/logo-square-light.webp';

// import router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    RouteComponentProps
} from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../props/user';

// import services
import UserService from '../services/users/user';

// import pages
import Login from './login';
import Logout from './logout';
import ForgetPassword from './forgetPassword';
import VerifyEmail from './verifyEmail';
import Verify from './verifyEmail/verify';
import ResetPassword from './resetPassword';

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import LoadingComponent from '../components/feedbacks/loading';

interface IPageState {
    loaded?: boolean;
    user?: IUserResourceShortProps;
}

export default class AuthPage extends React.Component<RouteComponentProps, IPageState> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {}
    }

    public componentDidMount = async () => {
        try {
            const user = await UserService.currentUser();
            this.setState({ loaded: true, user });
        } catch (e) {
            const page = (this.props.match.params as any).page;
            if (page && page.toLowerCase() !== 'login' && page.toLowerCase() !== 'forgetpassword' ) {
                window.location.href = "/auth/login";
            } else {
                this.setState({ loaded: true, user: undefined });
            }
        }
    }

    public render() {
        const { loaded, user } = this.state;

        return (
            <Stack className={styles.authPage}>
                <Stack className={styles.container} horizontal>
                    <Stack className={styles.leftContainer}>
                        <Stack verticalAlign={"center"} horizontalAlign={"center"} className={styles.content} tokens={{ childrenGap: 30 }}>
                            <Stack horizontalAlign={"center"}>
                                <img src={Logo} className={styles.logo} />
                            </Stack>
                            {
                                loaded ? (
                                    <Switch>
                                        <Route exact path="/auth/login"><Login user={user} route={this.props} /></Route>
                                        <Route exact path="/auth/forgetPassword"><ForgetPassword user={user} route={this.props} /></Route>
                                        {user ? <Route exact path="/auth/logout"><Logout user={user} route={this.props} /></Route> : null}
                                        {user ? <Route exact path="/auth/verify"><Verify user={user} route={this.props} /></Route> : null}
                                        {user ? <Route exact path="/auth/resetPassword"><ResetPassword user={user} route={this.props} /></Route> : null}
                                        {user ? <Route exact path="/auth/verifyEmail"><VerifyEmail user={user} route={this.props} /></Route> : null}
                                    </Switch>
                                ) : <LoadingComponent label={""} labelPosition={"bottom"} />
                            }
                        </Stack>
                    </Stack>
                    <Stack className={styles.rightContainer}>
                        <Stack className={styles.background} styles={{ root: { backgroundColor: '#4e4e4e' } }} />
                        <Stack verticalAlign={"center"} horizontalAlign={"center"} className={styles.content}>
                            <img src={LogoSquareLight} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}