import Request from './../request';

// import props
import { IQuantityUnitResourceShortProps } from './../../props/general/quantityUnit';

const QuantityUnitService = {
    retrieve: async ():Promise<IQuantityUnitResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/quantityUnits`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    new: async (data:FormData):Promise<IQuantityUnitResourceShortProps> => {
        try {
            const result = await Request.post(`/api/quantityUnits`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id:string, data:FormData):Promise<IQuantityUnitResourceShortProps> => {
        try {
            const result = await Request.post(`/api/quantityUnits/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/quantityUnits/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
};

export default QuantityUnitService;