import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';

// props

// components
import { ActionButton, Checkbox, DatePicker, DefaultButton, Dropdown, IconButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { IBankAccountResourceShortProps } from '../../../../../props/general/bankAccount';
import OrderInvoiceService from '../../../../../services/orders/invoices/invoice';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import OrderService from '../../../../../services/orders/order';
import { IOrderResourceProps, IOrderResourceShortProps } from '../../../../../props/orders/order';
import IncomeInvoiceService from '../../../../../services/finance/incomes/invoices/invoice';
import moment from 'moment';
import BankAccountService from '../../../../../services/general/bankAccount';
import { ITruckResourceShortProps } from '../../../../../props/order';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../../../props/data/ships';
import { ITrainResourceShort, ITrainScheduleResourceShort } from '../../../../../props/data/trains';
import OrderTrackingType from '../../../../../manifests/orderTrackingType';
import TrackingTypes from '../../../../../manifests/trackingTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorService from '../../../../../services/general/error';
import TrucksService from '../../../../../services/data/trucks';
import TrainScheduleService from '../../../../../services/data/trains/trainScedule';
import TrainsService from '../../../../../services/data/trains';
import ShipSchedulesService from '../../../../../services/data/ships/shipSchedules';
import ShipsService from '../../../../../services/data/ships';
import { ITruckResourceShort } from '../../../../../props/data/trucks';
import OrderTrackingService from '../../../../../services/orders/trackings/orderTracking';
import { IOrderTrackingResourceShortProps } from '../../../../../props/orders/trackings/orderTracking';
import OrderTrackingDetailsCard from '../details/card';

interface ISortOrderTrackingFormProps {
    order: IOrderResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const SortOrderTrackingForm: React.FC<ISortOrderTrackingFormProps> = (props: ISortOrderTrackingFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = React.useState<string>('all');
    const [trackings, setTrackings] = React.useState<IOrderTrackingResourceShortProps[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _trackings = await OrderTrackingService.retrieve(props.order.id);
        _trackings = _trackings.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0))
        _trackings.forEach((tracking, idx) => {
            tracking.orderNumber = idx;
        });
        setTrackings([..._trackings]);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            trackings.forEach((tracking) => {
                fd.append('trackings[]', JSON.stringify({
                    id: tracking.id,
                    orderNumber: tracking.orderNumber
                }));
            });

            await OrderTrackingService.reorder(props.order.id, fd);

            banner.add({
                key: 'sort_order_tracking_success',
                variant: 'success',
                icon: faCheck,
                text: `Order tracking sorted succesfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onReorder = (tracking: IOrderTrackingResourceShortProps, type: 'up' | 'down') => {
        let _trackings = trackings;
        const currentIdx = _trackings.findIndex((t) => t.id === tracking.id);
        const newIdx = currentIdx + (type === 'up' ? -1 : 1);

        const currentOrderNumber = _trackings[currentIdx].orderNumber;
        const newOrderNumber = _trackings[newIdx].orderNumber;
        _trackings[newIdx].orderNumber = currentOrderNumber;
        _trackings[currentIdx].orderNumber = newOrderNumber;
        _trackings = _trackings.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0))
        _trackings.forEach((tracking, idx) => {
            tracking.orderNumber = idx;
        });

        setTrackings([..._trackings]);
    }

    return <Panel headerText={'Sort Order Tracking'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Sorting order tracking ..."} /> : null}
            </Stack>;
        }}>
        <Stack className={styles.container} tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>{trackings.filter((tracking) => {
                        if (props.order.goodsType === 'fcl' || props.order.goodsType === 'lcl') {
                            return selectedFilter !== 'all' && tracking.container ? tracking.container.id === selectedFilter : true;
                        } else if (props.order.goodsType === 'ftl' || props.order.goodsType === 'ltl') {
                            return selectedFilter !== 'all' && tracking.orderTruck ? tracking.orderTruck.id === selectedFilter : true;
                        } else {
                            return true;
                        }
                    }).map((tracking, idx) => {
                        return <>
                            <OrderTrackingDetailsCard key={tracking.id}
                                showAsTimeline={false} 
                                tracking={tracking}
                                onRenderLeftContainer={() => {
                                    return <Stack verticalAlign={'space-between'}>
                                        {idx > 0 ? <IconButton iconProps={{iconName: "ChevronUp"}} onClick={() => _onReorder(tracking, 'up')} /> : <Stack> </Stack>}
                                        {idx < (trackings.length - 1) ? <IconButton iconProps={{iconName: "ChevronDown"}} onClick={() => _onReorder(tracking, 'down')} /> : null}
                                    </Stack>
                                }} />
                            {idx < (trackings.length - 1) ? <Stack className={'divider'}></Stack> : null}
                        </>
                    })}</Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default SortOrderTrackingForm;
