import React, { useEffect } from 'react';

import { ActionButton, DetailsRow, IColumn, IconButton, SelectionMode, ShimmeredDetailsList, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';

import styles from './../styles.module.scss';
import { IQuantityUnitResourceShortProps } from '../../../../../../props/general/quantityUnit';
import AdditionalCostCostingForm from './form';

type AdditionalCostCostingProps = {
    quantityUnits: IQuantityUnitResourceShortProps[];
    data?: AdditionalCostFormData[];
    submitting?: boolean;
    mode?: string;
    onChange(data?: AdditionalCostFormData[]): void;
}

export type AdditionalCostFormData = {
    id: string;
    name: string;
    price: string;
    priceType: 'lumpsum' | 'perquantity';
    quantity: string;
    quantityUnit?: IQuantityUnitResourceShortProps;
    totalPrice: string;
    vendor?: IVendorResourceShortProps;
    notes?: string;
}

const AdditionalCostCosting: React.FC<AdditionalCostCostingProps> = (props: AdditionalCostCostingProps) => {
    const [data, setData] = React.useState<AdditionalCostFormData[]>(props.data || []);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [activeData, setActiveData] = React.useState<AdditionalCostFormData | undefined>();

    const columns: IColumn[] = [
        {
            key: "idx",
            name: 'No.',
            minWidth: 25,
            maxWidth: 25,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>{(idx || 0) + 1}</Text> : null}
                </Stack>
            }
        },
        {
            key: "vendor",
            name: 'Vendor',
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    <Label size={'xsmall'}>{item.name}</Label>
                    <Text size={'xsmall'}>Vendor: {item.vendor?.name}</Text>
                </Stack>
            }
        },
        {
            key: "notes",
            name: 'Notes',
            minWidth: 150,
            isMultiline: true,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    <Text size={'small'}>{item.notes || ""}</Text>
                </Stack>
            }
        },
        {
            key: "price",
            name: 'Price per Quantity',
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.priceType === 'perquantity' ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.price))}</Text> : null}
                    {item.priceType === 'lumpsum' ? <Text size={'small'} style={{ fontStyle: 'italic' }}>(lumpsum price)</Text> : null}
                </Stack>
            }
        },
        {
            key: "quantity",
            name: 'Qty',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantity))} {item.quantityUnit?.name}</Text> : null}
                </Stack>
            }
        },
        {
            key: "totalPrice",
            name: 'Total Price',
            minWidth: 150,
            maxWidth: 150,
            headerClassName: styles.priceColumn,
            className: styles.priceColumn,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Text> : null}
                    {item.id === 'footer' ? <>
                        <Text size={'xsmall'}>TOTAL</Text>
                        <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getTotalCost())}</Label>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "action",
            name: '',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item: AdditionalCostFormData, idx?: number) => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {item.id !== 'footer' && props.mode !== 'view' ? <>
                        <IconButton iconProps={{ iconName: 'Edit' }}
                            onClick={() => {
                                setActiveData(item);
                                setActiveSurface('form');
                            }} />
                        <IconButton iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                _onDelete(item);
                            }} />
                    </> : null}
                </Stack>
            }
        }
    ];

    const getTotalCost = () => {
        let totalCost = 0;
        data.forEach((d) => totalCost += Number(d.totalPrice));
        return totalCost;
    }

    const _onSurfaceDismissed = () => {
        setActiveSurface(undefined);
    }

    const _onSubmit = (cost: AdditionalCostFormData) => {
        let _data = data;

        if (!activeData) {
            _data = [...data, cost];
        } else {
            _data = [...data.map((d) => d.id === cost.id ? cost : d)];
        }

        props.onChange(_data);
        setActiveSurface(undefined);
        setActiveData(undefined);
    }

    const _onDelete = (cost: AdditionalCostFormData) => {
        const text = `Are you sure to remove additional cost Rp. ${GeneralService.getNumberWithSeparator(Number(cost.totalPrice))} by ${cost.vendor?.name}?`;
        if (window.confirm(text)) {
            const _data = data.filter((d) => d.id !== cost.id);
            props.onChange(_data);
        }
    }

    useEffect(() => {
        setData(props.data ? [...props.data] : []);
    }, [props.data]);

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal horizontalAlign={'space-between'}>
                <Label>Additional Costs</Label>
                {props.mode !== 'view' ? <ActionButton iconProps={{ iconName: "Add" }}
                    text={'Add additional cost'}
                    onClick={() => {
                        setActiveData(undefined);
                        setActiveSurface('form');
                    }} /> : null}
            </Stack>
            <Stack>
                {data.length > 0 ? <ShimmeredDetailsList items={data}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderDetailsFooter={(props) => {
                        return <DetailsRow
                            selectionMode={SelectionMode.none}
                            columns={props?.columns}
                            item={{
                                id: 'footer'
                            }}
                            itemIndex={data.length}
                        />
                    }} /> : null}
                {data.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No related additional cost</Text> : null}
            </Stack>
        </Stack>
        {activeSurface === 'form' ? <AdditionalCostCostingForm quantityUnits={props.quantityUnits} data={activeData} onDismissed={_onSurfaceDismissed} onSubmit={_onSubmit} /> : null}
    </Stack>
}

export default AdditionalCostCosting;