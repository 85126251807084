import React from 'react';

// assets

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

// props

// components
import { ActionButton, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { LoadingLabourCostFormData } from '.';
import VendorsService from '../../../../../../services/data/vendors';
import VendorField from '../../../../../uiframeworks/forms/vendor';
import { IQuantityUnitResourceShortProps } from '../../../../../../props/general/quantityUnit';

type LoadingLabourCostingFormProps = {
    quantityUnits: IQuantityUnitResourceShortProps[];
    data?: Partial<LoadingLabourCostFormData>;
    onDismissed(): void;
    onSubmit(data: LoadingLabourCostFormData): void;
}

type FormDataErrorProps = {
    price?: string;
    quantity?: string;
    notes?: string;
}

const LoadingLabourCostingForm: React.FC<LoadingLabourCostingFormProps> = (props: LoadingLabourCostingFormProps) => {
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [data, setData] = React.useState<Partial<LoadingLabourCostFormData>>(props.data ? {
        ...props.data,
        price: Number(props.data?.price) + "",
        totalPrice: Number(props.data?.price) + ""
    } : {
        id: GeneralService.guid(),
        price: '',
        priceType: 'perquantity',
        quantity: '1',
        totalPrice: '',
        notes: ''
    });

    const isSubmitButtonDisabled = () => {
        if (!data.vendor || (data.price || "").trim() === "" || (data.quantity || "").trim() === "") {
            return true;
        } else if (error.notes || error.price || error.quantity) {
            return true;
        }

        return false;
    }

    const calculateTotalPrice = (_data: Partial<LoadingLabourCostFormData>) => {
        let totalPrice = 0;

        if (_data.priceType === 'lumpsum') {
            totalPrice = Number(_data.price);
        } else if ((_data.price || "").trim() !== "" && (_data.quantity || "").trim() !== "") {
            totalPrice = Number(_data.price) * Number(_data.quantity);
        }

        return totalPrice;
    }

    return <Panel headerText={`${props.data ? 'Update' : 'Add'} Loading Labour`}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={props.data ? "Update" : "Add"} disabled={isSubmitButtonDisabled()} onClick={() => props.onSubmit(data as LoadingLabourCostFormData)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <VendorField required
                selected={data.vendor}
                allowCreate
                onChange={(vendor) => {
                    data.vendor = vendor;
                    setData({ ...data });
                }} />
            <Stack.Item>
                <TextField label={"Notes"}
                    value={data.notes}
                    multiline={true}
                    rows={1}
                    autoAdjustHeight
                    resizable={false}
                    onChange={(evt, value) => {
                        data.notes = value || "";

                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                        error.notes = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.notes} />
            </Stack.Item>
            <Stack className={'divider'}></Stack>
            <Stack.Item>
                <Dropdown label={"Price type"}
                    selectedKey={data.priceType}
                    options={[
                        { key: 'lumpsum', text: 'Lump sum' },
                        { key: 'perquantity', text: 'Per quantity' }
                    ]}
                    required={true}
                    onChange={(evt, opt) => {
                        data.priceType = opt ? opt.key as any : 'perquantity';
                        data.totalPrice = calculateTotalPrice(data).toFixed(2);

                        setData({ ...data });
                    }} />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item styles={{ root: { width: 100 } }}>
                        <TextField label={"Quantity"}
                            value={data.quantity}
                            required={true}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.quantity = value || "";
                                    data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.quantity = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.quantity} />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: 100 } }}>
                        <Dropdown label={" "}
                            selectedKey={data.quantityUnit?.id}
                            options={props.quantityUnits.map((unit) => { return { key: unit.id, text: unit.name } })}
                            styles={{root: {marginTop: 3}}}
                            onChange={(evt, opt) => {
                                const selectedId = opt ? opt.key as string : undefined;
                                data.quantityUnit = props.quantityUnits.find((unit) => unit.id === selectedId);

                                setData({ ...data });
                            }} />
                    </Stack.Item>
                </Stack>
                <Stack.Item grow={1}>
                    <TextField label={data.priceType === 'perquantity' ? "Price per quantity" : "Price"}
                        value={data.price}
                        required={true}
                        prefix={'Rp'}
                        suffix={data.priceType === 'perquantity' ? '/ quantity' : undefined}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.price = value || "";
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                error.price = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.price} />
                </Stack.Item>
            </Stack>
            {data.priceType === 'perquantity' ? <>
                <Stack className={'divider'}></Stack>
                <Stack.Item>
                    <TextField label={"Total price"}
                        value={GeneralService.getNumberWithSeparator(Number(data.totalPrice))}
                        disabled={true}
                        prefix={'Rp'}
                        errorMessage={error.quantity} />
                </Stack.Item>
            </> : null}
        </Stack>
    </Panel>
};

export default LoadingLabourCostingForm;
