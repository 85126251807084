import { ITrainResourceShort, ITrainScheduleResourceShort } from '../../../props/data/trains';
import { IDocumentProps } from '../../../props/general';
import Request from './../../request';

// import props

const TrainsService = {
  retrieve: async (qs?: string): Promise<ITrainResourceShort[]> => {
    try {
      const results = await Request.get(`/api/operationals/trains?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ITrainResourceShort> => {
    try {
      const results = await Request.get(`/api/operationals/trains/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ITrainResourceShort> => {
    try {
      const result = await Request.post(`/api/operationals/trains`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ITrainResourceShort> => {
    try {
      const result = await Request.post(`/api/operationals/trains/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/operationals/trains/${id}`);
    } catch (e) {
      throw e;
    }
  },
  getSchedules: async (trainId: string): Promise<ITrainScheduleResourceShort[]> => {
    try {
      const item = await Request.get(`/api/operationals/trains/${trainId}/schedules`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  documents: {
    retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
      try {
        const results = await Request.get(`/api/operationals/trains/${id}/documents?${qs || ""}`);
        return results;
      } catch (e) {
        throw (e);
      }
    },
    new: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/trains/${id}/documents`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw (e);
      }
    },
    delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/trains/${id}/delete_documents`, data);
        return result;
      } catch (e) {
        throw (e);
      }
    }
  },
};

export default TrainsService;
