import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';

// services
import CompanyService from '../../../../../services/users/company';
import ValidationService from '../../../../../services/validation';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import ErrorService from '../../../../../services/general/error';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';
import { ICompanyResourceShort } from '../../../../../props/users/company';

interface ICompanyFormProps {
    companyId?: string;
    onDismissed(refresh?: boolean, company?: ICompanyResourceShort): void;
}

type FormDataProps = {
    name: string;
    address: string;
    phoneNumber: string;
}

type FormDataErrorProps = {
    name?: string;
    address?: string;
    phoneNumber?: string;
}

const CompanyForm: React.FC<ICompanyFormProps> = (props: ICompanyFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        address: "",
        phoneNumber: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.companyId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['companies.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['companies.update.all'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && props.companyId && (user.data?.companies || []).map((c) => c.id).indexOf(props.companyId) > -1 && PermissionsService.hasPermission(['companies.update.me'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.companyId) {
            const _company = await CompanyService.get(props.companyId);
            setData({
                name: _company.name,
                address: _company.address || "",
                phoneNumber: _company.phoneNumber || ""
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.address || error.phoneNumber) {
            return true;
        } else if (data.name.trim() === '' || data.address.trim() === '' || data.phoneNumber.trim() === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, address, phoneNumber } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("address", address);
            fd.append("phoneNumber", phoneNumber);

            let result;
            if (props.companyId === undefined) {
                result = await CompanyService.new(fd);
            } else {
                result = await CompanyService.update(props.companyId, fd);
            }
            banner.add({
                key: 'create_company_success',
                variant: 'success',
                icon: faCheck,
                text: `Company "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            banner.add({
                key: 'create_company_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Company' : 'Update Company'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating company ..." : "Updating company ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.name = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Address"}
                            required={true}
                            value={data.address}
                            multiline
                            rows={5}
                            autoAdjustHeight={true}
                            resizable={false}
                            onChange={(evt, value) => {
                                data.address = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.address = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.address}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Phone Number"}
                            required={true}
                            value={data.phoneNumber}
                            onChange={(evt, value) => {
                                if ((value || "").trim() !== "" && !isNaN(Number(value))) {
                                    data.phoneNumber = value || "";
                                    
                                    const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                    error.phoneNumber = validation.message;

                                    setData({...data});
                                    setError({...error});
                                }
                            }}
                            errorMessage={error.phoneNumber}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default CompanyForm;
