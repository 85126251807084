import React from 'react';
import { useStore } from '../../../../../../stores/root';
import { EmployeeSalaryService } from '../../../../../../services/users/employee';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { IEmployedSalaryResourceShort } from '../../../../../../props/users/user';
import moment from 'moment';

interface IDeleteSalaryFormProps {
  salary: IEmployedSalaryResourceShort;
  onDismissed(refresh?: boolean): void;
}

const DeleteSalaryForm: React.FC<IDeleteSalaryFormProps> = (props: IDeleteSalaryFormProps) => {
  const { banner } = useStore();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      await EmployeeSalaryService.delete(props.salary.employeeId, props.salary.id);

      banner.add({
        key: 'delete_salary_success',
        variant: 'success',
        icon: faCheck,
        text: `Salary "${props.salary.employee?.name}" deleted successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  return <Panel headerText={"Delete Salary"}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Delete"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting salary ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      <Text>Are you sure you want to delete salary with details below?</Text>
      <Stack.Item>
        <Label>Amount</Label>
        <Text>{props.salary.amount}</Text>
      </Stack.Item>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item styles={{ root: { width: '50%' } }}>
          <Label>Start From</Label>
          <Text>{moment(props.salary.startDate).format('DD/MM/YYYY')}</Text>
        </Stack.Item>
        <Stack.Item >
          <Label>Until</Label>
          <Text>{moment(props.salary.endDate).format('DD/MM/YYYY')}</Text>
        </Stack.Item>
      </Stack>
      <Stack className='divider'></Stack>
    </Stack>
  </Panel>
};

export default DeleteSalaryForm;
