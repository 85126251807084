import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';

import LoadingComponent from '../../../../feedbacks/loading';

type ICreateFolderPanelProps = {
  title?: string;
  onCancel(): void;
  onCreate(folderName: string): Promise<void>
}

const CreateFolderPanel: React.FC<ICreateFolderPanelProps> = (props: ICreateFolderPanelProps) => {
  const [created, setCreated] = useState<boolean>(false)
  const [folderName, setFolderName] = useState<string>()

  const onCreate = async () => {
    if (folderName) {
      setCreated(true)
      try {
        await props.onCreate(folderName)
        setCreated(false)
      } catch (error) {
        setCreated(false)

      }
    }
  }

  return <Panel headerText={props.title || "Create Folder"}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onCancel()}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!created ? <>
          <PrimaryButton text={'Create'} onClick={onCreate} />
          <DefaultButton text={'Cancel'} onClick={() => props.onCancel()} />
        </> : null}
        {created ? <LoadingComponent label='Uploading ...' labelPosition='right' spinnerPosition='baseline' /> : null}
      </Stack>;
    }}>
    <Stack>
      <TextField label='Folder Name' value={folderName} onChange={(e, v) => setFolderName(v ? v.replace(/[\/\\:*?"<>|]|folder_name/g, '') : '')} />
    </Stack>
  </Panel>;
};

export default CreateFolderPanel;
