import React, { useEffect, useState } from 'react';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
// props

// components
import { DefaultButton, Dropdown, ITag, Label, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import ValidationService from '../../../../services/validation';
import { INotificationResourceShort, IUserResourceShortProps } from '../../../../props/users/user';
import CompanyService from '../../../../services/users/company';
import { ICompanyResourceShort } from '../../../../props/users/company';
import ErrorService from '../../../../services/general/error';
import LoadingComponent from '../../../feedbacks/loading';
import NotificationService from '../../../../services/notifications';
import authService from '../../../../services/users/user';
import GeneralService from '../../../../services/general';

interface INotificationFormProps {
  notificationId?: string;
  onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
  subject: string;
  richDescription: string;
  shortDescription: string;
  notification?: INotificationResourceShort
  users: {
    id: string;
    text: string;
    secondaryText: string;
    imageInitials: string;
    metadata: IUserResourceShortProps;
  }[]
}

type FormDataErrorProps = {
  subject?: string;
  richDescription?: string;
  shortDescription?: string;
}

const NotificationForm: React.FC<INotificationFormProps> = (props: INotificationFormProps) => {
  const { banner } = useStore();
  const mode: 'create' | 'update' = props.notificationId === undefined ? 'create' : 'update';

  const [loaded, setLoaded] = React.useState<boolean>(true);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [data, setData] = React.useState<FormDataProps>({
    subject: "",
    richDescription: "",
    shortDescription: "",
    users: []
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});
  const [userListType, setUserListType] = useState<'allUsers' | 'selectedCompanies' | 'selectedUsers'>('allUsers')
  const [company, setCompany] = useState<{
    list: ICompanyResourceShort[],
    loaded: boolean,
    companiesSelected: ICompanyResourceShort[]
  }>({
    list: [],
    loaded: false,
    companiesSelected: []
  })

  useEffect(() => {
    if (!company.loaded && userListType === 'selectedCompanies') {
      getComapanies()
    }
    return () => { }
  }, [company, userListType])

  const isSubmitButtonDisabled = (): boolean => {
    if (error.subject || error.richDescription || error.shortDescription) {
      return true;
    }
    if (data.subject.trim() === '' || data.richDescription.trim() === '' || data.shortDescription.trim() === '') {
      return true;
    }
    return false;
  }

  const _onSubmit = async () => {
    setSubmitting(true);
    try {
      const { subject, richDescription, shortDescription, users } = data;

      // create form data
      let fd = new FormData();
      fd.append("subject", subject);
      fd.append("richDescription", richDescription);
      fd.append("shortDescription", shortDescription);
      fd.append("path", '-');
      fd.append("key", '-');
      if (userListType === 'selectedUsers') {
        fd.append("selectedUsers", '1');
        users.map(user => fd.append('userIds[]', user.id))
      }
      if (userListType === 'selectedCompanies') {
        fd.append("selectedCompanies", '1')
        company.companiesSelected.map(cp => fd.append('companies[]', cp.id))
      }
      if (userListType === 'allUsers') {
        fd.append("allUsers", '1')
      }

      await NotificationService.new(fd)

      banner.add({
        key: mode + '_notification_success',
        variant: 'success',
        icon: faCheck,
        text: `Notification "${subject}" ${mode}ed successfully`
      });
      setSubmitting(false);
      // props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  const getComapanies = async () => {
    try {
      const items = await CompanyService.retrieve()
      setCompany({
        loaded: true,
        list: items.data,
        companiesSelected: []
      })
    } catch (error) {

    }
  }

  const _onSearchCompanies = async (keyword: string) => {
    try {
      const data = company.list.filter(item => item.name.toLowerCase().includes(keyword)).map((item) => ({
        key: item.id,
        name: item.name,
        metadata: item
      }));

      return data;
    } catch (error) {
      banner.add({
        key: "search_companies_error",
        text: "Failed to search related companies. Error: " + ErrorService.getMessage(error),
        variant: 'error',
        icon: faXmarkCircle
      });

      return [];
    }
  }

  const _onSearchUsers = async (keyword: string) => {
    const qs: string[] = [];
    if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }
    try {
      const items = (await authService.retrieve(qs.join("&"))).data.map((user) => {
        return {
          id: user.id,
          text: user.name,
          secondaryText: user.email,
          imageInitials: GeneralService.getInitial(user.name),
          metadata: user,
        };
      })
      return items;
    } catch (error) {
      banner.add({
        key: "search_customers_error",
        text: "Failed to search related customers. Error: " + ErrorService.getMessage(error),
        variant: 'error',
        icon: faXmarkCircle
      });

      return [];
    }
  }

  return <Panel headerText={mode === 'create' ? 'Create Notification' : 'Update Notification'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating notification ..." : "Updating notification ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
      {
        loaded ? <>
          <Stack.Item>
            <TextField label={"Subject"}
              required={true}
              value={data.subject}
              onChange={(evt, value) => {
                data.subject = value || "";

                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                error.subject = validation.message;

                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.subject}
              disabled={submitting} />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Short Description"}
              required={true}
              value={data.shortDescription}
              onChange={(evt, value) => {
                data.shortDescription = value || "";

                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 255 });
                error.shortDescription = validation.message;

                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.shortDescription}
              disabled={submitting} />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Content"}
              required={true}
              multiline
              rows={5}
              resizable={false}
              value={data.richDescription}
              onChange={(evt, value) => {
                data.richDescription = value || "";

                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 10000 });
                error.richDescription = validation.message;

                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.richDescription}
              disabled={submitting} />
          </Stack.Item>
          <Stack.Item>
            <Dropdown required label={"Target"}
              options={[
                { key: "allUsers", text: "All Users" },
                { key: "selectedCompanies", text: "Selected Companies" },
                { key: "selectedUsers", text: "Selected Users" },
              ]}
              selectedKey={userListType}
              onChange={(evt, opt) => {
                setUserListType(opt?.key as any)
              }} />
          </Stack.Item>
          {userListType === 'selectedCompanies' && (
            <Stack.Item>
              <Label required>Companies</Label>
              {company.loaded && (
                <TagPicker
                  selectedItems={company.companiesSelected.map(cs => ({ key: cs.id, name: cs.name }))}
                  removeButtonAriaLabel='Remove'
                  onChange={items => {
                    const companiesSelected: ICompanyResourceShort[] = []
                    items?.map(item => {
                      const cn = company.list.find(f => f.id === item.key)
                      cn && companiesSelected.push(cn)
                    })
                    setCompany({ ...company, companiesSelected })
                  }}
                  onResolveSuggestions={_onSearchCompanies}
                  onEmptyResolveSuggestions={() => _onSearchCompanies('')}
                />
              )}
              {!company.loaded && <LoadingComponent label={'Mengambil data company'} />}
            </Stack.Item>
          )}
          {userListType === 'selectedUsers' && (
            <Stack.Item>
              <Label required>Users</Label>
              <NormalPeoplePicker
                onResolveSuggestions={_onSearchUsers}
                onEmptyResolveSuggestions={() => _onSearchUsers('')}
                selectedItems={data.users}
                onChange={(items?: any) => {
                  if (items) {
                    setData({ ...data, users: items })
                  }
                  return null;
                }}
              />
            </Stack.Item>
          )}
        </> : null
      }
    </Stack>
  </Panel>
};

export default NotificationForm;
