import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../../../typography/text';
import Label from '../../../../../../typography/label';
import { TSelectedFilterKey } from '.';
import { IVendorResourceShortProps } from '../../../../../../../props/data/vendors';
import { IOutcomeResourceShorterProps } from '../../../../../../../props/finance/outcomes';

type OutcomeInvoicesSummaryFilterVendorProps = {
    invoices: IOutcomeResourceShorterProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: IVendorResourceShortProps[]): void;
}

const OutcomeInvoicesSummaryFilterVendor: React.FC<OutcomeInvoicesSummaryFilterVendorProps> = (props: OutcomeInvoicesSummaryFilterVendorProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IVendorResourceShortProps[]>([]);

    useEffect(() => {

    }, [props.invoices]);

    const getUniqueVendors = (): IVendorResourceShortProps[] => {
        const vendors: IVendorResourceShortProps[] = [];

        props.invoices.forEach((invoice) => {
            const exist = vendors.find((c) => c.id === invoice.vendor?.id);
            if (!exist && invoice.vendor) {
                vendors.push(invoice.vendor);
            }
        });

        return vendors;
    }

    const renderOption = (vendor: IVendorResourceShortProps) => {
        const isChecked = selected.findIndex((s) => s.id === vendor.id) > -1;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, vendor)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{vendor.name}</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, vendor: IVendorResourceShortProps) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== vendor.id);
        } else {
            _selected.push(vendor);
        }

        setSelected([..._selected]);
        props.onChange('vendor', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Vendors</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search vendor ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueVendors().filter((c) => c.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((vendor) => {
                return renderOption(vendor);
            })}
        </Stack>
    </Stack>;
};

export default OutcomeInvoicesSummaryFilterVendor;
