import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IUserResourceShortProps } from '../../../../props/users/user';

// components
import { Persona, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../typography/text';
import Heading from '../../../typography/heading';
import Label from '../../../typography/label';
import GeneralService from '../../../../services/general';
import Tag from '../../../uiframeworks/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHome } from '@fortawesome/pro-light-svg-icons';
import UpdatePasswordForm from '../password';
import LogoutForm from '../logout';

interface IProfileDetailsProps {
}

const ProfileDetails: React.FC<IProfileDetailsProps> = (props: IProfileDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [resetPasswordForm, setResetPasswordForm] = useState<JSX.Element>()
    const [logoutForm, setLogoutForm] = useState<JSX.Element>()

    useEffect(() => {
        _onGetProfile();
    }, []);

    const _onGetProfile = async () => {
        try {
            setLoaded(false);

            topbar.show("My Profile", [
                { key: "home", icon: faHome, href: "/" },
                { key: "profile", text: "Profile" }
            ]);

            setLoaded(true);
        } catch (e) {
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetProfile() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {resetPasswordForm}
        {logoutForm}
        {user.data ? <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack className={styles.card} grow={1} tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
                    <Stack>
                        <Label size={'large'}>Profile Details</Label>
                        <Text>Some info may be visible to other people using Jejak Group applications.</Text>
                    </Stack>
                    <Stack>
                        <PrimaryButton text={"Update Profile"} />
                    </Stack>
                </Stack>
                <Stack horizontal className={styles.border} verticalAlign='center'>
                    <Stack styles={{ root: { width: 200 } }}>
                        <Label size={'small'}>Profile Picture</Label>
                    </Stack>
                    <Stack grow={1}>
                        <Text size={'small'}>A profile picture helps personalise your account</Text>
                    </Stack>
                    <Persona imageInitials={GeneralService.getInitial(user.data.name)} imageUrl={user.data.imageUrl} />
                </Stack>
                <Stack horizontal className={styles.border} verticalAlign='center'>
                    <Stack styles={{ root: { width: 200 } }}>
                        <Label size={'small'}>Name</Label>
                    </Stack>
                    <Stack grow={1}>
                        <Text size={'small'}>{user.data.name}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal className={styles.border} verticalAlign='center'>
                    <Stack styles={{ root: { width: 200 } }}>
                        <Label size={'small'}>Email Address</Label>
                    </Stack>
                    <Stack grow={1}>
                        <Text size={'small'}>{user.data.email}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal className={styles.border} verticalAlign='center'>
                    <Stack styles={{ root: { width: 200 } }}>
                        <Label size={'small'}>Roles</Label>
                    </Stack>
                    <Stack grow={1} horizontal tokens={{ childrenGap: 10 }}>
                        {user.data.roles.map((role) => {
                            return <Tag text={role.name} />
                        })}
                    </Stack>
                </Stack>
                <Stack horizontal className={styles.border} verticalAlign='center'>
                    <Stack styles={{ root: { width: 200 } }}>
                        <Label size={'small'}>Phone Number</Label>
                    </Stack>
                    <Stack grow={1}>
                        <Text size={'small'}>{user.data.phoneNumber || "-"}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal verticalAlign='center'>
                    <Stack styles={{ root: { width: 200 } }}>
                        <Label size={'small'}>Address</Label>
                    </Stack>
                    <Stack grow={1}>
                        <Text size={'small'}>{user.data.address || "-"}</Text>
                    </Stack>
                </Stack>
            </Stack>
            <Stack grow={1} styles={{ root: { maxWidth: 300 } }} tokens={{ childrenGap: 20 }}>
                <Stack className={styles.card} horizontal tokens={{ childrenGap: 10 }} verticalAlign='center' onClick={() => {
                    setResetPasswordForm(<UpdatePasswordForm onDismissed={function (refresh?: boolean | undefined): void {
                        setResetPasswordForm(undefined)
                    }} />)
                }}>
                    <Stack grow={1}>
                        <Label>Reset Password</Label>
                        <Text size='small'>Enhance account security by updating your password regularly</Text>
                    </Stack>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Stack>
                <Stack className={styles.card} horizontal tokens={{ childrenGap: 10 }} verticalAlign='center' onClick={() => {
                    setLogoutForm(<LogoutForm onDismissed={function (refresh?: boolean | undefined): void {
                        setLogoutForm(undefined)
                    }} />)
                }}>
                    <Stack grow={1}>
                        <Label>Logout</Label>
                        <Text size='small'>Taking a break? Sign out from your session for added security</Text>
                    </Stack>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Stack>
            </Stack>
        </Stack> : null}
    </Stack>;
};

export default ProfileDetails;
