import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../../services/orders/order';

// props
import { IOrderResourceProps, IOrderResourcerShorterProps, IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { ActionButton, Checkbox, CommandBar, DatePicker, Dropdown, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompanyResourceShort } from '../../../../../props/users/company';
import { TSelectedFilterKey } from '.';

type OrdersDashboardChartFilterCompanyProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: ICompanyResourceShort[]): void;
}

const OrdersDashboardChartFilterCompany: React.FC<OrdersDashboardChartFilterCompanyProps> = (props: OrdersDashboardChartFilterCompanyProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<ICompanyResourceShort[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniqueCompanies = (): ICompanyResourceShort[] => {
        const companies: ICompanyResourceShort[] = [];

        props.orders.forEach((order) => {
            const exist = companies.find((c) => c.id === order.company?.id);
            if (!exist && order.company) {
                companies.push(order.company);
            }
        });

        return companies;
    }

    const renderOption = (company: ICompanyResourceShort) => {
        const isChecked = selected.findIndex((s) => s.id === company.id) > -1;
        const totalItems = props.filteredOrders.filter((o) => o.company?.id === company.id).length;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, company)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{company.name} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, company: ICompanyResourceShort) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== company.id);
        } else {
            _selected.push(company);
        }

        setSelected([..._selected]);
        props.onChange('company', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Companies</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search company ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueCompanies().filter((c) => c.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((company) => {
                return renderOption(company);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterCompany;
