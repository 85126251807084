import React, { useEffect } from 'react';

import { ActionButton, DetailsList, Dropdown, IColumn, SelectionMode, Stack, TagPicker, TextField } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { searchCities, getCitiesOptions } from '../../../../../../manifests/cities';
import CityService from '../../../../../../services/data/cities';
import { IOfferAddressResourceShortProps } from '../../../../../../props/offers/address';
import GeneralService from '../../../../../../services/general';

type OfferAddressDetailsProps = {
    data?: AddressDetailsFormData;
    submitting?: boolean;
    mode?: string;
    onChange(data?: AddressDetailsFormData): void;
}

export type AddressDetailsFormData = {
    originAddresses: IOfferAddressResourceShortProps[];
    destinationAddresses: IOfferAddressResourceShortProps[];
}

export type AddressDetailsFormDataError = {
}

const OfferAddressDetails: React.FC<OfferAddressDetailsProps> = (props: OfferAddressDetailsProps) => {
    const { banner } = useStore();
    const [error, setError] = React.useState<AddressDetailsFormDataError>({});
    const [data, setData] = React.useState<AddressDetailsFormData | undefined>();

    useEffect(() => {
        let _data = props.data || (props.data || { destinationAddresses: [], originAddresses: [] });
        if (props.mode !== 'view') {
            _data = checkEmpty(_data);
        }
        setData({ ..._data });
    }, [props.data]);

    const checkEmpty = (_data: AddressDetailsFormData) => {
        const emptyOriginExist = _data.originAddresses.findIndex((addr) => addr.address.trim() === "") > -1;
        const emptyDestinationExist = _data.destinationAddresses.findIndex((addr) => addr.address.trim() === "") > -1;

        if (!emptyOriginExist) {
            _data.originAddresses.push({
                id: GeneralService.guid(),
                target: 'origin',
                type: "door",
                address: "",
                orderNumber: _data.originAddresses.length + 1
            })
        }

        if (!emptyDestinationExist) {
            _data.destinationAddresses.push({
                id: GeneralService.guid(),
                target: 'destination',
                type: "door",
                address: "",
                orderNumber: _data.destinationAddresses.length + 1
            })
        }

        return _data;
    }

    const columns: IColumn[] = [
        {
            key: "type",
            name: "Type",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOfferAddressResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {props.mode === 'view' ? <Text size={'small'}>{item.type.toUpperCase()}</Text> : null}
                    {props.mode !== 'view' ? <Dropdown selectedKey={item.type}
                        options={[
                            { key: 'door', text: 'Door' },
                            { key: 'yard', text: 'Cont. Yard' },
                            { key: 'port', text: 'Port' }
                        ]}
                        onChange={(evt, opt) => {
                            const value = opt ? opt.key as string : 'door';
                            let _data = data || {
                                originAddresses: [],
                                destinationAddresses: []
                            };

                            if (item.target === 'origin') {
                                const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.originAddresses[idx]) {
                                    _data.originAddresses[idx].type = value;
                                } else {
                                    _data.originAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'origin',
                                        type: value,
                                        address: "",
                                        orderNumber: _data.originAddresses.length + 1
                                    });
                                }
                            } else if (item.target === 'destination') {
                                const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.destinationAddresses[idx]) {
                                    _data.destinationAddresses[idx].type = value;
                                } else {
                                    _data.destinationAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'destination',
                                        type: value,
                                        address: "",
                                        orderNumber: _data.destinationAddresses.length + 1
                                    });
                                }
                            }

                            _data = checkEmpty(_data);
                            props.onChange(_data);
                        }}
                        disabled={props.submitting} /> : null}
                </Stack>
            }
        },
        {
            key: "address",
            name: "Address",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOfferAddressResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {props.mode === 'view' ? <Text size={'small'}>{item.address}</Text> : null}
                    {props.mode !== 'view' ? <TextField value={item.address}
                        disabled={props.submitting}
                        multiline
                        rows={2}
                        autoAdjustHeight
                        resizable={false}
                        onChange={(evt, val) => {
                            const value = val || "";
                            let _data = data || {
                                originAddresses: [],
                                destinationAddresses: []
                            };

                            if (item.target === 'origin') {
                                const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.originAddresses[idx]) {
                                    _data.originAddresses[idx].address = value;
                                } else {
                                    _data.originAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'origin',
                                        type: "door",
                                        address: value,
                                        orderNumber: _data.originAddresses.length + 1
                                    });
                                }
                            } else if (item.target === 'destination') {
                                const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.destinationAddresses[idx]) {
                                    _data.destinationAddresses[idx].address = value;
                                } else {
                                    _data.destinationAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'destination',
                                        type: "door",
                                        address: value,
                                        orderNumber: _data.destinationAddresses.length + 1
                                    });
                                }
                            }

                            _data = checkEmpty(_data);
                            props.onChange(_data);
                        }} /> : null}
                </Stack>
            }
        },
        {
            key: "province",
            name: "Province",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IOfferAddressResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {props.mode === 'view' ? <Text size={'small'}>{item.province || ""}</Text> : null}
                    {props.mode !== 'view' ? <TagPicker selectedItems={(item.province || "").trim() !== '' ? [{ key: item.province || "", name: item.province || "" }] : []}
                        removeButtonAriaLabel='Remove'
                        itemLimit={1}
                        disabled={item.address.trim() === ""}
                        onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                        onChange={(items) => {
                            const value = items && items[0] ? items[0].name : "";
                            let _data = data || {
                                originAddresses: [],
                                destinationAddresses: []
                            };

                            if (item.target === 'origin') {
                                const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.originAddresses[idx]) {
                                    _data.originAddresses[idx].province = value;
                                    _data.originAddresses[idx].city = "";
                                } else {
                                    _data.originAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'origin',
                                        type: "door",
                                        address: "",
                                        province: value,
                                        orderNumber: _data.originAddresses.length + 1
                                    });
                                }
                            } else if (item.target === 'destination') {
                                const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.destinationAddresses[idx]) {
                                    _data.destinationAddresses[idx].province = value;
                                    _data.destinationAddresses[idx].city = "";
                                } else {
                                    _data.destinationAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'destination',
                                        type: "door",
                                        address: "",
                                        province: value,
                                        orderNumber: _data.destinationAddresses.length + 1
                                    });
                                }
                            }

                            _data = checkEmpty(_data);
                            props.onChange(_data);
                        }}
                        onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')} /> : null}
                </Stack>
            }
        },
        {
            key: "city",
            name: "City",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IOfferAddressResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {props.mode === 'view' ? <Text size={'small'}>{item.city || ""}</Text> : null}
                    {props.mode !== 'view' ? <TagPicker selectedItems={(item.city || "").trim() !== '' ? [{ key: item.city || "", name: item.city || "" }] : []}
                        removeButtonAriaLabel='Remove'
                        itemLimit={1}
                        disabled={item.address.trim() === "" || (item.province || "") === ""}
                        onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [item.province || ""])}
                        onChange={(items) => {
                            const value = items && items[0] ? items[0].name : "";
                            let _data = data || {
                                originAddresses: [],
                                destinationAddresses: []
                            };

                            if (item.target === 'origin') {
                                const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.originAddresses[idx]) {
                                    _data.originAddresses[idx].city = value;
                                } else {
                                    _data.originAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'origin',
                                        type: "door",
                                        address: "",
                                        province: "",
                                        city: value,
                                        orderNumber: _data.originAddresses.length + 1
                                    });
                                }
                            } else if (item.target === 'destination') {
                                const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.destinationAddresses[idx]) {
                                    _data.destinationAddresses[idx].city = value;
                                } else {
                                    _data.destinationAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'destination',
                                        type: "door",
                                        address: "",
                                        province: "",
                                        city: value,
                                        orderNumber: _data.destinationAddresses.length + 1
                                    });
                                }
                            }

                            _data = checkEmpty(_data);
                            props.onChange(_data);
                        }}
                        onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'city', [item.province || ""])} /> : null}
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IOfferAddressResourceShortProps, idx?: number) => {
                let show = false;
                if (item.target === 'origin') {
                    show = (idx || 0) < (data?.originAddresses || []).length - 1;
                } else {
                    show = (idx || 0) < (data?.destinationAddresses || []).length - 1;
                }

                if (props.mode === 'view') {
                    show = false;
                }

                return <Stack.Item styles={{ root: { padding: '16px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        {show ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                            let _data = data || {
                                originAddresses: [],
                                destinationAddresses: []
                            };

                            if (item.target === 'origin') {
                                _data.originAddresses = _data.originAddresses.filter((addr) => addr.id !== item.id);
                            } else if (item.target === 'destination') {
                                _data.destinationAddresses = _data.destinationAddresses.filter((addr) => addr.id !== item.id);
                            }

                            _data = checkEmpty(_data);
                            props.onChange(_data);
                        }} /> : null}
                    </Stack.Item>
                </Stack.Item>;
            }
        }
    ]

    return <Stack tokens={{ childrenGap: 15 }}>
        <Stack>
            <Label size={'small'}>Pick-up Location</Label>
            <DetailsList items={data?.originAddresses || []} columns={columns} selectionMode={SelectionMode.none} />
        </Stack>
        {/*props.mode !== 'view' ? <>
                <Stack styles={{ root: { width: 200 } }}>
                    <Dropdown label={"Pick-up Location"}
                        options={[
                            { key: 'door', text: 'Door' },
                            { key: 'yard', text: 'Container Yard' },
                            { key: 'port', text: 'Port' }
                        ]}
                        selectedKey={data?.originType}
                        onChange={(evt, opt) => {
                            let _data = data || {};
                            _data.originType = opt ? opt.key as string : "door";
                            props.onChange(_data);
                        }}
                        disabled={props.submitting} />
                </Stack>
                <Stack grow={1} tokens={{ childrenGap: 10 }}>
                    <Stack grow={1} tokens={{ childrenGap: 20 }}>
                        <TextField label={"Pick-up Address"}
                            value={data?.originAddress}
                            disabled={props.submitting}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={false}
                            onChange={(evt, value) => {
                                let _data = data || {};
                                _data.originAddress = value || "";
                                props.onChange(_data);
                            }} />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label>Province</Label>
                            <TagPicker selectedItems={(data?.originProvince || "").trim() !== '' ? [{ key: data?.originProvince || "", name: data?.originProvince || "" }] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                                onChange={(items) => {
                                    let _data = data || {};
                                    _data.originProvince = items && items[0] ? items[0].name : undefined;
                                    props.onChange(_data);
                                }}
                                onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')}
                            />
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label>City</Label>
                            <TagPicker disabled={(data?.originProvince || "").trim() === ""}
                                selectedItems={(data?.originCity || "").trim() !== '' ? [{ key: data?.originCity || "", name: data?.originCity || "" }] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [data?.originProvince || ""])}
                                onChange={(items) => {
                                    let _data = data || {};
                                    _data.originCity = items && items[0] ? items[0].name : undefined;
                                    props.onChange(_data);
                                }}
                                onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'city', [data?.originProvince || ""])}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                            </> : null*/}
        <Stack>
            <Label size={'small'}>Drop-off Location</Label>
            <DetailsList items={data?.destinationAddresses || []} columns={columns} selectionMode={SelectionMode.none} />
        </Stack>
        {/*props.mode !== 'view' ? <>
                <Stack styles={{ root: { width: 200 } }}>
                    <Dropdown label={"Drop-off Location"}
                        options={[
                            { key: 'door', text: 'Door' },
                            { key: 'yard', text: 'Container Yard' },
                            { key: 'port', text: 'Port' }
                        ]}
                        selectedKey={data?.destinationType}
                        onChange={(evt, opt) => {
                            let _data = data || {};
                            _data.destinationType = opt ? opt.key as string : "door";
                            props.onChange(_data);
                        }}
                        disabled={props.submitting} />
                </Stack>
                <Stack grow={1} tokens={{ childrenGap: 10 }}>
                    <Stack grow={1} tokens={{ childrenGap: 20 }}>
                        <TextField label={"Drop-off Address"}
                            value={data?.destinationAddress}
                            disabled={props.submitting}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={false}
                            onChange={(evt, value) => {
                                let _data = data || {};
                                _data.destinationAddress = value || "";
                                props.onChange(_data);
                            }} />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label>Province</Label>
                            <TagPicker selectedItems={(data?.destinationProvince || "").trim() !== '' ? [{ key: data?.destinationProvince || "", name: data?.destinationProvince || "" }] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                                onChange={(items) => {
                                    let _data = data || {};
                                    _data.destinationProvince = items && items[0] ? items[0].name : undefined;
                                    props.onChange(_data);
                                }}
                                onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')}
                            />
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Label>City</Label>
                            <TagPicker disabled={(data?.destinationProvince || "").trim() === ""}
                                selectedItems={(data?.destinationCity || "").trim() !== '' ? [{ key: data?.destinationCity || "", name: data?.destinationCity || "" }] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [data?.destinationProvince || ""])}
                                onChange={(items) => {
                                    let _data = data || {};
                                    _data.destinationCity = items && items[0] ? items[0].name : undefined;
                                    props.onChange(_data);
                                }}
                                onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'city', [data?.destinationProvince || ""])}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                            </> : null*/}
    </Stack>;
}

export default OfferAddressDetails;

