import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import OfficesList from '../../../components/settings/offices/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IOfficesListPageProps {
}

const OfficesListPage: React.FC<IOfficesListPageProps> = (props: IOfficesListPageProps) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Public Office", [
      { key: "home", icon: faHome, href: "/" },
      { key: "settings", text: 'Settings', href: "/settings" },
      { key: "offices", text: 'Offices' }
    ])
  }, []);

  return (
    <Stack>
      <OfficesList />
    </Stack>
  );
};

export default OfficesListPage;
