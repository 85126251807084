import React from 'react';
import { useStore } from '../../../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../../../services/general';

// props

// components
import { Stack } from '@fluentui/react';
import Label from '../../../../../../typography/label';
import Text from '../../../../../../typography/text';
import { IOutcomePaymentResourceProps } from '../../../../../../../props/finance/outcomes/payments';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import Tag, { TTagVariant } from '../../../../../../uiframeworks/tag';
import { IIncomePaymentResourceProps } from '../../../../../../../props/finance/incomes/payments/payment';
import moment from 'moment';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';

type IncomeInvoicePaymentDetailsPropertiesProps = {
    payment: IIncomePaymentResourceProps;
}

const IncomeInvoicePaymentDetailsProperties: React.FC<IncomeInvoicePaymentDetailsPropertiesProps> = (props: IncomeInvoicePaymentDetailsPropertiesProps) => {
    const { payment } = props;
    const { banner } = useStore();
    const paymentMethod = PaymentMethods.find((m) => m.key === payment.method);

    const renderStatusTag = () => {
        let tagText: string = 'Pending Verification';
        let tagVariant: TTagVariant = 'warning';

        if (payment.status === 'Verified') {
            tagText = 'Verified';
            tagVariant = 'success';
        } else if (payment.status === 'Rejected') {
            tagText = 'Rejected';
            tagVariant = 'error';
        }

        return <Stack horizontal><Tag variant={tagVariant} text={tagText} /></Stack>;
    }

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        {payment.status === 'Rejected' ? <>
            <Stack>
                <Label size={'small'}>Rejection reason</Label>
                <Text>{payment.justification}</Text>
            </Stack>
            <Stack className={'divider'}></Stack>
        </> : null}
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Recorded by</Label>
                <Text>{payment.recordedBy.name}</Text>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Recorded at</Label>
                <Text>{moment(payment.recordedAt).format("DD/MM/YYYY")}</Text>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack>
            <Label size={'small'}>Amount</Label>
            <Text>Rp. {GeneralService.getNumberWithSeparator(payment.amount)}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Payment method</Label>
                <Text>{payment.method}</Text>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Payment date</Label>
                <Text>{moment(payment.paymentDate).format("DD/MM/YYYY")}</Text>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
        </Stack>
        {payment.notes ? <Stack>
            <Label size={'small'}>Notes</Label>
            <Text>{payment.notes}</Text>
        </Stack> : null}
        <Stack className={'divider'}></Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Invoice number</Label>
                <Text>#{IncomeInvoiceService.getInvoiceNumber(payment.invoice.id)}</Text>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Bank account</Label>
                {payment.invoice.bankAccount ? <Text>{payment.invoice.bankAccount.accountNumber} a/n {(payment.invoice.bankAccount.accountName || "").trim() !== "" ? + payment.invoice.bankAccount.accountName : payment.invoice.bankAccount.name} ({payment.invoice.bankAccount.bankName})</Text> : <Text>-</Text>}
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
        </Stack>
    </Stack>
};

export default IncomeInvoicePaymentDetailsProperties;
