import React from 'react';

// assets

// services
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

// props

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { ContainerShippingCostFormData } from '.';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';
import VendorField from '../../../../../uiframeworks/forms/vendor';
import ShipField from '../../../../../uiframeworks/forms/ship';
import ShipScheduleField from '../../../../../uiframeworks/forms/shipSchedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { IQuantityUnitResourceShortProps } from '../../../../../../props/general/quantityUnit';

type ContainerShippingCostingFormProps = {
    quantityUnits: IQuantityUnitResourceShortProps[];
    data?: Partial<ContainerShippingCostFormData>;
    onDismissed(): void;
    onSubmit(data: ContainerShippingCostFormData): void;
}

type FormDataErrorProps = {
    quantity?: string;
    notes?: string;
    thcOrigin?: string;
    thcDestination?: string;
    loloOrigin?: string;
    loloDestination?: string;
    doc?: string;
    seal?: string;
    lss?: string;
    apbs?: string;
    cleaning?: string;
    oceanFreight?: string;
    dutyStamp?: string;
    ops?: string;
    pol?: string;
    pod?: string;
}

const ContainerShippingCostingForm: React.FC<ContainerShippingCostingFormProps> = (props: ContainerShippingCostingFormProps) => {
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [data, setData] = React.useState<Partial<ContainerShippingCostFormData>>(props.data ? {
        ...props.data,
        price: Number(props.data?.price || '0') + "",
        totalPrice: Number(props.data?.price || '0') + "",
        quantity: Number(props.data?.quantity || '0') + "",
        thcOrigin: Number(props.data?.thcOrigin || '0') + "",
        thcDestination: Number(props.data?.thcDestination || '0') + "",
        loloOrigin: Number(props.data?.loloOrigin || '0') + "",
        loloDestination: Number(props.data?.loloDestination) + "",
        doc: Number(props.data?.doc || '0') + "",
        seal: Number(props.data?.seal || '0') + "",
        lss: Number(props.data?.lss || '0') + "",
        apbs: Number(props.data?.apbs || '0') + "",
        cleaning: Number(props.data?.cleaning || '0') + "",
        oceanFreight: Number(props.data?.oceanFreight || '0') + "",
        dutyStamp: Number(props.data?.dutyStamp || '0') + "",
        ops: Number(props.data?.ops || '0') + ""
    } : {
        id: GeneralService.guid(),
        price: '',
        priceType: 'perquantity',
        quantity: '1',
        totalPrice: '',
        notes: '',
        thcOrigin: '',
        thcDestination: '',
        loloOrigin: '',
        loloDestination: '',
        doc: '',
        seal: '',
        lss: '',
        apbs: '',
        cleaning: '',
        oceanFreight: '',
        dutyStamp: '',
        ops: '',
        pol: '',
        pod: ''
    });

    const isSubmitButtonDisabled = () => {
        if (!data.vendor || (data.price || "").trim() === "" || (data.quantity || "").trim() === "") {
            return true;
        } else if (error.notes || error.quantity) {
            return true;
        }

        return false;
    }

    const calculateTotalPrice = (_data: Partial<ContainerShippingCostFormData>) => {
        let totalPrice = 0;

        if ((_data.price || "").trim() !== "" && (_data.quantity || "").trim() !== "") {
            if (_data.priceType === 'lumpsum') {
                totalPrice = Number(_data.price || 0);
            } else {
                totalPrice = Number(_data.price) * Number(_data.quantity);
            }
        }

        return totalPrice;
    }

    const calculatePrice = (_data: Partial<ContainerShippingCostFormData>) => {
        let price = 0;

        if ((_data.apbs || "").trim() !== "") {
            price += Number(_data.apbs)
        }

        if ((_data.cleaning || "").trim() !== "") {
            price += Number(_data.cleaning)
        }

        if ((_data.doc || "").trim() !== "") {
            price += Number(_data.doc)
        }

        if ((_data.ops || "").trim() !== "") {
            price += Number(_data.ops)
        }

        if ((_data.seal || "").trim() !== "") {
            price += Number(_data.seal)
        }

        if ((_data.loloDestination || "").trim() !== "") {
            price += Number(_data.loloDestination)
        }

        if ((_data.loloOrigin || "").trim() !== "") {
            price += Number(_data.loloOrigin)
        }

        if ((_data.thcDestination || "").trim() !== "") {
            price += Number(_data.thcDestination)
        }

        if ((_data.thcOrigin || "").trim() !== "") {
            price += Number(_data.thcOrigin)
        }

        if ((_data.oceanFreight || "").trim() !== "") {
            price += Number(_data.oceanFreight)
        }

        if ((_data.lss || "").trim() !== "") {
            price += Number(_data.lss)
        }

        return price;
    }

    return <Panel headerText={`${props.data ? 'Update' : 'Add'} Container Shipping`}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={props.data ? 'Update' : 'Add'} disabled={isSubmitButtonDisabled()} onClick={() => props.onSubmit(data as ContainerShippingCostFormData)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <VendorField required
                selected={data.vendor}
                allowCreate
                onChange={(vendor) => {
                    data.vendor = vendor;
                    if (!data.vendor) {
                        data.ship = undefined;
                        data.schedule = undefined;
                    }

                    setData({ ...data });
                }} />
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '50%' } }}>
                    <ShipField selected={data.ship}
                        disabled={!data.vendor}
                        vendor={data.vendor}
                        allowCreate={true}
                        onChange={(ship) => {
                            data.ship = ship;
                            if (!data.ship) {
                                data.schedule = undefined;
                            }

                            setData({ ...data });
                        }} />
                </Stack>
                <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '50%' } }}>
                    <ShipScheduleField selected={data.schedule}
                        disabled={!data.ship}
                        ship={data.ship}
                        allowCreate={true}
                        onChange={(schedule) => {
                            data.schedule = schedule;
                            data.pol = schedule?.originAddress || "";
                            data.pod = schedule?.destinationAddress || "";

                            setData({ ...data });
                        }} />
                </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: 'calc(50% - 30px)' } }}>
                    <TextField label={"Port of Loading"}
                        value={data.pol}
                        disabled={data.schedule !== undefined}
                        multiline={true}
                        rows={2}
                        resizable={false}
                        onChange={(evt, value) => {
                            data.pol = value || "";

                            const validation = ValidationService.combination(value, ['limit'], { maxChars: 255 });
                            error.pol = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.pol} />
                </Stack>
                <Stack styles={{ root: { marginTop: 35 } }}><FontAwesomeIcon icon={faArrowRight} /></Stack>
                <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: 'calc(50% - 30px)' } }}>
                    <TextField label={"Port of Discharge"}
                        value={data.pod}
                        disabled={data.schedule !== undefined}
                        multiline={true}
                        rows={2}
                        resizable={false}
                        onChange={(evt, value) => {
                            data.pod = value || "";

                            const validation = ValidationService.combination(value, ['limit'], { maxChars: 255 });
                            error.pod = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.pod} />
                </Stack>
            </Stack>
            <Stack.Item>
                <TextField label={"Notes"}
                    value={data.notes}
                    multiline={true}
                    rows={1}
                    autoAdjustHeight
                    resizable={false}
                    onChange={(evt, value) => {
                        data.notes = value || "";

                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                        error.notes = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.notes} />
            </Stack.Item>
            <Stack className={'divider'}></Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack grow={1}>
                    <Dropdown label={"Price type"}
                        selectedKey={data.priceType}
                        options={[
                            { key: 'lumpsum', text: 'Lump sum' },
                            { key: 'perquantity', text: 'Per quantity' }
                        ]}
                        required={true}
                        onChange={(evt, opt) => {
                            data.priceType = opt ? opt.key as any : 'perquantity';
                            data.totalPrice = calculateTotalPrice(data).toFixed(2);

                            setData({ ...data });
                        }} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item styles={{ root: { width: 100 } }}>
                        <TextField label={"Quantity"}
                            value={data.quantity}
                            required={true}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.quantity = value || "";
                                    data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.quantity = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.quantity} />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: 100 } }}>
                        <Dropdown label={" "}
                            selectedKey={data.quantityUnit?.id}
                            options={props.quantityUnits.map((unit) => { return { key: unit.id, text: unit.name } })}
                            styles={{root: {marginTop: 3}}}
                            onChange={(evt, opt) => {
                                const selectedId = opt ? opt.key as string : undefined;
                                data.quantityUnit = props.quantityUnits.find((unit) => unit.id === selectedId);

                                setData({ ...data });
                            }} />
                    </Stack.Item>
                </Stack>
            </Stack>
            <Stack className={'divider'}></Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <TextField label={"Ocean freight (OF)"}
                        value={data.oceanFreight}
                        required={true}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.oceanFreight = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                error.oceanFreight = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.oceanFreight} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"LSS"}
                        value={data.lss}
                        required={true}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.lss = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.lss = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.lss} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"Seal"}
                        value={data.seal}
                        required={true}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.seal = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.seal = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.seal} />
                </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"THC at POL"}
                        value={data.thcOrigin}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.thcOrigin = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.thcOrigin = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.thcOrigin} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"THC at POD"}
                        value={data.thcDestination}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.thcDestination = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.thcDestination = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.thcDestination} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"LOLO at POL"}
                        value={data.loloOrigin}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.loloOrigin = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.loloOrigin = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.loloOrigin} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"LOLO at POD"}
                        value={data.loloDestination}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.loloDestination = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.loloDestination = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.loloDestination} />
                </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"APBS"}
                        value={data.apbs}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.apbs = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.apbs = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.loloOrigin} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"Cleaning"}
                        value={data.cleaning}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.cleaning = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.cleaning = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.cleaning} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"Doc"}
                        value={data.doc}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.doc = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.doc = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.doc} />
                </Stack>
                <Stack styles={{ root: { width: '25%' } }}>
                    <TextField label={"Ops fee"}
                        value={data.ops}
                        required={true}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.ops = value || "";
                                data.price = calculatePrice(data).toFixed(2);
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                error.ops = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.ops} />
                </Stack>
            </Stack>
            <Stack className={'divider'}></Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item grow={1}>
                    <TextField label={data.priceType === 'perquantity' ? "Shipping cost per quantity" : "Total shipping cost"}
                        value={GeneralService.getNumberWithSeparator(Number(data.price))}
                        required={true}
                        prefix={'Rp'}
                        suffix={data.priceType === 'perquantity' ? '/ quantity' : undefined}
                        disabled={true} />
                </Stack.Item>
                {data.priceType === 'perquantity' ? <>
                    <Stack.Item styles={{ root: { width: 200 } }}>
                        <TextField label={"Total price"}
                            value={GeneralService.getNumberWithSeparator(Number(data.totalPrice))}
                            disabled={true}
                            prefix={'Rp'} />
                    </Stack.Item>
                </> : null}
            </Stack>
        </Stack>
    </Panel>
};

export default ContainerShippingCostingForm;
