import React, { ChangeEvent, DragEvent, DragEventHandler, useEffect } from 'react';
import { useStore } from '../../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, DefaultButton, DetailsList, IColumn, ISelection, Link, Selection, SelectionMode, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../../../typography/label';
import Text from '../../../../../../../typography/text';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import { FormDataProps } from '../../props';
import FileService from '../../../../../../../../services/general/file';
import LoadingComponent from '../../../../../../../feedbacks/loading';

type UploadInvoiceSubformProps = {
    disabled?: boolean;
    data: Partial<FormDataProps>[];
    onChange(data: Partial<FormDataProps>[]): void;
}

const UploadInvoiceSubform: React.FC<UploadInvoiceSubformProps> = (props: UploadInvoiceSubformProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [files, setFiles] = React.useState<File[]>([]);
    const [filePreview, setFilePreview] = React.useState<string[]>();
    const [loadingPreview, setLoadingPreview] = React.useState<boolean>(false);

    const _selection: ISelection = new Selection({
        onSelectionChanged: () => {
            const file = (_selection.getSelection())[0] as File;
            if (file) {
                getFileBase64(file);
            } else {
                setFilePreview(undefined);
            }
        }
    });

    const columns: IColumn[] = [
        {
            key: "name",
            name: "File name",
            minWidth: 0,
            onRender: (item: File) => {
                return <Stack styles={{root: {padding: '5px 0px'}}}>
                    <Text>{item.name}</Text>
                </Stack>
            }
        },
        {
            key: "action",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: File) => {
                return <Stack>
                    <ActionButton iconProps={{iconName: "Delete"}} onClick={() => _onDelete(item)} />
                </Stack>
            }
        }
    ]

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const _files: File[] = [];
        (props.data || []).forEach((d) => {
            if (d.systemFile) {_files.push(d.systemFile);}
        })
        setFiles(_files);
    }, [props.data]);

    const init = async () => {
        setLoaded(true);
    }

    const _onDelete = (file: File) => {
        const { data } = props;
        const idx = data.findIndex((d) => d.systemFile?.name === file.name);
        if (idx > -1) {
            data.splice(idx, 1);
        }

        props.onChange(data);
    }
    
    const _onChange = (_files: FileList | File[]) => {
        const { data } = props;

        for (var ctr = 0; ctr < _files.length || 0; ctr++) {
            const file = _files[ctr] ? _files[ctr] : undefined;
            const idx = (data || []).findIndex((d) => d.systemFile?.name.toLowerCase() === file?.name.toLowerCase());

            if (file && idx < 0) {
                data.push({systemFile: file});
            } else {
                data[idx].systemFile = file;
            }
        }

        props.onChange(data);
    }

    const _onDrop = (evt: DragEvent<HTMLElement>) => {
        evt.preventDefault();
        const { data } = props;
        const files: File[] = [];

        if (evt.dataTransfer.items) {
            [...evt.dataTransfer.items].forEach((item, i) => {
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    if (file && file && files.map((f) => f.name.toLowerCase()).indexOf(file.name.toLowerCase()) < 0) {
                        files.push(file);
                    }
                }
            });
        }

        _onChange(files);
    }

    const getFileBase64 = async (file: File) => {
        setFilePreview(undefined);
        setLoadingPreview(true);

        let results: string[] = [];
        const base64 = FileService.arrayBufferToBase64((await file.arrayBuffer()));
        const extension = file.name.toLowerCase().split(".").pop();

        if (extension === 'pdf') {
            results = await FileService.convertPdfToImage(base64);
        } else if (['jpg', 'jpeg', 'png'].indexOf(extension || "") > 0) {
            results = [`data:image/${extension};base64,${base64}`];
        }

        setLoadingPreview(false);
        setFilePreview(results);
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <Label size={'medium'}>Upload invoices</Label>
        <Stack className={'divider'}></Stack>
        {files.length < 1 ? <Dropzone disabled={props.disabled}>
            {({ getRootProps, getInputProps }) => (
                <Stack tokens={{ childrenGap: 20 }} {...getRootProps({
                    value: files,
                    onChange: (evt) => _onChange((evt?.target as any).files || []),
                    onDrop: _onDrop,
                    className: `${styles.upload} dropzone`,
                    for: 'upload_files'
                })}>
                    <input {...getInputProps({ id: 'upload_files' })} />
                    <FontAwesomeIcon icon={faUpload} fontSize={25} />
                    <Stack tokens={{ childrenGap: 0 }} horizontalAlign='center'>
                        <Label>Drop your invoices here</Label>
                        <Text size={'small'}><Link>Browse invoice</Link> from your computer</Text>
                    </Stack>
                </Stack>
            )}
        </Dropzone> : null}
        {files.length > 0 ? <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack className={styles.preview} grow={1} tokens={{ childrenGap: 10 }}>
                {!filePreview && !loadingPreview ? <Text style={{ color: '#fff' }} size={'small'}>No file selected. Select any file to preview.</Text> : null}
                {loadingPreview ? <LoadingComponent label={'Generating file preview ...'} size={SpinnerSize.medium} /> : null}
                {filePreview && !loadingPreview ? filePreview.map((preview) => {
                    return <img src={preview} className={styles.image} />;
                }) : null}
            </Stack>
            <Stack className={styles.list} tokens={{ childrenGap: 10 }}>
                <Stack>
                    <input type={'file'} onChange={(evt) => _onChange(evt?.target.files || [])} multiple value={[]} />
                </Stack>
                <DetailsList selectionMode={SelectionMode.single}
                    selection={_selection}
                    columns={columns}
                    items={files || []} />
            </Stack>
        </Stack> : null}
    </Stack>
};

export default UploadInvoiceSubform;
