import Request from './../../request';
import { IOrderTruckResourceProps, IOrderTruckResourceShortProps } from '../../../props/orders/trucks';

// import props

const OrderTruckService = {
    retrieve: async (orderId:string, qs?: string):Promise<IOrderTruckResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/orders/${orderId}/trucks?${qs || ""}`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    new: async (orderId: string, data: FormData): Promise<IOrderTruckResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/trucks`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (orderId: string, truckId: string, data: FormData):Promise<IOrderTruckResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/trucks/${truckId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    get: async (orderId: string, truckId: string):Promise<IOrderTruckResourceProps> => {
        try {
            const result = await Request.get(`/api/orders/${orderId}/trucks/${truckId}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (orderId: string, truckId: string):Promise<void> => {
        try {
            await Request.delete(`/api/orders/${orderId}/trucks/${truckId}`);
            return;
        } catch(e) {
            throw(e);
        }
    },
    documents: {
        upload: async (orderId:string, truckId: string, data:FormData) => {
            try {
                const result = await Request.post(`/api/orders/${orderId}/trucks/${truckId}/documents`, data, 'multipart/form-data');
                return result;
            } catch(e) {
                throw(e);
            }
        },
        delete: async (orderId:string, truckId: string, basename: string):Promise<void> => {
            try {
                await Request.delete(`/api/orders/${orderId}/trucks/${truckId}/documents/${basename}`);
                return;
            } catch(e) {
                throw(e);
            }
        }
    }
}

export default OrderTruckService;