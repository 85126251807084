import React from 'react';

// assets

// services
import GeneralService from '../../../../../../services/general';

// props

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from '@fluentui/react';
import { IOrderAddressResourceShortProps } from '../../../../../../props/orders/address';
import CityService from '../../../../../../services/data/cities';
import Label from '../../../../../typography/label';
import { IOfferOfferedPriceResourceShortProps } from '../../../../../../props/offers/offeredPrice';
import { IQuantityUnitResourceShortProps } from '../../../../../../props/general/quantityUnit';
import containersType from '../../../../../../manifests/containersType';
import { FormDataProps } from '../..';
import ValidationService from '../../../../../../services/validation';
import trucksType from '../../../../../../manifests/trucksType';
import SelectQuantityUnit from '../../../../../uiframeworks/forms/quantityUnit';

type RequestOfferItemSubformProps = {
    fd: FormDataProps;
    item?: IOfferOfferedPriceResourceShortProps;
    onDismissed(data?: IOfferOfferedPriceResourceShortProps): void;
}

type FormDataErrorProps = {
    name?: string;
    quantityAmount?: string;
    quantityUnit?: string;
    price?: string;
}

const RequestOfferItemSubform: React.FC<RequestOfferItemSubformProps> = (props: RequestOfferItemSubformProps) => {
    const [data, setData] = React.useState<IOfferOfferedPriceResourceShortProps>({
        id: GeneralService.guid(),
        name: '',
        price: '',
        priceType: '',
        quantityAmount: '',
        totalPrice: '',
        notes: '',
        type: '',
        quantityUnit: {id: '', name: ''}
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    const isSubmitButtonDisabled = () => {
        return false;
    }

    const calculateTotalPrice = (_data: IOfferOfferedPriceResourceShortProps) => {
        let totalPrice = 0;

        if (_data.priceType === 'lumpsum') {
            totalPrice = Number(_data.price);
        } else if ((_data.price || "").trim() !== "" && (_data.quantityAmount || "").trim() !== "") {
            totalPrice = Number(_data.price) * Number(_data.quantityAmount);
        }

        return totalPrice;
    }

    return <Panel headerText={props.item ? 'Update Item' : 'Add Item'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={props.item ? "Update" : "Submit"} disabled={isSubmitButtonDisabled()} onClick={() => props.onDismissed(data)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item styles={{ root: { width: 125 } }}>
                    <Dropdown label={'Name'}
                        required={true}
                        selectedKey={data.type}
                        options={[
                            { key: "container", text: 'Container', disabled: props.fd.goodsType?.key !== 'fcl' },
                            { key: "truck", text: 'Truck', disabled: props.fd.goodsType?.key !== 'ftl' },
                            { key: "goods", text: 'Goods', disabled: props.fd.goodsType?.key === 'fcl' || props.fd.goodsType?.key === 'ftl' },
                            { key: "others", text: 'Others' }
                        ]}
                        onChange={(evt, opt) => {
                            data.type = opt ? opt.key as string : undefined;
                            setData({ ...data });
                        }} />
                </Stack.Item>
                {data.type === 'container' ? <Stack.Item grow={1}>
                    <Dropdown options={containersType}
                        styles={{ root: { marginTop: 25 } }}
                        selectedKey={data.name}
                        onChange={(evt, opt) => {
                            data.name = opt ? opt.key as string : '';

                            const validation = ValidationService.combination(data.name, ['required'], {});
                            error.name = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.name} />
                </Stack.Item> : null}
                {data.type === 'truck' ? <Stack.Item grow={1}>
                    <Dropdown options={trucksType}
                        styles={{ root: { marginTop: 25 } }}
                        selectedKey={data.name}
                        onChange={(evt, opt) => {
                            data.name = opt ? opt.key as string : '';

                            const validation = ValidationService.combination(data.name, ['required'], {});
                            error.name = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.name} />
                </Stack.Item> : null}
                {data.type === 'others' || data.type === 'goods' ? <Stack.Item grow={1}>
                    <TextField value={data.name}
                        styles={{ root: { marginTop: 25 } }}
                        onChange={(evt, value) => {
                            data.name = value || '';

                            const validation = ValidationService.combination(data.name, ['required', 'limit'], { maxChars: 100 });
                            error.name = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.name} />
                </Stack.Item> : null}
                {(data.type || "").trim() === "" ? <Stack.Item grow={1}>
                    <TextField disabled={true} styles={{ root: { marginTop: 25 } }} />
                </Stack.Item> : null}
            </Stack>
            <Stack.Item>
                <TextField label={'Goods list'}
                    multiline
                    rows={3}
                    autoAdjustHeight
                    resizable={false}
                    placeholder='ex. pupuk dolomit 10 jumbo bag (200 kg), jagung kernel 200 zak (100 kg), etc ...'
                    onChange={(evt, value) => {
                        data.notes = value || '';
                        setData({...data});
                    }} />
            </Stack.Item>
            <Stack className={'divider'}></Stack>
            <Stack.Item>
                <Dropdown label={"Price type"}
                    selectedKey={data.priceType}
                    options={[
                        { key: 'lumpsum', text: 'Lump sum' },
                        { key: 'perquantity', text: 'Per quantity' }
                    ]}
                    required={true}
                    onChange={(evt, opt) => {
                        data.priceType = opt ? opt.key as any : 'perquantity';
                        data.totalPrice = calculateTotalPrice(data).toFixed(2);

                        setData({ ...data });
                    }} />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item styles={{ root: { width: 125 } }}>
                        <TextField label={"Quantity"}
                            value={data.quantityAmount}
                            required={true}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.quantityAmount = value || "";
                                    data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 18 });
                                    error.quantityAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.quantityAmount} />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: 100 } }}>
                        <SelectQuantityUnit selected={data.quantityUnit}
                            style={{marginTop: 25}}
                            hideLabel={true}
                            onChange={(unit) => {
                                data.quantityUnit = unit || data.quantityUnit;
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                </Stack>
                <Stack.Item grow={1}>
                    <TextField label={data.priceType === 'perquantity' ? "Price per quantity" : "Price"}
                        value={data.price}
                        required={true}
                        prefix={'Rp'}
                        suffix={data.priceType === 'perquantity' ? `/ ${data.quantityUnit ? data.quantityUnit.name : 'quantity'}` : undefined}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.price = value || "";
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 18 });
                                error.price = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.price} />
                </Stack.Item>
            </Stack>
            {data.priceType === 'perquantity' ? <>
                <Stack className={'divider'}></Stack>
                <Stack.Item>
                    <TextField label={"Total price"}
                        value={GeneralService.getNumberWithSeparator(Number(data.totalPrice))}
                        disabled={true}
                        prefix={'Rp'} />
                </Stack.Item>
            </> : null}
        </Stack>
    </Panel>
};

export default RequestOfferItemSubform;
