import React, { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../../typography/text';
import UserService from '../../../../../../services/users/user';
import LoadingComponent from '../../../../../feedbacks/loading';
import Label from '../../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../../uiframeworks/detailsStack';
import SalesCommissionList from '../../../../salesComissions/general/list';
import PermissionsService from '../../../../../../services/permissions';
import { IEmployedBankAccountResourceShort } from '../../../../../../props/users/user';
import { EmployeeBankAccountService } from '../../../../../../services/users/bankAccount';
import SetDefaultEmloyeeBankAccountForm from '../form/setDefault';
import EmployeeBankAccountForm from '../form';

interface IEmployeebankAccountDetailsProps {
  bankAccountId: string;
  employeeId: string;
}

const EmployeebankAccountDetails: React.FC<IEmployeebankAccountDetailsProps> = (props: IEmployeebankAccountDetailsProps) => {
  const { banner, topbar, user, route } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [bankAccount, setbankAccount] = React.useState<IEmployedBankAccountResourceShort>();
  const [activeSurface, setActiveSurface] = React.useState<'update' | 'setDefault' | undefined>();

  useEffect(() => {
    _onGetAdministrator();
  }, []);

  const _onGetAdministrator = async () => {
    try {
      setLoaded(false);
      const _bankAccount = await EmployeeBankAccountService.read(props.employeeId, props.bankAccountId);
      setbankAccount(_bankAccount);

      topbar.show(_bankAccount.bankName, [
        { key: "home", icon: faHome, href: "/" },
        { key: "users", text: 'Users' },
        { key: "bankAccounts", text: 'Bank Account', href: `/users/employments/${props.employeeId}/bank_accounts` },
        { key: props.bankAccountId, text: _bankAccount.bankName }
      ]);

      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_bankAccount_error',
        text: 'Failed to get bankAccount details. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const getCommandBarItems = () => {
    const items: ICommandBarItemProps[] = [];
    const farItems: ICommandBarItemProps[] = [];

    items.push({
      key: 'update',
      text: 'Update details',
      onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
    });

    items.push({
      key: 'setdefault',
      text: 'Set Default',
      onRender: () => <ActionButton text={'Set Default'} iconProps={{ iconName: "Check" }} onClick={() => setActiveSurface('setDefault')} styles={{ root: { marginLeft: 20 } }} />
    });

    return { items, farItems };
  }

  const getUserPropertiesSection = (_bankAccount: IEmployedBankAccountResourceShort): IStackSectionProps => {
    return {
      header: {
        title: "Details"
      },
      key: 'user_details',
      isCard: true,
      content: <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'small'}>Unique Id</Label>
            <Text>{_bankAccount.id.toUpperCase()}</Text>
          </Stack>
          <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'small'}>Name</Label>
            <Text>{_bankAccount.bankName}</Text>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'small'}>Account Number</Label>
            <Text>{_bankAccount.accountNumber || "-"}</Text>
          </Stack>
          <Stack styles={{ root: { width: '50%' } }}>
            <Label size={'small'}>City</Label>
            <Text>{_bankAccount.bankCity || "-"}</Text>
          </Stack>
        </Stack>
      </Stack>
    }
  }

  const _onSurfaceDismissed = (refresh?: boolean) => {
    setActiveSurface(undefined);
    if (refresh) { _onGetAdministrator() }
  }

  const renderDetailsStack = (_bankAccount: IEmployedBankAccountResourceShort) => {
    const leftSections = [];
    leftSections.push(getUserPropertiesSection(_bankAccount));

    return <Stack className={styles.details} tokens={{ childrenGap: 20 }}>
      <DetailsStack left={leftSections} />
    </Stack>;
  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    {!loaded ? <LoadingComponent label='Retrieving bankAccount details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
    {loaded && bankAccount ? <>
      {PermissionsService.hasPermission(['superuser', 'admin'], user.roles) ? <CommandBar
        items={getCommandBarItems().items}
        farItems={getCommandBarItems().farItems}
        styles={{
          root: {
            padding: 0,
            height: 'unset',
            backgroundColor: 'transparent'
          }
        }}
        ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
      {renderDetailsStack(bankAccount)}
    </> : null}
    {activeSurface === 'update' && bankAccount ? <EmployeeBankAccountForm employeeId={props.employeeId} bankAccount={bankAccount} onDismissed={_onSurfaceDismissed} /> : null}
    {activeSurface === 'setDefault' && bankAccount ? <SetDefaultEmloyeeBankAccountForm bankAccount={bankAccount} onDismissed={_onSurfaceDismissed} employeeId={props.employeeId} /> : null}
  </Stack>;
};

export default EmployeebankAccountDetails;
