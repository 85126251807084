import { ActionButton, ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Icon, Link, Panel, PanelType, PrimaryButton, SpinnerSize, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { NavLink } from 'react-router-dom';
import LoadingComponent from '../../../feedbacks/loading';
import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faImage, faUpload } from '@fortawesome/pro-light-svg-icons';
import FileFormats, { TFileType } from '../../../../manifests/fileFormats';
import GeneralService from '../../../../services/general';
import Dropzone, { useDropzone } from 'react-dropzone';
import { IDocumentProps, } from '../../../../props/general';
import moment from 'moment';
import RenderImage from '../renderImage';
import DocumentService from '../../../../services/documents/document';
import FileService from '../../../../services/general/file';

export type TTagVariant = 'default' | 'active' | 'success' | 'light' | 'error' | 'warning' | 'transparent';

type FilePreviewProps = {
    title?: string;
    file: IDocumentProps;
    metadata?: any;
    onDismissed(): void;
}

const FilePreview: React.FC<FilePreviewProps> = (props: FilePreviewProps) => {
    const [file, setFile] = React.useState<IDocumentProps>(props.file);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [images, setImages] = React.useState<string[]>([]);

    useEffect(() => {
        init(props.file);
    }, [props.file]);

    const init = async (file: IDocumentProps) => {
        const base64 = (await DocumentService.get(encodeURIComponent(file.path))).data.data;
        const images = await FileService.getImageBase64(base64, file.path.split(".").pop() || "");
        setImages(images);
        setLoaded(true);
    }

    return <Dialog hidden={false}
        dialogContentProps={{ title: props.title || file.basename || file.path.split("/").pop() }}
        onDismiss={props.onDismissed}
        modalProps={{ isBlocking: true }}
        styles={{main: {maxWidth: "1024px !important"}}}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack grow={1} className={styles.preview}>
                {!loaded ? <LoadingComponent label={'Generating file preview ...'} size={SpinnerSize.medium} /> : null}
                {images && loaded ? images.map((preview) => {
                    return <img src={preview} className={styles.image} />;
                }) : null}
            </Stack>
            <Stack className={styles.properties} tokens={{childrenGap: 15}}>
                <Stack.Item>
                    <Text size={'xsmall'}>File name</Text>
                    <Label size={'xsmall'}>{file.basename}</Label>
                </Stack.Item>
                <Stack.Item>
                    <Text size={'xsmall'}>Type</Text>
                    <Label size={'xsmall'}>{file.type}</Label>
                </Stack.Item>
                <Stack className={'divider'}></Stack>
                <Stack.Item>
                    <Text size={'xsmall'}>Size</Text>
                    <Label size={'xsmall'}>{(Number(file.size || "0") / 1000).toFixed(2)} KB</Label>
                </Stack.Item>
                <Stack.Item>
                    <Text size={'xsmall'}>Visibility</Text>
                    <Label size={'xsmall'}>{file.visibility}</Label>
                </Stack.Item>
                <Stack.Item>
                    <Text size={'xsmall'}>Uploaded at</Text>
                    <Label size={'xsmall'}>{moment(file.uploadedAt).format("DD/MM/YYYY HH:mm")}</Label>
                </Stack.Item>
            </Stack>
        </Stack>
    </Dialog>;
};

export default FilePreview;
