import { INotificationResourceShort } from '../../props/users/user';
import Request from '../request';

const NotificationService = {
  retrieve: async (): Promise<INotificationResourceShort[]> => {
    try {
      const results = await Request.get(`/api/notifications`);
      return results;
    } catch (e) {
      throw (e);
    }
  },
  get: async (id: string): Promise<INotificationResourceShort> => {
    try {
      const results = await Request.get(`/api/notifications/${id}`);
      return results;
    } catch (e) {
      throw (e);
    }
  },
  new: async (data: FormData): Promise<INotificationResourceShort> => {
    try {
      const result = await Request.post(`/api/notifications`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw (e);
    }
  },
  update: async (id: string, data: FormData): Promise<INotificationResourceShort> => {
    try {
      const result = await Request.post(`/api/notifications/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw (e);
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/notifications/${id}`);
      return;
    } catch (e) {
      throw (e);
    }
  }
}

export default NotificationService;