import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from '../../../../typography/text';
import TeamMemberForm from '../form';
import Pagination from '../../../../uiframeworks/pagination';
import DeleteTeamMemberForm from '../delete';
import ISalesTeamResourceShort, { ISalesTeamMemberResourceShort } from '../../../../../props/users/team';
import { TeamMemberService } from '../../../../../services/users/team';

interface ITeamMemberListProps {
    team?: ISalesTeamResourceShort
}

const TeamMemberList: React.FC<ITeamMemberListProps> = (props: ITeamMemberListProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [teamMembers, setTeamMembers] = React.useState<ISalesTeamMemberResourceShort[]>([]);
    const [activeTeamMember, setActiveTeamMember] = React.useState<ISalesTeamMemberResourceShort | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "sales",
            fieldName: "sales",
            name: "Sales Name",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: ISalesTeamMemberResourceShort) => {
                return <Stack>
                    <Text>{item.sales.name}</Text>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ISalesTeamMemberResourceShort) => {
                const menuItems: IContextualMenuItem[] = [
                    {
                        key: "update",
                        text: "Update details",
                        iconProps: { iconName: "Edit" },
                        onClick: () => {
                            setActiveTeamMember(item);
                            setActiveSurface('update');
                        }
                    },
                    {
                        key: "delete",
                        text: "Delete",
                        iconProps: { iconName: "Delete" },
                        onClick: () => {
                            setActiveTeamMember(item);
                            setActiveSurface('delete');
                        }
                    },
                ];

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item>
                </Stack.Item>;
            },
        },
        {
            key: "team",
            fieldName: "team",
            name: "Team",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: ISalesTeamMemberResourceShort) => {
                return <Stack>
                    <Text>{item.team.name}</Text>
                </Stack>;
            }
        },
        {
            key: "feePercentage",
            fieldName: "feePercentage",
            name: "Fee Percentage",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: ISalesTeamMemberResourceShort) => {
                return <Stack>
                    <Text>{item.feePercentage}</Text>
                </Stack>;
            }
        },
        {
            key: "feeAmount",
            fieldName: "feeAmount",
            name: "Fee Amount",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: ISalesTeamMemberResourceShort) => {
                return <Stack>
                    <Text>{item.feeAmount}</Text>
                </Stack>;
            }
        },
    ];

    useEffect(() => {
        _onRetrieveTeamMembers();
    }, []);

    useEffect(() => {
        _onRetrieveTeamMembers();
    }, [keyword]);

    const _onRetrieveTeamMembers = async (pageNumber?: number,) => {
        setLoaded(false);
        try {
            const qs: string[] = [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (props.team) { qs.push(`teamId=${props.team?.id}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await TeamMemberService.retrieve(qs.join("&"));
            setTeamMembers(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'administrators_list_error',
                text: 'Failed to retrieve administrators list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        farItems.push({
            key: "register",
            text: "Create TeamMember",
            iconProps: { iconName: "Add" },
            onRender: () => {
                return <PrimaryButton text={"Create TeamMember"}
                    iconProps={{ iconName: "Add" }}
                    onClick={() => { setActiveSurface('create') }}
                    styles={{ root: { marginLeft: 10 } }} />;
            }
        });

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveTeamMember(undefined);

        if (refresh) { _onRetrieveTeamMembers() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && teamMembers.length > 0 ? <Pagination goToPage={_onRetrieveTeamMembers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " administrator(s) found"} /> : null}
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && teamMembers.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={teamMembers}
                                columns={props.team ? columns.filter(f => f.key !== 'team') : columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && teamMembers.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Team Member(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && teamMembers.length > 0 ? <Pagination goToPage={_onRetrieveTeamMembers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'create' ? <TeamMemberForm team={props.team} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeTeamMember ? <TeamMemberForm team={props.team} teamMemberId={activeTeamMember.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && activeTeamMember ? <DeleteTeamMemberForm teamMember={activeTeamMember} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default TeamMemberList;
