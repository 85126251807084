import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../../services/orders/order';

// props
import { IOrderResourceProps, IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { ActionButton, CommandBar, DatePicker, Dropdown, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrdersDashboardChartFilterCompany from './company';
import { ICompanyResourceShort } from '../../../../../props/users/company';
import { IFreightCategoryResourceShortProps } from '../../../../../props/orders/freightCategory';
import { IDeliveryTypeResourceShortProps } from '../../../../../props/orders/deliveryType';
import OrdersDashboardChartFilterFreightCategory from './freightCategory';
import OrdersDashboardChartFilterGoodsType from './goodsType';
import { IUserResourceShortProps } from '../../../../../props/users/user';
import OrdersDashboardChartFilterSales from './sales';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import OrdersDashboardChartFilterSubsidiary from './subsidiaries';
import { IOrderStatusResourceShortProps } from '../../../../../props/orders/orderStatus';
import OrdersDashboardChartFilterStatus from './status';
import OrdersDashboardChartFilterPaymentStatus from './paymentStatus';

type OrdersDashboardChartFilterProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    onChange(selectedFilter: SelectedFilterProps): void;
}

export type SelectedFilterProps = {
    company?: ICompanyResourceShort[];
    freightCategory?: IFreightCategoryResourceShortProps[];
    deliveryType?: IDeliveryTypeResourceShortProps;
    goodsType?: string[];
    sales?: IUserResourceShortProps[];
    subsidiary?: ISubsidiariesResourceShort[];
    status?: IOrderStatusResourceShortProps[];
    paymentStatus?: string[];
}

export type TSelectedFilterKey = 'company' | 'freightCategory' | 'deliveryType' | 'goodsType' | 'sales' | 'subsidiary' | 'status' | 'paymentStatus';

const OrdersDashboardChartFilter: React.FC<OrdersDashboardChartFilterProps> = (props: OrdersDashboardChartFilterProps) => {
    const { banner } = useStore();
    const [selectedFilter, setSelectedFilter] = React.useState<SelectedFilterProps>({});

    const _onFilterChange = (key: TSelectedFilterKey, selected: any) => {
        let _selectedFilter = selectedFilter;
        _selectedFilter[key] = selected;

        setSelectedFilter({..._selectedFilter});
        props.onChange(_selectedFilter);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 30}}>
        <OrdersDashboardChartFilterStatus hideSearch filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
        <OrdersDashboardChartFilterPaymentStatus hideSearch filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
        <OrdersDashboardChartFilterFreightCategory hideSearch filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
        <OrdersDashboardChartFilterGoodsType hideSearch filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
        <OrdersDashboardChartFilterSubsidiary hideSearch filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
        <OrdersDashboardChartFilterCompany filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
        <OrdersDashboardChartFilterSales filteredOrders={props.filteredOrders} orders={props.orders} onChange={_onFilterChange} />
    </Stack>;
};

export default OrdersDashboardChartFilter;
