import Request from './../request';

// import props
import { ISalesRecapResourceShortProps, IUserResourceShortProps } from './../../props/users/user';
import { IPaginationResourceShort } from '../../props/general';
import { IRequestFilterProps } from '../request';
import Cookies from 'js-cookie';
import moment from 'moment';
import { IOrderResourceShortProps } from '../../props/orders/order';
import { IRoleResourceShortProps } from '../../props/role';

const authService = {
  retrieve: async (
    qs?: string
  ): Promise<{ data: IUserResourceShortProps[]; pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/users?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  salesRecap: async (id: string, qs?: string): Promise<ISalesRecapResourceShortProps> => {
    try {
      const results = await Request.get(`/api/users/${id}/sales/recap?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.get(`/api/users/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<IUserResourceShortProps> => {
    try {
      const result = await Request.post(`/api/users`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IUserResourceShortProps> => {
    try {
      const result = await Request.post(`/api/users/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/users/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  setVerified: async (id: string): Promise<void> => {
    try {
      await Request.post(`/api/users/${id}/is_verified`);
      return;
    } catch (e) {
      throw e;
    }
  },
  isVerified: async (): Promise<boolean> => {
    try {
      await Request.get(`/api/users/me/isVerified`);
      return true;
    } catch (e: any) {
      if (e.indexOf('not verified') > -1 || e.indexOf('403') > -1 || e.indexOf('401') > -1) {
        return false;
      }
      throw e;
    }
  },
  is_verified: async (): Promise<boolean> => {
    try {
      await Request.get(`/api/is_verified`);
      return true;
    } catch (e: any) {
      if (e.indexOf('not verified') > -1 || e.indexOf('403') > -1 || e.indexOf('401') > -1) {
        return false;
      }
      throw e;
    }
  },
  resetPassword: async (email: string): Promise<IUserResourceShortProps> => {
    try {
      const result = await Request.post(`/api/users/${email}/resetPassword`);
      return result;
    } catch (e) {
      throw e;
    }
  },
  updatePassword: async (data: FormData): Promise<any> => {
    try {
      const result = await Request.post(`/api/password/update`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  currentUser: async (): Promise<IUserResourceShortProps> => {
    try {
      const result = await Request.get(`/api/users/me`);
      return result;
    } catch (e) {
      throw e;
    }
  },
  isRegistered: async (email: string): Promise<boolean> => {
    try {
      const result = await Request.get(`/api/email/${email}`, true);
      return result.isRegistered;
    } catch (e) {
      throw e;
    }
  },
  login: async (email: string, password: string) => {
    try {
      let result = await Request.post(
        '/api/auth/login',
        {
          email,
          password,
        },
        undefined,
        true,
        true
      );

      Cookies.set('user', result.user);
      Cookies.set('roles', result.user.roles);
      Cookies.set('token', result.token.access_token);
      Cookies.set('refresh', result.token.refresh_token);
      Cookies.set(
        'expiry',
        moment()
          .add(result.token.expires_in - 60, 'seconds')
          .toISOString()
      );

      return {};
    } catch (e: any) {
      throw e.message;
    }
  },
  logout: async () => {
    try {
      await Request.post('/api/auth/logout');

      Cookies.remove('user');
      Cookies.remove('roles');
      Cookies.remove('token');
      Cookies.remove('refresh');
      Cookies.remove('expiry');

      return;
    } catch (e: any) {
      throw e.message;
    }
  },
  sendEmailVerification: async () => {
    try {
      await Request.post(`/api/users/verify/resend`);
      return {};
    } catch (e) {
      throw e;
    }
  },
  verify: async (url: string) => {
    try {
      await Request.verify(url);
      return;
    } catch (e) {
      throw e;
    }
  },
  orders: {
    retrieve: async (
      id: string,
      filters?: IRequestFilterProps[]
    ): Promise<{ data: IOrderResourceShortProps[]; pagination: IPaginationResourceShort }> => {
      try {
        let qstring: string | undefined = undefined;
        if (filters) {
          qstring = filters
            .map((filter) => {
              return `${filter.key}=${filter.value.join('|')}`;
            })
            .join('&');
        }

        const results = await Request.get(`/api/users/${id}/orders?${qstring || ''}`);
        return results;
      } catch (e) {
        throw e;
      }
    },
  },
  confirmPassword: async (password: string): Promise<boolean> => {
    try {
      const fd = new FormData();
      fd.append('_p', password);
      const result = await Request.post(`/api/password/confirm`, fd);
      return result;
    } catch (e) {
      throw e;
    }
  },
  hasAccess: (accessTo: string, roles: IRoleResourceShortProps[]) => {
    const temp = {
      finance: ['superuser', 'admin', 'finance'],
    };

    const requiredRoles = (temp as any)[accessTo];
    for (var x = 0; x < roles.length; x++) {
      if (requiredRoles.indexOf(roles[x].key) > -1) {
        return true;
      }
    }
    return false;
  },
  /*hasAccess: (accessTo: string, roles: IRoleResourceShortProps[]) => {
    const temp = {
      finance: ['superuser', 'admin', 'finance'],
    };

    const requiredRoles = (temp as any)[accessTo];
    for (var x = 0; x < roles.length; x++) {
      if (requiredRoles.indexOf(roles[x].key) > -1) {
        return true;
      }
    }
    return false;
  },*/
};

export default authService;
