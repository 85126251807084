import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import ShipDetails from '../../../components/operationals/ships/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IShipDetailsPageProps {
}

const ShipDetailsPage: React.FC<IShipDetailsPageProps> = (props: IShipDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Ships", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "ships", text: 'Ships'}
        ]);
    }, []);

    return <Stack>
        <ShipDetails shipId={params.shipId} />
    </Stack>;
};

export default ShipDetailsPage;
