import React, { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import TrainScheduleDelaysService from '../../../../../../services/data/trains/trainScheduleDelays';

// props
import { ITrainScheduleDelayResourceShort } from '../../../../../../props/data/trains';

// components
import { CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import TrainScheduleDelayForm from '../form';

interface ITrainScheduleDelaysListProps {
    scheduleId: string;
}

const TrainScheduleDelaysList: React.FC<ITrainScheduleDelaysListProps> = (props: ITrainScheduleDelaysListProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [scheduleDelays, setScheduleDelays] = React.useState<ITrainScheduleDelayResourceShort[]>([]);
    const [activeScheduleDelay, setActiveScheduleDelay] = React.useState<ITrainScheduleDelayResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "createdAt",
            name: "Created At",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ITrainScheduleDelayResourceShort) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
                </Stack>
            }
        },
        {
            key: "departureDate",
            name: "New Departure Date",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ITrainScheduleDelayResourceShort) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{moment(item.newEstimatedDeparture).format("DD/MM/YYYY")}</Text>
                </Stack>
            }
        },
        {
            key: "arrivalDate",
            name: "New Arrival Date",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ITrainScheduleDelayResourceShort) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{moment(item.newEstimatedArrival).format("DD/MM/YYYY")}</Text>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        _onRetrieveScheduleDelays();
    }, [keyword]);

    const _onRetrieveScheduleDelays = async () => {
        try {
            const results = await TrainScheduleDelaysService.retrieve(props.scheduleId);
            setScheduleDelays(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_train_schedule_delays_error',
                text: `Failed to retrieve train's schedule delays list. Error: ` + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "register",
            text: "Delay Schedule",
            iconProps: { iconName: "Add" },
            onRender: () => {
                return <PrimaryButton text={"Delay Schedule"}
                    iconProps={{ iconName: "Add" }}
                    onClick={() => { setActiveSurface('create') }}
                    styles={{ root: { marginLeft: 10 } }} />;
            }
        });

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveScheduleDelay(undefined);

        if (refresh) { _onRetrieveScheduleDelays() }
    }

    return <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 10 } }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack>
            {
                !loaded || (loaded && scheduleDelays.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={scheduleDelays}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && scheduleDelays.length < 1 ? <Text>Train schedule delay(s) not found</Text> : null}
        </Stack>
        {activeSurface === 'create' ? <TrainScheduleDelayForm scheduleId={props.scheduleId} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default TrainScheduleDelaysList;