import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import TrainsService from '../../../../../services/data/trains';

// props
import { ITrainResourceShort } from '../../../../../props/data/trains';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import Tag from '../../../../uiframeworks/tag';

interface IDeleteTrainFormProps {
    train: ITrainResourceShort;
    onDismissed(refresh?: boolean): void;
}

const DeleteTrainForm: React.FC<IDeleteTrainFormProps> = (props: IDeleteTrainFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await TrainsService.delete(props.train.id);

            banner.add({
                key: 'delete_train_success',
                variant: 'success',
                icon: faCheck,
                text: `Train "${props.train.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Train"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting user ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete train with details below?</Text>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.train.name}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Vendor</Label>
                <Stack horizontal><Tag text={props.train.vendor.name} /></Stack>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteTrainForm;
