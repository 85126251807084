import { IPaginationResourceShort } from '../../props/general';
import { IEmployedContractResourceShort, IEmployedSalaryResourceShort } from '../../props/users/user';
import Request from '../request';

const EmployeeSalaryService = {
  retrieve: async (employeeId: string, qs?: string): Promise<{ data: IEmployedSalaryResourceShort[], pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/employees/${employeeId}/salaries?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (employeeId: string, id: string): Promise<IEmployedSalaryResourceShort> => {
    try {
      const results = await Request.get(`/api/employees/${employeeId}/salaries/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (employeeId: string, data: FormData): Promise<IEmployedSalaryResourceShort> => {
    try {
      const result = await Request.post(`/api/employees/${employeeId}/salaries`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (employeeId: string, id: string, data: FormData): Promise<IEmployedSalaryResourceShort> => {
    try {
      const result = await Request.post(`/api/employees/${employeeId}/salaries/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (employeeId: string, id: string): Promise<void> => {
    try {
      await Request.delete(`/api/employees/${employeeId}/salaries/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  activate: async (employeeId: string, id: string, data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/employees/${employeeId}/salaries/${id}/activate?_method=PUT`, data, 'multipart/form-data');
      return;
    } catch (e) {
      throw e;
    }
  },
  inactivate: async (employeeId: string, id: string, data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/employees/${employeeId}/salaries/${id}/inactivate?_method=PUT`, data, 'multipart/form-data');
      return;
    } catch (e) {
      throw e;
    }
  },
};

const EmployeeContractService = {
  retrieve: async (employeeId: string, qs?: string): Promise<{ data: IEmployedContractResourceShort[], pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/employees/${employeeId}/contracts?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (employeeId: string, id: string): Promise<IEmployedContractResourceShort> => {
    try {
      const results = await Request.get(`/api/employees/${employeeId}/contracts/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (employeeId: string, data: FormData): Promise<IEmployedContractResourceShort> => {
    try {
      const result = await Request.post(`/api/employees/${employeeId}/contracts`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (employeeId: string, id: string, data: FormData): Promise<IEmployedContractResourceShort> => {
    try {
      const result = await Request.post(`/api/employees/${employeeId}/contracts/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (employeeId: string, id: string): Promise<void> => {
    try {
      await Request.delete(`/api/employees/${employeeId}/contracts/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  activate: async (employeeId: string, id: string, data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/employees/${employeeId}/contracts/${id}/activate?_method=PUT`, data, 'multipart/form-data');
      return;
    } catch (e) {
      throw e;
    }
  },
  inactivate: async (employeeId: string, id: string, data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/employees/${employeeId}/contracts/${id}/inactivate?_method=PUT`, data, 'multipart/form-data');
      return;
    } catch (e) {
      throw e;
    }
  },
};

export { EmployeeSalaryService, EmployeeContractService };
