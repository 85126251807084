import moment from 'moment';
import { IPaginationResourceShort } from '../../props/general';
import { IOfferResourceProps, IOfferResourceShortProps } from '../../props/offers';
import Request from '../request';

const OfferService = {
  getOfferNumber: (offerNumber: string) => {
    return offerNumber.split('.').splice(2).join('.');
  },
  retrieve: async (qs?: string): Promise<{ pagination: IPaginationResourceShort; data: IOfferResourceProps[] }> => {
    try {
      const results = await Request.get(`/api/offers?${qs || ''}`);
      return results;
    } catch (e) {
      throw (e);
    }
  },
  read: async (id: string): Promise<IOfferResourceProps> => {
    try {
      const results = await Request.get(`/api/offers/${id}`);
      return results;
    } catch (e) {
      throw (e);
    }
  },
  convert: async (id: string, data: FormData): Promise<IOfferResourceShortProps> => {
    try {
      const result = await Request.post(`/api/offers/${id}/convert`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw (e);
    }
  },
  request: async (data: FormData): Promise<IOfferResourceShortProps> => {
    try {
      const result = await Request.post(`/api/offers_request`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw (e);
    }
  },
  create: async (data: FormData): Promise<IOfferResourceShortProps> => {
    try {
      const result = await Request.post(`/api/offers`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw (e);
    }
  },
  update: async (id: string, data: FormData): Promise<IOfferResourceShortProps> => {
    try {
      const result = await Request.post(`/api/offers/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw (e);
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/offers/${id}`);
      return;
    } catch (e) {
      throw (e);
    }
  },
  cancel: async (id: string, data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/offers/${id}/cancel`, data);
      return;
    } catch (e) {
      throw (e);
    }
  },
  reject: async (id: string): Promise<void> => {
    try {
      await Request.post(`/api/offers/${id}/reject`);
      return;
    } catch (e) {
      throw (e);
    }
  },
  approve: async (id: string): Promise<void> => {
    try {
      await Request.post(`/api/offers/${id}/approve`);
      return;
    } catch (e) {
      throw (e);
    }
  },
  download: async (offerId: string, offerNumber: string): Promise<any> => {
    try {
      const result = await Request.download(`/api/offers/${offerId}/download`);

      const data = new Blob([result.data], { type: result.headers["content-type"] });
      const url = window.URL.createObjectURL(data);
      const linkTag = document.createElement('a');
      linkTag.href = url;
      linkTag.setAttribute('download', `Penawaran ${offerNumber}.docx`);
      linkTag.click();

      return result;
    } catch (e) {
      throw (e);
    }
  }
}

export default OfferService;