import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import OrderStatusService from '../../../../../services/orders/orderStatus';

// props
import { IOrderStatusResourceShortProps } from '../../../../../props/orders/orderStatus';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import OrderStatusForm from '../form';
import { IQuantityUnitResourceShortProps } from '../../../../../props/general/quantityUnit';
import QuantityUnitService from '../../../../../services/general/quantityUnit';
import QuantityUnitForm from '../form';

interface IQuantityUnitsListProps {
}

const QuantityUnitsList: React.FC<IQuantityUnitsListProps> = (props: IQuantityUnitsListProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [quantityUnits, setQuantityUnits] = React.useState<IQuantityUnitResourceShortProps[]>([]);
    const [activeQuantityUnit, setActiveQuantityUnit] = React.useState<IQuantityUnitResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "name",
            fieldName: "name",
            name: "Name",
            minWidth: 0,
            onRender: (item: IOrderStatusResourceShortProps) => {
                return <Stack styles={{root: {height: '100%'}}}>
                    <Text>{item.name}</Text>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IOrderStatusResourceShortProps) => {
                return <Stack styles={{root: {height: '100%'}}}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: [
                                {key: "update", text: "Update", iconProps: {iconName: "Edit"}, onClick: () => {
                                    setActiveQuantityUnit(item);
                                    setActiveSurface('update');
                                }}
                            ]
                        }} />
                    </Stack.Item>
                </Stack>;
            }
        }
    ];

    useEffect(() => {
        _onRetrieveQuantityUnits();
    }, []);

    const _onRetrieveQuantityUnits = async (pageNumber?: number, ) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = [];
            const results = await QuantityUnitService.retrieve();
            setQuantityUnits(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_quantity_units_error',
                text: 'Failed to retrieve quantity units. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?:string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        farItems.push({
            key: "register",
            text: "Create Quantity Unit",
            iconProps: { iconName: "Add" },
            onRender: () => { 
                return <PrimaryButton text={"Create Quantity Unit"} 
                    iconProps={{ iconName: "Add" }} 
                    onClick={() => {setActiveSurface('create') }} 
                    styles={{ root: { marginLeft: 10 } }} />;
            }
        });

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveQuantityUnit(undefined);

        if (refresh) {_onRetrieveQuantityUnits()}
    }

    return <Stack className={styles.container} tokens={{childrenGap: 20}}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{root: {
                padding: 0,
                height: 'unset',
                backgroundColor: 'transparent'
            }}}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack className={styles.list}>
            {
                !loaded || (loaded && quantityUnits.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={quantityUnits}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && quantityUnits.length < 1 ? <Stack styles={{root: {padding: 10}}}>
                <Text>Quantity unit(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderStatusForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeQuantityUnit ? <QuantityUnitForm quantityUnit={activeQuantityUnit} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default QuantityUnitsList;
