import React, { useEffect, useState } from 'react';
import DocumentService from '../../../../../services/documents/document';

type TypeRenderImageProps = {
  children?: React.ReactNode;
  path: string
  className?: string
};

const RenderImage: React.FC<TypeRenderImageProps> = (props) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const getIamgeData = async () => {
    try {
      const blob = (await DocumentService.get(props.path)).data.data
      setImageSrc(blob)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIamgeData()
    return () => {

    }
  }, [])


  return (
    <>
      {imageSrc &&
        <img className={props.className} src={`data:image/jpeg;base64,${imageSrc}`} alt="Gambar" {...props} />}
    </>
  );
};

export default RenderImage;
