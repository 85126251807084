import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../../stores/root';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import moment from 'moment';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import GeneralService from '../../../../../../../services/general';
import IncomePaymentService from '../../../../../../../services/finance/incomes/payments/payment';
import { DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../../typography/label';
import UploadFilesComponent from '../../../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../../../props/general';
import { IOutcomeSalaryPaymentResourceShortProps } from '../../../../../../../props/finance/outcomes/salaries';
import { EmployeeBankAccountService } from '../../../../../../../services/users/bankAccount';
import { OutcomeSalaryPaymentService } from '../../../../../../../services/finance/outcomes/salaries';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';
import ErrorService from '../../../../../../../services/general/error';

interface IApproveInvoicePaymentFormProps {
  paymentId: string;
  salaryId: string
  onDismissed(refresh?: boolean): void;
}
type FormDataProps = {
  method: string;
  amount: string;
  paymentDate: string;
  reason: string;
}

type FormDataErrorProps = {
  method?: string;
  amount?: string;
  paymentDate?: string;
  reason?: string;
}

const ApproveInvoicePaymentForm: React.FC<IApproveInvoicePaymentFormProps> = (props: IApproveInvoicePaymentFormProps) => {
  const { banner } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<IOutcomeSalaryPaymentResourceShortProps>();
  const [deleteFileIds, setDeleteFileIds] = useState<string[]>([])
  const [files, setFiles] = useState<IFileDetailsProps[]>([])
  const [data, setData] = React.useState<FormDataProps>({
    reason: "",
    method: "cash",
    amount: "",
    paymentDate: moment().toISOString(),
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const _payment = await OutcomeSalaryPaymentService.read(props.salaryId, props.paymentId);

      setData({
        method: _payment.paymentMethod,
        amount: _payment.amount,
        paymentDate: _payment.processedAt,
        reason: _payment.justification || ''
      })
      setPayment(_payment);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_payment_error',
        variant: 'error',
        icon: faXmarkCircle,
        text: `Failed to get invoice "${IncomeInvoiceService.getInvoiceNumber(props.paymentId)}". Error: ${ErrorService.getMessage(e)}`
      });
      props.onDismissed();
    }
  }

  const isSubmitButtonDisabled = (): boolean => {
    return false;
  }

  const _onSubmit = async () => {
    try {
      setSubmitting(true);

      let fd = new FormData();
      fd.append("verificationStatus", 'approved');

      files.map(f => fd.append('documents[]', f.data))

      files.map(f => {
        const file = f.data as File
        const allMetaData = {
          name: file.name,
          size: file.size,
        }
        fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, type: f.type, ...allMetaData }))
      })

      await OutcomeSalaryPaymentService.verify(props.salaryId, props.paymentId, fd);

      banner.add({
        key: 'create_income_payment_success',
        variant: 'success',
        icon: faCheck,
        text: `Payment  recorded successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  const _onUploadDocuments = async (_files: IFileDetailsProps[]) => {
    setFiles(_files)
  }

  return <Panel headerText={'Approve Payment'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Approve"} disabled={isSubmitButtonDisabled()} onClick={() => _onSubmit()} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }}>
      {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
      {
        loaded && payment ? <>
          <Stack.Item>
            <Dropdown label={"Method"}
              required={true}
              disabled
              selectedKey={data.method}
              options={PaymentMethods}
              onChange={(evt, opt) => {
                data.method = opt ? opt.key as string : "";
                setData({ ...data });
              }} />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Amount"}
              value={data.amount}
              required={true}
              prefix={'Rp'}
              errorMessage={error.amount}
              disabled />
          </Stack.Item>
          <Stack.Item>
            <DatePicker label={"Payment Date"}
              isRequired={true}
              value={moment(data.paymentDate).toDate()}
              formatDate={GeneralService.formatDate}
              disabled />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Notes"}
              value={data.reason}
              multiline
              rows={3}
              resizable={false}
              autoAdjustHeight
              errorMessage={error.reason}
              disabled />
          </Stack.Item>
          <Stack styles={{ root: { maxHeight: 600 } }}>
            <Label>Document(s)</Label>
            <UploadFilesComponent multiple onDeleFiles={fileids => setDeleteFileIds(fileids)} files={files} onUpload={_onUploadDocuments} documentTypes={[
              { key: "document", text: "Document" }
            ]}
            />
          </Stack>
        </> : null
      }
    </Stack>
  </Panel>
};

export default ApproveInvoicePaymentForm;