import React, { useEffect } from 'react';
import styles from './../styles.module.scss';

import { ActionButton, DetailsList, DetailsRow, Dropdown, IColumn, IDropdownOption, IconButton, SelectionMode, ShimmeredDetailsList, Stack, TextField } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { FormDataProps } from '..';
import GeneralService from '../../../../../services/general';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import containersType from '../../../../../manifests/containersType';
import trucksType from '../../../../../manifests/trucksType';
import QuantityUnitService from '../../../../../services/general/quantityUnit';
import { IQuantityUnitResourceShortProps } from '../../../../../props/general/quantityUnit';
import LoadingComponent from '../../../../feedbacks/loading';
import OfferedPriceForm from './form';
import OfferedPriceIncludedExcludedItemForm from './includedExcludedItem';

type OfferedPriceProps = {
    fd: FormDataProps;
    data?: OfferedPriceFormData;
    submitting?: boolean;
    mode?: string;
    quantityUnits: IQuantityUnitResourceShortProps[];
    onChange(data?: OfferedPriceFormData, error?: OfferedPriceFormPropsDataError): void;
}

export type OfferedPriceFormData = {
    items: OfferedPriceItemData[];
    subtotalPrice: string;
    totalPrice: string;
    paymentTerm: string;
    included: OfferedPriceInclusiveData[];
    excluded: OfferedPriceInclusiveData[];
}

export type OfferedPriceFormPropsDataError = {
    /*price?: string;
    paymentTerm?: string;
    notes?: string;*/
}

export type OfferedPriceItemData = {
    id: string;
    type?: string;
    quantityAmount: string;
    quantityUnit: IQuantityUnitResourceShortProps;
    priceType: string;
    price: string;
    totalPrice: string;
    name: string;
    notes: string;
    touched?: boolean;
}

export type OfferedPriceInclusiveData = {
    id: string;
    name: string;
    auto: boolean;
}

const OfferedPrice: React.FC<OfferedPriceProps> = (props: OfferedPriceProps) => {
    const [error, setError] = React.useState<OfferedPriceFormPropsDataError>({});
    const [data, setData] = React.useState<OfferedPriceFormData>(props.data || {
        items: [],
        subtotalPrice: '',
        totalPrice: '',
        paymentTerm: '',
        included: [],
        excluded: []
    });
    const [quantityUnits, setQuantityUnits] = React.useState<IQuantityUnitResourceShortProps[]>([]);
    const [activeItem, setActiveItem] = React.useState<OfferedPriceItemData | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        setData(data);
        init();
    }, [props.data]);

    /*useEffect(() => {
        init();
    }, [props]);*/

    const init = () => {
        const { fd } = props;
        const _data = data;
        _data.included = _data.included.filter((included) => !included.auto);
        _data.excluded = _data.excluded.filter((excluded) => !excluded.auto);
        let touched: boolean = false;

        const included: string[] = [];
        const excluded: string[] = [];

        const originCities = Array.from(new Set((fd.addressDetails?.originAddresses || []).map((address) => address.city).filter(Boolean)));
        const destinationCities = Array.from(new Set((fd.addressDetails?.destinationAddresses || []).map((address) => address.city).filter(Boolean)));

        if (fd.includedCosts?.originTrucking) {
            const text = "Biaya dooring di " + originCities.join(", ");
            included.push(text);
        } else {
            const text = "Biaya dooring di " + originCities.join(", ");
            excluded.push(text);
        }

        if (fd.includedCosts?.destinationTrucking) {
            const text = "Biaya dooring di " + destinationCities.join(", ");
            included.push(text);
        } else {
            const text = "Biaya dooring di " + destinationCities.join(", ");
            excluded.push(text);
        }

        if (fd.includedCosts?.loadingLabour) {
            const text = "Biaya buruh muat";
            included.push(text);
        } else {
            const text = "Biaya buruh muat";
            excluded.push(text);
        }

        if (fd.includedCosts?.unloadingLabour) {
            const text = "Biaya buruh bongkar";
            included.push(text);
        } else {
            const text = "Biaya buruh bongkar";
            excluded.push(text);
        }

        if (fd.includedCosts?.containerShipping) {
            (fd.containerShippingCost || []).map((shipping) => {
                const text = "Biaya pelayaran " + shipping.pol + " - " + shipping.pod
                included.push(text);
            });
        }

        if (fd.includedCosts?.charteredShip) {
            const text = "Biaya kapal/pelayaran"
            included.push(text);
        }

        if (fd.includedCosts?.additionalCosts) {
            (fd.additionalCosts || []).map((item) => {
                const text = "Biaya " + item.name
                included.push(text);
            });
        }

        if (fd.includedCosts?.insuranceCost && (fd.insuranceCost || []).length > 0) {
            (fd.insuranceCost || []).map((item) => {
                const text = `Asuransi barang "${item.name}" senilai Rp. ${GeneralService.getNumberWithSeparator(Number(item.goodsValue))}`
                included.push(text);
            })
        } else {
            const text = `Asuransi barang`
            excluded.push(text);
        }

        if (fd.includedCosts?.ppn) {
            const text = `PPn`
            included.push(text);
        } else {
            const text = `PPn`
            excluded.push(text);
        }

        if (fd.includedCosts?.pph) {
            const text = `PPh`
            included.push(text);
        } else {
            const text = `PPh`
            excluded.push(text);
        }

        included.forEach((name) => {
            const exist = _data.included.findIndex((i) => i.name.toLowerCase() === name.toLowerCase()) > -1;
            if (!exist) {
                touched = true;
                _data.included.push({ id: GeneralService.guid(), name, auto: true });
            }
        })

        excluded.forEach((name) => {
            const exist = _data.excluded.findIndex((i) => i.name.toLowerCase() === name.toLowerCase()) > -1;
            if (!exist) {
                touched = true;
                _data.excluded.push({ id: GeneralService.guid(), name, auto: true });
            }
        })

        if (touched) { props.onChange(_data); }
    }

    const columns: IColumn[] = [
        {
            key: "idx",
            name: 'No.',
            minWidth: 25,
            maxWidth: 25,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>{(idx || 0) + 1}</Text> : null}
                </Stack>
            }
        },
        {
            key: "name",
            name: 'Name',
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    <Text size={'small'}>{item.name}</Text>
                </Stack>
            }
        },
        {
            key: "notes",
            name: 'Notes',
            minWidth: 150,
            isMultiline: true,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    <Text size={'small'}>{item.notes || ""}</Text>
                </Stack>
            }
        },
        {
            key: "price",
            name: 'Price per Quantity',
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.priceType === 'perquantity' ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.price))}</Text> : null}
                    {item.priceType === 'lumpsum' ? <Text size={'small'} style={{ fontStyle: 'italic' }}>(lumpsum price)</Text> : null}
                </Stack>
            }
        },
        {
            key: "quantity",
            name: 'Qty',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>{GeneralService.getNumberWithSeparator(Number(item.quantityAmount))} {item.quantityUnit?.name}</Text> : null}
                </Stack>
            }
        },
        {
            key: "totalPrice",
            name: 'Total Price',
            minWidth: 150,
            maxWidth: 150,
            headerClassName: styles.priceColumn,
            className: styles.priceColumn,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack styles={{ root: { padding: '8px 0px' } }}>
                    {item.id !== 'footer' ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Text> : null}
                    {item.id === 'footer' ? <>
                        <Text size={'xsmall'}>TOTAL</Text>
                        <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getTotalCost())}</Label>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "action",
            name: '',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item: OfferedPriceItemData, idx?: number) => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {item.id !== 'footer' && props.mode !== 'view' ? <>
                        <IconButton iconProps={{ iconName: 'Edit' }}
                            onClick={() => {
                                setActiveItem(item);
                                setActiveSurface('form');
                            }} />
                        <IconButton iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                _onDelete(item);
                            }} />
                    </> : null}
                </Stack>
            }
        }
    ];

    const getTotalCost = () => {
        let totalCost = 0;
        data.items.forEach((d) => totalCost += Number(d.totalPrice));
        return totalCost;
    }

    const _onDelete = (item: OfferedPriceItemData) => {
        const text = `Are you sure to remove item (${item.type}) ${item.name} Rp. ${GeneralService.getNumberWithSeparator(Number(item.totalPrice))}?`;
        if (window.confirm(text)) {
            const _items = data.items.filter((d) => d.id !== item.id);
            data.items = _items;

            data.subtotalPrice = calculateSubtotalPrice(data);
            data.totalPrice = calculateTotalPrice(data);

            props.onChange(data);
        }
    }

    const _onSubmit = (item: OfferedPriceItemData) => {
        let _data = data;

        if (!activeItem) {
            _data.items = [...data.items, item];
        } else {
            _data.items = [...data.items.map((i) => i.id === item.id ? item : i)];
        }

        _data.subtotalPrice = calculateSubtotalPrice(_data);
        _data.totalPrice = calculateTotalPrice(_data);

        props.onChange(_data);
        setActiveSurface(undefined);
        setActiveItem(undefined);
    }

    const _onSurfaceDismissed = () => {
        setActiveSurface(undefined);
    }

    const calculateSubtotalPrice = (_data: OfferedPriceFormData) => {
        let subtotal = 0;
        (_data.items || []).forEach((item) => {
            subtotal += Number(item.totalPrice || '0');
        });

        return subtotal.toFixed(2);
    }

    const calculateTotalPrice = (_data: OfferedPriceFormData) => {
        let subtotal = Number(calculateSubtotalPrice(_data));
        let total = subtotal;

        return total.toFixed(2);
    }

    const getProfitTextClass = () => {
        const { fd } = props;

        let textClass = 'color-green';
        if (fd.profitPercentage <= 0) {
            textClass = 'color-red';
        } else if (fd.profitPercentage < 1.5) {
            textClass = 'color-yellow';
        }

        return textClass;
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack tokens={{ childrenGap: 3 }}>
            <Stack horizontal tokens={{childrenGap: 20}} horizontalAlign={'space-between'}>
                <Label>What's Included</Label>
                {props.mode !== 'view' ? <ActionButton text={'Add included item'} iconProps={{iconName: 'Add'}} onClick={() => setActiveSurface('add.included')} /> : null}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                {data.included.map((included) => {
                    return <Tag>
                        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign='center'>
                            <Text size={'small'}>{included.name}</Text>
                            {!included.auto && props.mode !== 'view' ? <ActionButton styles={{root: {height: 'unset !important', minHeight: 'unset !important'}}} 
                                iconProps={{iconName: 'Delete'}}
                                onClick={() => {
                                    data.included = data.included.filter((inc) => inc.id !== included.id);
                                    props.onChange(data);
                                }} /> : null}
                        </Stack>
                    </Tag>
                })}
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 3 }}>
            <Stack horizontal tokens={{childrenGap: 20}} horizontalAlign={'space-between'}>
                <Label>What's NOT Included</Label>
                {props.mode !== 'view' ? <ActionButton text={'Add excluded item'} iconProps={{iconName: 'Add'}} onClick={() => setActiveSurface('add.excluded')} /> : null}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                {data.excluded.map((excluded) => {
                    return <Tag>
                        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign='center'>
                            <Text size={'small'}>{excluded.name}</Text>
                            {!excluded.auto && props.mode !== 'view' ? <ActionButton styles={{root: {height: 'unset !important', minHeight: 'unset !important'}}} 
                                iconProps={{iconName: 'Delete'}}
                                onClick={() => {
                                    data.included = data.excluded.filter((inc) => inc.id !== excluded.id);
                                    props.onChange(data);
                                }} /> : null}
                        </Stack>
                    </Tag>
                })}
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'}>
                <Label>Offered Price</Label>
                {props.mode !== 'view' ? <ActionButton text={'Add offered price'} iconProps={{ iconName: "Add" }} onClick={() => setActiveSurface('form')} /> : null}
            </Stack>
            {data.items.length > 0 ? <ShimmeredDetailsList items={data.items}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsFooter={(props) => {
                    return <DetailsRow
                        selectionMode={SelectionMode.none}
                        columns={props?.columns}
                        item={{
                            id: 'footer'
                        }}
                        itemIndex={data.items.length}
                    />
                }} /> : null}
            {data.items.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>Cannot find any offered price</Text> : null}
        </Stack>
        <Stack className='divider'></Stack>
        <Label>Costing</Label>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Cost (before PPn & PPh)</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(props.fd.subtotalCost)}</Text>
            </Stack>
            {props.fd.includedCosts?.ppn && props.fd.ppnCost ? <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>PPn</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(props.fd.ppnCost.amount || '0')}</Text>
            </Stack> : null}
            {props.fd.includedCosts?.pph && props.fd.pphCost ? <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>PPh</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(props.fd.pphCost.amount || '0')}</Text>
            </Stack> : null}
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Cost (after PPn & PPh)</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(props.fd.totalCost)}</Text>
            </Stack>
            {!props.fd.includedCosts?.ppn ? <Stack styles={{ root: { width: '25%' } }}> </Stack> : null}
            {!props.fd.includedCosts?.pph ? <Stack styles={{ root: { width: '25%' } }}> </Stack> : null}
        </Stack>
        <Stack className='divider'></Stack>
        <Label>Profit</Label>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Total price</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(data.totalPrice)}</Text>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Profit percentage</Label>
                <Label size={'small'} className={getProfitTextClass()}>{GeneralService.getNumberWithSeparator(props.fd.profitPercentage)}%</Label>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Label size={'small'}>Profit amount</Label>
                <Label size={'small'} className={getProfitTextClass()}>Rp. {GeneralService.getNumberWithSeparator(props.fd.profitAmount)}</Label>
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}> </Stack>
        </Stack>
        <Stack className='divider'></Stack>
        <Stack tokens={{ childrenGap: 20 }}>
            <TextField label={"Payment Term"}
                multiline
                rows={5}
                autoAdjustHeight
                resizable={false}
                value={data?.paymentTerm}
                disabled={props.submitting}
                onChange={(evt, value) => {
                    let _data = data || {};
                    _data.paymentTerm = value || "";
                    props.onChange(_data);
                }} />
        </Stack>
        {activeSurface === 'form' ? <>
            <OfferedPriceForm fd={props.fd}
                data={activeItem}
                quantityUnits={props.quantityUnits}
                onSubmit={_onSubmit}
                onDismissed={_onSurfaceDismissed} />
        </> : null}
        {activeSurface === 'add.included' ? <>
            <OfferedPriceIncludedExcludedItemForm data={data}
                onDismissed={_onSurfaceDismissed}
                title={'Add Included Item'}
                onSubmit={(item) => {
                    let _data = data;
                    _data.included.push({ id: GeneralService.guid(), name: item.name, auto: false});
                    setActiveSurface(undefined);
                    props.onChange(_data);
                }} />
        </> : null}
        {activeSurface === 'add.excluded' ? <>
            <OfferedPriceIncludedExcludedItemForm data={data}
                onDismissed={_onSurfaceDismissed}
                title={'Add Excluded Item'}
                onSubmit={(item) => {
                    let _data = data;
                    _data.excluded.push({ id: GeneralService.guid(), name: item.name, auto: false});
                    setActiveSurface(undefined);
                    props.onChange(_data);
                }} />
        </> : null}
    </Stack>
}

export default OfferedPrice;