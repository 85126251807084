import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import OutcomeInvoicesList from '../../../components/finances/outcomes/invoices/general/list';
import OutcomePaymentsList from '../../../components/finances/outcomes/payments/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IOutcomePaymentsPageProps {
}

const OutcomePaymentsPage: React.FC<IOutcomePaymentsPageProps> = (props: IOutcomePaymentsPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Outcome Payments", [
            {key: "home", icon: faHome, href: "/"},
            {key: "finance", text: 'Finance', href: "/finance"},
            {key: "outcomes", text: 'Outcomes', href: "/finance/outcomes"},
            {key: "outcomePayments", text: 'Payments'}
        ])
    }, []);

    return <Stack>
        <OutcomePaymentsList />
    </Stack>;
};

export default OutcomePaymentsPage;
