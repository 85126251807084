import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import ComingSoon from '../../../components/uiframeworks/comingSoon';
import { useParams } from 'react-router-dom';
import PermissionsService from '../../../services/permissions';
import NoAccess from '../../../components/uiframeworks/noAccess';
import AdministratorPerformanceDashboard from '../../../components/users/administrators/performance/dashboard';
import EmploymentDashbord from '../../../components/users/employments/dashboard';

interface EmploymentDashboardPageProps {
}

const EmploymentDashboardPage: React.FC<EmploymentDashboardPageProps> = (props: EmploymentDashboardPageProps) => {
    const {topbar, user} = useStore();
    const params: any = useParams();
    const hasAccess = params.employeeId && (params.employeeId === 'my' || PermissionsService.hasPermission(['superuser', 'finance', 'hr'], user.roles) || params.employeeId === user.data?.id);

    useEffect(() => {
        topbar.show(params.employeeId === 'my' ? "Employment Details" : "Employment Details", [
            {key: "home", icon: faHome, href: "/"},
            {key: "administrators", text: 'Administrators'}
        ]);
        if (hasAccess) {init();}
    }, []);

    const init = () => {
        try {
        } catch(e) {
        }
    }

    return <Stack>
        {!hasAccess ? <NoAccess /> : null}
        {hasAccess ? <EmploymentDashbord employeeId={params.employeeId} /> : null}
    </Stack>;
};

export default EmploymentDashboardPage;
