import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import TrackingTypes from '../../../../../manifests/trackingTypes';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import OrderTrackingService from '../../../../../services/orders/trackings/orderTracking';

// props
import { IOrderTrackingResourceShortProps } from '../../../../../props/orders/trackings/orderTracking';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';

interface IDeleteOrderTrackingFormProps {
    orderId: string;
    tracking: IOrderTrackingResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteOrderTrackingForm: React.FC<IDeleteOrderTrackingFormProps> = (props: IDeleteOrderTrackingFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await OrderTrackingService.delete(props.orderId, props.tracking.id);

            banner.add({
                key: 'delete_order_tracking_success',
                variant: 'success',
                icon: faCheck,
                text: `Tracking "${props.tracking.type}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Tracking"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting tracking ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Preparing form ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Text>Are you sure you want to delete tracking with details below?</Text>
                <Stack.Item>
                    <Label>Type</Label>
                    <Text>{TrackingTypes.find((t) => t.key === props.tracking.type)?.text}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>Notes</Label>
                    <Text>{props.tracking.notes || ""}</Text>
                </Stack.Item>
                {props.tracking.shippingDetails ? <>
                    <Stack.Item>
                        <Label>Ship</Label>
                        <Text>{props.tracking.shippingDetails.ship.name} ({props.tracking.shippingDetails.ship.registrationNumber})</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Voy</Label>
                        <Text>{props.tracking.shippingDetails.schedule.voy}</Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Port of Loading</Label>
                            <Text>{props.tracking.shippingDetails.schedule.originAddress}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Port of Destination</Label>
                            <Text>{props.tracking.shippingDetails.schedule.destinationAddress}</Text>
                        </Stack.Item>
                    </Stack>
                </> : null}
                {props.tracking.trainDetails ? <>
                    <Stack.Item>
                        <Label>Train</Label>
                        <Text>{props.tracking.trainDetails.train.name}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Voy</Label>
                        <Text>{props.tracking.trainDetails.schedule.voy}</Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Loading Station</Label>
                            <Text>{props.tracking.trainDetails.schedule.originAddress}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Unloading Station</Label>
                            <Text>{props.tracking.trainDetails.schedule.destinationAddress}</Text>
                        </Stack.Item>
                    </Stack>
                </> : null}
                {props.tracking.dooringDetails ? <>
                    <Stack.Item>
                        <Label>Truck</Label>
                        <Text>{props.tracking.dooringDetails.truck?.name || "-"}</Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Origin Address</Label>
                            <Text>{props.tracking.dooringDetails.originAddress}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Destination Address</Label>
                            <Text>{props.tracking.dooringDetails.destinationAddress}</Text>
                        </Stack.Item>
                    </Stack>
                </> : null}
            </> : null}
        </Stack>
    </Panel>
};

export default DeleteOrderTrackingForm;
