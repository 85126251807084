import { Checkbox, Icon, IconButton, ISelection, Stack, TooltipHost, Selection, ShimmeredDetailsList, SelectionMode, IColumn, ActionButton, CommandBar, ICommandBarItemProps, SpinnerSize } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

// assets

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import { faFile, faLock } from '@fortawesome/pro-light-svg-icons';
import GeneralService from '../../../../services/general';
import { IDocumentProps } from '../../../../props/general';
import moment from 'moment';
import { useStore } from '../../../../stores/root';
import LoadingDialogComponent from '../../../feedbacks/loadingDialog';
import RenderImage from '../renderImage';
import Tag from '../../tag';
import DocumentService from '../../../../services/documents/document';
import FilePreview from '../preview';

type FilesListColumnProps = {
    files: IDocumentProps[];
    loaded?: boolean;
    size?: 'small' | 'medium' | 'large';
}

const FilesListColumn: React.FC<FilesListColumnProps> = (props: FilesListColumnProps) => {
    const { banner } = useStore();

    const [files, setFiles] = React.useState<IDocumentProps[]>(props.files);
    const [loaded, setLoaded] = React.useState<boolean>(props.loaded || false);
    const [selectedPreview, setSelectedPreview] = useState<IDocumentProps>();
    const [activeSurface, setActiveSurface] = useState<string | undefined>();

    const size = props.size || 'large';
    let gap = 10;
    if (size === 'medium') {
        gap = 8;
    } else if (size === 'small') {
        gap = 5;
    }

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    useEffect(() => {
        setLoaded(props.loaded || false);
    }, [props.loaded]);

    const _onPreview = (file: IDocumentProps) => {
        setSelectedPreview(file);
        setActiveSurface('preview');
    }

    const renderFileCard = (file: IDocumentProps) => {
        const type = GeneralService.getFileType(file.basename);
        return <Stack key={file.basename} className={`${styles.card}`}>
            <TooltipHost content={<Text size={'small'}>{file.basename}</Text>}>
                <Stack onClick={() => _onPreview(file)} className={`${styles.fileImage} ${styles[size]} ${styles["bg" + type]}`}>
                    {type === 'word' ? <Icon className={styles.icon} iconName='WordDocument' /> : null}
                    {type === 'excel' ? <Icon className={styles.icon} iconName='ExcelDocument' /> : null}
                    {type === 'powerpoint' ? <Icon className={styles.icon} iconName='PowerPointDocument' /> : null}
                    {type === 'pdf' ? <Icon className={styles.icon} iconName='PDF' /> : null}
                    {type === 'video' ? <Icon className={styles.icon} iconName='Video' /> : null}
                    {type === 'file' ? <FontAwesomeIcon className={styles.icon} icon={faFile} /> : null}
                    {type === 'image' ? <RenderImage path={file.path} className={styles.image} /> : null}
                </Stack>
            </TooltipHost>
        </Stack>
    }

    return <Stack className={styles.container} horizontal wrap tokens={{ childrenGap: gap }}>
        {loaded && files.length > 0 ? files.map((f) => renderFileCard(f)) : null}
        {loaded && files.length < 1 ? <Text style={{fontStyle: 'italic'}} size={'small'}>No files</Text> : null}
        {!loaded ? <LoadingComponent size={SpinnerSize.small} label={'Retrieving files ...'} labelPosition='right' spinnerPosition={'baseline'} /> : null}
        {activeSurface === 'preview' && selectedPreview ? <FilePreview file={selectedPreview} onDismissed={() => {
            setSelectedPreview(undefined);
            setActiveSurface(undefined);
        }} /> : null}
    </Stack>;
};

export default FilesListColumn;