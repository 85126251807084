import { faHome } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect } from 'react';
import { useStore } from '../../stores/root';
import { Stack } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import OfferCostSummary from '../../components/offers/general/costs/summary';

type ViewOfferCostPageProps = {
  children?: React.ReactNode;
};

const ViewOfferCostPage: React.FC<ViewOfferCostPageProps> = (props) => {
  const { topbar } = useStore();
  const params: any = useParams();

  useEffect(() => {
    topbar.show("Offer", [
      { key: "home", icon: faHome, href: "/" },
      { key: "offers", text: 'Offers', href: "/offers" }
    ])
  }, []);

  return (
    <Stack>
      <OfferCostSummary offerId={params.offerId} />
    </Stack>
  )
};

export default ViewOfferCostPage;