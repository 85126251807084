import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services

// props

// components
import { Dropdown, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';
import { ISystemBankResourceShortProps } from '../../../../props/systems/banks';
import SystemBankService from '../../../../services/systems/bank';

type SelectBankProps = {
    selected?: ISystemBankResourceShortProps;
    options?: ISystemBankResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    errorMessage?: string;
    onChange(selected?: ISystemBankResourceShortProps): void;
}

const SelectBank: React.FC<SelectBankProps> = (props: SelectBankProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<ISystemBankResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<ISystemBankResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _options = await SystemBankService.retrieve();
        setOptions(_options);
        setLoaded(true);
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'center'}>
            {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
            <Stack grow={1}>
                <Dropdown disabled={props.disabled || !loaded}
                    selectedKey={selected?.id}
                    options={options.map((ba) => {
                        return { key: ba.id, text: `${ba.name}` }
                    })}
                    onChange={(evt, opt) => {
                        const selected = options.find((o) => o.id === opt?.key as string);
                        props.onChange(selected);
                    }} />
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectBank;
