import React, { useEffect } from 'react';

import { Checkbox, Stack } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import Label from '../../../../../typography/label';

type OfferIncludedCostsProps = {
    data?: IncludedCostsFormPropsData;
    submitting?: boolean;
    mode?: string;
    onChange(data?: IncludedCostsFormPropsData, error?: IncludedCostsFormPropsDataError): void;
}

export type IncludedCostsFormPropsData = {
    additionalCosts?: boolean;
    loadingLabour?: boolean;
    unloadingLabour?: boolean;
    originTrucking?: boolean;
    destinationTrucking?: boolean;
    containerShipping?: boolean;
    breakbulkShip?: boolean;
    charteredShip?: boolean;
    goodsPrice?: boolean;
    insuranceCost?: boolean;
    ppn?: boolean;
    pph?: boolean;
}

export type IncludedCostsFormPropsDataError = {
}

const OfferIncludedCosts: React.FC<OfferIncludedCostsProps> = (props: OfferIncludedCostsProps) => {
    const { banner } = useStore();
    const [error, setError] = React.useState<IncludedCostsFormPropsDataError>({});
    const [data, setData] = React.useState<IncludedCostsFormPropsData | undefined>();

    useEffect(() => {
        setData({ ...props.data });
    }, [props.data]);

    return <Stack tokens={{ childrenGap: 15 }}>
        <Label>Included Costs</Label>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Trucking at Origin'}
                    checked={data?.originTrucking}
                    disabled={props.mode === 'view'}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.originTrucking = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Loading Labour'}
                    checked={data?.loadingLabour}
                    disabled={props.mode === 'view'}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.loadingLabour = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Trucking at Destination'}
                    checked={data?.destinationTrucking}
                    disabled={props.mode === 'view'}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.destinationTrucking = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Unloading Labour'}
                    checked={data?.unloadingLabour}
                    disabled={props.mode === 'view'}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.unloadingLabour = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Container Shipping'}
                    checked={data?.containerShipping}
                    disabled={props.mode === 'view'}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.containerShipping = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Breakbulk/Cargo Ship'}
                    disabled={true}
                    checked={data?.breakbulkShip}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.breakbulkShip = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Chartered Ship'}
                    disabled={props.mode === 'view'}
                    checked={data?.charteredShip}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.charteredShip = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Goods Price'}
                    disabled={true}
                    checked={data?.goodsPrice}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.goodsPrice = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Additional Costs'}
                    disabled={props.mode === 'view'}
                    checked={data?.additionalCosts}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.additionalCosts = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'Insurance'}
                    disabled={props.mode === 'view'}
                    checked={data?.insuranceCost}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.insuranceCost = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'PPn'}
                    disabled={props.mode === 'view'}
                    checked={data?.ppn}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.ppn = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}>
                <Checkbox label={'PPh'}
                    disabled={props.mode === 'view'}
                    checked={data?.pph}
                    onChange={(ev, checked) => {
                        let _data = data || {};
                        _data.pph = checked;
                        props.onChange(_data);
                    }} />
            </Stack>
        </Stack>
    </Stack>;
}

export default OfferIncludedCosts;

