import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import KeysList from '../../../components/settings/keys/general/list';

interface IKeysListPageProps {
}

const KeysListPage: React.FC<IKeysListPageProps> = (props: IKeysListPageProps) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Public Key", [
      { key: "home", icon: faHome, href: "/" },
      { key: "settings", text: 'Settings', href: "/settings" },
      { key: "keys", text: 'Keys' }
    ])
  }, []);

  return (
    <Stack>
      <KeysList />
    </Stack>
  );
};

export default KeysListPage;
