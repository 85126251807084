import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import { NavLink } from 'react-router-dom';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import ShipSchedulesService from '../../../../../../services/data/ships/shipSchedules';

// props
import { IShipScheduleResourceShort } from '../../../../../../props/data/ships';
import { TTagVariant } from '../../../../../uiframeworks/tag';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import moment from 'moment';
import ShipScheduleForm from '../form';
import ShipScheduleStatusForm from '../status';
import { ITrainScheduleResourceShort } from '../../../../../../props/data/trains';
import TrainScheduleService from '../../../../../../services/data/trains/trainScedule';
import TrainScheduleForm from '../form';
import TrainScheduleStatusForm from '../status';
import PermissionsService from '../../../../../../services/permissions';

interface ITrainSchedulesListProps {
    trainId: string;
}

const TrainSchedulesList: React.FC<ITrainSchedulesListProps> = (props: ITrainSchedulesListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [schedules, setSchedules] = React.useState<ITrainScheduleResourceShort[]>([]);
    const [activeSchedule, setActiveSchedule] = React.useState<ITrainScheduleResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['trains.schedules.read.all'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "voy",
            name: "Voy",
            fieldName: "voy",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ITrainScheduleResourceShort) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {/*<NavLink to={`/operationals/trains/${item.id}/schedules/${item.id}`}><Text>{item.voy}</Text></NavLink>*/}
                    <NavLink to={`#`}><Text>{item.voy}</Text></NavLink>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ITrainScheduleResourceShort) => {
                let menuItems: IContextualMenuItem[] = []

                if (PermissionsService.hasPermission(['trains.schedules.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveSchedule(item);
                            setActiveSurface('update');
                        }
                    });
                }

                if (PermissionsService.hasPermission(['trains.schedules.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update status", iconProps: { iconName: "CompositeCheckbox" }, onClick: () => {
                            setActiveSchedule(item);
                            setActiveSurface('updateStatus');
                        }
                    });
                }

                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: 'status',
            fieldName: 'status',
            name: 'Status',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ITrainScheduleResourceShort) => {
                let statusVariant: TTagVariant = 'default';
                let statusText: string = 'Scheduled';

                if (item.status === 'in progress') {
                    statusText = 'On the Move';
                    statusVariant = 'warning';
                } else if (item.status === 'arrived') {
                    statusText = 'Completed';
                    statusVariant = 'success';
                } else if (item.status === 'cancelled') {
                    statusText = 'Cancelled';
                    statusVariant = 'error';
                }

                return (
                    <Stack horizontal>
                        <Tag text={statusText} variant={statusVariant} />
                    </Stack>
                );
            }
        },
        {
            key: "loadingStation",
            name: "Loading Station",
            minWidth: 300,
            isMultiline: true,
            onRender: (item: ITrainScheduleResourceShort) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.originAddress}</Text>
                </Stack>
            }
        },
        {
            key: "unloadingStation",
            name: "Unloading Station",
            minWidth: 300,
            isMultiline: true,
            onRender: (item: ITrainScheduleResourceShort) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.destinationAddress}</Text>
                </Stack>
            }
        },
        {
            key: "departureDate",
            name: "Departure Date",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ITrainScheduleResourceShort) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { padding: '4px 0px' } }}>
                    {item.actualDeparture ? <>
                        <Text>{moment(item.actualDeparture).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontStyle: 'italic' }}>(Actual)</Text>
                    </> : null}
                    {!item.actualDeparture ? <>
                        <Text>{moment(item.estimatedDeparture).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontStyle: 'italic' }}>(Estimated)</Text>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "arrivalDate",
            name: "Arrival Date",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ITrainScheduleResourceShort) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { padding: '4px 0px' } }}>
                    {item.actualArrival ? <>
                        <Text>{moment(item.actualArrival).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontStyle: 'italic' }}>(Actual)</Text>
                    </> : null}
                    {!item.actualArrival ? <>
                        <Text>{moment(item.estimatedArrival).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontStyle: 'italic' }}>(Estimated)</Text>
                    </> : null}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        _onRetrieveSchedules();
    }, [keyword]);

    const _onRetrieveSchedules = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = [];
            qs.push(`trainId=${props.trainId}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await TrainScheduleService.retrieve(qs.join("&"));
            setSchedules(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_train_schedules_error',
                text: `Failed to retrieve train's schedules list. Error: ` + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['trains.schedules.read.all'], user.permissions)) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (PermissionsService.hasPermission(['trains.schedules.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Schedule",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Schedule"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveSchedule(undefined);

        if (refresh) { _onRetrieveSchedules() }
    }

    return <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 10 } }}>
        {hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack>
                {
                    (!loaded || (loaded && schedules.length > 0)) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={schedules}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && schedules.length < 1 ? <Text>Train schedule(s) not found</Text> : null}
            </Stack>
            {activeSurface === 'create' ? <TrainScheduleForm trainId={props.trainId} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'update' && activeSchedule ? <TrainScheduleForm trainId={props.trainId} scheduleId={activeSchedule.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'updateStatus' && activeSchedule ? <TrainScheduleStatusForm scheduleId={activeSchedule.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default TrainSchedulesList;