import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../services/validation';
import DataPermissionService from '../../../../../../services/data/permission';
import PermissionsService from '../../../../../../services/permissions';
import RoleService from '../../../../../../services/roles';

// props
import { IDataPermissionResourceShort } from '../../../../../../props/data/permission';
import { IRoleResourceShortProps } from '../../../../../../props/roles/role';

// components
import { ActionButton, Checkbox, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import NoAccess from '../../../../../uiframeworks/noAccess';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SelectRolePermissionsFormProps = {
    permissions: IDataPermissionResourceShort[];
    selected: string[];
    onDismissed(selected?: string[]): void;
}

const SelectRolePermissionsForm: React.FC<SelectRolePermissionsFormProps> = (props: SelectRolePermissionsFormProps) => {
    const { banner, user } = useStore();

    const {permissions} = props;
    const [selected, setSelected] = React.useState<string[]>(props.selected || []);
    const [expandedGroups, setExpandedGroups] = React.useState<string[]>([]);

    const renderPermissionsCheckbox = () => {
        const groupings: {group: string, permissions: IDataPermissionResourceShort[]}[] = [];

        permissions.forEach((permission) => {
            const groupIdx = groupings.findIndex((g) => g.group === permission.group);
            if (groupIdx > -1) {
                groupings[groupIdx].permissions.push(permission);
            } else {
                groupings.push({
                    group: permission.group,
                    permissions: [permission]
                })
            }
        });

        groupings.forEach((g) => {
            g.permissions = g.permissions.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0))
        });

        return <Stack tokens={{childrenGap: 8}}>
            {groupings.map((g) => {
                const expanded = expandedGroups.indexOf(g.group) > -1;

                return <>
                    <Stack tokens={{childrenGap: 20}}>
                        <Stack tokens={{childrenGap: 10}}>
                            <ActionButton onClick={(() => {
                                    let _expandedGroups = expandedGroups;
                                    const idx = _expandedGroups.indexOf(g.group);
                                    if (idx > -1) {
                                        _expandedGroups = _expandedGroups.filter((eg) => eg !== g.group);
                                    } else {
                                        _expandedGroups.push(g.group);
                                    }

                                    setExpandedGroups([..._expandedGroups]);
                                })} styles={{root: {paddingLeft: '0px !important', paddingRight: '0px !important'}}}>
                                <Stack grow={1} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{childrenGap: 20}}>
                                    <Label size={'small'}>{g.group.toUpperCase()}</Label>
                                    <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
                                </Stack>
                            </ActionButton>
                            {expanded ? <>
                                <Stack tokens={{childrenGap: 15}}>
                                    {g.permissions.map((permission) => {
                                        const checked = selected.indexOf(permission.id) > -1;
                                        return <Checkbox label={permission.name} 
                                            key={permission.id} 
                                            checked={checked}
                                            onChange={() => {
                                                const idx = selected.indexOf(permission.id);
                                                let _selected = selected;

                                                if (idx > -1) {
                                                    _selected = _selected.filter((p) => p !== permission.id);
                                                } else {
                                                    _selected.push(permission.id);
                                                }

                                                setSelected([..._selected]);
                                            }} />
                                        }
                                    )}
                                </Stack>
                                <Stack></Stack>
                            </> : null}
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                </>
            })}
        </Stack>
    }

    return <Panel headerText={"Select Permissions"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={'Apply'} onClick={() => props.onDismissed(selected)} />
                <DefaultButton text={'Cancel'} onClick={() => props.onDismissed()} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {renderPermissionsCheckbox()}
        </Stack>
    </Panel>
};

export default SelectRolePermissionsForm;
