import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import EmployeePayslipsList from '../../../../components/users/administrators/payslips/general/list';

interface EmployeePayslipsListPageProps {
}

const EmployeePayslipsListPage: React.FC<EmployeePayslipsListPageProps> = (props: EmployeePayslipsListPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Administrators", [
            {key: "home", icon: faHome, href: "/"},
            {key: "users", text: 'Users'},
            {key: "administrators", text: 'Administrators'}
        ])
    }, []);

    return <Stack>
        <EmployeePayslipsList employeeId={params.administratorId} />
    </Stack>;
};

export default EmployeePayslipsListPage;
