import moment from 'moment';
import Request from './../request';
import { IInternalDocumentResourceShort } from '../../props/internalDocuments';

const InternalDocumentService = {
  retrieve: async (qs?: string): Promise<IInternalDocumentResourceShort[]> => {
    try {
      const result = await Request.get(`/api/all_internal_documents?${qs}`);
      return result;
    } catch (e) {
      throw (e);
    }
  },
  new: async (data: FormData): Promise<IInternalDocumentResourceShort[]> => {
    try {
      const result = await Request.post('/api/internal_documents', data);
      return result;
    } catch (e) {
      throw (e);
    }
  },
  newFolder: async (data: FormData): Promise<IInternalDocumentResourceShort[]> => {
    try {
      const result = await Request.post('/api/create_folder_internal_documents', data);
      return result;
    } catch (e) {
      throw (e);
    }
  },
  getFolder: async (qs?: string): Promise<{
    documents: IInternalDocumentResourceShort[],
    folders: IInternalDocumentResourceShort[]
  }> => {
    try {
      const result = await Request.get(`/api/get_folder?${qs}`);
      return result;
    } catch (e) {
      throw (e);
    }
  },
  deletes: async (data: FormData) => {
    try {
      const result = await Request.post('/api/destroy_documents', data);
      return result;
    } catch (e) {
      throw (e);
    }
  }
}

export { InternalDocumentService }