import React, { useEffect } from 'react';
import styles from './../styles.module.scss';

import { ActionButton, NormalPeoplePicker, Stack, TagPicker, TextField } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import { IUserResourceShortProps } from '../../../../../../props/users/user';
import { ICompanyResourceShort } from '../../../../../../props/users/company';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import CompanyService from '../../../../../../services/users/company';
import GeneralService from '../../../../../../services/general';

type OfferCustomerDetailsProps = {
    data?: CustomerDetailsFormData;
    submitting?: boolean;
    mode?: string;
    onChange(data?: CustomerDetailsFormData, error?: CustomerDetailsFormDataError): void;
}

export type CustomerDetailsFormData = {
    company?: ICompanyResourceShort;
    customer?: IUserResourceShortProps;
    name?: string;
    phoneNumber?: string;
    isCompleted?: boolean;
}

export type CustomerDetailsFormDataError = {
    customer?: string;
    name?: string;
}

const OfferCustomerDetails: React.FC<OfferCustomerDetailsProps> = (props: OfferCustomerDetailsProps) => {
    const { banner } = useStore();
    const [error, setError] = React.useState<CustomerDetailsFormDataError>({});
    const [data, setData] = React.useState<CustomerDetailsFormData | undefined>();

    useEffect(() => {
        setData({ ...props.data });
    }, [props.data]);

    const _onSearchCompanies = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await CompanyService.retrieve(qs.join("&"))).data.map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_companies_error",
                text: "Failed to search related companies. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchCustomers = async (keyword: string) => {
        try {
            if (data?.company) {
                keyword = keyword.toLowerCase();
                const users = await CompanyService.getUsers(data.company.id);
                return users.filter((user, idx) => {
                    return user.email.toLowerCase().indexOf(keyword) > -1 ||
                        user.name.toLowerCase().indexOf(keyword) > -1 ||
                        (user.phoneNumber || "").toLowerCase().indexOf(keyword) > -1;
                }).map((user) => {
                    return {
                        id: user.id,
                        text: user.name,
                        secondaryText: user.email,
                        imageInitials: GeneralService.getInitial(user.name),
                        metadata: user,
                    };
                });
            } else {
                return [];
            }
        } catch (error) {
            banner.add({
                key: "search_customers_error",
                text: "Failed to search related customers. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const checkIsCompleted = (_data: CustomerDetailsFormData) => {
        if (_data?.company && _data?.customer && (_data?.name || "").trim() !== "") {
            return true;
        } else {
            return false;
        }
    }

    return <Stack tokens={{ childrenGap: 15 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={styles.picker} styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'} required={true}>Select Company</Label>
                {!data?.company && props.mode !== 'view' ? <TagPicker
                    selectedItems={[]}
                    removeButtonAriaLabel='Remove'
                    itemLimit={1}
                    onItemSelected={(item: any) => {
                        let _data = data || {};
                        _data.company = item?.metadata;
                        _data.customer = undefined;
                        _data.isCompleted = checkIsCompleted(_data);
                        props.onChange(_data);
                        return null;
                    }}
                    onResolveSuggestions={_onSearchCompanies}
                    onEmptyResolveSuggestions={() => _onSearchCompanies('')}
                    disabled={props.submitting}
                /> : null}
                {data?.company ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                    <Stack>
                        <Label size={'small'}>{data.company.name}</Label>
                        {data.company.phoneNumber ? <Text size={'small'}>{data.company.phoneNumber}</Text> : null}
                    </Stack>
                    {props.mode !== 'view' ? <ActionButton disabled={props.submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                        let _data = data || {};
                        _data.company = undefined;
                        _data.customer = undefined;
                        _data.isCompleted = checkIsCompleted(_data);
                        props.onChange(_data);
                    }} /> : null}
                </Stack> : null}
            </Stack.Item>
            <Stack.Item className={styles.picker} styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'} required={true}>Customer (PIC)</Label>
                {!data?.customer && props.mode !== 'view' ? <NormalPeoplePicker
                    onResolveSuggestions={_onSearchCustomers}
                    onEmptyResolveSuggestions={() => _onSearchCustomers('')}
                    itemLimit={1}
                    selectedItems={[]}
                    disabled={props.submitting || data?.company === undefined}
                    onChange={(item?: any) => {
                        let _data = data || {};
                        if (item[0]?.metadata) {
                            _data.customer = item[0] ? item[0].metadata : undefined;
                            _data.name = item[0].metadata.name;
                            _data.phoneNumber = item[0].metadata.phoneNumber || "";
                            _data.isCompleted = checkIsCompleted(_data);
                            props.onChange(_data);
                        }
                        return null;
                    }}
                /> : null}
                {data?.customer ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                    <Stack>
                        <Label size={'small'}>{data.customer.name}</Label>
                        <Text size={'small'}>{data.customer.email}</Text>
                    </Stack>
                    {props.mode !== 'view' ? <ActionButton disabled={props.submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                        let _data = data || {};
                        _data.customer = undefined;
                        _data.name = "";
                        _data.isCompleted = checkIsCompleted(_data);
                        props.onChange(_data);

                    }} /> : null}
                </Stack> : null}
            </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{childrenGap: 20}}>
            <Stack.Item styles={{ root: { width: '50%' } }}>
                <TextField label={"Name (on offer letter)"}
                    value={data?.name}
                    disabled={data?.customer === undefined || data?.company === undefined || props.submitting || props.mode === 'view'}
                    onChange={(evt, value) => {
                        let _data = data || {};
                        _data.name = value || "";
                        _data.isCompleted = checkIsCompleted(_data);
                        props.onChange(_data);
                    }} />
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '50%' } }}>
                <TextField label={"Phone Number"}
                    value={data?.phoneNumber}
                    disabled={data?.customer === undefined || data?.company === undefined || props.submitting || props.mode === 'view'}
                    onChange={(evt, value) => {
                        let _data = data || {};
                        _data.phoneNumber = value || "";
                        _data.isCompleted = checkIsCompleted(_data);
                        props.onChange(_data);
                    }} />
            </Stack.Item>
        </Stack>
    </Stack>;
}

export default OfferCustomerDetails;

