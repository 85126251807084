import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { IOrderContainerResourceShortProps } from '../../../../../props/orders/containers';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';

interface IDeleteUserFormProps {
    container: IOrderContainerResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteOrderContainerForm: React.FC<IDeleteUserFormProps> = (props: IDeleteUserFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await OrderContainerService.delete(props.container.orderId, props.container.id);

            banner.add({
                key: 'delete_order_container_success',
                variant: 'success',
                icon: faCheck,
                text: `Container ${props.container.containerNumber ? props.container.containerNumber + " " : ""}deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Container"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting container ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete container with details below?</Text>
            <Stack.Item>
                <Label>Container Number</Label>
                <Text>{props.container.containerNumber || "-"}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Price</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(Number(props.container.totalPrice))}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteOrderContainerForm;
