import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import GeneralService from '../../../../../services/general';
import OrderTruckService from '../../../../../services/orders/trucks';

// props
import { IOrderContainerResourceShortProps } from '../../../../../props/orders/containers';
import { IOrderTruckResourceShortProps } from '../../../../../props/orders/trucks';

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import Tag from '../../../../uiframeworks/tag';
import Label from '../../../../typography/label';
import OrderTruckForm from '../form';
import OrderTruckDetails from '../details';
import PermissionsService from '../../../../../services/permissions';

interface IOrderTrucksListProps {
    orderId: string;
    qs?: string[];
    trucks?: IOrderTruckResourceShortProps[];
    columns?: TOrderContainerColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderContainerColumn = 'name' | 'goodsList' | 'address' | 'price';

const OrderTrucksList: React.FC<IOrderTrucksListProps> = (props: IOrderTrucksListProps) => {
    const shownColumns: TOrderContainerColumn[] = props.columns || [
        'name',
        'address',
        'goodsList',
        'price'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [trucks, setTrucks] = React.useState<IOrderTruckResourceShortProps[]>(props.trucks || []);
    const [activeTruck, setActiveTruck] = React.useState<IOrderTruckResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOrderTruckResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveSurface('details');
                        setActiveTruck(item);
                    }}><Text>{item.name || "-"}</Text></Link>
                </Stack>
            }
        },
        {
            key: "goodsList",
            name: "Goods List",
            minWidth: 250,
            onRender: (item: IOrderTruckResourceShortProps) => {
                if (item.goods.length <= 0) {
                    return <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text style={{ fontStyle: 'italic' }}>Good(s) are not listed yet</Text>
                    </Stack>
                } else {
                    return <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
                        {item.goods.map((g) => {
                            return <Tag>
                                <Stack>
                                    <Label size={'xsmall'}>{g.goods.name}</Label>
                                    <Text size={'small'}>{GeneralService.getNumberWithSeparator(g.quantityAmount)} {g.goods.quantityUnit}</Text>
                                </Stack>
                            </Tag>
                        })}
                    </Stack>
                }
            }
        },
        {
            key: "price",
            name: "Total Price",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderTruckResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{childrenGap: 2}}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Text>
                    {item.ppn > 0 ? <Text size={'small'} style={{fontStyle: 'italic'}}>(inc. PPN) Rp. {GeneralService.getNumberWithSeparator(Number(item.ppn))}</Text> : null}
                    {item.pph > 0 ? <Text size={'small'} style={{fontStyle: 'italic'}}>(inc. PPh) Rp. {GeneralService.getNumberWithSeparator(Number(item.pph))}</Text> : null}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.trucks) {
            _onRetrieveTrucks();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveTrucks = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await OrderTruckService.retrieve(props.orderId, qs.join("&"));
            setTrucks(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_trucks_list_error',
                text: 'Failed to retrieve order trucks list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Truck",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Truck"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveTruck(undefined);

        if (refresh) { _onRetrieveTrucks() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && trucks.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={trucks.filter((truck) => {
                                const search = (keyword || "").trim().toLowerCase();
                                return (truck.truck?.registrationNumber || "").toLowerCase().indexOf(search) > -1 || (truck.truck?.name || "").toLowerCase().indexOf(search) > -1 || (truck.truck?.vendor.name || "").toLowerCase().indexOf(search) > -1
                            })}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderContainerColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && trucks.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text>Truck(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderTruckForm orderId={props.orderId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'details' && activeTruck ? <OrderTruckDetails orderId={props.orderId} truckId={activeTruck.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrderTrucksList;
