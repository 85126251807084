import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import SalesTeamList from '../../../../components/users/teams/general/list';

type SalesTeamListPageProps = {
}

const SalesTeamListPage: React.FC<SalesTeamListPageProps> = (props: SalesTeamListPageProps) => {
    const { topbar } = useStore();

    useEffect(() => {
        topbar.show("Sales Teams", [
            { key: "home", icon: faHome, href: "/" },
            { key: "teams", text: 'Teams', href: "/users/teams" },
            { key: "sales_teams", text: 'Sales' }
        ])
    }, []);

    return (
        <Stack>
            <SalesTeamList />
        </Stack>
    );
};

export default SalesTeamListPage;
