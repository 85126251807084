import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import VendorDetails from '../../../components/operationals/vendors/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IVendorDetailsPageProps {
}

const VendorDetailsPage: React.FC<IVendorDetailsPageProps> = (props: IVendorDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Vendors", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "vendors", text: 'Vendors'}
        ]);
    }, []);

    return <Stack>
        <VendorDetails vendorId={params.vendorId} />
    </Stack>;
};

export default VendorDetailsPage;
