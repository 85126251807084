import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import { TSelectedFilterKey } from '.';
import { IUserResourceShortProps } from '../../../../../props/users/user';

type OrdersDashboardChartFilterSalesProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: IUserResourceShortProps[]): void;
}

const OrdersDashboardChartFilterSales: React.FC<OrdersDashboardChartFilterSalesProps> = (props: OrdersDashboardChartFilterSalesProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IUserResourceShortProps[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniqueSales = (): IUserResourceShortProps[] => {
        const users: IUserResourceShortProps[] = [];

        props.orders.forEach((order) => {
            (order.sales || []).forEach((sales) => {
                const exist = users.find((u) => u.id === sales.sales.id);
                if (!exist) {
                    users.push(sales.sales);
                }
            })
        });

        return users;
    }

    const renderOption = (sales: IUserResourceShortProps) => {
        const isChecked = selected.findIndex((s) => s.id === sales.id) > -1;
        const totalItems = props.filteredOrders.filter((o) => (o.sales || []).map((s) => s.sales.id).indexOf(sales.id) > -1).length;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, sales)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{sales.name} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, sales: IUserResourceShortProps) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== sales.id);
        } else {
            _selected.push(sales);
        }

        setSelected([..._selected]);
        props.onChange('sales', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Sales</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search sales ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueSales().filter((f) => f.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((user) => {
                return renderOption(user);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterSales;
