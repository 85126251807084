import { faHome } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect } from 'react';
import { useStore } from '../../stores/root';
import { Stack } from '@fluentui/react';
import OfferForm from '../../components/offers/general/form';
import { useParams } from 'react-router-dom';

type ViewOfferPageProps = {
  children?: React.ReactNode;
};

const ViewOfferPage: React.FC<ViewOfferPageProps> = (props) => {
  const { topbar } = useStore();
  const params: any = useParams();

  useEffect(() => {
    topbar.show("Offer", [
      { key: "home", icon: faHome, href: "/" },
      { key: "offers", text: 'Offers', href: "/offers" }
    ])
  }, []);

  return (
    <Stack>
      <OfferForm offerId={params.offerId} mode={'view'} />
    </Stack>
  )
};

export default ViewOfferPage;