import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack } from '@fluentui/react';
import ErrorService from '../../../../../services/general/error';
import LoadingComponent from '../../../../feedbacks/loading';
import { IOrderContainerResourceCompleteProps } from '../../../../../props/orders/containers/index';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import OrderContainerDetailsProperties from './properties';
import OrderContainerForm from '../form';
import OrderContainerGoodsForm from '../../goods/general/form';
import PermissionsService from '../../../../../services/permissions';
import DeleteOrderContainerForm from '../form/delete';

interface IOrderContainerDetailsProps {
    orderId: string;
    containerId: string;
    onDismissed(refresh?: boolean): void;
}

const OrderContainerDetails: React.FC<IOrderContainerDetailsProps> = (props: IOrderContainerDetailsProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [container, setContainer] = React.useState<IOrderContainerResourceCompleteProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);
            const _container = await OrderContainerService.get(props.orderId, props.containerId);
            setContainer(_container);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_order_container_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                text: 'Update',
                onRender: () => {
                    return <PrimaryButton text={'Update'}
                        menuProps={{
                            items: [
                                {
                                    key: 'update_details', text: "Update Details", onClick: () => {
                                        setActiveSurface('updateDetails');
                                    }
                                },
                                {
                                    key: 'update_goods', text: "Update Goods", onClick: () => {
                                        setActiveSurface('updateGoods');
                                    }
                                },
                            ]
                        }}
                        styles={{ root: { marginRight: 20 } }} />
                }
            });
        }

        items.push({
            key: 'download',
            text: 'Download Packing List',
            onRender: () => {
                return <ActionButton iconProps={{ iconName: "Download" }} text={"Packing List"} onClick={async () => {
                    try {
                        await OrderContainerService.downloadPackingList(container?.id || '', 'docx')
                    } catch (error) {

                    }
                }} />
            }
        });

        if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: 'delete',
                text: 'Delete',
                onRender: () => {
                    return <ActionButton iconProps={{ iconName: "Delete" }} text={"Delete"} onClick={() => setActiveSurface('delete')} />
                }
            })
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { init(); }
    }

    return <Panel headerText={container && container.containerNumber ? container.containerNumber : "Container Details"}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(true)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(true) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && container ? <>
                <CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />
                <Pivot className={'linePivot'}>
                    <PivotItem key={'details'} headerText={"Details"}>
                        <Stack styles={{ root: { padding: 10 } }}><OrderContainerDetailsProperties container={container} /></Stack>
                    </PivotItem>
                    <PivotItem key={'trackings'} headerText={"Trackings"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                    <PivotItem key={'documents'} headerText={"Documents"}>
                        <Stack><ComingSoon /></Stack>
                    </PivotItem>
                </Pivot>
            </> : null}
        </Stack>
        {activeSurface === 'updateDetails' ? <OrderContainerForm orderId={props.orderId} containerId={props.containerId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'updateGoods' && container ? <OrderContainerGoodsForm orderId={props.orderId} goods={container.goods} container={container} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && container ? <DeleteOrderContainerForm container={container} onDismissed={props.onDismissed} /> : null}
    </Panel>
};

export default OrderContainerDetails;
