import React, { useEffect } from 'react';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { ActionButton, DefaultButton, Dropdown, IDropdownOption, NormalPeoplePicker, Panel, PanelType, PrimaryButton, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import RoleService from '../../../../../services/role';
import ValidationService from '../../../../../services/validation';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import TeamService from '../../../../../services/users/team';
import { IUserResourceShortProps } from '../../../../../props/user';
import GeneralService from '../../../../../services/general';
import authService from '../../../../../services/users/user';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';


interface ITeamFormProps {
    teamId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataSalesProps = {
    id: string;
    sales?: IUserResourceShortProps;
    fee_percentage: string;
    fee_amount: string;
}

type FormDataProps = {
    name: string;
    description: string;
    salesItems: FormDataSalesProps[];
}

type FormDataErrorProps = {
    name?: string;
    description?: string;
}

const TeamForm: React.FC<ITeamFormProps> = (props: ITeamFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        description: "",
        salesItems: [{ id: GeneralService.guid(), fee_amount: "", fee_percentage: "" }]
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [updatingRelatedSales, setUpdatingRelatedSales] = React.useState<boolean>(false);
    const mode: 'create' | 'update' = props.teamId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.teamId) {
            const _team = await TeamService.get(props.teamId);
            setData({
                name: _team.name,
                description: _team.description || '',
                salesItems: _team.sales ? [..._team.sales.map(f => ({ ...f, fee_amount: f.feeAmount, fee_percentage: f.feePercentage })),
                { id: GeneralService.guid(), fee_amount: "", fee_percentage: "" }] : [{ id: GeneralService.guid(), fee_amount: "", fee_percentage: "" }]
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name) {
            return true;
        } else if (data.name === '') {
            return true;
        }
        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, description, salesItems } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("description", description);
            const sales = salesItems.filter(f => f.sales);
            fd.append('sales', JSON.stringify(sales));

            if (props.teamId === undefined) {
                await TeamService.new(fd);
            } else {
                await TeamService.update(props.teamId, fd);
            }
            banner.add({
                key: 'create_administration_success',
                variant: 'success',
                icon: faCheck,
                text: `Team "${name}" created successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchSales = async (keyword: string) => {
        try {
            const qs: string[] = [];
            // qs.push(`rolesGroup=backoffice`);
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await authService.retrieve(qs.join("&"));

            return results.data.filter((d, idx) => idx < 10).map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_sales_error',
                text: 'Failed to search related sales. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }

    const _onSalesChanged = (id: string, item?: IUserResourceShortProps) => {
        const _items = data.salesItems;
        const _related = _items.find((g) => g.id === id);
        if (_related) {
            _related.sales = item;
            _related.fee_amount = "";
            _related.fee_percentage = "";
        }

        if (item) {
            _items.push({
                id: GeneralService.guid(),
                fee_amount: "",
                fee_percentage: "",
            });
        }


        data.salesItems = _items;
        setData({ ...data });
    }

    return <Panel headerText={mode === 'create' ? "Create Team" : "Update Team"}
        isOpen={true}
        type={PanelType.large}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating administrator ..." : "Updating administrator ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField
                            label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Description"}
                            value={data.description}
                            onChange={(evt, value) => {
                                data.description = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.description = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.description}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className='divider'></Stack>
                    <Stack.Item>
                        <Label>Member(s)</Label>
                        <ShimmeredDetailsList
                            items={data.salesItems}
                            shimmerLines={3}
                            enableShimmer={updatingRelatedSales}
                            columns={[
                                {
                                    key: "sales",
                                    name: "Member",
                                    minWidth: 200,
                                    maxWidth: 200,
                                    isMultiline: true,
                                    onRender: (item: FormDataSalesProps) => {
                                        return <>
                                            <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                <NormalPeoplePicker
                                                    onResolveSuggestions={_onSearchSales}
                                                    onEmptyResolveSuggestions={() => _onSearchSales('')}
                                                    itemLimit={1}
                                                    selectedItems={item.sales ? [
                                                        {
                                                            id: item.sales.id,
                                                            text: item.sales.name,
                                                            secondaryText: item.sales.email,
                                                            imageInitials: GeneralService.getInitial(item.sales.name),
                                                            metadata: item.sales,
                                                        } as any,
                                                    ] : []}
                                                    disabled={submitting}
                                                    onChange={(items: any) => {
                                                        if (items) {
                                                            _onSalesChanged(item.id || "", items[0].metadata);
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        </>
                                    }
                                },
                                {
                                    key: "fee_percentage",
                                    name: "Fee Percentage",
                                    minWidth: 200,
                                    maxWidth: 200,
                                    onRender: (item: FormDataSalesProps) => {
                                        return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                            <TextField
                                                value={item.fee_percentage}
                                                disabled={submitting || !item.sales}
                                                onChange={(evt, value) => {
                                                    const _related = data.salesItems.find((d) => d.id === item.id);
                                                    if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                                        _related.fee_percentage = value || "";
                                                        setData({ ...data });
                                                    }
                                                }} />
                                        </Stack>
                                    }
                                },
                                {
                                    key: "amount",
                                    name: "Fee Amount",
                                    minWidth: 200,
                                    maxWidth: 200,
                                    onRender: (item: FormDataSalesProps) => {
                                        return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                            <TextField value={item.fee_amount}
                                                prefix={"Rp"}
                                                disabled={submitting || !item.sales}
                                                onChange={(evt, value) => {
                                                    const _related = data.salesItems.find((d) => d.id === item.id);
                                                    if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                                        _related.fee_amount = value || "";
                                                        setData({ ...data });
                                                    }
                                                }} />
                                        </Stack>
                                    }
                                },
                                {
                                    key: "actions",
                                    name: "",
                                    minWidth: 50,
                                    maxWidth: 50,
                                    onRender: (item: FormDataSalesProps) => {
                                        return <Stack.Item styles={{ root: { padding: '16px 0px' } }}>
                                            <Stack.Item className={"detailsListActionRow"}>
                                                {item.sales ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                                                    const _saless = data.salesItems.filter((d) => d.id !== item.id);
                                                    data.salesItems = _saless;
                                                    setData({ ...data });
                                                }} /> : null}
                                            </Stack.Item>
                                        </Stack.Item>;
                                    }
                                }
                            ]}
                            selectionMode={SelectionMode.none} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default TeamForm;
