import React from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services
import GeneralService from '../../../../../../services/general';

// props
import { IOutcomeSalaryResourceShortProps } from '../../../../../../props/finance/outcomes/salaries';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import OutcomeSalaryService from '../../../../../../services/finance/outcomes/salaries';

interface DeleteSalarySlipFormProps {
    payslip: IOutcomeSalaryResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteSalarySlipForm: React.FC<DeleteSalarySlipFormProps> = (props: DeleteSalarySlipFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await OutcomeSalaryService.delete(props.payslip.id);

            banner.add({
                key: 'delete_outcome_salary',
                variant: 'success',
                icon: faCheck,
                text: `Payslip has been deleted`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Payslip"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting payslip ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete payslip with details below?</Text>
            <Stack.Item>
                <Label size={'small'}>Employee</Label>
                <Text size={'small'}>{props.payslip.employee.name}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label size={'small'}>Payment month</Label>
                <Text size={'small'}>{moment(props.payslip.paymentMonth).format("MMMM YYYY")}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Status</Label>
                <Stack horizontal>
                    {props.payslip.paymentStatus.toLowerCase() === 'paid' ? <Tag text={'Paid'} variant={'success'} /> : null}
                    {props.payslip.paymentStatus.toLowerCase() === 'unpaid' ? <Tag text={'Unpaid'} variant={'error'} /> : null}
                </Stack>
            </Stack.Item>
            <Stack className={'divider'} />
            <Stack.Item>
                <Label size={'small'}>Base salary</Label>
                <Text size={'small'} className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.salaryAmount))}</Text>
            </Stack.Item>
            {props.payslip.bonusAmount > 0 ? <Stack.Item>
                <Label size={'small'}>Bonus</Label>
                <Text size={'small'} className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.bonusAmount))}</Text>
            </Stack.Item> : null}
            <Stack className={'divider'} />
            <Stack.Item>
                <Label size={'small'}>Health insurance</Label>
                <Text size={'small'} className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.healthInsuranceAmount))}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label size={'small'}>Working insurance</Label>
                <Text size={'small'} className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.workingInsuranceAmount))}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label size={'small'}>Tax</Label>
                <Text size={'small'} className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.taxAmount))}</Text>
            </Stack.Item>
            {props.payslip.totalAllowance > 0 || props.payslip.totalDeduction > 0 || props.payslip.totalCommission > 0 ? <>
                <Stack className={'divider'} />
                <Stack.Item>
                    <Label size={'small'}>Total allowance</Label>
                    <Text size={'small'} className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.totalAllowance))}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'small'}>Total deduction</Label>
                    <Text size={'small'} className={styles.deduction}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.totalDeduction))}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'small'}>Total commission</Label>
                    <Text size={'small'} className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.totalCommission))}</Text>
                </Stack.Item>
            </> : null}
            <Stack className={'divider'} />
            <Stack.Item>
                <Text size={'small'}>Total salary</Text>
                <Label>Rp. {GeneralService.getNumberWithSeparator(Number(props.payslip.totalSalary))}</Label>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteSalarySlipForm;
