import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import InternalBankAccountService from '../../../../services/data/bankaccounts/internal';

// props
import { IInternalBankAccountResourceShortProps } from '../../../../props/data/bankaccounts/internal';

// components
import { Dropdown, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';

type SelectInternaBankAccountProps = {
    subsidiaryId?: string;
    selected?: IInternalBankAccountResourceShortProps;
    options?: IInternalBankAccountResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IInternalBankAccountResourceShortProps): void;
}

const SelectInternalBankAccount: React.FC<SelectInternaBankAccountProps> = (props: SelectInternaBankAccountProps) => {
    const [selected, setSelected] = React.useState<IInternalBankAccountResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IInternalBankAccountResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const qs: string[] = [];
        const results = await InternalBankAccountService.retrieve(qs.join("&"));
        setOptions(results.data);
        setLoaded(true);
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'center'}>
            {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
            <Stack grow={1}>
                <Dropdown disabled={props.disabled || !loaded}
                    selectedKey={selected?.id}
                    options={options.filter((opt) => props.subsidiaryId ? opt.subsidiary.id === props.subsidiaryId : true).map((ba) => {
                        return { key: ba.id, text: `${ba.accountName} (${ba.accountNumber})` }
                    })}
                    onChange={(evt, opt) => {
                        const selected = options.find((o) => o.id === opt?.key as string);
                        props.onChange(selected);
                    }} />
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectInternalBankAccount;
