import React, { useEffect } from 'react';

// assets

// services
import OrderService from '../../../../services/orders/order';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';
import { IDeliveryTypeResourceShortProps } from '../../../../props/deliveryType';
import { IFreightCategoryResourceShortProps } from '../../../../props/freightCategories';
import { IUserResourceShortProps } from '../../../../props/users/user';

// components
import { DefaultButton, IContextualMenuItem } from '@fluentui/react';
import FilterPanel, { FilterOptionProps } from '../../../uiframeworks/filters/panel';
import { IOrderStatusResourceShortProps } from '../../../../props/orders/orderStatus';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';

interface IOrderListFilterButtonProps {
    filters?: TFilterBy[];
    selected: FilterOptionProps[];
    onChange(selected: FilterOptionProps[]): void;
}

export type TFilterBy = 'company' | 'customer' | 'delivery_type' | 'freight_category' | 'order_status' | 'goods_type' | 'subsidiary';

const OrderListFilterButton: React.FC<IOrderListFilterButtonProps> = (props: IOrderListFilterButtonProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [by, setBy] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<FilterOptionProps[]>(props.selected);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    const _onFilterApplied = (_selected: FilterOptionProps[]) => {
        props.onChange(_selected);
        setActiveSurface(undefined);
        setBy(undefined);
    }

    const _onCancelled = () => {
        setBy(undefined);
        setActiveSurface(undefined);
    }

    const getQueryString = () => {
        const qs: string[] = [];

        const groupedFilter: { key: string, options: FilterOptionProps[] }[] = [];
        selected.map((sel) => {
            const gfidx = groupedFilter.findIndex((gf) => gf.key === sel.filterby);
            if (gfidx > -1) {
                groupedFilter[gfidx].options.push(sel);
            } else {
                groupedFilter.push({
                    key: sel.filterby,
                    options: [sel]
                });
            }
        });

        groupedFilter.forEach((gf) => {
            qs.push(`${gf.key}=${gf.options.map((opt) => opt.key).join(';')}`);
        });

        return qs.join("&");
    }

    const _onRenderOptions = async (): Promise<FilterOptionProps[]> => {
        if (by) {
            const key = by.toLowerCase().split(" ").join("_");
            const options: FilterOptionProps[] = [];
            const qs = getQueryString();
            const results = await OrderService.getUniqueValue(key, qs);

            if (key === 'company') {
                results.forEach((res: ICompanyResourceShort) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Company',
                        key: res.id,
                        title: res.name,
                        description: res.address,
                        metadata: res
                    });
                })
            } else if (key === 'customer') {
                results.forEach((res: IUserResourceShortProps) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Customer',
                        key: res.id,
                        title: res.name,
                        description: res.email,
                        metadata: res
                    });
                })
            } else if (key === 'delivery_type') {
                results.forEach((res: IDeliveryTypeResourceShortProps) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Delivery type',
                        key: res.id,
                        title: res.name,
                        metadata: res
                    });
                })
            } else if (key === 'freight_category') {
                results.forEach((res: IFreightCategoryResourceShortProps) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Freight category',
                        key: res.id,
                        title: res.name,
                        metadata: res
                    });
                })
            } else if (key === 'subsidiary') {
                results.forEach((res: ISubsidiariesResourceShort) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Subsidiary',
                        key: res.id,
                        title: res.name,
                        metadata: res
                    });
                })
            } else if (key === 'order_status') {
                results.forEach((res: IOrderStatusResourceShortProps) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Status',
                        key: res.id,
                        title: res.name,
                        metadata: res
                    });
                })
            } else if (key === 'goods_type') {
                options.push({
                    filterby: key,
                    filterbyText: 'Goods Type',
                    key: 'fcl',
                    title: 'Full Container Load (FCL)'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Goods Type',
                    key: 'lcl',
                    title: 'Less Container Load (LCL)'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Goods Type',
                    key: 'ftl',
                    title: 'Full Truck Load (FTL)'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Goods Type',
                    key: 'ltl',
                    title: 'Less Truck Load (LTL)'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Goods Type',
                    key: 'breakbulk',
                    title: 'Breakbulk'
                });

                results.forEach((res: IOrderStatusResourceShortProps) => {
                    options.push({
                        filterby: key,
                        filterbyText: 'Goods Type',
                        key: res.id,
                        title: res.name,
                        metadata: res
                    });
                })
            } else if (key === 'payment_status') {
                options.push({
                    filterby: key,
                    filterbyText: 'Payment',
                    key: 'paid',
                    title: 'Paid'
                });

                options.push({
                    filterby: key,
                    filterbyText: 'Payment',
                    key: 'unpaid',
                    title: 'Unpaid'
                });
            }

            return options;
        } else {
            return [];
        }
    }

    const renderFilterButton = () => {
        const items: IContextualMenuItem[] = [];
        const filteredby: string[] | undefined = props.filters ? (props.filters || []).map((f) => f.toLowerCase()) : undefined;

        if (!filteredby || (filteredby || "").indexOf('company') > -1) {
            items.push({
                key: 'company',
                text: 'By company',
                onClick: () => {
                    setBy('company');
                    setActiveSurface('filter');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('customer') > -1) {
            items.push({
                key: 'customer',
                text: 'By customer',
                onClick: () => {
                    setBy('customer');
                    setActiveSurface('filter');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('subsidiary') > -1) {
            items.push({
                key: 'subsidiary',
                text: 'By subsidiary',
                onClick: () => {
                    setBy('subsidiary');
                    setActiveSurface('filter');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('delivery_type') > -1) {
            items.push({
                key: 'delivery_type',
                text: 'By delivery type',
                onClick: () => {
                    setBy('delivery_type');
                    setActiveSurface('filter');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('freight_category') > -1) {
            items.push({
                key: 'freight_category',
                text: 'By freight category',
                onClick: () => {
                    setBy('freight_category');
                    setActiveSurface('filter');
                }
            });
        }

        if (!filteredby || (filteredby || "").indexOf('goods_type') > -1) {
            items.push({
                key: 'goods_type',
                text: 'By goods type',
                onClick: () => {
                    setBy('goods_type');
                    setActiveSurface('filter');
                }
            });
        }

        /*items.push({
            key: 'payment_status',
            text: 'By payment status',
            onClick: () => {
                setBy('payment_status');
                setActiveSurface('filter');
            }
        });*/

        return <DefaultButton menuIconProps={{ iconName: "Filter" }} text={"Filter"} menuProps={{ items }} />
    }

    return <>
        {renderFilterButton()}
        {activeSurface === 'filter' && by ? <>
            <FilterPanel title={"Filter by " + by}
                onRenderOptions={_onRenderOptions}
                selected={selected}
                onCancel={_onCancelled}
                onApply={_onFilterApplied} />
        </> : null}
    </>
};

export default OrderListFilterButton;
