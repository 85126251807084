import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IDocumentProps, IFileDetailsProps } from '../../../../../props/general';

// components
import { CommandBar, DefaultButton, ICommandBarItemProps, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import FilesList from '../../../../uiframeworks/files/list';
import UploadFilesPanel from '../../../../uiframeworks/files/uploads/panel';
import DeleteFilesPanel from '../../../../uiframeworks/files/delete';
import PermissionsService from '../../../../../services/permissions';
import Permissions from '../../../../../permissions';
import VendorsService from '../../../../../services/data/vendors';

interface IVendorDocumentsListProps {
  vendorId: string;
  qs?: string[];
  documents?: IDocumentProps[];
  hideSearch?: boolean;
  hideCommandBar?: boolean;
  hideDeleteButton?: boolean;
  hideCreateButton?: boolean;
  variant?: 'card' | 'plain';
}


const VendorDocumentsList: React.FC<IVendorDocumentsListProps> = (props: IVendorDocumentsListProps) => {
  const { banner, user } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<IDocumentProps[]>(props.documents || []);
  const [selected, setSelected] = React.useState<IDocumentProps[]>([]);
  const [activeDocument, setActiveDocument] = React.useState<IDocumentProps | undefined>();
  const [keyword, setKeyword] = React.useState<string>("");
  const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

  const hasPermission = PermissionsService.hasPermission(['vendors.read.all'], user.permissions);

  useEffect(() => {
    if (!props.documents) {
      _onRetrieveDocuments();
    } else {
      setLoaded(true);
    }
  }, []);

  const _onRetrieveDocuments = async (pageNumber?: number,) => {
    try {
      setLoaded(false);
      setSelected([]);
      const qs: string[] = props.qs || [];
      const result = await VendorsService.documents.retrieve(props.vendorId);
      setDocuments(result);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'retrieve_vendor_documents_list_error',
        text: 'Failed to retrieve vendor documents list. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const _onKeywordChanged = (value?: string) => {
    setKeyword(value || "");
  }

  const getCommandBarItems = () => {
    let items: ICommandBarItemProps[] = [];
    let farItems: ICommandBarItemProps[] = [];

    if (!props.hideSearch) {
      items.push({
        key: "search",
        onRender: () => {
          return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
        }
      });
    }
    if (PermissionsService.hasPermission(['vendors.update.all'], user.permissions)) {
      if (!props.hideDeleteButton && selected.length > 0) {
        items.push({
          key: "delete",
          text: "Delete Document(s)",
          iconProps: { iconName: "Delete" },
          onRender: () => {
            return <DefaultButton text={"Delete Document(s)"}
              iconProps={{ iconName: "Delete" }}
              onClick={() => { setActiveSurface('delete') }}
              styles={{ root: { marginLeft: 10 } }} />;
          }
        })
      }

      if (!props.hideCreateButton) {
        farItems.push({
          key: "upload",
          text: "Upload Document(s)",
          iconProps: { iconName: "Upload" },
          onRender: () => {
            return <PrimaryButton text={"Upload Document(s)"}
              iconProps={{ iconName: "Upload" }}
              onClick={() => { setActiveSurface('upload') }}
              styles={{ root: { marginLeft: 10 } }} />;
          }
        });
      }
    }

    return { items, farItems };
  }

  const _onSurfaceDismissed = (refresh?: boolean) => {
    setActiveSurface(undefined);
    setActiveDocument(undefined);

    if (refresh) { _onRetrieveDocuments() }
  }

  const _onUploadDocuments = async (files: IFileDetailsProps[]) => {
    try {
      const names: string[] = [];
      const fd = new FormData();

      files.map((f: any) => {
        const file = f.data as File
        const allMetaData = {
          name: file.name,
          size: file.size,
        }
        fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, document_type: f.document_type, ...allMetaData, type: 'generic' }))
      })
      files.map(f => fd.append('documents[]', f.data))

      await VendorsService.documents.new(props.vendorId, fd)

      banner.add({
        key: "upload_vendor_documents_success",
        variant: 'success',
        icon: faCheck,
        text: `Document(s) ${names.join(", ")} uploaded successfully.`
      });

      setActiveSurface(undefined);
      setActiveDocument(undefined);
      _onRetrieveDocuments();
    } catch (e) {
      throw (e);
    }
  }

  const _onDeleteDocuments = async (files: IDocumentProps[]) => {
    try {
      const names: string[] = [];
      const fd = new FormData();
      files.forEach((file) => {
        fd.append('deleteDocumentIds[]', file.id);
        names.push(`"${file.basename}"`);
      });
      await VendorsService.documents.delete(props.vendorId, fd);

      banner.add({
        key: "delete_vendor_documents_success",
        variant: 'success',
        icon: faCheck,
        text: `Document(s) ${names.join(", ")} deleted successfully.`
      });

      setActiveSurface(undefined);
      setActiveDocument(undefined);
      _onRetrieveDocuments();
    } catch (e) {
      throw (e);
    }
  }

  const _onSelect = (_selected: IDocumentProps[]) => {
    setSelected([..._selected]);
  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    {hasPermission ? <>
      {!props.hideCommandBar ? <CommandBar
        items={getCommandBarItems().items}
        farItems={getCommandBarItems().farItems}
        styles={{
          root: {
            padding: 0,
            height: 'unset',
            backgroundColor: 'transparent'
          }
        }}
        ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
      <Stack className={styles[props.variant || 'card']}>
        <FilesList documentTypes={[
          { key: "document", text: "Document" },
        ]} files={documents.filter((doc) => doc.path.toLowerCase().indexOf(keyword.toLowerCase()) > -1)}
          loaded={loaded}
          onSelectFile={_onSelect}
          selected={selected} />
      </Stack>
      {activeSurface === 'upload' ? <>
        <UploadFilesPanel
          multiple={true}
          existings={documents}
          title={'Upload Related Document(s)'}
          onCancel={_onSurfaceDismissed}
          onUpload={_onUploadDocuments}
          useVendorDocumentType
        />
      </> : null}
      {activeSurface === 'delete' && selected.length > 0 ? <>
        <DeleteFilesPanel title={'Delete Document(s)'}
          files={selected}
          onCancel={_onSurfaceDismissed}
          onDelete={_onDeleteDocuments} />
      </> : null}
    </> : null}
  </Stack>;
};

export default VendorDocumentsList;
