import React from 'react';

// assets

// services

// props
import { IOrderResourceProps } from '../../../../../props/orders/order';
import { IOrderSummaryResourceShortProps } from '../../../../../props/orders/summary';

// components
import { Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import GeneralService from '../../../../../services/general';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/root';
import PermissionsService from '../../../../../services/permissions';

interface OrderDetailsProfitSummaryProps {
    order: IOrderResourceProps;
    summary: IOrderSummaryResourceShortProps;
}

const OrderDetailsProfitSummary: React.FC<OrderDetailsProfitSummaryProps> = observer((props: OrderDetailsProfitSummaryProps) => {
    const { user } = useStore();

    const order = props.order;
    const summary = props.summary;

    let grossProfitVariant: TTagVariant = 'warning';
    if (order.grossProfit < 0) {
        grossProfitVariant = 'error';
    } else if (summary.profit > 250000) {
        grossProfitVariant = 'success';
    }

    let nettProfitVariant: TTagVariant = 'warning';
    if (order.nettProfit < 0) {
        nettProfitVariant = 'error';
    } else if (summary.profit > 250000) {
        nettProfitVariant = 'success';
    }

    const renderNettProfit = () => {
        return <>
            <Stack className='divider'> </Stack>
            <Stack horizontal horizontalAlign={'space-between'} verticalAlign='center'>
                <Text>Nett Profit</Text>
                <Tag variant={nettProfitVariant} text={`Rp. ${GeneralService.getNumberWithSeparator(order.nettProfit)}`} />
            </Stack>
        </>
    }

    const renderTax = () => {
        return <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Tax</Text>
            <Label className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(Number(order.ppn) + Number(order.pph))}</Label>
        </Stack>
    }

    return <Stack tokens={{ childrenGap: 10 }} verticalAlign={'space-between'}>
        <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Total</Text>
            <Label>Rp. {GeneralService.getNumberWithSeparator(order.totalPrice)}</Label>
        </Stack>
        <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Expenses</Text>
            <Label className={'color-red'}>- Rp. {GeneralService.getNumberWithSeparator(summary.totalOutcomes)}</Label>
        </Stack>
        <Stack className='divider'> </Stack>
        <Stack horizontal horizontalAlign={'space-between'}>
            <Text>Gross Profit</Text>
            <Tag variant={grossProfitVariant} text={`Rp. ${GeneralService.getNumberWithSeparator(order.grossProfit)}`} />
        </Stack>
        {renderTax()}
        {renderNettProfit()}
    </Stack >
});

export default OrderDetailsProfitSummary;
