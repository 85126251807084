import { IOutcomeCategoryResourceProps, IOutcomeCategoryResourceShortProps } from '../../../../props/finance/outcomes/categories';
import Request from './../../../request';

// import props

const OutcomeCategoryService = {
    retrieve: async (qs?: string): Promise<IOutcomeCategoryResourceShortProps[]> => {
        try {
            const result = await Request.get(`/api/outcomes/categories?${qs || ""}`);
            return result.data;
        } catch (e) {
            throw (e);
        }
    },
    get: async (id: string): Promise<IOutcomeCategoryResourceProps> => {
        try {
            const result = await Request.get(`/api/outcomes/categories/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IOutcomeCategoryResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/categories`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (id: string, data: FormData): Promise<IOutcomeCategoryResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/categories/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw e;
        }
    }
}

export default OutcomeCategoryService;