import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import DataPermissionService from '../../../../../services/data/permission';
import PermissionsService from '../../../../../services/permissions';
import RoleService from '../../../../../services/roles';

// props
import { IDataPermissionResourceShort } from '../../../../../props/data/permission';
import { IRoleResourceShortProps } from '../../../../../props/roles/role';

// components
import { ActionButton, Checkbox, DefaultButton, DetailsList, Dropdown, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import NoAccess from '../../../../uiframeworks/noAccess';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectRolePermissionsForm from './subforms/permissions';

type RoleFormProps = {
    role?: IRoleResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    description: string;
    key: string;
    permissions: string[];
    group: string;
}

type FormDataErrorProps = {
    name?: string;
    description?: string;
}

const RoleForm: React.FC<RoleFormProps> = (props: RoleFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [permissions, setPermissions] = React.useState<IDataPermissionResourceShort[]>([]);
    const [selectPermissionsFormOpened, setSelectPermissionsOpened] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        description: "",
        key: "",
        permissions: [],
        group: "backoffice"
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.role === undefined ? 'create' : 'update';

    const hasPermission = PermissionsService.hasPermission(mode === 'create' ? ['roles.create'] : ['roles.update'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _permissions = await DataPermissionService.retrieve();
        setPermissions(_permissions);

        if (props.role) {
            setData({
                name: props.role.name,
                key: props.role.key,
                description: props.role.description || "",
                group: props.role.group,
                permissions: sortSelectedPermissions(props.role.permissions.map((perm) => perm.permission), _permissions).map((p) => p.key)
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name) {
            return true;
        } else if (data.name === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, description, permissions, key, group } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("description", description || "");
            fd.append("group", group);
            fd.append("key", key);
            permissions.forEach((p) => {
                fd.append("permissions[]", p);
            });

            if (props.role === undefined) {
                await RoleService.create(fd);
            } else {
                await RoleService.update(props.role.id, fd);
            }
            banner.add({
                key: mode + '_role_success',
                variant: 'success',
                icon: faCheck,
                text: `Role "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const sortSelectedPermissions = (selectedPermission: string[], _permissions?: IDataPermissionResourceShort[]) => {
        const selected: { key: string, orderNumber: number, permission: IDataPermissionResourceShort }[] = [];

        selectedPermission.forEach((permission) => {
            const idx = (_permissions || permissions).findIndex((p) => p.id === permission);
            selected.push({ key: permission, orderNumber: idx, permission: permissions[idx] });
        });

        return selected.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0))
    }

    return <Panel headerText={mode === 'create' ? 'Create Role' : 'Update Role'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating role ..." : "Updating role ..."} /> : null}
            </Stack>;
        }}>
        {hasPermission ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                data.key = (value || "").split(" ").join("").toLowerCase();

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Unique key"}
                            disabled={true}
                            value={data.key} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Description"}
                            required={false}
                            multiline
                            rows={3}
                            autoAdjustHeight
                            resizable={false}
                            value={data.description}
                            onChange={(evt, value) => {
                                data.description = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.description = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.description}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown label={"Group"}
                            required={true}
                            options={[
                                { key: 'backoffice', text: 'Back office' },
                                { key: 'customer', text: 'Customer' }
                            ]}
                            selectedKey={data.group}
                            onChange={(evt, opt) => {
                                data.group = opt?.key as string || "backoffice";
                                setData({ ...data });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <Stack tokens={{ childrenGap: 5 }}>
                        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'start'}>
                            <Stack tokens={{childrenGap: 3}}>
                                <Label size={'small'}>Selected permissions</Label>
                                <Text size={'small'}>Permissions that can be done by the user with this role.</Text>
                            </Stack>
                            <PrimaryButton iconProps={{ iconName: "Edit" }} text={'Update permissions'} onClick={() => setSelectPermissionsOpened(true)} />
                        </Stack>
                        <DetailsList items={data.permissions}
                            selectionMode={SelectionMode.none}
                            columns={[
                                {
                                    key: 'group',
                                    name: 'Group',
                                    minWidth: 150,
                                    maxWidth: 150,
                                    isMultiline: true,
                                    onRender: (key: string) => {
                                        const permission = permissions.find((p) => p.id === key);
                                        return <Stack>
                                            <Text size={'small'}>{permission?.group}</Text>
                                        </Stack>
                                    }
                                },
                                {
                                    key: 'details',
                                    name: 'Permission',
                                    minWidth: 0,
                                    isMultiline: true,
                                    onRender: (key: string) => {
                                        const permission = permissions.find((p) => p.id === key);
                                        return <Stack>
                                            <Text size={'small'}>{permission?.name}</Text>
                                            {permission?.description ? <Text size={'xsmall'}>{permission.description}</Text> : null}
                                        </Stack>
                                    }
                                }
                            ]} />
                    </Stack>
                </> : null
            }
        </Stack> : null}
        {!hasPermission ? <NoAccess /> : null}
        {selectPermissionsFormOpened ? <SelectRolePermissionsForm permissions={permissions}
            selected={data.permissions}
            onDismissed={(selected?: string[]) => {
                if (selected) { data.permissions = sortSelectedPermissions(selected).map((p) => p.key); }
                setData({ ...data });
                setSelectPermissionsOpened(false);
            }} /> : null}
    </Panel>
};

export default RoleForm;
