import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';
import CompanyService from '../../../../services/users/company';
import GeneralService from '../../../../services/general';
import FreightCategoryService from '../../../../services/orders/freightCategory';
import DeliveryTypeService from '../../../../services/orders/deliveryType';
import OrderService from '../../../../services/orders/order';
import ValidationService from '../../../../services/validation';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';
import { IDeliveryTypeDataProps, IDeliveryTypeResourceShortProps } from '../../../../props/deliveryType';
import { IFreightCategoryResourceShortProps } from '../../../../props/freightCategories';
import { IUserResourceShortProps } from '../../../../props/users/user';
import DeliveredGoodsType, { IDeliveredGoodsTypeResourceShortProps } from '../../../../manifests/deliveredGoodsType';

// components
import { ActionButton, Checkbox, DefaultButton, DetailsList, Dropdown, IColumn, IDropdownOption, IconButton, NormalPeoplePicker, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../services/data/subsidiaries';
import ISalesTeamResourceShort from '../../../../props/users/team';
import TeamService from '../../../../services/users/team';
import { IndonesianCities, searchCities, searchCitiesByProvince, searchProvinceByCity } from '../../../../manifests/cities';
import CityService from '../../../../services/data/cities';
import { IOrderAddressResourceShortProps } from '../../../../props/orders/address';
import CompanyField from '../../../uiframeworks/forms/company';
import SelectCustomerField from '../../../uiframeworks/forms/customer';
import SelectFreightCategory from '../../../uiframeworks/forms/freightCategory';
import SelectDeliveryType from '../../../uiframeworks/forms/deliveryType';
import SelectGoodsType from '../../../uiframeworks/forms/goodsType';
import RequestOfferAddressSubform from './subforms/address';
import { IOfferOfferedPriceResourceShortProps } from '../../../../props/offers/offeredPrice';
import RequestOfferItemSubform from './subforms/items';
import Tag from '../../../uiframeworks/tag';
import OfferService from '../../../../services/offers';
import LoadingDialogComponent from '../../../feedbacks/loadingDialog';

type RequestOfferFormProps = {
    onDismissed(refresh?: boolean): void;
}

export type FormDataProps = {
    offerNumber: string;
    notes: string;
    paymentTerm: string;
    company?: ICompanyResourceShort;
    customer?: IUserResourceShortProps;
    freightCategory?: IFreightCategoryResourceShortProps;
    deliveryType?: IDeliveryTypeResourceShortProps;
    goodsType?: IDeliveredGoodsTypeResourceShortProps;
    originAddress: IOrderAddressResourceShortProps[];
    destinationAddress: IOrderAddressResourceShortProps[];
    items: IOfferOfferedPriceResourceShortProps[];
    includedCosts: {
        ppn: boolean;
        pph: boolean;
        loadingLabour: boolean;
        unloadingLabour: boolean;
        insurance: boolean;
    },
    totalPrice: number;
}

type FormDataErrorProps = {
    notes?: string;
    paymentTerm?: string;
}

const RequestOfferForm: React.FC<RequestOfferFormProps> = (props: RequestOfferFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(true);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        offerNumber: GeneralService.makeid(25, true, 'OFR'),
        notes: '',
        paymentTerm: '',
        originAddress: [],
        destinationAddress: [],
        items: [],
        includedCosts: {
            ppn: true,
            pph: true,
            loadingLabour: false,
            unloadingLabour: false,
            insurance: false
        },
        totalPrice: 0
    });
    const [activeItem, setActiveItem] = React.useState<IOfferOfferedPriceResourceShortProps | undefined>();
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const isSubmitButtonDisabled = (): boolean => {
        if (!data.company || !data.customer || !data.freightCategory || !data.deliveryType || !data.goodsType) {
            return true;
        } else if (data.originAddress.length < 1 || data.destinationAddress.length < 1 || data.items.length < 1) {
            return true;
        } else if (error.notes || error.paymentTerm) {
            return true;
        }

        return false;
    }

    const itemColumns: IColumn[] = [
        {
            key: 'details',
            name: 'Details',
            minWidth: 100,
            onRender: (item: IOfferOfferedPriceResourceShortProps) => {
                let name = item.name;
                if (item.type === 'container') {
                    name = "Container " + name;
                }

                return <Stack styles={{ root: { height: '100%' } }} verticalAlign={'center'}>
                    <Label size={'xsmall'}>{name}</Label>
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 3 }}>
                        <Text size={'xsmall'}>{item.quantityAmount} {item.quantityUnit.name}</Text>
                        {item.priceType === 'perquantity' ? <Text size={'xsmall'}>@ Rp. {GeneralService.getNumberWithSeparator(item.price)}</Text> : null}
                    </Stack>
                </Stack>
            }
        },
        {
            key: 'targetPrice',
            name: 'Target Price',
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOfferOfferedPriceResourceShortProps) => {
                return <Stack styles={{ root: { height: '100%' } }} verticalAlign={'center'}>
                    <Label size={'xsmall'}>Rp. {GeneralService.getNumberWithSeparator(item.totalPrice)}</Label>
                </Stack>
            }
        },
        {
            key: 'action',
            name: '',
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOfferOfferedPriceResourceShortProps) => {
                return <Stack styles={{ root: { height: '100%' } }} verticalAlign={'center'} horizontal tokens={{ childrenGap: 5 }}>
                    <IconButton iconProps={{ iconName: 'Edit' }}
                        onClick={() => {
                            setActiveItem(item);
                            setActiveSurface('update.item');
                        }} />
                    <IconButton iconProps={{ iconName: 'Delete' }}
                        onClick={() => {
                            if (window.confirm('Are you sure to delete item "' + item.name + '"?')) {
                                data.items = data.items.filter((i) => i.id === item.id);
                                setData({ ...data });
                            }
                        }} />
                </Stack>
            }
        }
    ]

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append('offerNumber', data.offerNumber);

            fd.append('customerId', data.customer?.id || "");
            fd.append('customerName', data.customer?.name || "");
            fd.append('companyId', data.company?.id || "");

            fd.append('freightCategoryId', data.freightCategory?.id || "");
            fd.append('deliveryTypeId', data.deliveryType?.id || "");
            fd.append('goodsType', data.goodsType?.key || "");

            fd.append('includePPn', data.includedCosts.ppn ? '1' : '0');
            fd.append('includePPh', data.includedCosts.pph ? '1' : '0');
            fd.append('includeLoadingLabour', data.includedCosts.loadingLabour ? '1' : '0');
            fd.append('includeUnloadingLabour', data.includedCosts.unloadingLabour ? '1' : '0');

            fd.append('subtotalPrice', data.totalPrice.toFixed(2));
            fd.append('totalPrice', data.totalPrice.toFixed(2));
            fd.append('paymentTerm', data.paymentTerm);

            data.originAddress.forEach((address) => {
                fd.append('originAddresses[]', JSON.stringify(address));
            });

            data.destinationAddress.forEach((address) => {
                fd.append('destinationAddresses[]', JSON.stringify(address));
            });

            data.items.forEach((item: any) => {
                item.quantityUnitId = item.quantityUnit.id;
                fd.append('items[]', JSON.stringify(item));
            });

            const offer = await OfferService.request(fd);
            
			setSubmitted(true);
			setTimeout(() => {
				route.route?.history.push(`/offers/${offer.id}/view`);
			}, 4000);
        } catch (e) {
            banner.add({
                key: 'request_offer_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to request offer. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    const _onAddressChanged = (address?: IOrderAddressResourceShortProps) => {
        if (address && address.target === 'destination') {
            data.destinationAddress.push(address);
        } else if (address && address.target === 'origin') {
            data.originAddress.push(address);
        }

        setData({ ...data });
        setActiveSurface(undefined);
    }

    const _onItemChanged = (item?: IOfferOfferedPriceResourceShortProps) => {
        if (item) {
            const idx = data.items.findIndex((i) => i.id === item.id);
            if (idx > -1) {
                data.items[idx] = item;
            } else {
                data.items.push(item);
            }
        }

        // calculate total price
        let totalPrice = 0;
        data.items.forEach((i) => {
            totalPrice += Number(i.totalPrice);
        });
        data.totalPrice = totalPrice;

        setData({ ...data });
        setActiveItem(undefined);
        setActiveSurface(undefined);
    }

    return <Panel headerText={"Request Offer"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign={'center'} horizontalAlign={'space-between'}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </Stack>
                        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }} styles={{ root: { paddingRight: 15 } }}>
                            <Text size={'small'} style={{ marginTop: 2 }}>TARGET PRICE</Text>
                            <Text size={'small'}>:</Text>
                            <Tag text={`Rp. ${GeneralService.getNumberWithSeparator(data.totalPrice)}`} variant={'success'} />
                        </Stack>
                    </Stack> : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Requesting offer ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <CompanyField selected={data.company}
                                required={true}
                                onChange={(selected) => {
                                    data.company = selected;
                                    data.customer = undefined;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <SelectCustomerField selected={data.customer}
                                label={'Customer (PIC)'}
                                required={true}
                                disabled={!data.company}
                                companyId={data.company?.id}
                                onChange={(selected) => {
                                    data.customer = selected;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '33.3%' } }}>
                            <SelectFreightCategory selected={data.freightCategory}
                                required={true}
                                onChange={(selected) => {
                                    data.freightCategory = selected;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '33.3%' } }}>
                            <SelectDeliveryType selected={data.deliveryType}
                                required={true}
                                onChange={(selected) => {
                                    data.deliveryType = selected;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '33.3%' } }}>
                            <SelectGoodsType selected={data.goodsType}
                                required={true}
                                disabled={!data.deliveryType || !data.freightCategory}
                                onChange={(selected) => {
                                    data.goodsType = selected;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label size={'xsmall'}>Pick-up address</Label>
                            <ActionButton text={'Add origin'}
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    setActiveSurface('add.address.origin')
                                }} />
                        </Stack>
                        {data.originAddress.length > 0 ? <ol style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
                            {data.originAddress.map((address) => {
                                const fullAddress = [address.address, address.province, address.city].filter(Boolean).join(", ");
                                return <li>
                                    <Stack horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 20 }}>
                                        <Text size={'small'}>{address.type.toUpperCase()} - {fullAddress}</Text>
                                        <ActionButton iconProps={{ iconName: "Delete" }}
                                            styles={{ root: { height: 'unset !important', minHeight: 'unset !important' } }}
                                            onClick={() => {
                                                if (window.confirm('Are you confirm to delete "' + fullAddress + '"?')) {
                                                    data.originAddress = data.originAddress.filter((a) => a.id !== address.id);
                                                    setData({ ...data });
                                                }
                                            }} />
                                    </Stack>
                                </li>
                            })}
                        </ol> : null}
                        {data.originAddress.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No origin address</Text> : null}
                    </Stack>
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label size={'xsmall'}>Drop-off address</Label>
                            <ActionButton text={'Add destination'}
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    setActiveSurface('add.address.destination')
                                }} />
                        </Stack>
                        {data.destinationAddress.length > 0 ? <ol style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
                            {data.destinationAddress.map((address) => {
                                const fullAddress = [address.address, address.province, address.city].filter(Boolean).join(", ");
                                return <li>
                                    <Stack horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 20 }}>
                                        <Text size={'small'}>{address.type.toUpperCase()} - {fullAddress}</Text>
                                        <ActionButton iconProps={{ iconName: "Delete" }}
                                            styles={{ root: { height: 'unset !important', minHeight: 'unset !important' } }}
                                            onClick={() => {
                                                if (window.confirm('Are you confirm to delete "' + fullAddress + '"?')) {
                                                    data.destinationAddress = data.destinationAddress.filter((a) => a.id !== address.id);
                                                    setData({ ...data });
                                                }
                                            }} />
                                    </Stack>
                                </li>
                            })}
                        </ol> : null}
                        {data.destinationAddress.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No destination address</Text> : null}
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Label size={'xsmall'}>What needs to be included?</Label>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Checkbox label='Loading labour'
                                checked={data.includedCosts.loadingLabour}
                                onChange={() => {
                                    data.includedCosts.loadingLabour = !data.includedCosts.loadingLabour;
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Checkbox label='Unloading labour'
                                checked={data.includedCosts.unloadingLabour}
                                onChange={() => {
                                    data.includedCosts.unloadingLabour = !data.includedCosts.unloadingLabour;
                                    setData({ ...data });
                                }} />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Checkbox label='PPn'
                                checked={data.includedCosts.ppn}
                                onChange={() => {
                                    data.includedCosts.ppn = !data.includedCosts.ppn;
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack styles={{ root: { width: '50%' } }}>
                            <Checkbox label='PPh'
                                checked={data.includedCosts.pph}
                                onChange={() => {
                                    data.includedCosts.pph = !data.includedCosts.pph;
                                    setData({ ...data });
                                }} />
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label size={'xsmall'}>Requested items</Label>
                            <ActionButton text={'Add item'}
                                iconProps={{ iconName: 'Add' }}
                                disabled={!data.freightCategory || !data.deliveryType || !data.goodsType}
                                onClick={() => {
                                    setActiveSurface('add.item')
                                }} />
                        </Stack>
                        {data.items.length > 0 ? <DetailsList items={data.items}
                            columns={itemColumns}
                            selectionMode={SelectionMode.none} /> : null}
                        {data.items.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No requested items</Text> : null}
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack.Item>
                        <TextField label={'Additional notes'}
                            value={data.notes}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={false}
                            placeholder='ex. include asuransi pupuk dolomit senilai 500jt, etc ...'
                            onChange={(evt, value) => {
                                data.notes = value || '';

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={'Payment term'}
                            value={data.paymentTerm}
                            placeholder='ex. pembayaran termin 1 bulan setelah barang bongkar, etc ...'
                            multiline
                            rows={2}
                            autoAdjustHeight
                            resizable={false}
                            onChange={(evt, value) => {
                                data.paymentTerm = value || '';

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.paymentTerm = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
        {activeSurface === 'add.item' ? <RequestOfferItemSubform fd={data} onDismissed={_onItemChanged} /> : null}
        {activeSurface === 'update.item' && activeItem ? <RequestOfferItemSubform fd={data} item={activeItem} onDismissed={_onItemChanged} /> : null}
        {activeSurface === 'add.address.origin' ? <RequestOfferAddressSubform target={'origin'} orderNumber={data.originAddress.length + 1} headerTitle={'Add Origin Address'} onDismissed={_onAddressChanged} /> : null}
        {activeSurface === 'add.address.destination' ? <RequestOfferAddressSubform target={'destination'} orderNumber={data.originAddress.length + 1} headerTitle={'Add Destination Address'} onDismissed={_onAddressChanged} /> : null}
        {submitted ? <LoadingDialogComponent
			title={`Offer Requested`}
			secondaryText={`Offer requested successfully. You will be redirected momentarily.`}
		/> : null}
    </Panel>
};

export default RequestOfferForm;
