import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import { Panel, PanelType, Stack, PrimaryButton, DefaultButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Tag } from 'lucide-react';
import { Label } from 'recharts';
import ISalesTeamResourceShort from '../../../../../props/users/team';
import TeamService from '../../../../../services/users/team';


interface IDeleteTeamFormProps {
    team: ISalesTeamResourceShort;
    onDismissed(refresh?: boolean): void;
}

const DeleteTeamForm: React.FC<IDeleteTeamFormProps> = (props: IDeleteTeamFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await TeamService.delete(props.team.id);

            banner.add({
                key: 'delete_team_success',
                variant: 'success',
                icon: faCheck,
                text: `Team "${props.team.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Team"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting team ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete team with details below?</Text>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.team.name}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeleteTeamForm;
