export type TFileType = 'word' | 'excel' | 'image' | 'powerpoint' | 'pdf' | 'file' | 'video';

const FileFormats = {
    word: ['.docx', '.doc'],
    excel: ['.xls', '.xlsx'],
    powerpoint: ['.pptx', '.ppt'],
    pdf: ['.pdf'],
    image: ['.png', '.jpg', '.jpeg', '.tiff', '.svg', '.gif'],
    video: ['.mp4'],
    file: []
}

export default FileFormats;