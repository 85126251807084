import ISalesTeamResourceShort, { ISalesCommissionResourceShort, ISalesTeamMemberResourceShort } from "../../props/users/team";
import Request from './../request';

const SalesTeamService = {
  retrieve: async (qs?: string): Promise<ISalesTeamResourceShort[]> => {
    try {
      const results = await Request.get(`/api/teams/sales?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ISalesTeamResourceShort> => {
    try {
      const results = await Request.get(`/api/teams/sales/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ISalesTeamResourceShort> => {
    try {
      const result = await Request.post(`/api/teams/sales`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ISalesTeamResourceShort> => {
    try {
      const result = await Request.post(`/api/teams/sales/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/teams/sales/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
}

const TeamMemberService = {
  retrieve: async (qs?: string): Promise<ISalesTeamMemberResourceShort[]> => {
    try {
      const results = await Request.get(`/api/team_sales_members?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ISalesTeamMemberResourceShort> => {
    try {
      const results = await Request.get(`/api/team_sales_members/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ISalesTeamMemberResourceShort> => {
    try {
      const result = await Request.post(`/api/team_sales_members`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ISalesTeamMemberResourceShort> => {
    try {
      const result = await Request.post(`/api/team_sales_members/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/team_sales_members/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
}

const SalesCommissionService = {
  retrieve: async (qs?: string): Promise<ISalesCommissionResourceShort[]> => {
    try {
      const results = await Request.get(`/api/sales_comissions?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ISalesCommissionResourceShort> => {
    try {
      const results = await Request.get(`/api/sales_comissions/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ISalesCommissionResourceShort> => {
    try {
      const result = await Request.post(`/api/sales_comissions`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ISalesCommissionResourceShort> => {
    try {
      const result = await Request.post(`/api/sales_comissions/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/sales_comissions/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
}

export { TeamMemberService, SalesCommissionService }

export default SalesTeamService;