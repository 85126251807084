import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

import { useStore } from '../../../stores/root';
import ComingSoon from '../../../components/uiframeworks/comingSoon';
import FinanceMonthlySummary from '../../../components/finances/summary/monthly';
import Label from '../../../components/typography/label';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { observer } from 'mobx-react';
import PermissionsService from '../../../services/permissions';
import NoPermission from '../../../components/uiframeworks/noPermission';
import Permissions from '../../../permissions';

interface IFinanceDashboardPageProps {
}

const FinanceDashboardPage: React.FC<IFinanceDashboardPageProps> = observer((props: IFinanceDashboardPageProps) => {
    const {topbar, user} = useStore();
    const hasPermission = PermissionsService.hasPermission(Permissions.finance.dashboard.read, user.roles);

    useEffect(() => {
        topbar.show("Finance", [
            {key: "home", icon: faHome, href: "/"},
            {key: "finance", text: 'Finance'}
        ])
    }, []);

    return <Stack tokens={{childrenGap: 20}} className={styles.container}>
        {!hasPermission ? <NoPermission /> : null}
        {hasPermission ? <Stack horizontal tokens={{childrenGap: 20}}>
            <Stack className={styles.ordersSummary}>
                <FinanceMonthlySummary />
            </Stack>
            <Stack>
                <Label>Outcomes by Category</Label>
                <Label>Most Profitable orders</Label>
                <Label>Non-Profitable orders</Label>
                <Label>Order Summary</Label>
            </Stack>
        </Stack> : null}
    </Stack>;
});

export default FinanceDashboardPage;
