import { DropdownMenuItemType, IDropdownOption } from "@fluentui/react";

const trucksType: IDropdownOption[] = [
    { key: 'smallTruck', text: 'Mobil / Truk Kecil', itemType: DropdownMenuItemType.Header },
    { key: 'Van', text: 'Van' },
    { key: 'Box', text: 'Box' },
    { key: 'Gran Max', text: 'Gran Max' },
    { key: 'Pick-up', text: 'Pick-up' },
    { key: 'L300 Bak', text: 'L300 Bak' },
    { key: 'L300 Box', text: 'L300 Box' },
    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'coltDiesel', text: 'Colt Diesel', itemType: DropdownMenuItemType.Header },
    { key: 'Colt Diesel Engkel Box (CDE)', text: 'Colt Diesel Engkel Box (CDE)' },
    { key: 'Colt Diesel Engkel Bak (CDE)', text: 'Colt Diesel Engkel Bak (CDE)' },
    { key: 'Colt Diesel Double Box (CDD)', text: 'Colt Diesel Double Box (CDD)' },
    { key: 'Colt Diesel Double Bak (CDD)', text: 'Colt Diesel Double Bak (CDD)' },
    { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'fuso', text: 'Fuso', itemType: DropdownMenuItemType.Header },
    { key: 'Fuso Box', text: 'Fuso Box' },
    { key: 'Fuso Bak', text: 'Fuso Bak' },
    { key: 'divider_3', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'tronton', text: 'Tronton', itemType: DropdownMenuItemType.Header },
    { key: 'Tronton Box', text: 'Tronton Box' },
    { key: 'Tronton Bak', text: 'Tronton Bak' },
    { key: 'divider_4', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'wingbox', text: 'Wingbox', itemType: DropdownMenuItemType.Header },
    { key: 'Wingbox', text: 'Wingbox' },
    { key: 'divider_5', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'trailer', text: 'Trailer', itemType: DropdownMenuItemType.Header },
    { key: 'Trailer Flatbed', text: 'Trailer Flatbed' },
    { key: 'Trailer Box', text: 'Trailer Box' },
    { key: 'Trailer Lowbed', text: 'Trailer Lowbed' }
  ];
  
  export default trucksType;
  