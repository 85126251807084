import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import ComingSoon from '../../../components/uiframeworks/comingSoon';
import { useParams } from 'react-router-dom';
import PermissionsService from '../../../services/permissions';
import NoAccess from '../../../components/uiframeworks/noAccess';
import AdministratorPerformanceDashboard from '../../../components/users/administrators/performance/dashboard';

interface MyPerformancePageProps {
}

const AdministratorPerformancePage: React.FC<MyPerformancePageProps> = (props: MyPerformancePageProps) => {
    const {topbar, user} = useStore();
    const params: any = useParams();
    const hasAccess = params.administratorId && (params.administratorId === 'my' || PermissionsService.hasPermission(['superuser', 'finance', 'hr'], user.roles) || params.administratorId === user.data?.id);

    useEffect(() => {
        topbar.show(params.administratorId === 'my' ? "My Performance" : "Performance", [
            {key: "home", icon: faHome, href: "/"},
            {key: "administrators", text: 'Administrators'}
        ]);
        if (hasAccess) {init();}
    }, []);

    const init = () => {
        try {
        } catch(e) {
        }
    }

    return <Stack>
        {!hasAccess ? <NoAccess /> : null}
        {hasAccess ? <AdministratorPerformanceDashboard userId={params.administratorId} /> : null}
    </Stack>;
};

export default AdministratorPerformancePage;
