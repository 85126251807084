import ISubsidiariesResourceShort from "../../props/data/subsidiaries";
import Request from './../request';

const SubsidiariesService = {
  retrieve: async (qs?: string): Promise<ISubsidiariesResourceShort[]> => {
    try {
      const results = await Request.get(`/api/subsidiaries?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ISubsidiariesResourceShort> => {
    try {
      const results = await Request.get(`/api/subsidiaries/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ISubsidiariesResourceShort> => {
    try {
      const result = await Request.post(`/api/subsidiaries`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ISubsidiariesResourceShort> => {
    try {
      const result = await Request.post(`/api/subsidiaries/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/subsidiaries/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
}

export default SubsidiariesService