import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';

// props
import { IUserResourceShortProps } from '../../../../props/users/user';
import { IPaginationResourceShort } from '../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, Link, Persona, PersonaSize, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from './../../../typography/text';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import { IOrderResourceProps, IOrderResourceShortProps } from '../../../../props/orders/order';
import GeneralService from '../../../../services/general';
import FileService from '../../../../services/general/file';
import Label from '../../../typography/label';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import OrderService from '../../../../services/orders/order';
import OrderForm from '../form';
import { IGoodsResourceShortProps } from '../../../../props/goods/goods';
import GoodsService from '../../../../services/goods/goods';
import GoodsForm from '../form';
import moment from 'moment';
import DeleteGoodsForm from '../delete';
import GoodsDetails from '../details';

interface IGoodsListProps {
    qs?: string[];
    goods?: IGoodsResourceShortProps[];
    columns?: TGoodsColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateGoods?: boolean;
    variant?: 'card' | 'plain';
}

export type TGoodsColumn = 'name' | 'actions' | 'company' | 'customer' | 'quantity' | 'warehouse' | 'supplier' | 'deliveryDate';

const GoodsList: React.FC<IGoodsListProps> = (props: IGoodsListProps) => {
    const shownColumns: TGoodsColumn[] = props.columns || [
        'name',
        'actions',
        'company',
        'customer',
        'quantity',
        'warehouse',
        'supplier',
        'deliveryDate'
    ];

    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [goods, setGoods] = React.useState<IGoodsResourceShortProps[]>(props.goods || []);
    const [activeGoods, setActiveGoods] = React.useState<IGoodsResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            minWidth: 150,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveSurface('details');
                        setActiveGoods(item);
                    }}><Text>{item.name}</Text></Link>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IGoodsResourceShortProps) => {
                let menuItems: IContextualMenuItem[] = [
                    { key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                        setActiveGoods(item);
                        setActiveSurface('update');
                    } },
                    { key: "delete", text: "Delete", iconProps: { iconName: "Delete" }, onClick: () => {
                        setActiveGoods(item);
                        setActiveSurface('delete');
                    } }
                ];

                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item>
                </Stack.Item>;
            }
        },
        {
            key: "company",
            name: "Owned By (Company)",
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {item.company ? <NavLink to={`/users/company/${item.company.id}`}>
                        <Text>{item.company.name}</Text>
                    </NavLink> : null}
                    {!item.company ? <Text>-</Text> : null}
                </Stack>
            }
        },
        {
            key: "customer",
            name: "Customer (PIC)",
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/users/customers/${item.user.id}`}>
                        <Text>{item.user.name}</Text>
                    </NavLink>
                </Stack>
            }
        },
        {
            key: "quantity",
            name: "Quantity",
            minWidth: 125,
            maxWidth: 125,
            isMultiline: true,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.remainingQuantity} {item.quantityUnit || ""}</Text>
                </Stack>
            }
        },
        {
            key: "warehouse",
            name: "Location",
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {item.warehouse ? <NavLink to={`/operationals/warehouses/${item.warehouse.id}`}>
                        <Text>{item.warehouse.name}</Text>
                    </NavLink> : null}
                    {item.locationDetails ? <Text size='small' style={{fontStyle: 'italic'}}>Note: {item.locationDetails}</Text> : null}
                    {!item.locationDetails && !item.warehouse ? <Text>-</Text> : null}
                </Stack>
            }
        },
        {
            key: "supplier",
            name: "Supplier",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.supplier || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "deliveryDate",
            name: "Delivery Date",
            minWidth: 125,
            maxWidth: 125,
            isMultiline: true,
            onRender: (item: IGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.deliveryDate ? GeneralService.formatDate(moment(item.deliveryDate).toDate()) : "-"}</Text>
                </Stack>
            }
        },
    ];

    useEffect(() => {
        if (!props.goods) {
            _onRetrieveGoods();
        } else {
            setLoaded(true);
        }
    }, [keyword]);

    const _onRetrieveGoods = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            qs.push(`status=current`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await GoodsService.retrieve.getPaged(qs.join("&"));
            if (!pageNumber) {
                setGoods(results.data);
            } else {
                setGoods([...goods, ...results.data]);
            }
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_goods_error',
                text: 'Failed to retrieve goods list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateGoods) {
            farItems.push({
                key: "register",
                text: "Create Goods",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Goods"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveGoods(undefined);

        if (refresh) { _onRetrieveGoods() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && goods.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={goods}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TGoodsColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && goods.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                <Text>Goods not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <GoodsForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeGoods ? <GoodsForm goodsId={activeGoods.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && activeGoods ? <DeleteGoodsForm goods={activeGoods} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'details' && activeGoods ? <GoodsDetails goodsId={activeGoods.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default GoodsList;
