import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';
import DeliveredGoodsType, { IDeliveredGoodsTypeResourceShortProps } from '../../../../manifests/deliveredGoodsType';

// assets

// services

// props
import { IDeliveryTypeResourceShortProps } from '../../../../props/orders/deliveryType';

// components
import { Dropdown, Stack } from '@fluentui/react';
import Label from '../../../typography/label';

type SelectGoodsTypeProps = {
    deliveryType?: IDeliveryTypeResourceShortProps;
    selected?: IDeliveredGoodsTypeResourceShortProps;
    options?: IDeliveredGoodsTypeResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IDeliveredGoodsTypeResourceShortProps): void;
}

const SelectGoodsType: React.FC<SelectGoodsTypeProps> = (props: SelectGoodsTypeProps) => {
    const { banner } = useStore();
    const [options, setOptions] = React.useState<IDeliveredGoodsTypeResourceShortProps[]>(props.options || DeliveredGoodsType);
    const [selected, setSelected] = React.useState<IDeliveredGoodsTypeResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Goods type"}</Label>}
        <Dropdown selectedKey={selected?.key}
            options={options.filter((dgt) => {
                const { deliveryType } = props;
                if (deliveryType) {
                    if ((deliveryType?.key || "").toLowerCase() === 'shipping') {
                        return dgt.key !== 'ftl' && dgt.key !== 'ltl';
                    } else if ((deliveryType?.key || "").toLowerCase() === 'trucking') {
                        return dgt.key === 'ftl' || dgt.key === 'ltl';
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }).map((opt) => {
                return { key: opt.key, text: opt.text, metadata: opt };
            })}
            disabled={props.disabled}
            onChange={(evt, opt: any) => {
                const selected = opt ? opt.metadata : undefined;
                props.onChange(selected);
            }}
        />
    </Stack>;
};

export default SelectGoodsType;
