const Permissions = {
    order: {
        read: ['superuser', 'admin', 'sales', 'finance'],
        list: ['superuser', 'admin', 'sales', 'finance'],
        create: [],
        update: ['superuser', 'admin'],
        cancel: ['superuser', 'admin'],
        history: {
            read: ['superuser']
        },
        dashboard: {
            read: ['superuser', 'admin']
        },
        sales: {
            update: ['superuser', 'admin']
        },
        status: {
            update: ['superuser', 'admin']
        },
        document: {
            create: ['superuser', 'admin']
        }
    },
    customer: {
        create: ['superuser', 'admin', 'sales'],
        update: ['superuser', 'admin'],
        delete: ['superuser', 'admin'],
        password: {
            reset: ['superuser', 'admin']
        }
    },
    finance: {
        dashboard: {
            read: ['superuser', 'admin', 'finance']
        },
        outcomes: {
            read: ['superuser', 'admin', 'finance']
        },
        incomes: {
            read: ['superuser', 'admin', 'finance']
        },
    }
}

export default Permissions;