import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import ComingSoon from '../../../components/uiframeworks/comingSoon';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IInvoicesListPageProps {
}

const InvoicesListPage: React.FC<IInvoicesListPageProps> = (props: IInvoicesListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Invoices", [
            {key: "home", icon: faHome, href: "/"},
            {key: "finance", text: 'Finance', href: "/finance"},
            {key: "invoices", text: 'Invoices'}
        ])
    }, []);

    return <Stack>
        <ComingSoon />
    </Stack>;
};

export default InvoicesListPage;
