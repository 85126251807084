import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import ShipsService from '../../../../../services/data/ships';

// props
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';
import { IShipResourceShortProps } from '../../../../../props/data/ships';

// components
import { CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import Tag from '../../../../uiframeworks/tag';
import ShipForm from './../form';
import { NavLink } from 'react-router-dom';
import ShipSchedulesList from '../../schedules/general/list';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import ShipDocumentsList from '../../documents/general/list';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';

interface IShipDetailsProps {
    shipId: string;
}

const ShipDetails: React.FC<IShipDetailsProps> = (props: IShipDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [ship, setShip] = React.useState<IShipResourceShortProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['ships.read.all'], user.permissions);

    useEffect(() => {
        _onGetCustomer();
    }, []);

    const _onGetCustomer = async () => {
        try {
            const _ship = await ShipsService.get(props.shipId);
            setShip(_ship);

            topbar.show(_ship.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "operationals", text: 'Operationals' },
                { key: "vendors", text: 'Vendors', href: "/operationals/vendors" },
                { key: _ship.vendor.id, text: _ship.vendor.name, href: "/operationals/vendors/" + _ship.vendor.id },
                { key: props.shipId, text: _ship.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_ship_error',
                text: 'Failed to get ship details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['ships.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                text: 'Update details',
                onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
            });
        }

        return { items, farItems };
    }

    const getShipPropertiesSection = (_ship: IShipResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'ship_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_ship.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Name</Label>
                        <Text>{_ship.name}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Registration Number</Label>
                        <Text>{_ship.registrationNumber || "-"}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Vendor</Label>
                        <NavLink to={'/operationals/vendors/' + _ship.vendor.id}><Text>{_ship.vendor.name || "-"}</Text></NavLink>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getNextScheduleSection = (_ship: IShipResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "Next Schedule"
            },
            key: 'next_schedule',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <ComingSoon />
            </Stack>
        }
    }

    const getTabsSection = (_ship: IShipResourceShortProps): IStackSectionProps => {
        const tabs: ISectionTabProps[] = [];

        if (PermissionsService.hasPermission(['ships.schedules.read.all'], user.permissions)) {
            tabs.push({
                key: 'ship_schedules',
                title: 'Ship Schedule(s)',
                content: <Stack>
                    <ShipSchedulesList shipId={_ship.id} />
                </Stack>
            });
        }

        tabs.push({
            key: 'related_documents',
            title: 'Related Document(s)',
            content: <Stack>
                <ShipDocumentsList shipId={props.shipId} />
            </Stack>
        });

        return {
            key: 'ship_tabs',
            isCard: true,
            tabs
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetCustomer() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving customer details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && ship && hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getShipPropertiesSection(ship)]} right={getNextScheduleSection(ship)} />
                <DetailsStack left={[getTabsSection(ship)]} />
            </Stack>
            {activeSurface === 'update' ? <ShipForm shipId={ship.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {!hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default ShipDetails;
