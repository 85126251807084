import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';
import PaymentMethods from '../../../../../manifests/paymentMethods';

// assets
import { faArrowRight, faCalendar, faShip, faTrain, faTruck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../services/general';
import ErrorService from '../../../../../services/general/error';
import OutcomePaymentService from '../../../../../services/finance/outcomes/payments';

// props
import { IOutcomePaymentResourceShortProps } from '../../../../../props/finance/outcomes/payments';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import { IOutcomeOrderResourceShortProps } from '../../../../../props/finance/outcomes/orders';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import OrderService from '../../../../../services/orders/order';
import OutcomeInvoiceDetails from '../../../../finances/outcomes/invoices/general/details';

interface IOrderOutcomesListProps {
    orderId: string;
    qs?: string[];
    outcomes?: IOutcomeOrderResourceShortProps[];
    columns?: TOrderInvoicePaymentColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderInvoicePaymentColumn = 'name' | 'details' | 'notes' | 'amount' | 'status';

const OrderOutcomesList: React.FC<IOrderOutcomesListProps> = (props: IOrderOutcomesListProps) => {
    const shownColumns: TOrderInvoicePaymentColumn[] = props.columns || [
        'name',
        'details',
        'notes',
        'amount',
        'status'
    ];

    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [outcomes, setOutcomes] = React.useState<IOutcomeOrderResourceShortProps[]>(props.outcomes || []);
    const [activeOutcome, setActiveOutcome] = React.useState<IOutcomeOrderResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            minWidth: 250,
            maxWidth: 250,
            isMultiline: true,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveOutcome(item);
                        setActiveSurface('outcome.details');
                    }}><Text>{item.invoice?.name || "-"}</Text></Link>
                </Stack>
            }
        },
        {
            key: "details",
            name: "Details",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                const invoice = item.invoice;

                if (invoice) {
                    return <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                        {invoice.type === 'shipping' || invoice.type === 'train' || invoice.type === 'trucking' ? <>
                            <Stack>
                                <Tag>
                                    {invoice.ship ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <FontAwesomeIcon icon={faShip} fontSize={14} style={{ marginTop: 3 }} />
                                        <Stack>
                                            <Label size={'small'}>{invoice.ship.name}</Label>
                                            <Text size={'small'}>{invoice.ship.vendor.name}</Text>
                                        </Stack>
                                    </Stack> : null}
                                    {invoice.train ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <FontAwesomeIcon icon={faTrain} fontSize={14} style={{ marginTop: 3 }} />
                                        <Stack>
                                            <Label size={'small'}>{invoice.train.name}</Label>
                                            <Text size={'small'}>{invoice.train.vendor.name}</Text>
                                        </Stack>
                                    </Stack> : null}
                                    {invoice.truck ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <FontAwesomeIcon icon={faTruck} fontSize={14} style={{ marginTop: 3 }} />
                                        <Stack>
                                            <Label size={'small'}>{invoice.truck.name}</Label>
                                            <Text size={'small'}>{invoice.truck.vendor.name}</Text>
                                        </Stack>
                                    </Stack> : null}
                                </Tag>
                            </Stack>
                            <Stack>
                                {invoice.shipSchedule ? <Tag>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <FontAwesomeIcon icon={faCalendar} fontSize={14} style={{ marginTop: 3 }} />
                                        <Stack>
                                            <Label size={'small'}>Voy #{invoice.shipSchedule.voy}</Label>
                                            <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                                                <Stack>
                                                    <Text size={'small'}>{invoice.shipSchedule.originAddress}</Text>
                                                    {!invoice.shipSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.shipSchedule.estimatedDeparture).toDate())} (Est.)</Text> : null}
                                                    {invoice.shipSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.shipSchedule.actualDeparture).toDate())} (Actual)</Text> : null}
                                                </Stack>
                                                <FontAwesomeIcon icon={faArrowRight} />
                                                <Stack>
                                                    <Text size={'small'}>{invoice.shipSchedule.destinationAddress}</Text>
                                                    {!invoice.shipSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.shipSchedule.estimatedArrival).toDate())} (Est.)</Text> : null}
                                                    {invoice.shipSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.shipSchedule.actualArrival).toDate())} (Actual)</Text> : null}
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Tag> : null}
                                {invoice.trainSchedule ? <Tag>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <FontAwesomeIcon icon={faCalendar} fontSize={14} style={{ marginTop: 3 }} />
                                        <Stack>
                                            <Label size={'small'}>Voy #{invoice.trainSchedule.voy}</Label>
                                            <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                                                <Stack>
                                                    <Text size={'small'}>{invoice.trainSchedule.originAddress}</Text>
                                                    {!invoice.trainSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.trainSchedule.estimatedDeparture).toDate())} (Est.)</Text> : null}
                                                    {invoice.trainSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.trainSchedule.actualDeparture).toDate())} (Actual)</Text> : null}
                                                </Stack>
                                                <FontAwesomeIcon icon={faArrowRight} />
                                                <Stack>
                                                    <Text size={'small'}>{invoice.trainSchedule.destinationAddress}</Text>
                                                    {!invoice.trainSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.trainSchedule.estimatedArrival).toDate())} (Est.)</Text> : null}
                                                    {invoice.trainSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(invoice.trainSchedule.actualArrival).toDate())} (Actual)</Text> : null}
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Tag> : null}
                            </Stack>
                        </> : null}
                    </Stack>
                } else {
                    return null;
                }
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Amount",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOutcomeOrderResourceShortProps) => {
                let statusText = 'Unpaid';
                let statusVariant: TTagVariant = 'warning';

                if (item.invoice && item.invoice.status.toLowerCase() === 'cancelled') {
                    statusText = 'Cancelled';
                    statusVariant = 'error';
                } else if (item.unpaid <= 0) {
                    statusText = 'Paid';
                    statusVariant = 'success';
                }

                return <Stack horizontal>
                    <Tag text={statusText} variant={statusVariant} />
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.outcomes) {
            _onRetrieveOutcomes();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveOutcomes = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            const result = await OrderService.outcomes.retrieve(props.orderId);
            setOutcomes(result);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_outcomes_list_error',
                text: 'Failed to retrieve order outcomes list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton) {
            farItems.push({
                key: "register",
                text: "Create Payment",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Payment"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveOutcome(undefined);

        if (refresh) { _onRetrieveOutcomes() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && outcomes.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={outcomes.filter((outcome) => {
                                const search = (keyword || "").trim().toLowerCase();
                                return ((outcome.invoice?.method || "").toLowerCase()).indexOf(search) > -1 || ((outcome.invoice?.name || "").toLowerCase()).indexOf(search) > -1 || (outcome.notes || "").toLowerCase().indexOf(search) > -1
                            })}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderInvoicePaymentColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && outcomes.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text>Order outcome(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'outcome.details' && activeOutcome?.invoice ? <OutcomeInvoiceDetails outcomeId={activeOutcome.invoice.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrderOutcomesList;
