import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';
import PaymentMethods from '../../../../../../manifests/paymentMethods';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import ErrorService from '../../../../../../services/general/error';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';

// props
import { IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';

// components
import { ActionButton, IColumn, Link, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import Label from '../../../../../typography/label';
import PermissionsService from '../../../../../../services/permissions';
import { IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import OutcomePaymentCompleteForm from '../../../payments/general/form/complete';
import OutcomePaymentRejectForm from '../../../payments/general/form/reject';
import FilesListColumn from '../../../../../uiframeworks/files/listcolumn';

type OutcomeInvoicePaymentListProps = {
    invoice: IOutcomeResourceShortProps;
}

const OutcomeInvoicePaymentList: React.FC<OutcomeInvoicePaymentListProps> = (props: OutcomeInvoicePaymentListProps) => {
    const { banner, user } = useStore();
    const [payments, setPayments] = React.useState<IOutcomePaymentResourceShortProps[]>([]);
    const [activePayment, setActivePayment] = React.useState<IOutcomePaymentResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.payments.read.all'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "paymentDate",
            name: "Paid/Rejected On",
            minWidth: 120,
            maxWidth: 120,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {item.paidOn ? <Link onClick={() => {
                        setActivePayment(item);
                        setActiveSurface('details');
                    }}><Text>{moment(item.paidOn).format("DD/MM/YYYY")}</Text></Link> : <Text>-</Text>}
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                const menuItems = [];

                if (item.status === 'requested') {
                    if (PermissionsService.hasPermission(['outcomes.invoices.payments.approve'], user.permissions)) {
                        menuItems.push({
                            key: "complete", text: "Complete", iconProps: { iconName: "Check" }, onClick: () => {
                                setActivePayment(item);
                                setActiveSurface('complete');
                            }
                        });
                    }

                    if (PermissionsService.hasPermission(['outcomes.invoices.payments.reject'], user.permissions)) {
                        menuItems.push({
                            key: "reject", text: "Reject", iconProps: { iconName: "Xmark" }, onClick: () => {
                                setActivePayment(item);
                                setActiveSurface('reject');
                            }
                        });
                    }
                }

                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: "payment",
            name: "Paid To",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                const method = PaymentMethods.find((pm) => pm.key === item.method)
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{/*item.invoice.vendor?.name*/}</Text>
                    {item.invoice?.vendor ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 50, minWidth: 50 } }}><Text size={'small'}>Vendor</Text></Stack>
                        <Label size={'xsmall'}>:</Label>
                        <Label size={'xsmall'}>{item.invoice.vendor.name}</Label>
                    </Stack> : null}
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 50, minWidth: 50 } }}><Text size={'small'}>Method</Text></Stack>
                        <Label size={'xsmall'}>:</Label>
                        <Label size={'xsmall'}>{method?.text || 'Cash'}</Label>
                    </Stack>
                    {item.method === 'transfer' && item.targetBankAccount ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 50, minWidth: 50 } }}><Text size={'small'}>Acc. no.</Text></Stack>
                        <Label size={'xsmall'}>:</Label>
                        <Stack>
                            <Text size={'small'}>{item.targetBankAccount.bank.name} ({item.targetBankAccount.bankCity})</Text>
                            <Label size={'xsmall'}>{item.targetBankAccount.accountNumber}</Label>
                            <Text size={'xsmall'}>(a/n {item.targetBankAccount.accountName || item.vendor?.name})</Text>
                        </Stack>
                    </Stack> : null}
                    {item.method === 'virtualaccount' && item.targetVirtualAccountNumber ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{ root: { width: 50, minWidth: 50 } }}><Text size={'small'}>VA no.</Text></Stack>
                        <Label size={'xsmall'}>:</Label>
                        <Label size={'xsmall'}>{item.targetVirtualAccountNumber || "-"}</Label>
                    </Stack> : null}
                </Stack>
            }
        },
        {
            key: "dueDate",
            name: "Due Date",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                if (item.dueDate) {
                    const now = moment().startOf('day');
                    const target = moment(item.dueDate).startOf('day');
                    const diff = target.diff(now, 'day');

                    const tagText: string = GeneralService.getFriendlyDateFormat(item.dueDate);
                    let tagVariant: TTagVariant = 'success';
                    if (diff < 1) {
                        tagVariant = 'error';
                    } else if (diff > 2) {
                        tagVariant = 'success';
                    } else {
                        tagVariant = 'warning';
                    }

                    return <Stack horizontal>
                        <Tag variant={tagVariant} text={tagText} />
                    </Stack>
                } else {
                    return <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text>-</Text>
                    </Stack>
                }
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                let tagText: string = 'Requested';
                let tagVariant: TTagVariant = 'active';
                if (item.status === 'completed') {
                    tagText = 'Paid';
                    tagVariant = 'success';
                } else if (item.status === 'rejected') {
                    tagText = 'Rejected';
                    tagVariant = 'error';
                }

                return <Stack horizontal styles={{ root: { padding: '0px' } }}>
                    <Tag variant={tagVariant} text={tagText} />
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "totalAmount",
            name: "Total Amount",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount || "0"))}</Text>
                </Stack>
            }
        },
        {
            key: "files",
            name: "Proof of Payment",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <FilesListColumn files={item.documents || []} size={'medium'} loaded={true} />
            }
        },
    ];

    useEffect(() => {
        _onRetrievePayments();
    }, []);

    const _onRetrievePayments = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            const qs: string[] = [`invoice_id=${props.invoice.id}`, `top=all`];
            const result = await OutcomePaymentService.retrieve(qs.join("&"));

            setPayments(result.data);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcome_payments_list_error',
                text: 'Failed to retrieve invoice payments list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActivePayment(undefined);

        if (refresh) { _onRetrievePayments() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            <Stack tokens={{ childrenGap: 10 }}>
                {
                    !loaded || (loaded && payments.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={payments}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && payments.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>Payments not found</Text> : null}
            </Stack>
            {/*activeSurface === 'details' && activePayment ? <OutcomePaymentDetails paymentId={activePayment.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' && activePayment ? <DeleteOutcomePaymentForm payment={activePayment} onDismissed={_onSurfaceDismissed} /> : null*/}
            {activeSurface === 'complete' && activePayment ? <OutcomePaymentCompleteForm payments={[activePayment]} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'reject' && activePayment ? <OutcomePaymentRejectForm payments={[activePayment]} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default OutcomeInvoicePaymentList;
