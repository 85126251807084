import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import moment from 'moment';
import OutcomeTypes from '../../../../../../manifests/outcomeTypes';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import OutcomeService from '../../../../../../services/finance/outcomes';

// props
import { IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import ValidationService from '../../../../../../services/validation';

interface ICancelOutcomeInvoiceFormProps {
    invoice: IOutcomeResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    justification: string;
}

type FormDataErrorProps = {
    justification?: string;
}

const CancelOutcomeInvoiceForm: React.FC<ICancelOutcomeInvoiceFormProps> = (props: ICancelOutcomeInvoiceFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({justification: ""});
    const [error, setError] = React.useState<FormDataErrorProps>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append('justification', data.justification);

            await OutcomeService.cancel(props.invoice.id, fd);

            banner.add({
                key: 'cancel_outcome_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Invoice "${props.invoice.name}" cancelled successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const isSubmitButtonsDisabled = () => {
        if ((data.justification || "").trim() === "" || error.justification) {
            return true;
        }

        return false;
    }

    return <Panel headerText={"Cancel Invoice"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Cancel"} disabled={isSubmitButtonsDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Cancelling outcomes ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Preparing form ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Text size={'small'}>Are you sure you want to cancel invoice with details below?</Text>
                <Stack.Item>
                    <Label size={'xsmall'}>Outcome Details</Label>
                    <Text size={'small'}>{props.invoice.name}</Text>
                </Stack.Item>
                {props.invoice.invoiceNumber ? <Stack.Item>
                    <Label size={'xsmall'}>Invoice Number</Label>
                    <Text size={'small'}>{props.invoice.invoiceNumber}</Text>
                </Stack.Item> : null}
                <Stack.Item>
                    <Label size={'xsmall'}>Type</Label>
                    <Text size={'small'}>{OutcomeTypes.find((ot) => ot.key === props.invoice.type)?.text || "-"}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Amount</Label>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(props.invoice.amount))}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Due Date</Label>
                    <Text size={'small'}>{GeneralService.formatDate(moment(props.invoice.dueDate).toDate())}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Bank Account</Label>
                    <Text size={'small'}>{props.invoice.bankAccount ? `${props.invoice.bankAccount.accountName}` : null}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Notes</Label>
                    <Text size={'small'}>{props.invoice.notes || "-"}</Text>
                </Stack.Item>
                <Stack.Item>
                    <TextField label={'Justification'}
                        multiline
                        value={data.justification}
                        rows={3}
                        autoAdjustHeight
                        resizable={false}
                        required
                        errorMessage={error.justification}
                        onChange={(evt, value) => {
                            data.justification = value || "";
                            
                            const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 255});
                            error.justification = validation.message;

                            setData({...data});
                            setError({...error});
                        }} />
                </Stack.Item>
            </> : null}
        </Stack>
    </Panel>
};

export default CancelOutcomeInvoiceForm;
