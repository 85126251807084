import { IExternalBankAccountResourceProps, IExternalBankAccountResourceShortProps } from '../../../props/data/bankaccounts/external';
import Request from './../../request';

// import props

const ExternalBankAccountService = {
    retrieve: async (qs?: string): Promise<{data: IExternalBankAccountResourceShortProps[]}> => {
        try {
            const results = await Request.get(`/api/data/bankaccounts/external?${qs || ""}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IExternalBankAccountResourceProps> => {
        try {
            const result = await Request.post(`/api/data/bankaccounts/external`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (accountId: string, data: FormData): Promise<IExternalBankAccountResourceProps> => {
        try {
            const result = await Request.post(`/api/data/bankaccounts/external/${accountId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (id: string): Promise<IExternalBankAccountResourceProps> => {
        try {
            const result = await Request.get(`/api/data/bankaccounts/external/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    }
};

export default ExternalBankAccountService;