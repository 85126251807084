import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './styles/global.scss';
import './styles/form.scss';
import { initializeIcons } from '@uifabric/icons';
import { registerIcons, loadTheme } from '@uifabric/styling';
import '@fluentui/react/dist/css/fabric.css';
import 'antd/dist/antd.css';
import * as LucideIcons from 'lucide-react';

// import pages
import Page from './pages';
import Auth from './auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar, faCheck, faChevronDown, faDownload, faFile, faFolder, faImage, faLocationArrow, faPlus, faShip, faSquareInfo, faTrain, faTrash, faXmark } from '@fortawesome/pro-light-svg-icons';
import Banner from './components/uiframeworks/banner';
import ViewOfferCostPage from './pages/offers/cost';

// init icons
initializeIcons();

registerIcons({
  icons: {
    Page: <LucideIcons.File />,
    Home: <LucideIcons.Home />,
    Train: <FontAwesomeIcon icon={faTrain} />,
    Ship: <FontAwesomeIcon icon={faShip} />,
    Delete: <FontAwesomeIcon icon={faTrash} />,
    Calendar: <FontAwesomeIcon icon={faCalendar} />,
    People: <LucideIcons.Users />,
    Settings: <LucideIcons.Settings />,
    ShoppingCart: <LucideIcons.ShoppingCart />,
    WindDirection: <FontAwesomeIcon icon={faLocationArrow} />,
    ArrowRight: <FontAwesomeIcon icon={faArrowRight} />,
    Download: <FontAwesomeIcon icon={faDownload} />,
    Info: <FontAwesomeIcon icon={faSquareInfo} />,
    Xmark: <FontAwesomeIcon icon={faXmark} />,
    Image: <FontAwesomeIcon icon={faImage} />,
    ChevronDown: <FontAwesomeIcon icon={faChevronDown} />,
    Plus: <FontAwesomeIcon icon={faPlus} />,
    Folder: <FontAwesomeIcon icon={faFolder} />,
    File: <FontAwesomeIcon icon={faFile} />,
    Check: <FontAwesomeIcon icon={faCheck} />,
  },
});

// update theme
loadTheme({
  palette: {
    themePrimary: '#e48825',
    themeLighterAlt: '#fefaf5',
    themeLighter: '#fbead9',
    themeLight: '#f7d9b8',
    themeTertiary: '#eeb476',
    themeSecondary: '#e6943c',
    themeDarkAlt: '#cc7921',
    themeDark: '#ac661c',
    themeDarker: '#7f4b14',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
});

function App() {
  return <>
    <Banner />
    <Router>
      <Switch>
        {/* print pages */}
        <Route exact path='/print/offers/:offerId/costs'><ViewOfferCostPage /></Route>

        {/* other pages */}
        <Route exact path='/auth/:page' render={(props) => <Auth {...props} />} />
        <Route exact path='/*' render={(props) => <Page {...props} />} />
      </Switch>
    </Router>
  </>
}

export default App;
