import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../services/orders/order';

// props
import { IOrderResourceProps, IOrderResourcerShorterProps } from '../../../../props/orders/order';

// components
import { ActionButton, CommandBar, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import Label from '../../../typography/label';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import GeneralService from '../../../../services/general';
import ErrorService from '../../../../services/general/error';

type NewOrdersCardProps = {
    style?: any;
    grow?: number | boolean | "inherit" | "initial" | "unset" | undefined;
    variant?: 'primary' | 'default';
}

const NewOrdersCard: React.FC<NewOrdersCardProps> = (props: NewOrdersCardProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [currentOrders, setCurrentOrders] = React.useState<IOrderResourcerShorterProps[]>([]);
    const [previousOrders, setPreviousOrders] = React.useState<IOrderResourcerShorterProps[]>([]);

    useEffect(() => {
        _onRetrieveOrders();
    }, []);

    const _onRetrieveOrders = async () => {
        try {
            const qs: string[] = [];
            qs.push(`top=all`);
            qs.push(`start_date=${moment().startOf('week').add(-1, 'week').toISOString()}`);
            qs.push(`end_date=${moment().endOf('week').toISOString()}`);

            const _orders = await OrderService.retrieve(qs.join('&'));
            const _currentOrders = _orders.data.filter((order) => {
                return moment(order.createdAt).isSameOrAfter(moment().startOf('week'));
            });
            const _previousOrders = _orders.data.filter((order) => {
                return moment(order.createdAt).isBefore(moment().startOf('week'));
            });

            setCurrentOrders(_currentOrders);
            setPreviousOrders(_previousOrders);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_new_orders_error',
                text: 'Failed to retrieve new orders. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getComparisonText = () => {
        const diff = currentOrders.length - previousOrders.length;
        if (diff !== 0) {
        return <Text size={'small'} className={diff > 0 ? styles.success : styles.error}>{diff} orders than last week</Text>
        } else {
            return <Text size={'small'}>same as last week</Text>
        }
    }

    return <Stack grow={props.grow} tokens={{ childrenGap: 20 }} className={`${styles.card} ${styles.variant}`} style={props.style}>
        <Stack>
            <Label size={'small'}>New orders</Label>
            <Text size={'xsmall'}>(This week)</Text>
        </Stack>
        <Stack>
            {!loaded ? <LoadingComponent labelPosition='right' label={'Retrieving ...'} spinnerPosition={'baseline'} size={SpinnerSize.small} /> : null}
            {loaded ? <>
                <Label size={'large'}>{currentOrders.length}</Label>
                {getComparisonText()}
            </> : null}
        </Stack>
    </Stack>;
};

export default NewOrdersCard;
