import { FormDataProps } from ".";

type FormStepProps = {
	key: string;
	title: string;
	validate(data: FormDataProps): string[];
}

const OfferFormSteps: FormStepProps[] = [
    {
        key: 'customerDetails',
        title: 'Customer & Order Details',
        validate: (_data: FormDataProps) => {
            const errors = [];

            if (!_data.orderDetails?.subsidiary) {
                errors.push("Subsidiary is required");
            }

            if (!_data.orderDetails?.sales) {
                errors.push("Sales is required");
            }

            if (!_data.customerDetails?.company) {
                errors.push("Company is required");
            }

            if (!_data.customerDetails?.customer) {
                errors.push("Customer is required");
            }

            if ((_data.customerDetails?.name || "").trim() === "") {
                errors.push("Customer name is required");
            }

            return errors;
        }
    },
    {
        key: 'freightDetails',
        title: 'Freight Details',
        validate: (_data: FormDataProps) => {
            const errors = [];

            if (!_data.freightDetails?.deliveryType) {
                errors.push("Delivery type is required");
            }

            if (!_data.freightDetails?.freightCategory) {
                errors.push("Freight category is required");
            }

            if (!_data.freightDetails?.goodsType) {
                errors.push("Goods type is required");
            }

            if ((_data.addressDetails?.originAddresses || []).filter((address) => (address.address || "").trim() !== "").length < 1) {
                errors.push("Origin address is required");
            } else {
                (_data.addressDetails?.originAddresses || []).filter((address) => (address.address || "").trim() !== "").forEach((address) => {
                    if ((address.type || "").trim() === "") {
                        errors.push(`Address "${address.address}" type is required`);
                    }

                    if ((address.province || "").trim() === "") {
                        errors.push(`Address "${address.address}" province is required`);
                    }

                    if ((address.city || "").trim() === "") {
                        errors.push(`Address "${address.address}" city is required`);
                    }
                })
            }

            if ((_data.addressDetails?.destinationAddresses || []).filter((address) => (address.address || "").trim() !== "").length < 1) {
                errors.push("Destination address is required");
            } else {
                (_data.addressDetails?.destinationAddresses || []).filter((address) => (address.address || "").trim() !== "").forEach((address) => {
                    if ((address.type || "").trim() === "") {
                        errors.push(`Address "${address.address}" type is required`);
                    }

                    if ((address.province || "").trim() === "") {
                        errors.push(`Address "${address.address}" province is required`);
                    }

                    if ((address.city || "").trim() === "") {
                        errors.push(`Address "${address.address}" city is required`);
                    }
                })
            }

            return errors;
        }
    },
    {
        key: 'costing',
        title: 'Costing',
        validate: (_data: FormDataProps) => {
            const errors: string[] = [];

            if (_data.includedCosts?.originTrucking) {
                if ((_data.originTruckingCost || []).length < 1) {
                    errors.push("Trucking at origin is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.destinationTrucking) {
                if ((_data.destinationTruckingCost || []).length < 1) {
                    errors.push("Trucking at destination is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.loadingLabour) {
                if ((_data.loadingLabourCost || []).length < 1) {
                    errors.push("Loading labour is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.unloadingLabour) {
                if ((_data.unloadingLabourCost || []).length < 1) {
                    errors.push("Unloading labour is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.containerShipping) {
                if ((_data.containerShippingCost || []).length < 1) {
                    errors.push("Container shipping is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.charteredShip) {
                if ((_data.charteredShipCost || []).length < 1) {
                    errors.push("Chartered ship is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.additionalCosts) {
                if ((_data.additionalCosts || []).length < 1) {
                    errors.push("Additional cost is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.insuranceCost) {
                if ((_data.insuranceCost || []).length < 1) {
                    errors.push("Insurance is included, but no cost is added");
                }
            }

            if (_data.includedCosts?.ppn) {
                if ((_data.ppnCost?.percentage || "").trim() === "") {
                    errors.push("PPn percentage is required");
                }
            }

            if (_data.includedCosts?.pph) {
                if ((_data.pphCost?.percentage || "").trim() === "") {
                    errors.push("PPh percentage is required");
                }
            }

            return errors;
        }
    },
    {
        key: 'offerDetails',
        title: 'Offer Details',
        validate: (_data: FormDataProps) => {
            const errors = [];

            if ((_data.offeredPrice?.items || []).length < 1) {
                errors.push("Offered price is required");
            }

            return errors;
        }
    },
    {
        key: 'summary',
        title: 'Summary',
        validate: (_data: FormDataProps) => {
            return [];
        }
    }
];

export default OfferFormSteps;