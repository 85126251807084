import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import ShipsService from '../../../../../services/data/ships';

// props
import { IShipResourceShortProps } from '../../../../../props/data/ships';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import Tag from '../../../../uiframeworks/tag';
import ShipForm from '../form';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';

interface IShipsListProps {
}

const ShipsList: React.FC<IShipsListProps> = (props: IShipsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [ships, setShips] = React.useState<IShipResourceShortProps[]>([]);
    const [activeShip, setActiveShip] = React.useState<IShipResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['ships.read.all'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IShipResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/operationals/ships/${item.id}`}><Text>{item.name}</Text></NavLink>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IShipResourceShortProps) => {
                let menuItems: IContextualMenuItem[] = []

                if (PermissionsService.hasPermission(['ships.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveShip(item);
                            setActiveSurface('update');
                        }
                    });
                }

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: 'registrationNumber',
            fieldName: 'registrationNumber',
            name: 'Registration Number',
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IShipResourceShortProps) => {
                return (
                    <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text>{item.registrationNumber}</Text>
                    </Stack>
                );
            },
        },
        {
            key: 'vendor',
            fieldName: 'vendor',
            name: 'Vendor',
            minWidth: 200,
            onRender: (item: IShipResourceShortProps) => {
                return (
                    <Stack horizontal styles={{ root: { padding: '4px 0px' } }}>
                        {item.vendor ? <Tag text={item.vendor.name} /> : null}
                    </Stack>
                );
            },
        },
        {
            key: 'fleetType',
            name: 'Type',
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IShipResourceShortProps) => {
                return (
                    <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text>{item.fleetType?.name || "-"}</Text>
                    </Stack>
                );
            },
        }
    ];

    useEffect(() => {
        _onRetrieveShips();
    }, [keyword]);

    const _onRetrieveShips = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await ShipsService.retrieve(qs.join("&"));
            setShips(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_ships_error',
                text: `Failed to retrieve ships list. Error: ` + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['ships.read.all'], user.permissions)) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (PermissionsService.hasPermission(['ships.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Ship",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Ship"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveShip(undefined);

        if (refresh) { _onRetrieveShips() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 10 } }}>
        {hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && ships.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={ships}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && ships.length < 1 ? <Text>Ships not found</Text> : null}
            </Stack>
            {activeSurface === 'create' ? <ShipForm onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'update' && activeShip ? <ShipForm shipId={activeShip.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default ShipsList;
