import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../stores/root';
import { useParams } from 'react-router-dom';
import OrderDetails from '../../components/orders/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IOrderDetailsPageProps {
}

const OrderDetailsPage: React.FC<IOrderDetailsPageProps> = (props: IOrderDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Orders", [
            {key: "home", icon: faHome, href: "/"},
            {key: "orders", text: 'Orders'}
        ]);
    }, []);

    return <Stack>
        <OrderDetails orderId={params.orderId} />
    </Stack>;
};

export default OrderDetailsPage;
