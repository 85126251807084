import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../../stores/root';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import moment from 'moment';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../../services/validation';
import GeneralService from '../../../../../../../services/general';
import IncomePaymentService from '../../../../../../../services/finance/incomes/payments/payment';

// props
import { IOrderResourceShortProps } from '../../../../../../../props/orders/order';
import { IBankAccountResourceShortProps } from '../../../../../../../props/general/bankAccount';

// components
import { DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';
import ErrorService from '../../../../../../../services/general/error';
import Label from '../../../../../../typography/label';
import Text from '../../../../../../typography/text';
import UploadFilesComponent from '../../../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../../../props/general';
import { IIncomePaymentResourceShortProps } from '../../../../../../../props/finance/incomes/payments/payment';
import FilesList from '../../../../../../uiframeworks/files/list';
import { IOutcomeSalaryPaymentResourceShortProps } from '../../../../../../../props/finance/outcomes/salaries';
import { OutcomeSalaryPaymentService } from '../../../../../../../services/finance/outcomes/salaries';

interface IRejectInvoicePaymentFormProps {
  paymentId: string;
  salaryId: string
  onDismissed(refresh?: boolean): void;
}
type FormDataProps = {
  method: string;
  amount: string;
  paymentDate: string;
  notes?: string;
  reason: string;
}

type FormDataErrorProps = {
  method?: string;
  amount?: string;
  paymentDate?: string;
  notes?: string;
  reason?: string;
}

const RejectInvoicePaymentForm: React.FC<IRejectInvoicePaymentFormProps> = (props: IRejectInvoicePaymentFormProps) => {
  const { banner } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<IOutcomeSalaryPaymentResourceShortProps>();
  const [deleteFileIds, setDeleteFileIds] = useState<string[]>([])
  const [files, setFiles] = useState<IFileDetailsProps[]>([])
  const [data, setData] = React.useState<FormDataProps>({
    reason: "",
    method: "cash",
    amount: "",
    paymentDate: moment().toISOString(),
    notes: ""
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const _payment = await OutcomeSalaryPaymentService.read(props.salaryId, props.paymentId);

      setData({
        method: _payment.paymentMethod,
        amount: _payment.amount,
        paymentDate: _payment.processedAt,
        reason: _payment.justification || ''
      })
      setPayment(_payment);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_payment_error',
        variant: 'error',
        icon: faXmarkCircle,
        text: `Failed to get invoice "${IncomeInvoiceService.getInvoiceNumber(props.paymentId)}". Error: ${ErrorService.getMessage(e)}`
      });
      props.onDismissed();
    }
  }

  const isSubmitButtonDisabled = (): boolean => {
    return false;
  }

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      const { reason } = data;

      // create form data
      let fd = new FormData();
      reason && fd.append("verificationReason", reason);
      fd.append("verificationStatus", 'rejected');

      await OutcomeSalaryPaymentService.verify(props.salaryId, props.paymentId, fd);

      banner.add({
        key: 'create_income_payment_success',
        variant: 'success',
        icon: faCheck,
        text: `Payment  recorded successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  return <Panel headerText={'Reject Payment'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Reject"} disabled={isSubmitButtonDisabled()} onClick={() => _onSubmit()} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }}>
      {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
      {
        loaded && payment ? <>
          <Stack.Item>
            <Dropdown label={"Method"}
              required={true}
              disabled
              selectedKey={data.method}
              options={PaymentMethods}
              onChange={(evt, opt) => {
                data.method = opt ? opt.key as string : "";
                setData({ ...data });
              }} />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Amount"}
              value={data.amount}
              required={true}
              prefix={'Rp'}
              errorMessage={error.amount}
              disabled />
          </Stack.Item>
          <Stack.Item>
            <DatePicker label={"Payment Date"}
              isRequired={true}
              value={moment(data.paymentDate).toDate()}
              formatDate={GeneralService.formatDate}
              disabled />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Reason"}
              required={true}
              value={data.reason}
              onChange={(evt, value) => {
                data.reason = value || "";

                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                error.reason = validation.message;

                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.reason}
              disabled={submitting}
              multiline
              rows={5}
            />
          </Stack.Item>
          <Stack styles={{ root: { maxHeight: 600 } }}>
            <Label>Document(s)</Label>
            <FilesList files={payment.documents || []}
              loaded={loaded}
              mode='list'
              documentTypes={[
                { key: "document", text: "Document" }
              ]} />
          </Stack>
        </> : null
      }
    </Stack>
  </Panel>
};

export default RejectInvoicePaymentForm;