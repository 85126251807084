import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faContainerStorage, faShip, faTruck } from "@fortawesome/pro-light-svg-icons";

export interface IDeliveredGoodsTypeResourceShortProps {
    key: string;
    text: string;
    disabled?: boolean;
    icon?: IconProp;
}

const DeliveredGoodsType: IDeliveredGoodsTypeResourceShortProps[] = [
    {key: "fcl", text: "Full Container Load (FCL)", icon: faContainerStorage},
    {key: "lcl", text: "Less Container Load (LCL)", disabled: true, icon: faContainerStorage},
    {key: "ftl", text: "Full Truck Load (FTL)", icon: faTruck},
    {key: "ltl", text: "Less Truck Load (LTL)", icon: faTruck, disabled: true},
    {key: "bulk", text: "Breakbulk", icon: faShip}
];

export default DeliveredGoodsType;