import { IPaginationResourceShort } from '../../props/general';
import { IUserResourceShortProps } from '../../props/user';
import { ICompanyResource, ICompanyResourceShort } from './../../props/users/company';
import Request from './../request';

const CompanyService = {
  retrieve: async (qs?: string): Promise<{data: ICompanyResourceShort[], pagination: IPaginationResourceShort}> => {
    try {
      const results = await Request.get(`/api/companies?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ICompanyResource> => {
    try {
      const results = await Request.get(`/api/companies/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ICompanyResourceShort> => {
    try {
      const result = await Request.post(`/api/companies`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ICompanyResourceShort> => {
    try {
      const result = await Request.post(`/api/companies/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/companies/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  getUsers: async (id: string, qs?: string): Promise<IUserResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/companies/${id}/users?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default CompanyService;
