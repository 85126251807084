import Request from './../request';

// import props
import {
  IOrderContainerResourceCompleteProps,
  IOrderContainerResourceProps,
  IOrderContainerResourceShortProps,
} from '../../props/orders/containers';
import { IOrderResourceShortProps } from '../../props/orders/order';
import { IOrderTrackingResourceShortProps } from '../../props/orders/trackings/orderTracking';
import { IOrderTrackingShippingDetailsResourceShorProps } from '../../props/orders/trackings/details/shipping/shippingDetails';
import moment from 'moment';
// import templatePDF from 'pdf-templater';

const OrderContainerService = {
  new: async (orderId: string, data: FormData): Promise<IOrderContainerResourceShortProps> => {
    try {
      const result = await Request.post(`/api/orders/${orderId}/containers`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (
    orderId: string,
    containerId: string,
    data: FormData
  ): Promise<IOrderContainerResourceProps> => {
    try {
      const result = await Request.post(
        `/api/orders/${orderId}/containers/${containerId}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return result;
    } catch (e) {
      throw e;
    }
  },
  get: async (orderId: string, containerId: string): Promise<IOrderContainerResourceCompleteProps> => {
    try {
      const result = await Request.get(`/api/orders/${orderId}/containers/${containerId}`);
      return result;
    } catch (e) {
      throw e;
    }
  },
  retrieve: async (orderId: string, qs?: string): Promise<IOrderContainerResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/orders/${orderId}/containers?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (orderId: string, containerId: string): Promise<void> => {
    try {
      await Request.delete(`/api/orders/${orderId}/containers/${containerId}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  getShipDetails: (trackings: IOrderTrackingResourceShortProps[], container: IOrderContainerResourceShortProps): IOrderTrackingShippingDetailsResourceShorProps | undefined => {
    // check if there are any tracking related specifically for this container
    let relatedTracking = trackings.find((track) => track.type === 'ship' && track.containerId === container.id);
    if (!relatedTracking) {
      // if no tracking specifically related to this container, get the global
      relatedTracking = trackings.find((track) => track.type === 'ship');
    }

    return relatedTracking?.shippingDetails;
  },
  getTotalPrice: (containers: Partial<IOrderContainerResourceShortProps>[]): string => {
    try {
      let price = 0;
      containers.forEach((container) => {
        price += parseInt(container.price || '0');
      });
      return price + '';
    } catch (e) {
      return '0';
    }
  },
  downloadPackingList: async (containerId: string, format?: string, name?: string) => {
    try {
      const result = await Request.download(`/api/container/${containerId}/packing_list/download?format=${format ?? 'docx'}`);

      const data = new Blob([result.data], { type: result.headers["content-type"] });
      const url = window.URL.createObjectURL(data);
      const linkTag = document.createElement('a');
      linkTag.href = url;
      linkTag.setAttribute('download', `${"PackingList " + (name ?? moment().format("DDMMYYYY"))}.${format ?? 'docx'}`);
      linkTag.click();

      return result;
    } catch (e) {
      throw (e);
    }
  },
  documents: {
    upload: async (orderId: string, containerId: string, data: FormData) => {
      try {
        const result = await Request.post(
          `/api/orders/${orderId}/containers/${containerId}/documents`,
          data,
          'multipart/form-data'
        );
        return result;
      } catch (e) {
        throw e;
      }
    },
    delete: async (orderId: string, containerId: string, basename: string): Promise<void> => {
      try {
        await Request.delete(`/api/orders/${orderId}/containers/${containerId}/documents/${basename}`);
        return;
      } catch (e) {
        throw e;
      }
    },
  },
  bl: {
    upload: async (orderId: string, containerId: string, data: FormData) => {
      try {
        const result = await Request.post(
          `/api/orders/${orderId}/containers/${containerId}/bl`,
          data,
          'multipart/form-data'
        );
        return result;
      } catch (e) {
        throw e;
      }
    },
    delete: async (orderId: string, containerId: string): Promise<void> => {
      try {
        await Request.delete(`/api/orders/${orderId}/containers/${containerId}/bl`);
        return;
      } catch (e) {
        throw e;
      }
    },
  },
};

export default OrderContainerService;

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
