import React from 'react';

// import styles
import parentStyles from './../style.module.scss';

// import router
import { RouteComponentProps } from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../../props/user';

// import services

// import pages

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { DefaultButton } from '@fluentui/react';

interface ILogoutProps {
    user?: IUserResourceShortProps;
    route: RouteComponentProps;
}

interface ILogoutState {
}

export default class Logout extends React.Component<ILogoutProps, ILogoutState> {
    constructor(props: ILogoutProps) {
        super(props);
        this.state = {}
    }

    public render() {
        const { user } = this.props;

        return (
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack className={parentStyles.header}>
                    <Text className={parentStyles.text} as={"h2"} variant={"medium"}>Lupa Password</Text>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Reset Password Tidak Tersedia</Text>
                    <Text style={{ textAlign: "center" }}>Maaf, kami tidak bisa membantu reset password anda melalui sistem. Untuk merest password, anda harus menghubungi manager anda atau kontak tim terkait untuk membantu anda mereset password melalui portal administrasi.</Text>
                </Stack>
                <Stack horizontalAlign={"center"}>
                    <DefaultButton href={"/auth/login"} text={"Kembali ke Halaman Login"} />
                </Stack>
            </Stack>
        );
    }
}