import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import OrderStatusList from '../../../components/settings/orderStatus/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IOrderStatusListPageProps {
}

const OrderStatusListPage: React.FC<IOrderStatusListPageProps> = (props: IOrderStatusListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Order Status", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "orderStatus", text: 'Order Status'}
        ])
    }, []);

    return <Stack>
        <OrderStatusList />
    </Stack>;
};

export default OrderStatusListPage;
