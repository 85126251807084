import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';
import CompanyService from '../../../../services/users/company';
import GeneralService from '../../../../services/general';
import FreightCategoryService from '../../../../services/orders/freightCategory';
import DeliveryTypeService from '../../../../services/orders/deliveryType';
import OrderService from '../../../../services/orders/order';
import ValidationService from '../../../../services/validation';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';
import { IDeliveryTypeResourceShortProps } from '../../../../props/deliveryType';
import { IFreightCategoryResourceShortProps } from '../../../../props/freightCategories';
import { IUserResourceShortProps } from '../../../../props/users/user';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';

// components
import { ActionButton, DefaultButton, Dropdown, IDropdownOption, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../services/data/subsidiaries';
import ISalesTeamResourceShort from '../../../../props/users/team';
import TeamService from '../../../../services/users/team';
import { IOrderResourceProps } from '../../../../props/orders/order';
import SalesTeamService from '../../../../services/users/team';

interface IOrderSalesTeamFormProps {
    order: IOrderResourceProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    salesTeam?: ISalesTeamResourceShort;
}

type FormDataErrorProps = {
}

const OrderSalesTeamForm: React.FC<IOrderSalesTeamFormProps> = (props: IOrderSalesTeamFormProps) => {
    const { banner, route } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [freightCategories, setFreightCategories] = React.useState<IFreightCategoryResourceShortProps[]>([]);
    const [deliveryTypes, setDeliveryTypes] = React.useState<IDeliveryTypeResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        salesTeam: props.order.salesTeam
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const {salesTeam} = data;

            // create form data
            let fd = new FormData();
            fd.append('salesTeamId', salesTeam?.id || "");
            await OrderService.update(props.order.id, fd);

            banner.add({
                key: 'update_order_sales_team_success',
                variant: 'success',
                icon: faCheck,
                text: `Order "${OrderService.getOrderNumber(props.order.orderNumber)}" sales team updated successfully`
            });

            props.onDismissed(true);
        } catch (e) {
            banner.add({
                key: 'update_order_sales_team_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to update order sales team. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    const _onSearchSalesTeams = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const data = (await SalesTeamService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));
            return data;
        } catch (e) {
            banner.add({
                key: 'search_sales_error',
                text: 'Failed to search related sales. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }

    return <Panel headerText={"Update Order Sales Team"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating order sales team ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Stack tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Label>Sales Team</Label>
                                {!data.salesTeam ? <TagPicker
                                    selectedItems={[]}
                                    removeButtonAriaLabel='Remove'
                                    itemLimit={1}
                                    onItemSelected={(item: any) => {
                                        data.salesTeam = item?.metadata;
                                        setData({ ...data });

                                        return null;
                                    }}
                                    onResolveSuggestions={_onSearchSalesTeams}
                                    onEmptyResolveSuggestions={() => _onSearchSalesTeams('')}
                                    disabled={submitting}
                                /> : null}
                                {data.salesTeam ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                    <Stack>
                                        <Label size={'small'}>{data.salesTeam.name}</Label>
                                    </Stack>
                                    <ActionButton disabled={submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                        data.salesTeam = undefined;
                                        setData({ ...data });
                                    }} />
                                </Stack> : null}
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderSalesTeamForm;
