/*** src/Store.js ***/

import { makeObservable, observable, action } from "mobx";
import RootStore from './root';
import { RouteComponentProps } from "react-router-dom";

export class RouteStore {
    private rootStore: RootStore;
    public route: RouteComponentProps | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            route: observable,
            setRoute: action.bound
        });
    }

    public setRoute = (route: RouteComponentProps) => {
        this.route = route;
    }
}