
import React, { } from 'react';

// import props

// import subpages

// import components

// import fabric ui component
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './styles.module.scss';
import { Stack } from '@fluentui/react';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Label from '../../../typography/label';
import Text from '../../../typography/text';

interface PivotButtonProps {
    buttons: IButtonProps[];
}

export interface IButtonProps {
    variant?: 'primary' | 'default' | 'light';
    key?: string;
    icon?: IconProp;
    description?: string;
    text: string;
    onClick?(): void;
    link?: string;
    width?: string;
    active?: boolean;
}

const PivotButton: React.FC<PivotButtonProps> = (props) => {
    return <Stack horizontal>
        <Stack horizontal className={styles.pivot}>
            {props.buttons.map((button, idx) => {
                const body = <Stack key={button.key || idx} horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                    {button.icon ? <FontAwesomeIcon icon={button.icon} fontSize={30} /> : null}
                    <Text size={'small'}>{button.text}</Text>
                </Stack>;

                return <Stack className={`${styles.button} ${button.active ? styles.active : ''}`} styles={{ root: { width: button.width } }} onClick={button.onClick}>
                    {!button.onClick && button.link ? <NavLink to={button.link || "#"}>{body}</NavLink> : body}
                </Stack>
            })}
        </Stack>
    </Stack>
}

export default PivotButton;