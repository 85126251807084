import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import QuantityUnitService from '../../../../../services/general/quantityUnit';

// props
import { IQuantityUnitResourceShortProps } from '../../../../../props/general/quantityUnit';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';

interface IQuantityUnitFormProps {
    quantityUnit?: IQuantityUnitResourceShortProps;
    onDismissed(refresh?: boolean, data?: IQuantityUnitResourceShortProps): void;
}

type FormDataProps = {
    name: string;
}

type FormDataErrorProps = {
    name?: string;
}

const QuantityUnitForm: React.FC<IQuantityUnitFormProps> = (props: IQuantityUnitFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.quantityUnit === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.quantityUnit) {
            setData({
                name: props.quantityUnit.name
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name) {
            return true;
        } else if (data.name === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);

            let result;
            if (props.quantityUnit === undefined) {
                result = await QuantityUnitService.new(fd);
            } else {
                result = await QuantityUnitService.update(props.quantityUnit.id, fd);
            }
            banner.add({
                key: 'create_quantity_unit_success',
                variant: 'success',
                icon: faCheck,
                text: `Quantity unit "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Quantity Unit' : 'Update Quantity Unit'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating quantity unit ..." : "Updating quantity unit ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.name = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default QuantityUnitForm;
