import React from 'react';
import { NavLink } from 'react-router-dom';

// assets

// services

// props
import { IOrderResourceProps } from '../../../../../props/orders/order';
import { IOrderSummaryResourceShortProps } from '../../../../../props/orders/summary';

// components
import { Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import GeneralService from '../../../../../services/general';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/root';

interface OrderDetailsSalesProps {
    order: IOrderResourceProps;
}

const OrderDetailsSales: React.FC<OrderDetailsSalesProps> = observer((props: OrderDetailsSalesProps) => {
    const { user } = useStore();
    const sales = (props.order.sales || []);

    return <Stack tokens={{ childrenGap: 10 }}>
        {sales.length < 1 ? <Text style={{ fontStyle: 'italic' }}>No sales selected</Text> : null}
        {sales.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
            {(sales || []).map((s) => {
                return <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign={'space-between'}>
                    <NavLink to={"/users/administrators/" + s.sales.id}><Text size={'small'}>{s.sales.name}</Text></NavLink>
                    {Number(s.feeAmount) > 0 ? <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(s.feeAmount))}</Text> : null}
                    {Number(s.feePercentage) > 0 ? <Text size={'small'}>{GeneralService.getNumberWithSeparator(Number(s.feePercentage))}%</Text> : null}
                </Stack>
            })}
        </Stack> : null}
    </Stack>
});

export default OrderDetailsSales;
