import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import { NavLink } from 'react-router-dom';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import TrucksService from '../../../../../services/data/trucks';
import ErrorService from '../../../../../services/general/error';

// props
import { ITruckResourceShort } from '../../../../../props/data/trucks';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import PermissionsService from '../../../../../services/permissions';
import TruckForm from '../../../trucks/general/form';

interface IVendorTrucksListProps {
    vendorId: string;
}

const VendorTrucksList: React.FC<IVendorTrucksListProps> = (props: IVendorTrucksListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [trucks, setTrucks] = React.useState<ITruckResourceShort[]>([]);
    const [activeTruck, setActiveTruck] = React.useState<ITruckResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "registrationNumber",
            name: "Registration Number",
            fieldName: "registrationNumber",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: ITruckResourceShort) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/operationals/trucks/${item.id}`}><Text>{item.registrationNumber}</Text></NavLink>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ITruckResourceShort) => {
                let menuItems: IContextualMenuItem[] = []

                if (PermissionsService.hasPermission(['trucks.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveTruck(item);
                            setActiveSurface('update');
                        }
                    });
                }

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: 'fleetType',
            name: 'Type',
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: ITruckResourceShort) => {
                return (
                    <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text>{item.fleetType?.name || "-"}</Text>
                    </Stack>
                );
            },
        }
    ];

    const hasPermission = PermissionsService.hasPermission(['trucks.read.all'], user.permissions);

    useEffect(() => {
        _onRetrieveTrucks();
    }, [keyword]);

    const _onRetrieveTrucks = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = [];
            qs.push(`vendor_id=${props.vendorId}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await TrucksService.retrieve(qs.join("&"));
            setTrucks(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_vendor_trucks_error',
                text: `Failed to retrieve vendor's trucks list. Error: ` + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        if (PermissionsService.hasPermission(['trucks.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create truck",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create truck"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveTruck(undefined);

        if (refresh) { _onRetrieveTrucks() }
    }

    return <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 10 } }}>
        {hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack>
                {
                    !loaded || (loaded && trucks.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={trucks}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && trucks.length < 1 ? <Text>Truck(s) not found</Text> : null}
            </Stack>
            {activeSurface === 'create' ? <TruckForm vendorId={props.vendorId} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'update' && activeTruck ? <TruckForm vendorId={props.vendorId} truckId={activeTruck.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default VendorTrucksList;
