import { ISystemFleetTypeResourceShortProps } from '../../props/systems/fleetTypes';
import Request from './../request';

const SystemFleetTypeService = {
  retrieve: async (qs?: string): Promise<ISystemFleetTypeResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/systems/fleet_types?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  }
}

export default SystemFleetTypeService;