import Request from './../request';

// import props
import { IQuantityUnitResourceShortProps } from './../../props/general/quantityUnit';
import { IBankAccountResourceShortProps, IVendorBankAccountResourceShortProps } from '../../props/general/bankAccount';

const BankAccountService = {
    retrieve: async (qs?: string): Promise<IBankAccountResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/bankAccounts`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IBankAccountResourceShortProps> => {
        try {
            const result = await Request.post(`/api/bankAccounts`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (accountId: string, data: FormData): Promise<IBankAccountResourceShortProps> => {
        try {
            const result = await Request.post(`/api/bankAccounts/${accountId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (id: string): Promise<IBankAccountResourceShortProps> => {
        try {
            const result = await Request.get(`/api/bankAccounts/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    }
};

const VendorBankAccountService = {
    retrieve: async (vendorId: string, qs?: string): Promise<IVendorBankAccountResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/vendors/${vendorId}/bankAccounts`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (vendorId: string, data: FormData): Promise<IVendorBankAccountResourceShortProps> => {
        try {
            const result = await Request.post(`/api/vendors/${vendorId}/bankAccounts`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (vendorId: string, accountId: string, data: FormData): Promise<IVendorBankAccountResourceShortProps> => {
        try {
            const result = await Request.post(`/api/vendors/${vendorId}/bankAccounts/${accountId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (vendorId: string, id: string): Promise<IVendorBankAccountResourceShortProps> => {
        try {
            const result = await Request.get(`/api/vendors/${vendorId}/bankAccounts/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (vendorId: string, id: string): Promise<IVendorBankAccountResourceShortProps> => {
        try {
            const result = await Request.delete(`/api/vendors/${vendorId}/bankAccounts/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    }
}

export { VendorBankAccountService }

export default BankAccountService;