import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../../../services/orders/order';
import OrderStatusService from '../../../../../../services/orders/orderStatus';

// props
import { IOrderResourceProps } from '../../../../../../props/orders/order';

// components
import { DefaultButton, Dropdown, Panel, PanelType, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import { IVersionHistoryResourceShortProps } from '../../../../../../props/general/versionHistory';
import GeneralService from '../../../../../../services/general';
import moment from 'moment';
import DeliveredGoodsType from '../../../../../../manifests/deliveredGoodsType';
import Tag from '../../../../../uiframeworks/tag';
import OrderInvoiceService from '../../../../../../services/orders/invoices/invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import OutcomeService from '../../../../../../services/finance/outcomes';
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';

type OutcomeInvoiceVersionHistoryProps = {
    invoiceId: string;
    onDismissed(refresh?: boolean): void;
}

const OutcomeInvoiceVersionHistory: React.FC<OutcomeInvoiceVersionHistoryProps> = (props: OutcomeInvoiceVersionHistoryProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [versions, setVersions] = React.useState<IVersionHistoryResourceShortProps[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _versions = await OutcomeService.versions.retrieve(props.invoiceId);
            setLoaded(true);
            setVersions(_versions);
        } catch (e) {

        }
    }

    const renderVersion = (_version: IVersionHistoryResourceShortProps, idx: number) => {
        const invoice = _version.data as Partial<IOutcomeResourceProps>;

        return <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack className={styles.indicator}>
                <Stack className={`${styles.dot} ${idx === 0 ? styles.active : ''}`}></Stack>
                {idx < versions.length - 1 ? <Stack className={styles.line}></Stack> : null}
            </Stack>
            <Stack grow={1} tokens={{ childrenGap: 10 }} className={styles.card}>
                <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                    <Stack>
                        <Persona text={_version.createdBy.name} imageInitials={GeneralService.getInitial(_version.createdBy.name)} size={PersonaSize.size24} />
                    </Stack>
                    <Text size={'small'}>|</Text>
                    <Text size={'small'}>{moment(_version.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{_version.notes}</Text>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack>
                    <table className={styles.table}>
                        {invoice.status ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Status</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal>
                                    {invoice.status.toLowerCase() === 'cancelled' ? <Tag variant='error' text='Cancelled' /> : null}
                                    {invoice.status.toLowerCase() === 'active' ? <Tag variant='active' text='Active' /> : null}
                                </Stack>
                            </td>
                        </tr> : null}
                        {invoice.type ? <tr>
                            <td className={styles.title}><Text size={'small'}>Type</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{invoice.type}</Text></td>
                        </tr> : null}
                        {invoice.dueDate ? <tr>
                            <td className={styles.title}><Text size={'small'}>Due date</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{moment(invoice.dueDate).format('DD/MM/YYYY')}</Text></td>
                        </tr> : null}
                        {invoice.name ? <tr>
                            <td className={styles.title}><Text size={'small'}>Name</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{invoice.name}</Text></td>
                        </tr> : null}
                        {invoice.invoiceDate || invoice.invoiceNumber ? <tr>
                            <td className={styles.title} style={{ paddingTop: 10 }}><Text size={'small'}>Invoice number/date</Text></td>
                            <td className={styles.separator} style={{ paddingTop: 10 }}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{invoice.invoiceNumber || ""} {invoice.invoiceDate ? moment(invoice.invoiceDate).format("DD/MM/YYYY") : ""}</Text></td>
                        </tr> : null}
                        {invoice.totalAmount ? <tr>
                            <td className={styles.title}><Text size={'small'}>Amount</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{GeneralService.getNumberWithSeparator(invoice.totalAmount || 0)}</Text></td>
                        </tr> : null}
                        {invoice.bankAccount ? <tr>
                            <td className={styles.title}><Text size={'small'}>Bank account</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{invoice.bankAccount.accountName} - {invoice.bankAccount.accountNumber}</Text></td>
                        </tr> : null}
                        {invoice.vendor ? <tr>
                            <td className={styles.title}><Text size={'small'}>Vendor</Text></td>
                            <td className={styles.separator} style={{ paddingTop: 10 }}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{invoice.vendor.name}</Text></td>
                        </tr> : null}
                        {(invoice.orders || []).length > 0 ? <tr>
                            <td className={styles.title}><Text size={'small'} style={{ paddingTop: 5 }}>Orders</Text></td>
                            <td className={styles.separator}><Text size={'small'} style={{ paddingTop: 5 }}>:</Text></td>
                            <td className={styles.content}>
                                <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                                    {(invoice.orders || []).map((order, idx) => <Tag text={`#${OrderService.getOrderNumber(order.order.orderNumber)} (Rp. ${GeneralService.getNumberWithSeparator(Number(order.amount))})`} />)}
                                </Stack>
                            </td>
                        </tr> : null}
                        {invoice.justification ? <tr>
                            <td className={styles.title}><Text size={'small'}>Cancellation reason</Text></td>
                            <td className={styles.separator}><Text size={'small'}>:</Text></td>
                            <td className={styles.content}><Text size={'small'}>{invoice.justification}</Text></td>
                        </tr> : null}
                    </table>
                </Stack>
            </Stack>
        </Stack>;
    }

    const compareVersion = (currentVersion: IVersionHistoryResourceShortProps, previousVersion: IVersionHistoryResourceShortProps) => {
        const currentInvoice = currentVersion.data as IOutcomeResourceProps;
        const previousInvoice = previousVersion.data as IOutcomeResourceProps;
        const cleanInvoice: Partial<IOutcomeResourceProps> = {};

        if (currentInvoice.status !== previousInvoice.status) {
            cleanInvoice.status = currentInvoice.status;
        }
        if (currentInvoice.justification !== previousInvoice.justification) {
            cleanInvoice.justification = currentInvoice.justification;
        }
        if (currentInvoice.type !== previousInvoice.type) {
            cleanInvoice.type = currentInvoice.type;
        }
        if (currentInvoice.dueDate !== previousInvoice.dueDate) {
            cleanInvoice.dueDate = currentInvoice.dueDate;
        }
        if (currentInvoice.name !== previousInvoice.name) {
            cleanInvoice.name = currentInvoice.name;
        }
        if (currentInvoice.invoiceDate !== previousInvoice.invoiceDate) {
            cleanInvoice.invoiceDate = currentInvoice.invoiceDate;
        }
        if (currentInvoice.invoiceNumber !== previousInvoice.invoiceNumber) {
            cleanInvoice.invoiceNumber = currentInvoice.invoiceNumber;
        }
        if (currentInvoice.totalAmount !== previousInvoice.totalAmount) {
            cleanInvoice.totalAmount = currentInvoice.totalAmount;
        }
        if (currentInvoice.bankAccount?.id !== previousInvoice.bankAccount?.id) {
            cleanInvoice.bankAccount = currentInvoice.bankAccount;
        }
        if (currentInvoice.vendor?.id !== previousInvoice.vendor?.id) {
            cleanInvoice.vendor = currentInvoice.vendor;
        }
        if (currentInvoice.notes !== previousInvoice.notes) {
            cleanInvoice.notes = currentInvoice.notes;
        }
        if ((currentInvoice.orders || []).map((o) => o.id + o.amount).sort().join(';') !== (previousInvoice.orders || []).map((o) => o.id + o.amount).sort().join(';')) {
            cleanInvoice.orders = currentInvoice.orders;
        }

        return { ...currentVersion, data: cleanInvoice };
    }

    return <Panel headerText={"Version History"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Retrieving version history ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Stack tokens={{ childrenGap: 20 }} className={styles.timeline}>
                    {versions.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)).reverse().map((version, idx) => {
                        const currentVersion = version;
                        const previousVersion = versions[idx + 1] ? versions[idx + 1] : undefined;

                        if (previousVersion) {
                            const updated = compareVersion(currentVersion, previousVersion);
                            return renderVersion(updated, idx);
                        } else {
                            return renderVersion(currentVersion, idx);
                        }
                    })}
                </Stack>
            </> : null}
        </Stack>
    </Panel>
};

export default OutcomeInvoiceVersionHistory;
