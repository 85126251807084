import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../../stores/root';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import TrucksService from '../../../../../services/data/trucks';

// props
import { ITruckResourceShort } from '../../../../../props/data/trucks';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import TruckForm from './../form';
import DeleteTruckForm from '../delete';
import TruckDocumentsList from '../../documents/general/list';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';

interface ITruckDetailsProps {
    truckId: string;
}

const TruckDetails: React.FC<ITruckDetailsProps> = (props: ITruckDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [truck, setTruck] = React.useState<ITruckResourceShort>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['trucks.read.all'], user.permissions);

    useEffect(() => {
        _onGetTrain();
    }, []);

    const _onGetTrain = async () => {
        try {
            const _truck = await TrucksService.get(props.truckId);
            setTruck(_truck);

            topbar.show(_truck.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "operationals", text: 'Operationals' },
                { key: "vendors", text: 'Vendors', href: "/operationals/vendors" },
                { key: _truck.vendor.id, text: _truck.vendor.name, href: "/operationals/vendors/" + _truck.vendor.id },
                { key: props.truckId, text: _truck.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_truck_error',
                text: 'Failed to get truck details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['trucks.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                text: 'Update details',
                onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
            });
        }

        /*farItems.push({
            key: 'delete',
            text: 'Delete Truck',
            onRender: () => <ActionButton text={'Delete Truck'} iconProps={{ iconName: "Delete" }} onClick={() => setActiveSurface('delete')} />
        });*/

        return { items, farItems };
    }

    const getPropertiesSection = (_truck: ITruckResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'truck_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_truck.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Name</Label>
                        <Text>{_truck.name}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Registration Number</Label>
                        <Text>{_truck.registrationNumber}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Vendor</Label>
                        <NavLink to={'/operationals/vendors/' + _truck.vendor.id}><Text>{_truck.vendor.name || "-"}</Text></NavLink>
                    </Stack>
                </Stack>
                {_truck.gpsModule ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>GPS Module</Label>
                        <Text>{_truck.gpsModule.name} - {_truck.gpsDeviceId || "-"}</Text>
                    </Stack>
                </Stack> : null}
            </Stack>
        }
    }

    const getNextScheduleSection = (_truck: ITruckResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Next Schedule"
            },
            key: 'next_schedule',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <ComingSoon />
            </Stack>
        }
    }

    const getTabsSection = (_truck: ITruckResourceShort): IStackSectionProps => {
        return {
            key: 'truck_tabs',
            isCard: true,
            tabs: [
                {
                    key: 'related_documents',
                    title: 'Related Document(s)',
                    content: <Stack>
                        <TruckDocumentsList truckId={props.truckId} />
                    </Stack>
                },
                {
                    key: 'live_tracking',
                    title: 'Live Tracking',
                    content: <Stack>
                        <ComingSoon />
                    </Stack>
                }
            ]
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetTrain() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving truck details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && truck && hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getPropertiesSection(truck)]} right={getNextScheduleSection(truck)} />
                <DetailsStack left={[getTabsSection(truck)]} />
            </Stack>
            {activeSurface === 'update' ? <TruckForm truckId={truck.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' ? <DeleteTruckForm truck={truck} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {!hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default TruckDetails;
