import { CompoundButton, IButtonProps, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../stores/root';
import { faBoxesPacking, faBuilding, faContactCard, faCreditCard, faGlobe, faHome, faLockOpen, faMapLocationDot, faMoneyCheckDollarPen, faNewspaper, faStethoscope, faTruck, faTruckFast, faUsers, faWarehouse } from '@fortawesome/pro-light-svg-icons';
import CardButton from '../../components/uiframeworks/buttons/card';
import Label from '../../components/typography/label';

interface ISettingsPageProps {
}

const SettingsPage: React.FC<ISettingsPageProps> = (props: ISettingsPageProps) => {
    const { topbar } = useStore();

    useEffect(() => {
        topbar.show("Settings", [
            { key: "home", icon: faHome, href: "/" },
            { key: "settings", text: 'Settings' }
        ])
    }, []);

    const buttons: IButtonProps[] = [

    ];

    return <Stack tokens={{ childrenGap: 40 }}>
        <Stack tokens={{ childrenGap: 10 }}>
            <Label>Administration Settings</Label>
            <CardButton horizontal gap={20} buttons={[
                {
                    icon: faGlobe,
                    text: 'Freight Categories',
                    description: 'Category for each freight eg. export, import, Indonesia.',
                    variant: 'light',
                    link: '/settings/freightCategories',
                    shadow: true
                },
                {
                    icon: faTruckFast,
                    text: 'Delivery Types',
                    description: 'Delivery type for each order eg. shipping, trucking.',
                    variant: 'light',
                    link: '/settings/deliveryTypes',
                    shadow: true
                },
                {
                    icon: faStethoscope,
                    text: 'Order Status',
                    description: 'State for each order eg. active, in progress, completed.',
                    variant: 'light',
                    link: '/settings/orderStatus',
                    shadow: true
                }
            ]} />
            <CardButton horizontal gap={20} buttons={[
                {
                    icon: faBoxesPacking,
                    text: 'Quantity Units',
                    description: 'Measurement units for goods.',
                    variant: 'light',
                    link: '/settings/quantityUnits',
                    shadow: true,
                    width: "calc(33.33333% - 15px)"
                },
                {
                    icon: faWarehouse,
                    text: 'Warehouses',
                    description: 'List of all available warehouses.',
                    variant: 'light',
                    link: '/settings/warehouses',
                    shadow: true,
                    width: "calc(33.33333% - 15px)"
                }
            ]} />
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Label>Roles & Permissions Settings</Label>
            <CardButton horizontal gap={20} buttons={[
                {
                    icon: faLockOpen,
                    text: 'Roles',
                    description: 'List of all available user roles.',
                    variant: 'light',
                    link: '/settings/roles',
                    shadow: true,
                    width: "calc(33.33333% - 15px)"
                },
                {
                    icon: faMoneyCheckDollarPen,
                    text: 'Outcome Categories',
                    description: 'List of all outcome categories.',
                    variant: 'light',
                    link: '/settings/outcomes/categories',
                    shadow: true,
                    width: "calc(33.33333% - 15px)"
                }
            ]} />
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Label>Company Settings</Label>
            <CardButton horizontal gap={20} buttons={[
                {
                    icon: faBuilding,
                    text: 'Subsidiaries',
                    description: 'List of all company subsidiaries.',
                    variant: 'light',
                    link: '/settings/subsidiaries',
                    shadow: true,
                    width: "calc(33.33333% - 15px)"
                },
                {
                    icon: faCreditCard,
                    text: 'Bank Accounts',
                    description: 'List of all bank accounts owned by Jejak Group.',
                    variant: 'light',
                    link: '/settings/bankAccounts',
                    shadow: true,
                    width: "calc(33.33333% - 15px)"
                }
            ]} />
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Label>Public Website Settings</Label>
                <CardButton horizontal gap={20} buttons={[
                    {
                        icon: faContactCard,
                        text: 'Public Contact',
                        description: 'List of all available contacts.',
                        variant: 'light',
                        link: '/settings/contacts',
                        shadow: true
                    },
                    {
                        icon: faMapLocationDot,
                        text: 'Public Offices',
                        description: 'List of all available offices.',
                        variant: 'light',
                        link: '/settings/offices',
                        shadow: true
                    },
                    {
                        icon: faUsers,
                        text: 'Public Clients',
                        description: 'List of all available clients.',
                        variant: 'light',
                        link: '/settings/clients',
                        shadow: true
                    },
                ]} />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
                <CardButton horizontal gap={20} buttons={[
                    {
                        icon: faNewspaper,
                        text: 'News',
                        description: 'List of all available contacts.',
                        variant: 'light',
                        link: '/settings/news',
                        shadow: true,
                        width: "calc(33.33333% - 15px)"
                    },
                ]} />
            </Stack>
        </Stack>
    </Stack>;
};

export default SettingsPage;
