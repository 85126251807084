import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../services/validation';
import OrderContainerService from '../../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../../services/general';

// props

// components
import { DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { IBankAccountResourceShortProps } from '../../../../../../props/general/bankAccount';
import OrderInvoiceService from '../../../../../../services/orders/invoices/invoice';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import OrderService from '../../../../../../services/orders/order';
import { IOrderResourceShortProps } from '../../../../../../props/orders/order';
import IncomeInvoiceService from '../../../../../../services/finance/incomes/invoices/invoice';
import moment from 'moment';
import BankAccountService from '../../../../../../services/general/bankAccount';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';

type IncomeInvoiceFormProps = {
    orderId?: string;
    invoiceId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    order?: IOrderResourceShortProps;
    invoiceNumber: string;
    amount: string;
    dueDate: string;
    bankAccount: string;
    notes: string;
}

type FormDataErrorProps = {
    invoiceNumber?: string;
    amount?: string;
    dueDate?: string;
    bankAccount?: string;
    notes?: string;
}

const IncomeInvoiceForm: React.FC<IncomeInvoiceFormProps> = (props: IncomeInvoiceFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [bankAccounts, setBankAccounts] = React.useState<IBankAccountResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        invoiceNumber: GeneralService.makeid(25, true, "INV"),
        amount: "",
        dueDate: moment().add(1, 'day').toISOString(),
        notes: "",
        bankAccount: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.invoiceId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['incomes.invoices.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['incomes.invoices.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.invoiceId) {
            const _invoice = await IncomeInvoiceService.get(props.invoiceId);

            setData({
                invoiceNumber: _invoice.id,
                amount: _invoice.amount,
                dueDate: _invoice.dueDate,
                notes: _invoice.notes || "",
                bankAccount: _invoice.bankAccount?.id || "",
                order: _invoice.order
            });
        } else if (props.orderId) {
            const _order = await OrderService.get(props.orderId);
            setData({...data, order: _order});

            const _bankAccounts = await BankAccountService.retrieve();
            setBankAccounts(_bankAccounts);
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.amount || error.dueDate || error.notes || error.bankAccount) {
            return true;
        } else if (data.amount === '' || !data.bankAccount) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { order, amount, invoiceNumber, dueDate, notes, bankAccount } = data;

            // create form data
            let fd = new FormData();
            fd.append("id", invoiceNumber);
            fd.append("orderId", order?.id || "");
            fd.append("amount", amount);
            fd.append("dueDate", dueDate);
            fd.append("notes", notes);
            if (bankAccount) {
                fd.append("bankAccountId", bankAccount);
            }

            let invoice = undefined;
            if (props.invoiceId === undefined) {
                invoice = await IncomeInvoiceService.create(fd);
            } else {
                invoice = await IncomeInvoiceService.update(props.invoiceId, fd);
            }

            banner.add({
                key: mode + '_order_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Invoice "${OrderInvoiceService.getInvoiceNumber(invoice.id)}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Invoice' : 'Update Invoice'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating invoice ..." : "Updating invoice ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    {data.order ? <Stack.Item>
                        <Label>Order Number</Label>
                        <Text>{OrderService.getOrderNumber(data.order.orderNumber)}</Text>
                    </Stack.Item> : null}
                    <Stack.Item>
                        <Label>Invoice Number</Label>
                        <Text>{OrderInvoiceService.getInvoiceNumber(data.invoiceNumber)}</Text>
                    </Stack.Item>
                    {data.order ? <Stack.Item>
                        <Label>Unpaid Amount</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(data.order.unpaidAmount)}</Text>
                    </Stack.Item> : null}
                    <Stack.Item>
                        <TextField label={"Invoiced Amount"}
                            value={data.amount}
                            required={true}
                            prefix={'Rp'}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.amount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.amount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.amount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker label={"Due Date"}
                            isRequired={true}
                            value={moment(data.dueDate).toDate()}
                            onSelectDate={(date) => {
                                data.dueDate = date ? moment(date).toISOString() : data.dueDate;
                                setData({...data});
                            }}
                            formatDate={GeneralService.formatDate}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown label={"Bank Account"}
                            required={true}
                            selectedKey={data.bankAccount}
                            options={bankAccounts.map((ba) => {
                                return {key: ba.id, text: `${ba.name} (${ba.bankName}) - ${ba.accountNumber}`}
                            })}
                            onChange={(evt, opt) => {
                                data.bankAccount = opt ? opt.key as string : "";
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.notes = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.notes = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default IncomeInvoiceForm;
