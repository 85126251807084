import { ITruckResourceShort } from '../../../props/data/trucks';
import { IDocumentProps } from '../../../props/general';
import Request from './../../request';

// import props

const TrucksService = {
  retrieve: async (qs?: string): Promise<ITruckResourceShort[]> => {
    try {
      const results = await Request.get(`/api/operationals/trucks?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ITruckResourceShort> => {
    try {
      const results = await Request.get(`/api/operationals/trucks/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ITruckResourceShort> => {
    try {
      const result = await Request.post(`/api/operationals/trucks`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ITruckResourceShort> => {
    try {
      const result = await Request.post(`/api/operationals/trucks/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/operationals/trucks/${id}`);
    } catch (e) {
      throw e;
    }
  },
  liveTracking: async (id: string): Promise<{ lat: number, long: number, status: string }> => {
    try {
      const results = await Request.get(`/api/operationals/trucks/${id}/livetrack`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  documents: {
    retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
      try {
        const results = await Request.get(`/api/operationals/trucks/${id}/documents?${qs || ""}`);
        return results;
      } catch (e) {
        throw (e);
      }
    },
    new: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/trucks/${id}/documents`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw (e);
      }
    },
    delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/trucks/${id}/delete_documents`, data);
        return result;
      } catch (e) {
        throw (e);
      }
    }
  },
};

export default TrucksService;
