import React from 'react';

// import styles
import parentStyles from './../style.module.scss';

// import router
import { RouteComponentProps } from "react-router-dom";

// import props
import { IUserResourceShortProps } from '../../props/user';

// import services
import UserService from '../../services/users/user';

// import pages

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import LoadingComponent from '../../components/feedbacks/loading/index';
import { IRenderMessageBarProps } from '../../props/general';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import GeneralService from '../../services/general';
import SimpleMessageBarComponent from '../../components/feedbacks/simpleMessageBar';
import { ActionButton } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

interface IVerifyEmailProps {
    user: IUserResourceShortProps;
    route: RouteComponentProps;
}

interface IVerifyEmailState {
    loaded?: boolean;
    sending?: boolean;
    verifying?: boolean;
    isVerified?: boolean;
    messageBar?: IRenderMessageBarProps;
}

export default class VerifyEmail extends React.Component<IVerifyEmailProps, IVerifyEmailState> {
    constructor(props: IVerifyEmailProps) {
        super(props);
        this.state = {}
    }

    private _onResend = async () => {
        try {
            this.setState({ sending: true, messageBar: undefined });
            await UserService.sendEmailVerification();
            this.setState({ 
                sending: false, 
                messageBar: {
                    content: <Text variant={"small"}>Email verifikasi telah dikirim ke {this.props.user.email}.</Text>,
                    type: MessageBarType.success
                }
            });
        } catch (e) {
            this.setState({
                sending: false,
                messageBar: {
                    content: <Text variant={"small"}>{GeneralService.generateErrorMessage(e)}</Text>,
                    type: MessageBarType.error
                }
            })
        }
    }

    public componentDidMount = async () => {
        try {
            const isVerified = await UserService.isVerified();
            this.setState({isVerified, loaded: true});

            if (isVerified) {setTimeout(() => {window.location.href = "/";}, 4000)}
        } catch(e) {
            this.setState({loaded: true});
        }
    }

    public render() {
        const { user } = this.props;

        return (
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack className={parentStyles.header}>
                    <Text className={parentStyles.text} as={"h2"} variant={"medium"}>Verifikasi Email</Text>
                </Stack>
                {this.state.messageBar ? <SimpleMessageBarComponent properties={this.state.messageBar} onDismiss={() => { this.setState({ messageBar: undefined }) }} /> : null}
                {!this.state.loaded ? <LoadingComponent label={"Harap tunggu ..."} /> : null}
                {
                    this.state.loaded && !this.state.isVerified ? (
                        <>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Halo {user.name}!</Text>
                                <Text style={{ textAlign: "center" }}>Untuk memastikan kenyamanan dan keamanan para pengguna website Jejak Logistik, maka anda harus memverifikasi alamat email anda.</Text>
                                <Text style={{ textAlign: "center" }}>Kami telah mengirimkan email verifikasi ke <strong>{user.email}</strong>. Mohon cek kotak masuk email anda dan tekan link yang ada di dalam email verifikasi tersebut.</Text>
                                <Text style={{ textAlign: "center" }}>Apabila anda tidak dapat menemukan email verifikasi dari kami, tekan tombol dibawah untuk mengirim ulang email verifikasi ke email anda.</Text>
                            </Stack>
                            {
                                !this.state.sending && !this.state.verifying ? (
                                    <Stack horizontal horizontalAlign={"center"} tokens={{childrenGap: 20}}>
                                        <DefaultButton text={"Kirim Ulang Email"} iconProps={{iconName: "Mail"}} onClick={this._onResend} />
                                    </Stack>
                                ) : null
                            }
                            {this.state.sending ? <LoadingComponent label={"Mengirim ulang email verifikasi ..."} labelPosition={"right"} /> : null}
                            {this.state.verifying ? <LoadingComponent label={"Memverifikasi email ..."} labelPosition={"right"} /> : null}
                        </>
                    ) : null
                }
                {
                    this.state.loaded && this.state.isVerified ? (
                        <>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Text as={"h2"} variant={"large"} style={{ textAlign: "center", fontWeight: 600 }}>Email Terverifikasi!</Text>
                                <Text style={{ textAlign: "center" }}>Email {user.email} telah terverifikasi. Harap tunggu sementara kami mengalihkan anda ke halaman portal administrasi Jejak Logistik.</Text>
                            </Stack>
                            <LoadingComponent label={""} />
                        </>
                    )  : null
                }
            </Stack>
        );
    }
}