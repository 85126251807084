import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import TeamDetails from '../../../components/users/teams/general/details';

interface ITeamDetailsPageProps {
}

const TeamDetailsPage: React.FC<ITeamDetailsPageProps> = (props: ITeamDetailsPageProps) => {
    const { topbar } = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Team", [
            { key: "home", icon: faHome, href: "/" },
            { key: "users", text: 'Users' },
            { key: "customers", text: 'Team' }
        ]);
    }, []);

    return <Stack>
        <TeamDetails teamId={params.teamId} />
    </Stack>;
};

export default TeamDetailsPage;
