import React from 'react';

// import assets
import LogoDark from './../../../assets/logo/logo-dark.webp';

// import manifests

// import configs

// import stylings

// import props

// import services

// import components

// import antd component
import { Dialog, DialogType, SpinnerSize, Stack, Text } from '@fluentui/react';
import LoadingComponent from '../loading/index';

export interface ILoadingDialogComponentProps {
    label?: string;
    spinnerProps?: {
        size?: SpinnerSize;
        label?: string;
    };
    title?: string;
    secondaryText?: string;
    hideLogo?: boolean;
    onRender?(): JSX.Element;
}
interface ILoadingDialogComponentState {}

export default class LoadingDialogComponent extends React.Component<ILoadingDialogComponentProps, ILoadingDialogComponentState> {

    constructor(props: ILoadingDialogComponentProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const {hideLogo, title, secondaryText, spinnerProps, onRender} = this.props;

      return <Dialog hidden={false}
            dialogContentProps={{ type: DialogType.largeHeader, styles: { title: { padding: 0 } } }}
            modalProps={{ isBlocking: true }} >
                <Stack tokens={{childrenGap: 20}}>
                    {hideLogo ? null : <Stack horizontalAlign={"center"}><img style={{maxWidth: 175}} src={LogoDark} alt={"Jejak Logistik Logo"} /></Stack>}
                    {onRender ? onRender() : null}
                    {
                        !onRender ? (
                            <>
                                <Stack tokens={{childrenGap: 10}}>
                                    {title ? <Text as={"h2"} variant={"xLarge"} style={{textAlign: "center"}}>{title}</Text> : null}
                                    {secondaryText ? <Text style={{textAlign: "center"}}>{secondaryText}</Text> : null}
                                </Stack>
                                <LoadingComponent label={spinnerProps ? spinnerProps.label || "" : ""} size={spinnerProps ? spinnerProps.size : undefined} />
                            </>
                        ) : null
                    }
                </Stack>
        </Dialog>
    }
}