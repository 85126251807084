import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import OrdersList from '../../../../orders/general/list';
import CompanyForm from '../form';
import { ICompanyResource } from '../../../../../props/users/company';
import CompanyService from '../../../../../services/users/company';
import ComingSoon from '../../../../uiframeworks/comingSoon';
import CustomersList from '../../../customers/general/list';

interface CompanyDetailsProps {
    companyId: string;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = (props: CompanyDetailsProps) => {
    const { banner, topbar } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [company, setCompany] = React.useState<ICompanyResource>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onGetCompany();
    }, []);

    const _onGetCompany = async () => {
        try {
            const _company = await CompanyService.get(props.companyId);
            setCompany(_company);

            topbar.show(_company.name, [
                {key: "home", icon: faHome, href: "/"},
                {key: "users", text: 'Users'},
                {key: "companies", text: 'Companies', href: "/users/companies"},
                {key: props.companyId, text: _company.name}
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_company_error',
                text: 'Failed to get company details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'update',
            text: 'Update details',
            onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
        });

        farItems.push({
            key: 'delete',
            text: 'Delete',
            onRender: () => <ActionButton text={'Delete'} iconProps={{iconName: "Delete"}} styles={{root: {marginLeft: 20}}} onClick={() => setActiveSurface('delete')} />
        });

        return {items, farItems};
    }

    const getCompanyPropertiesSection = (_company: ICompanyResource): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'user_details',
            isCard: true,
            content: <Stack tokens={{childrenGap: 20}}>
                <Stack horizontal tokens={{childrenGap: 20}}>
                    <Stack styles={{root: {width: '50%'}}}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_company.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{root: {width: '50%'}}}>
                        <Label size={'small'}>Name</Label>
                        <Text>{_company.name}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{childrenGap: 20}}>
                    <Stack styles={{root: {width: '50%'}}}>
                        <Label size={'small'}>Address</Label>
                        <Text>{_company.address || "-"}</Text>
                    </Stack>
                    <Stack styles={{root: {width: '50%'}}}>
                        <Label size={'small'}>phoneNumber</Label>
                        <Text>{_company.phoneNumber || "-"}</Text>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getStatus = (_company: ICompanyResource): IStackSectionProps => {
        return {
            header: {
                title: "Status"
            },
            key: 'status',
            isCard: true,
            content: <Stack>
                <ComingSoon />
            </Stack>
        }
    }

    const getRelatedItemsSection = (_company: ICompanyResource): IStackSectionProps => {
        const oqs: string[] = [];
        oqs.push(`company=${props.companyId}`);

        const uqs: string[] = [];
        uqs.push(`companies=${props.companyId}`);

        return {
            key: 'user_companies',
            isCard: true,
            tabs: [
                {
                    key: 'related_orders',
                    title: 'Related Orders',
                    content: <Stack>
                        <OrdersList qs={oqs} filters={['delivery_type', 'freight_category', 'goods_type', 'order_status']} />
                    </Stack>
                },
                {
                    key: 'related_users',
                    title: 'Related Users',
                    content: <Stack>
                        <CustomersList qs={uqs} />
                    </Stack>
                }/*,
                {
                    key: 'stored_goods',
                    title: 'Stored Goods',
                    content: <Stack>
                        Stored Goods
                    </Stack>
                },
                {
                    key: 'goods_history',
                    title: 'Goods History',
                    content: <Stack>
                        Goods History
                    </Stack>
                }*/
            ]
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) {_onGetCompany()}
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving company details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && company ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack className={styles.details} tokens={{childrenGap: 20}}>
                <DetailsStack left={[getCompanyPropertiesSection(company)]} right={getStatus(company)} />
                <DetailsStack left={[getRelatedItemsSection(company)]} />
            </Stack>
            {activeSurface === 'update' ? <CompanyForm companyId={company.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default CompanyDetails;
