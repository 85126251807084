import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';

// props
import { IOrderGoodsResourceShortProps } from '../../../../../props/orders/orderGoods';

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import OrderGoodsForm from '../form';
import OrderGoodsService from '../../../../../services/orders/orderGoods';
import OrderGoodsDetails from '../details';
import PermissionsService from '../../../../../services/permissions';

interface IOrderGoodsListProps {
    orderId: string;
    qs?: string[];
    orderGoods?: IOrderGoodsResourceShortProps[];
    columns?: TOrderGoodsColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderGoodsColumn = 'goods' | 'quantityAmount' | 'actions' | 'totalPrice' | 'notes' | 'price';

const OrderGoodsList: React.FC<IOrderGoodsListProps> = (props: IOrderGoodsListProps) => {
    const shownColumns: TOrderGoodsColumn[] = props.columns || [
        'goods',
        'quantityAmount',
        'actions',
        'totalPrice',
        'notes',
        'price'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [orderGoods, setOrderGoods] = React.useState<IOrderGoodsResourceShortProps[]>(props.orderGoods || []);
    const [activeOrderGoods, setActiveOrderGoods] = React.useState<IOrderGoodsResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "goods",
            name: "Goods",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IOrderGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveOrderGoods(item);
                        setActiveSurface("details");
                    }}><Text>{item.goods.name || "-"}</Text></Link>
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 150,
            isMultiline: true,
            onRender: (item: IOrderGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "quantityAmount",
            name: "Quantity",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{GeneralService.getNumberWithSeparator(Number(item.quantityAmount))} {item.goods.quantityUnit || ""}</Text>
                </Stack>
            }
        },
        {
            key: "price",
            name: "Price",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {item.pricingMethod === 'perquantity' ? <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.price || '0'))}</Text> : null}
                    {item.pricingMethod === 'lumpsum' ? <Text style={{fontStyle: 'italic'}}>Use lump sum price</Text> : null}
                </Stack>
            }
        },
        {
            key: "totalPrice",
            name: "Total Price",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderGoodsResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice || '0'))}</Text>
                    {item.ppn > 0 ? <Text size={'small'} style={{fontStyle: 'italic'}}>(inc. PPN) Rp. {GeneralService.getNumberWithSeparator(Number(item.ppn))}</Text> : null}
                    {item.pph > 0 ? <Text size={'small'} style={{fontStyle: 'italic'}}>(inc. PPh) Rp. {GeneralService.getNumberWithSeparator(Number(item.pph))}</Text> : null}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.orderGoods) {
            _onRetrieveOrderGoods();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveOrderGoods = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await OrderGoodsService.retrieve.get(props.orderId, qs.join("&"));
            setOrderGoods(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_containers_list_error',
                text: 'Failed to retrieve order containers list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Goods",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Goods"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveOrderGoods(undefined);

        if (refresh) { _onRetrieveOrderGoods() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && orderGoods.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={orderGoods.filter((og) => {
                                const search = (keyword || "").trim().toLowerCase();
                                return (og.goods.name || "").toLowerCase().indexOf(search) > -1;
                            })}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderGoodsColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && orderGoods.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text>Goods(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderGoodsForm orderId={props.orderId} onDismissed={_onSurfaceDismissed} /> : null}
        {/*activeSurface === 'update' && activeContainer ? <OrderContainerForm orderId={props.orderId} containerId={activeContainer.id} onDismissed={_onSurfaceDismissed} /> : null*/}
        {activeSurface === 'details' && activeOrderGoods ? <OrderGoodsDetails orderId={props.orderId} orderGoodsId={activeOrderGoods.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrderGoodsList;
