import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import CustomersList from '../../../components/users/customers/general/list';
import CustomerDetails from '../../../components/users/customers/general/details';
import { useParams } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface ICustomerDetailsPageProps {
}

const CustomerDetailsPage: React.FC<ICustomerDetailsPageProps> = (props: ICustomerDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Customers", [
            {key: "home", icon: faHome, href: "/"},
            {key: "users", text: 'Users'},
            {key: "customers", text: 'Customers'}
        ]);
    }, []);

    return <Stack>
        <CustomerDetails userId={params.customerId} />
    </Stack>;
};

export default CustomerDetailsPage;
