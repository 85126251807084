import React from 'react';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../../services/general';

// props
import OutcomeTypes from '../../../../../../manifests/outcomeTypes';
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';

// components
import { Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import SelectInternalBankAccount from '../../../../../uiframeworks/forms/bankaccounts/internal';

interface IOutcomeInvoiceDetailsPropertiesProps {
    outcome: IOutcomeResourceProps;
}

const OutcomeInvoiceDetailsProperties: React.FC<IOutcomeInvoiceDetailsPropertiesProps> = (props: IOutcomeInvoiceDetailsPropertiesProps) => {
    const { outcome } = props;
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const renderDueDateTag = () => {
        let tagVariant: TTagVariant = 'active';
        const diff = moment(outcome.dueDate).diff(moment(), 'days');

        if (diff < 1) {
            tagVariant = 'error';
        } else if (diff < 2) {
            tagVariant = 'warning';
        }

        return <Stack horizontal>
            <Tag size={'small'} text={GeneralService.getFriendlyDateFormat(outcome.dueDate)} variant={tagVariant} />
        </Stack>
    }

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Category</Text>
                <Label size={'small'}>{outcome.category?.name || "-"}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Invoice Number</Text>
                <Label size={'small'}>#{outcome.invoiceNumber || "-"}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Invoice Date</Text>
                <Label size={'small'}>{GeneralService.formatDate(moment(outcome.invoiceDate).toDate())}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Due Date</Text>
                {renderDueDateTag()}
            </Stack.Item>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack.Item>
            <Text size={'small'}>Name</Text>
            <Label size={'small'}>{outcome.name}</Label>
        </Stack.Item>
        <Stack.Item>
            <Text size={'small'}>Notes</Text>
            <Label size={'small'}>{outcome.notes || "-"}</Label>
        </Stack.Item>
        <Stack horizontal tokens={{childrenGap: 20}}>
            <Stack styles={{root: {width: '25%'}}}>
                <Text size={'small'}>Subsidiary</Text>
                <Label size={'small'}>{outcome.subsidiary?.name || "-"}</Label>
            </Stack>
            <Stack styles={{root: {width: '25%'}}}>
                <Text size={'small'}>Transfer from</Text>
                {outcome.bankAccount ? <Stack tokens={{ childrenGap: 0 }}>
                    <Label size={'small'}>{outcome.bankAccount.accountNumber} a/n {outcome.bankAccount.accountName}</Label>
                    <Text size={'xsmall'}>{outcome.bankAccount.bank.name} ({[outcome.bankAccount.bankCity, outcome.bankAccount.bank.country].filter((s) => (s || "") !== "" && (s || "") !== "-").join(", ")})</Text>
                </Stack> : null}
                {!outcome.bankAccount ? <Label size={'small'}>-</Label> : null}
            </Stack>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Price (as per invoice)</Text>
                <Stack tokens={{ childrenGap: 2 }}>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.originalAmount))}</Label>
                    <Stack horizontal tokens={{ childrenGap: 3 }}>
                        {outcome.includePPn ? <Text className={'color-green'} style={{ fontStyle: 'italic' }} size={'small'}>(include PPn)</Text> : null}
                        {!outcome.includePPn ? <Text className={'color-red'} style={{ fontStyle: 'italic' }} size={'small'}>(exclude PPn)</Text> : null}
                        {outcome.includePPh ? <Text className={'color-green'} style={{ fontStyle: 'italic' }} size={'small'}>(include PPh)</Text> : null}
                        {!outcome.includePPh ? <Text className={'color-red'} style={{ fontStyle: 'italic' }} size={'small'}>(exclude PPh)</Text> : null}
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>PPn</Text>
                {Number(outcome.ppn) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.ppn))}</Label>
                    {outcome.ppnPercentage !== 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.ppnPercentage}%)</Text> : null}
                    {outcome.ppnPercentage === 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.ppnPercentage})</Text> : null}
                </Stack> : <Text size={'small'} style={{fontStyle: 'italic'}}>No PPn</Text>}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>PPh</Text>
                {Number(outcome.pph) > 0 ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                    <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.pph))}</Label>
                    {outcome.pphPercentage !== 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.pphPercentage}%)</Text> : null}
                    {outcome.pphPercentage === 'custom' ? <Text style={{ fontStyle: 'italic' }} size={'small'}>({outcome.pphPercentage})</Text> : null}
                </Stack> : <Text size={'small'} style={{fontStyle: 'italic'}}>No PPh</Text>}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Total amount</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.totalAmount))}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Total amount to be paid</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(outcome.amount))}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Paid</Text>
                <Label size={'small'} className='color-green'>Rp. {GeneralService.getNumberWithSeparator(outcome.paid)}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Pending payment</Text>
                <Label size={'small'} className='color-yellow'>Rp. {GeneralService.getNumberWithSeparator(outcome.pendingPayment)}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Unpaid</Text>
                <Label size={'small'} className='color-red'>Rp. {GeneralService.getNumberWithSeparator(outcome.unpaid)}</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '25%' } }}> </Stack.Item>
        </Stack>
        {outcome.vendor || outcome.train || outcome.ship || outcome.shipSchedule || outcome.trainSchedule || outcome.truck ? <>
            <Stack className='divider'></Stack>
            {outcome.vendor ? <Stack.Item>
                <Text size={'small'}>Vendor</Text>
                <Label size={'small'}>{outcome.vendor.name}</Label>
            </Stack.Item> : null}
            {outcome.ship ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                {outcome.ship ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Ship</Text>
                    <Label size={'small'}>{outcome.ship.name}</Label>
                </Stack> : null}
                {outcome.shipSchedule ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Voy</Text>
                    <Label size={'small'}>{outcome.shipSchedule.voy}</Label>
                </Stack> : null}
            </Stack> : null}
            {outcome.shipSchedule ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Port of Loading</Text>
                    <Label size={'small'}>{outcome.shipSchedule.originAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.shipSchedule.actualDeparture ? `(Actual) ${moment(outcome.shipSchedule.actualDeparture).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.shipSchedule.estimatedDeparture).format("DD/MM/YYYY")}`}</Text>
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Port of Discharge</Text>
                    <Label size={'small'}>{outcome.shipSchedule.destinationAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.shipSchedule.actualArrival ? `(Actual) ${moment(outcome.shipSchedule.actualArrival).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.shipSchedule.estimatedArrival).format("DD/MM/YYYY")}`}</Text>
                </Stack>
            </Stack> : null}
            {outcome.train ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                {outcome.train ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Train</Text>
                    <Label size={'small'}>{outcome.train.name}</Label>
                </Stack> : null}
                {outcome.trainSchedule ? <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Voy</Text>
                    <Label size={'small'}>{outcome.trainSchedule.voy}</Label>
                </Stack> : null}
            </Stack> : null}
            {outcome.trainSchedule ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Origin Station</Text>
                    <Label size={'small'}>{outcome.trainSchedule.originAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.trainSchedule.actualDeparture ? `(Actual) ${moment(outcome.trainSchedule.actualDeparture).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.trainSchedule.estimatedDeparture).format("DD/MM/YYYY")}`}</Text>
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Destination Station</Text>
                    <Label size={'small'}>{outcome.trainSchedule.destinationAddress}</Label>
                    <Text size={'small'} style={{ fontStyle: 'italic' }}>{outcome.trainSchedule.actualArrival ? `(Actual) ${moment(outcome.trainSchedule.actualArrival).format("DD/MM/YYYY")}` : `(Est.) ${moment(outcome.trainSchedule.estimatedArrival).format("DD/MM/YYYY")}`}</Text>
                </Stack>
            </Stack> : null}
            {outcome.truck ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Text size={'small'}>Truck</Text>
                    <Label size={'small'}>{outcome.truck.registrationNumber}</Label>
                </Stack>
            </Stack> : null}
        </> : null}
    </Stack>
};

export default OutcomeInvoiceDetailsProperties;
