import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { CommandBar, IColumn, ICommandBarItemProps, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from './../../../../typography/text';
import GeneralService from '../../../../../services/general';
import { FilterOptionProps } from '../../../../uiframeworks/filters/panel';
import SelectedFilter from '../../../../uiframeworks/filters/selected';
import Pagination from '../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../config';
import PermissionsService from '../../../../../services/permissions';
import { IEmployeeContractResourceShortProps } from '../../../../../props/employees/contracts/contract';
import EmployeeContractService from '../../../../../services/employees/contracts';
import moment from 'moment';
import EmployeeContractForm from '../form';

type EmployeeContractsListProps = {
    qs?: string[];
    contracts?: IEmployeeContractResourceShortProps[];
    columns?: TEmployeeContractColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateContract?: boolean;
    hideFilter?: boolean;
    variant?: 'card' | 'plain';
}

export type TEmployeeContractColumn = 'id' | 'actions' | 'employee' | 'subsidiary' | 'endDate' | 'startDate';

const EmployeeContractsList: React.FC<EmployeeContractsListProps> = (props: EmployeeContractsListProps) => {
    const shownColumns = props.columns || [
        'id',
        'actions',
        'employee',
        'subsidiary',
        'startDate',
        'endDate'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [contracts, setContracts] = React.useState<IEmployeeContractResourceShortProps[]>(props.contracts || []);
    const [activeContract, setActiveContract] = React.useState<IEmployeeContractResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterOptionProps[]>([]);
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "id",
            name: "Contract #",
            fieldName: "id",
            minWidth: 250,
            maxWidth: 250,
            onRender: (item: IEmployeeContractResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/orders/${item.id}`}><Text>#{item.id}</Text></NavLink>
                </Stack>
            }
        },
        {
            key: "employee",
            name: "Employee",
            fieldName: "employee",
            minWidth: 200,
            onRender: (item: IEmployeeContractResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <NavLink to={`/users/administrators/${item.employee.id}`}>
                        <Text>{item.employee.name}</Text>
                    </NavLink>
                </Stack>
            }
        },
        {
            key: "subsidiary",
            name: "Subsidiary",
            fieldName: "subsidiary",
            minWidth: 250,
            maxWidth: 250,
            isMultiline: true,
            onRender: (item: IEmployeeContractResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.subsidiary.name}</Text>
                </Stack>
            }
        },
        {
            key: "startDate",
            name: "Start Date",
            fieldName: "startDate",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IEmployeeContractResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{moment(item.startDate).format("DD/MM/YYYY")}</Text>
                </Stack>
            }
        },
        {
            key: "endDate",
            name: "End Date",
            fieldName: "endDate",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IEmployeeContractResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{GeneralService.getFriendlyDateFormat(item.endDate)}</Text>
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.contracts) {
            _onRetrieveContracts();
        } else {
            setLoaded(true);
        }
    }, [keyword]);

    const getQueryString = (pageNumber?: number) => {
        const qs: string[] = props.qs || [];
        qs.push(`top=${GlobalConfig.defaultTop}`);
        if (pageNumber) { qs.push(`page=${pageNumber}`); }
        if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

        return qs.join("&");
    }

    const _onRetrieveContracts = async (pageNumber?: number) => {
        try {
            setLoaded(false);
            const qs = getQueryString(pageNumber);
            const results = await EmployeeContractService.retrieve(qs);

            setContracts(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_orders_list_error',
                text: 'Failed to retrieve orders list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} styles={{ root: { marginRight: 10 } }} />
                }
            });
        }

        if (!props.hideCreateContract && PermissionsService.hasPermission(['employees.contracts.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create contract",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create contract"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveContract(undefined);

        if (refresh) { _onRetrieveContracts() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        {selectedFilter.length > 0 ? <SelectedFilter selected={selectedFilter} onChange={(selected) => {
            setSelectedFilter(selected);
        }} /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && contracts.length > 0 ? <Pagination goToPage={_onRetrieveContracts} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " contract(s) found"} /> : null}
            <Stack className={styles[props.variant || 'card']}>
                {
                    !loaded || (loaded && contracts.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={contracts}
                                columns={columns.filter((col) => shownColumns.indexOf(col.key as TEmployeeContractColumn) > -1)}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && contracts.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Contracts not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && contracts.length > 0 ? <Pagination goToPage={_onRetrieveContracts} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'create' ? <EmployeeContractForm onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default EmployeeContractsList;
