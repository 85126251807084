import Request from '../request';
import { IDataPermissionResourceShort } from '../../props/data/permission';

const DataPermissionService = {
  retrieve: async (qs?: string): Promise<IDataPermissionResourceShort[]> => {
    try {
      const results = await Request.get(`/api/data/permissions?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default DataPermissionService;
