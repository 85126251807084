import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { ActionButton, Link, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import moment from 'moment';
import GeneralService from '../../../../services/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import PermissionsService from '../../../../services/permissions';
import { useStore } from '../../../../stores/root';
import { ITrainResourceShort, ITrainScheduleResourceShort } from '../../../../props/data/trains';
import TrainScheduleService from '../../../../services/data/trains/trainScedule';
import TrainScheduleForm from '../../../operationals/trains/schedules/general/form';

type SelectTrainScheduleProps = {
    train?: ITrainResourceShort;
    selected?: ITrainScheduleResourceShort;
    options?: ITrainScheduleResourceShort[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: ITrainScheduleResourceShort): void;
}

const SelectTrainSchedule: React.FC<SelectTrainScheduleProps> = (props: SelectTrainScheduleProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<ITrainScheduleResourceShort | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    const _onSearchSchedule = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: "Voy #" + item.voy,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                if (props.train) { qs.push(`train_id=${props.train.id}`) }
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                const options = (await TrainScheduleService.retrieve(qs.join("&"))).map((item) => ({
                    key: item.id,
                    name: item.voy,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Schedule"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onRenderSuggestionsItem={(props: any) => {
                    const md = props.metadata as ITrainScheduleResourceShort;

                    return <Stack styles={{ root: { padding: 5, borderBottom: '1px solid #eee' } }} horizontalAlign='baseline'>
                        <Label size={'small'}>Voy #{md.voy}</Label>
                        <Stack horizontal verticalAlign='baseline'>
                            <Stack styles={{ root: { width: 150 } }} horizontalAlign='baseline' verticalAlign={'baseline'}>
                                <Text size={'small'} style={{ textAlign: 'start' }}>{md.originAddress}</Text>
                                {md.actualDeparture ? <Text size={'xsmall'}>{moment(md.actualDeparture).format("DD/MM/YYYY")} (Actual)</Text> : null}
                                {!md.actualDeparture ? <Text size={'xsmall'}>{moment(md.estimatedDeparture).format("DD/MM/YYYY")} (Est.)</Text> : null}
                            </Stack>
                            <Stack styles={{ root: { marginLeft: 20, marginRight: 20 } }}><FontAwesomeIcon icon={faArrowRight} /></Stack>
                            <Stack styles={{ root: { width: 150 } }} horizontalAlign='baseline' verticalAlign={'baseline'}>
                                <Text size={'small'} style={{ textAlign: 'start' }}>{md.destinationAddress}</Text>
                                {md.actualArrival ? <Text size={'xsmall'}>{moment(md.actualArrival).format("DD/MM/YYYY")} (Actual)</Text> : null}
                                {!md.actualArrival ? <Text size={'xsmall'}>{moment(md.estimatedArrival).format("DD/MM/YYYY")} (Est.)</Text> : null}
                            </Stack>
                        </Stack>
                    </Stack>;
                }}
                onResolveSuggestions={_onSearchSchedule}
                onEmptyResolveSuggestions={() => _onSearchSchedule('')}
            />
            {props.allowCreate && props.train && PermissionsService.hasPermission(['trains.schedules.create'], user.permissions) ? <Text size={'small'}>Cannot find schedule? <Link onClick={() => {setActiveSurface('create')}}>Click here</Link> to create train schedule</Text> : null}
        </> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack tokens={{ childrenGap: 10 }}>
                <Label size={'small'}>Voy #{selected.voy}</Label>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack grow={1}>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <Label style={{ width: 30 }} size={'xsmall'}>POL: </Label>
                            <Text size={'small'}>{selected.originAddress}</Text>
                        </Stack>
                        <Stack styles={{ root: { marginLeft: 35 } }}>
                            {!selected.actualDeparture ? <Text size={'small'}>{GeneralService.formatDate(moment(selected.estimatedDeparture).toDate())} (Est.)</Text> : null}
                            {selected.actualDeparture ? <Text size={'small'}>{GeneralService.formatDate(moment(selected.actualDeparture).toDate())} (Actual)</Text> : null}
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}> </Stack>
                    <Stack grow={1}>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <Label style={{ width: 30 }} size={'xsmall'}>POD: </Label>
                            <Text size={'small'}>{selected.destinationAddress}</Text>
                        </Stack>
                        <Stack styles={{ root: { marginLeft: 35 } }}>
                            {!selected.actualArrival ? <Text size={'small'}>{GeneralService.formatDate(moment(selected.estimatedArrival).toDate())} (Est.)</Text> : null}
                            {selected.actualArrival ? <Text size={'small'}>{GeneralService.formatDate(moment(selected.actualArrival).toDate())} (Actual)</Text> : null}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
        {activeSurface === 'create' && props.train ? <TrainScheduleForm trainId={props.train?.id} onDismissed={(refresh, schedule) => {
            setActiveSurface(undefined);
            props.onChange(schedule);
        }} /> : null}
    </Stack>;
};

export default SelectTrainSchedule;
