import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import RolesList from '../../../components/settings/roles/general/list';
import PermissionsService from '../../../services/permissions';
import NoAccess from '../../../components/uiframeworks/noAccess';

type RolesListPageProps = {
}

const RolesListPage: React.FC<RolesListPageProps> = (props: RolesListPageProps) => {
    const {topbar, user} = useStore();

    useEffect(() => {
        topbar.show("Roles", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "roles", text: 'Roles'}
        ])
    }, []);

    const hasPermission = PermissionsService.hasPermission(['roles.read.all', 'roles.read.me'], user.permissions);

    return <Stack>
        {hasPermission ? <RolesList /> : <NoAccess />}
    </Stack>;
};

export default RolesListPage;
