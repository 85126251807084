import React, { useEffect, useState } from 'react';

// assets
import Logo from './../../../assets/logo/logo-dark.webp';

import styles from './style.module.scss';

export type HeadingProps = React.PropsWithChildren<{
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    className?: string;
    style?: React.CSSProperties;
}>

const Heading: React.FC<HeadingProps> = (props: HeadingProps) => {
    if (props.variant === 'h1') {
        return <h1 className={`${styles.heading} ${styles.h1} ${props.className || ''}`}>{props.children}</h1>;
    } else if (props.variant === 'h2') {
        return <h2 className={`${styles.heading} ${styles.h2} ${props.className || ''}`}>{props.children}</h2>;
    } else if (props.variant === 'h3') {
        return <h3 className={`${styles.heading} ${styles.h3} ${props.className || ''}`}>{props.children}</h3>;
    } else if (props.variant === 'h4') {
        return <h4 className={`${styles.heading} ${styles.h4} ${props.className || ''}`}>{props.children}</h4>;
    } else if (props.variant === 'h5') {
        return <h5 className={`${styles.heading} ${styles.h5} ${props.className || ''}`}>{props.children}</h5>;
    } else {
        return <h6 className={`${styles.heading} ${styles.h6} ${props.className || ''}`}>{props.children}</h6>;
    }
};

export default Heading;
