import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../services/orders/order';

// props
import { IOrderResourceProps, IOrderResourceShortProps, IOrderResourcerShorterProps } from '../../../../props/orders/order';

// components
import { ActionButton, CommandBar, DatePicker, Dropdown, IButtonProps, ICommandBarItemProps, PrimaryButton, SpinnerSize, Stack } from '@fluentui/react';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import Label from '../../../typography/label';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import GeneralService from '../../../../services/general';
import ErrorService from '../../../../services/general/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrdersDashboardChartFilter, { SelectedFilterProps } from './filters';
import OrdersDashboardTotalOrdersChart from './graphs/totalOrders';
import OrdersDashboardIncomesOutcomesChart from './graphs/incomesOutcomes';
import DetailsStack from '../../../uiframeworks/detailsStack';
import OrdersDashboardTotalProfitChart from './graphs/totalProfit';
import OrdersList from '../../general/list';

type OrdersDashboardChartProps = {
}

const OrdersDashboardChart: React.FC<OrdersDashboardChartProps> = (props: OrdersDashboardChartProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [groupBy, setGroupBy] = React.useState<string>("created");
    const [dateRange, setDateRange] = React.useState<string>("thisweek");
    const [selectedDate, setSelectedDate] = React.useState<{startDate: string, endDate: string}>({
        startDate: moment().startOf('week').toISOString(),
        endDate: moment().endOf('week').toISOString()
    });
    const [orders, setOrders] = React.useState<IOrderResourceShortProps[]>([]);
    const [filteredOrders, setFilteredOrders] = React.useState<IOrderResourceShortProps[]>([]);
    const [totalOrdersBy, setTotalOrdersBy] = React.useState<string>('month');

    useEffect(() => {
        _onRetrieveOrders();
    }, [selectedDate, groupBy]);

    const _onRetrieveOrders = async () => {
        try {
            setLoaded(false);
            let groupByFilterText = 'date';

            const qs: string[] = [];
            if (groupBy === 'actualdeparture') {
                groupByFilterText = "actual_departure_date";
            } else if (groupBy === 'estimateddeparture') {
                groupByFilterText = "estimated_departure_date";
            }

            qs.push(`start_${groupByFilterText}=${selectedDate.startDate}`);
            qs.push(`end_${groupByFilterText}=${selectedDate.endDate}`);
            qs.push(`top=all`);
            qs.push(`return_type=summary`);

            const _orders = await OrderService.retrieve(qs.join('&'));

            setOrders(_orders.data);
            setFilteredOrders(_orders.data);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_new_orders_error',
                text: 'Failed to retrieve new orders. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onFilterChanged = (selectedFilter: SelectedFilterProps) => {
        let _filteredOrders = orders;

        if (selectedFilter.company && selectedFilter.company.length > 0) {
            const selectedIds = selectedFilter.company.map((company) => company.id);
            _filteredOrders = _filteredOrders.filter((order) => selectedIds.indexOf(order.company?.id || "") > -1);
        }
        
        if (selectedFilter.goodsType && selectedFilter.goodsType.length > 0) {
            _filteredOrders = _filteredOrders.filter((order) => (selectedFilter.goodsType || []).indexOf(order.goodsType) > -1);
        }
        
        if (selectedFilter.freightCategory && selectedFilter.freightCategory.length > 0) {
            const selectedIds = selectedFilter.freightCategory.map((freightCategory) => freightCategory.id);
            _filteredOrders = _filteredOrders.filter((order) => selectedIds.indexOf(order.freightCategory?.id || "") > -1);
        }
        
        if (selectedFilter.sales && selectedFilter.sales.length > 0) {
            const selectedIds = selectedFilter.sales.map((sales) => sales.id);
            _filteredOrders = _filteredOrders.filter((order) => {
                const exist = (order.sales || []).findIndex((s) => selectedIds.indexOf(s.sales.id) > -1) > -1;
                return exist;
            });
        }
        
        if (selectedFilter.status && selectedFilter.status.length > 0) {
            const selectedIds = selectedFilter.status.map((status) => status.id);
            _filteredOrders = _filteredOrders.filter((order) => selectedIds.indexOf(order.status.id || "") > -1);
        }
        
        if (selectedFilter.subsidiary && selectedFilter.subsidiary.length > 0) {
            const selectedIds = selectedFilter.subsidiary.map((subsidiary) => subsidiary.id);
            _filteredOrders = _filteredOrders.filter((order) => selectedIds.indexOf(order.subsidiary?.id || "") > -1);
        }
        
        if (selectedFilter.paymentStatus && selectedFilter.paymentStatus.length > 0) {
            _filteredOrders = _filteredOrders.filter((order) => {
                const paymentStatus = order.unpaidAmount < 0 ? 'Paid' : 'Unpaid';
                return (selectedFilter.paymentStatus || []).indexOf(paymentStatus) > -1;
            });
        }

        setFilteredOrders([..._filteredOrders]);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 20}}>
        <Stack horizontal tokens={{childrenGap: 20}}>
            <Dropdown label={'Group by'}
                options={[
                    {key: 'created', text: 'Created date'},
                    {key: 'actualdeparture', text: 'Actual departure/start date'},
                    {key: 'estimateddeparture', text: 'Estimated departure/start date'}
                ]}
                disabled={!loaded}
                styles={{root: {width: 250}}}
                selectedKey={groupBy}
                onChange={(ev, opt) => {
                    const _groupBy = opt?.key as string || groupBy;
                    setGroupBy(_groupBy);
                }} />
            <Dropdown label={'Date range'}
                options={[
                    {key: 'thisweek', text: 'This week'},
                    {key: 'lastweek', text: 'Last week'},
                    {key: 'thismonth', text: 'This month'},
                    {key: 'lastmonth', text: 'Last month'},
                    {key: 'last3month', text: 'Last 3 months'},
                    {key: 'thisyear', text: 'This year'},
                    {key: 'lastyear', text: 'Last year'},
                    {key: 'custom', text: 'Custom'}
                ]}
                disabled={!loaded}
                styles={{root: {width: 200}}}
                selectedKey={dateRange}
                onChange={(ev, opt) => {
                    let _startDate = selectedDate.startDate;
                    let _endDate = selectedDate.endDate;
                    let _dateRange = opt?.key as string || dateRange;

                    if (_dateRange === 'thismonth') {
                        _startDate = moment().startOf('month').toISOString();
                        _endDate = moment().endOf('month').toISOString();
                    } else if (_dateRange === 'lastmonth') {
                        _startDate = moment().startOf('month').add(-1, 'month').toISOString();
                        _endDate = moment().endOf('month').add(-1, 'month').toISOString();
                    } else if (_dateRange === 'last3month') {
                        _startDate = moment().startOf('month').add(-2, 'month').toISOString();
                        _endDate = moment().endOf('month').toISOString();
                    } else if (_dateRange === 'thisweek') {
                        _startDate = moment().startOf('week').toISOString();
                        _endDate = moment().endOf('week').toISOString();
                    } else if (_dateRange === 'lastweek') {
                        _startDate = moment().startOf('week').add(-1, 'week').toISOString();
                        _endDate = moment().endOf('week').add(-1, 'week').toISOString();
                    } else if (_dateRange === 'thisyear') {
                        _startDate = moment().startOf('year').toISOString();
                        _endDate = moment().endOf('year').toISOString();
                    } else if (_dateRange === 'lastyear') {
                        _startDate = moment().startOf('year').add(-1, 'year').toISOString();
                        _endDate = moment().endOf('year').add(-1, 'year').toISOString();
                    }

                    setSelectedDate({startDate: _startDate, endDate: _endDate});
                    setDateRange(_dateRange);
                }} />
            {dateRange === 'custom' ? <Stack verticalAlign='center' horizontal tokens={{childrenGap: 20}} styles={{root: {paddingTop: 26}}}>
                <DatePicker value={moment(selectedDate.startDate).toDate()}
                    styles={{root: {width: 150}}}
                    disabled={!loaded}
                    formatDate={GeneralService.formatDate}
                    onSelectDate={(date) => {
                        const _startDate = date ? moment(date).startOf('day').toISOString() : selectedDate.startDate;
                        setSelectedDate({startDate: _startDate, endDate: selectedDate.endDate});
                    }} />
                <Stack><FontAwesomeIcon icon={faArrowRight} /></Stack>
                <DatePicker value={moment(selectedDate.endDate).toDate()}
                    styles={{root: {width: 150}}}
                    disabled={!loaded}
                    formatDate={GeneralService.formatDate}
                    onSelectDate={(date) => {
                        const _endDate = date ? moment(date).endOf('day').toISOString() : selectedDate.endDate;
                        setSelectedDate({startDate: selectedDate.startDate, endDate: _endDate});
                    }} />
            </Stack> : null}
        </Stack>
        <Stack className={'divider'}></Stack>
        {!loaded ? <LoadingComponent label={'Generating charts ...'} labelPosition={'right'} spinnerPosition={'baseline'} size={SpinnerSize.large} /> : null}
        {loaded ? <Stack horizontal tokens={{childrenGap: 20}}>
            <Stack styles={{root: {width: 250}}}>
                <OrdersDashboardChartFilter filteredOrders={filteredOrders} orders={orders} onChange={_onFilterChanged} />
            </Stack>
            {filteredOrders.length <= 0 ? <Text>No orders found</Text> : null}
            {filteredOrders.length > 0 ? <Stack styles={{root: {width: '100%'}}} tokens={{childrenGap: 20}}>
                <Stack tokens={{childrenGap: 20}} className={styles.card} styles={{root: {width: '100%', height: 450}}}>
                    <OrdersDashboardIncomesOutcomesChart orders={filteredOrders} groupBy={groupBy} />
                </Stack>
                <DetailsStack left={[
                    {
                        key: 'graph',
                        subsections: [
                            {
                                key: 'totalOrders',
                                header: {onRender: () => {
                                    return <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 3}}>
                                        <Label>Total orders</Label>
                                    </Stack>
                                }},
                                isCard: true,
                                content: <OrdersDashboardTotalOrdersChart orders={filteredOrders} groupBy={groupBy} />
                            },
                            {
                                key: 'totalOrders',
                                header: {title: 'Total orders'},
                                isCard: true,
                                content: <OrdersDashboardTotalProfitChart orders={filteredOrders} groupBy={groupBy} />
                            }
                        ]
                    }
                ]} />
            </Stack> : null}
        </Stack> : null}
    </Stack>;
};

export default OrdersDashboardChart;
