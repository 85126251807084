import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFolders = () => {
  const location = useLocation();
  const [folders, setFolders] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const documentsIndex = pathSegments.indexOf('documents') + 1;
    const folders = pathSegments.slice(documentsIndex);
    setFolders(folders);
  }, [location.pathname]);

  return folders;
};

export default useFolders