import React, { useEffect } from 'react';

// assets

// services
import UserService from '../../../../../services/users/user';
import ErrorService from '../../../../../services/general/error';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';

// components
import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import RoleService from '../../../../../services/roles';
import ValidationService from '../../../../../services/validation';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { ICompanyResourceShort } from '../../../../../props/users/company';
import Label from '../../../../typography/label';
import CompanyService from '../../../../../services/users/company';
import { IUserResourceShortProps } from '../../../../../props/users/user';

interface ICustomerFormProps {
    customerId?: string;
    onDismissed(refresh?: boolean, customer?: IUserResourceShortProps): void;
}

type FormDataProps = {
    companies: ICompanyResourceShort[];
    email: string;
    name: string;
    roles: IDropdownOption[];
    password: string;
    address: string;
    phoneNumber: string;
}

type FormDataErrorProps = {
    companies?: string;
    email?: string;
    name?: string;
    password?: string;
    roles?: string;
    address?: string;
    phoneNumber?: string;
}

const CustomerForm: React.FC<ICustomerFormProps> = (props: ICustomerFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        email: "",
        name: "",
        roles: [],
        password: "",
        address: "",
        phoneNumber: "",
        companies: []
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.customerId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const roles = await RoleService.retrieve();
        setRoles(roles);

        if (props.customerId) {
            const _user = await UserService.get(props.customerId);
            setData({
                email: _user.email,
                name: _user.name,
                address: _user.address || "",
                roles: _user.roles.map((role) => {
                    return { key: role.id, text: role.name }
                }),
                password: "",
                phoneNumber: _user.phoneNumber || "",
                companies: _user.companies
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.email || error.address || error.phoneNumber || error.password) {
            return true;
        } else if (data.name === '' || data.email === '') {
            return true;
        } else if (mode === 'create' && data.password === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, roles, email, password, address, phoneNumber, companies } = data;

            // create form data
            let fd = new FormData();
            const role = roles.find((r) => r.key === 'customer');
            if (role) {
                fd.append("rolesId", JSON.stringify([role.id]));
            }
            fd.append("email", email);
            fd.append("name", name);
            if (password && password.length > 0) {
                fd.append("password", password);
            }
            fd.append("address", address);
            fd.append("phoneNumber", phoneNumber);
            companies.forEach((company) => {
                fd.append("companiesId[]", company.id);
            });

            let result;
            if (props.customerId === undefined) {
                result = await UserService.new(fd);
            } else {
                result = await UserService.update(props.customerId, fd);
            }
            banner.add({
                key: 'create_customer_success',
                variant: 'success',
                icon: faCheck,
                text: `Customer "${name}" created successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchCompanies = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await CompanyService.retrieve(qs.join("&"))).data.map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_companies_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Customer' : 'Update Customer'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating customer ..." : "Updating customer ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Email Address"}
                            required={true}
                            value={data.email}
                            onChange={(evt, value) => {
                                data.email = value || "";

                                const validation = ValidationService.combination(value, ['required', 'email', 'limit'], { maxChars: 100 });
                                error.email = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.email}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    {mode === 'create' ? <Stack.Item>
                        <TextField label={"Password"}
                            required={true}
                            value={data.password}
                            onChange={(evt, value) => {
                                data.password = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                error.password = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.password}
                            disabled={submitting} />
                    </Stack.Item> : null}
                    <Stack.Item>
                        <TextField label={"Address"}
                            required={false}
                            multiline
                            rows={5}
                            value={data.address}
                            errorMessage={error.address}
                            onChange={(evt, value) => {
                                data.address = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.address = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Phone Number"}
                            required={false}
                            value={data.phoneNumber}
                            errorMessage={error.phoneNumber}
                            onChange={(evt, value) => {
                                if (value === "" || !isNaN(Number(value))) {
                                    data.phoneNumber = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.phoneNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Related Companies</Label>
                        <TagPicker
                            selectedItems={data.companies.map((comp) => {
                                return {key: comp.id, name: comp.name, metadata: comp}
                            })}
                            removeButtonAriaLabel='Remove'
                            inputProps={{
                                multiple: true
                            }}
                            onChange={(items?: any) => {
                                data.companies = (items || []).map((item: any) => item.metadata)

                                setData({ ...data });
                            }}
                            onResolveSuggestions={_onSearchCompanies}
                            onEmptyResolveSuggestions={() => _onSearchCompanies('')}
                        />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default CustomerForm;
