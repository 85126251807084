import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';
import ValidationService from '../../../../../../services/validation';
import VendorsService from '../../../../../../services/data/vendors';
import ShipsService from '../../../../../../services/data/ships';

// props
import { IRoleResourceShortProps } from '../../../../../../props/users/role';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';

// components
import { DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import moment from 'moment';
import ShipSchedulesService from '../../../../../../services/data/ships/shipSchedules';
import GeneralService from '../../../../../../services/general';
import { shipScheduleStatuses } from '../../../../../../utils/status';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';

interface IShipScheduleStatusFormProps {
    scheduleId: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    status: string;
    actualDeparture?: string;
    actualArrival?: string;
}

const ShipScheduleStatusForm: React.FC<IShipScheduleStatusFormProps> = (props: IShipScheduleStatusFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        status: 'scheduled'
    });

    const hasPermission = PermissionsService.hasPermission(['ships.schedules.update.all'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.scheduleId) {
            const _schedule = await ShipSchedulesService.get(props.scheduleId);
            setData({
                status: _schedule.status || "scheduled",
                actualArrival: _schedule.actualArrival || moment().toISOString(),
                actualDeparture: _schedule.actualDeparture || moment().toISOString()
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (data.status === 'sailing' && !data.actualDeparture) {
            return true;
        } else if (data.status === 'completed' && !data.actualArrival) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { status, actualArrival, actualDeparture } = data;

            // create form data
            let fd = new FormData();
            fd.append("status", status);
            if (status === 'sailing' && actualDeparture) {
                fd.append('actualDeparture', actualDeparture);
            }
            if (status === 'arrived' && actualArrival) {
                fd.append('actualArrival', actualArrival);
            }

            await ShipSchedulesService.update(props.scheduleId, fd);
            banner.add({
                key: 'update_ship_schedule_status_success',
                variant: 'success',
                icon: faCheck,
                text: `Ship schedule status updated successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={'Update Schedule Status'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating schedule status ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <Dropdown label={"Status"}
                            options={shipScheduleStatuses}
                            selectedKey={data.status}
                            onChange={(evt, opt) => {
                                data.status = opt ? opt.key as string : 'scheduled';
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    {data.status === 'sailing' ? <Stack.Item>
                        <DatePicker label='Actual Departure'
                            isRequired={true}
                            value={moment(data.actualDeparture).toDate()}
                            onSelectDate={(date) => {
                                if (date) {
                                    data.actualDeparture = moment(date).toISOString();
                                    setData({ ...data });
                                }
                            }} formatDate={GeneralService.formatDate} />
                    </Stack.Item> : null}
                    {data.status === 'arrived' ? <Stack.Item>
                        <DatePicker label='Actual Arrival'
                            value={moment(data.actualArrival).toDate()}
                            onSelectDate={(date) => {
                                if (date) {
                                    data.actualArrival = moment(date).toISOString();
                                    setData({ ...data });
                                }
                            }} formatDate={GeneralService.formatDate} />
                    </Stack.Item> : null}
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default ShipScheduleStatusForm;