import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import SubsidiariesList from '../../../components/settings/subsidiaries/general/list';

interface ISubsidiariesListPageProps {
}

const SubsidiariesListPage: React.FC<ISubsidiariesListPageProps> = (props: ISubsidiariesListPageProps) => {
    const { topbar } = useStore();

    useEffect(() => {
        topbar.show("Subsidiaries", [
            { key: "home", icon: faHome, href: "/" },
            { key: "users", text: 'Users' },
            { key: "subsidiaries", text: 'Subsidiaries' }
        ])
    }, []);

    return (
        <Stack>
            <SubsidiariesList />
        </Stack>
    );
};

export default SubsidiariesListPage;
