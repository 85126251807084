import Request from '../../request';

const EmployeePerformanceService = {
  commission: {
    get: async (employeeId: string, qs?: string): Promise<any> => {
      try {
        const results = await Request.get(`/api/employees/${employeeId}/performance/commission?${qs || ''}`);
        return results;
      } catch (e) {
        throw e;
      }
    }
  }
};

export default EmployeePerformanceService;
