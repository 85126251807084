import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import RoleService from '../../../../../services/roles';
import PermissionsService from '../../../../../services/permissions';

// props
import { IRoleResourceShortProps } from '../../../../../props/roles/role';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import RoleForm from '../form';

type RolesListProps = {
}

const RolesList: React.FC<RolesListProps> = (props: RolesListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [activeRole, setActiveRole] = React.useState<IRoleResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['roles.read.all', 'roles.read.me'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "name",
            fieldName: "name",
            name: "Name",
            minWidth: 120,
            maxWidth: 120,
            onRender: (item: IRoleResourceShortProps) => {
                return <Stack styles={{ root: { height: '100%' } }}>
                    <Text>{item.name}</Text>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IRoleResourceShortProps) => {
                const hasUpdatePermission = PermissionsService.hasPermission(['roles.update'], user.permissions);
                const hasDeletePermission = PermissionsService.hasPermission(['roles.delete'], user.permissions);

                const items: IContextualMenuItem[] = [];
                if (hasUpdatePermission) {
                    items.push({
                        key: "update", text: "Update", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveRole(item);
                            setActiveSurface('update');
                        }
                    });
                }
                if (hasDeletePermission) {
                    items.push({
                        key: "delete", text: "Delete", iconProps: { iconName: "Trash" }, onClick: () => {
                            setActiveRole(item);
                            setActiveSurface('delete');
                        }
                    });
                }


                return <Stack styles={{ root: { height: '100%' } }}>
                    {hasPermission ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{items}} />
                    </Stack.Item> : null}
                </Stack>;
            }
        },
        {
            key: "description",
            fieldName: "description",
            name: "Description",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: IRoleResourceShortProps) => {
                return <Stack styles={{ root: { height: '100%' } }}>
                    <Text>{item.description}</Text>
                </Stack>;
            }
        }
    ];

    useEffect(() => {
        _onRetrieveRoles();
    }, []);

    const _onRetrieveRoles = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = [];
            const results = await RoleService.retrieve();
            setRoles(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_roles_error',
                text: 'Failed to retrieve roles. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        const hasCreatePermission = PermissionsService.hasPermission(['roles.create'], user.permissions);

        if (hasCreatePermission) {
            farItems.push({
                key: "register",
                text: "Create role",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create role"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveRole(undefined);

        if (refresh) { _onRetrieveRoles() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && roles.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={roles}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && roles.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Roles not found</Text>
                </Stack> : null}
            </Stack>
        </> : null}
        {activeSurface === 'create' ? <RoleForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeRole ? <RoleForm role={activeRole} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default RolesList;
