import axios, { ResponseType } from 'axios';
import moment from 'moment';
import Config from './../config';
import Cookies from 'js-cookie';
import { IPaginationResourceShort } from '../props/general';

export interface IRequestFilterProps {
  key: string;
  value: string[];
}

export const refreshToken = async () => {
  try {
    // check if token is expired
    const expiry = Cookies.get('expiry');
    console.log(expiry);
    if (moment(expiry).diff(moment()) < 0) {
      let refreshToken = Cookies.get('refresh');
      const result = await axios.post(
        Config.apiHostname + '/api/auth/refresh',
        {},
        {
          headers: {
            accept: 'application/json',
            authorization: refreshToken,
            'content-type': 'application/json',
          },
        }
      );

      Cookies.set('token', result.data.token.access_token);
      Cookies.set('refresh', result.data.token.refresh_token);
      Cookies.set(
        'expiry',
        moment()
          .add(result.data.token.expires_in - 60, 'seconds')
          .toISOString()
      );
    }
  } catch (e) {
    throw e;
  }
};

const convertPagination = (pagination: any): IPaginationResourceShort => {
  return {
    currentPage: pagination.current_page,
    total: pagination.total || 0,
    lastPage: pagination.last_page || 1,
    nextPageUrl: pagination.next_page_url,
    prevPageUrl: pagination.prev_page_url,
  };
};

const Request = {
  getNext: async (url: string) => {
    try {
      await refreshToken();
      const token = Cookies.get('token');
      const res = await axios.get(url, {
        headers: {
          accept: 'application/json',
          authorization: 'Bearer ' + token,
        },
      });
      const pagination = convertPagination(res.data.pagination);
      return { data: res.data.data, pagination };
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  get: async (api: string, disableToken?: boolean, responseType?: ResponseType) => {
    try {
      if (disableToken) { } else {
        await refreshToken();
      }
      const token = Cookies.get('token');
      let headers: any = {
        accept: 'application/json',
        authorization: disableToken ? undefined : 'Bearer ' + token,
      }

      const res = await axios.get(Config.apiHostname + api, {
        headers
      });
      const pagination = res.data.pagination ? convertPagination(res.data.pagination) : undefined;
      if (res.data.data) {
        return pagination ? { data: res.data.data, pagination } : res.data.data;
      } else {
        return res;
      }
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  post: async (api: string, body?: any, contentType?: string, disableToken?: boolean, skipRefreshToken?: boolean) => {
    try {
      if (skipRefreshToken) { } else { await refreshToken(); }
      const token = Cookies.get('token');
      const res = await axios.post(Config.apiHostname + api, body, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? undefined : 'Bearer ' + token,
          'content-type': contentType || 'application/json',
        },
      });
      return res.data.data;
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  download: async (api: string) => {
    try {
      await refreshToken();
      const token = Cookies.get('token');
      const res = await axios.get(Config.apiHostname + api, {
        headers: {authorization: 'Bearer ' + token},
        responseType: 'blob',
      });

      /*const type = res.headers['content-type'];
      let fileExtension = '';
      if (type) {
        if (type === 'application/pdf') {
          fileExtension = 'pdf';
        } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          fileExtension = 'docx';
        }
      }*/
      return res
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  getBlob: async (api: string, disableToken?: boolean, responseType?: ResponseType) => {
    try {
      const token = Cookies.get('token');
      await refreshToken();
      const res = await axios.get(Config.apiHostname + api, {
        headers: {
          'Content-Type': 'application/json',
          authorization: disableToken ? undefined : 'Bearer ' + token,
        },
      });

      return res
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  getArrayBuffer: async (api: string, disableToken?: boolean, responseType?: ResponseType) => {
    try {
      const token = Cookies.get('token');
      await refreshToken();
      const res = await axios.get(Config.apiHostname + api, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          authorization: disableToken ? undefined : 'Bearer ' + token,
        },
      });

      return res
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  put: async (api: string, body?: any, contentType?: string, disableToken?: boolean) => {
    try {
      await refreshToken();
      const token = Cookies.get('token');
      const res = await axios.put(Config.apiHostname + api, body, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? undefined : 'Bearer ' + token,
          'content-type': contentType || 'application/json',
        },
      });
      return res.data.data;
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  delete: async function (api: string, disableToken?: boolean, data?: any, contentType?: string) {
    try {
      await refreshToken();
      const token = Cookies.get('token');
      const res = await axios.delete(Config.apiHostname + api, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? undefined : 'Bearer ' + token,
          'content-type': contentType || 'application/json',
        },
        data,
      });
      return res.data.data;
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
  verify: async function (url: string) {
    try {
      await refreshToken();
      const token = Cookies.get('token');
      await axios.post(
        url,
        {},
        {
          headers: {
            accept: 'application/json',
            authorization: 'Bearer ' + token,
            'content-type': 'application/json',
          },
        }
      );
      return;
    } catch (e: any) {
      throw e && e.response && e.response.data && e.response.data.error ? e.response.data.error : e.toString();
    }
  },
};

export default Request;
