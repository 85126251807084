import React, { useEffect, useState, DragEvent } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';
import moment from 'moment';
import GeneralService from '../../../../../../services/general';
import OutcomeService from '../../../../../../services/finance/outcomes';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';
import { DatePicker, DefaultButton, DetailsList, Link, MessageBarType, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { IOutcomePaymentResourceProps, IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';
import { IFileDetailsProps } from '../../../../../../props/general';
import UploadFilesComponent from '../../../../../uiframeworks/files/uploads/uploadFile';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import PermissionsService from '../../../../../../services/permissions';
import { faUpload, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import LoadingComponent from '../../../../../feedbacks/loading';
import { IOutcomePaymentInvoiceResourceShortProps } from '../../../../../../props/finance/outcomes/payments/invoice';
import { IInternalBankAccountResourceShortProps } from '../../../../../../props/data/bankaccounts/internal';
import SelectInternalBankAccount from '../../../../../uiframeworks/forms/bankaccounts/internal';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileService from '../../../../../../services/general/file';
import OpenAIService from '../../../../../../services/systems/openai';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import SimpleMessageBarComponent from '../../../../../feedbacks/simpleMessageBar';

interface OutcomePaymentCompleteFormProps {
  payments: IOutcomePaymentResourceShortProps[];
  onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
  paymentDate: string;
  sourceBankAccount?: IInternalBankAccountResourceShortProps;
}

const OutcomePaymentCompleteForm: React.FC<OutcomePaymentCompleteFormProps> = (props: OutcomePaymentCompleteFormProps) => {
  const { banner, user } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>();
  const [preview, setPreview] = React.useState<string[]>();
  const [extracting, setExtracting] = React.useState<boolean>(false);
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [data, setData] = useState<FormDataProps>({
    paymentDate: moment().toISOString(),
    sourceBankAccount: props.payments[0].invoice.bankAccount
  });

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [payments, setPayments] = React.useState<IOutcomePaymentResourceProps[]>([]);
  const paymentMethod = PaymentMethods.find((m) => m.key === props.payments[0].method);

  const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.payments.approve'], user.permissions);

  const _onSubmit = async () => {
    setSubmitting(true)
    const fd = new FormData();

    payments.forEach((payment) => {
      fd.append('ids[]', payment.id);
    });

    fd.append('totalAmount', getTotalAmount() + "");
    fd.append('paymentDate', data.paymentDate);
    fd.append('sourceBankAccountId', data.sourceBankAccount?.id || "");

    /*files.map(f => {
      const file = f.data as File
      const allMetaData = {
        name: file.name,
        size: file.size,
        type: 'Proof of Payment'
      }
      fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, ...allMetaData }))
    })
    files.map(f => fd.append('documents[]', f.data))*/

    if (file) {
      fd.append('metadatas[]', JSON.stringify({ visibility: 'Public', name: file.name, type: "Proof of Payment" }));
      fd.append('documents[]', file);
    }

    try {
      await OutcomePaymentService.setComplete(fd);
      props.onDismissed(true)
    } catch (error) {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    _onRetrievePayment();
  }, []);

  const _onRetrievePayment = async () => {
    try {
      setLoaded(false);
      setPayments(props.payments);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_payment_error',
        text: 'Failed to retrieve payments. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const getTotalAmount = () => {
    let totalAmount = 0;
    payments.forEach((payment) => {
      totalAmount += Number(payment.amount);
    });

    return totalAmount;
  }

  const extract = async (file: File): Promise<{
    amount: number;
    notes?: string;
    paymentDate: string;
  } | undefined> => {
    try {
      const images = await FileService.getBase64(file);

      const fd = new FormData();
      fd.append('type', 'proofOfPayment');
      images.forEach((img) => fd.append("base64[]", img));

      const result = await OpenAIService.extractDocument(fd);
      return result;
    } catch (e) {
      return;
    }
  }

  const _onFileChange = async (_files: FileList | File[]) => {
    try {
      if (file) {
        if (!window.confirm("Are you sure you want to replace current proof of payment file?")) {
          return;
        }
      }

      const _file = _files[0];
      setFile(_file);

      const _warnings: string[] = [];
      if (_file) {
        const images = await FileService.getBase64(_file);
        setPreview(images);

        setExtracting(true);
        const result = await extract(_file);
        if (result && result.amount !== getTotalAmount()) {
          _warnings.push("Total amount does not matched the amount that is transfered");
        }
        if (result && moment(result.paymentDate).format("DD/MM/YYYY") !== moment(data.paymentDate).format("DD/MM/YYYY")) {
          _warnings.push("Payment date does not matched the transfer date from proof of payment. Make sure the payment date is correct.");
        }
      } else {
        _warnings.push("No proof of payment is uploaded");
      }

      setWarnings(_warnings);
      setExtracting(false);
    } catch (e) {
      setWarnings([]);
      setExtracting(false);
      setFile(undefined);
    }
  }

  return <Panel
    headerText={'Complete Payment'}
    isOpen={true}
    type={PanelType.large}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting && hasPermission ? (
            <>
              <PrimaryButton disabled={!file} text={"Submit"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
      </Stack>;
    }}
  >
    {hasPermission ? <Stack tokens={{ childrenGap: 15 }}>
      {!loaded ? <LoadingComponent label={'Retrieving form ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
      {warnings.length > 0 ? <Stack tokens={{ childrenGap: 5 }}>
        {warnings.map((warning) => <SimpleMessageBarComponent properties={{ type: MessageBarType.warning, content: <Text size={'small'}>{warning}</Text> }} />)}
      </Stack> : null}
      {loaded ? <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack grow={1} styles={{ root: { width: '50%' } }} tokens={{ childrenGap: 10 }}>
          <input type={'file'} onChange={(evt) => _onFileChange(evt?.target.files || [])} value={[]} />
          <Stack className={styles.preview}>
            {extracting ? <LoadingComponent label={'Generating file preview ...'} size={SpinnerSize.medium} /> : null}
            {preview && !extracting ? preview.map((preview) => {
              return <img src={preview} className={styles.image} />;
            }) : null}
            {!preview && !extracting ? <Text size={'small'} className={'color-white'}>No proof of payment selected</Text> : null}
          </Stack>
        </Stack>
        {extracting ? <LoadingDialogComponent title='Extracting Proof of Payment' secondaryText="Please wait while we extract proof of payment details ..." /> : null}
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 700 } }}>
          <Pivot className={'linePivot'}>
            <PivotItem key={'details'} headerText='Details'>
              <Stack tokens={{ childrenGap: 15 }}>
                <Stack styles={{ root: { marginTop: 15 } }}>
                  <Label size={'xsmall'}>Related payments</Label>
                  <DetailsList items={payments || []}
                    selectionMode={SelectionMode.none}
                    columns={[
                      {
                        key: 'name',
                        name: 'Invoice Details',
                        minWidth: 150,
                        maxWidth: 150,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                          return <Stack styles={{ root: { paddingTop: 8 } }}>
                            <Label size={'xsmall'}>{item.invoice.name}</Label>
                            {(item.invoice.invoiceNumber || "").trim() !== "" ? <Text size={'small'}>#{item.invoice.invoiceNumber}</Text> : null}
                            {item.invoice.vendor ? <Text size={'small'}>{item.invoice.vendor.name}</Text> : null}
                          </Stack>;
                        }
                      },
                      {
                        key: 'notes',
                        name: 'Notes',
                        minWidth: 0,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                          return <Stack styles={{ root: { paddingTop: 8 } }}>
                            <Text size={'small'}>{item.notes || "-"}</Text>
                          </Stack>;
                        }
                      },
                      {
                        key: 'amount',
                        name: 'Payment Amount',
                        minWidth: 125,
                        maxWidth: 125,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                          return <Stack styles={{ root: { paddingTop: 8 } }}>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount || '0'))}</Text>
                          </Stack>;
                        }
                      }
                    ]} />
                </Stack>
                <Stack>
                  <Label size={'xsmall'}>Total amount</Label>
                  <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getTotalAmount())}</Text>
                </Stack>
                <Stack className={'divider'} />
                <Label size={'small'}>Source Payment Details</Label>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack styles={{ root: { width: '50%' } }}>
                    <DatePicker label={'Payment date'}
                      value={moment(data.paymentDate).toDate()}
                      formatDate={GeneralService.formatDate}
                      onSelectDate={(date) => {
                        if (date != undefined) {
                          const _data = data;
                          _data.paymentDate = moment(date).toISOString();

                          setData({ ..._data });
                        }
                      }} />
                  </Stack>
                  <Stack styles={{ root: { width: '50%' } }}>
                    <SelectInternalBankAccount label={"Transfer from"}
                      selected={data.sourceBankAccount}
                      onChange={(bankaccount) => {
                        const _data = data;
                        _data.sourceBankAccount = bankaccount;

                        setData({ ..._data });
                      }} />
                  </Stack>
                </Stack>
                <Stack className={'divider'} />
                <Label size={'small'}>Target Payment Details</Label>
                <Stack>
                  <Label size={'xsmall'}>Vendor</Label>
                  <Text size={'small'}>{payments[0].invoice?.vendor?.name || "-"}</Text>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'xsmall'}>Method</Label>
                    <Text size={'small'}>{paymentMethod?.text || "Cash"}</Text>
                  </Stack>
                  {payments[0].method === 'transfer' && payments[0].targetBankAccount ? <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'xsmall'}>Account Number</Label>
                    <Text size={'small'}>{payments[0].targetBankAccount.accountNumber} - {payments[0].targetBankAccount.bank.name} ({payments[0].targetBankAccount.bankCity})</Text>
                  </Stack> : null}
                  {payments[0].method === 'virtualaccount' && props.payments[0].targetVirtualAccountNumber ? <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'xsmall'}>Virtual Account Number</Label>
                    <Text size={'small'}>{payments[0].targetVirtualAccountNumber}</Text>
                  </Stack> : null}
                </Stack>
              </Stack>
            </PivotItem>
          </Pivot>
        </Stack>
      </Stack> : null}
    </Stack> : null}
  </Panel>
};

export default OutcomePaymentCompleteForm;
