import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import AdministratorsList from '../../../components/users/administrators/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IAdministratorsListPageProps {
}

const AdministratorsListPage: React.FC<IAdministratorsListPageProps> = (props: IAdministratorsListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Administrators", [
            {key: "home", icon: faHome, href: "/"},
            {key: "users", text: 'Users'},
            {key: "administrators", text: 'Administrators'}
        ])
    }, []);

    return <Stack>
        <AdministratorsList />
    </Stack>;
};

export default AdministratorsListPage;
