import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TTagVariant } from "../components/uiframeworks/tag";

export type DocumentTypeProps = {
  key: string;
  text: string;
  varian?: TTagVariant;
  icon?: IconProp;
}

const DocumentTypes: DocumentTypeProps[] = [
  { key: "document", text: "Document" },
  { key: "bill_of_landing", text: "Bill of Landing" }
]

const VendorDocumentTypes: DocumentTypeProps[] = [
  { key: "ktp", text: "KTP Direktur" },
  { key: "npwp", text: "NPWP" }
]

const OrderDocumentTypes: DocumentTypeProps[] = [
  { key: "load", text: "Loading" },
  { key: "unload", text: "Unloading" },
  { key: "truck", text: "Dooring" },
  { key: "ship", text: "Shipping" },
  { key: "train", text: "Train" }
]

export { VendorDocumentTypes, OrderDocumentTypes }

export default DocumentTypes