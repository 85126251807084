import React from 'react';

import { Stack } from '@fluentui/react';
import styles from './styles.module.scss';
import Text from '../../../typography/text';
import Label from '../../../typography/label';
import { FormDataProps } from '.';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import GeneralService from '../../../../services/general';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import OrderService from '../../../../services/orders/order';


interface OfferSummaryProps {
    data: FormDataProps;
}

const OfferSummary: React.FC<OfferSummaryProps> = (props: OfferSummaryProps) => {
    const { data } = props;

    const getFreightDetails = () => {
        const text: string[] = [];
        const goodsType = DeliveredGoodsType.find((gt) => gt.key === data.freightDetails?.goodsType);

        if (data.freightDetails?.freightCategory) {
            text.push(data.freightDetails.freightCategory.name);
        }

        if (data.freightDetails?.deliveryType) {
            text.push(data.freightDetails.deliveryType.name);
        }

        if (goodsType) {
            text.push(goodsType.text);
        }

        return text.join(' - ');
    }

    const getAddressType = (type?: string) => {
        if (type === 'door') {
            type = 'Door';
        } else if (type === 'yard') {
            type = 'Container Yard';
        } else if (type === 'port') {
            type = 'Port';
        }

        return type || '';
    }

    const renderProfitTag = () => {
        const { data } = props;

        let tagVariant: TTagVariant = 'success';
        if (data.profitPercentage <= 0) {
            tagVariant = 'error';
        } else if (data.profitPercentage < 1.5) {
            tagVariant = 'warning';
        }

        return <Stack horizontal>
            <Tag variant={tagVariant} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.profitAmount))}`} />
        </Stack>
    }

    return <Stack tokens={{ childrenGap: 10 }}>
        <Label>Summary</Label>
        <Stack className='divider'></Stack>
        <Stack>
            <Label size={'small'}>Customer</Label>
            {data.customerDetails?.company && data.customerDetails?.customer ? <Text size={'small'}>{data.customerDetails.company.name} - {data.customerDetails?.customer.name}</Text> : <Text size={'small'}>-</Text>}
        </Stack>
        <Stack className='divider'></Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 3 }}>
                <Label size={'small'}>Freight Category</Label>
                <Text size={'small'}>{getFreightDetails()}</Text>
            </Stack>
            <Stack tokens={{ childrenGap: 3 }}>
                <Label size={'small'}>Origin</Label>
                <ol style={{ padding: '0px 10px', margin: 0 }}>
                    {(data.addressDetails?.originAddresses || []).filter((addr) => addr.address.trim() !== "").map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <Text size={'small'}><li>{text}</li></Text>
                    })}
                </ol>
            </Stack>
            <Stack tokens={{ childrenGap: 3 }}>
                <Label size={'small'}>Destination</Label>
                <ol style={{ padding: '0px 10px', margin: 0 }}>
                    {(data.addressDetails?.destinationAddresses || []).filter((addr) => addr.address.trim() !== "").map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <Text size={'small'}><li>{text}</li></Text>
                    })}
                </ol>
            </Stack>
        </Stack>
        <Stack className='divider'></Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Label size={'small'}>Costing</Label>
            <Stack horizontal verticalAlign='center' horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Subtotal</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.subtotalCost))}</Label>
            </Stack>
            {data.includedCosts?.ppn && data.ppnCost ? <Stack horizontal verticalAlign='center' horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>PPn</Text>
                <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.ppnCost.amount || '0')}</Text>
            </Stack> : null}
            {data.includedCosts?.pph && data.pphCost ? <Stack horizontal verticalAlign='center' horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>PPh</Text>
                <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.pphCost.amount || '0')}</Text>
            </Stack> : null}
            <Stack horizontal verticalAlign='center' horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Total</Text>
                <Tag text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.totalCost))}`} variant={'active'} />
            </Stack>
        </Stack>
        <Stack className='divider'></Stack>
        <Stack tokens={{ childrenGap: 10 }}>
            <Label size={'small'}>Pricing</Label>
            <Stack horizontal verticalAlign='center' horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Offered Price</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.offeredPrice?.totalPrice || '0'))}</Label>
            </Stack>
            <Stack horizontal verticalAlign='center' horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                <Text size={'small'}>Profit</Text>
                {renderProfitTag()}
            </Stack>

        </Stack>
    </Stack>
};

export default OfferSummary;
