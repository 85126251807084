import React from 'react';
import { useStore } from '../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { Stack } from '@fluentui/react';
import { IInvoiceResourceShortProps } from '../../../../../../../props/orders/invoice';
import { IVendorResourceShortProps } from '../../../../../../../props/data/vendors';
import { IBankAccountResourceShortProps } from '../../../../../../../props/general/bankAccount';
import { IOutcomeCategoryResourceShortProps } from '../../../../../../../props/finance/outcomes/categories';
import { IOutcomeResourceShorterProps } from '../../../../../../../props/finance/outcomes';
import OutcomeInvoicesSummaryFilterVendor from './vendor';
import OutcomeInvoicesSummaryFilterPaymentStatus from './paymentStatus';
import OutcomeInvoicesSummaryFilterCategory from './category';
import OutcomeInvoicesSummaryFilterTransferFrom from './transferFrom';

type OutcomeInvoicesSummaryFilterProps = {
    invoices: IOutcomeResourceShorterProps[];
    onChange(selectedFilter: SelectedFilterProps): void;
}

export type SelectedFilterProps = {
    vendor?: IVendorResourceShortProps[];
    transferFrom?: IBankAccountResourceShortProps[];
    category?: IOutcomeCategoryResourceShortProps[];
    paymentStatus?: string[];
}

export type TSelectedFilterKey = 'vendor' | 'paymentStatus' | 'category' | 'transferFrom';

const OutcomeInvoicesSummaryFilter: React.FC<OutcomeInvoicesSummaryFilterProps> = (props: OutcomeInvoicesSummaryFilterProps) => {
    const { banner } = useStore();
    const [selectedFilter, setSelectedFilter] = React.useState<SelectedFilterProps>({});

    const _onFilterChange = (key: TSelectedFilterKey, selected: any) => {
        let _selectedFilter = selectedFilter;
        _selectedFilter[key] = selected;

        setSelectedFilter({..._selectedFilter});
        props.onChange(_selectedFilter);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 30}}>
        <OutcomeInvoicesSummaryFilterPaymentStatus invoices={props.invoices} onChange={_onFilterChange} />
        <OutcomeInvoicesSummaryFilterVendor invoices={props.invoices} onChange={_onFilterChange} />
        <OutcomeInvoicesSummaryFilterCategory invoices={props.invoices} onChange={_onFilterChange} />
        <OutcomeInvoicesSummaryFilterTransferFrom invoices={props.invoices} onChange={_onFilterChange} />
    </Stack>;
};

export default OutcomeInvoicesSummaryFilter;
