import Request from '../../request';

// import props
import { IShipScheduleDelayResourceShort } from '../../../props/data/ships';

const ShipScheduleDelaysService = {
  retrieve: async (qs?: string): Promise<IShipScheduleDelayResourceShort[]> => {
    try {
      const results = await Request.get(`/api/data/shipScheduleDelays?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<IShipScheduleDelayResourceShort> => {
    try {
      const result = await Request.post(`/api/data/shipScheduleDelays`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IShipScheduleDelayResourceShort> => {
    try {
      const result = await Request.post(
        `/api/data/shipScheduleDelays/${id}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/data/shipScheduleDelays/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

export default ShipScheduleDelaysService;
