import React from 'react';

// assets

// services
import ValidationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// props

// components
import { ActionButton, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { OfferedPriceItemData } from '.';
import VendorsService from '../../../../../services/data/vendors';
import { IQuantityUnitResourceShortProps } from '../../../../../props/general/quantityUnit';
import { FormDataProps } from '..';
import containersType from '../../../../../manifests/containersType';
import trucksType from '../../../../../manifests/trucksType';

type OfferedPriceFormProps = {
    quantityUnits: IQuantityUnitResourceShortProps[];
    data?: Partial<OfferedPriceItemData>;
    fd: FormDataProps;
    onDismissed(): void;
    onSubmit(data: OfferedPriceItemData): void;
}

type FormDataErrorProps = {
    price?: string;
    quantityAmount?: string;
    notes?: string;
    name?: string;
}

const OfferedPriceForm: React.FC<OfferedPriceFormProps> = (props: OfferedPriceFormProps) => {
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [data, setData] = React.useState<Partial<OfferedPriceItemData>>(props.data || {
        id: GeneralService.guid(),
        priceType: 'lumpsum',
        price: '',
        totalPrice: '',
        type: '',
        name: '',
        quantityAmount: ''
    });

    const isSubmitButtonDisabled = () => {
        if ((data.name || "").trim() === "" || (data.price || "").trim() === "" || (data.totalPrice || "").trim() === "" || (data.type || "").trim() === "" || !data.quantityUnit || (data.quantityAmount || "").trim() === "") {
            return true;
        } else if (error.price || error.quantityAmount || error.notes || error.name) {
            return true;
        }

        return false;
    }

    const calculateTotalPrice = (_data: Partial<OfferedPriceItemData>) => {
        let totalPrice = 0;

        if (_data.priceType === 'lumpsum') {
            totalPrice = Number(_data.price);
        } else if ((_data.price || "").trim() !== "" && (_data.quantityAmount || "").trim() !== "") {
            totalPrice = Number(_data.price) * Number(_data.quantityAmount);
        }

        return totalPrice;
    }

    return <Panel headerText={'Add Offered Price'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={props.data ? "Update" : "Add"} disabled={isSubmitButtonDisabled()} onClick={() => props.onSubmit(data as OfferedPriceItemData)} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <Stack horizontal tokens={{childrenGap: 10}}>
                <Stack.Item styles={{root: {width: 125}}}>
                    <Dropdown label={'Name'} 
                        required={true}
                        selectedKey={data.type}
                        options={[
                            { key: "container", text: 'Container', disabled: props.fd.freightDetails?.goodsType !== 'fcl' },
                            { key: "truck", text: 'Truck', disabled: props.fd.freightDetails?.goodsType !== 'ftl' },
                            { key: "goods", text: 'Goods', disabled: props.fd.freightDetails?.goodsType === 'fcl' || props.fd.freightDetails?.goodsType === 'ftl' },
                            { key: "others", text: 'Others' }
                        ]}
                        onChange={(evt, opt) => {
                            data.type = opt ? opt.key as string : undefined;
                            setData({ ...data });
                        }} />
                </Stack.Item>
                {data.type === 'container' ? <Stack.Item grow={1}>
                    <Dropdown options={containersType}
                        styles={{root: {marginTop: 25}}}
                        selectedKey={data.name}
                        onChange={(evt, opt) => {
                            data.name = opt ? opt.key as string : undefined;

                            const validation = ValidationService.combination(data.name, ['required'], {});
                            error.name = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.name} />
                </Stack.Item> : null}
                {data.type === 'truck' ? <Stack.Item grow={1}>
                    <Dropdown options={trucksType}
                        styles={{root: {marginTop: 25}}}
                        selectedKey={data.name}
                        onChange={(evt, opt) => {
                            data.name = opt ? opt.key as string : undefined;

                            const validation = ValidationService.combination(data.name, ['required'], {});
                            error.name = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.name} />
                </Stack.Item> : null}
                {data.type === 'others' || data.type === 'goods' ? <Stack.Item grow={1}>
                    <TextField value={data.name}
                        styles={{root: {marginTop: 25}}}
                        onChange={(evt, value) => {
                            data.name = value || '';

                            const validation = ValidationService.combination(data.name, ['required', 'limit'], { maxChars: 100 });
                            error.name = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }}
                        errorMessage={error.name} />
                </Stack.Item> : null}
                {(data.type || "").trim() === "" ? <Stack.Item grow={1}>
                    <TextField disabled={true} styles={{root: {marginTop: 25}}} />
                </Stack.Item> : null}
            </Stack>
            <Stack className={'divider'}></Stack>
            <Stack.Item>
                <Dropdown label={"Price type"}
                    selectedKey={data.priceType}
                    options={[
                        { key: 'lumpsum', text: 'Lump sum' },
                        { key: 'perquantity', text: 'Per quantity' }
                    ]}
                    required={true}
                    onChange={(evt, opt) => {
                        data.priceType = opt ? opt.key as any : 'perquantity';
                        data.totalPrice = calculateTotalPrice(data).toFixed(2);

                        setData({ ...data });
                    }} />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Stack.Item styles={{ root: { width: 125 } }}>
                        <TextField label={"Quantity"}
                            value={data.quantityAmount}
                            required={true}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.quantityAmount = value || "";
                                    data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 18 });
                                    error.quantityAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            errorMessage={error.quantityAmount} />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: 100 } }}>
                        <Dropdown styles={{ root: { marginTop: 25 } }}
                            selectedKey={data.quantityUnit?.id}
                            options={props.quantityUnits.map((unit) => { return { key: unit.id, text: unit.name } })}
                            onChange={(evt, opt) => {
                                const selectedId = opt ? opt.key as string : undefined;
                                data.quantityUnit = props.quantityUnits.find((unit) => unit.id === selectedId);

                                setData({ ...data });
                            }} />
                    </Stack.Item>
                </Stack>
                <Stack.Item grow={1}>
                    <TextField label={data.priceType === 'perquantity' ? "Price per quantity" : "Price"}
                        value={data.price}
                        required={true}
                        prefix={'Rp'}
                        suffix={data.priceType === 'perquantity' ? `/ ${data.quantityUnit ? data.quantityUnit.name : 'quantity'}` : undefined}
                        onChange={(evt, value) => {
                            if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                data.price = value || "";
                                data.totalPrice = calculateTotalPrice(data).toFixed(2);

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 18 });
                                error.price = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }
                        }}
                        errorMessage={error.price} />
                </Stack.Item>
            </Stack>
            {data.priceType === 'perquantity' ? <>
                <Stack className={'divider'}></Stack>
                <Stack.Item>
                    <TextField label={"Total price"}
                        value={GeneralService.getNumberWithSeparator(Number(data.totalPrice))}
                        disabled={true}
                        prefix={'Rp'} />
                </Stack.Item>
            </> : null}
        </Stack>
    </Panel >
};

export default OfferedPriceForm;
