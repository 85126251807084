import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../stores/root';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import ValidationService from '../../../../../services/validation';
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import ImageUpload from '../../../../uploadImage';
import GlobalConfig from '../../../../../config';
import KeyService from '../../../../../services/data/keys';
import { PublicKeyTypes } from '../../../../../manifests/PublicKeyTypes';

interface IKeyFormProps {
    keyId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    key: string
}

type FormDataErrorProps = {
    name?: string;
    key?: string;
}

const KeyForm: React.FC<IKeyFormProps> = (props: IKeyFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        key: ''
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.keyId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.keyId) {
            const _key = await KeyService.get(props.keyId);
            setData({
                name: _key.name,
                key: _key.key
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name) {
            return true;
        } else if (data.name.trim() === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            // setSubmitting(true);
            const { name, key } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("key", key);

            if (props.keyId === undefined) {
                await KeyService.new(fd);
            } else {
                await KeyService.update(props.keyId, fd);
            }
            banner.add({
                key: 'create_key_success',
                variant: 'success',
                icon: faCheck,
                text: `Key "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Key' : 'Update Key'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating key ..." : "Updating key ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? (
                    <>
                        <Stack.Item>
                            <Dropdown
                                label={"Name"}
                                required={true}
                                disabled={submitting}
                                selectedKey={data.name}
                                options={PublicKeyTypes}
                                onChange={(evt, opt) => {
                                    data.name = opt ? opt.key as string : "";
                                    setData({ ...data });
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label={"Key"}
                                required={true}
                                value={data.key}
                                onChange={(evt, value) => {
                                    data.key = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 2000 });
                                    error.key = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.key}
                                disabled={submitting}
                            />
                        </Stack.Item>
                    </>
                ) : null
            }
        </Stack>
    </Panel>
};

export default KeyForm;
