import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../services/general/error';

// props

// components
import { Dropdown, Link, SpinnerSize, Stack } from '@fluentui/react';
import LoadingComponent from '../../../feedbacks/loading';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { IQuantityUnitResourceShortProps } from '../../../../props/general/quantityUnit';
import QuantityUnitService from '../../../../services/general/quantityUnit';
import QuantityUnitForm from '../../../settings/quantityUnits/general/form';

type SelectQuantityUnitProps = {
    selected?: IQuantityUnitResourceShortProps;
    options?: IQuantityUnitResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    style?: any;
    onChange(selected?: IQuantityUnitResourceShortProps): void;
}

const SelectQuantityUnit: React.FC<SelectQuantityUnitProps> = (props: SelectQuantityUnitProps) => {
    const { banner } = useStore();
    const [options, setOptions] = React.useState<IQuantityUnitResourceShortProps[]>(props.options || []);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IQuantityUnitResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            if (!props.options) {
                const _quantityUnits = await QuantityUnitService.retrieve();
                setOptions(_quantityUnits);
            }

            setLoaded(true);
        } catch(e) {
            banner.add({
                key: 'retrieve_quantity_units_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to retrieve quantity units. Error: ${ErrorService.getMessage(e)}`
            });
        }
    }

    return <Stack styles={{root: props.style}}>
        {props.hideLabel ? null : <Label size={'xsmall'} required={props.required} style={{padding: '3px 0px'}}>{props.label || "Quantity unit"}</Label>}
        {!loaded ? <LoadingComponent size={SpinnerSize.xSmall} label={'Retrieving ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded ? <>
            <Dropdown options={options.map((opt) => {
                    return { key: opt.id, text: opt.name, metadata: opt };
                })}
                selectedKey={selected?.id}
                disabled={props.disabled}
                onChange={(evt, opt: any) => {
                    const selected = opt ? opt.metadata : undefined;
                    props.onChange(selected);
                }}
            />
            {props.allowCreate && !selected ? <Text size={'small'}>Cannot find unit? <Link onClick={() => {setActiveSurface('create')}}>Click here</Link> to create quantity unit</Text> : null}
        </> : null}
        {activeSurface === 'create' ? <QuantityUnitForm onDismissed={(refresh, unit) => {
            setActiveSurface(undefined);
            props.onChange(unit);
        }} /> : null}
    </Stack>;
};

export default SelectQuantityUnit;
