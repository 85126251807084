import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import ValidationService from '../../../../../services/validation';
import VendorsService from '../../../../../services/data/vendors';
import ShipsService from '../../../../../services/data/ships';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../typography/label';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';
import { IShipResourceShortProps } from '../../../../../props/data/ships';
import VendorField from '../../../../uiframeworks/forms/vendor';
import SelectFleetType from '../../../../uiframeworks/forms/selectFleetType';
import { ISystemFleetTypeResourceShortProps } from '../../../../../props/systems/fleetTypes';

interface IShipFormProps {
    shipId?: string;
    vendorId?: string;
    onDismissed(refresh?: boolean, ship?: IShipResourceShortProps): void;
}

type FormDataProps = {
    name: string;
    registrationNumber: string;
    fleetType?: ISystemFleetTypeResourceShortProps;
    vendor?: IVendorResourceShortProps;
}

type FormDataErrorProps = {
    name?: string;
    registrationNumber?: string;
    vendor?: string;
    fleetType?: string;
}

const ShipForm: React.FC<IShipFormProps> = (props: IShipFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        registrationNumber: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.shipId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['ships.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['ships.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _data = data;

        if (props.shipId) {
            const _ship = await ShipsService.get(props.shipId);
            _data = {
                name: _ship.name,
                registrationNumber: _ship.registrationNumber,
                vendor: _ship.vendor,
                fleetType: _ship.fleetType
            };
        } else if (props.vendorId) {
            const _vendor = await VendorsService.get(props.vendorId);
            _data.vendor = _vendor;
        }

        setData({..._data});
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.vendor || error.registrationNumber || error.fleetType) {
            return true;
        } else if (data.name === '' || !data.vendor || !data.fleetType) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, registrationNumber, vendor, fleetType } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("registrationNumber", registrationNumber);
            if (vendor) {
                fd.append("vendorId", vendor.id);
            }
            if (fleetType) {
                fd.append("fleetTypeId", fleetType.id);
            }

            let result;
            if (props.shipId === undefined) {
                result = await ShipsService.new(fd);
            } else {
                result = await ShipsService.update(props.shipId, fd);
            }
            banner.add({
                key: mode + '_ship_success',
                variant: 'success',
                icon: faCheck,
                text: `Ship "${name}" ${mode}ed successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Ship' : 'Update Ship'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating ship ..." : "Updating ship ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <VendorField label={'Select vendor'}
                        selected={data.vendor}
                        disabled={submitting || props.vendorId !== undefined}
                        onChange={(item) => {
                            data.vendor = item;
                            setData({ ...data });
                        }} />
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Registration Number"}
                            required={true}
                            value={data.registrationNumber}
                            onChange={(evt, value) => {
                                data.registrationNumber = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.registrationNumber = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.registrationNumber}
                            disabled={submitting} />
                    </Stack.Item>
                    <SelectFleetType selected={data.fleetType}
                        disabled={submitting}
                        errorMessage={error.fleetType}
                        qs={[`type=Ship`]}
                        required
                        onChange={(item) => {
                            data.fleetType = item;

                            const validation = ValidationService.combination(item, ['required'], {});
                            error.fleetType = validation.message;

                            setData({ ...data });
                        }} />
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default ShipForm;
