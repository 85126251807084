import moment from 'moment';
import ExcelJS from 'exceljs';
import Request from './../request';
import { IOutcomePaymentResourceShortProps } from '../../props/finance/outcomes/payments';

const DocumentService = {
  get: async (path: string) => {
    try {
      const result = await Request.getBlob(`/api/document?path=${path}`);
      return result;
    } catch (e) {
      throw (e);
    }
  },
  getReportTemplate: async () => {
    try {
      const result = await Request.getArrayBuffer(`/api/report_template`);
      return result.data;
    } catch (e) {
      throw (e);
    }
  },
  dowonloadPaymentsReport: async (payments: IOutcomePaymentResourceShortProps[]) => {
    try {
      const data = await DocumentService.getReportTemplate()

      const templateBuffer = Buffer.from(data);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateBuffer);
      const worksheet = workbook.getWorksheet('PENJUALAN');



      const dataPenjualan = payments.map(payment => {
        let vendor = '-';

        /*if (payment.invoice.vendor) {
          vendor = payment.invoice.vendor.name
        } else if (payment.invoice.ship) {
          vendor = payment.invoice.ship.name
        } else if (payment.invoice.truck) {
          vendor = payment.invoice.truck.registrationNumber
        } else if (payment.invoice.train) {
          vendor = payment.invoice.train.name
        } else if (payment.invoice.trainSchedule) {
          vendor = `Voy #${payment.invoice.trainSchedule.voy}`
        } else if (payment.invoice.shipSchedule) {
          vendor = `Voy #${payment.invoice.shipSchedule.voy}`
        }*/

        return ({
          created_at: moment(payment.createdAt).format('DD/MM/YYYY'),
          npwp: '-',
          no_f_pajak: '-',
          no_faktur_jual: '',
          uraian: payment.notes ?? '-',
          customer: vendor,
          penjualan: payment.amount ? parseFloat(payment.amount) : 0,
          PPN: payment.ppn ? parseFloat(payment.ppn) : 0,
          PPH: payment.ppn ? parseFloat(payment.ppn) : 0,
          total_piutang: payment.totalAmount ? parseFloat(payment.totalAmount) : 0,
        })
      });

      if (worksheet) {
        let rowNumber = 6;

        dataPenjualan.forEach((penjualan) => {
          const newRow = worksheet.getRow(rowNumber);

          worksheet.getCell(`A${rowNumber}`).value = penjualan.created_at;
          worksheet.getCell(`B${rowNumber}`).value = penjualan.npwp;
          worksheet.getCell(`C${rowNumber}`).value = penjualan.no_f_pajak;
          worksheet.getCell(`D${rowNumber}`).value = penjualan.no_faktur_jual;
          worksheet.getCell(`E${rowNumber}`).value = penjualan.uraian;
          worksheet.getCell(`F${rowNumber}`).value = penjualan.customer;
          worksheet.getCell(`G${rowNumber}`).value = penjualan.penjualan;
          worksheet.getCell(`H${rowNumber}`).value = penjualan.PPN;
          worksheet.getCell(`I${rowNumber}`).value = penjualan.PPH;
          worksheet.getCell(`J${rowNumber}`).value = penjualan.total_piutang;

          rowNumber++;
        });

        worksheet.getCell(`J3`).value = { formula: 'SUBTOTAL(9,J6:J65561)' };
        worksheet.getCell(`I3`).value = { formula: 'SUBTOTAL(9,I6:I65561)' };
        worksheet.getCell(`H3`).value = { formula: 'SUBTOTAL(9,H6:H65561)' };
        worksheet.getCell(`G3`).value = { formula: 'SUBTOTAL(9,G6:G65561)' };
      }

      const blob = await workbook.xlsx.writeBuffer();

      const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = `report.xlsx`;
      downloadLink.click();

      URL.revokeObjectURL(blobUrl);

    } catch (error) {
      throw error
    }
  }
};

export default DocumentService;
