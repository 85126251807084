import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../../stores/root';

// assets

// services

// props

// components
import { ActionButton, Checkbox, DatePicker, DefaultButton, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { FormDataProps } from '../../props';
import FileService from '../../../../../../../../services/general/file';
import OutcomeService from '../../../../../../../../services/finance/outcomes';
import LoadingComponent from '../../../../../../../feedbacks/loading';
import Text from '../../../../../../../typography/text';
import Label from '../../../../../../../typography/label';
import GeneralService from '../../../../../../../../services/general';
import OutcomeTypes from '../../../../../../../../manifests/outcomeTypes';
import ValidationService from '../../../../../../../../services/validation';
import { PPhPercentageOptions, PPNPercentageOptions } from '../../../../../../../../manifests/taxPercentage';
import moment from 'moment';
import VendorField from '../../../../../../../uiframeworks/forms/vendor';
import ShipField from '../../../../../../../uiframeworks/forms/ship';
import ShipScheduleField from '../../../../../../../uiframeworks/forms/shipSchedule';
import SelectTruck from '../../../../../../../uiframeworks/forms/selectTruck';
import { IVendorResourceShortProps } from '../../../../../../../../props/data/vendors';
import LoadingDialogComponent from '../../../../../../../feedbacks/loadingDialog';
import SelectTrain from '../../../../../../../uiframeworks/forms/selectTrain';
import SelectTrainSchedule from '../../../../../../../uiframeworks/forms/selectTrainSchedule';
import { IOutcomeOrderResourceShortProps } from '../../../../../../../../props/finance/outcomes/orders';
import SelectOrder from '../../../../../../../uiframeworks/forms/selectOrder';


type InvoiceOrderSubformProps = {
    disabled?: boolean;
    data: Partial<FormDataProps>;
    order?: Partial<IOutcomeOrderResourceShortProps>;
    onDismissed(order?: IOutcomeOrderResourceShortProps): void;
}

const InvoiceOrderSubform: React.FC<InvoiceOrderSubformProps> = (props: InvoiceOrderSubformProps) => {
    let remainingAmount = Number(props.data.totalAmount || "0");
    (props.data.orders || []).forEach((o) => remainingAmount -= Number(o.amount));

    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Partial<IOutcomeOrderResourceShortProps>>(props.order || {
        id: GeneralService.guid(),
        amount: remainingAmount + ""
    });
    const [error, setError] = React.useState<any>({});

    useEffect(() => {
    }, []);

    const isSubmitButtonDisabled = () => {
        if (!data.order || ((data.amount || "") + "").trim() === "") {
            return true;
        } else if (error.amount) {
            return true;
        }

        return false;
    }

    return <Panel headerText={props.order ? "Update Related Order" : "Add Related Order"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed()}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'} verticalAlign='center'>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={() => {
                        if (data.order) {
                            props.onDismissed({
                                id: data.id || GeneralService.guid(),
                                amount: data.amount || "0",
                                paid: data.paid || 0,
                                unpaid: data.unpaid || 0,
                                order: data.order,
                                orderId: data.orderId || ""
                            });
                        }
                    }} />
                    <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed() }} />
                </Stack>
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            <Stack>
                <Label size={'xsmall'}>Remaining amount</Label>
                <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(remainingAmount)}</Text>
            </Stack>
            <SelectOrder selected={data.order}
                required
                onChange={(order) => {
                    data.order = order;
                    setData({ ...data });
                }} />
            <TextField label={"Amount"}
                prefix='Rp'
                required={true}
                value={(data.amount === undefined ? "" : data.amount) + ""}
                onChange={(evt, value) => {
                    if ((value || "").trim() === "" || !isNaN(Number(value))) {
                        data.amount = value !== "" ? (value || "") : undefined;

                        const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 15, max: remainingAmount });
                        error.amount = validation.message;

                        if (!error.amount && remainingAmount < (Number(data.amount || "0"))) {
                            error.amount = "Amount cannot exceed remaining amount"
                        }

                        setData({ ...data });
                        setError({ ...error });
                    }
                }}
                errorMessage={error.amount} />
        </Stack>
    </Panel>
};

export default InvoiceOrderSubform;
