import React, { useEffect } from 'react';
import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { Stack } from '@fluentui/react';
import NewsForm from '../../../components/settings/news/general/form';

type TypeCreateNewsPageProps = {
  children?: React.ReactNode;
};

const CreateNewsPage: React.FC<TypeCreateNewsPageProps> = (props) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Create News", [
      { key: "home", icon: faHome, href: "/" },
      { key: "settings", text: 'Settings', href: "/settings" },
      { key: "news", text: 'Public News', href: "/settings/news" },
      { key: "create", text: 'Create News' }
    ])
  }, []);

  return (
    <Stack>
      <NewsForm />
    </Stack>
  )
};

export default CreateNewsPage;