import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from '../../../../typography/text';
import SubsidiariesForm from '../form';
import Pagination from '../../../../uiframeworks/pagination';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../../services/data/subsidiaries';
import DeleteSubsidiariesForm from '../delete';
import ISalesTeamResourceShort from '../../../../../props/users/team';
import PermissionsService from '../../../../../services/permissions';

interface ISubsidiariesListProps {
    team?: ISalesTeamResourceShort
}

const SubsidiariesList: React.FC<ISubsidiariesListProps> = (props: ISubsidiariesListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [subsidiaries, setSubsidiaries] = React.useState<ISubsidiariesResourceShort[]>([]);
    const [activeSubsidiaries, setActiveSubsidiaries] = React.useState<ISubsidiariesResourceShort | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "name",
            fieldName: "name",
            name: "Name",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: ISubsidiariesResourceShort) => {
                return <Stack>
                    <NavLink to={`/settings/subsidiaries/${item.id}`}><Text>{item.name}</Text></NavLink>
                </Stack>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ISubsidiariesResourceShort) => {
                const menuItems: IContextualMenuItem[] = [
                    {
                        key: "update",
                        text: "Update details",
                        iconProps: { iconName: "Edit" },
                        onClick: () => {
                            setActiveSubsidiaries(item);
                            setActiveSurface('update');
                        }
                    },
                    {
                        key: "delete",
                        text: "Delete",
                        iconProps: { iconName: "Delete" },
                        onClick: () => {
                            setActiveSubsidiaries(item);
                            setActiveSurface('delete');
                        }
                    },
                ];

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item>
                </Stack.Item>;
            },
        },
        {
            key: "npwp",
            fieldName: "npwp",
            name: "NPWP",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: ISubsidiariesResourceShort) => {
                return <Stack>
                    <Text>{item.npwp}</Text>
                </Stack>;
            }
        },

    ];

    useEffect(() => {
        _onRetrieveSubsidiaries();
    }, []);

    useEffect(() => {
        _onRetrieveSubsidiaries();
    }, [keyword]);

    const _onRetrieveSubsidiaries = async (pageNumber?: number,) => {
        setLoaded(false);
        try {
            const qs: string[] = [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (props.team) { qs.push(`teamId=${props.team?.id}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await SubsidiariesService.retrieve(qs.join("&"));
            setSubsidiaries(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'subsidiaries_list_error',
                text: 'Failed to retrieve subsidiaries list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        if (PermissionsService.hasPermission(['subsidiaries.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Subsidiary",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Subsidiary"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveSubsidiaries(undefined);

        if (refresh) { _onRetrieveSubsidiaries() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack tokens={{ childrenGap: 10 }}>
            {pagination && subsidiaries.length > 0 ? <Pagination goToPage={_onRetrieveSubsidiaries} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " administrator(s) found"} /> : null}
            <Stack className={styles.list}>
                {
                    !loaded || (loaded && subsidiaries.length > 0) ? (
                        <>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={subsidiaries}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={!loaded}
                                onShouldVirtualize={() => false}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details" />
                        </>
                    ) : null
                }
                {loaded && subsidiaries.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                    <Text>Subsidiaries(s) not found</Text>
                </Stack> : null}
            </Stack>
            {pagination && subsidiaries.length > 0 ? <Pagination goToPage={_onRetrieveSubsidiaries} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
        </Stack>
        {activeSurface === 'create' ? <SubsidiariesForm team={props.team} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeSubsidiaries ? <SubsidiariesForm team={props.team} subsidiariesId={activeSubsidiaries.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && activeSubsidiaries ? <DeleteSubsidiariesForm subsidiaries={activeSubsidiaries} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default SubsidiariesList;
