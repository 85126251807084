import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { observer } from 'mobx-react';
import { useStore } from '../../stores/root';
import ComingSoon from '../../components/uiframeworks/comingSoon';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import DashboardSchedule from '../../components/dashboard/schedule';
import DetailsStack from '../../components/uiframeworks/detailsStack';
import EmployeeContractsList from '../../components/hr/contracts/general/list';

interface HRDashboardPageProps {
}

const HRDashboardPage: React.FC<HRDashboardPageProps> = (props: HRDashboardPageProps) => {
    const store = useStore();
    
    
    useEffect(() => {
        store.topbar.show('HR Dashboard', [
            {key: 'home', icon: faHome},
            {key: 'HR', text: 'HR'}
        ]);
    }, []);

    return <Stack>
        <DetailsStack left={[
            {
                key: 'activeContract',
                header: {title: 'Active Contracts'},
                content: <EmployeeContractsList />
            }
        ]} />
    </Stack>;
};

export default HRDashboardPage;
