import { ActionButton, IButtonProps, Link, Pivot, PivotItem, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import styles from './styles.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './../../typography/text';
import Label from '../../typography/label';

export type TTagVariant = 'default' | 'success' | 'light' | 'error' | 'warning' | 'transparent';

type PaginationProps = {
    text?: string;
    currentPage: number;
    totalPage: number;
    goToPage(pageNumber: number): void;
};

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<{
        showAll: boolean;
        shownPages: string[];
        isFirst: boolean;
        isMiddle: boolean;
        isLast: boolean;
        currentPage: number;
    }>({
        currentPage: 1,
        showAll: true,
        shownPages: [],
        isFirst: true,
        isMiddle: false,
        isLast: false
    });

    useEffect(() => {
        resetData();
    }, [props.currentPage]);

    const resetData = () => {
        const maxNumber = 7;
        data.currentPage = props.currentPage;

        if (props.totalPage > maxNumber) {
            if (props.currentPage <= (maxNumber - 3)) {
                data.isFirst = true;
                data.isLast = false;
                data.isMiddle = false;
                data.showAll = false;
                data.shownPages = [];
                for (var ctr = 1; ctr <= (maxNumber - 2); ctr++) {
                    data.shownPages.push(ctr + "");
                }
            } else if (props.currentPage >= props.totalPage - (maxNumber - 4)) {
                data.isFirst = false;
                data.isLast = true;
                data.isMiddle = false;
                data.showAll = false;
                data.shownPages = [];
                for (var ctr = props.totalPage - (maxNumber - 3); ctr <= props.totalPage; ctr++) {
                    data.shownPages.push(ctr + "");
                }
            } else {
                data.isFirst = false;
                data.isLast = false;
                data.isMiddle = true;
                data.showAll = false;
                data.shownPages = [];
                data.shownPages.push((props.currentPage - 1) + "");
                data.shownPages.push((props.currentPage) + "");
                data.shownPages.push((props.currentPage + 1) + "");
            }
        } else {
            data.showAll = true;
            data.shownPages = [];
            for (var ctr = 1; ctr <= props.totalPage; ctr++) {
                data.shownPages.push(ctr + "");
            }
        }

        setData({...data});
        setLoading(false);
    }

    const renderPageNumber = (pageNumber: number) => {
        const isActive = data.currentPage === pageNumber;
        return <li className={`${styles.item} ${isActive ? styles.active : ''}`}>
            <Link className={`${styles.link} ${isActive && loading ? styles.loading : ''}`} onClick={() => {
                setLoading(true);
                props.goToPage(pageNumber);
            }}>
                {!loading || !isActive ? pageNumber : null}
                {isActive && loading ? <Spinner size={SpinnerSize.small} /> : null}
            </Link>
        </li>
    }

    return <Stack className={styles.container} horizontal horizontalAlign={'space-between'} verticalAlign={'center'} tokens={{ childrenGap: 20 }}>
        <Stack>
            {props.text ? <Text size={'small'}>{props.text}</Text> : null}
        </Stack>
        {props.totalPage > 1 ? <Stack horizontal tokens={{childrenGap: 2}} verticalAlign='center'>
            <ActionButton onClick={() => props.goToPage(props.currentPage - 1)} className={styles.button} iconProps={{iconName: "ChevronLeft"}} disabled={data.currentPage === 1} />
            <ul className={styles.list}>
                {!data.showAll && !data.isFirst ? renderPageNumber(1) : null}
                {!data.showAll && !data.isFirst ? <li className={`${styles.item} ${styles.helper}`}><Text>...</Text></li> : null}
                {data.shownPages.map((pageNumber) => renderPageNumber(Number(pageNumber)))}
                {!data.showAll && !data.isLast ? <li className={`${styles.item} ${styles.helper}`}><Text>...</Text></li> : null}
                {!data.showAll && !data.isLast ? renderPageNumber(props.totalPage) : null}
            </ul>
            <ActionButton onClick={() => props.goToPage(props.currentPage + 1)} className={styles.button} iconProps={{iconName: "ChevronRight"}} disabled={data.currentPage === props.totalPage} />
        </Stack> : null}
    </Stack>
};

export default Pagination;
