import Request from '../request';
import { IPublicNewsResourceShort } from '../../props/data/news';
import { IPaginationResourceShort } from '../../props/general';

const PublicNewsService = {
  retrieve: async (qs?: string): Promise<{ data: IPublicNewsResourceShort[], pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/news?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicNewsResourceShort> => {
    try {
      const results = await Request.get(`/api/news/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<IPublicNewsResourceShort> => {
    try {
      const result = await Request.post(`/api/news`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IPublicNewsResourceShort> => {
    try {
      const result = await Request.post(`/api/news/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/news/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

const PublicNewsImageService = {
  delete: async (data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/news/images/delete`, data);
      return;
    } catch (e) {
      throw e;
    }
  },
}

export { PublicNewsImageService }

export default PublicNewsService;
