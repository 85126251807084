import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';
import OrderGoodsService from '../../../../../services/orders/orderGoods';

// props
import { IGoodsResourceShortProps } from '../../../../../props/goods/goods';

// components
import { Checkbox, DefaultButton, Dropdown, IconButton, Link, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField, Toggle } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import GoodsService from '../../../../../services/goods/goods';
import ErrorService from '../../../../../services/general/error';
import GoodsForm from '../../../../goods/general/form';
import { PPNPercentageOptions, PPhPercentageOptions } from '../../../../../manifests/taxPercentage';

interface IOrderGoodsFormProps {
    orderId: string;
    orderGoodsId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    goods?: IGoodsResourceShortProps;
    quantityAmount: string;
    notes: string;
    pricingMethod: string;
    ppn: string;
    pph: string;
    includePPN?: boolean;
    includePPh?: boolean;
    ppnPercentage: string;
    pphPercentage: string;
    price: string;
    finalPrice: string;
    nettPrice: string;
    invoicePrice: string;
}

type FormDataErrorProps = {
    goods?: string;
    quantityAmount?: string;
    notes?: string;
    price?: string;
    ppn?: string;
    pph?: string;
}

const OrderGoodsForm: React.FC<IOrderGoodsFormProps> = (props: IOrderGoodsFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        quantityAmount: "",
        notes: "",
        pricingMethod: "perquantity",
        price: "",
        ppn: "0",
        pph: "0",
        ppnPercentage: "0",
        pphPercentage: "0",
        finalPrice: "0",
        nettPrice: "0",
        invoicePrice: "0"
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const mode: 'create' | 'update' = props.orderGoodsId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.orderGoodsId) {
            const _orderGoods = await OrderGoodsService.get(props.orderId, props.orderGoodsId);

            setData({
                goods: _orderGoods.goods,
                quantityAmount: _orderGoods.quantityAmount != undefined ? Number(_orderGoods.quantityAmount) + "" : "",
                notes: _orderGoods.notes || "",
                price: _orderGoods.price != undefined ? Number(_orderGoods.price) + "" : "",
                finalPrice: _orderGoods.totalPrice != undefined ? Number(_orderGoods.totalPrice) + "" : "",
                pricingMethod: _orderGoods.pricingMethod,
                ppn: Number(_orderGoods.ppn) + "",
                pph: Number(_orderGoods.pph) + "",
                ppnPercentage: Number(_orderGoods.ppnPercentage) + "",
                pphPercentage: Number(_orderGoods.pphPercentage) + "",
                nettPrice: _orderGoods.nettPrice + "",
                invoicePrice: _orderGoods.invoicePrice + "",
                includePPN: _orderGoods.includePPN,
                includePPh: _orderGoods.includePPh
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.goods || error.notes || error.price || error.quantityAmount) {
            return true;
        } else if (!data.goods || data.quantityAmount === "" || (data.pricingMethod !== 'lumpsum' && data.price === "") || data.finalPrice === "") {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { goods, price, notes, quantityAmount, pricingMethod, ppn, pph, includePPN, includePPh, ppnPercentage, pphPercentage, finalPrice, nettPrice, invoicePrice } = data;

            // create form data
            let fd = new FormData();
            fd.append("goodsId", goods?.id || "");
            fd.append("price", price !== "" ? price : '0');
            fd.append("notes", notes);
            fd.append("quantityAmount", quantityAmount);
            fd.append("pricingMethod", pricingMethod);
            fd.append("totalPrice", finalPrice);
            fd.append("finalPrice", finalPrice);
            fd.append("nettPrice", nettPrice);
            fd.append("invoicePrice", invoicePrice);
            fd.append("ppn", ppn);
            fd.append("pph", pph);
            fd.append("includePPN", includePPN + "");
            fd.append("includePPh", includePPh + "");
            fd.append("ppnPercentage", ppnPercentage + "");
            fd.append("pphPercentage", pphPercentage + "");

            if (props.orderGoodsId === undefined) {
                await OrderGoodsService.create(props.orderId, fd);
            } else {
                await OrderGoodsService.update(props.orderId, props.orderGoodsId, fd);
            }

            banner.add({
                key: mode + '_order_goods_success',
                variant: 'success',
                icon: faCheck,
                text: `Goods "${goods?.name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchGoods = async (keyword: string) => {
        try {
            const qs: string[] = [];
            qs.push(`status=current`);
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await GoodsService.retrieve.get(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_goods_error",
                text: "Failed to search related goods. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onGoodsCreated = (refresh?: boolean, goods?: IGoodsResourceShortProps) => {
        if (goods) {
            _onGoodsChanged(goods);
        }

        setActiveSurface(undefined);
    }

    const _onGoodsChanged = (item?: IGoodsResourceShortProps) => {
        data.goods = item;
        data.price = "";
        data.quantityAmount = Number(item?.quantityAmount || 0) + "";

        setData({ ...data });
    }

    const calculateAllPrice = (_data: FormDataProps) => {
        let originalPrice = "0";
        let calculatedPrice = Number(_data.price);
        if (_data.pricingMethod === 'perquantity') {
            calculatedPrice = calculatedPrice * Number(_data.quantityAmount);
        }

        if (_data.includePPN) {
            if (_data.ppnPercentage && data.ppnPercentage !== 'custom') {
                originalPrice = (calculatedPrice * 100 / (100 + Number(_data.ppnPercentage))).toFixed();
            } else {
                originalPrice = (calculatedPrice- Number(_data.ppn)).toFixed();
            }
        } else if (!_data.includePPN) {
            originalPrice = calculatedPrice + "";
        }

        let ppn = "0";
        if (_data.includePPN && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(originalPrice) * (Number(_data.ppnPercentage) / 100)).toFixed();
        } else if (!_data.includePPN && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (calculatedPrice * (Number(_data.ppnPercentage) / 100)).toFixed();
        }
        _data.ppn = ppn;

        let pph = "0";
        if (_data.pphPercentage && data.pphPercentage !== 'custom') {
            pph = (Number(originalPrice) * (Number(_data.pphPercentage) / 100)).toFixed();
        }
        _data.pph = pph;

        let finalPrice = 0;
        if (_data.includePPN && _data.includePPh) {
            finalPrice = calculatedPrice;
        } else if (_data.includePPN && !_data.includePPh) {
            finalPrice = calculatedPrice + Number(_data.pph);
        } else if (!_data.includePPN && _data.includePPh) {
            finalPrice = calculatedPrice + Number(_data.ppn);
        } else {
            finalPrice = calculatedPrice + Number(_data.ppn) + Number(_data.pph);
        }
        _data.finalPrice = finalPrice + "";

        let nettPrice = finalPrice;
        let invoicePrice = finalPrice;
        if (_data.includePPN) {
            nettPrice = nettPrice - Number(_data.ppn);
        }
        if (_data.includePPh) {
            nettPrice = nettPrice - Number(_data.pph);
        }
        _data.invoicePrice = invoicePrice + "";

        setData({ ...data });
    }

    return <Panel headerText={mode === 'create' ? 'Create Order Goods' : 'Update Order Goods'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating goods ..." : "Updating goods ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack>
                        <Label size={'small'}>Goods</Label>
                        <TagPicker removeButtonAriaLabel='Remove'
                            selectedItems={data.goods ? [{
                                key: data.goods.id,
                                name: data.goods.name,
                                metadata: data.goods
                            }] as any : []}
                            itemLimit={1}
                            onItemSelected={(tag: any) => {
                                const value = tag ? tag.metadata : undefined;
                                _onGoodsChanged(value);
                                return null;
                            }}
                            onChange={(tags) => {
                                if (tags && tags.length < 1) {
                                    data.goods = undefined;
                                    setData({ ...data });
                                }
                            }}
                            onResolveSuggestions={_onSearchGoods}
                            onEmptyResolveSuggestions={() => _onSearchGoods('')}
                            disabled={submitting} />
                        <Text size={'small'}>Cannot find goods? <Link onClick={() => { setActiveSurface('goods.create') }}>Click here</Link> to create goods.</Text>
                    </Stack>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            resizable={false}
                            autoAdjustHeight
                            rows={4}
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            suffix={data.goods?.notes || ""}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Quantity"}
                            value={data.quantityAmount}
                            onChange={(evt, value) => {
                                if (!isNaN(Number(value))) {
                                    data.quantityAmount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.quantityAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                    calculateAllPrice(data);
                                }
                            }}
                            suffix={data.goods?.quantityUnit || ""}
                            errorMessage={error.quantityAmount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown label={"Pricing Method"}
                            selectedKey={data.pricingMethod}
                            options={[
                                { key: 'lumpsum', text: 'Lump sum' },
                                { key: 'perquantity', text: 'Per quantity' }
                            ]}
                            onChange={(evt, opt) => {
                                data.pricingMethod = opt?.key as string;
                                setData({ ...data });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={data.pricingMethod === 'perquantity' ? "Price per Quantity" : "Total Price"}
                            value={data.price}
                            onChange={(evt, value) => {
                                if (!isNaN(Number(value))) {
                                    data.price = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.price = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                    calculateAllPrice(data);
                                }
                            }}
                            prefix={"Rp"}
                            suffix={data.price !== "" ? GeneralService.convertNumberToWords(Number(data.price)) : undefined}
                            errorMessage={error.price}
                            disabled={submitting} />
                    </Stack.Item>
                    {data.pricingMethod === 'perquantity' ? <Stack>
                        <Label size={'small'}>Total Price</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(Number(data.price) * Number(data.quantityAmount))}</Text>
                    </Stack> : null}
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <Stack.Item>
                            <Checkbox checked={data.includePPN}
                                label='Include PPN'
                                onChange={(ev, checked) => {
                                    data.includePPN = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox checked={data.includePPh}
                                label='Include PPh'
                                onChange={(ev, checked) => {
                                    data.includePPh = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPN</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPNPercentageOptions}
                                    selectedKey={data.ppnPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.ppn = (Number(opt.key) / 100 * Number(data.price)).toFixed();
                                        }
                                        data.ppnPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.ppn}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.ppn = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.ppn = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.ppn}
                                    disabled={submitting || data.ppnPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPh</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPhPercentageOptions}
                                    selectedKey={data.pphPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.pph = (Number(opt.key) / 100 * Number(data.price)).toFixed();
                                        }
                                        data.pphPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.pph}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.pph = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.pph = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.pph}
                                    disabled={submitting || data.pphPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack.Item>
                        <Label size={'small'}>Final Price</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(data.finalPrice)}</Text>
                    </Stack.Item>
                </> : null
            }
        </Stack>
        {activeSurface === 'goods.create' ? <GoodsForm onDismissed={_onGoodsCreated} /> : null}
    </Panel>
};

export default OrderGoodsForm;
