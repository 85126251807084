import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';

// props

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import OfficeService from '../../../../../services/users/offices';
import { type } from 'os';
import OfficeTypes from '../../../../../manifests/PublicOfficeTypes';

interface IOfficeFormProps {
    officeId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    type: string
    longitude: string
    latitude: string
    address: string

    order_number: string;
    is_featured: string;
}

type FormDataErrorProps = {
    name?: string;
    alias?: string;
    address?: string
    type?: string
    longitude?: string
    latitude?: string
}

const OfficeForm: React.FC<IOfficeFormProps> = (props: IOfficeFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        address: "",
        type: "",
        longitude: "",
        latitude: "",
        order_number: "",
        is_featured: "",
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.officeId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.officeId) {
            const _office = await OfficeService.get(props.officeId);
            setData({
                name: _office.name,
                address: _office.address || "",
                order_number: String(_office.order_number),
                is_featured: String(_office.is_featured),
                type: _office.type,
                longitude: _office.longitude,
                latitude: _office.latitude,
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.address || error.latitude || error.longitude || error.type) {
            return true;
        } else if (data.name.trim() === '' || data.address.trim() === '' || data.latitude.trim() === '' || data.longitude.trim() === '' || data.type.trim() === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, address, order_number, is_featured, latitude, longitude, type } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("address", address);
            fd.append("type", type);
            fd.append("longitude", longitude || '0');
            fd.append("latitude", latitude || '0');
            fd.append("order_number", order_number || '0');
            fd.append("is_featured", is_featured || '0');

            if (props.officeId === undefined) {
                await OfficeService.new(fd);
            } else {
                await OfficeService.update(props.officeId, fd);
            }
            banner.add({
                key: 'create_office_success',
                variant: 'success',
                icon: faCheck,
                text: `Office "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Office' : 'Update Office'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating office ..." : "Updating office ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Address"}
                            required={true}
                            value={data.address}
                            multiline
                            rows={5}
                            autoAdjustHeight={true}
                            resizable={false}
                            onChange={(evt, value) => {
                                data.address = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.address = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.address}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Latitude"}
                            required={true}
                            type='number'
                            value={data.latitude}
                            onChange={(evt, value) => {
                                data.latitude = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.latitude = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.latitude}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Longitude"}
                            required={true}
                            type='number'
                            value={data.longitude}
                            onChange={(evt, value) => {
                                data.longitude = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.longitude = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.longitude}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown label={"Type"}
                            required={true}
                            disabled={submitting}
                            selectedKey={data.type}
                            options={OfficeTypes}
                            onChange={(evt, opt) => {
                                data.type = opt ? opt.key as string : "";
                                setData({ ...data });
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Order Number"}
                            required={true}
                            value={data.order_number}
                            type='number'
                            onChange={(evt, value) => {
                                data.order_number = value || "";
                                setData({ ...data });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle checked={data.is_featured === '1' ? true : false} label="Is Featured" onChange={(event, checked) => {
                            if (checked) {
                                data.is_featured = '1'
                            } else {
                                data.is_featured = '0'
                            }
                            setData({ ...data });
                        }} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OfficeForm;
