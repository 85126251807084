import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ActionButton, Checkbox, CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import styles from './styles.module.scss';
import Text from '../../../../../typography/text';
import Label from '../../../../../typography/label';
import { FormDataProps } from './../..';
import DeliveredGoodsType from '../../../../../../manifests/deliveredGoodsType';
import GeneralService from '../../../../../../services/general';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';
import PermissionsService from '../../../../../../services/permissions';
import OfferService from '../../../../../../services/offers';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import CancelOfferForm from '../../cancel';
import { IOfferResourceShortProps } from '../../../../../../props/offers';
import OrderService from '../../../../../../services/orders/order';
import DateTimePicker from '../../../../../uiframeworks/forms/dateTimePicker';


interface SummaryDetailsProps {
    mode: string;
    data: FormDataProps;
    offer?: IOfferResourceShortProps;
}

type ConvertOrderDataProps = {
    estimatedLoadingStartTime: string;
    estimatedLoadingEndTime: string;
    notifyCustomer: boolean;
}

const SummaryDetails: React.FC<SummaryDetailsProps> = (props: SummaryDetailsProps) => {
    const { user, banner, route } = useStore();
    const { data } = props;
    const [converting, setConverting] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [convertOrderData, setConvertOrderData] = React.useState<ConvertOrderDataProps>({
        estimatedLoadingStartTime: moment().add(1, 'day').startOf('day').toISOString(),
        estimatedLoadingEndTime: moment().add(2, 'day').startOf('day').add(4, 'hours').toISOString(),
        notifyCustomer: true
    });
    const [showConvertOrderDialog, setShowConvertOrderDialog] = React.useState<boolean>(false);
    const history = useHistory();
    const params: { offerId?: string } = useParams()

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        const hasUpdateAccess = PermissionsService.hasPermission(['offers.update.all', 'offers.pricings.update.all'], user.permissions) || (PermissionsService.hasPermission(['offers.pricings.update.me'], user.permissions) && data.orderDetails?.sales?.id === user.data?.id);

        if (PermissionsService.hasPermission(['orders.create'], user.permissions) &&
            PermissionsService.hasPermission(['orders.expenses.create'], user.permissions) &&
            PermissionsService.hasPermission(['outcomes.invoices.create'], user.permissions) &&
            PermissionsService.hasPermission(['offers.read.costings'], user.permissions) &&
            data.id && data.status === 'approved' && !props.data.orderId) {
            items.push({
                key: 'convert',
                onRender: () => {
                    return <PrimaryButton
                        text={'Convert to Order'}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={async () => setShowConvertOrderDialog(true)}
                    />
                }
            });
        }

        if (hasUpdateAccess && data.status !== 'approved' && data.id && !props.data.orderId) {
            items.push({
                key: 'update',
                onRender: () => {
                    return <PrimaryButton
                        text={'Update'}
                        iconProps={{ iconName: 'Edit' }}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={() => {
                            history.push(`/offers/${params?.offerId}/update`);
                        }}
                    />
                }
            });
        }

        if (hasUpdateAccess && data.status === 'approved' && data.id && !props.data.orderId) {
            items.push({
                key: 'update',
                onRender: () => {
                    return <ActionButton
                        text={'Update'}
                        iconProps={{ iconName: 'Edit' }}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={() => {
                            history.push(`/offers/${params?.offerId}/update`);
                        }}
                    />
                }
            });
        }

        if (data.id) {
            items.push({
                key: 'download',
                onRender: () => {
                    return <ActionButton text={'Download'}
                        iconProps={{ iconName: 'Download' }}
                        menuProps={{
                            items: [
                                { key: 'download_offer_customer', text: "Download Offer (for Customer)", onClick: () => { onDownload() }, },
                                { key: 'download_offer', text: "Download Cost Summary (for Internal)", href: "/print/offers/" + data.id + '/costs', target: '_blank' }
                            ]
                        }} />
                }
            });
        }

        const hasCancelAccess = PermissionsService.hasPermission(['superuser', 'admin', 'sales'], user.roles);
        if (hasCancelAccess && props.offer && !props.data.orderId) {
            farItems.push({
                key: 'cancel',
                text: 'Cancel',
                iconProps: { iconName: 'Cancel' },
                onClick: () => setActiveSurface('cancel')
            });
        }

        return { items, farItems };
    }

    const _onConvert = async () => {
        try {
            setConverting(true);
            const orderNumber = GeneralService.makeid(30, true, 'ORD');
            const fd = new FormData();
            fd.append('orderNumber', orderNumber);
            fd.append('estimatedLoadingStartTime', convertOrderData.estimatedLoadingStartTime);
            fd.append('estimatedLoadingEndTime', convertOrderData.estimatedLoadingEndTime);
            fd.append('notifyCustomer', convertOrderData.notifyCustomer ? "1" : "0")
            const order = await OfferService.convert(data.id || "", fd);

            history.push(`/orders/${order.id}`);
        } catch (e) {
            setConverting(false);
            banner.add({
                key: 'convert_offer_to_order_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to convert offer to order. Message: ${ErrorService.getMessage(e)}`
            });
        }
    }

    const onDownload = async () => {
        try {
            setDownloading(true);
            await OfferService.download(params.offerId || '', data.offerNumber);
            banner.add({
                key: 'download_offer_success',
                variant: 'success',
                icon: faCheck,
                text: `Offer #${OfferService.getOfferNumber(params.offerId || "")} has been downloaded`
            });

            setDownloading(false);
        } catch (e) {
            setDownloading(false);

            banner.add({
                key: 'download_offer_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to download offer. Message: ${ErrorService.getMessage(e)}`
            });
        }
    }

    const renderStatusTag = () => {
        let tagVariant: TTagVariant = 'active'
        let tagText: string = 'Requested';
        if (data.status === 'approved') {
            tagVariant = 'success';
            tagText = 'Approved';
        } else if (data.status === 'rejected') {
            tagVariant = 'error';
            tagText = 'Rejected';
        } else if (data.status === 'converted') {
            tagVariant = 'primary';
            tagText = 'Order Created';
        } else if (data.status === 'waitingapproval') {
            tagVariant = 'active';
            tagText = 'Waiting Approval';
        } else if (data.status === 'cancelled') {
            tagVariant = 'error';
            tagText = 'Cancelled';
        }

        return <Stack horizontal>
            <Tag variant={tagVariant} text={tagText} />
        </Stack>
    }

    const renderProfitTag = () => {
        let tagVariant: TTagVariant = 'success';
        if (data.profitPercentage <= 0) {
            tagVariant = 'error';
        } else if (data.profitPercentage < 1.5) {
            tagVariant = 'warning';
        }

        return <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
            <Tag variant={tagVariant} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.profitAmount))}`} />
            <Text>({data.profitPercentage}%)</Text>
        </Stack>
    }

    const getFreightDetails = () => {
        const text: string[] = [];
        const goodsType = DeliveredGoodsType.find((gt) => gt.key === data.freightDetails?.goodsType);

        if (data.freightDetails?.freightCategory) {
            text.push(data.freightDetails.freightCategory.name);
        }

        if (data.freightDetails?.deliveryType) {
            text.push(data.freightDetails.deliveryType.name);
        }

        if (goodsType) {
            text.push(goodsType.text);
        }

        return text.join(' - ');
    }

    const getAddressType = (type?: string) => {
        if (type === 'door') {
            type = 'Door';
        } else if (type === 'yard') {
            type = 'Container Yard';
        } else if (type === 'port') {
            type = 'Port';
        }

        return type || '';
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {props.data.orderId ? <>
            <MessageBar messageBarType={MessageBarType.success}>
                <Text>This offer has been converted to order. <NavLink to={'/orders/' + props.data.orderId}>Click here</NavLink> to open order details.</Text>
            </MessageBar>
            <Stack className={'divider'}></Stack>
        </> : null}
        {props.mode !== 'update' ? <>
            <CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0 } }} />
            <Stack className={'divider'}></Stack>
        </> : null}
        <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign={'center'} >
            <Label size={'large'}>#{data.offerNumber}</Label>
            {renderStatusTag()}
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack tokens={{ childrenGap: 8 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Offered Price</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.offeredPrice?.totalPrice || '0'))}</Label>
            </Stack>
            <Stack tokens={{ childrenGap: 8 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Total Cost</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.totalCost || '0'))}</Label>
            </Stack>
            <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Profit</Text>
                {renderProfitTag()}
            </Stack>
            <Stack styles={{ root: { width: '25%' } }}></Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text size={'small'}>Subsidiary</Text>
                {data.orderDetails?.subsidiary ? <Label size={'small'}>{data.orderDetails.subsidiary.name}</Label> : null}
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text size={'small'}>Sales</Text>
                {data.orderDetails?.sales ? <Label size={'small'}>{data.orderDetails.sales.name}</Label> : null}
                {!data.orderDetails?.sales ? <Label size={'small'}>-</Label> : null}
            </Stack>
        </Stack>
        <Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text size={'small'}>Customer</Text>
                <Label size={'small'}>{data.customerDetails?.name} ({data.customerDetails?.company?.name})</Label>
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text size={'small'}>Freight Details</Text>
                <Label size={'small'}>{getFreightDetails()}</Label>
            </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text size={'small'}>Origin</Text>
                <ol style={{ padding: '0px 10px', margin: 0 }}>
                    {(data.addressDetails?.originAddresses || []).filter((addr) => addr.address.trim() !== "").map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <Text size={'small'}><li>{text}</li></Text>
                    })}
                </ol>
            </Stack>
            <Stack styles={{ root: { width: '50%' } }}>
                <Text size={'small'}>Destination</Text>
                <ol style={{ padding: '0px 10px', margin: 0 }}>
                    {(data.addressDetails?.destinationAddresses || []).filter((addr) => addr.address.trim() !== "").map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <Text size={'small'}><li>{text}</li></Text>
                    })}
                </ol>
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
            <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Cost (before tax)</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.subtotalCost))}</Label>
            </Stack>
            {data.includedCosts?.ppn && data.ppnCost ? <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>PPn</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.ppnCost.amount || '0')}</Label>
            </Stack> : null}
            {data.includedCosts?.pph && data.pphCost ? <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>PPh</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.pphCost.amount || '0')}</Label>
            </Stack> : null}
            <Stack tokens={{ childrenGap: 3 }} styles={{ root: { width: '25%' } }}>
                <Text size={'small'}>Total cost (after tax)</Text>
                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(data.totalCost))}</Label>
            </Stack>
        </Stack>
        <Stack className={'divider'}></Stack>
        <Stack tokens={{ childrenGap: 20 }}>
            <Label>Pricings</Label>
            <Stack tokens={{ childrenGap: 10 }}>
                {(data.offeredPrice?.items || []).filter((item) => item.name.trim() !== '' && item.price.trim() !== '').map((pricing, idx) => {
                    return <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign={'center'}>
                        <Stack styles={{ root: { width: '50%' } }}>
                            {pricing.type === 'container' ? <Text size={'small'}>Container {pricing.name}</Text> : null}
                            {pricing.type === 'truck' ? <Text size={'small'}>Truck {pricing.name}</Text> : null}
                            {pricing.type === 'goods' || pricing.type === 'others' ? <Text size={'small'}>{pricing.name}</Text> : null}
                        </Stack>
                        <Stack styles={{ root: { width: '0%' } }}></Stack>
                        <Stack styles={{ root: { width: '25%' } }} horizontal tokens={{ childrenGap: 5 }}>
                            <Text size={'small'}>{pricing.quantityAmount} {pricing.quantityUnit?.name}</Text>
                            <Text size={'small'}>{pricing.priceType === 'lumpsum' ? '(lumpsum)' : '(per quantity)'}</Text>
                        </Stack>
                        <Stack styles={{ root: { width: '25%' } }}>
                            <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(pricing.totalPrice || '0'))}</Label>
                        </Stack>
                    </Stack>
                })}
            </Stack>
        </Stack>
        {downloading ? <LoadingDialogComponent title={'Downloading'} secondaryText={"Please wait while we're downloading this offer."} /> : null}
        {converting ? <LoadingDialogComponent title={'Converting to Order'} secondaryText={"Please wait while we're converting this offer to order. Once completed, we'll redirect you momentarily."} /> : null}
        {activeSurface === 'cancel' && props.offer ? <CancelOfferForm offer={props.offer} onDismissed={(refresh) => {
            if (refresh) {
                route.route?.history.push("/offers/list");
            }
            setActiveSurface(undefined);
        }} /> : null}
        <Dialog
            hidden={!showConvertOrderDialog}
            onDismiss={() => setShowConvertOrderDialog(false)}
            dialogContentProps={{title: "Convert to Order", type: DialogType.largeHeader}}
            modalProps={{isBlocking: true}} >
            <Stack tokens={{childrenGap: 20}}>
                <Stack tokens={{childrenGap: 20}}>
                    <DateTimePicker label={'Estimated loading time'}
                        enableTime={true}
                        value={convertOrderData.estimatedLoadingStartTime}
                        onChange={(date) => {
                            const _date = date || convertOrderData.estimatedLoadingStartTime;
                            convertOrderData.estimatedLoadingStartTime = _date;
                            convertOrderData.estimatedLoadingEndTime = moment(_date).add(4, 'hours').toISOString();
                            setConvertOrderData({...convertOrderData});
                        }} />
                    <Stack tokens={{childrenGap: 3}}>
                        <Label size={'xsmall'}>Notify customer?</Label>
                        <Checkbox label={'Notify customer (' + props.offer?.customer.name + ') about this order'}
                            checked={convertOrderData.notifyCustomer}
                            onChange={(ev, checked) => {
                                convertOrderData.notifyCustomer = checked || false;
                                setConvertOrderData({...convertOrderData});
                            }} />
                    </Stack>
                </Stack>
                <Stack className={'divider'}></Stack>
                <DialogFooter>
                    <PrimaryButton onClick={_onConvert} text="Convert" />
                    <DefaultButton onClick={() => setShowConvertOrderDialog(false)} text="Cancel" />
                </DialogFooter>
            </Stack>
        </Dialog>
    </Stack>
};

export default SummaryDetails;
