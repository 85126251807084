import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';

// props

// components
import { ActionButton, Checkbox, DatePicker, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { IBankAccountResourceShortProps } from '../../../../../props/general/bankAccount';
import OrderInvoiceService from '../../../../../services/orders/invoices/invoice';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import OrderService from '../../../../../services/orders/order';
import { IOrderResourceProps, IOrderResourceShortProps } from '../../../../../props/orders/order';
import IncomeInvoiceService from '../../../../../services/finance/incomes/invoices/invoice';
import moment from 'moment';
import BankAccountService from '../../../../../services/general/bankAccount';
import { ITruckResourceShortProps } from '../../../../../props/order';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../../../props/data/ships';
import { ITrainResourceShort, ITrainScheduleResourceShort } from '../../../../../props/data/trains';
import OrderTrackingType from '../../../../../manifests/orderTrackingType';
import TrackingTypes from '../../../../../manifests/trackingTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorService from '../../../../../services/general/error';
import TrucksService from '../../../../../services/data/trucks';
import TrainScheduleService from '../../../../../services/data/trains/trainScedule';
import TrainsService from '../../../../../services/data/trains';
import ShipSchedulesService from '../../../../../services/data/ships/shipSchedules';
import ShipsService from '../../../../../services/data/ships';
import { ITruckResourceShort } from '../../../../../props/data/trucks';
import OrderTrackingService from '../../../../../services/orders/trackings/orderTracking';
import ShipField from '../../../../uiframeworks/forms/ship';
import ShipScheduleField from '../../../../uiframeworks/forms/shipSchedule';
import DateTimePicker from '../../../../uiframeworks/forms/dateTimePicker';
import SelectTruck from '../../../../uiframeworks/forms/selectTruck';
import SelectTrain from '../../../../uiframeworks/forms/selectTrain';
import SelectTrainSchedule from '../../../../uiframeworks/forms/selectTrainSchedule';

interface IOrderTrackingFormProps {
    orderId: string;
    trackingId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    type: string;
    name: string;
    notes: string;
    originAddress: string;
    destinationAddress: string;
    estimatedStartDate: string;
    estimatedEndDate: string;
    applyToAll: boolean;
    truck?: ITruckResourceShort;
    ship?: IShipResourceShortProps;
    shipSchedule?: IShipScheduleResourceShort;
    train?: ITrainResourceShort;
    trainSchedule?: ITrainScheduleResourceShort;
    driverName: string;
    driverPhone: string;
    container?: string;
    orderTruck?: string;
}

type FormDataErrorProps = {
    notes?: string;
    name?: string;
    originAddress?: string;
    destinationAddress?: string;
    driverName?: string;
    driverPhone?: string;
}

const OrderTrackingForm: React.FC<IOrderTrackingFormProps> = (props: IOrderTrackingFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [bankAccounts, setBankAccounts] = React.useState<IBankAccountResourceShortProps[]>([]);
    const [order, setOrder] = React.useState<IOrderResourceProps | undefined>();
    const [data, setData] = React.useState<FormDataProps>({
        type: "",
        name: "",
        notes: "",
        originAddress: "",
        destinationAddress: "",
        estimatedStartDate: moment().toISOString(),
        estimatedEndDate: moment().toISOString(),
        applyToAll: true,
        driverName: "",
        driverPhone: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.trackingId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _order = await OrderService.get(props.orderId);
        setOrder(_order);

        if (props.trackingId) {
            const _tracking = await OrderTrackingService.get(props.orderId, props.trackingId);
            const { shippingDetails, dooringDetails, trainDetails, loadDetails, unloadingDetails, container } = _tracking;

            const _data: FormDataProps = {
                type: _tracking.type,
                name: "",
                notes: _tracking.notes || "",
                originAddress: "",
                destinationAddress: "",
                estimatedStartDate: moment().toISOString(),
                estimatedEndDate: moment().add(1, 'day').toISOString(),
                applyToAll: container ? false : true,
                truck: dooringDetails?.truck,
                ship: shippingDetails?.ship,
                shipSchedule: shippingDetails?.schedule,
                train: trainDetails?.train,
                trainSchedule: trainDetails?.schedule,
                driverName: dooringDetails?.driverName || "",
                driverPhone: dooringDetails?.driverPhone || "",
                container: container?.id
            };

            if (dooringDetails) {
                _data.originAddress = dooringDetails.originAddress;
                _data.destinationAddress = dooringDetails.destinationAddress;
                _data.estimatedStartDate = dooringDetails.estimatedDepartureDate;
                _data.estimatedEndDate = dooringDetails.estimatedArrivalDate;
            } else if (loadDetails) {
                _data.originAddress = loadDetails.address;
                _data.estimatedStartDate = loadDetails.estimatedStartDate || moment().toISOString();
                _data.estimatedEndDate = loadDetails.estimatedEndDate || moment().toISOString();
            } else if (unloadingDetails) {
                _data.destinationAddress = unloadingDetails.address;
                _data.estimatedStartDate = unloadingDetails.estimatedStartDate || moment().toISOString();
                _data.estimatedEndDate = unloadingDetails.estimatedEndDate || moment().toISOString();
            }

            setData(_data);
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (data.type.trim() === "") {
            return true;
        } else if (data.type === 'ship' && (!data.ship || !data.shipSchedule)) {
            return true;
        } else if (data.type === 'train' && (!data.train || !data.trainSchedule)) {
            return true;
        } else if (data.type === 'truck' && (data.originAddress.trim() === "" || data.destinationAddress.trim() === "")) {
            return true;
        } else if (data.type === 'load' && (data.originAddress.trim() === "")) {
            return true;
        } else if (data.type === 'unload' && (data.destinationAddress.trim() === "")) {
            return true;
        } else if (!data.applyToAll && (!data.container || data.container.trim() === "") && order && (order.goodsType === 'fcl' || order.goodsType === 'lcl')) {
            return true;
        } else if (!data.applyToAll && (!data.orderTruck || data.orderTruck.trim() === "") && order && (order.goodsType === 'ftl' || order.goodsType === 'ltl')) {
            return true;
        } else if (error.destinationAddress || error.originAddress || error.name || error.notes || error.driverName || error.driverPhone) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { type, name, notes, originAddress, destinationAddress, estimatedStartDate, estimatedEndDate, applyToAll, truck, ship, shipSchedule, train, trainSchedule, driverName, driverPhone, container, orderTruck } = data;

            // create form data
            let fd = new FormData();
            fd.append('type', type);
            fd.append('notes', notes);

            if (type === 'load') {
                fd.append('address', originAddress);
                fd.append('estimatedStartDate', estimatedStartDate);
                fd.append('estimatedEndDate', estimatedEndDate);
            } else if (type === 'unload') {
                fd.append('address', destinationAddress);
                fd.append('estimatedStartDate', estimatedStartDate);
                fd.append('estimatedEndDate', estimatedEndDate);
            } else if (type === 'ship' && ship && shipSchedule) {
                fd.append('shipId', ship.id);
                fd.append('scheduleId', shipSchedule.id);
            } else if (type === 'train' && train && trainSchedule) {
                fd.append('trainId', train.id);
                fd.append('scheduleId', trainSchedule.id);
            } else if (type === 'truck') {
                fd.append('originAddress', originAddress);
                fd.append('destinationAddress', destinationAddress);
                fd.append('estimatedDepartureDate', estimatedStartDate);
                fd.append('estimatedArrivalDate', estimatedEndDate);
                fd.append('driverName', driverName);
                fd.append('driverPhone', driverPhone);
                if (truck) {
                    fd.append('truckId', truck.id);
                }
            }

            if (!applyToAll && container) {
                fd.append('containerId', container);
            }

            if (!applyToAll && orderTruck) {
                fd.append('orderTruckId', orderTruck);
            }

            if (props.trackingId) {
                await OrderTrackingService.update(props.orderId, props.trackingId, fd);
            } else {
                await OrderTrackingService.create(props.orderId, fd);
            }

            banner.add({
                key: mode + '_order_tracking_success',
                variant: 'success',
                icon: faCheck,
                text: `Tracking "${type}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchShip = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const options = (await ShipsService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_ship_error",
                text: "Failed to search related ship(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchShipSchedule = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.ship) { qs.push(`ship_id=${data.ship.id}`) }

            const options = (await ShipSchedulesService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: "Voy " + item.voy,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_ship_schedule_error",
                text: "Failed to search related ship schedule(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchTrain = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const options = (await TrainsService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_train_error",
                text: "Failed to search related train(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchTrainSchedule = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.train) { qs.push(`train_id=${data.train.id}`) }

            const options = (await TrainScheduleService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: "Voy " + item.voy,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_train_schedule_error",
                text: "Failed to search related train schedule(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchTruck = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const options = (await TrucksService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_truck_error",
                text: "Failed to search related truck(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const getApplyToAllText = (_order: IOrderResourceProps) => {
        if (_order.goodsType === 'fcl' || _order.goodsType === 'lcl') {
            return 'containers'
        } else if (_order.goodsType === 'ftl' || _order.goodsType === 'ltl') {
            return 'trucks';
        } else {
            return "goods";
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Tracking' : 'Update Tracking'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating tracking ..." : "Updating tracking ..."} /> : null}
            </Stack>;
        }}>
        <Stack className={styles.container} tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && order ? <>
                    <Stack.Item>
                        <Dropdown label={"Tracking Type"}
                            required={true}
                            selectedKey={data.type}
                            options={TrackingTypes}
                            onChange={(evt, opt) => {
                                data.type = opt ? opt.key as string : "";
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    {data.type === 'load' ? <>
                        <Stack.Item>
                            <TextField label={"Loading Address"}
                                multiline
                                rows={3}
                                autoAdjustHeight
                                resizable={false}
                                value={data.originAddress}
                                required={true}
                                errorMessage={error.originAddress}
                                disabled={submitting}
                                onChange={(evt, value) => {
                                    data.originAddress = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.originAddress = validation.message;

                                    setError({ ...error });
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <DateTimePicker label={"Estimated start date"}
                                required={true}
                                value={data.estimatedStartDate}
                                enableTime
                                onChange={(date?: string) => {
                                    data.estimatedStartDate = date || data.estimatedStartDate;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <DateTimePicker label={"Estimated end date"}
                                required={true}
                                value={data.estimatedEndDate}
                                enableTime
                                onChange={(date?: string) => {
                                    data.estimatedEndDate = date || data.estimatedEndDate;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                    </> : null}
                    {data.type === 'unload' ? <>
                        <Stack.Item>
                            <TextField label={"Unloading Address"}
                                multiline
                                rows={3}
                                autoAdjustHeight
                                resizable={false}
                                value={data.destinationAddress}
                                required={true}
                                errorMessage={error.destinationAddress}
                                disabled={submitting}
                                onChange={(evt, value) => {
                                    data.destinationAddress = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.destinationAddress = validation.message;

                                    setError({ ...error });
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <DateTimePicker label={"Estimated start date"}
                                required={true}
                                value={data.estimatedStartDate}
                                enableTime
                                onChange={(date?: string) => {
                                    data.estimatedStartDate = date || data.estimatedStartDate;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <DateTimePicker label={"Estimated loading end date"}
                                required={true}
                                value={data.estimatedEndDate}
                                enableTime
                                onChange={(date?: string) => {
                                    data.estimatedEndDate = date || data.estimatedEndDate;
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                    </> : null}
                    {data.type === 'ship' ? <>
                        <Stack>
                            <ShipField selected={data.ship}
                                allowCreate={true}
                                onChange={(ship) => {
                                    data.ship = ship;
                                    if (!data.ship) {
                                        data.shipSchedule = undefined;
                                    }

                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack>
                            <ShipScheduleField selected={data.shipSchedule}
                                disabled={!data.ship}
                                ship={data.ship}
                                allowCreate={true}
                                onChange={(schedule) => {
                                    data.shipSchedule = schedule;

                                    setData({ ...data });
                                }} />
                        </Stack>
                    </> : null}
                    {data.type === 'train' ? <>
                        <Stack>
                            <SelectTrain selected={data.train}
                                allowCreate={true}
                                onChange={(train) => {
                                    data.train = train;
                                    if (!data.train) {
                                        data.trainSchedule = undefined;
                                    }

                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack>
                            <SelectTrainSchedule selected={data.trainSchedule}
                                disabled={!data.train}
                                train={data.train}
                                allowCreate={true}
                                onChange={(schedule) => {
                                    data.trainSchedule = schedule;

                                    setData({ ...data });
                                }} />
                        </Stack>
                    </> : null}
                    {data.type === 'truck' ? <>
                        <SelectTruck selected={data.truck}
                            allowCreate={true}
                            onChange={(truck) => {
                                data.truck = truck;
                                setData({ ...data });
                            }} />
                        <Stack className={'divider'}></Stack>
                        <Stack>
                            <TextField label={"Origin address"}
                                multiline
                                rows={3}
                                autoAdjustHeight
                                resizable={false}
                                value={data.originAddress}
                                required={true}
                                errorMessage={error.originAddress}
                                disabled={submitting}
                                onChange={(evt, value) => {
                                    data.originAddress = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.originAddress = validation.message;

                                    setError({ ...error });
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <DateTimePicker label={"Estimated departure time"}
                            required={true}
                            value={data.estimatedStartDate}
                            enableTime
                            onChange={(date?: string) => {
                                data.estimatedStartDate = date || data.estimatedStartDate;
                                setData({ ...data });
                            }} />
                        <Stack>
                            <TextField label={"Destination address"}
                                multiline
                                rows={3}
                                autoAdjustHeight
                                resizable={false}
                                value={data.destinationAddress}
                                required={true}
                                errorMessage={error.destinationAddress}
                                disabled={submitting}
                                onChange={(evt, value) => {
                                    data.destinationAddress = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.destinationAddress = validation.message;

                                    setError({ ...error });
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <DateTimePicker label={"Estimated arrival date"}
                            required={true}
                            value={data.estimatedEndDate}
                            enableTime
                            onChange={(date?: string) => {
                                data.estimatedEndDate = date || data.estimatedEndDate;
                                setData({ ...data });
                            }} />
                        <Stack className={'divider'}></Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item styles={{ root: { width: '50%' } }}>
                                <TextField label={"Driver name"}
                                    value={data.driverName}
                                    errorMessage={error.driverName}
                                    disabled={submitting}
                                    onChange={(evt, value) => {
                                        data.driverName = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                        error.driverName = validation.message;

                                        setError({ ...error });
                                        setData({ ...data });
                                    }} />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: '50%' } }}>
                                <TextField label={"Driver phone"}
                                    value={data.driverPhone}
                                    errorMessage={error.driverPhone}
                                    disabled={submitting}
                                    prefix={'+62'}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            data.driverPhone = value || "";

                                            const validation = ValidationService.combination(value, ['limit'], { maxChars: 20 });
                                            error.driverPhone = validation.message;

                                            setError({ ...error });
                                            setData({ ...data });
                                        }
                                    }} />
                            </Stack.Item>
                        </Stack>
                    </> : null}
                    {data.type === 'other' ? <>
                        <Stack.Item>
                            <TextField label={"Name"}
                                value={data.name}
                                required={true}
                                errorMessage={error.name}
                                disabled={submitting}
                                onChange={(evt, value) => {
                                    data.name = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.name = validation.message;

                                    setError({ ...error });
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack grow={1}>
                                <DateTimePicker label={"Estimated start date"}
                                    required={true}
                                    value={data.estimatedStartDate}
                                    enableTime
                                    onChange={(date?: string) => {
                                        data.estimatedStartDate = date || data.estimatedStartDate;
                                        setData({ ...data });
                                    }} />
                            </Stack>
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: 46 }} />
                            <Stack grow={1}>

                                <DateTimePicker label={"Estimated end date"}
                                    required={true}
                                    value={data.estimatedEndDate}
                                    enableTime
                                    onChange={(date?: string) => {
                                        data.estimatedEndDate = date || data.estimatedEndDate;
                                        setData({ ...data });
                                    }} />
                            </Stack>
                        </Stack>
                    </> : null}
                    <Stack className='divider'></Stack>
                    <Stack.Item>
                        <Checkbox label={`Apply to all ${getApplyToAllText(order)}`}
                            checked={data.applyToAll}
                            onChange={(evt, checked) => {
                                data.applyToAll = checked || false;
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    {!data.applyToAll && (order.goodsType === 'fcl' || order.goodsType === 'lcl') ? <Stack.Item>
                        <Dropdown label={"Related Container"}
                            required={true}
                            selectedKey={data.container}
                            options={order.containers.map((cont, idx) => {
                                return { key: cont.id, text: `Container ${cont.containerNumber || '#' + (idx + 1)}` }
                            })}
                            onChange={(evt, opt) => {
                                data.container = opt ? opt.key as string : "";
                                setData({ ...data });
                            }} />
                    </Stack.Item> : null}
                    {!data.applyToAll && (order.goodsType === 'ftl' || order.goodsType === 'ltl') ? <Stack.Item>
                        <Dropdown label={"Related Truck"}
                            required={true}
                            selectedKey={data.orderTruck}
                            options={(order.trucks || []).map((truck, idx) => {
                                return { key: truck.id, text: truck.name || `Truck ${idx + 1}` }
                            })}
                            onChange={(evt, opt) => {
                                data.orderTruck = opt ? opt.key as string : "";
                                setData({ ...data });
                            }} />
                    </Stack.Item> : null}
                    <Stack className='divider'></Stack>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderTrackingForm;
