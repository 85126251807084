import { IOutcomePaymentResourceShortProps } from '../../../../props/finance/outcomes/payments';
import { IDocumentProps, IPaginationResourceShort } from '../../../../props/general';
import Request from './../../../request';

// import props

const OutcomePaymentService = {
    getUniqueValue: async (key: string, qs?: string): Promise<any> => {
        try {
            const results = await Request.get(`/api/outcomes/payments/filters/options?key=${key}${qs ? `&${qs}` : ''}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    retrieve: async (qs?: string): Promise<{ data: IOutcomePaymentResourceShortProps[], pagination?: IPaginationResourceShort }> => {
        try {
            const result = await Request.get(`/api/outcomes/payments?${qs || ""}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    get: async (id: string): Promise<IOutcomePaymentResourceShortProps> => {
        try {
            const result = await Request.get(`/api/outcomes/payments/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    setComplete: async (data: FormData): Promise<IOutcomePaymentResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/payments/complete`, data);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    reject: async (data: FormData): Promise<IOutcomePaymentResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/payments/reject`, data);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IOutcomePaymentResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/payments`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (id: string): Promise<void> => {
        try {
            await Request.delete(`/api/outcomes/payments/${id}`);
            return;
        } catch (e) {
            throw (e);
        }
    },
    documents: {
        retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
            try {
                const result = await Request.get(`/api/outcomes/payments/${id}/documents?${qs || ''}`);
                return result;
            } catch (e) {
                throw (e);
            }
        },
        new: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/outcomes/payments/${id}/documents`, data, 'multipart/form-data');
                return result;
            } catch (e) {
                throw (e);
            }
        },
        delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/outcomes/payments/${id}/delete_documents`, data);
                return result;
            } catch (e) {
                throw (e);
            }
        }
    }
}

export default OutcomePaymentService;