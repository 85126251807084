import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import TrainDetails from '../../../components/operationals/trains/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface ITrainDetailsPageProps {
}

const TrainDetailsPage: React.FC<ITrainDetailsPageProps> = (props: ITrainDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Trains", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "trains", text: 'Trains'}
        ]);
    }, []);

    return <Stack>
        <TrainDetails trainId={params.trainId} />
    </Stack>;
};

export default TrainDetailsPage;
