import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../services/general/error';
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import TeamForm from '../form';
import ISalesTeamResourceShort from '../../../../../props/users/team';
import TeamService from '../../../../../services/users/team';
import DeleteTeamForm from '../delete';
import TeamMemberList from '../../../teamMembers/general/list';

interface ITeamDetailsProps {
    teamId: string;
}

const TeamDetails: React.FC<ITeamDetailsProps> = (props: ITeamDetailsProps) => {
    const { banner, topbar } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [team, setTeam] = React.useState<ISalesTeamResourceShort>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onGetTeam();
    }, []);

    const _onGetTeam = async () => {
        try {
            setLoaded(false);
            const _team = await TeamService.get(props.teamId);
            setTeam(_team);

            topbar.show(_team.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "teams", text: 'Teams' },
                { key: "sales", text: 'Sales' },
                { key: props.teamId, text: _team.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_team_error',
                text: 'Failed to get team details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'update',
            text: 'Update details',
            onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
        });

        farItems.push({
            key: 'delete',
            text: 'Delete',
            onRender: () => <ActionButton text={'Delete'} iconProps={{ iconName: "Delete" }} styles={{ root: { marginLeft: 20 } }} onClick={() => setActiveSurface('delete')} />
        });

        return { items, farItems };
    }

    const getTeamPropertiesSection = (_team: ISalesTeamResourceShort): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'team_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'small'}>Name</Label>
                    <Text>{_team.name}</Text>
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Label size={'small'}>Description</Label>
                    <Text>{_team.description}</Text>
                </Stack>
            </Stack>
        }
    }


    const getRelatedItemsSection = (_team: ISalesTeamResourceShort): IStackSectionProps => {
        const qs: string[] = [];
        qs.push(`sales=${props.teamId}`);

        return {
            key: 'team_companies',
            isCard: true,
            tabs: [
                {
                    key: 'related_teams',
                    title: 'Related Members',
                    content: <Stack>
                        <TeamMemberList team={team} />
                    </Stack>
                },
            ]
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetTeam() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving team details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && team ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack className={styles.details} tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getTeamPropertiesSection(team)]} />
                {team && <DetailsStack left={[getRelatedItemsSection(team)]} />}
            </Stack>
            {activeSurface === 'update' ? <TeamForm teamId={team.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' ? <DeleteTeamForm team={team} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default TeamDetails;
