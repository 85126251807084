import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faArrowRight, faCalendar, faShip, faTrain, faTruck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import { IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';
import OutcomeService from '../../../../../../services/finance/outcomes';
import { IPaginationResourceShort } from '../../../../../../props/general';
import OutcomeForm from '../form';
import OutcomeTypes from '../../../../../../manifests/outcomeTypes';
import { NavLink, useParams } from 'react-router-dom';
import GeneralService from '../../../../../../services/general';
import moment from 'moment';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../../typography/label';
import OutcomeDetails from '../details';
import Pagination from '../../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../../config';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';

interface IOutcomesInvoiceListProps {
    qs?: string[];
    invoices?: IOutcomeResourceShortProps[];
    columns?: TOutcomeColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOutcomeColumn = 'name' | 'action' | 'details' | 'type' | 'vendor' | 'transferFrom' | 'dueDate' | 'amount' | 'status';

const OutcomesInvoiceList: React.FC<IOutcomesInvoiceListProps> = (props: IOutcomesInvoiceListProps) => {
    const params: any = useParams();
    const shownColumns: TOutcomeColumn[] = props.columns || [
        'name',
        'action',
        'details',
        'type',
        'vendor',
        'transferFrom',
        'dueDate',
        'amount',
        'status'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [outcomes, setOutcomes] = React.useState<IOutcomeResourceShortProps[]>(props.invoices || []);
    const [activeOutcome, setActiveOutcome] = React.useState<IOutcomeResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.read.all'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "name",
            name: "Name",
            minWidth: 175,
            maxWidth: 175,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveOutcome(item);
                        setActiveSurface('details');
                    }}><Text size={'small'}>{item.name}</Text></Link>
                </Stack>
            }
        },
        {
            key: "type",
            name: "Type",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IOutcomeResourceShortProps) => {
                const type = OutcomeTypes.find((t) => t.key === item.type);
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{item.category.name}</Text>
                </Stack>
            }
        },
        {
            key: "vendor",
            name: "Vendor",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{item.vendor?.name}</Text>
                </Stack>
            }
        },
        {
            key: "details",
            name: "Details",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack tokens={{ childrenGap: 10 }} wrap>
                    {item.type === 'shipping' && (item.ship || item.shipSchedule) ? <>
                        <Tag>
                            <Stack tokens={{ childrenGap: 15 }} grow={1}>
                                {item.ship ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <Stack styles={{ root: { width: 18 } }}><FontAwesomeIcon icon={faShip} fontSize={13} style={{ marginTop: 1 }} /></Stack>
                                    <Stack>
                                        <Label size={'xsmall'}>{item.ship.name}</Label>
                                        {item.ship.fleetType ? <Text size={'xsmall'}>{item.ship.fleetType.name}</Text> : null}
                                    </Stack>
                                </Stack> : null}
                                {item.shipSchedule ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <Stack styles={{ root: { width: 18 } }}><FontAwesomeIcon icon={faCalendar} fontSize={13} style={{ marginTop: 1 }} /></Stack>
                                    <Stack grow={1} tokens={{ childrenGap: 5 }}>
                                        <Label size={'xsmall'}>Voy #{item.shipSchedule.voy}</Label>
                                        <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                                            <Stack styles={{ root: { width: '50%' } }}>
                                                <Text size={'small'}>{item.shipSchedule.originAddress}</Text>
                                                {!item.shipSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.shipSchedule.estimatedDeparture).toDate())} (Est.)</Text> : null}
                                                {item.shipSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.shipSchedule.actualDeparture).toDate())} (Actual)</Text> : null}
                                            </Stack>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                            <Stack styles={{ root: { width: '50%' } }}>
                                                <Text size={'small'}>{item.shipSchedule.destinationAddress}</Text>
                                                {!item.shipSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.shipSchedule.estimatedArrival).toDate())} (Est.)</Text> : null}
                                                {item.shipSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.shipSchedule.actualArrival).toDate())} (Actual)</Text> : null}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack> : null}
                            </Stack>
                        </Tag>
                    </> : null}
                    {item.type === 'train' && (item.train || item.trainSchedule) ? <>
                        <Tag>
                            <Stack tokens={{ childrenGap: 15 }} grow={1}>
                                {item.train ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <Stack styles={{ root: { width: 18 } }}><FontAwesomeIcon icon={faTrain} fontSize={13} style={{ marginTop: 1 }} /></Stack>
                                    <Stack>
                                        <Label size={'xsmall'}>{item.train.name}</Label>
                                        {item.train.fleetType ? <Text size={'xsmall'}>{item.train.fleetType.name}</Text> : null}
                                    </Stack>
                                </Stack> : null}
                                {item.trainSchedule ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <Stack styles={{ root: { width: 18 } }}><FontAwesomeIcon icon={faCalendar} fontSize={13} style={{ marginTop: 1 }} /></Stack>
                                    <Stack grow={1} tokens={{ childrenGap: 5 }}>
                                        <Label size={'xsmall'}>Voy #{item.trainSchedule.voy}</Label>
                                        <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
                                            <Stack styles={{ root: { width: '50%' } }}>
                                                <Text size={'small'}>{item.trainSchedule.originAddress}</Text>
                                                {!item.trainSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.trainSchedule.estimatedDeparture).toDate())} (Est.)</Text> : null}
                                                {item.trainSchedule.actualDeparture ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.trainSchedule.actualDeparture).toDate())} (Actual)</Text> : null}
                                            </Stack>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                            <Stack styles={{ root: { width: '50%' } }}>
                                                <Text size={'small'}>{item.trainSchedule.destinationAddress}</Text>
                                                {!item.trainSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.trainSchedule.estimatedArrival).toDate())} (Est.)</Text> : null}
                                                {item.trainSchedule.actualArrival ? <Text size={'xsmall'}>{GeneralService.formatDate(moment(item.trainSchedule.actualArrival).toDate())} (Actual)</Text> : null}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack> : null}
                            </Stack>
                        </Tag>
                    </> : null}
                    {item.type === 'trucking' && (item.truck) ? <>
                        <Tag>
                            <Stack tokens={{ childrenGap: 10 }} grow={1}>
                                {item.truck ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <Stack styles={{ root: { width: 18 } }}><FontAwesomeIcon icon={faTruck} fontSize={13} style={{ marginTop: 1 }} /></Stack>
                                    <Stack>
                                        <Label size={'xsmall'}>{item.truck.name}</Label>
                                        {item.truck.fleetType ? <Text size={'xsmall'}>{item.truck.fleetType.name}</Text> : null}
                                    </Stack>
                                </Stack> : null}
                            </Stack>
                        </Tag>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "transferFrom",
            name: "Transfer From",
            minWidth: 225,
            maxWidth: 225,
            isMultiline: true,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 5 }}>
                    <Label size={'xsmall'}>{item.subsidiary.name}</Label>
                    {item.bankAccount ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <Label size={'xsmall'}>{item.bankAccount.accountNumber} a/n {item.bankAccount.accountName}</Label>
                            <Text size={'xsmall'}>{item.bankAccount.bank.name}</Text>
                            <Text size={'xsmall'}>{[item.bankAccount.bankCity, item.bankAccount.bank.country].filter((s) => (s || "") !== "" && (s || "") !== "-").join(", ")}</Text>
                        </Stack>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "dueDate",
            name: "Due Date",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{GeneralService.formatDate(moment(item.dueDate).toDate())}</Text>
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Amount",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOutcomeResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalAmount))}</Text>
                    {Number(item.ppn) > 0 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>(inc. PPN) Rp. {GeneralService.getNumberWithSeparator(Number(item.ppn))}</Text> : null}
                    {Number(item.pph) > 0 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>(inc. PPh) Rp. {GeneralService.getNumberWithSeparator(Number(item.pph))}</Text> : null}
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOutcomeResourceShortProps) => {
                let statusText = 'Unpaid';
                let statusVariant: TTagVariant = 'warning';

                if (item.status.toLowerCase() === 'cancelled') {
                    statusText = 'Cancelled';
                    statusVariant = 'error';
                } else if (Number(item.unpaid) <= 0) {
                    statusText = 'Paid';
                    statusVariant = 'success';
                }

                return <Stack horizontal>
                    <Tag size={'small'} text={statusText} variant={statusVariant} />
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.invoices) {
            _onRetrieveOutcomes();
        } else {
            setLoaded(true);
        }
    }, [keyword]);

    useEffect(() => {
        setOutcomes(props.invoices || []);
    }, [props.invoices])

    const _onRetrieveOutcomes = async (pageNumber?: number) => {
        try {
            setLoaded(false);

            if (params.invoiceId) {
                setActiveSurface('details');
            }

            const qs: string[] = props.qs || [];
            qs.push(`top=${GlobalConfig.defaultTop}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await OutcomeService.retrieve(qs.join("&"));
            setOutcomes(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcomes_list_error',
                text: 'Failed to retrieve outcomes list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['outcomes.invoices.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create invoice",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <NavLink to={'/finance/outcomes/invoices/create'}>
                        <PrimaryButton text={"Create invoice"}
                            iconProps={{ iconName: "Add" }}
                            styles={{ root: { marginLeft: 10 } }} />
                    </NavLink>;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveOutcome(undefined);

        if (refresh) { _onRetrieveOutcomes() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            {!props.hideCommandBar ? <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
            <Stack tokens={{ childrenGap: 10 }}>
                {pagination && outcomes.length > 0 ? <Pagination goToPage={_onRetrieveOutcomes} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " invoice(s) found"} /> : null}
                <Stack className={styles[props.variant || 'card']}>
                    {
                        !loaded || (loaded && outcomes.length > 0) ? (
                            <>
                                <ShimmeredDetailsList
                                    setKey="items"
                                    items={outcomes}
                                    columns={columns.filter((col) => shownColumns.indexOf(col.key as TOutcomeColumn) > -1)}
                                    selectionMode={SelectionMode.none}
                                    enableShimmer={!loaded}
                                    onShouldVirtualize={() => false}
                                    ariaLabelForShimmer="Content is being fetched"
                                    ariaLabelForGrid="Item details" />
                            </>
                        ) : null
                    }
                    {loaded && outcomes.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                        <Text>Outcome(s) not found</Text>
                    </Stack> : null}
                </Stack>
                {pagination && outcomes.length > 0 ? <Pagination goToPage={_onRetrieveOutcomes} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
            </Stack>
            {activeSurface === 'create' ? <OutcomeForm onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'details' && activeOutcome ? <OutcomeDetails outcomeId={activeOutcome.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {!hasPermission ? <NoAccess /> : null}
    </Stack>;
};

export default OutcomesInvoiceList;
