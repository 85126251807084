/*** src/Store.js ***/

import { makeObservable, observable, action } from "mobx";
import RootStore from './root';
import { IUserResourceShortProps } from "../props/users/user";
import { IRoleResourceShortProps } from "../props/users/role";

export class UserStore {
    private rootStore: RootStore;
    public data: IUserResourceShortProps | null = null;
    public verified: boolean = false;
    public temporaryPassword: boolean = false;
    public roles: string[] = [];
    public permissions: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            data: observable,
            verified: observable,
            temporaryPassword: observable,
            roles: observable,
            permissions: observable,
            setData: action.bound,
            setVerified: action.bound,
            setTemporaryPassword: action.bound
        });
    }

    public setData = (data: IUserResourceShortProps) => {
        this.data = data;
        this.roles = data.roles.map((r) => r.key);

        let _permissions: string[] = [];
        
        data.roles.forEach((role) => {
            role.permissions.forEach((perm) => {
                if (_permissions.indexOf(perm.permission) < 0) {
                    _permissions.push(perm.permission);
                }
            })
        });

        this.permissions = _permissions;
    }

    public setVerified = (verified: boolean) => {
        this.verified = verified;
    }

    public setTemporaryPassword = (temporaryPassword: boolean) => {
        this.temporaryPassword = temporaryPassword;
    }
}