import React from 'react';

// assets

// services
import GeneralService from '../../../../../services/general';

// props
import { IOrderGoodsResourceProps } from '../../../../../props/orders/orderGoods';

// components
import { Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';

interface IOrderGoodsDetailsPropertiesProps {
    orderGoods: IOrderGoodsResourceProps;
}

const OrderGoodsDetailsProperties: React.FC<IOrderGoodsDetailsPropertiesProps> = (props: IOrderGoodsDetailsPropertiesProps) => {
    const { orderGoods } = props;

    return <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 10 } }}>
        <Stack.Item>
            <Label>Goods</Label>
            <Text>{orderGoods.goods.name || "-"}</Text>
        </Stack.Item>
        <Stack.Item>
            <Label>Notes</Label>
            <Text>{orderGoods.notes || "-"}</Text>
        </Stack.Item>
        <Stack.Item>
            <Label>Quantity</Label>
            <Text>{GeneralService.getNumberWithSeparator(Number(orderGoods.quantityAmount))} {orderGoods.goods.quantityUnit || ""}</Text>
        </Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: '50%' } }}>
                <Label>Price</Label>
                {orderGoods.pricingMethod === 'lumpsum' ? <Text style={{ fontStyle: 'italic' }}>Use lump sum price</Text> : null}
                {orderGoods.pricingMethod === 'perquantity' ? <Text>Rp. {GeneralService.getNumberWithSeparator(Number(orderGoods.price))} / {orderGoods.goods.quantityUnit || "-"}</Text> : null}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '50%' } }}>
                <Label>PPN</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(Number(orderGoods.ppn || '0'))}</Text>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '50%' } }}>
                <Label>PPh</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(Number(orderGoods.pph || '0'))}</Text>
            </Stack.Item>
        </Stack>
        <Stack>
            <Stack.Item styles={{ root: { width: '50%' } }}>
                <Label>Total Price</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(Number(orderGoods.totalPrice))}</Text>
            </Stack.Item>
        </Stack>
    </Stack>
};

export default OrderGoodsDetailsProperties;
