import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import AdministratorDetails from '../../../components/users/administrators/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IAdministratorDetailsPageProps {
}

const AdministratorDetailsPage: React.FC<IAdministratorDetailsPageProps> = (props: IAdministratorDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Customers", [
            {key: "home", icon: faHome, href: "/"},
            {key: "users", text: 'Users'},
            {key: "customers", text: 'Customers'}
        ]);
    }, []);

    return <Stack>
        <AdministratorDetails userId={params.administratorId} />
    </Stack>;
};

export default AdministratorDetailsPage;
