import React, { useEffect } from 'react';

import { ActionButton, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useStore } from '../../../../stores/root';
import OfferService from '../../../../services/offers';
import styles from './styles.module.scss';
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../services/general/error';
import GeneralService from '../../../../services/general';
import Text from '../../../typography/text';
import Label from '../../../typography/label';
import OfferSummary from './summary';
import moment from 'moment';
import LoadingDialogComponent from '../../../feedbacks/loadingDialog';
import { IOfferResourceShortProps } from '../../../../props/offers';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import LoadingComponent from '../../../feedbacks/loading';
import PermissionsService from '../../../../services/permissions';

// new costing components
import OriginTruckingCosting, { OriginTruckingCostFormData } from './costings/originTrucking';
import DestinationTruckingCosting, { DestinationTruckingCostFormData } from './costings/destinationTrucking';
import LoadingLabourCosting, { LoadingLabourCostFormData } from './costings/loadingLabour';
import UnloadingLabourCosting, { UnloadingLabourCostFormData } from './costings/unloadingLabour';
import ContainerShippingCosting, { ContainerShippingCostFormData } from './costings/containerShipping';
import CharteredShipCosting, { CharteredShipCostFormData } from './costings/charteredShip';
import { IQuantityUnitResourceShortProps } from '../../../../props/general/quantityUnit';
import QuantityUnitService from '../../../../services/general/quantityUnit';
import InsuranceCosting, { InsuranceCostFormData } from './costings/insurance';
import OfferedPrice, { OfferedPriceFormData, OfferedPriceItemData } from './pricings';
import OfferFormSteps from './steps';
import AdditionalCostCosting, { AdditionalCostFormData } from './costings/additionalCost';
import OfferGeneralDetails, { GeneralDetailsFormData } from './details/general';
import OfferCustomerDetails, { CustomerDetailsFormData } from './details/customer';
import OfferFreightDetails, { FreightDetailsFormData } from './details/freight';
import OfferAddressDetails, { AddressDetailsFormData } from './details/address';
import OfferIncludedCosts, { IncludedCostsFormPropsData } from './costings/includedCosts';
import PPnCosting, { PPnFormData } from './costings/ppn';
import PPhCosting, { PPhFormData } from './costings/pph';
import SummaryDetails from './details/summary';


interface IOfferFormProps {
	offerId?: string;
	mode?: 'create' | 'update' | 'view';
}

export type FormDataProps = {
	// new
	id?: string;
	offerNumber: string;
	includedCosts?: IncludedCostsFormPropsData;
	subtotalCost: number;
	totalCost: number;
	profitAmount: number;
	profitPercentage: number;
	status: string;
	createdAt: string;
	orderId?: string;
	orderCreatedOn?: string;

	// details form
	orderDetails?: GeneralDetailsFormData;
	customerDetails?: CustomerDetailsFormData;
	freightDetails?: FreightDetailsFormData;
	addressDetails?: AddressDetailsFormData;

	// costing form
	additionalCosts?: AdditionalCostFormData[];
	originTruckingCost?: OriginTruckingCostFormData[];
	destinationTruckingCost?: DestinationTruckingCostFormData[];
	loadingLabourCost?: LoadingLabourCostFormData[];
	unloadingLabourCost?: UnloadingLabourCostFormData[];
	containerShippingCost?: ContainerShippingCostFormData[];
	charteredShipCost?: CharteredShipCostFormData[];
	insuranceCost?: InsuranceCostFormData[];
	offeredPrice?: OfferedPriceFormData;

	// ppn pph form
	ppnCost?: PPnFormData;
	pphCost?: PPhFormData;
}

const OfferForm: React.FC<IOfferFormProps> = (props: IOfferFormProps) => {
	const { banner, route, topbar, user } = useStore();
	const [quantityUnits, setQuantityUnits] = React.useState<IQuantityUnitResourceShortProps[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [submitting, setSubmitting] = React.useState<boolean>(false);
	const [submitted, setSubmitted] = React.useState<boolean>(false);
	const [activeStep, setActiveStep] = React.useState<string>(props.mode === 'view' ? 'summary' : 'customerDetails');
	const [offer, setOffer] = React.useState<IOfferResourceShortProps | undefined>();
	const [data, setData] = React.useState<FormDataProps>({
		offerNumber: GeneralService.makeid(25, true, 'OFR'),
		subtotalCost: 0,
		totalCost: 0,
		profitAmount: 0,
		profitPercentage: 0,
		status: 'new',
		createdAt: moment().toISOString()
	});
	const [mode, setMode] = React.useState<'create' | 'update' | 'view'>(props.offerId === undefined ? 'create' : (props.mode || 'create'));

	useEffect(() => {
		init();
	}, []);

	const _onSubmit = async () => {
		try {
			setSubmitting(true);
			setSubmitted(false);

			const {
				offerNumber,
				orderDetails,
				customerDetails,
				freightDetails,
				addressDetails,
				includedCosts,
				ppnCost,
				pphCost,
				profitAmount,
				profitPercentage,
				offeredPrice,
				subtotalCost,
				totalCost,
				loadingLabourCost,
				unloadingLabourCost,
				originTruckingCost,
				destinationTruckingCost,
				charteredShipCost,
				containerShippingCost,
				additionalCosts,
				insuranceCost
			} = data;

			const fd = new FormData();
			fd.append('offerNumber', offerNumber);

			if (orderDetails && orderDetails.subsidiary) {
				fd.append('subsidiaryId', orderDetails.subsidiary.id);
			}

			if (orderDetails && orderDetails.sales) {
				fd.append('salesId', orderDetails.sales.id);
			}

			if (customerDetails && customerDetails.company && customerDetails.customer) {
				fd.append('companyId', customerDetails.company.id);
				fd.append('customerId', customerDetails.customer.id);
				fd.append('customerName', customerDetails.name || customerDetails.customer.name);
			}

			if (freightDetails?.deliveryType && freightDetails?.freightCategory && freightDetails?.goodsType) {
				fd.append('freightCategoryId', freightDetails.freightCategory.id);
				fd.append('deliveryTypeId', freightDetails.deliveryType.id);
				fd.append('goodsType', freightDetails.goodsType);
				fd.append('goodsTypeName', DeliveredGoodsType.find(f => f.key === freightDetails.goodsType)?.text || '');
			}

			(addressDetails?.originAddresses || []).forEach((address) => {
				if (address.address.trim() !== "") {
					fd.append('originAddresses[]', JSON.stringify(address));
				}
			});

			(addressDetails?.destinationAddresses || []).forEach((address) => {
				if (address.address.trim() !== "") {
					fd.append('destinationAddresses[]', JSON.stringify(address));
				}
			});

			/*if (deliveryDetails?.originAddress && deliveryDetails?.originType) {
				fd.append('originAddress', deliveryDetails.originAddress);
				fd.append('originType', deliveryDetails.originType);
			}

			if (deliveryDetails?.originProvince && deliveryDetails?.originCity) {
				fd.append('originProvince', deliveryDetails.originProvince);
				fd.append('originCity', deliveryDetails.originCity);
			}

			if (deliveryDetails?.destinationAddress && deliveryDetails?.destinationType) {
				fd.append('destinationAddress', deliveryDetails.destinationAddress);
				fd.append('destinationType', deliveryDetails.destinationType);
			}

			if (deliveryDetails?.destinationProvince && deliveryDetails?.destinationCity) {
				fd.append('destinationProvince', deliveryDetails.destinationProvince);
				fd.append('destinationCity', deliveryDetails.destinationCity);
			}*/

			if (includedCosts?.ppn) {
				fd.append('includePPn', '1');
				if (ppnCost?.percentage) { fd.append('ppnPercentage', ppnCost.percentage); }
				if (ppnCost?.amount) { fd.append('ppnAmount', ppnCost.amount); }
			}

			if (includedCosts?.pph) {
				fd.append('includePPh', '1');
				if (pphCost?.percentage) { fd.append('pphPercentage', pphCost.percentage); }
				if (pphCost?.amount) { fd.append('pphAmount', pphCost.amount); }
			}

			if (includedCosts?.originTrucking) {
				(originTruckingCost || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('originTruckingCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.destinationTrucking) {
				(destinationTruckingCost || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('destinationTruckingCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.loadingLabour) {
				(loadingLabourCost || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('loadingLabourCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.unloadingLabour) {
				(unloadingLabourCost || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('unloadingLabourCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.charteredShip) {
				(charteredShipCost || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('charteredShipCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.containerShipping) {
				(containerShippingCost || []).forEach((cost) => {
					const data: any = cost;
					data.shipId = cost.ship?.id;
					data.shipScheduleId = cost.schedule?.id;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('containerShippingCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.insuranceCost) {
				(insuranceCost || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;

					fd.append('insuranceCost[]', JSON.stringify(data));
				})
			}

			if (includedCosts?.additionalCosts) {
				(additionalCosts || []).forEach((cost) => {
					const data: any = cost;
					data.vendorId = cost.vendor?.id;
					data.quantityUnitId = cost.quantityUnit?.id;

					fd.append('additionalCosts[]', JSON.stringify(data));
				})
			}

			if (offeredPrice) {
				(offeredPrice.items || []).filter((item) => {
					return item.name.trim() !== "" && item.price.trim() !== "";
				}).forEach((item) => {
					const data: any = item;
					data.quantityUnitId = item.quantityUnit?.id;
					fd.append('offeredPrices[]', JSON.stringify(data));
				})

				fd.append('subtotalPrice', offeredPrice.subtotalPrice || '0');
				fd.append('totalPrice', offeredPrice.totalPrice || '0');
				fd.append('paymentTerm', offeredPrice.paymentTerm || '');

				(offeredPrice.included || []).forEach((item) => {
					fd.append('includedItems[]', JSON.stringify(item));
				});

				(offeredPrice.excluded || []).forEach((item) => {
					fd.append('excludedItems[]', JSON.stringify(item));
				});
			}

			fd.append('profitPercentage', profitPercentage + '');
			fd.append('profitAmount', profitAmount + '');
			fd.append('subtotalCost', subtotalCost + '');
			fd.append('totalCost', totalCost + '');

			let offer: IOfferResourceShortProps | undefined;
			if (mode === 'create') {
				offer = await OfferService.create(fd);
			} else if (mode === 'update' && props.offerId) {
				offer = await OfferService.update(props.offerId, fd);
			}

			setSubmitted(true);
			setTimeout(() => {
				route.route?.history.push(`/offers/${offer?.id}/view`);
			}, 4000);
		} catch (error) {
			setSubmitting(false);
			banner.add({
				key: mode + '_order_error',
				variant: 'error',
				icon: faXmarkCircle,
				text: `Failed to ${mode} order. Message: ${ErrorService.getMessage(error)}`
			});
		}
	}

	const _onNext = () => {
		const steps = OfferFormSteps;
		const currentPageIndex = OfferFormSteps.findIndex((p) => p.key.toLowerCase() === activeStep.toLowerCase());		
		const errors = steps[currentPageIndex].validate(data);
		if (errors.length > 0) {
			banner.add({
				key: 'error_customer_details',
				variant: 'error',
				icon: faXmarkCircle,
				text: `Please check this fields:\n${errors.map((err, idx) => {
					return `${idx + 1}. ${err}`;
				}).join("\n")}`
			});
		} else {
			const selectedPage = steps[currentPageIndex + 1].key;
			setActiveStep(selectedPage);
		}
	}
	const _onPrevious = () => {
		const steps = OfferFormSteps;
		const currentPageIndex = OfferFormSteps.findIndex((p) => p.key.toLowerCase() === activeStep.toLowerCase());		
		const errors = steps[currentPageIndex].validate(data);
		if (errors.length > 0) {
			banner.add({
				key: 'error_customer_details',
				variant: 'error',
				icon: faXmarkCircle,
				text: `Please check this fields:\n${errors.map((err, idx) => {
					return `${idx + 1}. ${err}`;
				}).join("\n")}`
			});
		} else {
			const selectedPage = steps[currentPageIndex - 1].key;
			setActiveStep(selectedPage);
		}
	}

	const calculateCosting = () => {
		let subtotal = 0;
		let total = 0;
		let ppn = '0';
		let pph = '0';

		const { includedCosts, loadingLabourCost, insuranceCost, unloadingLabourCost, originTruckingCost, destinationTruckingCost, containerShippingCost, charteredShipCost, ppnCost, pphCost, additionalCosts, offeredPrice } = data;

		if (includedCosts?.originTrucking && originTruckingCost) {
			(originTruckingCost || []).forEach((cost) => {
				subtotal += Number(cost.totalPrice);
			});
		}

		if (includedCosts?.destinationTrucking && destinationTruckingCost) {
			(destinationTruckingCost || []).forEach((cost) => {
				subtotal += Number(cost.totalPrice);
			});
		}

		if (includedCosts?.loadingLabour && loadingLabourCost) {
			(loadingLabourCost || []).forEach((cost) => {
				subtotal += Number(cost.totalPrice);
			});
		}

		if (includedCosts?.unloadingLabour && unloadingLabourCost) {
			(unloadingLabourCost || []).forEach((cost) => {
				subtotal += Number(cost.totalPrice);
			});
		}

		if (includedCosts?.charteredShip && charteredShipCost) {
			(charteredShipCost || []).forEach((cost) => {
				subtotal += Number(cost.totalPrice);
			});
		}

		if (includedCosts?.containerShipping && containerShippingCost) {
			(containerShippingCost || []).forEach((cost) => {
				subtotal += Number(cost.totalPrice);
			});
		}

		if (includedCosts?.insuranceCost && insuranceCost) {
			(insuranceCost || []).forEach((item) => {
				subtotal += Number(item.totalPrice);
			});
		}

		if (includedCosts?.additionalCosts && additionalCosts) {
			(additionalCosts || []).forEach((item) => {
				subtotal += Number(item.totalPrice || '0');
			});
		}

		total = subtotal;

		let totalPrice = Number(offeredPrice?.totalPrice || 0);
		let originalPrice = '0';
		if (includedCosts?.ppn) {
			if (ppnCost?.percentage && ppnCost?.percentage !== 'custom') {
				originalPrice = (totalPrice * 100 / (100 + Number(ppnCost?.percentage))).toFixed();
			} else {
				originalPrice = (Number(totalPrice) - Number(ppnCost?.amount || 0)).toFixed();
			}
		} else if (!includedCosts?.ppn) {
			originalPrice = totalPrice.toFixed();
		}

		if (includedCosts?.ppn && ppnCost?.percentage && ppnCost?.percentage !== 'custom') {
			ppn = (Number(originalPrice) * (Number(ppnCost?.percentage) / 100)).toFixed();
		} else if (includedCosts?.ppn && ppnCost?.percentage === 'custom') {
			ppn = ppnCost?.amount || '0';
		}

		if (includedCosts?.pph && pphCost?.percentage && pphCost.percentage !== 'custom') {
			pph = (Number(originalPrice) * (Number(pphCost.percentage) / 100)).toFixed();
		} else if (includedCosts?.pph && pphCost?.percentage === 'custom') {
			pph = pphCost?.amount || '0';
		}

		total += Number(pph || 0);
		total += Number(ppn || 0);

		return { subtotal, total, ppn, pph };
	}

	const init = async () => {
		try {
			if (props.offerId) {
				const offer = await OfferService.read(props.offerId);
				setOffer(offer);
				setData({
					id: props.offerId,
					offerNumber: offer.offerNumber,
					customerDetails: {
						company: offer.company,
						customer: offer.customer,
						name: offer.customerName,
						phoneNumber: offer.customer.phoneNumber
					},
					orderDetails: {
						sales: offer.sales,
						subsidiary: offer.subsidiary
					},
					freightDetails: {
						deliveryType: offer.deliveryType,
						freightCategory: offer.freightCategory,
						goodsType: offer.goodsType
					},
					addressDetails: {
						originAddresses: offer.originAddresses,
						destinationAddresses: offer.destinationAddresses
					},
					includedCosts: {
						loadingLabour: offer.loadingLabourCosts != undefined && offer.loadingLabourCosts.length > 0,
						unloadingLabour: offer.unloadingLabourCosts != undefined && offer.unloadingLabourCosts.length > 0,
						containerShipping: offer.containerShippingCosts != undefined && offer.containerShippingCosts.length > 0,
						additionalCosts: offer.additionalCosts != undefined && offer.additionalCosts.length > 0,
						originTrucking: offer.originTruckingCosts != undefined && offer.originTruckingCosts.length > 0,
						destinationTrucking: offer.destinationTruckingCosts != undefined && offer.destinationTruckingCosts.length > 0,
						charteredShip: offer.charteredShipCosts != undefined && offer.charteredShipCosts.length > 0,
						insuranceCost: offer.insuranceCosts != undefined && offer.insuranceCosts.length > 0,
						ppn: offer.includePPn,
						pph: offer.includePPh
					},
					loadingLabourCost: offer.loadingLabourCosts || [],
					originTruckingCost: offer.originTruckingCosts || [],
					unloadingLabourCost: offer.unloadingLabourCosts || [],
					destinationTruckingCost: offer.destinationTruckingCosts || [],
					containerShippingCost: offer.containerShippingCosts || [],
					charteredShipCost: offer.charteredShipCosts || [],
					additionalCosts: offer.additionalCosts || [],
					insuranceCost: offer.insuranceCosts || [],
					/*insuranceCost: {
						price: (offer.insuranceCost?.price || 0) + '',
						percentage: (offer.insuranceCost?.percentage || 0) + '',
						goodsValue: (offer.insuranceCost?.goodsValue || 0) + '',
						priceType: offer.insuranceCost?.priceType
					},*/
					//additionalCosts: { items: offer.additionalCosts },
					offeredPrice: {
						items: (offer.offeredPrices || []) as OfferedPriceItemData[],
						paymentTerm: offer.paymentTerm || "",
						subtotalPrice: offer.subtotalPrice + '',
						totalPrice: offer.totalPrice + '',
						included: offer.includedItems || [],
						excluded: offer.excludedItems || []
					},
					/*offeredPrice: {
						items: offer.offeredPrices,
						paymentTerm: offer.paymentTerm,
						subtotalPrice: offer.subtotalPrice + '',
						totalPrice: offer.totalPrice + ''
					},*/
					pphCost: offer.pphAmount > 0 ? {
						amount: offer.pphAmount + '',
						percentage: offer.pphPercentage !== 'Custom' ? Number(offer.pphPercentage) + '' : 'Custom'
					} : undefined,
					ppnCost: offer.ppnAmount > 0 ? {
						amount: offer.ppnAmount + '',
						percentage: offer.ppnPercentage !== 'Custom' ? Number(offer.ppnPercentage) + '' : 'Custom'
					} : undefined,
					subtotalCost: offer.subtotalCost,
					totalCost: offer.totalCost,
					profitAmount: offer.profitAmount,
					profitPercentage: offer.profitPercentage,
					status: offer.status,
					createdAt: offer.createdAt,
					orderId: offer.orderId,
					orderCreatedOn: offer.orderCreatedOn
				});
				setMode(props.mode || 'create');

				topbar.show(offer.offerNumber, [
					{ key: "home", icon: faHome, href: "/" },
					{ key: "offers", text: 'Offers', href: "/offers" },
					{ key: offer.id, text: '#' + offer.offerNumber }
				]);
			} else {
				topbar.show("Create Offer", [
					{ key: "home", icon: faHome, href: "/" },
					{ key: "offers", text: 'Offers', href: "/offers" },
					{ key: "create", text: 'Create' }
				]);
			}

			const _quantityUnits = await QuantityUnitService.retrieve();
			setQuantityUnits(_quantityUnits);

			setLoaded(true);
		} catch (e) {
			banner.add({
				key: 'initialise_offer_form_error',
				variant: 'error',
				icon: faXmarkCircle,
				text: `Failed to initialise offer form. Message: ${ErrorService.getMessage(e)}`
			});
		}
	}

	const calculateCostingAndProfit = () => {
		const costing = calculateCosting();
		const profitAmount = Number(data.offeredPrice?.totalPrice || '0') - costing.total;
		const profitPercentage = Number(((profitAmount / costing.total) * 100).toFixed(2));

		data.subtotalCost = costing.subtotal;
		data.totalCost = costing.total;
		data.profitAmount = profitAmount;
		data.profitPercentage = profitPercentage;

		if (data.ppnCost) {
			data.ppnCost.amount = costing.ppn;
		}
		if (data.pphCost) {
			data.pphCost.amount = costing.pph;
		}

		setData({ ...data });
	}

	const onApprove = async () => {
		setSubmitting(true)
		try {
			await OfferService.approve(props?.offerId || '');
			setData({ ...data, status: 'approved' });
			setSubmitting(false)
		} catch (error) {

			setSubmitting(false)
		}
	}

	const onReject = async () => {
		setSubmitting(true)
		try {
			await OfferService.reject(props?.offerId || '');
			setData({ ...data, status: 'rejected' });
			setSubmitting(false)
		} catch (error) {

			setSubmitting(false)
		}
	}

	const getAccessMode = (mode: 'create' | 'update' | 'view', type?: 'pricing') => {
		const hasCreateAccess = PermissionsService.hasPermission(['offers.create'], user.permissions);

		if (mode === 'create' && hasCreateAccess) {
			return 'create';
		} else if (mode === 'update' && PermissionsService.hasPermission(['offers.update.all'], user.permissions)) {
			return 'update';
		} else if (mode === 'update' && type === 'pricing' && (PermissionsService.hasPermission(['offers.pricings.update.all'], user.permissions) || (PermissionsService.hasPermission(['offers.pricings.update.me'], user.permissions) && data.orderDetails?.sales?.id === user.data?.id))) {
			return 'update';
		}

		return 'view';
	}

	return <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
		<Stack className={styles.pagination} verticalAlign='center' horizontal tokens={{ childrenGap: 20 }}>
			{OfferFormSteps.map((step, idx) => {
				const isActive = step.key === activeStep;
				return <>
					{idx > 0 ? <Stack grow={1} className={styles.divider}> </Stack> : null}
					<Stack key={step.key} verticalAlign='center' horizontal tokens={{ childrenGap: 10 }} className={styles.item} onClick={() => setActiveStep(step.key)}>
						<Text className={`${styles.number} ${isActive ? styles.active : ''}`}>{idx + 1}</Text>
						<Label className={`${styles.title} ${isActive ? styles.active : ''}`}>{step.title}</Label>
					</Stack>
				</>;
			})}
		</Stack>
		<Stack className={styles.body} horizontal tokens={{ childrenGap: 20 }}>
			<Stack className={styles.left} grow={1} tokens={{ childrenGap: 20 }}>
				{!loaded ? <LoadingComponent label={'Retrieving form ...'} labelPosition={'right'} spinnerPosition={'baseline'} withBackground /> : null}
				{
					loaded ? <>
						<Stack tokens={{ childrenGap: 40 }} className={styles.form} >
							{activeStep === 'customerDetails' ? <>
								<OfferGeneralDetails data={data.orderDetails}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.orderDetails = value;
										setData({ ...data });
									}} />
								<Stack className={'divider'}></Stack>
								<OfferCustomerDetails data={data.customerDetails}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.customerDetails = value;
										setData({ ...data });
									}} />
							</> : null}
							{activeStep === 'freightDetails' ? <>
								<OfferFreightDetails data={data.freightDetails}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.freightDetails = value;
										setData({ ...data });
									}} />
								<Stack className={'divider'}></Stack>
								<OfferAddressDetails data={data.addressDetails}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.addressDetails = value;
										setData({ ...data });
									}} />
							</> : null}
							{activeStep === 'costing' ? <>
								<OfferIncludedCosts data={data.includedCosts}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.includedCosts = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} />
								{data.includedCosts?.originTrucking ? <OriginTruckingCosting data={data.originTruckingCost}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.originTruckingCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.loadingLabour ? <LoadingLabourCosting data={data.loadingLabourCost}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.loadingLabourCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.destinationTrucking ? <DestinationTruckingCosting data={data.destinationTruckingCost}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.destinationTruckingCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.unloadingLabour ? <UnloadingLabourCosting data={data.unloadingLabourCost}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.unloadingLabourCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.containerShipping ? <ContainerShippingCosting data={data.containerShippingCost}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.containerShippingCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.charteredShip ? <CharteredShipCosting data={data.charteredShipCost}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.charteredShipCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.additionalCosts ? <AdditionalCostCosting data={data.additionalCosts}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.additionalCosts = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								{data.includedCosts?.insuranceCost ? <InsuranceCosting data={data.insuranceCost}
									mode={getAccessMode(mode)}
									onChange={(value) => {
										data.insuranceCost = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} /> : null}
								<Stack horizontal tokens={{ childrenGap: 20 }}>
									{data.includedCosts?.ppn ? <Stack styles={{ root: { width: '50%' } }}>
										<PPnCosting data={data.ppnCost}
											mode={getAccessMode(mode)}
											onChange={(value) => {
												data.ppnCost = value;
												setData({ ...data });
												calculateCostingAndProfit();
											}} />
									</Stack> : null}
									{data.includedCosts?.pph ? <Stack styles={{ root: { width: '50%' } }}>
										<PPhCosting data={data.pphCost}
											mode={getAccessMode(mode)}
											onChange={(value) => {
												data.pphCost = value;
												setData({ ...data });
												calculateCostingAndProfit();
											}} />
									</Stack> : null}
									{!data.includedCosts?.ppn ? <Stack styles={{root: {width: '50%'}}}> </Stack> : null}
									{!data.includedCosts?.pph ? <Stack styles={{root: {width: '50%'}}}> </Stack> : null}
								</Stack>

							</> : null}
							{activeStep === 'offerDetails' ? <>
								<OfferedPrice fd={data} data={data.offeredPrice}
									quantityUnits={quantityUnits}
									mode={getAccessMode(mode, 'pricing')}
									onChange={(value) => {
										data.offeredPrice = value;
										setData({ ...data });
										calculateCostingAndProfit();
									}} />
							</> : null}
							{activeStep === 'summary' ? <>
								<SummaryDetails mode={mode} data={data} offer={offer} />
							</> : null}
						</Stack>
						<Stack className={styles.footer} horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 20 }}>
							{submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating offer ..." : "Updating offer ..."} /> : null}
							{!submitting ? <>
								<ActionButton text={"Previous"} iconProps={{ iconName: "ChevronLeft" }} disabled={activeStep === 'customerDetails'} onClick={() => _onPrevious()} />
								{activeStep !== 'summary' ? <ActionButton text={"Next"} menuIconProps={{ iconName: "ChevronRight" }} onClick={() => _onNext()} /> : null}
								{activeStep === 'summary' && mode !== 'view' ? <PrimaryButton text={"Submit"} onClick={_onSubmit} /> : null}
								{activeStep === 'summary' && mode === 'view' && data.status !== 'approved' && data.status !== 'rejected' && !data.orderId ? <Stack horizontal tokens={{ childrenGap: 10 }}>
									{PermissionsService.hasPermission(['offers.approve.all'], user.permissions) || (PermissionsService.hasPermission(['offers.approve.me'], user.permissions) && data.orderDetails?.sales?.id === user.data?.id) ? <PrimaryButton
										disabled={submitting}
										text={'Approved'}
										onClick={onApprove}
									/> : null}
									{PermissionsService.hasPermission(['offers.reject.all'], user.permissions) || (PermissionsService.hasPermission(['offers.reject.me'], user.permissions) && data.orderDetails?.sales?.id === user.data?.id) ? <DefaultButton
										disabled={submitting}
										text={'Rejected'}
										onClick={onReject}
									/> : null}
								</Stack> : null}
								{/* {activePage === 'summary' && mode === 'view' && data.status === 'rejected' ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Tag variant='error' icon={faXmark} text='Penawaran telah ditolak.' />
                </Stack> : null}
                {activePage === 'summary' && mode === 'view' && data.status === 'approved' ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Tag variant='success' icon={faCheck} text='Penawaran telah disetujui.' />
                </Stack> : null} */}
							</> : null}
						</Stack>
					</> : null
				}
			</Stack>
			<Stack className={styles.right}>
				<OfferSummary data={data} />
			</Stack>
		</Stack>
		{submitting && !submitted ? <LoadingDialogComponent title='Creating Offer' secondaryText={'Please wait while we create this offer. Once completed, you will redirected to the offer details page.'} /> : null}
		{submitted ? <LoadingDialogComponent
			title={`Offer ${mode === 'create' ? 'Created' : 'Updated'}`}
			secondaryText={`This offer ${mode === 'create' ? 'created' : 'updated'} successfully. You will be redirected momentarily.`}
		/> : null}
	</Stack>
};

export default OfferForm;
