import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../services/general';
import OrderGoodsService from '../../../../../services/orders/orderGoods';

// props
import { IOrderGoodsResourceShortProps } from '../../../../../props/orders/orderGoods';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import LoadingComponent from '../../../../feedbacks/loading';

interface IDeleteOrderGoodsFormProps {
    orderId: string;
    orderGoods: IOrderGoodsResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteOrderGoodsForm: React.FC<IDeleteOrderGoodsFormProps> = (props: IDeleteOrderGoodsFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await OrderGoodsService.delete(props.orderId, props.orderGoods.id);

            banner.add({
                key: 'delete_order_goods_success',
                variant: 'success',
                icon: faCheck,
                text: `Order goods "${props.orderGoods.goods.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Order Goods"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting order goods ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Preparing form ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Text>Are you sure you want to delete order goods with details below?</Text>
                <Stack.Item>
                    <Label>Goods</Label>
                    <Text>{props.orderGoods.goods.name || "-"}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>Notes</Label>
                    <Text>{props.orderGoods.notes || "-"}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>Quantity</Label>
                    <Text>{GeneralService.getNumberWithSeparator(Number(props.orderGoods.quantityAmount))} {props.orderGoods.goods.quantityUnit || ""}</Text>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Price</Label>
                        {props.orderGoods.pricingMethod === 'lumpsum' ? <Text style={{ fontStyle: 'italic' }}>Use lump sum price</Text> : null}
                        {props.orderGoods.pricingMethod === 'perquantity' ? <Text>Rp. {GeneralService.getNumberWithSeparator(Number(props.orderGoods.price))} / {props.orderGoods.goods.quantityUnit || "-"}</Text> : null}
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Total Price</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(Number(props.orderGoods.totalPrice))}</Text>
                    </Stack.Item>
                </Stack>
            </> : null}
        </Stack>
    </Panel>
};

export default DeleteOrderGoodsForm;
