import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { ActionButton, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { useStore } from '../../../../stores/root';
import { IOrderResourceShortProps } from '../../../../props/orders/order';
import OrderService from '../../../../services/orders/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCheckSquare, faDollar, faGlobe, faHashtag, faUser, faUserTag } from '@fortawesome/pro-light-svg-icons';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Tag, { TTagVariant } from '../../tag';
import { IOutcomeResourceShorterProps } from '../../../../props/finance/outcomes';
import OutcomeService from '../../../../services/finance/outcomes';
import GeneralService from '../../../../services/general';

type SelectInvoiceProps = {
    selected?: IOutcomeResourceShorterProps;
    options?: IOutcomeResourceShorterProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    error?: string;
    onChange(selected?: IOutcomeResourceShorterProps): void;
}

const SelectInvoice: React.FC<SelectInvoiceProps> = (props: SelectInvoiceProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [error, setError] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IOutcomeResourceShorterProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);
    
    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
    }, [props]);

    const _onSearchInvoice = async (keyword: string) => {
        try {
            if (props.options) {
                return props.options.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));
            } else {
                const qs: string[] = props.qs || [];
                qs.push(`top=10`);
                if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

                const options = (await OutcomeService.retrieve(qs.join("&"))).data.map((item) => ({
                    key: item.id,
                    name: item.name,
                    metadata: item
                }));

                return options;
            }
        } catch (error) {
            return [];
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Invoice"}</Label>}
        {!selected ? <>
            <TagPicker
                disabled={props.disabled}
                selectedItems={[]}
                removeButtonAriaLabel='Remove'
                itemLimit={1}
                onItemSelected={(item: any) => {
                    const selected = item ? item.metadata : undefined;
                    props.onChange(selected);

                    return null;
                }}
                onRenderSuggestionsItem={(props: any) => {
                    const invoice: IOutcomeResourceShorterProps = props.metadata;
                    let statusVariant: TTagVariant = 'active';

                    if (Number(invoice.unpaid) <= 0) { statusVariant = 'success'; }
                    else if (Number(invoice.unpaid) > 0) { statusVariant = 'error'; }

                    return <Stack tokens={{ childrenGap: 10 }} horizontalAlign={'baseline'} styles={{ root: { width: '100%', padding: 5 } }}>
                        <Stack styles={{ root: { width: '100%' } }} horizontalAlign={'baseline'}>
                            <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }} horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Label size={'small'}>{invoice.name}</Label>
                                <Tag text={Number(invoice.unpaid) > 0 ? 'Unpaid' : 'Paid'} size={'small'} variant={statusVariant} />
                            </Stack>
                            <Text size={'small'}>(Rp. {GeneralService.getNumberWithSeparator(Number(invoice.totalAmount))})</Text>
                        </Stack>
                        <Stack tokens={{ childrenGap: 3 }}>
                            {invoice.invoiceNumber ? <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faHashtag} size={'xs'} style={{ marginTop: -1, width: 15 }} />
                                <Text size={'xsmall'}>{invoice.invoiceNumber}</Text>
                            </Stack> : null}
                            {invoice.vendor ? <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                                <FontAwesomeIcon icon={faBuilding} size={'xs'} style={{ marginTop: -1, width: 15 }} />
                                <Text size={'xsmall'}>{invoice.vendor.name}</Text>
                            </Stack> : null}
                        </Stack>
                    </Stack>
                }}
                onResolveSuggestions={_onSearchInvoice}
                onEmptyResolveSuggestions={() => _onSearchInvoice('')}
            />
        </> : null}
        {selected ? <Stack styles={{ root: { padding: 8 } }} className={'card'} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
            <Stack tokens={{ childrenGap: 10 }} styles={{root: {width: '100%'}}}>
                <Label size={'small'}>{selected.name}</Label>
                {selected.invoiceNumber || selected.vendor ? <>
                    <Stack className={'divider'}></Stack>
                    <Stack tokens={{ childrenGap: 3 }}>
                        {selected.invoiceNumber ? <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                            <FontAwesomeIcon icon={faHashtag} size={'xs'} style={{ marginTop: -2, width: 15 }} />
                            <Text size={'xsmall'}>{selected.invoiceNumber}</Text>
                        </Stack> : null}
                        {selected.vendor ? <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                            <FontAwesomeIcon icon={faBuilding} size={'xs'} style={{ marginTop: -2, width: 15 }} />
                            <Text size={'xsmall'}>{selected.vendor.name}</Text>
                        </Stack> : null}
                    </Stack>
                </> : null}
                <Stack className={'divider'}></Stack>
                <Stack horizontal tokens={{ childrenGap: 3 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                    <Text size={'xsmall'} style={{ width: 75 }}>Total</Text>
                    <Text size={'xsmall'}>:</Text>
                    <Text size={'xsmall'}>Rp. {GeneralService.getNumberWithSeparator(Number(selected.totalAmount))}</Text>
                </Stack>
                {Number(selected.ppn || "0") > 0 ? <Stack horizontal tokens={{ childrenGap: 3 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                    <Text size={'xsmall'} style={{ width: 75 }}>PPn</Text>
                    <Text size={'xsmall'}>:</Text>
                    <Text size={'xsmall'}>Rp. {GeneralService.getNumberWithSeparator(Number(selected.ppn))}</Text>
                </Stack> : null}
                {Number(selected.pph || "0") > 0 ? <Stack horizontal tokens={{ childrenGap: 3 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                    <Text size={'xsmall'} style={{ width: 75 }}>PPh</Text>
                    <Text size={'xsmall'}>:</Text>
                    <Text size={'xsmall'}>Rp. {GeneralService.getNumberWithSeparator(Number(selected.pph))}</Text>
                </Stack> : null}
                <Stack className={'divider'}></Stack>
                <Stack horizontal tokens={{ childrenGap: 3 }} verticalAlign={'center'} horizontalAlign={'baseline'}>
                    <Text size={'xsmall'} style={{ width: 75 }}>Outstanding</Text>
                    <Text size={'xsmall'}>:</Text>
                    <Text size={'xsmall'} className={'color-red'}>Rp. {GeneralService.getNumberWithSeparator(Number(selected.unpaid))}</Text>
                </Stack>
            </Stack>
            {!props.disabled ? <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => { props.onChange(); }} /> : null}
        </Stack> : null}
        {error ? <Text size={'xsmall'} className={'color-red'} style={{marginTop: 5}}>{error}</Text> : null}
    </Stack>;
};

export default SelectInvoice;
