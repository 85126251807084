import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../../../typography/text';
import Label from '../../../../../../typography/label';
import { TSelectedFilterKey } from '.';
import { IVendorResourceShortProps } from '../../../../../../../props/data/vendors';
import { IOutcomeResourceShorterProps } from '../../../../../../../props/finance/outcomes';

type OutcomeInvoicesSummaryFilterPaymentStatusProps = {
    invoices: IOutcomeResourceShorterProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: string[]): void;
}

const OutcomeInvoicesSummaryFilterPaymentStatus: React.FC<OutcomeInvoicesSummaryFilterPaymentStatusProps> = (props: OutcomeInvoicesSummaryFilterPaymentStatusProps) => {
    const { banner } = useStore();
    const [selected, setSelected] = React.useState<string[]>([]);

    useEffect(() => {

    }, [props.invoices]);

    const getUniquePaymentStatus = (): {key: string, text: string}[] => {
        const paymentStatus: {key: string, text: string}[] = [
            {key: 'paid', text: 'Paid'},
            {key: 'unpaid', text: 'Unpaid'},
            {key: 'pending', text: 'Pending'}
        ];

        return paymentStatus;
    }

    const renderOption = (status: {key: string, text: string}) => {
        const isChecked = selected.findIndex((s) => s === status.key) > -1;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, status)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{status.text}</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, status: {key: string, text: string}) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s !== status.key);
        } else {
            _selected.push(status.key);
        }

        setSelected([..._selected]);
        props.onChange('paymentStatus', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Payment Status</Label>
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniquePaymentStatus().map((status) => {
                return renderOption(status);
            })}
        </Stack>
    </Stack>;
};

export default OutcomeInvoicesSummaryFilterPaymentStatus;
