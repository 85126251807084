
import React, {  } from 'react';

// import props

// import subpages

// import components

// import fabric ui component
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './styles.module.scss';
import { Stack, Text } from '@fluentui/react';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

interface IButtonCardProps {
    horizontal?: boolean;
    gap?: number;
    buttons: IButtonProps[];
    buttonCount?: number;
}

export interface IButtonProps {
    variant?: 'primary' | 'default' | 'light';
    key?: string;
    icon?: IconProp;
    description?: string;
    text: string;
    onClick?(): void;
    link?: string;
    shadow?: boolean;
    width?: string;
} 

const CardButton: React.FC<IButtonCardProps> = (props) => {
    

    return <Stack horizontal={props.horizontal} tokens={{childrenGap: props.gap || 10}}>
        {props.buttons.map((button, idx) => {
            const body = <Stack key={button.key || idx} className={`${styles.buttonCard} ${styles[button.variant || 'default']} ${button.shadow ? styles.shadow : ''}`} horizontal tokens={{childrenGap: 20}} verticalAlign='center'>
                {button.icon ? <FontAwesomeIcon icon={button.icon} fontSize={30} /> : null}
                <Stack grow={1}>
                    <Text variant={'mediumPlus'} style={{fontWeight: '600'}}>{button.text}</Text>
                    {button.description ? <Text variant={'small'}>{button.description}</Text> : null}
                </Stack>
                <FontAwesomeIcon icon={faArrowRight} />
            </Stack>;

            return <Stack styles={{root: {width: button.width || (100/props.buttons.length) + '%'}}} onClick={button.onClick}>
                {!button.onClick && button.link ? <NavLink to={button.link || "#"}>{body}</NavLink> : body}
            </Stack>
        })}
    </Stack>
}

export default CardButton;