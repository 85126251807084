import { DatePicker, DefaultButton, Dropdown, IconButton, MessageBarType, Panel, PanelType, Persona, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

// assets
import styles from './styles.module.scss';
import { SidebarFarMenu, MenuGroupProps, MenuItemProps, SidebarMenu, SidebarMenuProps } from '../../../manifests/sidebar';
import Label from '../../typography/label';
import Text from '../../typography/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faChevronDown, faChevronRight, faChevronUp, faUser } from '@fortawesome/pro-light-svg-icons';
import { observer } from 'mobx-react';
import Heading from '../../typography/heading';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IBreadcrumbProps {
    items: IBreadcrumbItemProps[];
}

export type IBreadcrumbItemProps = {
    key: string;
    icon?: IconProp;
    href?: string;
    text?: string;
}

const Breadcrumb: React.FC<IBreadcrumbProps> = observer((props: IBreadcrumbProps) => {
    const {items} = props;
    
    const renderBreadcrumbItem = (item: IBreadcrumbItemProps, idx: number) => {
        const isLast = idx === items.length - 1;
        const component = <Stack key={item.key} horizontal verticalAlign='center' tokens={{childrenGap: 5}}>
            {item.icon ? <FontAwesomeIcon className={styles.text} icon={item.icon} fontSize={12} style={{marginTop: -1}} /> : null}
            {isLast && item.text ? <Label size='small'>{item.text}</Label> : null}
            {!isLast && item.text ? <Text className={styles.text}>{item.text}</Text> : null}
        </Stack>;

        return <>
            {item.href ? <NavLink key={item.key + "_link"} to={item.href}>{component}</NavLink> : component}
            {!isLast ? <FontAwesomeIcon key={item.key + "_icon"} icon={faChevronRight} fontSize={10} /> : null}
        </>
    }

    return <Stack className={styles.container} horizontal wrap verticalAlign='center' tokens={{childrenGap: 10}}>
        {items.map(renderBreadcrumbItem)}
    </Stack>;
});

export default Breadcrumb;
