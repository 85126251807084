import { Stack } from '@fluentui/react';
import React, { } from 'react';
import styles from './styles.module.scss';

// assets

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './../../typography/text';
import { NavLink } from 'react-router-dom';
import Label from '../../typography/label';

import ComingSoonVector from './../../../assets/vectors/coming-soon.webp';

type IComingSoonProps = {}

const ComingSoon: React.FC<IComingSoonProps> = (props: IComingSoonProps) => {
    
    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <img className={styles.image} src={ComingSoonVector} />
        <Stack>
            <Label size={'large'} className={styles.text}>We're Working on This Page</Label>
            <Text size={'small'} className={styles.text}>We are currently still constructing this page. Please check this page periodically.</Text>
        </Stack>
    </Stack>
};

export default ComingSoon;
