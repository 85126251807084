import React, { useEffect } from 'react';
import { ActionButton, Checkbox, DatePicker, DefaultButton, Label, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../../../../stores/root';
import ValidationService from '../../../../../services/validation';
import { SalesCommissionService } from '../../../../../services/users/team';
import { ISalesCommissionResourceShort } from '../../../../../props/users/team';
import { IUserResourceShortProps } from '../../../../../props/user';
import GeneralService from '../../../../../services/general';
import authService from '../../../../../services/users/user';
import ErrorService from '../../../../../services/general/error';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ISalesCommissionFormProps {
    sales?: IUserResourceShortProps;
    onDismissed(refresh?: boolean): void;
    salesComission?: ISalesCommissionResourceShort
}

type FormDataProps = {
    sales?: IUserResourceShortProps;
    commissionPercentage: string;
    startAmount: string;
    endAmount: string;
    startDate: string;
    endDate?: string;
}

type FormDataErrorProps = {
    commissionPercentage?: string
    startAmount?: string
    endAmount?: string
}

const SalesCommissionForm: React.FC<ISalesCommissionFormProps> = (props: ISalesCommissionFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [unlimitedEndAmount, setUnlimitedEndAmount] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        commissionPercentage: '',
        startAmount: '',
        endAmount: '',
        startDate: moment().toISOString()
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.salesComission === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.salesComission) {
            const item = await SalesCommissionService.get(props.salesComission.id);
            item.endAmount === null && setUnlimitedEndAmount(true)
            setData({
                commissionPercentage: item.commissionPercentage,
                startAmount: item.startAmount,
                endAmount: item?.endAmount || '',
                sales: item.sales,
                startDate: item.startDate,
                endDate: item.endDate
            });
        } else if (props.sales) {
            setData({ ...data, sales: props.sales });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        const { sales, commissionPercentage, startAmount, endAmount } = data
        const { commissionPercentage: errorcommissionPercentage, startAmount: errorstartAmount, endAmount: errorendAmount } = error
        if (startAmount.trim() === '' || commissionPercentage.trim() === '' || sales === undefined) {
            return true
        }
        if (errorcommissionPercentage !== undefined || errorstartAmount !== undefined || errorendAmount !== undefined) {
            return true
        }
        return false;
    }

    const _onSubmit = async () => {
        setSubmitting(true);
        const { commissionPercentage, startAmount, endAmount, sales, startDate, endDate } = data;
        try {

            const fd = new FormData();

            fd.append('salesId', sales?.id || '')
            fd.append('commissionPercentage', commissionPercentage)
            fd.append('startAmount', startAmount)
            if (!unlimitedEndAmount) {
                fd.append('endAmount', endAmount)
            } else {
                fd.append('endAmount', '0')
            }

            fd.append('startDate', startDate);
            if (endDate !== '' && endDate) {
                fd.append('endDate', endDate);
            }

            if (props.salesComission === undefined) {
                await SalesCommissionService.new(fd);
            } else {
                await SalesCommissionService.update(props.salesComission.id, fd);
            }
            banner.add({
                key: 'create_sales_commission_success',
                variant: 'success',
                icon: faCheck,
                text: `Sales commission for ${sales?.name} added successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchSales = async (keyword: string) => {
        try {
            const qs: string[] = [];
            // qs.push(`rolesGroup=backoffice`);
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await authService.retrieve(qs.join("&"));

            return results.data.filter((d, idx) => idx < 10).map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_sales_error',
                text: 'Failed to search related sales. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }



    return <Panel headerText={mode === 'create' ? "Add Commission" : "Update Commission"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Adding commission ..." : "Updating commission ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <Label required>Sales</Label>
                        <NormalPeoplePicker
                            onResolveSuggestions={_onSearchSales}
                            onEmptyResolveSuggestions={() => _onSearchSales('')}
                            itemLimit={1}
                            selectedItems={data.sales ? [
                                {
                                    id: data.sales.id,
                                    text: data.sales.name,
                                    secondaryText: data.sales.email,
                                    imageInitials: GeneralService.getInitial(data.sales.name),
                                    metadata: data.sales,
                                } as any,
                            ] : []}
                            disabled={submitting || props.sales !== undefined}
                            onChange={(item?: any) => {
                                data.sales = item[0] ? item[0].metadata : undefined;
                                setData({ ...data });

                                return null;
                            }}
                        />
                    </Stack.Item>
                    <Stack className={'divider'} />
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <DatePicker value={moment(data.startDate).toDate()}
                                label={'Start from'}
                                formatDate={GeneralService.formatDate}
                                onSelectDate={(date) => {
                                    data.startDate = moment(date).toISOString();
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: 45 }} />
                        <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: '50%' } }}>
                            <Stack.Item grow={1}>
                                <DatePicker value={data.endDate ? moment(data.endDate).toDate() : undefined}
                                    label={'Until'}
                                    formatDate={GeneralService.formatDate}
                                    onSelectDate={(date) => {
                                        data.endDate = date ? moment(date).toISOString() : undefined;
                                        setData({ ...data });
                                    }} />
                            </Stack.Item>
                            <ActionButton disabled={data.endDate === undefined}
                                styles={{root: {marginTop: 32}}}
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={() => {
                                    data.endDate = undefined;
                                    setData({ ...data });
                                }} />
                        </Stack>
                    </Stack>
                    <Stack className={'divider'} />
                    <Stack.Item>
                        <TextField
                            label={"Commission Percentage"}
                            type='number'
                            suffix='%'
                            value={data.commissionPercentage}
                            onChange={(evt, value) => {
                                data.commissionPercentage = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 5 });
                                error.commissionPercentage = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.commissionPercentage}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <TextField
                                label={"Start Amount"}
                                type='number'
                                prefix={'Rp'}
                                value={data.startAmount}
                                onChange={(evt, value) => {
                                    data.startAmount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 15 });
                                    error.startAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.startAmount}
                                disabled={submitting} />
                        </Stack.Item>
                        <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: 45 }} />
                        <Stack styles={{ root: { width: '50%' } }} tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <TextField
                                    label={"End Amount"}
                                    type='number'
                                    prefix={'Rp'}
                                    value={data.endAmount}
                                    onChange={(evt, value) => {
                                        data.endAmount = value || "";

                                        const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 15 });
                                        error.endAmount = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }}
                                    errorMessage={error.endAmount}
                                    disabled={submitting || unlimitedEndAmount} />
                            </Stack.Item>
                            <Checkbox checked={unlimitedEndAmount} onChange={(ev, ch) => {
                                data.endAmount = '';
                                setData({ ...data });
                                setUnlimitedEndAmount(ch || false)
                            }} label='Unlimited end amount' />
                        </Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default SalesCommissionForm;
