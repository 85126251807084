import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import ValidationService from '../../../../../services/validation';
import VendorsService from '../../../../../services/data/vendors';
import TrainsService from '../../../../../services/data/trains';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';
import { IVendorResourceShortProps } from '../../../../../props/data/vendors';

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../../typography/label';
import { IGPSModuleResourceShortProps } from '../../../../../props/gps/modules';
import TrucksService from '../../../../../services/data/trucks';
import GPSModuleService from '../../../../../services/gps/module';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';
import { ITruckResourceShort } from '../../../../../props/data/trucks';
import VendorField from '../../../../uiframeworks/forms/vendor';
import SelectFleetType from '../../../../uiframeworks/forms/selectFleetType';
import { ISystemFleetTypeResourceShortProps } from '../../../../../props/systems/fleetTypes';

interface ITruckFormProps {
    vendorId?: string;
    truckId?: string;
    onDismissed(refresh?: boolean, truck?: ITruckResourceShort): void;
}

type FormDataProps = {
    name: string;
    registrationNumber: string;
    fleetType?: ISystemFleetTypeResourceShortProps;
    gpsModule?: IGPSModuleResourceShortProps;
    gpsDeviceId: string;
    vendor?: IVendorResourceShortProps;
}

type FormDataErrorProps = {
    name?: string;
    registrationNumber?: string;
    vendor?: string;
    fleetType?: string;
    gpsDeviceId?: string;
}

const TruckForm: React.FC<ITruckFormProps> = (props: ITruckFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [gpsModules, setGpsModules] = React.useState<IGPSModuleResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        registrationNumber: "",
        gpsDeviceId: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.truckId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create' && PermissionsService.hasPermission(['trucks.create'], user.permissions)) {
        hasPermission = true;
    } else if (mode === 'update' && PermissionsService.hasPermission(['trucks.update.all'], user.permissions)) {
        hasPermission = true;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _data = data;

        const gpsModules = await GPSModuleService.retrieve();
        setGpsModules(gpsModules);

        if (props.truckId) {
            const _truck = await TrucksService.get(props.truckId);
            _data = {
                name: _truck.name,
                registrationNumber: _truck.registrationNumber,
                gpsModule: _truck.gpsModule,
                gpsDeviceId: _truck.gpsDeviceId || "",
                vendor: _truck.vendor,
                fleetType: _truck.fleetType
            };
        }

        if (props.vendorId) {
            const vendor = await VendorsService.get(props.vendorId);
            _data.vendor = vendor;
        }

        setData({ ..._data });
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.registrationNumber || error.gpsDeviceId || error.fleetType) {
            return true;
        } else if (data.name === '' || data.registrationNumber === '' || !data.vendor || (data.gpsDeviceId && (data.gpsDeviceId || "").trim() === "") || !data.fleetType) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, vendor, gpsDeviceId, gpsModule, registrationNumber, fleetType } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("registrationNumber", registrationNumber);
            if (vendor) {
                fd.append("vendorId", vendor.id);
            }
            if (gpsDeviceId) {
                fd.append("gpsDeviceId", gpsDeviceId);
            }
            if (gpsModule) {
                fd.append("gpsModuleId", gpsModule.id);
            }
            if (fleetType) {
                fd.append("fleetTypeId", fleetType.id);
            }

            let result;
            if (props.truckId === undefined) {
                result = await TrucksService.new(fd);
            } else {
                result = await TrucksService.update(props.truckId, fd);
            }

            banner.add({
                key: mode + '_truck_success',
                variant: 'success',
                icon: faCheck,
                text: `Truck "${name}" ${mode}ed successfully`
            });
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Truck' : 'Update Truck'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating truck ..." : "Updating truck ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <VendorField label={'Select vendor'}
                        selected={data.vendor}
                        disabled={submitting || props.vendorId !== undefined}
                        onChange={(item) => {
                            data.vendor = item;
                            setData({ ...data });
                        }} />
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Registration Number"}
                            required={true}
                            value={data.registrationNumber}
                            onChange={(evt, value) => {
                                data.registrationNumber = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.registrationNumber = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.registrationNumber}
                            disabled={submitting} />
                    </Stack.Item>
                    <SelectFleetType selected={data.fleetType}
                        disabled={submitting}
                        errorMessage={error.fleetType}
                        qs={[`type=Truck`]}
                        required
                        onChange={(item) => {
                            data.fleetType = item;

                            const validation = ValidationService.combination(item, ['required'], {});
                            error.fleetType = validation.message;

                            setData({ ...data });
                        }} />
                    <Stack className={'divider'}></Stack>
                    <Stack.Item>
                        <Dropdown label={"GPS Module"}
                            options={gpsModules.map((module) => {
                                return { key: module.id, text: module.name }
                            })}
                            selectedKey={data.gpsModule?.id}
                            onChange={(evt, opt) => {
                                data.gpsModule = gpsModules.find((module) => module.id === (opt?.key as string));

                                if (!data.gpsModule) {
                                    data.gpsDeviceId = "";
                                }

                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    {data.gpsModule ? <Stack.Item>
                        <TextField label={"GPS Device Id"}
                            required={true}
                            value={data.gpsDeviceId}
                            onChange={(evt, value) => {
                                data.gpsDeviceId = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.gpsDeviceId = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.gpsDeviceId}
                            disabled={submitting} />
                    </Stack.Item> : null}
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default TruckForm;
