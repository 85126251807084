import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';
import { SpinnerSize, Stack } from '@fluentui/react';
import { IOutcomeResourceShorterProps, IOutcomeResourceShortProps } from '../../../../../props/finance/outcomes';
import { faFileInvoice, faMoneyCheckDollar, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import OutcomeService from '../../../../../services/finance/outcomes';
import ErrorService from '../../../../../services/general/error';
import LoadingComponent from '../../../../feedbacks/loading';
import TotalOutcomeInvoiceAmountCard from './cards/totalAmount';
import TotalOutcomeInvoicePaidAmountCard from './cards/paidAmount';
import TotalOutcomeInvoiceUnpaidAmountCard from './cards/unpaidAmount';
import OutcomeInvoiceSummaryChart from './charts/summary';
import TotalOutcomeInvoicePendingAmountCard from './cards/pendingAmount';
import OutcomeInvoiceChart from './charts';
import ButtonCards from '../../../../buttons/card';

// assets

// services

// props

// components

type OutcomeInvoicesSummaryProps = {
    startDate: string;
    endDate: string;
}

export type TFilterBy = 'bank_account' | 'subsidiary' | 'vendor' | 'purpose';

const OutcomeInvoicesSummary: React.FC<OutcomeInvoicesSummaryProps> = (props: OutcomeInvoicesSummaryProps) => {
    const { banner, route } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [invoices, setInvoices] = React.useState<IOutcomeResourceShortProps[]>();
    const [filteredInvoices, setFilteredInvoices] = React.useState<IOutcomeResourceShortProps[]>();

    useEffect(() => {
        _onRetrieveInvoices();
    }, [props.startDate, props.endDate]);

    const _onRetrieveInvoices = async () => {
        try {
            setLoaded(false);
            setInvoices(undefined);

            const qs: string[] = [];
            qs.push(`start_date=${props.startDate}`);
            qs.push(`end_date=${props.endDate}`);
            qs.push(`top=all`);

            const _invoices = await OutcomeService.retrieve(qs.join('&'));

            setInvoices(_invoices.data);
            setFilteredInvoices(_invoices.data);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_invoices_error',
                text: 'Failed to retrieve invoices. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack tokens={{ childrenGap: 20 }} grow={1}>
            <Stack className={styles.cards} horizontal tokens={{ childrenGap: 20 }} styles={{ root: { width: '100%' } }}>
                <Stack styles={{ root: { width: '20%' } }}>
                    <TotalOutcomeInvoiceAmountCard invoices={invoices} />
                </Stack>
                <Stack styles={{ root: { width: '20%' } }}>
                    <TotalOutcomeInvoicePaidAmountCard invoices={invoices} />
                </Stack>
                <Stack styles={{ root: { width: '20%' } }}>
                    <TotalOutcomeInvoiceUnpaidAmountCard invoices={invoices} />
                </Stack>
                <Stack styles={{ root: { width: '20%' } }}>
                    <TotalOutcomeInvoicePendingAmountCard invoices={invoices} />
                </Stack>
                <Stack styles={{ root: { width: '20%' } }}>
                    <ButtonCards gap={15} buttons={[
                        { key: 'invoices', text: 'Invoices', variant: 'light', shadow: true, size: 'small', icon: faFileInvoice, onClick: () => route.route?.history.push("/finance/outcomes/invoices") },
                        { key: 'payments', text: 'Payments', variant: 'light', shadow: true, size: 'small', icon: faMoneyCheckDollar, onClick: () => route.route?.history.push("/finance/outcomes/payments") }
                    ]} />
                </Stack>
            </Stack>
            <Stack className={'divider'}></Stack>
            {!loaded ? <LoadingComponent size={SpinnerSize.medium} label={'Retrieving chart ...'} spinnerPosition={'baseline'} labelPosition={'right'} /> : null}
            {loaded && invoices ? <>
                <OutcomeInvoiceChart invoices={invoices} groupBy={'createdAt'} />
            </> : null}
        </Stack>
    </Stack>
};

export default OutcomeInvoicesSummary;
