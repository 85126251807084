import React from 'react';
import { useStore } from '../../../../../../stores/root';
import { EmployeeContractService } from '../../../../../../services/users/employee';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { IEmployedContractResourceShort } from '../../../../../../props/users/user';
import moment from 'moment';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';

interface ActivateContractFormProps {
    contract: IEmployedContractResourceShort;
    onDismissed(refresh?: boolean): void;
}

const ActivateContractForm: React.FC<ActivateContractFormProps> = (props: ActivateContractFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await EmployeeContractService.activate(props.contract.employeeId, props.contract.id, new FormData());

            banner.add({
                key: 'activae_contract_success',
                variant: 'success',
                icon: faCheck,
                text: `Contract number ${props.contract.contractNumber} is now active`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Activate Contract"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Activate"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Setting contract as active ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to activate contract with details below?</Text>
            <Stack.Item>
                <Label>Contract Number</Label>
                <Text>{props.contract.contractNumber}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Contract Date</Label>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Text>{moment(props.contract.startDate).format('DD/MM/YYYY')}</Text>
                    <Text>-</Text>
                    <Text>{moment(props.contract.endDate).format('DD/MM/YYYY')}</Text>
                </Stack>
            </Stack.Item>
            <Stack className='divider'></Stack>
            <Stack.Item>
                <Label>Inactivation Reason</Label>
                <Text>{props.contract.inactivateReason}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default ActivateContractForm;
