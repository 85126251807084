import React from 'react';
import { useStore } from '../../../../../../stores/root';

import { faCheck } from '@fortawesome/pro-light-svg-icons';

import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { EmployeeBankAccountService } from '../../../../../../services/users/bankAccount';
import { IEmployedBankAccountResourceShort } from '../../../../../../props/users/user';

interface ISetDefaultEmloyeeBankAccountFormProps {
  bankAccount: IEmployedBankAccountResourceShort;
  onDismissed(refresh?: boolean): void;
  employeeId: string
}

const SetDefaultEmloyeeBankAccountForm: React.FC<ISetDefaultEmloyeeBankAccountFormProps> = (props: ISetDefaultEmloyeeBankAccountFormProps) => {
  const { banner } = useStore();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      const fd = new FormData
      fd.append('isDefault', '1')
      fd.append('employeeId', props.employeeId)
      await EmployeeBankAccountService.update(props.bankAccount.employeeId, props.bankAccount.id, fd);

      banner.add({
        key: 'set_default_employee_success',
        variant: 'success',
        icon: faCheck,
        text: `Bank Account "${props.bankAccount.bankName}" set_default successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  return <Panel headerText={"Set Default Bank Account"}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Set Default"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"set default Bank Account ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      <Text>Are you sure you want to set default Bank Account with details below?</Text>
      <Stack.Item>
        <Label>Name</Label>
        <Text>{props.bankAccount.bankName}</Text>
      </Stack.Item>
    </Stack>
  </Panel>
};

export default SetDefaultEmloyeeBankAccountForm;
