import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import BankAccountService from '../../../../../services/general/bankAccount';

// props
import { IBankAccountResourceShortProps } from '../../../../../props/general/bankAccount';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';

interface IBankAccountFormProps {
    bankAccount?: IBankAccountResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    accountNumber: string;
    bankName: string;
    description: string;
}

type FormDataErrorProps = {
    name?: string;
    accountNumber?: string;
    bankName?: string;
    description?: string;
}

const BankAccountForm: React.FC<IBankAccountFormProps> = (props: IBankAccountFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        accountNumber: "",
        bankName: "",
        description: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.bankAccount === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.bankAccount) {
            setData({
                name: props.bankAccount.name,
                accountNumber: props.bankAccount.accountNumber,
                bankName: props.bankAccount.bankName,
                description: props.bankAccount.description || ""
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.accountNumber || error.bankName || error.description) {
            return true;
        } else if (data.name === '' || data.accountNumber === '' || data.bankName === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, bankName, accountNumber, description } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("bankName", bankName);
            fd.append("accountNumber", accountNumber);
            fd.append("description", description);

            if (props.bankAccount === undefined) {
                await BankAccountService.create(fd);
            } else {
                await BankAccountService.update(props.bankAccount.id, fd);
            }
            banner.add({
                key: 'form_bank_account_success',
                variant: 'success',
                icon: faCheck,
                text: `Bank account "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Bank Account' : 'Update Bank Account'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating bank account ..." : "Updating bank account ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.name = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Account Number"}
                            required={true}
                            value={data.accountNumber}
                            onChange={(evt, value) => {
                                data.accountNumber = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.accountNumber = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.accountNumber}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Bank Name"}
                            required={true}
                            value={data.bankName}
                            onChange={(evt, value) => {
                                data.bankName = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.bankName = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.bankName}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Description"}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={false}
                            value={data.description}
                            onChange={(evt, value) => {
                                data.description = value || "";
                                
                                const validation = ValidationService.combination(value, ['limit'], {maxChars: 100});
                                error.description = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default BankAccountForm;
