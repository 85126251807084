
import React, {  } from 'react';

// import props

// import subpages

// import components

// import fabric ui component
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon, IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './style.module.scss';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Label from '../../typography/label';
import Text from '../../typography/text';
import { Stack } from '@fluentui/react';

interface IButtonCardProps {
    horizontal?: boolean;
    gap?: number;
    buttons: IButtonProps[];
}

export interface IButtonProps {
    variant?: 'primary' | 'default' | 'light';
    size?: 'small' | 'medium';
    key?: string;
    icon?: IconProp;
    description?: string;
    text: string;
    onClick?(): void;
    link?: string;
    shadow?: boolean;
} 

const ButtonCards: React.FC<IButtonCardProps> = (props) => {
    return <Stack horizontal={props.horizontal} tokens={{childrenGap: props.gap || 10}}>
        {props.buttons.map((button, idx) => {
            let iconSize: number = 20;
            let descriptionSize: 'small' | 'medium' = button.size || 'medium';
            if (button.size === 'small') {
                iconSize = 16;    
            }

            const body = <Stack key={button.key || idx} className={`${styles.buttonCard} ${styles[button.variant || 'default']} ${button.shadow ? styles.shadow : ''} ${button.size ? styles[button.size] : styles.medium }`} horizontal tokens={{childrenGap: 8}} verticalAlign='center'>
                {button.icon ? <Stack styles={{root: {width: iconSize + 4}}}>
                    <FontAwesomeIcon icon={button.icon} fontSize={iconSize} />
                </Stack> : null}
                <Stack grow={1}>
                    <Label size={button.size}>{button.text}</Label>
                    {button.description ? <Text size={descriptionSize}>{button.description}</Text> : null}
                </Stack>
                <FontAwesomeIcon icon={faArrowRight} />
            </Stack>;

            return <Stack styles={{root: {width: props.horizontal ? (100/props.buttons.length) + '%' : '100%'}}} onClick={button.onClick}>
                {!button.onClick && button.link ? <NavLink to={button.link || "#"}>{body}</NavLink> : body}
            </Stack>
        })}
    </Stack>
}

export default ButtonCards;