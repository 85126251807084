import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import BankAccountsList from '../../../components/settings/bankAccounts/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IBankAccountsListPageProps {
}

const BankAccountsListPage: React.FC<IBankAccountsListPageProps> = (props: IBankAccountsListPageProps) => {
    const { topbar } = useStore();

    useEffect(() => {
        topbar.show("Bank Accounts", [
            { key: "home", icon: faHome, href: "/" },
            { key: "settings", text: 'Settings', href: "/settings" },
            { key: "bankAccounts", text: 'Bank Accounts' }
        ])
    }, []);

    return <Stack>
        <BankAccountsList />
    </Stack>;
};

export default BankAccountsListPage;
