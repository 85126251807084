import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../stores/root';
import ProfileDetails from '../../components/profiles/general/details';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IProfileDetailsPageProps {
}

const ProfileDetailsPage: React.FC<IProfileDetailsPageProps> = (props: IProfileDetailsPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("My Profile", [
            {key: "home", icon: faHome, href: "/"},
            {key: "profile", text: 'Profile'}
        ])
    }, []);

    return <Stack>
        <ProfileDetails />
    </Stack>;
};

export default ProfileDetailsPage;
