import React, { useEffect } from 'react';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Label, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import ValidationService from '../../../../../services/validation';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import TeamService, { TeamMemberService } from '../../../../../services/users/team';
import GeneralService from '../../../../../services/general';
import authService from '../../../../../services/users/user';
import { IUserResourceShortProps } from '../../../../../props/user';
import ISalesTeamResourceShort from '../../../../../props/users/team';

interface ITeamMemberFormProps {
    teamMemberId?: string;
    onDismissed(refresh?: boolean): void;
    team?: ISalesTeamResourceShort
}

type FormDataProps = {
    fee_percentage?: string;
    fee_amount?: string;
    sales?: IUserResourceShortProps;
    team?: ISalesTeamResourceShort

}

type FormDataErrorProps = {
    fee_percentage?: string;
    fee_amount?: string;
}

const TeamMemberForm: React.FC<ITeamMemberFormProps> = (props: ITeamMemberFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        fee_percentage: "",
        fee_amount: '',
        team: props.team,
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.teamMemberId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.teamMemberId) {
            const _teamMember = await TeamMemberService.get(props.teamMemberId);
            setData({
                fee_percentage: _teamMember.feePercentage,
                fee_amount: _teamMember.feeAmount,
                sales: _teamMember.sales,
                team: _teamMember.team,
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (data.sales === undefined || data.team === undefined) {
            return true;
        }
        return false;
    }

    const _onSubmit = async () => {
        setSubmitting(true);
        const { fee_percentage, fee_amount, team, sales } = data;
        if (team && sales) {
            try {

                // create form data
                let fd = new FormData();
                fd.append("fee_percentage", fee_percentage || '');
                fd.append("fee_amount", fee_amount || '');
                fd.append("team_id", team?.id);

                fd.append("sales_id", sales?.id);

                if (props.teamMemberId === undefined) {
                    await TeamMemberService.new(fd);
                } else {
                    await TeamMemberService.update(props.teamMemberId, fd);
                }
                banner.add({
                    key: 'create_administration_success',
                    variant: 'success',
                    icon: faCheck,
                    text: `TeamMember "" created successfully`
                });
                props.onDismissed(true);
            } catch (e) {
                setSubmitting(false);
            }
        }
    }

    const _onSearchSales = async (keyword: string) => {
        try {
            const qs: string[] = [];
            // qs.push(`rolesGroup=backoffice`);
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await authService.retrieve(qs.join("&"));

            return results.data.filter((d, idx) => idx < 10).map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_sales_error',
                text: 'Failed to search related sales. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }

    const _onSearchTeams = async (keyword: string) => {
        try {
            const qs: string[] = [];
            // qs.push(`rolesGroup=backoffice`);
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await TeamService.retrieve(qs.join("&"));

            return results.filter((d, idx) => idx < 10).map((team) => {
                return {
                    id: team.id,
                    text: team.name,
                    secondaryText: team.description,
                    imageInitials: GeneralService.getInitial(team.name),
                    metadata: team,
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_sales_error',
                text: 'Failed to search related sales. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }

    return <Panel headerText={mode === 'create' ? "Create TeamMember" : "Update TeamMember"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating administrator ..." : "Updating administrator ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Stack.Item>
                            <Label required>Sales</Label>
                            <NormalPeoplePicker
                                onResolveSuggestions={_onSearchSales}
                                onEmptyResolveSuggestions={() => _onSearchSales('')}
                                itemLimit={1}
                                selectedItems={data.sales ? [
                                    {
                                        id: data.sales.id,
                                        text: data.sales.name,
                                        secondaryText: data.sales.email,
                                        imageInitials: GeneralService.getInitial(data.sales.name),
                                        metadata: data.sales,
                                    } as any,
                                ] : []}
                                disabled={submitting}
                                onChange={(item?: any) => {
                                    data.sales = item[0] ? item[0].metadata : undefined;
                                    setData({ ...data });

                                    return null;
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                    {props.team === undefined && <Stack tokens={{ childrenGap: 20 }}>
                        <Stack.Item>
                            <Label required>Team</Label>
                            <NormalPeoplePicker
                                onResolveSuggestions={_onSearchTeams}
                                onEmptyResolveSuggestions={() => _onSearchTeams('')}
                                itemLimit={1}
                                selectedItems={data.team ? [
                                    {
                                        id: data.team.id,
                                        text: data.team.name,
                                        secondaryText: data.team.description,
                                        imageInitials: GeneralService.getInitial(data.team.name),
                                        metadata: data.team,
                                    } as any,
                                ] : []}
                                disabled={submitting}
                                onChange={(item?: any) => {
                                    data.team = item[0] ? item[0].metadata : undefined;
                                    setData({ ...data });

                                    return null;
                                }}
                            />
                        </Stack.Item>
                    </Stack>}
                    <Stack.Item>
                        <TextField
                            label={"Fee Percentage"}
                            type='number'
                            value={data.fee_percentage}
                            onChange={(evt, value) => {
                                data.fee_percentage = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.fee_percentage = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.fee_percentage}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Fee Amount"}
                            type='number'
                            value={data.fee_amount}
                            onChange={(evt, value) => {
                                data.fee_amount = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.fee_amount = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.fee_amount}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default TeamMemberForm;
