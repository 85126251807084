import React from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GoodsService from '../../../../services/goods/goods';

// props
import { IGoodsResourceShortProps } from '../../../../props/goods/goods';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import GeneralService from '../../../../services/general';

interface IDeleteGoodsFormProps {
    goods: IGoodsResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const DeleteGoodsForm: React.FC<IDeleteGoodsFormProps> = (props: IDeleteGoodsFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await GoodsService.delete(props.goods.id);

            banner.add({
                key: 'delete_goods_success',
                variant: 'success',
                icon: faCheck,
                text: `Goods "${props.goods.name}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Goods"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting goods ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete goods with details below?</Text>
            <Stack.Item>
                <Label>Name</Label>
                <Text>{props.goods.name}</Text>
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <Label>Owned By (Company)</Label>
                    <Text>{props.goods.company?.name || "-"}</Text>
                </Stack.Item>
                <Stack.Item >
                    <Label>Customer (PIC)</Label>
                    <Text>{props.goods.user.name}</Text>
                </Stack.Item>
            </Stack>
            <Stack className='divider'></Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <Label>Quantity</Label>
                    <Text>{GeneralService.getNumberWithSeparator(props.goods.quantityAmount || "0")} {props.goods.quantityUnit || ""}</Text>
                </Stack.Item>
                {props.goods.weightAmount ? <Stack.Item >
                    <Label>Weight (per quantity)</Label>
                    <Text>{GeneralService.getNumberWithSeparator(props.goods.weightAmount || "0")} {props.goods.weightUnit || ""}</Text>
                </Stack.Item> : null}
            </Stack>
            <Stack className='divider'></Stack>
            {props.goods.warehouse || props.goods.locationDetails ? <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <Label>Warehouse</Label>
                    <Text>{props.goods.warehouse?.name || "-"}</Text>
                </Stack.Item>
                <Stack.Item >
                    <Label>Customer (PIC)</Label>
                    <Text>{props.goods.locationDetails || "-"}</Text>
                </Stack.Item>
            </Stack> : null}
        </Stack>
    </Panel>
};

export default DeleteGoodsForm;
