import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { ActionButton, DatePicker, Dropdown, IDropdownOption, Link, Stack, TagPicker } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { IShipResourceShortProps } from '../../../../props/data/ships';
import ShipsService from '../../../../services/data/ships';
import { IVendorResourceShortProps } from '../../../../props/data/vendors';
import ShipForm from '../../../operationals/ships/general/form';
import PermissionsService from '../../../../services/permissions';
import { useStore } from '../../../../stores/root';
import moment from 'moment';
import GeneralService from '../../../../services/general';

type DateTimePickerProps = {
    value?: string;
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    enableTime?: boolean;
    allowClearValue?: boolean;
    onChange(value?: string): void;
}

const DateTimePicker: React.FC<DateTimePickerProps> = (props: DateTimePickerProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [value, setValue] = React.useState<string>();

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        if (props.value) {
            const closestMinute = findClosestMinute(Number(moment(props.value).format("mm")));
            const dateHour = moment(props.value).format("DD/MM/YYYY HH");
            const newValue = moment(dateHour + ":" + closestMinute, 'DD/MM/YYYY HH:mm').toISOString();
            props.onChange(newValue);
        }
    }, []);

    const findClosestMinute = (minute: number) => {
        let closest = '00';

        if (minute < 15) {
            closest = '00';
        } else if (minute < 30) {
            closest = '15';
        } else if (minute < 45) {
            closest = '30';
        } else {
            closest = '45';
        }

        return closest;
    }

    const hourOptions: IDropdownOption[] = [
        { key: "00", text: "00" },
        { key: "01", text: "01" },
        { key: "02", text: "02" },
        { key: "03", text: "03" },
        { key: "04", text: "04" },
        { key: "05", text: "05" },
        { key: "06", text: "06" },
        { key: "07", text: "07" },
        { key: "08", text: "08" },
        { key: "09", text: "09" },
        { key: "10", text: "10" },
        { key: "11", text: "11" },
        { key: "12", text: "12" },
        { key: "13", text: "13" },
        { key: "14", text: "14" },
        { key: "15", text: "15" },
        { key: "16", text: "16" },
        { key: "17", text: "17" },
        { key: "19", text: "19" },
        { key: "20", text: "20" },
        { key: "21", text: "21" },
        { key: "22", text: "22" },
        { key: "23", text: "23" }
    ];

    const minuteOptions: IDropdownOption[] = [
        { key: "00", text: "00" },
        { key: "15", text: "15" },
        { key: "30", text: "30" },
        { key: "45", text: "45" },
    ];

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Date and time"}</Label>}
        <Stack horizontal={props.enableTime} verticalAlign='center' tokens={{ childrenGap: 10 }}>
            <Stack grow={1}>
                <DatePicker value={value ? moment(value).toDate() : undefined}
                    disabled={props.disabled}
                    formatDate={GeneralService.formatDate}
                    onSelectDate={(selected?: Date | null | undefined) => {
                        if (props.enableTime && selected) {
                            const time = moment(value).format("HH:mm");
                            const date = moment(selected).format("DD/MM/YYYY");
                            selected = moment(date + " " + time, "DD/MM/YYYY HH:mm").toDate();
                        }

                        props.onChange(moment(selected).toISOString());
                    }} />
            </Stack>
            {props.enableTime ? <>
                <Stack styles={{root: {width: 1, background: '#aaa', height: 25}}}></Stack>
                <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 10 }}>
                    <Dropdown options={hourOptions}
                        disabled={props.disabled}
                        placeholder={'HH'}
                        selectedKey={value ? moment(value).format("HH") : undefined}
                        styles={{ root: { width: 60 } }}
                        onChange={(evt, opt) => {
                            if (props.enableTime && opt) {
                                const hour = opt.key as string;
                                const minute = moment(value).format("mm");
                                const date = moment(value).format("DD/MM/YYYY");
                                
                                const selected = moment(`${date} ${hour}:${minute}`, "DD/MM/YYYY HH:mm").toDate();
                                props.onChange(moment(selected).toISOString());
                            }
                        }} />
                    <Text>:</Text>
                    <Dropdown options={minuteOptions}
                        placeholder={'mm'}
                        disabled={props.disabled}
                        selectedKey={value ? moment(value).format("mm") : undefined}
                        styles={{ root: { width: 60 } }}
                        onChange={(evt, opt) => {
                            if (props.enableTime && opt) {
                                const hour = moment(value).format("HH");
                                const minute = opt.key as string;
                                const date = moment(value).format("DD/MM/YYYY");
                                
                                const selected = moment(`${date} ${hour}:${minute}`, "DD/MM/YYYY HH:mm").toDate();
                                props.onChange(moment(selected).toISOString());
                            }
                        }} />
                </Stack>
            </> : null}
            {props.allowClearValue ? <>
                <Stack styles={{root: {width: 1, background: '#aaa', height: 25}}}></Stack>
                <ActionButton iconProps={{iconName: 'Xmark'}} 
                    text={'Clear'} 
                    styles={{root: {paddingLeft: '0px !important'}}}
                    onChange={() => {
                        props.onChange();
                    }} />
            </> : null}
        </Stack>
    </Stack>;
};

export default DateTimePicker;
