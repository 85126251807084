import React, { useEffect } from 'react';
import styles from './style.module.scss';

// import router
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

// import components
import Sidebar from './../components/navigations/sidebar';

// import services
import UserService from './../services/users/user';

// import pages
import DashboardPage from './dashboard';

// import fabric ui component
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IUserResourceShortProps } from '../props/users/user';
import Topbar from '../components/navigations/topbar';
import AdministratorsListPage from './users/administrators/list';
import OrdersListPage from './orders/list';
import CompaniesListPage from './users/companies/list';
import CustomersListPage from './users/customers';
import SettingsPage from './settings';
import FreightCategoriesListPage from './settings/freightCategories/list';
import DeliveryTypesListPage from './settings/deliveryTypes/list';
import OrderStatusListPage from './settings/orderStatus/list';
import QuantityUnitsListPage from './settings/quantityUnits/list';
import WarehousesListPage from './settings/warehouses/list';
import BankAccountsListPage from './settings/bankAccounts/list';
import CustomerDetailsPage from './users/customers/details';
import AdministratorDetailsPage from './users/administrators/details';
import VendorsListPage from './operationals/vendors/list';
import VendorDetailsPage from './operationals/vendors/details';
import ShipsListPage from './operationals/ships/list';
import ShipDetailsPage from './operationals/ships/details';
import ShipScheduleDetailsPage from './operationals/ships/schedules/details';
import TrainsListPage from './operationals/trains/list';
import TrainDetailsPage from './operationals/trains/details';
import TrainScheduleDetailsPage from './operationals/trains/schedules/details';
import TrucksListPage from './operationals/trucks/list';
import TruckDetailsPage from './operationals/trucks/details';
import { useStore } from '../stores/root';
import { observer } from 'mobx-react';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../services/general/error';
import OrderDetailsPage from './orders/details';
import GoodsListPage from './goods/list';
import ProfileDetailsPage from './profiles/details';
import FinanceDashboardPage from './finances/dashboard';
import InvoicesListPage from './finances/invoices/list';
import ContactsListPage from './settings/contacts/list';
import OfficesListPage from './settings/offices/list';
import ClientsListPage from './settings/clients/list';
import OrderDashboardPage from './orders/dashboard';
import OutcomeDashboardPage from './finances/outcomes';
import OutcomeInvoicesPage from './finances/outcomes/invoices';
import OutcomePaymentsPage from './finances/outcomes/payments';
import InternalDocumentPage from './operationals/documents/list';
import TeamPage from './users/teams';
import SubsidiariesListPage from './settings/subsidiaries/list';
import SubsidiaryDetailsPage from './settings/subsidiaries/details';
import TeamMemberListPage from './users/teamMembers/list';
import TeamDetailsPage from './users/teams/details';
import SalesTeamListPage from './users/teams/sales/list';
import OfferForm from '../components/offers/general/form';
import OfferFormPage from './offers/form';
import KeysListPage from './settings/keys/list';
import PublicNewsPage from './settings/publicNews/list';
import CreateNewsPage from './settings/publicNews/create';
import UpdateNewsPage from './settings/publicNews/update';
import CompanyDetailsPage from './users/companies/details';
import OffersDashboardPage from './offers';
import NotificationPage from './notifications';
import CreateOfferPage from './offers/create';
import ViewOfferPage from './offers/view';
import UpdateOfferPage from './offers/update';
import OffersListPage from './offers/list';
import ViewOfferCostPage from './offers/cost';
import SalesComissionListPage from './users/salesComissions/list';
import AdministratorPerformancePage from './users/administrators/performance';
import EmploymentDashboardPage from './users/employments/dashboard';
import HRDashboardPage from './hr/dashboard';
import HRPayrollPage from './hr/payroll';
import SalarySlipPage from './hr/payroll/slip';
import EmployeePayslipDetailsPage from './users/administrators/payslips/details';
import EmployeePayslipsListPage from './users/administrators/payslips/list';
import BankAccountListpage from './users/administrators/bankAccounts/list';
import BankAccountDetailsPage from './users/administrators/bankAccounts/details';
import RolesListPage from './settings/roles/list';
import EmployeeContractsListPage from './hr/contracts/list';
import OutcomeInvoiceCreatePage from './finances/invoices/create';
import OutcomeCategoriesListPage from './settings/outcomeCategories/list';

interface IPageProps { }
interface IPageState {
  loaded?: boolean;
  currentUser?: IUserResourceShortProps;
}

declare var window: any;

const RootPage: React.FC<RouteComponentProps> = observer((props: RouteComponentProps) => {
  const { route, user, banner } = useStore();
  const isInit: boolean = false;

  const [loaded, setLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    route.setRoute(props);
    init();
  }, []);

  const init = async () => {
    try {
      const _user = await UserService.currentUser();
      const _verified = await UserService.is_verified();

      user.setData(_user);
      user.setVerified(_verified);
      user.setTemporaryPassword(_user.isTempPassword);

      if (!_verified) {
        window.location.href = '/auth/verifyEmail';
      } else if (_user.isTempPassword) {
        window.location.href = '/auth/resetPassword';
      } else {
        setLoaded(true);
      }
    } catch (e) {
      banner.add({
        key: 'login_error',
        variant: 'error',
        icon: faXmarkCircle,
        text: `Failed to login using current session. Error: ${ErrorService.getMessage(e)}`
      });

      window.location.href = '/auth/login?_o=' + window.location.pathname;
    }
  }

  return (
    <>
      {loaded && user ? (
        <Stack className={styles.container} horizontal>
          <Stack className={styles.left}>
            <Sidebar />
          </Stack>
          <Stack className={styles.right}>
            <Stack className={styles.header}>
              <Topbar />
            </Stack>
            <Stack className={styles.body}>
              <Switch>
                {/* profile page */}
                <Route exact path='/profile'><ProfileDetailsPage /></Route>

                {/* dashboard page */}
                <Route exact path='/'><DashboardPage /></Route>
                <Route exact path='/home'><DashboardPage /></Route>
                <Route exact path='/dashboard'><DashboardPage /></Route>
                <Route exact path='/notifications'><NotificationPage /></Route>

                {/* settings page */}
                <Route exact path='/settings'><SettingsPage /></Route>
                <Route exact path='/settings/freightCategories'><FreightCategoriesListPage /></Route>
                <Route exact path='/settings/deliveryTypes'><DeliveryTypesListPage /></Route>
                <Route exact path='/settings/orderStatus'><OrderStatusListPage /></Route>
                <Route exact path='/settings/quantityUnits'><QuantityUnitsListPage /></Route>
                <Route exact path='/settings/warehouses'><WarehousesListPage /></Route>
                <Route exact path='/settings/bankAccounts'><BankAccountsListPage /></Route>
                <Route exact path='/settings/contacts'><ContactsListPage /></Route>
                <Route exact path='/settings/offices'><OfficesListPage /></Route>
                <Route exact path='/settings/clients'><ClientsListPage /></Route>
                <Route exact path='/settings/subsidiaries'><SubsidiariesListPage /></Route>
                <Route exact path='/settings/subsidiaries/:subsidiaryId'><SubsidiaryDetailsPage /></Route>
                <Route exact path='/settings/keys'><KeysListPage /></Route>
                <Route exact path='/settings/news'><PublicNewsPage /></Route>
                <Route exact path='/settings/news/create'><CreateNewsPage /></Route>
                <Route exact path='/settings/news/update/:newsId'><UpdateNewsPage /></Route>
                <Route exact path='/settings/roles'><RolesListPage /></Route>
                <Route exact path='/settings/outcomes/categories'><OutcomeCategoriesListPage /></Route>

                {/* users page */}
                <Route exact path='/users/customers'><CustomersListPage /></Route>
                <Route exact path='/users/customers/:customerId'><CustomerDetailsPage /></Route>
                <Route exact path='/users/companies'><CompaniesListPage /></Route>
                <Route exact path='/users/companies/:companyId'><CompanyDetailsPage /></Route>
                <Route exact path='/users/administrators'><AdministratorsListPage /></Route>
                <Route exact path='/users/administrators/:administratorId'><AdministratorDetailsPage /></Route>
                <Route exact path='/users/administrators/:administratorId/performance'><AdministratorPerformancePage /></Route>
                <Route exact path='/users/administrators/:administratorId/payslips'><EmployeePayslipsListPage /></Route>
                <Route exact path='/users/administrators/:administratorId/payslips/:payslipId'><EmployeePayslipDetailsPage /></Route>
                <Route exact path='/users/teams'><TeamPage /></Route>
                <Route exact path='/users/teams/sales'><SalesTeamListPage /></Route>
                <Route exact path='/sales_comissions'><SalesComissionListPage /></Route>

                <Route exact path='/users/team_member_list'><TeamMemberListPage /></Route>
                <Route exact path='/users/team_members/:teamId'><TeamDetailsPage /></Route>

                {/* employments page */}
                <Route exact path='/users/employments/:employeeId/bank_accounts'><BankAccountListpage /></Route>
                <Route exact path='/users/employments/:employeeId/bank_accounts/:bankAccountId'><BankAccountDetailsPage /></Route>
                <Route exact path='/users/employments/:employeeId/dashboard'><EmploymentDashboardPage /></Route>

                {/* orders page */}
                <Route exact path='/orders/dashboard'><OrderDashboardPage /></Route>
                <Route exact path='/orders'><OrdersListPage /></Route>
                <Route exact path='/orders/list'><OrdersListPage /></Route>
                <Route exact path='/orders/:orderId'><OrderDetailsPage /></Route>

                {/* goods page */}
                <Route exact path='/goods'><GoodsListPage /></Route>

                {/* operationals page */}
                <Route exact path='/operationals/vendors'><VendorsListPage /></Route>
                <Route exact path='/operationals/vendors/:vendorId'><VendorDetailsPage /></Route>
                <Route exact path='/operationals/ships'><ShipsListPage /></Route>
                <Route exact path='/operationals/ships/:shipId'><ShipDetailsPage /></Route>
                <Route exact path='/operationals/ships/:shipId/schedules/:scheduleId'><ShipScheduleDetailsPage /></Route>
                <Route exact path='/operationals/trains'><TrainsListPage /></Route>
                <Route exact path='/operationals/trains/:trainId'><TrainDetailsPage /></Route>
                <Route exact path='/operationals/trains/:trainId/schedules/:scheduleId'><TrainScheduleDetailsPage /></Route>
                <Route exact path='/operationals/trucks'><TrucksListPage /></Route>
                <Route exact path='/operationals/trucks/:truckId'><TruckDetailsPage /></Route>
                <Route exact path='/operationals/documents'><InternalDocumentPage /></Route>
                <Route exact path='/operationals/documents/*'><InternalDocumentPage /></Route>

                {/* finance page */}
                <Route exact path='/finance'><FinanceDashboardPage /></Route>
                <Route exact path='/finance/outcomes'><OutcomeDashboardPage /></Route>
                <Route exact path='/finance/outcomes/invoices'><OutcomeInvoicesPage /></Route>
                <Route exact path='/finance/outcomes/invoices/create'><OutcomeInvoiceCreatePage /></Route>
                <Route exact path='/finance/outcomes/invoices/:invoiceId'><OutcomeInvoicesPage /></Route>
                <Route exact path='/finance/outcomes/payments'><OutcomePaymentsPage /></Route>
                <Route exact path='/finance/outcomes/create'><OutcomeDashboardPage /></Route>
                <Route exact path='/finance/invoices'><InvoicesListPage /></Route>

                {/* offers page */}
                <Route exact path='/offers/list'><OffersListPage /></Route>
                <Route exact path='/offers/create'><CreateOfferPage /></Route>
                <Route exact path='/offers/:offerId'><ViewOfferPage /></Route>
                <Route exact path='/offers/:offerId/view'><ViewOfferPage /></Route>
                <Route exact path='/offers/:offerId/update'><UpdateOfferPage /></Route>

                {/* hr page */}
                <Route exact path='/hr'><HRDashboardPage /></Route>
                <Route exact path='/hr/payroll'><HRPayrollPage /></Route>
                <Route exact path='/hr/payroll/salaryslip'><SalarySlipPage /></Route>

                {/* employees page */}
                <Route exact path='/employees/contracts'><EmployeeContractsListPage /></Route>

              </Switch>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
    </>
  );
});

export default RootPage;
