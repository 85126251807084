import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faArrowRight, faCheck, faTriangleExclamation, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../services/validation';
import OrderContainerService from '../../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../../services/general';

// props

// components
import { ActionButton, Checkbox, DatePicker, DefaultButton, DetailsList, Dropdown, Panel, PanelType, PrimaryButton, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import { IBankAccountResourceShortProps, IVendorBankAccountResourceShortProps } from '../../../../../../props/general/bankAccount';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import OrderService from '../../../../../../services/orders/order';
import { IOrderResourceShortProps } from '../../../../../../props/orders/order';
import moment from 'moment';
import BankAccountService, { VendorBankAccountService } from '../../../../../../services/general/bankAccount';
import { IVendorResourceShortProps } from '../../../../../../props/data/vendors';
import { IShipResourceShortProps, IShipScheduleResourceShort } from '../../../../../../props/data/ships';
import { ITrainResourceShort, ITrainScheduleResourceShort } from '../../../../../../props/data/trains';
import { ITruckResourceShort } from '../../../../../../props/data/trucks';
import OutcomeTypes from '../../../../../../manifests/outcomeTypes';
import VendorsService from '../../../../../../services/data/vendors';
import ErrorService from '../../../../../../services/general/error';
import ShipsService from '../../../../../../services/data/ships';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShipSchedulesService from '../../../../../../services/data/ships/shipSchedules';
import TrainsService from '../../../../../../services/data/trains';
import TrainScheduleService from '../../../../../../services/data/trains/trainScedule';
import TrucksService from '../../../../../../services/data/trucks';
import OutcomeService from '../../../../../../services/finance/outcomes';
import LoadingComponent from '../../../../../feedbacks/loading';
import { PPNPercentageOptions, PPhPercentageOptions } from '../../../../../../manifests/taxPercentage';
import ShipField from '../../../../../uiframeworks/forms/ship';
import ShipScheduleField from '../../../../../uiframeworks/forms/shipSchedule';
import VendorField from '../../../../../uiframeworks/forms/vendor';
import UploadOutcomeInvoiceForm from './upload';

interface IOutcomeInvoiceFormProps {
    outcomeId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    type?: string;
    bankAccount?: string;
    status: string;
    vendor?: IVendorResourceShortProps;
    dueDate: string;
    name: string;
    invoiceNumber: string;
    amount: number;
    originalAmount: string;
    totalAmount: number;
    tax: string;
    notes: string;
    invoiceDate?: string;
    accountingDate: string;
    ship?: IShipResourceShortProps;
    shipSchedule?: IShipScheduleResourceShort;
    train?: ITrainResourceShort;
    trainSchedule?: ITrainScheduleResourceShort;
    truck?: ITruckResourceShort;
    orders: FormDataOrderProps[];
    ppn: string;
    pph: string;
    includePPn?: boolean;
    includePPh?: boolean;
    ppnPercentage: string;
    pphPercentage: string;
}

type FormDataErrorProps = {
    name?: string;
    invoiceNumber?: string;
    amount?: string;
    tax?: string;
    notes?: string;
    ppn?: string;
    pph?: string;
}

type FormDataOrderProps = {
    id: string;
    order?: IOrderResourceShortProps;
    amount: string;
    notes: string;
}

const OutcomeInvoiceForm: React.FC<IOutcomeInvoiceFormProps> = (props: IOutcomeInvoiceFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [bankAccounts, setBankAccounts] = React.useState<IBankAccountResourceShortProps[]>([]);
    const [showUploadForm, setShowUploadForm] = React.useState<boolean>(false);
    const [order, setOrder] = React.useState<IOrderResourceShortProps>();
    const [targetBankAccounts, setTargetBankAccounts] = React.useState<IVendorBankAccountResourceShortProps[] | undefined>();
    const [data, setData] = React.useState<FormDataProps>({
        status: "",
        dueDate: moment().add(1, 'day').toISOString(),
        name: "",
        invoiceNumber: "",
        amount: 0,
        tax: "",
        notes: "",
        ppn: "0",
        pph: "0",
        totalAmount: 0,
        originalAmount: "",
        accountingDate: moment().toISOString(),
        orders: [{ id: GeneralService.guid(), amount: "", notes: "" }],
        ppnPercentage: "0",
        pphPercentage: "0",
    });
    const [updatingRelatedOrders, setUpdatingRelatedOrders] = React.useState<boolean>(false);
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.outcomeId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _bankAccounts = await BankAccountService.retrieve();
        setBankAccounts(_bankAccounts);

        if (props.outcomeId) {
            const _outcome = await OutcomeService.get(props.outcomeId);

            setData({
                type: _outcome.type,
                bankAccount: _outcome.bankAccount?.id,
                status: _outcome.status,
                vendor: _outcome.vendor,
                dueDate: _outcome.dueDate,
                name: _outcome.name,
                invoiceNumber: _outcome.invoiceNumber || "",
                amount: Number(_outcome.amount),
                originalAmount: Number(_outcome.originalAmount) + "",
                totalAmount: Number(_outcome.totalAmount),
                includePPh: _outcome.includePPh || false,
                includePPn: _outcome.includePPn || false,
                ppnPercentage: _outcome.ppnPercentage,
                pphPercentage: _outcome.pphPercentage,
                tax: "",
                notes: "",
                invoiceDate: _outcome.invoiceDate,
                accountingDate: _outcome.accountingDate,
                ship: _outcome.ship,
                shipSchedule: _outcome.shipSchedule,
                train: _outcome.train,
                trainSchedule: _outcome.trainSchedule,
                truck: _outcome.truck,
                ppn: Number(_outcome.ppn || "0") + "",
                pph: Number(_outcome.pph || "0") + "",
                orders: [
                    ..._outcome.orders.map((od) => {
                        return {
                            id: od.id,
                            amount: od.amount + "",
                            notes: od.notes || "",
                            order: od.order as IOrderResourceShortProps
                        }
                    }),
                    {
                        id: GeneralService.guid(),
                        amount: "",
                        notes: ""
                    }
                ]
            })
        }

        setLoaded(true);
    }

    const checkRelatedOrdersAmountMatched = () => {
        const totalOrders = data.orders.filter((order) => order.order).length;
        let totalRelatedOrdersAmount = 0;
        data.orders.filter((order) => order.order).forEach((order) => {
            totalRelatedOrdersAmount += Number(order.amount);
        });

        return totalRelatedOrdersAmount === data.totalAmount || totalOrders < 1;
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.amount || error.invoiceNumber || error.notes || error.name || error.tax || error.ppn || error.pph) {
            return true;
        } else if (data.name.trim() === "" || data.originalAmount.trim() === "" || data.ppn.trim() === "" || data.pph.trim() === "" || !data.bankAccount || !data.type) {
            return true;
        } else if (data.type === "shipping" && (!data.vendor || !data.ship || !data.shipSchedule)) {
            return true;
        } else if (data.type === "train" && (!data.vendor || !data.train || !data.trainSchedule)) {
            return true;
        } else if (data.type === "truck" && (!data.vendor || !data.truck)) {
            return true;
        } else if (!data.vendor) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            const { type, orders, bankAccount, status, includePPh, includePPn, ppnPercentage, pphPercentage, originalAmount, totalAmount, ppn, pph, vendor, dueDate, name, invoiceNumber, amount, tax, notes, invoiceDate, accountingDate, ship, shipSchedule, train, trainSchedule, truck } = data;
            setSubmitting(true);

            const fd = new FormData();
            fd.append("type", type || "others");
            fd.append("bankAccountId", bankAccount || "");
            fd.append("dueDate", dueDate);
            fd.append("name", name);
            fd.append("invoiceNumber", invoiceNumber);
            fd.append("tax", tax);
            fd.append("notes", notes);
            fd.append("accountingDate", accountingDate);

            fd.append("includePPn", includePPn ? "1" : "0");
            fd.append("includePPh", includePPh ? "1" : "0");
            fd.append("ppnPercentage", ppnPercentage);
            fd.append("pphPercentage", pphPercentage);
            fd.append("ppn", ppn);
            fd.append("pph", pph);

            fd.append("amount", amount + "");
            fd.append("originalAmount", originalAmount + "");
            fd.append("totalAmount", totalAmount + "");

            if (invoiceDate) {
                fd.append("invoiceDate", invoiceDate);
            }
            if (vendor) {
                fd.append("vendorId", vendor.id);
            }
            if (ship) {
                fd.append("shipId", ship.id);
            }
            if (shipSchedule) {
                fd.append("shipScheduleId", shipSchedule.id);
            }
            if (train) {
                fd.append("trainId", train.id);
            }
            if (trainSchedule) {
                fd.append("trainScheduleId", trainSchedule.id);
            }
            if (truck) {
                fd.append("truckId", truck.id);
            }

            (orders || []).forEach((item) => {
                if (item.order) {
                    fd.append('orders[]', JSON.stringify({
                        orderId: item.order.id,
                        amount: item.amount,
                        notes: item.notes
                    }));
                }
            });

            if (props.outcomeId) {
                await OutcomeService.update(props.outcomeId, fd);
            } else {
                await OutcomeService.create(fd);
            }

            banner.add({
                key: mode + '_outcome_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Invoice "" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchVendor = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const options = (await VendorsService.retrieve(qs.join("&"))).data.map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_vendor_error",
                text: "Failed to search related vendor(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchShip = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.vendor) { qs.push(`vendor_id=${data.vendor.id}`) }

            const options = (await ShipsService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_ship_error",
                text: "Failed to search related ship(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchShipSchedule = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.ship) { qs.push(`ship_id=${data.ship.id}`) }

            const options = (await ShipSchedulesService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: "Voy " + item.voy,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_ship_schedule_error",
                text: "Failed to search related ship schedule(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchTrain = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.vendor) { qs.push(`vendor_id=${data.vendor.id}`) }

            const options = (await TrainsService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_train_error",
                text: "Failed to search related train(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchTrainSchedule = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.train) { qs.push(`train_id=${data.train.id}`) }

            const options = (await TrainScheduleService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: "Voy " + item.voy,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_train_schedule_error",
                text: "Failed to search related train schedule(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchTruck = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }
            if (data.vendor) { qs.push(`vendor_id=${data.vendor.id}`) }

            const options = (await TrucksService.retrieve(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return options;
        } catch (error) {
            banner.add({
                key: "search_truck_error",
                text: "Failed to search related truck(s). Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchOrder = async (keyword: string) => {
        try {
            const qs: string[] = [];
            qs.push(`status=current`);
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await OrderService.retrieve(qs.join("&"))).data.map((item) => ({
                key: item.id,
                name: item.orderNumber,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_order_error",
                text: "Failed to search related order. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onOrderChanged = (id: string, item?: IOrderResourceShortProps) => {
        const _orders = data.orders;
        const _related = _orders.find((g) => g.id === id);
        if (_related) {
            _related.order = item;
            _related.amount = "";
            _related.notes = "";
        }

        if (item) {
            _orders.push({
                id: GeneralService.guid(),
                amount: "",
                notes: ""
            });
        }

        data.orders = _orders;
        setData({ ...data });
    }

    const _onInitialiseRelatedOrders = async (type: 'shipping' | 'train', id: string) => {
        let _orders = data.orders;
        if (_orders.length <= 0) {
            let results: IOrderResourceShortProps[] = [];

            if (type === 'shipping') {
                results = await ShipSchedulesService.getOrders(id);
            } else if (type === 'train') {
                results = await TrainScheduleService.getOrders(id);
            }

            const price = Number(data.totalAmount) / results.length;
            _orders = results.map((res) => {
                return {
                    id: GeneralService.guid(),
                    order: res,
                    notes: "",
                    amount: price + ""
                }
            });

            data.orders = _orders;
            setData({ ...data });
            setUpdatingRelatedOrders(false);
        } else {
            setUpdatingRelatedOrders(false);
        }
    }

    const _onRetrieveVendorBankAccounts = async (vendor: IVendorResourceShortProps) => {
        setTargetBankAccounts(undefined);
        const targetBankAccounts = await VendorBankAccountService.retrieve(vendor.id);
        setTargetBankAccounts([...targetBankAccounts]);
    }

    const getTotalPrice = (price: string, ppn: string, pph: string) => {
        if (price === "") { price = "0" }
        if (ppn === "") { ppn = "0" }
        if (pph === "") { pph = "0" }

        return Number(price) + Number(ppn) + Number(pph);
    }

    const getTotalPaidPrice = (price: string, ppn: string, pph: string, payingPPn: boolean, payingPPh: boolean) => {
        if (price === "") { price = "0" }
        if (ppn === "") { ppn = "0" }
        if (pph === "") { pph = "0" }

        let total = Number(price);
        if (payingPPn) { total += Number(ppn); }
        if (payingPPh) { total += Number(pph); }

        return total;
    }

    const udpateOrders = (_data: FormDataProps) => {
        const orders = _data.orders;
        const totalOrder = orders.filter((order) => order.order).length;
        if (!error.amount && totalOrder === 1) {
            orders[0].amount = _data.totalAmount + "";
        } else if (!error.amount && totalOrder > 1) {
            let evenDistribution = true;
            orders.filter((order) => order.order).forEach((order) => {
                if (Number(order.amount) !== Number(orders[0].amount) && order.amount !== "") {
                    evenDistribution = false;
                }
            });

            if (evenDistribution) {
                orders.filter((order) => order.order).forEach((order) => {
                    order.amount = (Number(_data.totalAmount) / totalOrder).toFixed(2);
                });
            }
        }

        return orders;
    }

    const calculateAllPrice = (_data: FormDataProps) => {
        let originalPrice = "0";

        if (_data.includePPn) {
            if (_data.ppnPercentage && data.ppnPercentage !== 'custom') {
                originalPrice = (Number(_data.originalAmount) * 100 / (100 + Number(_data.ppnPercentage))).toFixed();
            } else {
                originalPrice = (Number(_data.originalAmount) - Number(_data.ppn)).toFixed();
            }
        } else if (!_data.includePPn) {
            originalPrice = _data.originalAmount;
        }

        let ppn = "0";
        if (_data.includePPn && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(originalPrice) * (Number(_data.ppnPercentage) / 100)).toFixed();
        } else if (!_data.includePPn && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(_data.originalAmount) * (Number(_data.ppnPercentage) / 100)).toFixed();
        }
        _data.ppn = ppn;

        let pph = "0";
        if (_data.pphPercentage && data.pphPercentage !== 'custom') {
            pph = (Number(originalPrice) * (Number(_data.pphPercentage) / 100)).toFixed();
        }
        _data.pph = pph;

        let finalPrice = 0;
        if (_data.includePPn && _data.includePPh) {
            finalPrice = Number(_data.originalAmount);
        } else if (_data.includePPn && !_data.includePPh) {
            finalPrice = Number(_data.originalAmount) + Number(_data.pph);
        } else if (!_data.includePPn && _data.includePPh) {
            finalPrice = Number(_data.originalAmount) + Number(_data.ppn);
        } else {
            finalPrice = Number(_data.originalAmount) + Number(_data.ppn) + Number(_data.pph);
        }
        _data.totalAmount = Number(finalPrice.toFixed(2));

        let invoicePrice = finalPrice - Number(_data.pph);
        /*if (_data.includePPn) {
            nettPrice = nettPrice - Number(_data.ppn);
        }
        if (_data.includePPh) {
            nettPrice = nettPrice - Number(_data.pph);
        }*/
        _data.amount = Number(invoicePrice.toFixed(2));

        setData({ ..._data });
    }

    return <Panel headerText={mode === 'create' ? 'Create Outcome' : 'Update Outcome'}
        isOpen={true}
        type={PanelType.custom}
        customWidth={'750px'}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'} verticalAlign='center'>
                {
                    !submitting ? <>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </Stack>
                        {!checkRelatedOrdersAmountMatched() ? <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <Text>Amount does not match!</Text>
                        </Stack> : null}
                    </> : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating outcome ..." : "Updating outcome ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} className={styles.container}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <Dropdown label={"Type / Purpose"}
                            required={true}
                            selectedKey={data.type}
                            options={OutcomeTypes}
                            onChange={(evt, opt) => {
                                data.type = opt ? opt.key as string : "";
                                data.vendor = undefined;
                                data.ship = undefined;
                                data.shipSchedule = undefined;
                                data.train = undefined;
                                data.trainSchedule = undefined;
                                data.truck = undefined;

                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <Stack>
                        <TextField label={"Price (as per invoice)"}
                            prefix='Rp'
                            required={true}
                            value={data.originalAmount}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    data.originalAmount = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.amount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                    calculateAllPrice(data);

                                    // check if order amount needs to be updated
                                    data.orders = udpateOrders(data);
                                    setData({ ...data })
                                }
                            }}
                            errorMessage={error.amount}
                            disabled={submitting} />
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <Stack.Item>
                            <Checkbox checked={data.includePPn}
                                label='Include PPn'
                                onChange={(ev, checked) => {
                                    data.includePPn = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox checked={data.includePPh}
                                label='Include PPh'
                                onChange={(ev, checked) => {
                                    data.includePPh = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPn</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPNPercentageOptions}
                                    selectedKey={data.ppnPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.ppn = (Number(opt.key) / 100 * Number(data.originalAmount)).toFixed();
                                        }
                                        data.ppnPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.ppn}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.ppn = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.ppn = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.ppn}
                                    disabled={submitting || data.ppnPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPh</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPhPercentageOptions}
                                    selectedKey={data.pphPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.pph = (Number(opt.key) / 100 * Number(data.originalAmount)).toFixed();
                                        }
                                        data.pphPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.pph}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.pph = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.pph = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.pph}
                                    disabled={submitting || data.pphPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
                        <Stack styles={{ root: { width: 300 } }}>
                            <Label size={'small'}>Total Price</Label>
                            <Text>Rp. {GeneralService.getNumberWithSeparator(data.totalAmount)}</Text>
                        </Stack>
                        <Stack styles={{ root: { marginTop: 20 } }}>
                            <Text>({GeneralService.convertNumberToWords(data.totalAmount)})</Text>
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
                        <Stack styles={{ root: { width: 300 } }}>
                            <Label size={'small'}>Total price paid to vendor</Label>
                            <Text>Rp. {GeneralService.getNumberWithSeparator(data.amount)}</Text>
                        </Stack>
                        <Stack styles={{ root: { marginTop: 20 } }}>
                            <Text>({GeneralService.convertNumberToWords(data.amount)})</Text>
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            value={data.notes}
                            multiline
                            rows={3}
                            resizable={false}
                            autoAdjustHeight
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 1000 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown label={"Transfer From"}
                            required={true}
                            selectedKey={data.bankAccount}
                            options={bankAccounts.map((ba) => {
                                return { key: ba.id, text: `${ba.name} (${ba.bankName}) - ${ba.accountNumber}` }
                            })}
                            onChange={(evt, opt) => {
                                data.bankAccount = opt ? opt.key as string : "";
                                setData({ ...data });
                            }} />
                    </Stack.Item>
                    <Stack className='divider'></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '33%' } }}>
                            <TextField label={"Invoice Number"}
                                value={data.invoiceNumber}
                                onChange={(evt, value) => {
                                    data.invoiceNumber = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.invoiceNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.invoiceNumber}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '33%' } }}>
                            <DatePicker label={"Invoice Date"}
                                isRequired={true}
                                value={data.invoiceDate !== "" ? moment(data.invoiceDate).toDate() : undefined}
                                onSelectDate={(date) => {
                                    data.invoiceDate = date ? moment(date).toISOString() : data.invoiceDate;
                                    setData({ ...data });
                                }}
                                formatDate={GeneralService.formatDate}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '33%' } }}>
                            <DatePicker label={"Due Date"}
                                isRequired={true}
                                value={moment(data.dueDate).toDate()}
                                onSelectDate={(date) => {
                                    data.dueDate = date ? moment(date).toISOString() : data.dueDate;
                                    setData({ ...data });
                                }}
                                formatDate={GeneralService.formatDate}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                    <Stack className='divider'></Stack>
                    <Label>Vendor Details</Label>
                    <VendorField selected={data.vendor}
                        allowCreate={true}
                        onChange={(vendor) => {
                            data.vendor = vendor;
                            data.ship = undefined;
                            data.shipSchedule = undefined;
                            data.train = undefined;
                            data.trainSchedule = undefined;

                            setData({ ...data });
                        }} />
                    {data.type === 'shipping' || data.type === 'train' || data.type === 'trucking' ? <>
                        <Stack className={'divider'}></Stack>
                        {data.type === 'shipping' ? <>
                            <Stack>
                                <ShipField selected={data.ship}
                                    allowCreate={true}
                                    onChange={(ship) => {
                                        data.ship = ship;
                                        if (!data.ship) {
                                            data.shipSchedule = undefined;
                                        }

                                        setData({ ...data });
                                    }} />
                            </Stack>
                            <Stack>
                                <ShipScheduleField selected={data.shipSchedule}
                                    disabled={!data.ship}
                                    ship={data.ship}
                                    allowCreate={true}
                                    onChange={(schedule) => {
                                        data.shipSchedule = schedule;

                                        setData({ ...data });
                                    }} />
                            </Stack>
                        </> : null}
                        {data.type === 'train' ? <>
                            <Stack.Item>
                                <Label required>Train</Label>
                                {!data.train ? <TagPicker
                                    selectedItems={[]}
                                    removeButtonAriaLabel='Remove'
                                    itemLimit={1}
                                    onItemSelected={(item: any) => {
                                        data.train = item ? item.metadata : undefined;
                                        data.trainSchedule = undefined;

                                        setData({ ...data });

                                        return null;
                                    }}
                                    onResolveSuggestions={_onSearchTrain}
                                    onEmptyResolveSuggestions={() => _onSearchTrain('')}
                                    disabled={submitting || !data.vendor}
                                /> : null}
                                {data.train ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                    <Stack>
                                        <Label size={'small'}>{data.train.name}</Label>
                                    </Stack>
                                    <ActionButton className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                        data.train = undefined;
                                        data.trainSchedule = undefined;

                                        setData({ ...data });
                                    }} />
                                </Stack> : null}
                            </Stack.Item>
                            <Stack.Item>
                                <Label required>Train Schedule</Label>
                                {!data.trainSchedule ? <TagPicker
                                    selectedItems={[]}
                                    removeButtonAriaLabel='Remove'
                                    itemLimit={1}
                                    onItemSelected={(item: any) => {
                                        data.trainSchedule = item ? item.metadata : undefined;

                                        setData({ ...data });
                                        if (item) {
                                            setUpdatingRelatedOrders(true);
                                            _onInitialiseRelatedOrders('train', item.metadata.id);
                                        }
                                        return null;
                                    }}
                                    onResolveSuggestions={_onSearchTrainSchedule}
                                    onEmptyResolveSuggestions={() => _onSearchTrainSchedule('')}
                                    disabled={submitting || !data.train}
                                /> : null}
                                {data.trainSchedule ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                    <Stack>
                                        <Label size={'small'}>Voy {data.trainSchedule.voy}</Label>
                                        <Stack horizontal horizontalAlign={'space-evenly'} tokens={{ childrenGap: 20 }} verticalAlign='center'>
                                            <Stack grow={1}>
                                                <Text size={'small'}>{data.trainSchedule.originAddress}</Text>
                                                {!data.trainSchedule.actualDeparture ? <Text size={'small'}>{GeneralService.formatDate(moment(data.trainSchedule.estimatedDeparture).toDate())} (Est.)</Text> : null}
                                                {data.trainSchedule.actualDeparture ? <Text size={'small'}>{GeneralService.formatDate(moment(data.trainSchedule.actualDeparture).toDate())} (Actual)</Text> : null}
                                            </Stack>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                            <Stack grow={1}>
                                                <Text size={'small'}>{data.trainSchedule.destinationAddress}</Text>
                                                {!data.trainSchedule.actualArrival ? <Text size={'small'}>{GeneralService.formatDate(moment(data.trainSchedule.estimatedArrival).toDate())} (Est.)</Text> : null}
                                                {data.trainSchedule.actualArrival ? <Text size={'small'}>{GeneralService.formatDate(moment(data.trainSchedule.actualArrival).toDate())} (Actual)</Text> : null}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <ActionButton className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                        data.train = undefined;
                                        data.trainSchedule = undefined;

                                        setData({ ...data });
                                    }} />
                                </Stack> : null}
                            </Stack.Item>
                        </> : null}
                        {data.type === 'trucking' ? <>
                            <Stack.Item>
                                <Label required>Truck</Label>
                                {!data.truck ? <TagPicker
                                    selectedItems={[]}
                                    removeButtonAriaLabel='Remove'
                                    itemLimit={1}
                                    onItemSelected={(item: any) => {
                                        data.truck = item ? item.metadata : undefined;
                                        setData({ ...data });
                                        return null;
                                    }}
                                    onResolveSuggestions={_onSearchTruck}
                                    onEmptyResolveSuggestions={() => _onSearchTruck('')}
                                    disabled={submitting || !data.vendor}
                                /> : null}
                                {data.truck ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                    <Stack>
                                        <Label size={'small'}>{data.truck.name}</Label>
                                        <Text size={'small'}>{data.truck.registrationNumber}</Text>
                                    </Stack>
                                    <ActionButton className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                        data.truck = undefined;

                                        setData({ ...data });
                                    }} />
                                </Stack> : null}
                            </Stack.Item>
                        </> : null}
                    </> : null}
                    <Stack className='divider'></Stack>
                    <Stack.Item>
                        <Label>Related Orders</Label>
                        <ShimmeredDetailsList items={data.orders}
                            shimmerLines={3}
                            enableShimmer={updatingRelatedOrders}
                            columns={[
                                {
                                    key: "order",
                                    name: "Order",
                                    minWidth: 180,
                                    maxWidth: 180,
                                    isMultiline: true,
                                    onRender: (item: FormDataOrderProps) => {
                                        return <>
                                            {!item.order ? <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                <TagPicker selectedItems={[]}
                                                    removeButtonAriaLabel='Remove'
                                                    itemLimit={1}
                                                    onItemSelected={(tag: any) => {
                                                        const value = tag ? tag.metadata : undefined;
                                                        _onOrderChanged(item.id || "", value);
                                                        return null;
                                                    }}
                                                    onChange={(tags) => {
                                                        if (tags && tags.length < 1) {
                                                            _onOrderChanged(item.id || "", undefined);
                                                        }
                                                    }}
                                                    onRenderSuggestionsItem={(props: any, itemProps) => {
                                                        const md = props.metadata as IOrderResourceShortProps;
                                                        return <Stack styles={{ root: { padding: 10, borderBottom: '1px solid #eee' } }} horizontalAlign='baseline'>
                                                            <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign='center'>
                                                                <Label size={'small'}>#{OrderService.getOrderNumber(md.orderNumber)}</Label>
                                                                {md.batch && md.batch !== "" ? <Text style={{ marginTop: 2 }} size={'small'}>(Batch: {md.batch})</Text> : null}
                                                            </Stack>
                                                            {md.company ? <Text size={'small'}>{md.company.name} - {md.customer.name}</Text> : null}
                                                            {!md.company ? <Text size={'small'}>{md.customer.name}</Text> : null}
                                                        </Stack>;
                                                    }}
                                                    onResolveSuggestions={_onSearchOrder}
                                                    onEmptyResolveSuggestions={() => _onSearchOrder('')}
                                                    disabled={submitting} />
                                            </Stack> : null}
                                            {item.order ? <Stack horizontalAlign='baseline'>
                                                <Label size={'small'}>#{OrderService.getOrderNumber(item.order.orderNumber)}</Label>
                                                {item.order.company ? <Text size={'small'}>{item.order.company.name} - {item.order.customer.name}</Text> : null}
                                                {!item.order.company ? <Text size={'small'}>{item.order.customer.name}</Text> : null}
                                            </Stack> : null}
                                        </>
                                    }
                                },
                                {
                                    key: "amount",
                                    name: "Amount",
                                    minWidth: 150,
                                    maxWidth: 150,
                                    onRender: (item: FormDataOrderProps) => {
                                        return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                            <TextField value={item.amount}
                                                prefix={"Rp"}
                                                disabled={submitting || !item.order}
                                                onChange={(evt, value) => {
                                                    const _related = data.orders.find((d) => d.id === item.id);
                                                    if (_related && ((value || "").trim() === "" || !isNaN(Number(value)))) {
                                                        _related.amount = value || "";
                                                        setData({ ...data });
                                                    }
                                                }} />
                                        </Stack>
                                    }
                                },
                                {
                                    key: "notes",
                                    name: "Notes",
                                    minWidth: 100,
                                    onRender: (item: FormDataOrderProps) => {
                                        return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                            <TextField value={item.notes}
                                                multiline
                                                rows={3}
                                                autoAdjustHeight
                                                resizable={false}
                                                disabled={submitting || !item.order}
                                                onChange={(evt, value) => {
                                                    const _related = data.orders.find((d) => d.id === item.id);
                                                    if (_related && (value || "").length < 1000) {
                                                        _related.notes = value || "";
                                                        setData({ ...data });
                                                    }
                                                }} />
                                        </Stack>
                                    }
                                },
                                {
                                    key: "actions",
                                    name: "",
                                    minWidth: 50,
                                    maxWidth: 50,
                                    onRender: (item: FormDataOrderProps) => {
                                        return <Stack.Item styles={{ root: { padding: '16px 0px' } }}>
                                            <Stack.Item className={"detailsListActionRow"}>
                                                {item.order ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                                                    const _orders = data.orders.filter((d) => d.id !== item.id);
                                                    data.orders = _orders;
                                                    setData({ ...data });
                                                }} /> : null}
                                            </Stack.Item>
                                        </Stack.Item>;
                                    }
                                }
                            ]}
                            selectionMode={SelectionMode.none} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OutcomeInvoiceForm;
