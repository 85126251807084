import {
  ITrainDocumentResourceShortProps,
  ITrainScheduleDelayResourceShort,
  ITrainScheduleResourceShort,
} from '../../../props/data/trains';
import { IDocumentProps } from '../../../props/general';
import { IOrderContainerResourceShortProps } from '../../../props/orders/containers';
import { IOrderResourceProps } from '../../../props/orders/order';
import Request from './../../request';

// import props

const TrainScheduleService = {
  retrieve: async (qs?: string): Promise<ITrainScheduleResourceShort[]> => {
    try {
      const results = await Request.get(`/api/operationals/trains/schedules?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (data: FormData): Promise<ITrainScheduleResourceShort> => {
    try {
      const result = await Request.post(`/api/operationals/trains/schedules`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<ITrainScheduleResourceShort> => {
    try {
      const item = await Request.get(`/api/operationals/trains/schedules/${id}`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<ITrainScheduleResourceShort> => {
    try {
      const result = await Request.post(
        `/api/operationals/trains/schedules/${id}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/operationals/trains/schedules/${id}`);
    } catch (e) {
      throw e;
    }
  },
  getDelays: async (id: string): Promise<ITrainScheduleDelayResourceShort[]> => {
    try {
      const item = await Request.get(`/api/operationals/trains/schedules/${id}/delays`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  getContainers: async (id: string): Promise<IOrderContainerResourceShortProps[]> => {
    try {
      const item = await Request.get(`/api/operationals/trains/schedules/${id}/containers`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  getOrders: async (id: string): Promise<IOrderResourceProps[]> => {
    try {
      const item = await Request.get(`/api/operationals/trains/schedules/${id}/orders`);
      return item;
    } catch (e) {
      throw e;
    }
  },
  documents: {
    retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
      try {
        const results = await Request.get(`/api/operationals/trains/schedules/${id}/documents?${qs || ""}`);
        return results;
      } catch (e) {
        throw (e);
      }
    },
    new: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/trains/schedules/${id}/documents`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw (e);
      }
    },
    delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/operationals/trains/schedules/${id}/delete_documents`, data);
        return result;
      } catch (e) {
        throw (e);
      }
    }
  },
};

const TrainScheduleDocumentsService = {
  retrieve: async (scheduleId: string, keyword?: string): Promise<ITrainDocumentResourceShortProps[]> => {
    const queries: string[] = [];
    if (keyword && keyword.trim() !== '') {
      queries.push(`keyword=${keyword}`);
    }
    try {
      const results = await Request.get(`/api/data/trainSchedules/${scheduleId}/documents?${queries || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  new: async (scheduleId: string, data: FormData): Promise<ITrainDocumentResourceShortProps> => {
    try {
      const result = await Request.post(
        `/api/data/trainSchedules/${scheduleId}/documents`,
        data,
        'multipart/form-data'
      );
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (scheduleId: string, id: string): Promise<void> => {
    try {
      await Request.delete(`/api/data/trainSchedules/${scheduleId}/documents/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

const TrainScheduleImagesService = {
  retrieve: async (scheduleId: string, keyword?: string): Promise<ITrainDocumentResourceShortProps[]> => {
    const queries: string[] = [];
    if (keyword && keyword.trim() !== '') {
      queries.push(`keyword=${keyword}`);
    }
    try {
      const results = await Request.get(`/api/data/trainSchedules/${scheduleId}/images?${queries || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (scheduleId: string, id: string): Promise<void> => {
    try {
      await Request.delete(`/api/data/trainSchedules/${scheduleId}/images/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
};

export { TrainScheduleDocumentsService, TrainScheduleImagesService };

export default TrainScheduleService;
