import Request from './../request';

// import props
import {
    IDeliveryTypeResourceShortProps
} from './../../props/orders/deliveryType';

const DeliveryTypeService = {
    retrieve: async ():Promise<IDeliveryTypeResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/deliveryTypes`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    new: async (data:FormData):Promise<IDeliveryTypeResourceShortProps> => {
        try {
            const result = await Request.post(`/api/deliveryTypes`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id:string, data:FormData):Promise<IDeliveryTypeResourceShortProps> => {
        try {
            const result = await Request.post(`/api/deliveryTypes/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/deliveryTypes/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
}

export default DeliveryTypeService;