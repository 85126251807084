const ChartColors: string[] = [
    "#d29200",
    "#d83b01",
    "#004b50",
    "#5c005c",
    "#b4009e",
    "#32145a",
    "#00188f",
    "#004b1c",
    "#ffb900",
    "#ea4300",
    "#008272",
    "#a4262c",
    "#5c2d91",
    "#0078d4",
    "#107c10",
    "#fff100",
    "#ff8c00",
    "#d13438",
    "#e3008c",
    "#b4a0ff",
    "#00bcf2",
    "#00B294",
    "#bad80a"
];

const FixedChartColors = {
    paid: '#107C10',
    unpaid: '#a4262c',
    active: '#0078d4',
    completed: '#107C10',
    cancelled: 'a4262c'
}

export default ChartColors;
export {FixedChartColors};