import React, { useEffect, useState } from 'react';

// assets
import Logo from './../../../assets/logo/logo-dark.webp';

import styles from './style.module.scss';

export type TextProps = React.PropsWithChildren<{
    size?: 'xsmall' | 'small' | 'medium' | 'large';
    className?: string;
    style?: React.CSSProperties;
}>

const Text: React.FC<TextProps> = (props: TextProps) => {
    return <p className={`${styles.text} ${styles[props.size || 'medium']} ${props.className || ''}`} style={props.style}>{props.children}</p>
};

export default Text;
