import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../../services/general/error';

// props

// components
import { CommandBar, CommandBarButton, DefaultButton, ICommandBarItemProps, PrimaryButton, SearchBox, Stack, Text } from '@fluentui/react';
import { InternalDocumentService } from '../../../../../../services/documents/internalDocument';
import { IInternalDocumentResourceShort } from '../../../../../../props/internalDocuments';
import { IFileDetailsProps } from '../../../../../../props/general';
import InternalDocumentFilesList from '../../../files/list';
import DeleteInternalFilesPanel from '../../../files/delete';
import CreateFolderPanel from '../../../folders/form';
import { useLocation } from 'react-router-dom';
import useFolders from '../../../../../hooks/getFolders';
import addFolderKey from '../../../../../../utils/folderKey';
import UploadFilesPanel from '../../../../../uiframeworks/files/uploads/panel';

interface IInternalDocumentProps {
    qs?: string[];
    documents?: IInternalDocumentResourceShort[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideDeleteButton?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

const InternalDocument: React.FC<IInternalDocumentProps> = (props: IInternalDocumentProps) => {
    const { banner } = useStore();
    const location = useLocation();
    const folderNames = useFolders()

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [documents, setDocuments] = React.useState<IInternalDocumentResourceShort[]>(props.documents || []);
    const [selected, setSelected] = React.useState<IInternalDocumentResourceShort[]>([]);
    const [activeDocument, setActiveDocument] = React.useState<IInternalDocumentResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [notFoundFolder, setNotfoundFolder] = useState<boolean>(false)
    const [folder, setFolder] = useState<IInternalDocumentResourceShort>()

    useEffect(() => {
        folderNames !== undefined && getFolder()
        return () => { }
    }, [folderNames]);

    const getFolder = async () => {
        setLoaded(false)
        setNotfoundFolder(false)
        if (folderNames && folderNames.length !== 0) {
            const qs: string[] = props.qs || [];
            if (folderNames.length !== 0) { qs.push(`folders=${folderNames.map(f => addFolderKey(f)).join('/')}`) }
            try {
                const result = await InternalDocumentService.getFolder(qs.join("&"));
                const folder = result.folders.find(f => {
                    const paths = f.path.split('/')
                    const fName = addFolderKey(folderNames[folderNames.length - 1])
                    if (paths[paths.length - 1] === fName) {
                        return true
                    }
                })
                if (folder) {
                    setDocuments(result.documents.filter(f => f.id !== folder.id).filter(f => {
                        const paths = f.path.split('/');
                        if (f.type === 'folder') {
                            if (folderNames.length + 1 === paths.length) {
                                return true
                            }
                            return false
                        } else {
                            if (paths[paths.length - 4] === addFolderKey(folder.name)) {
                                return true
                            }
                            return false
                        }
                    }).sort((a, b) => {
                        if (a.type === 'folder' && b.type === 'document') {
                            return -1;
                        }
                        if (a.type === 'document' && b.type === 'folder') {
                            return 1;
                        }
                        return 0;
                    }))
                    setFolder(folder)
                    setLoaded(true)
                } else {
                    setNotfoundFolder(true)
                    setLoaded(true)
                }
            } catch (error) {
                setLoaded(true)
                setNotfoundFolder(true)
            }
        } else {
            _onRetrieveDocuments()
        }
    }

    const _onRetrieveDocuments = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            setSelected([]);
            const qs: string[] = props.qs || [];
            const result = (await InternalDocumentService.retrieve(qs.join("&"))).filter(r => {
                const paths = r.path.split('/')
                if (r.type === 'folder' && paths.length > 1) {
                    return false
                }
                return ({ ...r })
            });
            setDocuments(result);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_internal_documents_list_error',
                text: 'Failed to retrieve internal documents list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideDeleteButton && selected.length > 0) {
            items.push({
                key: "delete",
                text: "Delete Document(s)",
                iconProps: { iconName: "Delete" },
                onRender: () => {
                    return <DefaultButton text={"Delete(s)"}
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => { setActiveSurface('delete') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            })
        }

        if (!props.hideCreateButton) {
            farItems.push({
                key: "upload",
                text: "Create/Upload",
                iconProps: { iconName: "Plus" },
                onRender: () => {
                    return (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton
                                text='Create Folder'
                                onClick={() => {
                                    setActiveSurface('createFolder')
                                }}
                            />
                            <PrimaryButton
                                text='Upload Document'
                                onClick={() => {
                                    setActiveSurface('upload')
                                }}
                            />
                        </Stack>
                    )
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveDocument(undefined);

        if (refresh) { getFolder() }
    }

    const _onUploadDocuments = async (files: IFileDetailsProps[]) => {
        try {
            const names: string[] = [];
            const fd = new FormData();

            files.map(f => {
                const file = f.data as File
                const allMetaData = {
                    name: file.name,
                    size: file.size,
                }
                fd.append('metadatas[]', JSON.stringify({ visibility: f.visibility, type: f.type, ...allMetaData }))
            })
            if (folderNames) {
                folderNames.map(f => fd.append('folders[]', f))
            }
            files.map(f => fd.append('documents[]', f.data))

            await InternalDocumentService.new(fd)

            banner.add({
                key: "upload_internal_documents_success",
                variant: 'success',
                icon: faCheck,
                text: `Document(s) ${names.join(", ")} uploaded successfully.`
            });

            setActiveSurface(undefined);
            setActiveDocument(undefined);
            getFolder();
        } catch (e) {
            throw (e);
        }
    }

    const _onDeleteDocuments = async (files: IInternalDocumentResourceShort[]) => {
        const names: string[] = [];
        const fd = new FormData();
        files.forEach((file) => {
            fd.append('deleteDocumentIds[]', file.id);
            names.push(`"${file.name}"`);
        });
        try {
            await InternalDocumentService.deletes(fd)

            banner.add({
                key: "delete_internal_documents_success",
                variant: 'success',
                icon: faCheck,
                text: `Document(s) ${names.join(", ")} deleted successfully.`
            });

            setActiveSurface(undefined);
            setActiveDocument(undefined);
            getFolder();
        } catch (e) {
            throw (e);
        }
    }

    const _onSelect = (_selected: IInternalDocumentResourceShort[]) => {
        setSelected([..._selected]);
    }

    const onCreateFoler = async (folderName: string) => {
        const fd = new FormData();
        fd.append('name', folderName)
        if (folderNames) {
            folderNames.map(f => fd.append('folders[]', f))
        }
        try {
            await InternalDocumentService.newFolder(fd)
            getFolder();
            setActiveSurface(undefined)
        } catch (error) {

        }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {!notFoundFolder &&
                <InternalDocumentFilesList
                    files={documents.filter((doc) => doc.path.toLowerCase().indexOf(keyword.toLowerCase()) > -1)}
                    loaded={loaded}
                    onSelectFile={_onSelect}
                    selected={selected}
                />}
            {folderNames && notFoundFolder && <Text>Folder {folderNames[folderNames?.length - 1]} not found</Text>}
        </Stack>
        {activeSurface === 'upload' ? <>
            <UploadFilesPanel
                multiple={true}
                existings={documents}
                title={'Upload Related Document(s)'}
                onCancel={_onSurfaceDismissed}
                onUpload={_onUploadDocuments} />
        </> : null}
        {activeSurface === 'createFolder' ? <>
            <CreateFolderPanel
                title={'Create Foler'}
                onCancel={_onSurfaceDismissed}
                onCreate={onCreateFoler}
            />
        </> : null}
        {activeSurface === 'delete' && selected.length > 0 ? <>
            <DeleteInternalFilesPanel
                title={'Delete(s)'}
                files={selected}
                onCancel={_onSurfaceDismissed}
                onDelete={_onDeleteDocuments}
            />
        </> : null}
    </Stack>;
};

export default InternalDocument;
