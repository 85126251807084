import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../../stores/root';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import moment from 'moment';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../../../services/validation';
import GeneralService from '../../../../../../../services/general';
import IncomePaymentService from '../../../../../../../services/finance/incomes/payments/payment';

// props
import { IOrderResourceShortProps } from '../../../../../../../props/orders/order';
import { IBankAccountResourceShortProps } from '../../../../../../../props/general/bankAccount';

// components
import { DatePicker, DefaultButton, Dropdown, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { IIncomeInvoiceResourceShortProps } from '../../../../../../../props/finance/incomes/invoices/invoice';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';
import ErrorService from '../../../../../../../services/general/error';
import Label from '../../../../../../typography/label';
import Text from '../../../../../../typography/text';
import UploadFilesComponent from '../../../../../../uiframeworks/files/uploads/uploadFile';
import { IFileDetailsProps } from '../../../../../../../props/general';
import { IIncomePaymentResourceShortProps } from '../../../../../../../props/finance/incomes/payments/payment';
import FilesList, { TTagVariant } from '../../../../../../uiframeworks/files/list';
import { IOutcomeSalaryPaymentResourceShortProps } from '../../../../../../../props/finance/outcomes/salaries';
import { OutcomeSalaryPaymentService } from '../../../../../../../services/finance/outcomes/salaries';
import Tag from '../../../../../../uiframeworks/tag';
import EmployedPaymentDetails from '../details';

interface IRejectInvoicePaymentDetailsFormProps {
  paymentId: string;
  salaryId: string
  onDismissed(refresh?: boolean): void;
}
type FormDataProps = {
  method: string;
  amount: string;
  paymentDate: string;
  notes?: string;
  reason: string;
}

type FormDataErrorProps = {
  method?: string;
  amount?: string;
  paymentDate?: string;
  notes?: string;
  reason?: string;
}

const RejectInvoicePaymentDetailsForm: React.FC<IRejectInvoicePaymentDetailsFormProps> = (props: IRejectInvoicePaymentDetailsFormProps) => {
  const { banner } = useStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<IOutcomeSalaryPaymentResourceShortProps>();
  const [deleteFileIds, setDeleteFileIds] = useState<string[]>([])
  const [files, setFiles] = useState<IFileDetailsProps[]>([])
  const [data, setData] = React.useState<FormDataProps>({
    reason: "",
    method: "cash",
    amount: "",
    paymentDate: moment().toISOString(),
    notes: ""
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const _payment = await OutcomeSalaryPaymentService.read(props.salaryId, props.paymentId);

      setData({
        method: _payment.paymentMethod,
        amount: _payment.amount,
        paymentDate: _payment.processedAt,
        reason: _payment.justification || ''
      })
      setPayment(_payment);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_payment_error',
        variant: 'error',
        icon: faXmarkCircle,
        text: `Failed to get invoice "${IncomeInvoiceService.getInvoiceNumber(props.paymentId)}". Error: ${ErrorService.getMessage(e)}`
      });
      props.onDismissed();
    }
  }

  const isSubmitButtonDisabled = (): boolean => {
    return false;
  }

  const renderStatus = () => {
    if (payment) {
      let tagVariant: TTagVariant = 'active';
      let tagText: string = payment.paymentStatus;

      if (payment.paymentStatus.toLowerCase() === 'rejected') {
        tagVariant = 'error';
        tagText = 'Rejected'
      } else if (payment.paymentStatus.toLowerCase() === 'approved') {
        tagText = 'Complete'
        tagVariant = 'success';
      } else {
        {
          tagVariant = 'warning';
          tagText = 'Requested'
        }
      }


      return (
        <Stack>
          <Text>Status</Text>
          <Stack horizontal>
            <Tag variant={tagVariant} text={tagText} />
          </Stack>
        </Stack>
      )

    }
  }


  return <Panel headerText={'Details Payment'}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }}>
      {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Retrieving details ..."} /></Stack> : null}
      {
        loaded && payment ? <>
          <Stack tokens={{ childrenGap: 15 }}>
            {payment.paymentStatus === 'approved' ? <MessageBar messageBarType={MessageBarType.success}>
              <Text>This payment has been completed/paid by {payment.processedBy?.name} at {moment(payment.processedAt).format("DD/MM/YYYY HH:mm")}</Text>
            </MessageBar> : null}
            {payment.paymentStatus === 'rejected' ? <MessageBar messageBarType={MessageBarType.error}>
              <Text>This payment is rejected by {payment.processedBy?.name}. Justification: {payment.justification || "-"}</Text>
            </MessageBar> : null}
            {/*<CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />*/}
            <Pivot className={'linePivot'}>
              <PivotItem key={'details'} headerText={"Details"}>
                <Stack styles={{ root: { padding: 10 } }}><EmployedPaymentDetails payment={payment} salaryId={props.salaryId} paymentId={props.paymentId} /></Stack>
              </PivotItem>
            </Pivot>
          </Stack>
          {/* <Stack.Item>
            <Dropdown label={"Method"}
              required={true}
              disabled
              selectedKey={data.method}
              options={PaymentMethods}
              onChange={(evt, opt) => {
                data.method = opt ? opt.key as string : "";
                setData({ ...data });
              }} />
          </Stack.Item>
          <Stack.Item>
            <TextField label={"Amount"}
              value={data.amount}
              required={true}
              prefix={'Rp'}
              errorMessage={error.amount}
              disabled />
          </Stack.Item>
          <Stack.Item>
            <DatePicker label={"Payment Date"}
              isRequired={true}
              value={moment(data.paymentDate).toDate()}
              formatDate={GeneralService.formatDate}
              disabled />
          </Stack.Item>
          {renderStatus()}
          <Stack.Item>
            <TextField label={"Reason"}
              required={true}
              value={data.reason}
              onChange={(evt, value) => {
                data.reason = value || "";

                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                error.reason = validation.message;

                setData({ ...data });
                setError({ ...error });
              }}
              errorMessage={error.reason}
              disabled
              multiline
              rows={5}
            />
          </Stack.Item> */}
          <Stack styles={{ root: { maxHeight: 600 } }}>
            <Label>Document(s)</Label>
            <FilesList files={payment.documents || []}
              loaded={loaded}
              mode='card'
              documentTypes={[
                { key: "document", text: "Document" }
              ]} />
          </Stack>
        </> : null
      }
    </Stack>
  </Panel>
};

export default RejectInvoicePaymentDetailsForm;