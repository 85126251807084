import { ActionButton, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

import { useStore } from '../../../../../stores/root';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import { IUserResourceShortProps } from '../../../../../props/users/user';
import UserService from '../../../../../services/users/user';
import DetailsStack, { IStackDefaultSectionProps, IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import moment from 'moment';
import { faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../services/general/error';
import OrderService from '../../../../../services/orders/order';
import { IOrderResourceProps } from '../../../../../props/orders/order';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import GeneralService from '../../../../../services/general';
import LoadingComponent from '../../../../feedbacks/loading';
import { IEmployeePerformanceResourceShortProps } from '../../../../../props/users/employees/performance';
import EmployeePerformanceService from '../../../../../services/users/employees/performance';
import AdministratorPerformanceRevenueChart from './charts/revenue';
import AdministratorPerformanceTotalOrdersChart from './charts/totalOrders';
import AdministratorPerformanceCommissionsChart from './charts/commissions';

interface AdministratorPerformanceDashboardProps {
    userId: string;
}

const AdministratorPerformanceDashboard: React.FC<AdministratorPerformanceDashboardProps> = (props: AdministratorPerformanceDashboardProps) => {
    const { topbar, banner } = useStore();
    const [user, setUser] = React.useState<IUserResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [retrieving, setRetrieving] = React.useState<boolean>(true);
    const [performance, setPerformance] = React.useState<IEmployeePerformanceResourceShortProps | undefined>();
    const [selectedDate, setSelectedDate] = React.useState<string>("This Month");
    const [dateRange, setDateRange] = React.useState<{ startDate: string; endDate: string }>({
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString()
    });

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        _onRetrievePerformance();
    }, [dateRange, user]);

    const init = async () => {
        let _user;
        if (props.userId !== 'my') {
            _user = await UserService.get(props.userId);
        } else {
            _user = await UserService.currentUser();
        }

        topbar.show(_user.name, [
            { key: "home", icon: faHome, href: "/" },
            { key: "users", text: 'Users' },
            { key: "administrators", text: 'Administrators', href: "/users/administrators" },
            { key: props.userId, text: _user.name, href: "/users/administrators/" + _user.id },
            { key: 'performance', text: "Performance" }
        ]);

        setUser(_user);
        setLoaded(true);
    }

    const _onRetrievePerformance = async () => {
        try {
            setRetrieving(true);
            if (user) {
                const qs: string[] = [];
                qs.push(`top=all`);
                qs.push(`sales=${user.id}`);
                qs.push(`start_date=${dateRange.startDate}`);
                qs.push(`end_date=${dateRange.endDate}`);

                //const _performance = await EmployeePerformanceService.get(user.id, qs.join('&'));
                //setPerformance(_performance);
                setRetrieving(false);
            }
        } catch (e) {
            banner.add({
                key: 'retrieve_performance_summary_error',
                text: 'Failed to get performance summary. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const renderCharts = (): IStackSectionProps => {
        return {
            key: 'summaryChart',
            subsections: [
                {
                    key: 'revenueChart',
                    isCard: true,
                    header: {
                        title: 'Total Revenue'
                    },
                    content: <Stack tokens={{ childrenGap: 10 }}>
                        {retrieving ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                        {!retrieving && user && performance ? <AdministratorPerformanceRevenueChart employee={user} performance={performance} /> : null}
                    </Stack>
                },
                {
                    key: 'totalOrdersChart',
                    isCard: true,
                    header: {
                        title: 'Total Orders'
                    },
                    content: <Stack tokens={{ childrenGap: 10 }}>
                        {retrieving ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                        {!retrieving && user && performance ? <AdministratorPerformanceTotalOrdersChart employee={user} performance={performance} /> : null}
                    </Stack>
                },
                {
                    key: 'commissionChart',
                    isCard: true,
                    header: {
                        title: 'Total Commissions'
                    },
                    content: <Stack tokens={{ childrenGap: 10 }}>
                        {retrieving ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                        {!retrieving && user && performance ? <AdministratorPerformanceCommissionsChart employee={user} performance={performance} /> : null}
                    </Stack>
                }
            ]
        }
    }

    const renderSummaryInNumberStack = () => {
        let totalProfits = 0;
        let totalPaidRevenue = 0;
        let totalUnpaidRevenue = 0;
        let totalRevenue = 0;
        let totalCommission = 0;
        (performance?.salesOrders || []).forEach((order) => {
            totalProfits += order.grossProfit;
            totalRevenue += order.finalPrice;
            totalPaidRevenue += order.paidAmount;
            totalUnpaidRevenue += order.unpaidAmount;
        });

        (performance?.commissions || []).forEach((commission) => {
            totalCommission += commission.totalCommissions;
        })

        return {
            key: 'performanceInNumber',
            header: {
                title: "Performance in Number"
            },
            isCard: true,
            content: <Stack tokens={{ childrenGap: 10 }}>
                {retrieving ? <LoadingComponent label={'Retrieving data ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                {!retrieving ? <>
                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                        <Text size={"small"}>Total Orders</Text>
                        <Label size={'small'}>{GeneralService.getNumberWithSeparator((performance?.salesOrders || []).length)}</Label>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Label size={"small"}>Total Revenue</Label>
                        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                            <Text size={'small'}>Paid</Text>
                            <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(totalPaidRevenue)}</Label>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                            <Text size={'small'}>Unpaid</Text>
                            <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(totalUnpaidRevenue)}</Label>
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                        <Text size={"small"}>Total Profits</Text>
                        <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(totalProfits)}</Label>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                        <Text size={"small"}>Total Commissions</Text>
                        <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(totalCommission)}</Label>
                    </Stack>
                </> : null}
            </Stack>
        }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
            <Text size={'large'}>See performance on </Text>
            <Stack horizontal>
                <Label>{selectedDate}</Label>
                <ActionButton styles={{ root: { height: 'unset', minHeight: 'unset !important' } }} menuIconProps={{ iconName: "ChevronDown" }} menuProps={{
                    items: [
                        {
                            key: 'This Month', text: 'This Month', onClick: () => {
                                setSelectedDate('This Month');
                                setDateRange({
                                    startDate: moment().startOf('month').toISOString(),
                                    endDate: moment().endOf('month').toISOString()
                                });
                            }
                        },
                        {
                            key: 'Last Month', text: 'Last Month', onClick: () => {
                                setSelectedDate('Last Month');
                                setDateRange({
                                    startDate: moment().startOf('month').add(-1, 'month').toISOString(),
                                    endDate: moment().endOf('month').add(-1, 'month').toISOString()
                                })
                            }
                        },
                        {
                            key: 'Last 3 Months', text: 'Last 3 Months', onClick: () => {
                                setSelectedDate('Last 3 Month');
                                setDateRange({
                                    startDate: moment().startOf('month').add(-2, 'month').toISOString(),
                                    endDate: moment().endOf('month').toISOString()
                                })
                            }
                        },
                        {
                            key: 'This Year', text: 'This Year', onClick: () => {
                                setSelectedDate('This Year');
                                setDateRange({
                                    startDate: moment().startOf('year').toISOString(),
                                    endDate: moment().endOf('year').toISOString()
                                })
                            }
                        },
                        {
                            key: 'Last Year', text: 'Last Year', onClick: () => {
                                setSelectedDate('Last Year');
                                setDateRange({
                                    startDate: moment().startOf('year').add(-1, 'year').toISOString(),
                                    endDate: moment().endOf('year').add(-1, 'year').toISOString()
                                })
                            }
                        }
                    ]
                }} />
            </Stack>
        </Stack>
        <DetailsStack left={[renderCharts()]} right={[renderSummaryInNumberStack()]} />
    </Stack>;
};

export default AdministratorPerformanceDashboard;
