import { ISystemBankResourceShortProps } from '../../props/systems/banks';
import Request from './../request';

const SystemBankService = {
  retrieve: async (qs?: string): Promise<ISystemBankResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/systems/banks?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  }
}

export default SystemBankService;