import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import ContactsList from '../../../components/settings/contacts/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IContactsListPageProps {
}

const ContactsListPage: React.FC<IContactsListPageProps> = (props: IContactsListPageProps) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Public Contact", [
      { key: "home", icon: faHome, href: "/" },
      { key: "settings", text: 'Settings', href: "/settings" },
      { key: "contacts", text: 'Contacts' }
    ])
  }, []);

  return (
    <Stack>
      <ContactsList />
    </Stack>
  );
};

export default ContactsListPage;
