import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/root';
import styles from './styles.module.scss';

// assets

// props

// components
import { ActionButton, Stack } from '@fluentui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Label from '../../typography/label';
import moment from 'moment';
import ErrorService from '../../../services/general/error';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ActivitiesService from '../../../services/activities/activities';
import { IActivityResourceShortProps } from '../../../props/activities';
import DashboardScheduleCalendarView from './views/calendar';
import LoadingComponent from '../../feedbacks/loading';
import DashboardScheduleListView from './views/list';

export type DashboardScheduleProps = {
    view?: TScheduleView;
    startDate?: string;
    endDate?: string;
};

type TScheduleView = 'calendar' | 'list';

type ScheduleViewProps = {
    key: TScheduleView;
    text: string;
}

type CalendarItemProps = {
    date: string;
    link?: string;
    title?: string;
    content?: JSX.Element;
    backgroundColor?: string;
    items?: {
        icon?: IconProp;
        title?: string;
        value: string;
    }[];
}

const ScheduleViewOptions: ScheduleViewProps[] = [
    { key: 'calendar', text: 'Calendar View' },
    { key: 'list', text: 'List View' }
];

const DashboardSchedule: React.FC<DashboardScheduleProps> = observer((props: DashboardScheduleProps) => {
    const { banner } = useStore();
    const [view, setView] = React.useState<ScheduleViewProps>(props.view ? ScheduleViewOptions.find((v) => v.key === props.view) || ScheduleViewOptions[0] : ScheduleViewOptions[0]);
    const [selectedDate, setSelectedDate] = React.useState<{ startDate: string, endDate: string }>({
        startDate: props.startDate || moment().add(-1, 'days').startOf('day').toISOString(),
        endDate: props.endDate || moment().add(4, 'days').endOf('day').toISOString()
    });
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [activities, setActivities] = React.useState<IActivityResourceShortProps | undefined>();

    useEffect(() => {
        _onRetrieveCalendarItems();
    }, [selectedDate]);

    const _onSelectedDateChange = (startDate: string, endDate: string) => {
        setSelectedDate({startDate, endDate});
    }

    const _onRetrieveCalendarItems = async () => {
        try {
            setLoaded(false);
            const qs: string[] = [];
            qs.push(`startDate=${selectedDate.startDate}`);
            qs.push(`endDate=${selectedDate.endDate}`);
            const _activities = await ActivitiesService.retrieve(qs.join('&'));
            setActivities(_activities);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_dashboard_schedule_error',
                text: 'Failed to retrieve schedules. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 10 }}>
        <Stack horizontal horizontalAlign={'space-between'} verticalAlign='center'>
            <Label>Activities Schedule</Label>
            <ActionButton className={styles.desktop} text={view.text} menuProps={{
                items: ScheduleViewOptions.map((v) => {
                    return { key: v.key, text: v.text, onClick: () => setView(v) }
                })
            }} />
        </Stack>
        <Stack className='divider'></Stack>
        {!loaded ? <LoadingComponent label={'Retrieving schedule ...'} spinnerPosition='baseline' labelPosition='right' /> : null}
        <Stack className={styles.desktop}>
        {loaded && activities && view.key === 'calendar' ? <DashboardScheduleCalendarView onSelectedDateChange={_onSelectedDateChange} activities={activities} {...selectedDate} /> : null}
        {loaded && activities && view.key === 'list' ? <DashboardScheduleListView onSelectedDateChange={_onSelectedDateChange} activities={activities} {...selectedDate} /> : null}
        </Stack>
        <Stack className={styles.mobile}>
            {loaded && activities ? <DashboardScheduleListView onSelectedDateChange={_onSelectedDateChange} activities={activities} {...selectedDate} /> : null}
        </Stack>
    </Stack>;
});

export default DashboardSchedule;