import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../../../../stores/root';
import { faCheck, faPlus, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ValidationService from '../../../../../services/validation';
import { DatePicker, Dropdown, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import GlobalConfig from '../../../../../config';
import PublicNewsService, { PublicNewsImageService } from '../../../../../services/data/publicNews';
import { Editor } from '@tinymce/tinymce-react';
import DeliveredGoodsType from '../../../../../manifests/deliveredGoodsType';
import moment from 'moment';
import { Upload, UploadFile, UploadProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TurndownService from 'turndown';
import showdown from 'showdown';
import styles from './styles.module.scss';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import ErrorService from '../../../../../services/general/error';
import GeneralService from '../../../../../services/general';
import { IPublicNewsResourceShort } from '../../../../../props/data/news';
import { UploadFileStatus } from 'antd/lib/upload/interface';

const turndownService = new TurndownService();
const converter = new showdown.Converter();

interface INewsFormProps {
    newsId?: string;
}

type FormDataProps = {
    subject: string;
    content: string;
    isFeatured: boolean;
    projectLocation: string;
    goodsType: string;
    projectDate?: string;
}

type FormDataErrorProps = {
    subject?: string
    content?: string
    // isFeatured: string
    projectLocation?: string
}

const NewsForm: React.FC<INewsFormProps> = (props: INewsFormProps) => {
    const { banner } = useStore();
    const editorRef: any = useRef(null);
    const mode: 'create' | 'update' = props.newsId === undefined ? 'create' : 'update';

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [data, setData] = React.useState<FormDataProps>({
        subject: "",
        content: '',
        projectLocation: '',
        isFeatured: false,
        goodsType: '',
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const [deleteImages, setDeleteImages] = useState<string[]>([])


    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            if (props.newsId) {
                const _news = await PublicNewsService.get(props.newsId);
                const htmlString = converter.makeHtml(_news.content);
                setData({
                    subject: _news.subject,
                    content: htmlString,
                    projectLocation: _news.projectLocation || "",
                    isFeatured: _news.isFeatured,
                    goodsType: _news.goodsType || "",
                    projectDate: _news.projectDate
                });
                setFiles(_news);
            }

            setLoaded(true);
        } catch (error) {

        }

    }

    const setFiles = async (_news: IPublicNewsResourceShort) => {
        const promises = _news.images.map(async image => {
            const path = await GeneralService.getFileUrl(image.path);
            return {
                uid: image.id,
                name: image.path,
                status: 'done' as UploadFileStatus,
                url: path,
            };
        });
        Promise.all(promises)
            .then(fileList => setFileList(fileList))
            .catch(error => console.error("Error setting files:", error));
    };


    const isSubmitButtonDisabled = (): boolean => {
        if (error.subject) {
            return true;
        } else if (data.subject.trim() === '') {
            return true;
        }

        if (error.content) {
            return true;
        } else if (data.content.trim() === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        setSubmitting(true);
        try {
            const { content, subject, isFeatured, projectLocation, projectDate, goodsType } = data;

            const fd = new FormData();
            const toMarkdown = turndownService.turndown(content);
            fd.append("content", toMarkdown);
            fd.append("subject", subject);
            fd.append("isFeatured", isFeatured + "");
            fd.append("projectLocation", projectLocation);
            if (projectDate) {
                fd.append("projectDate", projectDate);
            }
            fd.append("goodsType", goodsType);
            deleteImages.length !== 0 && deleteImages.map(d => fd.append('deleteImages[]', d))
            if (fileList.length > 0) {
                fileList.forEach(f => {
                    if (f.originFileObj) {
                        fd.append('images[]', f.originFileObj)
                    }
                });
            }

            if (props.newsId === undefined) {
                await PublicNewsService.new(fd);
            } else {
                await PublicNewsService.update(props.newsId, fd);
                deleteImages.length !== 0 && await PublicNewsImageService.delete(fd)
            }
            banner.add({
                key: 'create_news_success',
                variant: 'success',
                icon: faCheck,
                text: `News "${subject}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            window.location.href = '/settings/news';
        } catch (e) {
            setSubmitting(false);
            banner.add({
                key: mode + "_news_error",
                text: "Failed to " + mode + " public news. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

    const onEditorChange = (a: string, editor: any) => {
        const content = a
        setData({ ...data, content })
    };

    return <Stack tokens={{ childrenGap: 20 }} className={styles.newsForm}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.form}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {loaded ? <>
                <Stack tokens={{ childrenGap: 15 }}>
                    <Stack tokens={{ childrenGap: 5 }}>
                        <Stack>
                            <Label size={'small'}>Is Featured?</Label>
                            <Text size={'small'}>If is featured is yes, this news article will be published in public website and application home screen.</Text>
                        </Stack>
                        <Toggle checked={data.isFeatured}
                            onChange={(event, checked) => {
                                data.isFeatured = checked || false;
                                setData({ ...data });
                            }}
                            onText='Yes'
                            offText='No' />
                    </Stack>
                    <Stack.Item>
                        <Label size={'small'}>Image</Label>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={handleChange}
                            onRemove={f => {
                                const items = [...deleteImages]
                                items.push(f.uid)
                                setDeleteImages(items)
                            }}
                        >
                            {fileList.length >= 8 ? null : <FontAwesomeIcon icon={faPlus} />}
                        </Upload>
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Subject"}
                            required={true}
                            value={data.subject}
                            onChange={(evt, value) => {
                                data.subject = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.subject = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.subject}
                            disabled={submitting}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label size={'small'} required>Article Content</Label>
                        <Editor
                            disabled={submitting}
                            onEditorChange={onEditorChange}
                            value={data.content}
                            apiKey={GlobalConfig.tinymceKey}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'lists'
                                ],
                                toolbar: 'undo redo | formatselect | ' + 'bold italic ' + '| numlist bullist',
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 15 }}>
                    <Stack.Item>
                        <Label showLine>Project Details</Label>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '33%' } }}>
                            <TextField label={"Project Location"}
                                value={data.projectLocation}
                                onChange={(evt, value) => {
                                    data.projectLocation = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.projectLocation = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.projectLocation}
                                disabled={submitting}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '33%' } }}>
                            <Dropdown label={"Goods Type"}
                                options={DeliveredGoodsType}
                                selectedKey={data.goodsType}
                                onChange={(evt, opt) => {
                                    data.goodsType = opt ? opt.key as string : "door";
                                    setData({ ...data });
                                }}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '33%' } }}>
                            <DatePicker
                                disabled={submitting}
                                label='Project Date'
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                value={data.projectDate ? new Date(data.projectDate) : undefined}
                                onSelectDate={(date) => date && setData({ ...data, projectDate: date.toISOString() })}
                                formatDate={GeneralService.formatDate}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </> : null}
        </Stack>
        <Stack horizontal className={styles.footer}>
            {!submitting ? <PrimaryButton text={mode === 'create' ? "Create" : "Update"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} /> : null}
            {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating news ..." : "Updating news ..."} /> : null}
        </Stack>
    </Stack>
};

export default NewsForm;
