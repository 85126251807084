import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import VendorsList from '../../../components/operationals/vendors/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IVendorsListPageProps {
}

const VendorsListPage: React.FC<IVendorsListPageProps> = (props: IVendorsListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Vendors", [
            {key: "home", icon: faHome, href: "/"},
            {key: "operationals", text: 'Operationals'},
            {key: "vendors", text: 'Vendors'}
        ])
    }, []);

    return <Stack>
        <VendorsList />
    </Stack>;
};

export default VendorsListPage;
