import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faCalendar, faLocationDot, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services

// props

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import ErrorService from '../../../../../services/general/error';
import LoadingComponent from '../../../../feedbacks/loading';
import { IPublicNewsResourceShort } from '../../../../../props/data/news';
import PublicNewsService from '../../../../../services/data/publicNews';
import { NavLink } from 'react-router-dom';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import Tag from '../../../../uiframeworks/tag';
import moment from 'moment';
import DeliveredGoodsType from '../../../../../manifests/deliveredGoodsType';
import ReactMarkdown from 'react-markdown';
import DeletePublicNewsForm from '../delete';
import GeneralService from '../../../../../services/general';

interface PublicNewsDetailsProps {
    newsId: string;
    onDismissed(refresh?: boolean): void;
}

const PublicNewsDetails: React.FC<PublicNewsDetailsProps> = (props: PublicNewsDetailsProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [news, setNews] = React.useState<IPublicNewsResourceShort | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoaded(false);
            const _news = await PublicNewsService.get(props.newsId);
            setNews(_news);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_news_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        items.push({
            key: 'update',
            text: 'Update',
            onRender: () => {
                return <NavLink to={'/settings/news/update/' + props.newsId}>
                    <PrimaryButton text={'Update'} iconProps={{ iconName: "Edit" }} />
                </NavLink>
            }
        });

        farItems.push({
            key: 'delete',
            text: 'Delete',
            onRender: () => {
                return <ActionButton iconProps={{ iconName: "Delete" }} text={"Delete"} onClick={() => setActiveSurface('news.delete')} />
            }
        })

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        if (refresh) {
            props.onDismissed(true);
        }
        setActiveSurface(undefined);
    }

    return <Panel headerText={"News Details"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} className={styles.details}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && news ? <>
                <CommandBar {...getCommandBarItems()} styles={{ root: { padding: 0, margin: 0 } }} />
                <Stack tokens={{ childrenGap: 20 }}>
                    <Stack tokens={{ childrenGap: 3 }}>
                        <Label>Is Featured</Label>
                        <Text>{news.isFeatured ? "Yes" : "No"}</Text>
                    </Stack>
                    <Stack tokens={{ childrenGap: 3 }}>
                        <Label>Subject</Label>
                        <Text>{news.subject}</Text>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Label>News Article</Label>
                        {news.projectLocation || news.goodsType || news.projectDate ? <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign={'center'}>
                            {news.projectDate ? <Tag icon={faCalendar} text={moment(news.projectDate).format("DD/MM/YYYY")} /> : null}
                            {news.goodsType && DeliveredGoodsType.find((gt) => gt.key === news.goodsType) ? <Tag icon={DeliveredGoodsType.find((gt) => gt.key === news.goodsType)?.icon} text={DeliveredGoodsType.find((gt) => gt.key === news.goodsType)?.text} /> : null}
                            {news.projectLocation ? <Tag icon={faLocationDot} text={news.projectLocation} /> : null}
                        </Stack> : null}
                        <Stack>
                            <ReactMarkdown>{news.content}</ReactMarkdown>
                        </Stack>
                        {news.images && news.images.length > 0 ? <Stack className={styles.images} horizontal wrap tokens={{childrenGap: 10}}>
                            {news.images.map((image) => {
                                return <Stack className={styles.item} styles={{
                                    root: {background: `url('${GeneralService.getFileUrl(image.path)}')`}
                                }} />
                            })}
                        </Stack> : null}
                    </Stack>
                </Stack>
                {activeSurface === 'news.delete' ? <DeletePublicNewsForm news={news} onDismissed={_onSurfaceDismissed} /> : null}
            </> : null}
        </Stack>
    </Panel>
};

export default PublicNewsDetails;
