import React, { useEffect } from 'react';
import { ActionButton, DatePicker, DefaultButton, Label, NormalPeoplePicker, Panel, PanelType, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { faArrowRight, faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../../../../../stores/root';
import ValidationService from '../../../../../../services/validation';
import { IUserResourceShortProps } from '../../../../../../props/user';
import GeneralService from '../../../../../../services/general';
import authService from '../../../../../../services/users/user';
import ErrorService from '../../../../../../services/general/error';
import moment from 'moment';
import { IEmployedSalaryResourceShort } from '../../../../../../props/users/user';
import { EmployeeSalaryService } from '../../../../../../services/users/employee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserService from '../../../../../../services/users/user';

interface IEmployedSalaryFormProps {
    employeeId?: string;
    onDismissed(refresh?: boolean): void;
    salaryId?: string;
}

type FormDataProps = {
    amount: string
    startDate: string
    endDate: string
    employee?: IUserResourceShortProps;
}

type FormDataErrorProps = {
    amount?: string
}

const EmployedSalaryForm: React.FC<IEmployedSalaryFormProps> = (props: IEmployedSalaryFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [employee, setEmployee] = React.useState<IUserResourceShortProps | undefined>();
    const [data, setData] = React.useState<FormDataProps>({
        amount: '',
        startDate: moment().toISOString(),
        endDate: moment().add(1, 'year').toISOString()
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.salaryId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.salaryId && props.employeeId) {
            const item = await EmployeeSalaryService.get(props.employeeId, props.salaryId);
            const _employee = await UserService.get(item.employeeId);
            setData({
                amount: Number(item.amount) + "",
                startDate: item.startDate,
                endDate: item.endDate,
                employee: _employee
            });
        } else if (props.employeeId) {
            const _employee = await UserService.get(props.employeeId);
            setData({ ...data, employee: _employee });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        const { amount, employee, startDate, endDate } = data
        const { amount: errorAmount } = error
        if (amount.trim() === '') {
            return true
        }
        if (errorAmount !== undefined) {
            return true
        }
        return false;
    }

    const _onSubmit = async () => {
        setSubmitting(true);
        const { amount, startDate, endDate, employee } = data;
        try {

            const fd = new FormData();

            fd.append('employeeId', employee?.id || '')
            fd.append('amount', amount)
            fd.append('startDate', moment(startDate).format('YYYY-MM-DD'));
            endDate !== '' && fd.append('endDate', moment(endDate).format('YYYY-MM-DD'));

            if (props.salaryId === undefined) {
                await EmployeeSalaryService.new(employee?.id || "", fd);
            } else {
                await EmployeeSalaryService.update(employee?.id || "", props.salaryId, fd);
            }
            banner.add({
                key: 'create_employee_salaries_success',
                variant: 'success',
                icon: faCheck,
                text: `Salary for ${employee?.name} added successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchEmployee = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await authService.retrieve(qs.join("&"));

            return results.data.filter((d, idx) => idx < 10).map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_employee_error',
                text: 'Failed to search related employee. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }



    return <Panel headerText={mode === 'create' ? "Add Salary" : "Update Salary"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Adding commission ..." : "Updating commission ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <Label >Employee</Label>
                        {!props.salaryId && !props.employeeId ? <NormalPeoplePicker
                            onResolveSuggestions={_onSearchEmployee}
                            onEmptyResolveSuggestions={() => _onSearchEmployee('')}
                            itemLimit={1}
                            selectedItems={data.employee ? [
                                {
                                    id: data.employee.id,
                                    text: data.employee.name,
                                    secondaryText: data.employee.email,
                                    imageInitials: GeneralService.getInitial(data.employee.name),
                                    metadata: data.employee,
                                } as any,
                            ] : []}
                            disabled={submitting || props.employeeId !== undefined}
                            onChange={(item?: any) => {
                                data.employee = item[0] ? item[0].metadata : undefined;
                                setData({ ...data });

                                return null;
                            }}
                        /> : null}
                        {(props.salaryId || props.employeeId) && data.employee ? <Persona imageInitials={GeneralService.getInitial(data.employee.name)} 
                            text={data.employee.name}
                            size={PersonaSize.size40}
                            secondaryText={data.employee.email}
                            styles={{root: {marginTop: 4}}} /> : null}
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            required
                            label={"Amount"}
                            type='number'
                            prefix='RP'
                            value={data.amount}
                            onChange={(evt, value) => {
                                data.amount = value || "";
                                const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 20 });
                                error.amount = validation.message;
                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.amount}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <DatePicker value={moment(data.startDate).toDate()}
                                label={'Start from'}
                                formatDate={GeneralService.formatDate}
                                onSelectDate={(date) => {
                                    data.startDate = moment(date).toISOString();
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                        <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: 45 }} />
                        <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: '50%' } }}>
                            <Stack.Item grow={1}>
                                <DatePicker value={data.endDate ? moment(data.endDate).toDate() : undefined}
                                    label={'Until'}
                                    formatDate={GeneralService.formatDate}
                                    onSelectDate={(date) => {
                                        data.endDate = date ? moment(date).toISOString() : data.endDate;
                                        setData({ ...data });
                                    }} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default EmployedSalaryForm;
