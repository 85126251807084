import { CommandBar, PrimaryButton, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import DetailsStack from '../../../components/uiframeworks/detailsStack';
import { NavLink } from 'react-router-dom';

interface HRPayrollPageProps {
}

const HRPayrollPage: React.FC<HRPayrollPageProps> = (props: HRPayrollPageProps) => {
    const store = useStore();


    useEffect(() => {
        store.topbar.show('Payroll', [
            { key: 'home', icon: faHome },
            { key: 'payroll', text: 'Payroll' }
        ]);
    }, []);



    return <Stack>
        <CommandBar items={[
            {
                key: 'create',
                text: 'Create Payslip',
                onRender: () => {
                    return <NavLink to={'/hr/payroll/salaryslip'}>
                        <PrimaryButton text={'Create Payslip'} iconProps={{ iconName: "Add" }} />
                    </NavLink>;
                }
            }
        ]} />
    </Stack>;
};

export default HRPayrollPage;
