import { IPersonaProps, Persona } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import DocumentService from '../../../services/documents/document';

const CustomPersona: React.FC<IPersonaProps> = (props) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  const getIamgeData = async () => {
    if (props.imageUrl)
      try {
        const blob = (await DocumentService.get(props.imageUrl)).data.data
        setImageSrc(blob)
      } catch (error) {
        console.log(error)
      }
  }

  useEffect(() => {
    getIamgeData()
    return () => {

    }
  }, [])

  return <Persona {...props} imageUrl={imageSrc && `data:image/jpeg;base64,${imageSrc}`} />
};

export default CustomPersona;