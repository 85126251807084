import { ActionButton, IButtonProps, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { } from 'react';

// assets

import styles from './styles.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './../../typography/text';
import Label from '../../typography/label';

export type TTagVariant = 'default' | 'success' | 'light' | 'error' | 'warning' | 'transparent';

type IDetailsStackProps = {
    left: IStackSectionProps[];
    right?: IStackSectionProps | IStackSectionProps[];
};

export type IStackDefaultSectionProps = {
    key: string;
    header?: {
        title?: string;
        buttons?: IButtonProps[];
        onRender?(): JSX.Element;
    };
    tabs?: ISectionTabProps[]
    isCard?: boolean;
    content?: JSX.Element;
    width?: string;
}

export type IStackSectionProps = IStackDefaultSectionProps & {
    subsections?: IStackDefaultSectionProps[];
}

export type ISectionTabProps = {
    key: string;
    title: string;
    content?: JSX.Element;
}

const DetailsStack: React.FC<IDetailsStackProps> = (props: IDetailsStackProps) => {
    const renderSection = (section: IStackDefaultSectionProps, width?: string) => {
        return <Stack className={`${section.isCard ? styles.card : ""} ${section.tabs && section.tabs.length > 0 ? styles.hasTab : ''}`} styles={{ root: { width } }} tokens={{ childrenGap: 10 }}>
            {section.header ? <Stack className={styles.header} horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                {section.header.title ? <Label>{section.header.title}</Label> : null}
                {section.header.buttons ? <Stack horizontal tokens={{childrenGap: 10}} verticalAlign='center'>
                    {section.header.buttons.map((button, idx) => <ActionButton styles={{root: {height: '100% !important', minHeight: 'unset !important'}}} key={`${section.key}_buttons_${idx}`} {...button} />)}
                </Stack> : null}
                {section.header.onRender ? section.header.onRender() : null}
            </Stack> : null}
            {section.tabs && section.tabs.length > 0 ? <Pivot className={'linePivot'}>
                {section.tabs.map((tab) => {
                    return <PivotItem key={tab.key} headerText={tab.title}>
                        <Stack className={styles.tabContent}>{tab.content}</Stack>
                    </PivotItem>
                })}
            </Pivot> : null}
            {section.content ? section.content : null}
        </Stack>;
    }

    return <Stack className={styles.container} horizontal={props.right != undefined} tokens={{ childrenGap: 20 }}>
        <Stack className={styles.left} grow={1} tokens={{ childrenGap: 20 }} >
            {props.left.map((se) => {
                if (se.subsections && se.subsections.length > 0) {
                    return <Stack horizontal tokens={{ childrenGap: 20 }}>
                        {se.subsections.map((subse) => renderSection(subse, subse.width || `${100 / (se.subsections || []).length}%`))}
                    </Stack>;
                } else {
                    return renderSection(se, se.width);
                }
            })}
        </Stack>
        {props.right ? <Stack className={styles.right} tokens={{ childrenGap: 20 }} >
            {Array.isArray(props.right) ? null : renderSection(props.right, props.right.width)}
            {Array.isArray(props.right) ? props.right.map((se) => renderSection(se, se.width)) : null}
        </Stack> : null}
    </Stack>
};

export default DetailsStack;
