import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IOrderResourcerShorterProps, IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import { TSelectedFilterKey } from '.';
import { IOrderStatusResourceShortProps } from '../../../../../props/orders/orderStatus';

type OrdersDashboardChartFilterStatusProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: IOrderStatusResourceShortProps[]): void;
}

const OrdersDashboardChartFilterStatus: React.FC<OrdersDashboardChartFilterStatusProps> = (props: OrdersDashboardChartFilterStatusProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IOrderStatusResourceShortProps[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniqueStatus = (): IOrderStatusResourceShortProps[] => {
        const status: IOrderStatusResourceShortProps[] = [];

        props.orders.forEach((order) => {
            const exist = status.find((c) => c.id === order.status.id);
            if (!exist && order.status) {
                status.push(order.status);
            }
        });

        return status;
    }

    const renderOption = (status: IOrderStatusResourceShortProps) => {
        const isChecked = selected.findIndex((s) => s.id === status.id) > -1;
        const totalItems = props.filteredOrders.filter((o) => o.status.id === status.id).length;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, status)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{status.name} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, status: IOrderStatusResourceShortProps) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== status.id);
        } else {
            _selected.push(status);
        }

        setSelected([..._selected]);
        props.onChange('status', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Order status</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search status ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueStatus().filter((c) => c.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((status) => {
                return renderOption(status);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterStatus;
