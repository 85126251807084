import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import DeliveryTypesList from '../../../components/settings/deliveryTypes/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IDeliveryTypesListPageProps {
}

const DeliveryTypesListPage: React.FC<IDeliveryTypesListPageProps> = (props: IDeliveryTypesListPageProps) => {
    const {topbar} = useStore();

    useEffect(() => {
        topbar.show("Freight Categories", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "deliveryTypes", text: 'Delivery Types'}
        ])
    }, []);

    return <Stack>
        <DeliveryTypesList />
    </Stack>;
};

export default DeliveryTypesListPage;
