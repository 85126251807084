import { IOutcomeOrderResourceShortProps } from '../../../../props/finance/outcomes/orders';
import Request from './../../../request';

const OutcomeOrderService = {
    retrieve: async (invoiceId: string, qs?: string):Promise<IOutcomeOrderResourceShortProps[]> => {
        try {
            const result = await Request.get(`/api/outcomes/invoices/${invoiceId}/orders?${qs || ''}`);
            return result;
        } catch(e) {
            throw(e);
        }
    }
};

export default OutcomeOrderService;