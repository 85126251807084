import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { ActionButton, CommandBar, DetailsList, Dropdown, IColumn, Link, MessageBar, MessageBarType, PrimaryButton, SelectionMode, Stack } from '@fluentui/react';
import { FormDataProps } from '../../props';
import FileService from '../../../../../../../../services/general/file';
import OutcomeService from '../../../../../../../../services/finance/outcomes';
import LoadingComponent from '../../../../../../../feedbacks/loading';
import Label from '../../../../../../../typography/label';
import Text from '../../../../../../../typography/text';
import GeneralService from '../../../../../../../../services/general';
import OutcomeTypes from '../../../../../../../../manifests/outcomeTypes';
import InvoiceDetailsSubform from '../details';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faCircleExclamation, faClose, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { IVendorResourceShortProps } from '../../../../../../../../props/data/vendors';
import SimpleMessageBarComponent from '../../../../../../../feedbacks/simpleMessageBar';
import Tag from '../../../../../../../uiframeworks/tag';


type InvoiceListSubformProps = {
    disabled?: boolean;
    data: Partial<FormDataProps>[];
    onChange(data: Partial<FormDataProps>[]): void;
    onRetry(data: Partial<FormDataProps>): void;
}

const InvoiceListSubform: React.FC<InvoiceListSubformProps> = (props: InvoiceListSubformProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Partial<FormDataProps>[]>(props.data || []);
    const [selectedData, setSelectedData] = React.useState<Partial<FormDataProps> | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: 'invoiceNumber',
            name: 'Invoice #',
            minWidth: 175,
            maxWidth: 175,
            isMultiline: true,
            onRender: (item: Partial<FormDataProps>) => {
                const content = <>
                    {item.invoiceNumber ? <Text size={'small'}>#{item.invoiceNumber}</Text> : null}
                    {!item.invoiceNumber ? <Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 5 }}>
                        <FontAwesomeIcon className={'color-red'} icon={faExclamationTriangle} size={'sm'} style={{ marginTop: -2 }} />
                        <Text className={'color-red'} size={'small'}>Invoice number is required</Text>
                    </Stack> : null}
                </>;

                return <Stack>
                    {!item.systemSubmission ? <Link onClick={() => {setActiveSurface('details');setSelectedData(item);}}>{content}</Link> : null}
                    {item.systemSubmission ? <Link href={`/finance/outcomes/invoices/${item.id}`} target={"_blank"}>{content}</Link> : null}
                </Stack>
            }
        },
        {
            key: 'name',
            name: 'Name',
            minWidth: 200,
            isMultiline: true,
            onRender: (item: Partial<FormDataProps>) => {
                const type = OutcomeTypes.find((ot) => ot.key.toLowerCase() === (item.type || "").toLowerCase());
                return <Stack>
                    {type ? <Label size={'xsmall'}>{type.text}</Label> : null}
                    <Text size={'small'}>{item.name || item.systemFile?.name || "-"}</Text>
                </Stack>
            }
        },
        {
            key: 'vendor',
            name: 'Vendor',
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            onRender: (item: Partial<FormDataProps>) => {
                const type = OutcomeTypes.find((ot) => ot.key.toLowerCase() === (item.type || "").toLowerCase());
                return <Stack>
                    {item.vendor ? <Text size={'small'}>{item.vendor.name}</Text> : null}
                    {!item.vendor ? <Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 5 }}>
                        <FontAwesomeIcon className={'color-red'} icon={faExclamationTriangle} size={'sm'} style={{ marginTop: -2 }} />
                        <Text className={'color-red'} size={'small'}>Vendor is required</Text>
                    </Stack> : null}
                </Stack>
            }
        },
        {
            key: 'amount',
            name: 'Amount',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: Partial<FormDataProps>) => {
                return <Stack>
                    <Label size={'xsmall'}>Rp. {GeneralService.getNumberWithSeparator(item.totalAmount || 0)}</Label>
                    {item.includePPn && Number(item.ppn || "0") > 0 ? <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>(include PPn Rp. {GeneralService.getNumberWithSeparator(item.ppn || 0)})</Text> : null}
                    {item.includePPh && Number(item.pph || "0") > 0 ? <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>(include PPh Rp. {GeneralService.getNumberWithSeparator(item.pph || 0)})</Text> : null}
                    {!item.includePPn && Number(item.ppn || "0") > 0 ? <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>(exclude PPn Rp. {GeneralService.getNumberWithSeparator(item.ppn || 0)})</Text> : null}
                    {!item.includePPh && Number(item.pph || "0") > 0 ? <Text size={'xsmall'} style={{ fontStyle: 'italic' }}>(exclude PPh Rp. {GeneralService.getNumberWithSeparator(item.pph || 0)})</Text> : null}
                </Stack>
            }
        },
        {
            key: 'dueDate',
            name: 'Due Date',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            onRender: (item: Partial<FormDataProps>) => {
                return <Stack>
                    {item.dueDate ? <Text size={'small'}>{GeneralService.getFriendlyDateFormat(item.dueDate)}</Text> : null}
                </Stack>
            }
        },
        {
            key: 'status',
            name: 'Status',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: Partial<FormDataProps>) => {
                return <>
                    {!item.systemSubmission ? <>
                        {item.systemVerified ? <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                            <FontAwesomeIcon icon={faCircleCheck} className={'color-green'} size={'sm'} style={{ marginTop: -2 }} />
                            <Text className={'color-green'} size={'small'}>Verified</Text>
                        </Stack> : null}
                        {!item.systemVerified ? <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                            <FontAwesomeIcon icon={faCircleExclamation} className={'color-red'} size={'sm'} style={{ marginTop: -2 }} />
                            <Text className={'color-red'} size={'small'}>Not verified</Text>
                        </Stack> : null}
                    </> : null}
                    {item.systemSubmission?.loading ? <>
                        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                            <LoadingComponent label={'Submitting ...'} labelPosition={'right'} spinnerPosition={'baseline'} />
                        </Stack>
                    </> : null}
                    {!item.systemSubmission?.loading && item.systemSubmission?.success ? <>
                        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                            <Tag variant={'success'} text={"Submitted"} size={'small'} />
                        </Stack>
                    </> : null}
                    {!item.systemSubmission?.loading && !item.systemSubmission?.success && item.systemSubmission?.error ? <>
                        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                            <Tag variant={'error'} text={"Failed"} size={'small'} />
                        </Stack>
                    </> : null}
                </>
            }
        },
        {
            key: 'action',
            name: '',
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: Partial<FormDataProps>) => {
                return <Stack.Item className={"detailsListActionRow"}>
                    {!item.systemSubmission ? <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                        if (window.confirm(`Are you sure to delete this invoice${item.invoiceNumber ? ' #' + item.invoiceNumber : ""}?`)) {
                            const _data = data;
                            const idx = _data.findIndex((d) => d.id === item.id);
                            if (idx > -1) {
                                _data.splice(idx, 1);
                                props.onChange([..._data]);
                            }
                        }
                    }} /> : null}
                    {item.systemSubmission?.error ? <ActionButton className={'detailsListActionButton'} text={'Retry'} iconProps={{ iconName: "Refresh" }} onClick={() => {
                        props.onRetry(item);
                    }} /> : null}
                </Stack.Item>
            }
        }
    ]

    useEffect(() => {
        init();
        //setLoaded(true);
    }, []);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const extract = async (file?: File): Promise<{
        invoiceNumber?: string;
        totalAmount?: number;
        subtotalAmount?: number;
        name?: string;
        dueDate?: string;
        createdDate?: string;
        ppnAmount?: number;
        pphAmount?: number;
        includePPn?: boolean;
        includePPh?: boolean;
        ppnPercentage?: number;
        pphPercentage?: number;
        vendor?: IVendorResourceShortProps;
    } | undefined> => {
        try {
            if (file) {
                let images: string[] = [];

                const base64 = FileService.arrayBufferToBase64((await file.arrayBuffer()));
                const extension = file.name.toLowerCase().split(".").pop();

                if (extension === 'pdf') {
                    const b64s = await FileService.convertPdfToImage(base64);
                    images = [...images, ...b64s];
                } else if (['jpg', 'jpeg', 'png'].indexOf(extension || "") > 0) {
                    const b64 = `data:image/${extension};base64,${base64}`;
                    images.push(b64);
                }

                const fd = new FormData();
                images.forEach((img) => fd.append("base64[]", img));

                const result = await OutcomeService.extract(fd);
                return result;
            }

            return;
        } catch (e) {
            return;
        }
    }

    const init = async () => {
        setLoaded(false);

        const _data = await Promise.all((data || []).map(async (d, idx) => {
            try {
                const result = await extract(d.systemFile);

                let _data: Partial<FormDataProps> = {
                    ...d,
                    id: d.id || GeneralService.guid(),
                    systemVerified: false,
                    originalAmount: (result?.subtotalAmount || result?.totalAmount || 0) + "",
                    invoiceNumber: result?.invoiceNumber || "",
                    name: result?.name || "",
                    dueDate: result?.dueDate || moment().add(3, 'days').toISOString(),
                    invoiceDate: result?.createdDate || moment().toISOString(),
                    ppn:(result?.ppnAmount || 0) + "",
                    pph: (result?.pphAmount || 0) + "",
                    ppnPercentage: (result?.ppnPercentage || "") + "",
                    pphPercentage: (result?.pphPercentage || "") + "",
                    includePPh: result?.includePPn || result?.includePPh || false,
                    includePPn: result?.includePPn || false,
                    vendor: result?.vendor,
                    aiGenerated: JSON.stringify(result || {})
                }
                const _price = OutcomeService.calculatePrice(_data);

                return {
                    ..._data,
                    totalAmount: (_price.totalAmount || 0) + "",
                    amount: (_price.amount || 0) + ""
                }
            } catch (e) {
                return d;
            }
        }))

        props.onChange(_data);
        setLoaded(true);
    }

    const _onSurfaceDismissed = (details?: Partial<FormDataProps>) => {
        if (details) {
            let _data = data;
            const idx = _data.findIndex((d) => d?.id === details?.id);
            if (idx > -1) {
                _data[idx] = details;
                props.onChange(_data);
            }
        }

        setActiveSurface(undefined);
        setSelectedData(undefined);
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <Stack styles={{ root: { padding: 20 } }}><LoadingComponent label={''} /></Stack> : null}
        {loaded ? <Stack tokens={{ childrenGap: 10 }}>
            {data.findIndex((d) => !d.systemVerified) > -1 ? <SimpleMessageBarComponent properties={{
                type: MessageBarType.warning,
                content: <Text size={'small'} style={{ fontStyle: 'italic' }}>All invoices need to be verified before submitting. Click on invoice number to verify details.</Text>
            }} /> : null}
            <SimpleMessageBarComponent properties={{
                type: MessageBarType.info,
                content: <Text size={'small'} style={{ fontStyle: 'italic' }}>Click on invoice number to view/update invoice details</Text>
            }} />
            <Stack>
                <CommandBar items={[
                    {
                        key: 'add',
                        onRender: () => {
                            return <PrimaryButton text={'Add invoice'} 
                                disabled={data.findIndex((d) => d.systemSubmission) > -1}
                                iconProps={{iconName: 'Add'}} onClick={() => {
                                const ndata: Partial<FormDataProps> = {
                                    id: GeneralService.guid(),
                                    ppnPercentage: '0',
                                    pphPercentage: '0',
                                    dueDate: moment().toISOString()
                                };

                                const _data = data;
                                _data.push(ndata);

                                props.onChange(_data);
                                setSelectedData(ndata);
                                setActiveSurface("details");
                            }} />
                        }
                    }
                ]} styles={{root: {padding: 0, margin: 0}}} />
                <DetailsList items={data}
                    columns={columns}
                    selectionMode={SelectionMode.none} />
            </Stack>
        </Stack> : null}
        {activeSurface && selectedData ? <InvoiceDetailsSubform data={selectedData} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>
};

export default InvoiceListSubform;
