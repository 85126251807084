import React, { useEffect } from 'react';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Dropdown, IDropdownOption, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import RoleService from '../../../../../services/role';
import ValidationService from '../../../../../services/validation';
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import SubsidiariesService from '../../../../../services/data/subsidiaries';
import ISalesTeamResourceShort from '../../../../../props/users/team';
import GeneralService from '../../../../../services/general';
import TeamService from '../../../../../services/users/team';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';
import Label from '../../../../typography/label';
import { IInternalBankAccountResourceProps } from '../../../../../props/data/bankaccounts/internal';
import InternalBankAccountService from '../../../../../services/data/bankaccounts/internal';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import SelectBank from '../../../../uiframeworks/forms/selectBank';

type InternalBankAccountFormProps = {
    id?: string;
    subsidiaryId?: string;
    onDismissed(refresh?: boolean, item?: IInternalBankAccountResourceProps): void;
    team?: ISalesTeamResourceShort
}

const InternalBankAccountForm: React.FC<InternalBankAccountFormProps> = (props: InternalBankAccountFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IInternalBankAccountResourceProps>>({});
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.id === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.id) {
            const _data = await InternalBankAccountService.read(props.id);
            setData(_data);
        } else if (props.subsidiaryId) {
            const _subsidiary = await SubsidiariesService.get(props.subsidiaryId);
            setData({subsidiary: _subsidiary, accountName: _subsidiary.name});
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.accountName || error.accountNumber || error.subsidiary || error.bank) {
            return true;
        } else if ((data.accountName || "").trim() === '' || (data.accountNumber || "").trim() === '' || !data.bank || !data.subsidiary) {
            return true;
        }
        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { accountName, accountNumber, bank, subsidiary, bankCity } = data;

            // create form data
            let fd = new FormData();
            fd.append("accountName", accountName || "");
            fd.append("accountNumber", accountNumber || "");
            fd.append("bankId", bank?.id || "");
            fd.append("subsidiaryId", subsidiary?.id || "");
            fd.append("bankCity", bankCity || "");

            let result;
            if (props.id === undefined) {
                result = await InternalBankAccountService.create(fd);
            } else {
                result = await InternalBankAccountService.update(props.id, fd);
            }

            banner.add({
                key: 'create_internal_bankaccount_success',
                variant: 'success',
                icon: faCheck,
                text: `Internal bank account "${accountName}(${accountNumber})" created successfully`
            });
            
            props.onDismissed(true, result);
        } catch (e) {
            setSubmitting(false);
        }
    }


    return <Panel headerText={mode === 'create' ? "Create Bank Account" : "Update Bank Account"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating bank account ..." : "Updating bank account ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <SelectSubsidiary label={'Subsidiary'}
                        required={true}
                        selected={data.subsidiary}
                        errorMessage={error.subsidiary}
                        disabled={submitting || props.subsidiaryId !== undefined}
                        onChange={(subsidiary) => {
                            data.subsidiary = subsidiary;
                            data.accountName = (data.accountName || "").trim() === "" ? subsidiary?.name : data.accountName;

                            const validation = ValidationService.combination(subsidiary, ['required'], {});
                            error.subsidiary = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} />
                    <Stack.Item>
                        <TextField
                            label={"Account name"}
                            required={true}
                            value={data.accountName || ""}
                            onChange={(evt, value) => {
                                data.accountName = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={"Account number"}
                            required={true}
                            value={data.accountNumber || ""}
                            onChange={(evt, value) => {
                                data.accountNumber = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.accountNumber}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <SelectBank label={'Select bank'}
                        required={true}
                        selected={data.bank}
                        errorMessage={error.bank}
                        disabled={submitting}
                        onChange={(bank) => {
                            data.bank = bank;

                            const validation = ValidationService.combination(bank, ['required'], {});
                            error.bank = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} />
                    <Stack.Item>
                        <TextField
                            label={"Bank city"}
                            value={data.bankCity || ""}
                            onChange={(evt, value) => {
                                data.bankCity = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.bankCity}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default InternalBankAccountForm;
