import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faCheck, faClose, faHashtag, faIdCard, faMemoCircleCheck, faUpload } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import VendorsService from '../../../../../services/data/vendors';

// props
import { FormDataProps } from '.';
import { IRoleResourceShortProps } from '../../../../../props/users/role';

// components
import { ActionButton, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import ErrorService from '../../../../../services/general/error';
import NoAccess from '../../../../uiframeworks/noAccess';
import { IVendorResourceProps } from '../../../../../props/data/vendors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import GeneralService from '../../../../../services/general';
import FileService from '../../../../../services/general/file';
import LoadingComponent from '../../../../feedbacks/loading';
import LoadingDialogComponent from '../../../../feedbacks/loadingDialog';
import { IDocumentProps } from '../../../../../props/general';
import DocumentService from '../../../../../services/documents/document';

type VendorDocumentsFormProps = {
    data: Partial<FormDataProps>;
    onChange(data: Partial<FormDataProps>): void;
}

const VendorDocumentsForm: React.FC<VendorDocumentsFormProps> = (props: VendorDocumentsFormProps) => {
    const { banner, user } = useStore();
    const [data, setData] = React.useState<Partial<FormDataProps>>(props.data);
    const [preview, setPreview] = React.useState<string[] | undefined>();
    const [loadingPreview, setLoadingPreview] = React.useState<boolean>(false);
    const [extracting, setExtracting] = React.useState<boolean>(false);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const _onSelectFile = async (file: FileList | File[], type: 'npwp' | 'nib' | 'ownerid') => {
        if (file[0]) {
            if (type === 'npwp') {
                setExtracting(true);
                data.systemNPWPFile = file[0];
                const npwp = await _onExtractNPWP(file[0]);
                data.name = (npwp?.name || "").trim() !== "" ? npwp?.name : data.name;
                data.npwp = (npwp?.taxNumber || "").trim() !== "" ? npwp?.taxNumber : data.npwp;
                data.address = (npwp?.address || "").trim() !== "" ? npwp?.address : data.address;

                setExtracting(false);
            } else if (type === 'ownerid') {
                data.systemOwnerIdFile = file[0];
            } else if (type === 'nib') {
                data.systemNIBFile = file[0];
            }

            props.onChange(data);
        }
    }

    const _onExtractNPWP = async (file: File): Promise<{
        name?: string;
        taxNumber?: string;
        address?: string;
    } | undefined> => {
        try {
            const images = await FileService.getBase64(file);

            const fd = new FormData();
            images.forEach((img) => fd.append("base64[]", img));
            fd.append('type', 'npwp');

            const result = await VendorsService.extractDocument(fd);
            return result;
        } catch (e) {
            return;
        }
    }

    const _onRemoveFile = (type: 'npwp' | 'nib' | 'ownerid') => {
        if (type === 'npwp') {
            data.systemNPWPFile = undefined;
            data.existingNPWPFile = undefined;
        } else if (type === 'nib') {
            data.systemNIBFile = undefined;
            data.existingNIBFile = undefined;
        } else if (type === 'ownerid') {
            data.systemOwnerIdFile = undefined;
            data.existingOwnerIdFile = undefined;
        }

        props.onChange(data);
    }

    const _onPreviewFile = async (type: 'npwp' | 'nib' | 'ownerid') => {
        let systemFile: File | undefined = undefined;
        let existingFile: IDocumentProps | undefined = undefined;
        let preview: string[] = [];
        
        if (type === 'npwp') {
            systemFile = data.systemNPWPFile;
            existingFile = data.existingNPWPFile;
        } else if (type === 'ownerid') {
            systemFile = data.systemOwnerIdFile;
            existingFile = data.existingOwnerIdFile;
        } else if (type === 'nib') {
            systemFile = data.systemNIBFile;
            existingFile = data.existingNIBFile;
        }

        setPreview(undefined);
        setLoadingPreview(true);
        if (existingFile) {
            const base64 = (await DocumentService.get(encodeURIComponent(existingFile.path))).data.data;
            preview = await FileService.getImageBase64(base64, existingFile.path.split(".").pop() || "");
        } else if (systemFile) {
            preview = await FileService.getBase64(systemFile);
        }
        setLoadingPreview(false);
        setPreview(preview);
    }

    return <Stack className={styles.documents} tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            {!data.systemNPWPFile && !data.existingNPWPFile ? <label htmlFor={'uploadNPWP'} className={styles.uploadButton}>
                <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faHashtag} fontSize={20} />
                    <Stack>
                        <Text size={"small"}>Upload</Text>
                        <Text style={{ fontWeight: 600, marginTop: -3 }}>NPWP</Text>
                    </Stack>
                </Stack>
                <input type="file" id="uploadNPWP" style={{ display: 'none' }} onChange={(evt) => _onSelectFile(evt?.target.files || [], 'npwp')}></input>
            </label> : null}
            {data.systemNPWPFile || data.existingNPWPFile ? <Stack className={styles.uploadButton} onClick={() => _onPreviewFile('npwp')}>
                <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faHashtag} fontSize={20} />
                    <Stack horizontal tokens={{childrenGap: 5}} horizontalAlign={'space-between'} verticalAlign={'center'}>
                        <Stack>
                            <Text size={'xsmall'} style={{fontWeight: 600}}>NPWP</Text>
                            <Text size={'xsmall'} className={styles.fileName}>{data.systemNPWPFile?.name || data.existingNPWPFile?.basename || ""}</Text>
                        </Stack>
                        <ActionButton onClick={() => _onRemoveFile('npwp')} iconProps={{iconName: "Delete"}} styles={{root: {marginRight: -15, paddingLeft: "0px !important"}}} />
                    </Stack>
                </Stack>
            </Stack> : null}
            {!data.systemOwnerIdFile && !data.existingOwnerIdFile ? <label htmlFor={'uploadOwnerId'} className={styles.uploadButton}>
                <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faIdCard} fontSize={20} />
                    <Stack>
                        <Text size={"small"}>Upload</Text>
                        <Text style={{ fontWeight: 600, marginTop: -3 }}>Owner Id</Text>
                    </Stack>
                </Stack>
                <input type="file" id="uploadOwnerId" style={{ display: 'none' }} onChange={(evt) => _onSelectFile(evt?.target.files || [], 'ownerid')}></input>
            </label> : null}
            {data.systemOwnerIdFile || data.existingOwnerIdFile ? <Stack className={styles.uploadButton} onClick={() => _onPreviewFile('ownerid')}>
                <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faHashtag} fontSize={20} />
                    <Stack horizontal tokens={{childrenGap: 5}} horizontalAlign={'space-between'} verticalAlign={'center'}>
                        <Stack>
                            <Text size={'xsmall'} style={{fontWeight: 600}}>Owner ID</Text>
                            <Text size={'xsmall'} className={styles.fileName}>{data.systemOwnerIdFile?.name || data.existingOwnerIdFile?.basename}</Text>
                        </Stack>
                        <ActionButton onClick={() => _onRemoveFile('ownerid')} iconProps={{iconName: "Delete"}} styles={{root: {marginRight: -15, paddingLeft: "0px !important"}}} />
                    </Stack>
                </Stack>
            </Stack> : null}
            {!data.systemNIBFile && !data.existingNIBFile ? <label htmlFor={'uploadNIB'} className={styles.uploadButton}>
                <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faMemoCircleCheck} fontSize={20} />
                    <Stack>
                        <Text size={"small"}>Upload</Text>
                        <Text style={{ fontWeight: 600, marginTop: -3 }}>NIB</Text>
                    </Stack>
                </Stack>
                <input type="file" id="uploadNIB" style={{ display: 'none' }} onChange={(evt) => _onSelectFile(evt?.target.files || [], 'nib')}></input>
            </label> : null}
            {data.systemNIBFile || data.existingNIBFile ? <Stack className={styles.uploadButton} onClick={() => _onPreviewFile('nib')}>
                <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign={'center'}>
                    <FontAwesomeIcon icon={faHashtag} fontSize={20} />
                    <Stack horizontal tokens={{childrenGap: 5}} horizontalAlign={'space-between'} verticalAlign={'center'}>
                        <Stack>
                            <Text size={'xsmall'} style={{fontWeight: 600}}>NIB</Text>
                            <Text size={'xsmall'} className={styles.fileName}>{data.systemNIBFile?.name || data.existingNIBFile?.basename}</Text>
                        </Stack>
                        <ActionButton onClick={() => _onRemoveFile('nib')} iconProps={{iconName: "Delete"}} styles={{root: {marginRight: -15, paddingLeft: "0px !important"}}} />
                    </Stack>
                </Stack>
            </Stack> : null}
        </Stack>
        <Stack className={styles.preview}>
            {loadingPreview ? <LoadingComponent label={'Generating file preview ...'} size={SpinnerSize.medium} /> : null}
            {preview && !loadingPreview ? preview.map((preview) => {
                return <img src={preview} className={styles.image} />;
            }) : null}
            {!preview && !loadingPreview ? <Text size={'small'} className={'color-white'}>No document selected</Text> : null}
        </Stack>
        {extracting ? <LoadingDialogComponent title='Extracting Document' secondaryText="Please wait while we extract document details ..." /> : null}
    </Stack>
};

export default VendorDocumentsForm;
