import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GoodsService from '../../../../services/goods/goods';

// props
import { IGoodsResourceShortProps } from '../../../../props/goods/goods';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import GeneralService from '../../../../services/general';
import ErrorService from '../../../../services/general/error';
import LoadingComponent from '../../../feedbacks/loading';

interface IGoodsDetailsProps {
    goodsId: string;
    onDismissed(refresh?: boolean): void;
}

const GoodsDetails: React.FC<IGoodsDetailsProps> = (props: IGoodsDetailsProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [goods, setGoods] = React.useState<IGoodsResourceShortProps | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _goods = await GoodsService.get(props.goodsId);
            setGoods(_goods);
            setLoaded(true);
        } catch (error) {
            banner.add({
                key: "get_goods_details_error",
                text: "Sorry, we encounter an issue. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    return <Panel headerText={goods ? goods.name : "Goods Details"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text={"Close"} onClick={() => { props.onDismissed(false) }} />
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Retrieving details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && goods ? <>
                <Stack.Item>
                    <Label>Name</Label>
                    <Text>{goods.name}</Text>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Owned By (Company)</Label>
                        <Text>{goods.company?.name || "-"}</Text>
                    </Stack.Item>
                    <Stack.Item >
                        <Label>Customer (PIC)</Label>
                        <Text>{goods.user.name}</Text>
                    </Stack.Item>
                </Stack>
                <Stack className='divider'></Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Quantity</Label>
                        <Text>{GeneralService.getNumberWithSeparator(goods.quantityAmount || "0")} {goods.quantityUnit || ""}</Text>
                    </Stack.Item>
                    {goods.weightAmount ? <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Weight (per quantity)</Label>
                        <Text>{GeneralService.getNumberWithSeparator(goods.weightAmount || "0")} {goods.weightUnit || ""}</Text>
                    </Stack.Item> : null}
                    {!goods.weightAmount ? <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Weight (per quantity)</Label>
                        <Text>-</Text>
                    </Stack.Item> : null}
                </Stack>
                <Stack className='divider'></Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Warehouse</Label>
                        <Text>{goods.warehouse?.name || "-"}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Location Details</Label>
                        <Text>{goods.locationDetails || "-"}</Text>
                    </Stack.Item>
                </Stack>
            </> : null}
        </Stack>
    </Panel>
};

export default GoodsDetails;
