import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import TrackingTypes from '../../../../../manifests/trackingTypes';
import { useStore } from '../../../../../stores/root';

// assets
import { faArrowRight, faBoxArchive, faCheck, faContainerStorage, faShip, faTruck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import OrderService from '../../../../../services/orders/order';
import OrderTrackingService from '../../../../../services/orders/trackings/orderTracking';

// props
import { IOrderTrackingResourceShortProps } from '../../../../../props/orders/trackings/orderTracking';

// components
import { ActionButton, CommandBar, DefaultButton, Dropdown, ICommandBarItemProps, IContextualMenuItem, IDropdownOption, Link, PrimaryButton, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import Tag, { TTagVariant } from '../../../../uiframeworks/tag';
import { IOrderResourceProps } from '../../../../../props/orders/order';
import Label from '../../../../typography/label';
import LoadingComponent from '../../../../feedbacks/loading';
import OrderTrackingForm from '../form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import DeleteOrderTrackingForm from '../delete';
import { IOrderContainerResourceShortProps } from '../../../../../props/orders/containers';
import OrderContainerDetails from '../../../containers/general/details';
import { NavLink } from 'react-router-dom';
import { IOrderTruckResourceShortProps } from '../../../../../props/orders/trucks';
import OrderTruckDetails from '../../../trucks/general/details';
import OrderTrackingStatusForm from '../status';
import TrainScheduleStatusForm from '../../../../operationals/trains/schedules/general/status';
import ShipScheduleStatusForm from '../../../../operationals/ships/schedules/general/status';
import UploadFilesPanel from '../../../../uiframeworks/files/uploads/panel';
import { IFileDetailsProps } from '../../../../../props/general';
import SortOrderTrackingForm from '../form/sort';
import OrderTrackingDetailsCard from '../details/card';
import PermissionsService from '../../../../../services/permissions';

interface IOrderTrackingsListProps {
    qs?: string[];
    orderId: string;
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateTracking?: boolean;
    variant?: 'card' | 'plain';
}

const OrderTrackingsList: React.FC<IOrderTrackingsListProps> = (props: IOrderTrackingsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [order, setOrder] = React.useState<IOrderResourceProps | undefined>();
    const [trackings, setTrackings] = React.useState<IOrderTrackingResourceShortProps[]>([]);
    const [activeTracking, setActiveTracking] = React.useState<IOrderTrackingResourceShortProps | undefined>();
    const [activeContainer, setActiveContainer] = React.useState<IOrderContainerResourceShortProps | undefined>();
    const [activeOrderTruck, setActiveOrderTruck] = React.useState<IOrderTruckResourceShortProps | undefined>();
    const [selectedFilter, setSelectedFilter] = React.useState<string>('all');
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onRetrieveTrackings();
    }, []);

    const _onRetrieveTrackings = async (pageNumber?: number,) => {
        try {
            setLoaded(false);

            const _order = await OrderService.get(props.orderId);
            setOrder(_order);

            const _trackings = await OrderTrackingService.retrieve(props.orderId);
            const _sorted: {date: string, tracking: IOrderTrackingResourceShortProps}[] = [];
            _trackings.forEach((tracking) => {
                let date: string | undefined;
                if (tracking.dooringDetails) {
                    date = tracking.dooringDetails.actualDepartureDate || tracking.dooringDetails.estimatedDepartureDate;
                } else if (tracking.loadDetails) {
                    date = tracking.loadDetails.actualStartDate || tracking.loadDetails.estimatedStartDate;
                } else if (tracking.unloadingDetails) {
                    date = tracking.unloadingDetails.actualStartDate || tracking.unloadingDetails.estimatedStartDate;
                } else if (tracking.shippingDetails) {
                    date = tracking.shippingDetails.schedule.actualDeparture || tracking.shippingDetails.schedule.estimatedDeparture;
                } else if (tracking.trainDetails) {
                    date = tracking.trainDetails.schedule.actualDeparture || tracking.trainDetails.schedule.estimatedDeparture;
                }

                if (date) {
                    const idx = _sorted.findIndex((s) => s.date && date && s.date > date);
                    if (idx < 0) {
                        _sorted.push({date, tracking});
                    } else {
                        _sorted.splice(idx, 0, {date, tracking});
                    }
                }
            });

            setTrackings(_sorted.map((s) => s.tracking));

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_trackings_error',
                text: 'Failed to retrieve order trackings. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "filter_by_container",
            text: "Filter by container",
            iconProps: { iconName: "Add" },
            onRender: () => {
                const options: IDropdownOption[] = [];

                let allText: string = 'All container(s)';
                if (order?.goodsType === 'fcl' || order?.goodsType === 'lcl') {
                    allText = "All container(s)";
                } else if (order?.goodsType === 'ftl' || order?.goodsType === 'ltl') {
                    allText = "All truck(s)";
                }

                options.push({ key: "all", text: allText });
                (order?.containers || []).forEach((container, idx) => {
                    options.push({ key: container.id, text: "Container " + (container.containerNumber || `#${idx + 1}`) })
                });
                (order?.trucks || []).forEach((truck, idx) => {
                    options.push({ key: truck.id, text: truck.name || `Truck ${idx + 1}` })
                });

                return <Dropdown styles={{ root: { width: 300 } }}
                    options={options}
                    selectedKey={selectedFilter}
                    onChange={(evt, opt) => {
                        const value = opt ? opt.key as string : 'all';
                        setSelectedFilter(value);
                    }} />;
            }
        });

        if (!props.hideCreateTracking && PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Tracking",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Tracking"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });

            farItems.push({
                key: "changeOrder",
                text: "Change Order",
                onRender: () => {
                    return <DefaultButton text={"Change Order"}
                        onClick={() => { setActiveSurface('sort') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            })
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveTracking(undefined);
        setActiveContainer(undefined);

        if (refresh) { _onRetrieveTrackings() }
    }

    const onUpload = async (files: IFileDetailsProps[]) => {
        if (activeTracking)
            try {
                const names: string[] = [];
                const fd = new FormData();

                files.map(f => {
                    const file = f.data as File
                    const allMetaData = {
                        name: file.name,
                        size: file.size,
                    };

                    fd.append('metadatas[]', JSON.stringify({ 
                        visibility: f.visibility, 
                        type: activeTracking.type, 
                        orderTrackingId: activeTracking.id,
                        ...allMetaData
                    }));
                });

                files.map(f => fd.append('documents[]', f.data));

                await OrderService.documents.new(activeTracking.orderId, fd);

                banner.add({
                    key: "upload_order_documents_success",
                    variant: 'success',
                    icon: faCheck,
                    text: `Document(s) ${names.join(", ")} uploaded successfully.`
                });

                setActiveSurface(undefined);
            } catch (e) {
                throw (e);
            }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {!loaded ? <LoadingComponent label='Retrieving order trackings ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded && order && trackings.length > 0 ? <Stack className={styles.timeline}>
                <Stack tokens={{ childrenGap: 10 }}>{trackings.filter((tracking) => {
                    if (order.goodsType === 'fcl' || order.goodsType === 'lcl') {
                        return selectedFilter !== 'all' && tracking.container ? tracking.container.id === selectedFilter : true;
                    } else if (order.goodsType === 'ftl' || order.goodsType === 'ltl') {
                        return selectedFilter !== 'all' && tracking.orderTruck ? tracking.orderTruck.id === selectedFilter : true;
                    } else {
                        return true;
                    }
                }).map((tracking) => {
                    return <OrderTrackingDetailsCard showAsTimeline={true} tracking={tracking} onOpenSurface={(key) => {
                        setActiveSurface(key);
                        setActiveTracking(tracking);
                    }} />
                })}</Stack>
            </Stack> : null}
            {loaded && trackings.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                <Text>Order tracking(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderTrackingForm orderId={props.orderId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'sort' && order ? <SortOrderTrackingForm order={order} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update.details' && activeTracking ? <OrderTrackingForm orderId={props.orderId} trackingId={activeTracking.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update.status' && activeTracking ? <OrderTrackingStatusForm orderId={props.orderId} trackingId={activeTracking.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'delete' && activeTracking ? <DeleteOrderTrackingForm orderId={props.orderId} tracking={activeTracking} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'container.details' && activeContainer ? <OrderContainerDetails orderId={props.orderId} containerId={activeContainer.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'ordertruck.details' && activeOrderTruck ? <OrderTruckDetails orderId={props.orderId} truckId={activeOrderTruck.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update.train.status' && activeTracking && activeTracking.trainDetails ? <TrainScheduleStatusForm scheduleId={activeTracking.trainDetails.schedule.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update.ship.status' && activeTracking && activeTracking.shippingDetails ? <ShipScheduleStatusForm scheduleId={activeTracking.shippingDetails.schedule.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'upload.files' && activeTracking ? <UploadFilesPanel multiple allowSelectDocumentType={false} allowedTypes={['word', 'pdf', 'image']} onUpload={onUpload} onCancel={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrderTrackingsList;
