import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets
import { faBuilding, faHome, faPhone, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props
import { IUserResourceShortProps } from '../../../../../props/users/user';

// components
import { ActionButton, CommandBar, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import Tag from '../../../../uiframeworks/tag';
import UserService from '../../../../../services/users/user';
import CustomerForm from '../form';
import ResetCustomerPasswordForm from '../resetPassword';
import LoadingComponent from '../../../../feedbacks/loading';
import Label from '../../../../typography/label';
import DetailsStack, { IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteUserForm from '../../../general/delete';
import OrdersList from '../../../../orders/general/list';
import PermissionsService from '../../../../../services/permissions';
import Permissions from '../../../../../permissions';
import { observer } from 'mobx-react';

interface ICustomerDetailsProps {
    userId: string;
}

const CustomerDetails: React.FC<ICustomerDetailsProps> = observer((props: ICustomerDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [customer, setCustomer] = React.useState<IUserResourceShortProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    useEffect(() => {
        _onGetCustomer();
    }, []);

    const _onGetCustomer = async () => {
        try {
            const _customer = await UserService.get(props.userId);
            setCustomer(_customer);

            topbar.show(_customer.name, [
                { key: "home", icon: faHome, href: "/" },
                { key: "users", text: 'Users' },
                { key: "customers", text: 'Customers', href: "/users/customers" },
                { key: props.userId, text: _customer.name }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_customer_error',
                text: 'Failed to get customer details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(Permissions.customer.update, user.roles)) {
            items.push({
                key: 'update',
                text: 'Update details',
                onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
            });
        }


        if (PermissionsService.hasPermission(Permissions.customer.password.reset, user.roles)) {
            items.push({
                key: 'reset_password',
                text: 'Reset Password',
                onRender: () => <ActionButton text={'Reset Password'} iconProps={{ iconName: "Permissions" }} onClick={() => setActiveSurface('resetPassword')} styles={{ root: { marginLeft: 20 } }} />
            });
        }


        if (PermissionsService.hasPermission(Permissions.customer.delete, user.roles)) {
            farItems.push({
                key: 'delete',
                text: 'Delete',
                onRender: () => <ActionButton text={'Delete'} iconProps={{ iconName: "Delete" }} styles={{ root: { marginLeft: 20 } }} onClick={() => setActiveSurface('delete')} />
            });
        }

        return { items, farItems };
    }

    const getUserPropertiesSection = (_customer: IUserResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "Details"
            },
            key: 'user_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Unique Id</Label>
                        <Text>{_customer.id.toUpperCase()}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Email Address</Label>
                        <Text>{_customer.email}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Phone Number</Label>
                        <Text>{_customer.phoneNumber || "-"}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '50%' } }}>
                        <Label size={'small'}>Address</Label>
                        <Text>{_customer.address || "-"}</Text>
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getRelatedCompaniesSection = (_customer: IUserResourceShortProps): IStackSectionProps => {
        return {
            header: {
                title: "Related Companies"
            },
            key: 'user_companies',
            isCard: true,
            content: <Stack>
                {_customer.companies.map((company) => {
                    return <Tag goIcon={true} href={'/users/companies/' + company.id}>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <Label>{company.name}</Label>
                            {company.address ? <Stack horizontal tokens={{ childrenGap: 3 }}>
                                <FontAwesomeIcon icon={faBuilding} fontSize={14} width={16} />
                                <Text size={'small'}>{company.address}</Text>
                            </Stack> : null}
                            {company.phoneNumber ? <Stack horizontal tokens={{ childrenGap: 3 }}>
                                <FontAwesomeIcon icon={faPhone} fontSize={12} width={16} style={{ marginTop: 1 }} />
                                <Text size={'small'}>{company.phoneNumber}</Text>
                            </Stack> : null}
                        </Stack>
                    </Tag>
                })}
            </Stack>
        }
    }

    const getRelatedItemsSection = (_customer: IUserResourceShortProps): IStackSectionProps => {
        const qs: string[] = [];
        qs.push(`customer=${props.userId}`);

        return {
            key: 'user_companies',
            isCard: true,
            tabs: [
                {
                    key: 'related_orders',
                    title: 'Related Orders',
                    content: <Stack>
                        <OrdersList qs={qs} filters={['delivery_type', 'freight_category', 'goods_type', 'order_status']} />
                    </Stack>
                }/*,
                {
                    key: 'stored_goods',
                    title: 'Stored Goods',
                    content: <Stack>
                        Stored Goods
                    </Stack>
                },
                {
                    key: 'goods_history',
                    title: 'Goods History',
                    content: <Stack>
                        Goods History
                    </Stack>
                }*/
            ]
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetCustomer() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving customer details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && customer ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack className={styles.details} tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getUserPropertiesSection(customer)]} right={getRelatedCompaniesSection(customer)} />
                <DetailsStack left={[getRelatedItemsSection(customer)]} />
            </Stack>
            {activeSurface === 'update' ? <CustomerForm customerId={customer.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'resetPassword' ? <ResetCustomerPasswordForm user={customer} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'delete' ? <DeleteUserForm user={customer} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
});

export default CustomerDetails;
