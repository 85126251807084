import { CommandBar, PrimaryButton, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import DetailsStack from '../../../components/uiframeworks/detailsStack';
import SalarySlipForm from '../../../components/hr/salaries/slips/general/form';

interface SalarySlipPageProps {
}

const SalarySlipPage: React.FC<SalarySlipPageProps> = (props: SalarySlipPageProps) => {
    const store = useStore();
    
    useEffect(() => {
        store.topbar.show('Salary Slip', [
            {key: 'home', icon: faHome},
            {key: 'payroll', text: 'Payroll', href: '/hr/payroll'},
            {key: 'salarySlip', text: 'Salary Slip'}
        ]);
    }, []);

    return <Stack>
        <SalarySlipForm />
    </Stack>;
};

export default SalarySlipPage;
