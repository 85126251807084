import React, { useEffect } from 'react';
import styles from './../styles.module.scss';

import { ActionButton, NormalPeoplePicker, Stack } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import { IUserResourceShortProps } from '../../../../../../props/users/user';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import GeneralService from '../../../../../../services/general';
import ISubsidiariesResourceShort from '../../../../../../props/data/subsidiaries';
import UserService from '../../../../../../services/users/user';
import SubsidiariesService from '../../../../../../services/data/subsidiaries';

type OfferGeneralDetailsProps = {
    data?: GeneralDetailsFormData;
    submitting?: boolean;
    mode?: string;
    onChange(data?: GeneralDetailsFormData): void;
}

export type GeneralDetailsFormData = {
    sales?: IUserResourceShortProps;
    subsidiary?: ISubsidiariesResourceShort;
}

export type GeneralDetailsFormDataError = {
    sales?: string;
    subsidiary?: string;
}

const OfferGeneralDetails: React.FC<OfferGeneralDetailsProps> = (props: OfferGeneralDetailsProps) => {
    const { banner } = useStore();
    const [error, setError] = React.useState<GeneralDetailsFormDataError>({});
    const [data, setData] = React.useState<GeneralDetailsFormData | undefined>();

    useEffect(() => {
        setData({ ...props.data });
    }, [props.data]);

    const _onSearchSubsidiary = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await SubsidiariesService.retrieve(qs.join("&"));

            return results.filter((d, idx) => idx < 10).map((subsidiary) => {
                return {
                    id: subsidiary.id,
                    text: subsidiary.name,
                    secondaryText: subsidiary.npwp,
                    imageInitials: GeneralService.getInitial(subsidiary.name),
                    metadata: subsidiary
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_subsidiary_error',
                text: 'Failed to search related subsidiary. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }

    const _onSearchSales = async (keyword: string) => {
        try {
            const qs: string[] = [];
            qs.push(`rolesGroup=backoffice`);
            qs.push(`top=10`);
            qs.push(`search=${keyword}`);

            const users = await UserService.retrieve(qs.join('&'));
            return users.data.map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (error) {
            banner.add({
                key: "search_sales_error",
                text: "Failed to search related sales. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    return <Stack tokens={{ childrenGap: 15 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={styles.picker} styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'} required={true}>Select Subsidiary</Label>
                {!data?.subsidiary && props.mode !== 'view' ? <NormalPeoplePicker
                    onResolveSuggestions={_onSearchSubsidiary}
                    onEmptyResolveSuggestions={() => _onSearchSubsidiary('')}
                    itemLimit={1}
                    selectedItems={[]}
                    onChange={(item?: any) => {
                        let _data = data || {};
                        if (item[0]?.metadata) {
                            _data.subsidiary = item[0] ? item[0].metadata : undefined;
                            props.onChange(_data);
                        }
                        return null;
                    }}
                /> : null}
                {data?.subsidiary ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                    <Stack>
                        <Label size={'small'}>{data.subsidiary.name}</Label>
                        <Text size={'small'}>{data.subsidiary.npwp}</Text>
                    </Stack>
                    {props.mode !== 'view' ? <ActionButton disabled={props.submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                        let _data = data || {};
                        _data.subsidiary = undefined;
                        props.onChange(_data);

                    }} /> : null}
                </Stack> : null}
            </Stack.Item>
            <Stack.Item className={styles.picker} styles={{ root: { width: '50%' } }}>
                <Label size={'xsmall'} required={true}>Select Sales</Label>
                {!data?.sales && props.mode !== 'view' ? <NormalPeoplePicker
                    onResolveSuggestions={_onSearchSales}
                    onEmptyResolveSuggestions={() => _onSearchSales('')}
                    itemLimit={1}
                    selectedItems={[]}
                    onChange={(item?: any) => {
                        let _data = data || {};
                        if (item[0]?.metadata) {
                            _data.sales = item[0] ? item[0].metadata : undefined;
                            props.onChange(_data);
                        }
                        return null;
                    }}
                /> : null}
                {data?.sales ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                    <Stack>
                        <Label size={'small'}>{data.sales.name}</Label>
                        <Text size={'small'}>{data.sales.email}</Text>
                    </Stack>
                    {props.mode !== 'view' ? <ActionButton disabled={props.submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                        let _data = data || {};
                        _data.sales = undefined;
                        props.onChange(_data);

                    }} /> : null}
                </Stack> : null}
            </Stack.Item>
        </Stack>
    </Stack>;
}

export default OfferGeneralDetails;

