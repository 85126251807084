import { IActivityResourceShortProps } from '../../props/activities';
import Request from './../request';

// import props

const ActivitiesService = {
  retrieve: async (qs?: string): Promise<IActivityResourceShortProps> => {
    try {
      const results = await Request.get(`/api/activities?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default ActivitiesService;
