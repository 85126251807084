import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

import { useStore } from '../../../../../../stores/root';
import { IUserResourceShortProps } from '../../../../../../props/users/user';
import { IEmployeePerformanceResourceShortProps } from '../../../../../../props/users/employees/performance';
import { Legend, Pie, ResponsiveContainer, PieChart, Tooltip } from 'recharts';
import Text from './../../../../../typography/text';
import { FixedChartColors } from '../../../../../../manifests/chartColors';
import Label from '../../../../../typography/label';
import GeneralService from '../../../../../../services/general';
import OrderService from '../../../../../../services/orders/order';
import LoadingComponent from '../../../../../feedbacks/loading';

interface AdministratorPerformanceRevenueChartProps {
    employee: IUserResourceShortProps;
    performance: IEmployeePerformanceResourceShortProps;
}

interface ChartDataProps {
    key: string;
    name: string;
    legend: string;
    fill: string;
    value: number;
}

const AdministratorPerformanceRevenueChart: React.FC<AdministratorPerformanceRevenueChartProps> = (props: AdministratorPerformanceRevenueChartProps) => {
    const { topbar, banner } = useStore();
    const [employee, setEmployee] = React.useState<IUserResourceShortProps>(props.employee);
    const [performance, setPerformance] = React.useState<IEmployeePerformanceResourceShortProps>(props.performance);
    const [data, setData] = React.useState<ChartDataProps[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);

    useEffect(() => {
        init(props.employee, props.performance);
    }, [employee, performance]);

    const init = (employee: IUserResourceShortProps, performance: IEmployeePerformanceResourceShortProps) => {
        const data: ChartDataProps[] = [];

        let totalPaid: number = 0;
        let totalUnpaid: number = 0;
        performance.salesOrders.forEach((order) => {
            totalPaid = order.paidAmount;
            totalUnpaid = order.unpaidAmount;
        });

        if (totalPaid > 0) {
            data.push({
                key: 'paid',
                name: 'Paid',
                legend: 'Paid',
                fill: FixedChartColors.paid,
                value: totalPaid
            });
        }

        if (totalUnpaid > 0) {
            data.push({
                key: 'unpaid',
                name: 'Unpaid',
                legend: 'Unpaid',
                fill: FixedChartColors.unpaid,
                value: totalUnpaid
            });
        }

        setData(data);
        setEmployee(employee);
        setPerformance(performance);
        setLoaded(true);
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        return <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='center' wrap>
            {payload.map((entry: any, index: number) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Stack.Item className={styles.indicator} styles={{ root: { backgroundColor: entry.color } }}> </Stack.Item>
                    <Text size={'small'}>{entry.payload.legend}</Text>
                </Stack>
            })}
        </Stack>;
    }

    const renderTooltip = (_props: any) => {
        if (_props.active && _props.payload && _props.payload[0]) {
            let total = 0;

            return <Stack tokens={{ childrenGap: 10 }} className={styles.tooltip}>
                <Stack tokens={{ childrenGap: 5 }}>
                    {data.map((d) => {
                        total += d.value;
                        return <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between' verticalAlign='center'>
                            <Label size={'xsmall'}>{d.name}</Label>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(d.value)}</Text>
                        </Stack>
                    })}
                </Stack>
                <Stack className='divider'></Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between' verticalAlign='center'>
                    <Label size={'xsmall'}>Total Revenue</Label>
                    <Label>{GeneralService.getNumberWithSeparator(total)}</Label>
                </Stack>
            </Stack>;
        }

        return null;
    };

    return <Stack styles={{ root: { width: '100%', height: 250 } }} verticalAlign='center' horizontalAlign='center'>
        {!loaded ? <LoadingComponent label={'Retrieving revenue ...'} /> : null}
        {loaded && data.length < 1 ? <Text>No revenue found</Text> : null}
        {loaded && data.length > 0 ? <ResponsiveContainer>
            <PieChart>
                <Pie dataKey="value"
                    data={data}
                    label={(entry) => entry.name}
                    paddingAngle={5}
                    innerRadius={60}
                    outerRadius={80} />
                <Tooltip content={renderTooltip} />
                <Legend content={renderLegend} verticalAlign="bottom" align="center" />
            </PieChart>
        </ResponsiveContainer> : null}
    </Stack>;
};

export default AdministratorPerformanceRevenueChart;
