import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../../stores/root';
import { IUserResourceShortProps } from '../../../../props/users/user';
import UserService from '../../../../services/users/user';
import Text from '../../../typography/text';
import DetailsStack from '../../../uiframeworks/detailsStack';
import EmployedContractForm from '../contracts/general/form';
import PermissionsService from '../../../../services/permissions';
import EmployedSalaryForm from '../salaries/general/form';
import ContractList from '../contracts/general/list';
import SalaryList from '../salaries/general/list';
import LoadingComponent from '../../../feedbacks/loading';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import BankAccountList from '../../administrators/bankAccounts/general/list';
import EmployeeBankAccountForm from '../../administrators/bankAccounts/general/form';

interface EmploymentDashbordProps {
    employeeId: string;
}

const EmploymentDashbord: React.FC<EmploymentDashbordProps> = (props: EmploymentDashbordProps) => {
    const { topbar, banner, user } = useStore();
    const [employee, setEmployee] = React.useState<IUserResourceShortProps | undefined>();
    const [loading, setLoading] = React.useState<string[]>(['contracts', 'salaries', 'bankAccounts']);
    const [activeSurface, setActiveSurface] = React.useState<'create.contract' | 'create.salary' | 'create.bankAccount' | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _employee: IUserResourceShortProps | undefined = undefined;
        if (props.employeeId !== 'my') {
            _employee = await UserService.get(props.employeeId);
        } else {
            _employee = await UserService.currentUser();
        }

        if (_employee) {
            topbar.show("Employment Details", [
                { key: "home", icon: faHome, href: "/" },
                { key: "users", text: 'Users' },
                { key: "administrators", text: 'Administrators', href: "/users/administrators" },
                { key: _employee.id, text: _employee.name, href: "/users/administrators/" + _employee.id },
                { key: 'employmentDetails', text: 'Employment Details' }
            ]);
            setEmployee(_employee);
        }

        setLoading([]);
    }

    const renderLeftStack = () => {
        return [
            {
                key: 'contractAndSalary',
                subsections: [
                    {
                        header: {
                            title: "Working Contracts",
                            buttons: PermissionsService.hasPermission(['superuser', 'hr'], user.roles) ? [
                                {
                                    key: 'create_contract',
                                    text: 'New Contract',
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => {
                                        setActiveSurface('create.contract');
                                    }
                                }
                            ] : undefined
                        },
                        key: 'workingContracts',
                        isCard: true,
                        content: <Stack tokens={{ childrenGap: 20 }}>
                            {loading.indexOf('contracts') > -1 ? <LoadingComponent label={'Retrieving contracts ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                            {employee && loading.indexOf('contracts') < 0 ? <ContractList employeeId={employee.id} hideCommandBar isHeaderVisible={false} /> : null}
                        </Stack>
                    },
                    {
                        header: {
                            title: "Salaries",
                            buttons: PermissionsService.hasPermission(['superuser', 'hr'], user.roles) ? [
                                {
                                    key: 'new_salary',
                                    text: 'New Salary',
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => {
                                        setActiveSurface('create.salary');
                                    }
                                }
                            ] : undefined
                        },
                        key: 'salaries',
                        isCard: true,
                        content: <Stack tokens={{ childrenGap: 20 }}>
                            {loading.indexOf('salaries') > -1 ? <LoadingComponent label={'Retrieving salaries ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                            {employee && loading.indexOf('salaries') < 0 ? <SalaryList employeeId={employee.id} hideCommandBar isHeaderVisible={false} /> : null}
                        </Stack>
                    }
                ]
            },
            {
                key: 'bankAccount',
                subsections: [
                    {
                        header: {
                            title: "Bank Accounts",
                            buttons: PermissionsService.hasPermission(['superuser', 'hr'], user.roles) ? [
                                {
                                    key: 'new_salary',
                                    text: 'New Account',
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => {
                                        setActiveSurface('create.bankAccount');
                                    }
                                }
                            ] : undefined
                        },
                        key: 'bankAccounts',
                        isCard: true,
                        content: <Stack tokens={{ childrenGap: 20 }}>
                            {loading.indexOf('bankAccounts') > -1 ? <LoadingComponent label={'Retrieving bankAccounts ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
                            {employee && loading.indexOf('bankAccounts') < 0 ? <BankAccountList employeeId={employee.id} hideCommandBar isHeaderVisible={false} /> : null}
                        </Stack>
                    }
                ]
            },
        ];
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        <DetailsStack left={renderLeftStack()} />
        {employee && activeSurface === 'create.contract' && <EmployedContractForm employeeId={employee.id} onDismissed={(refresh) => {
            setActiveSurface(undefined);
            if (refresh) {
                setLoading([...loading, 'contracts']);
                setTimeout(() => {
                    setLoading(loading.filter((l) => l !== 'contracts'))
                }, 100);
            }
        }} />}
        {employee && activeSurface === 'create.bankAccount' && <EmployeeBankAccountForm employeeId={employee.id} onDismissed={(refresh) => {
            setActiveSurface(undefined);
            if (refresh) {
                setLoading([...loading, 'bankAccounts']);
                setTimeout(() => {
                    setLoading(loading.filter((l) => l !== 'bankAccounts'))
                }, 100);
            }
        }} />}
        {employee && activeSurface === 'create.salary' && <EmployedSalaryForm employeeId={employee.id} onDismissed={(refresh) => {
            setActiveSurface(undefined);
            if (refresh) {
                setLoading([...loading, 'salaries']);
                setTimeout(() => {
                    setLoading(loading.filter((l) => l !== 'salaries'))
                }, 100);
            }
        }} />}
    </Stack>;
};

export default EmploymentDashbord;
