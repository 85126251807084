const ExtenstionIcons = {
    js: 'JS',
    html: 'FileHTML',
    doc: 'WordDocument',
    docs: 'WordDocument',
    docx: 'WordDocument',
    xls: 'ExcelDocument',
    xlsx: 'ExcelDocument',
    ppt: 'PowerPointDocument',
    pptx: 'PowerPointDocument',
    jpg: 'FileImage',
    jpeg: 'FileImage',
    png: 'FileImage',
    gif: 'FileImage',
    svg: 'FileImage',
    tiff: 'FileImage',
    bmp: 'FileImage',
    pdf: 'PDF',
    txt: 'TextDocument'
};

export default ExtenstionIcons;