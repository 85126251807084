import { ActionButton, Checkbox, ChoiceGroup, DefaultButton, Dialog, DialogFooter, Icon, IconButton, Panel, PanelType, PrimaryButton, Stack, Tooltip, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

// assets

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { Link, NavLink } from 'react-router-dom';
import LoadingComponent from '../../../../feedbacks/loading';
import { faCheck, faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faFolder, faImage, faUpload, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import FileFormats, { TFileType } from '../../../../../manifests/fileFormats';
import GeneralService from '../../../../../services/general';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import saveAs from 'file-saver';
import { useStore } from '../../../../../stores/root';
import ErrorService from '../../../../../services/general/error';
import LoadingDialogComponent from '../../../../feedbacks/loadingDialog';
import DocumentService from '../../../../../services/documents/document';
import RenderImage from '../renderImage';
import { IInternalDocumentResourceShort } from '../../../../../props/internalDocuments';
import { useHistory } from 'react-router-dom';



type IInternalDocumentFilesListProps = {
    files: IInternalDocumentResourceShort[];
    selected?: IInternalDocumentResourceShort[];
    loaded?: boolean;
    onSelectFile?(selected?: IInternalDocumentResourceShort[]): void;
}

const InternalDocumentFilesList: React.FC<IInternalDocumentFilesListProps> = (props: IInternalDocumentFilesListProps) => {
    const history = useHistory();
    const currentPath = window.location.pathname;
    const { banner } = useStore();

    const inputDocumentRef = React.useRef<HTMLInputElement>(null);
    const [files, setFiles] = React.useState<IInternalDocumentResourceShort[]>(props.files);
    const [selected, setSelected] = React.useState<IInternalDocumentResourceShort[]>(props.selected || []);
    const [loaded, setLoaded] = React.useState<boolean>(props.loaded || false);
    const [downloading, setDownloading] = React.useState<boolean>(false);

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    useEffect(() => {
        setLoaded(props.loaded || false);
    }, [props.loaded]);

    useEffect(() => {
        setSelected(props.selected || []);
    }, [props.selected]);

    const _onClick = async (file: IInternalDocumentResourceShort) => {
        let _selected = selected;
        const idx = _selected.findIndex((sel) => sel.path === file.path);
        if (idx > -1) {
            _selected.splice(idx, 1);
        } else {
            _selected.push(file);
        }

        if (props.onSelectFile) {
            props.onSelectFile(_selected);
        } else {
            setSelected([..._selected]);
        }
    }

    const _onDownload = async (file: IInternalDocumentResourceShort) => {
        try {
            const blobData = (await DocumentService.get(file.path)).data.data
            const byteCharacters = atob(blobData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            const blobURL = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = blobURL;
            downloadLink.download = file.name;
            downloadLink.click();

            URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.log(error)
        }
    }

    const renderFileCard = (file: IInternalDocumentResourceShort) => {
        const type = GeneralService.getFileType(file.name);
        const isSelected = selected.find((sel) => sel.path === file.path) !== undefined;

        if (file.type === 'document') {
            return (
                <Stack key={file.name} onClick={() => _onClick(file)} className={styles.card} styles={{ root: { width: '15%', minWidth: 200, maxWidth: 250 } }}>
                    <Checkbox onChange={() => _onClick(file)} checked={isSelected} className={`${styles.checkbox} ${isSelected ? styles.selected : ''}`} styles={{ checkbox: { borderRadius: '50%', width: 25, height: 25 } }} />
                    <IconButton
                        className={styles.download}
                        iconProps={{ iconName: "Download" }}
                        onClick={() => _onDownload(file)}
                    />
                    <Stack className={`${styles.fileImage} ${styles["bg" + type]}`}>
                        {type === 'word' ? <Icon className={styles.icon} iconName='WordDocument' /> : null}
                        {type === 'excel' ? <Icon className={styles.icon} iconName='ExcelDocument' /> : null}
                        {type === 'powerpoint' ? <Icon className={styles.icon} iconName='PowerPointDocument' /> : null}
                        {type === 'pdf' ? <Icon className={styles.icon} iconName='PDF' /> : null}
                        {type === 'file' ? <FontAwesomeIcon className={styles.icon} icon={faFile} /> : null}
                        {type === 'image' ? <RenderImage path={file.path} className={styles.image} /> : null}
                    </Stack>
                    <Stack className={styles.content} tokens={{ childrenGap: 3 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                            {file.createdAt ? <Text size={'xsmall'}>{moment(file.createdAt).format("DD/MM/YYYY HH:mm")}</Text> : null}
                            <Text size={'xsmall'}>{GeneralService.getFriendlyFileSizeText(file.size, true)}</Text>
                        </Stack>
                        <TooltipHost content={<Text size={'small'}>{file.name}</Text>}>
                            <Stack className={styles.name}>
                                <Label className={styles.text} size={'small'}>{file.name}</Label>
                            </Stack>
                        </TooltipHost>
                    </Stack>
                </Stack>
            )
        } else {
            return (
                <Stack
                    key={file.name}
                    className={styles.card}
                    styles={{ root: { width: '15%', minWidth: 200, maxWidth: 250 } }}
                >
                    <Checkbox onChange={() => _onClick(file)} checked={isSelected} className={`${styles.checkbox} ${isSelected ? styles.selected : ''}`} styles={{ checkbox: { borderRadius: '50%', width: 25, height: 25 } }} />
                    <Stack
                        styles={{ root: { height: '125px', backgroundColor: '#EDEBE9', borderRadius: 10, fontSize: 'unset !important' } }}
                        horizontalAlign='center'
                        verticalAlign='center'
                        className={`${styles.fileImage} ${styles["bgimage"]}`}
                    >
                        <FontAwesomeIcon size='5x' icon={faFolder} />
                    </Stack>
                    <Stack className={styles.content} tokens={{ childrenGap: 3 }} styles={{ root: { height: 70 } }}>
                        <NavLink to={`${currentPath}/${file.name}`}>
                            <Label className={styles.text} size={'small'}>{file.name}</Label>
                        </NavLink>
                    </Stack>
                </Stack>
            )
        }
    }

    return <Stack className={styles.container}>
        {!loaded ? <LoadingComponent label='Retrieving files ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && files.length > 0 ? <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
            {files.map(renderFileCard)}
        </Stack> : null}
        {loaded && files.length < 1 ? <Text>File(s) not found</Text> : null}
        {downloading ? <LoadingDialogComponent title='Downloading File' secondaryText="Please wait while we're downloading selected file ..." /> : null}
    </Stack>;
};

export default InternalDocumentFilesList;
