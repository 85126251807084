import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';
import OrderContainerService from '../../../../../services/orders/orderContainer';

// props
import { IOrderContainerResourceShortProps } from '../../../../../props/orders/containers';

// components
import { CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import Tag from '../../../../uiframeworks/tag';
import Label from '../../../../typography/label';
import GeneralService from '../../../../../services/general';
import OrderContainerForm from '../form';
import OrderContainerDetails from '../details';
import PermissionsService from '../../../../../services/permissions';

interface IOrderContainersListProps {
    orderId: string;
    qs?: string[];
    containers?: IOrderContainerResourceShortProps[];
    columns?: TOrderContainerColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderContainerColumn = 'containerNumber' | 'actions' | 'sealNumber' | 'goodsList' | 'price';

const OrderContainersList: React.FC<IOrderContainersListProps> = (props: IOrderContainersListProps) => {
    const shownColumns: TOrderContainerColumn[] = props.columns || [
        'containerNumber',
        'actions',
        'sealNumber',
        'goodsList',
        'price'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [containers, setContainers] = React.useState<IOrderContainerResourceShortProps[]>(props.containers || []);
    const [activeContainer, setActiveContainer] = React.useState<IOrderContainerResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "containerNumber",
            name: "Container Number",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderContainerResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActiveContainer(item);
                        setActiveSurface("details");
                    }}><Text>{item.containerNumber || "-"}</Text></Link>
                </Stack>
            }
        },
        {
            key: "sealNumber",
            name: "Seal Number",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderContainerResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{item.sealNumber || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "goodsList",
            name: "Goods List",
            minWidth: 250,
            onRender: (item: IOrderContainerResourceShortProps) => {
                if (item.goods.length <= 0) {
                    return <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text style={{fontStyle: 'italic'}}>Good(s) are not listed yet</Text>
                    </Stack>
                } else {
                    return <Stack wrap horizontal tokens={{childrenGap: 10}}>
                        {item.goods.map((g) => {
                            return <Tag>
                                <Stack>
                                    <Label size={'xsmall'}>{g.goods.name}</Label>
                                    <Text size={'small'}>{GeneralService.getNumberWithSeparator(g.quantityAmount)} {g.quantityUnit || g.goods.quantityUnit}</Text>
                                </Stack>
                            </Tag>
                        })}
                    </Stack>
                }
            }
        },
        {
            key: "price",
            name: "Price",
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IOrderContainerResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalPrice))}</Text>
                    {item.ppn > 0 ? <Text size={'small'} style={{fontStyle: 'italic'}}>(inc. PPN) Rp. {GeneralService.getNumberWithSeparator(Number(item.ppn))}</Text> : null}
                    {item.pph > 0 ? <Text size={'small'} style={{fontStyle: 'italic'}}>(inc. PPh) Rp. {GeneralService.getNumberWithSeparator(Number(item.pph))}</Text> : null}
                </Stack>
            }
        }
    ];

    useEffect(() => {
        if (!props.containers) {
            _onRetrieveContainers();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrieveContainers = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await OrderContainerService.retrieve(props.orderId, qs.join("&"));
            setContainers(results);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_order_containers_list_error',
                text: 'Failed to retrieve order containers list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton && PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Container",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Container"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveContainer(undefined);

        if (refresh) { _onRetrieveContainers() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && containers.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={containers.filter((container) => {
                                const search = (keyword || "").trim().toLowerCase();
                                return (container.containerNumber || "").toLowerCase().indexOf(search) > -1 || (container.sealNumber || "").toLowerCase().indexOf(search) > -1
                            })}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderContainerColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && containers.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text>Container(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <OrderContainerForm orderId={props.orderId} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeContainer ? <OrderContainerForm orderId={props.orderId} containerId={activeContainer.id} onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'details' && activeContainer ? <OrderContainerDetails orderId={props.orderId} containerId={activeContainer.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default OrderContainersList;
