import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets

// services

// props

// components
import { IOutcomeResourceProps } from '../../../../../../props/finance/outcomes';
import { FormDataProps } from './props';
import InvoiceDetailsSubform from './subforms/details';
import OutcomeService from '../../../../../../services/finance/outcomes';
import DocumentService from '../../../../../../services/documents/document';
import FileService from '../../../../../../services/general/file';
import { faCheck, faCircleX } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';

type OutcomeInvoiceUpdateFormProps = {
    invoiceId: string;
    onDismissed(refresh?: boolean): void;
}

const OutcomeInvoiceUpdateForm: React.FC<OutcomeInvoiceUpdateFormProps> = (props: OutcomeInvoiceUpdateFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<FormDataProps>>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _data: FormDataProps = await OutcomeService.get(props.invoiceId);
        const file = (_data.documents || []).find((d) => (d.type || "").toLowerCase() === 'invoice');
        if (file) {
            const base64 = (await DocumentService.get(encodeURIComponent(file.path))).data.data;
            const images = await FileService.getImageBase64(base64, file.path.split(".").pop() || "");
            _data.systemBase64 = images;
        }

        setData(_data);
        setLoaded(true);
    }

    const _onSubmit = async (details: Partial<FormDataProps>) => {
        try {
            setSubmitting(true);
            const { systemFile, type, orders, subsidiary, bankAccount, status, includePPh, includePPn, ppnPercentage, pphPercentage, originalAmount, totalAmount, ppn, pph, vendor, dueDate, name, invoiceNumber, amount, notes, invoiceDate, accountingDate, ship, shipSchedule, train, trainSchedule, truck } = details;
            
            const fd = new FormData();
            fd.append("type", type || "others");
            fd.append("subsidiaryId", subsidiary?.id || "");
            fd.append("bankAccountId", bankAccount?.id || "");
            fd.append("dueDate", dueDate || "");
            fd.append("name", name || "");
            fd.append("invoiceNumber", invoiceNumber || "");
            fd.append("notes", notes || "");
            fd.append("accountingDate", accountingDate || "");

            fd.append("includePPn", includePPn ? "1" : "0");
            fd.append("includePPh", includePPh ? "1" : "0");
            fd.append("ppnPercentage", ppnPercentage || "0");
            fd.append("pphPercentage", pphPercentage || "0");
            fd.append("ppn", (ppn || "0") + "");
            fd.append("pph", (pph || "0") + "");

            fd.append("amount", (amount || "0") + "");
            fd.append("originalAmount", (originalAmount || "0") + "");
            fd.append("totalAmount", (totalAmount || "0") + "");

            if (invoiceDate) {
                fd.append("invoiceDate", invoiceDate);
            }
            if (vendor) {
                fd.append("vendorId", vendor.id);
            }
            if (ship) {
                fd.append("shipId", ship.id);
            }
            if (shipSchedule) {
                fd.append("shipScheduleId", shipSchedule.id);
            }
            if (train) {
                fd.append("trainId", train.id);
            }
            if (trainSchedule) {
                fd.append("trainScheduleId", trainSchedule.id);
            }
            if (truck) {
                fd.append("truckId", truck.id);
            }

            (orders || []).forEach((item) => {
                if (item.order) {
                    fd.append('orders[]', JSON.stringify({
                        orderId: item.order.id,
                        amount: item.amount,
                        notes: item.notes
                    }));
                }
            });

            if (systemFile) {
                fd.append("documents[]", systemFile);
                fd.append("metadatas[]", JSON.stringify({type: "Invoice", visibility: "Public"}));
            }

            await OutcomeService.update(props.invoiceId, fd);
            props.onDismissed(true);
            banner.add({
                key: 'submit_outcome_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Outcome invoice updated`
            });
        } catch(e) {
            setSubmitting(false);
            banner.add({
                key: 'submit_outcome_invoice_error',
                variant: 'error',
                icon: faCircleX,
                text: `Failed to update outcome invoice. Message: ${ErrorService.getMessage(e)}`
            });
        }
    }

    const _onDismissed = (details?: Partial<FormDataProps>) => {
        if (details) {
            _onSubmit(details);
        } else {
            props.onDismissed();
        }
    }

    return <>
        <InvoiceDetailsSubform data={data} onDismissed={_onDismissed} />
        {submitting ? <LoadingDialogComponent title={'Updating Invoice'} secondaryText={"Please wait while we're updating this invoice."} /> : null}
    </>
};

export default OutcomeInvoiceUpdateForm;
