import React from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../services/orders/order';
import ValidationService from '../../../../services/validation';

// props
import { IOrderResourceShortProps } from '../../../../props/orders/order';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import { IOfferResourceShortProps } from '../../../../props/offers';
import OfferService from '../../../../services/offers';

interface CancelOfferFormProps {
    offer: IOfferResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    reason: string;
}

type FormDataErrorProps = {
    reason?: string;
}

const CancelOfferForm: React.FC<CancelOfferFormProps> = (props: CancelOfferFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        reason: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            fd.append('reason', data.reason);
            await OfferService.cancel(props.offer.id, fd);

            banner.add({
                key: 'cancel_offer_success',
                variant: 'success',
                icon: faCheck,
                text: `Offer "${props.offer.offerNumber}" cancelled successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.reason) {
            return true;
        } else if (data.reason === '') {
            return true;
        }

        return false;
    }

    return <Panel headerText={"Cancel Offer"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Cancelling offer ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to cancel offer with details below?</Text>
            <Stack.Item>
                <Label>Offer Number</Label>
                <Text>{props.offer.offerNumber}</Text>
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <Label>Company</Label>
                    <Text>{props.offer.company?.name || "-"}</Text>
                </Stack.Item>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <Label>Customer (PIC)</Label>
                    <Text>{props.offer.customer.name || "-"}</Text>
                </Stack.Item>
            </Stack>
            <Stack className='divider'></Stack>
            <Stack.Item>
                <Label>Freight Details</Label>
                <Text>{props.offer.freightCategory.name} - {props.offer.deliveryType.name} - {props.offer.goodsType}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Origin</Label>
                <ol style={{ padding: '0px 12px', margin: 0 }}>
                    {props.offer.originAddresses.map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <li><Text size={'small'}>{text}</Text></li>
                    })}
                </ol>
            </Stack.Item>
            <Stack.Item>
                <Label>Destination</Label>
                <ol style={{ padding: '0px 12px', margin: 0 }}>
                    {props.offer.destinationAddresses.map((address) => {
                        const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                        return <li><Text size={'small'}>{text}</Text></li>
                    })}
                </ol>
            </Stack.Item>
            <Stack className='divider'></Stack>
            <Stack.Item>
                <TextField label={"Reason"}
                    required={true}
                    value={data.reason}
                    rows={3}
                    multiline
                    autoAdjustHeight
                    resizable={false}
                    onChange={(evt, value) => {
                        data.reason = value || "";

                        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                        error.reason = validation.message;

                        setData({ ...data });
                        setError({ ...error });
                    }}
                    errorMessage={error.reason}
                    disabled={submitting} />
            </Stack.Item>
        </Stack>
    </Panel>
};

export default CancelOfferForm;
