import { IIncomeInvoiceResourceShortProps, IIncomeInvoiceResourceProps } from '../../../props/finance/incomes/invoices/invoice';
import Request from './../../request';

// import props

const OrderInvoiceService = {
    getInvoiceNumber: (id: string) => {
        return id.split(".").splice(2).join(".");
    },
    create: async (orderId: string, data: FormData): Promise<IIncomeInvoiceResourceProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/invoices`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (orderId: string, invoiceId: string, data: FormData): Promise<IIncomeInvoiceResourceProps> => {
      try {
        const result = await Request.post(`/api/orders/${orderId}/invoices/${invoiceId}?_method=PUT`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    },
    get: async (orderId: string, invoiceId: string, qs?: string): Promise<IIncomeInvoiceResourceShortProps> => {
        try {
            const result = await Request.get(`/api/orders/${orderId}/invoices/${invoiceId}`);
            return result;
        } catch(e) {
            throw(e);
        }
    },
    retrieve: async (orderId: string, qs?: string): Promise<IIncomeInvoiceResourceShortProps[]> => {
        try {
            const result = await Request.get(`/api/orders/${orderId}/invoices?${qs || ''}`);
            return result;
        } catch(e) {
            throw(e);
        }
    }
}

export default OrderInvoiceService;