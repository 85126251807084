import React from 'react';
import { useStore } from '../../../../../../stores/root';
import { EmployeeSalaryService } from '../../../../../../services/users/employee';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField } from '@fluentui/react';
import { IEmployedSalaryResourceShort } from '../../../../../../props/users/user';
import moment from 'moment';
import ValidationService from '../../../../../../services/validation';
import GeneralService from '../../../../../../services/general';

interface ActivateSalaryFormProps {
    salary: IEmployedSalaryResourceShort;
    onDismissed(refresh?: boolean): void;
}

const ActivateSalaryForm: React.FC<ActivateSalaryFormProps> = (props: ActivateSalaryFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            await EmployeeSalaryService.activate(props.salary.employeeId, props.salary.id, fd);

            banner.add({
                key: 'activate_salary_success',
                variant: 'success',
                icon: faCheck,
                text: `Salary Rp. ${GeneralService.getNumberWithSeparator(Number(props.salary.amount))} is now active`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Activate Salary"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Activate"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Setting salary as active ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to activate salary with details below?</Text>
            <Stack.Item>
                <Label>Amount</Label>
                <Text>Rp. {GeneralService.getNumberWithSeparator(Number(props.salary.amount))}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Active Date</Label>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Text>{moment(props.salary.startDate).format('DD/MM/YYYY')}</Text>
                    <Text>-</Text>
                    <Text>{moment(props.salary.endDate).format('DD/MM/YYYY')}</Text>
                </Stack>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default ActivateSalaryForm;
