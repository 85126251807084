import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../services/general';
import OrderAddOnService from '../../../../../services/orders/odrerAddOn';

// props
import { IOrderAddonResourceShortProps } from '../../../../../props/orders/orderAddon';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import { IPublicNewsResourceShort } from '../../../../../props/data/news';
import PublicNewsService from '../../../../../services/data/publicNews';

interface DeletePublicNewsFormProps {
    news: IPublicNewsResourceShort;
    onDismissed(refresh?: boolean): void;
}

const DeletePublicNewsForm: React.FC<DeletePublicNewsFormProps> = (props: DeletePublicNewsFormProps) => {
    const { banner } = useStore();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await PublicNewsService.delete(props.news.id);

            banner.add({
                key: 'delete_public_news_success',
                variant: 'success',
                icon: faCheck,
                text: `Public news "${props.news.subject}" deleted successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Delete Public News"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Delete"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting additional charge ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you want to delete public news with details below?</Text>
            <Stack.Item>
                <Label>Subject</Label>
                <Text>{props.news.subject}</Text>
            </Stack.Item>
            <Stack.Item>
                <Label>Is Featured</Label>
                <Text>{props.news.isFeatured ? "Yes" : "No"}</Text>
            </Stack.Item>
        </Stack>
    </Panel>
};

export default DeletePublicNewsForm;
