import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services

// props

// components
import { Dropdown, DropdownMenuItemType, IDropdownOption, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';
import { ISystemFleetTypeResourceShortProps } from '../../../../props/systems/fleetTypes';
import SystemFleetTypeService from '../../../../services/systems/fleetType';

type SelectFleetTypeProps = {
    selected?: ISystemFleetTypeResourceShortProps;
    options?: ISystemFleetTypeResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    errorMessage?: string;
    onChange(selected?: ISystemFleetTypeResourceShortProps): void;
}

const SelectFleetType: React.FC<SelectFleetTypeProps> = (props: SelectFleetTypeProps) => {
    const { user } = useStore();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<ISystemFleetTypeResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<ISystemFleetTypeResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _options = await SystemFleetTypeService.retrieve((props.qs || []).join("&"));
        setOptions(_options);
        setLoaded(true);
    }

    const generateOptions = (): IDropdownOption[] => {
        const _groupings: {
            name: string;
            type: string;
            options: ISystemFleetTypeResourceShortProps[];
        }[] = [];
        
        options.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
        options.forEach((opt) => {
            const idx = _groupings.findIndex((g) => g.name.toLowerCase() === opt.group.toLowerCase() && g.type.toLowerCase() === opt.type.toLowerCase());
            if (idx > -1) {
                _groupings[idx].options.push(opt);
            } else {
                _groupings.push({
                    name: opt.group,
                    type: opt.type,
                    options: [opt]
                });
            }
        });

        const _options: IDropdownOption[] = [];
        _groupings.forEach((g, idx) => {
            if (idx > 0) {_options.push({ key: 'divider_' + idx, text: '-', itemType: DropdownMenuItemType.Divider });}
            _options.push({ key: g.name, text: g.name, itemType: DropdownMenuItemType.Header });

            g.options.forEach((opt) => {
                _options.push({ key: opt.id, text: opt.name });
            })
        });

        return _options;
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Fleet type"}</Label>}
        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'center'}>
            {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
            <Stack grow={1}>
                <Dropdown disabled={props.disabled || !loaded}
                    selectedKey={selected?.id}
                    options={generateOptions()}
                    onChange={(evt, opt) => {
                        const selected = options.find((o) => o.id === opt?.key as string);
                        props.onChange(selected);
                    }} />
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectFleetType;
