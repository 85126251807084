import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import UserService from '../../../../../services/users/user';
import { IUserResourceShortProps } from '../../../../../props/users/user';

// props

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Text from './../../../../typography/text';
import Label from '../../../../typography/label';

interface IResetAdministratorPasswordFormProps {
    user: IUserResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const ResetAdministratorPasswordForm: React.FC<IResetAdministratorPasswordFormProps> = (props: IResetAdministratorPasswordFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            await UserService.resetPassword(props.user.email);
            props.onDismissed(true);
            banner.add({
                key: 'reset_administration_password_success',
                variant: 'success',
                icon: faCheck,
                text: `${props.user.name} password has been reset`
            });
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText="Reset Administrator Password"
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Resetting password ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 20 } }}>
            <Text>Are you sure you would like to reset password for administrator with details below:</Text>
            <Stack tokens={{childrenGap: 10}}>
                <Stack.Item>
                    <Label size={'small'}>Full name</Label>
                    <Text>{props.user.name}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'small'}>Email</Label>
                    <Text>{props.user.email}</Text>
                </Stack.Item>
            </Stack>
            <Text>Note: New password will be sent to administrator's email address. They will need to reset their password once they are logged in.</Text>
        </Stack>
    </Panel>
};

export default ResetAdministratorPasswordForm;
