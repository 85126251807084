import { IDocumentProps } from '../../../props/general';
import { IOrderTrackingResourceShortProps } from '../../../props/orders/trackings/orderTracking';
import Request from './../../request';

// import props

const OrderTrackingService = {
    retrieve: async (orderId?: string, qs?: string): Promise<IOrderTrackingResourceShortProps[]> => {
        try {
            if (orderId) {
                const results = await Request.get(`/api/orders/${orderId}/trackings?${qs || ''}`);
                return results;
            } else {
                const results = await Request.get(`/api/trackings?${qs || ''}`);
                return results;
            }
        } catch (e) {
            throw (e);
        }
    },
    get: async (orderId: string, trackingId?: string, qs?: string): Promise<IOrderTrackingResourceShortProps> => {
        try {
            const results = await Request.get(`/api/orders/${orderId}/trackings/${trackingId}?${qs || ''}`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    create: async (orderId: string, data: FormData): Promise<IOrderTrackingResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/trackings`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (orderId: string, trackingId: string, data: FormData): Promise<IOrderTrackingResourceShortProps> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/trackings/${trackingId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (orderId: string, trackingId: string): Promise<IOrderTrackingResourceShortProps> => {
        try {
            const result = await Request.delete(`/api/orders/${orderId}/trackings/${trackingId}`,);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    reorder: async (orderId: string, data: FormData): Promise<void> => {
        try {
            const result = await Request.post(`/api/orders/${orderId}/trackings/reorder?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    liveTracking: async (trackingId: string): Promise<any> => {
        try {
            const results = await Request.get(`/api/trackings/${trackingId}/livetrack`);
            return results;
        } catch (e) {
            throw (e);
        }
    },
    documents: {
        retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
            try {
                const results = await Request.get(`/api/trackings/${id}/documents?${qs || ""}`);
                return results;
            } catch (e) {
                throw (e);
            }
        },
        new: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/trackings/${id}/documents`, data, 'multipart/form-data');
                return result;
            } catch (e) {
                throw (e);
            }
        },
        delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/trackings/${id}/delete_documents`, data);
                return result;
            } catch (e) {
                throw (e);
            }
        }
    },
}

export default OrderTrackingService;