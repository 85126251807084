import { IOutcomeSalaryPaymentResourceShortProps, IOutcomeSalaryResourceProps, IOutcomeSalaryResourceShortProps } from '../../../../props/finance/outcomes/salaries';
import { IDocumentProps, IPaginationResourceShort } from '../../../../props/general';
import Request from './../../../request';

// import props

const OutcomeSalaryService = {
    create: async (data: FormData): Promise<IOutcomeSalaryResourceProps> => {
        try {
            const result = await Request.post(`/api/finance/outcomes/salaries`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (salaryId: string): Promise<IOutcomeSalaryResourceProps> => {
        try {
            const result = await Request.get(`/api/finance/outcomes/salaries/${salaryId}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    retrieve: async (qs?: string): Promise<{ data: IOutcomeSalaryResourceShortProps[], pagination?: IPaginationResourceShort }> => {
        try {
            const result = await Request.get(`/api/finance/outcomes/salaries?${qs || ""}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (salaryId: string): Promise<void> => {
        try {
            await Request.delete(`/api/finance/outcomes/salaries/${salaryId}`);
            return;
        } catch (e) {
            throw (e);
        }
    }
}

const OutcomeSalaryPaymentService = {
    create: async (data: FormData, salaryId: string): Promise<IOutcomeSalaryPaymentResourceShortProps> => {
        try {
            const result = await Request.post(`/api/finance/outcomes/salaries/${salaryId}/payments`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    read: async (salaryId: string, id: string): Promise<IOutcomeSalaryPaymentResourceShortProps> => {
        try {
            const result = await Request.get(`/api/finance/outcomes/salaries/${salaryId}/payments/${id}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    retrieve: async (salaryId: string, qs?: string): Promise<{ data: IOutcomeSalaryPaymentResourceShortProps[], pagination?: IPaginationResourceShort }> => {
        try {
            const result = await Request.get(`/api/finance/outcomes/salaries/${salaryId}/payments?${qs || ""}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    verify: async (salaryId: string, id: string, data: FormData): Promise<IOutcomeSalaryPaymentResourceShortProps> => {
        try {
            const result = await Request.post(`/api/finance/outcomes/salaries/${salaryId}/payments/${id}/verify?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (salaryId: string, id: string): Promise<void> => {
        try {
            await Request.delete(`/api/finance/outcomes/salaries/${salaryId}/payments/${id}`);
            return;
        } catch (e) {
            throw (e);
        }
    },
    documents: {
        retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
            try {
                const result = await Request.get(`/api/outcome_salary_payments/${id}/documents?${qs || ''}`);
                return result;
            } catch (e) {
                throw (e);
            }
        },
        new: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/outcome_salary_payments/${id}/documents`, data, 'multipart/form-data');
                return result;
            } catch (e) {
                throw (e);
            }
        },
        delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/outcome_salary_payments/${id}/delete_documents`, data);
                return result;
            } catch (e) {
                throw (e);
            }
        }
    }
}

export { OutcomeSalaryPaymentService }

export default OutcomeSalaryService;