import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import UserService from '../../../../services/users/user';
import ErrorService from '../../../../services/general/error';
import CompanyService from '../../../../services/users/company';
import GeneralService from '../../../../services/general';
import FreightCategoryService from '../../../../services/orders/freightCategory';
import DeliveryTypeService from '../../../../services/orders/deliveryType';
import OrderService from '../../../../services/orders/order';
import ValidationService from '../../../../services/validation';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';
import { IDeliveryTypeResourceShortProps } from '../../../../props/deliveryType';
import { IFreightCategoryResourceShortProps } from '../../../../props/freightCategories';
import { IUserResourceShortProps } from '../../../../props/users/user';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';

// components
import { ActionButton, DefaultButton, DetailsList, Dropdown, IColumn, IDropdownOption, NormalPeoplePicker, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import ISubsidiariesResourceShort from '../../../../props/data/subsidiaries';
import SubsidiariesService from '../../../../services/data/subsidiaries';
import ISalesTeamResourceShort from '../../../../props/users/team';
import TeamService from '../../../../services/users/team';
import { IndonesianCities, searchCities, searchCitiesByProvince, searchProvinceByCity } from '../../../../manifests/cities';
import CityService from '../../../../services/data/cities';
import { IOrderAddressResourceShortProps } from '../../../../props/orders/address';

interface IOrderFormProps {
    orderId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    freightCategory?: IDropdownOption;
    deliveryType?: IDropdownOption;
    customer?: IUserResourceShortProps;
    company?: ICompanyResourceShort;
    goodsType: string;
    originType: string;
    originAddresses: IOrderAddressResourceShortProps[];
    destinationAddresses: IOrderAddressResourceShortProps[];
    batch: string;
    notes: string;
    subsidiary?: ISubsidiariesResourceShort;
}

type FormDataErrorProps = {
    batch?: string;
    notes?: string;
    customer?: string;
    company?: string;
    originAddress?: string;
    destinationAddress?: string;
}

const OrderForm: React.FC<IOrderFormProps> = (props: IOrderFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [freightCategories, setFreightCategories] = React.useState<IFreightCategoryResourceShortProps[]>([]);
    const [deliveryTypes, setDeliveryTypes] = React.useState<IDeliveryTypeResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        goodsType: "fcl",
        originType: "door",
        batch: "",
        notes: "",
        originAddresses: [],
        destinationAddresses: []
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.orderId === undefined ? 'create' : 'update';

    const addressColumns: IColumn[] = [
        {
            key: "type",
            name: "Type",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IOrderAddressResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Dropdown selectedKey={item.type}
                        options={[
                            { key: 'door', text: 'Door' },
                            { key: 'yard', text: 'Cont. Yard' },
                            { key: 'port', text: 'Port' }
                        ]}
                        onChange={(evt, opt) => {
                            const value = opt ? opt.key as string : 'door';
                            let _data = data;

                            if (item.target === 'origin') {
                                const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.originAddresses[idx]) {
                                    _data.originAddresses[idx].type = value;
                                } else {
                                    _data.originAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'origin',
                                        type: value,
                                        address: "",
                                        orderNumber: _data.originAddresses.length + 1
                                    });
                                }
                            } else if (item.target === 'destination') {
                                const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                if (_data.destinationAddresses[idx]) {
                                    _data.destinationAddresses[idx].type = value;
                                } else {
                                    _data.destinationAddresses.push({
                                        id: GeneralService.guid(),
                                        target: 'destination',
                                        type: value,
                                        address: "",
                                        orderNumber: _data.destinationAddresses.length + 1
                                    });
                                }
                            }

                            _data = checkEmptyAddress(_data);
                            setData({ ..._data });
                        }}
                        disabled={submitting} />
                </Stack>
            }
        },
        {
            key: "address",
            name: "Address",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IOrderAddressResourceShortProps) => {
                return <Stack tokens={{childrenGap: 5}}>
                    <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <TextField value={item.address}
                            disabled={submitting}
                            multiline
                            rows={2}
                            autoAdjustHeight
                            resizable={false}
                            onChange={(evt, val) => {
                                const value = val || "";
                                let _data = data;

                                if (item.target === 'origin') {
                                    const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                    if (_data.originAddresses[idx]) {
                                        _data.originAddresses[idx].address = value;
                                    } else {
                                        _data.originAddresses.push({
                                            id: GeneralService.guid(),
                                            target: 'origin',
                                            type: "door",
                                            address: value,
                                            orderNumber: _data.originAddresses.length + 1
                                        });
                                    }
                                } else if (item.target === 'destination') {
                                    const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                    if (_data.destinationAddresses[idx]) {
                                        _data.destinationAddresses[idx].address = value;
                                    } else {
                                        _data.destinationAddresses.push({
                                            id: GeneralService.guid(),
                                            target: 'destination',
                                            type: "door",
                                            address: value,
                                            orderNumber: _data.destinationAddresses.length + 1
                                        });
                                    }
                                }

                                _data = checkEmptyAddress(_data);
                                setData({ ..._data });
                            }} />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack styles={{root: {width: '50%'}}}>
                            <TagPicker selectedItems={(item.province || "").trim() !== '' ? [{ key: item.province || "", name: item.province || "" }] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                disabled={item.address.trim() === ""}
                                onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                                onChange={(items) => {
                                    const value = items && items[0] ? items[0].name : "";
                                    let _data = data;

                                    if (item.target === 'origin') {
                                        const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                        if (_data.originAddresses[idx]) {
                                            _data.originAddresses[idx].province = value;
                                            _data.originAddresses[idx].city = "";
                                        } else {
                                            _data.originAddresses.push({
                                                id: GeneralService.guid(),
                                                target: 'origin',
                                                type: "door",
                                                address: "",
                                                province: value,
                                                orderNumber: _data.originAddresses.length + 1
                                            });
                                        }
                                    } else if (item.target === 'destination') {
                                        const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                        if (_data.destinationAddresses[idx]) {
                                            _data.destinationAddresses[idx].province = value;
                                            _data.destinationAddresses[idx].city = "";
                                        } else {
                                            _data.destinationAddresses.push({
                                                id: GeneralService.guid(),
                                                target: 'destination',
                                                type: "door",
                                                address: "",
                                                province: value,
                                                orderNumber: _data.destinationAddresses.length + 1
                                            });
                                        }
                                    }

                                    _data = checkEmptyAddress(_data);
                                    setData({ ..._data });
                                }}
                                onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')} />
                        </Stack>
                        <Stack styles={{root: {width: '50%'}}}>
                            <TagPicker selectedItems={(item.city || "").trim() !== '' ? [{ key: item.city || "", name: item.city || "" }] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                disabled={item.address.trim() === "" || (item.province || "") === ""}
                                onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [item.province || ""])}
                                onChange={(items) => {
                                    const value = items && items[0] ? items[0].name : "";
                                    let _data = data;

                                    if (item.target === 'origin') {
                                        const idx = _data.originAddresses.findIndex((addr) => addr.id === item.id);
                                        if (_data.originAddresses[idx]) {
                                            _data.originAddresses[idx].city = value;
                                        } else {
                                            _data.originAddresses.push({
                                                id: GeneralService.guid(),
                                                target: 'origin',
                                                type: "door",
                                                address: "",
                                                province: "",
                                                city: value,
                                                orderNumber: _data.originAddresses.length + 1
                                            });
                                        }
                                    } else if (item.target === 'destination') {
                                        const idx = _data.destinationAddresses.findIndex((addr) => addr.id === item.id);
                                        if (_data.destinationAddresses[idx]) {
                                            _data.destinationAddresses[idx].city = value;
                                        } else {
                                            _data.destinationAddresses.push({
                                                id: GeneralService.guid(),
                                                target: 'destination',
                                                type: "door",
                                                address: "",
                                                province: "",
                                                city: value,
                                                orderNumber: _data.destinationAddresses.length + 1
                                            });
                                        }
                                    }

                                    _data = checkEmptyAddress(_data);
                                    setData({ ..._data });
                                }}
                                onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'city', [item.province || ""])} />
                        </Stack>
                    </Stack>
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IOrderAddressResourceShortProps, idx?: number) => {
                let show = false;
                if (item.target === 'origin') {
                    show = (idx || 0) < (data?.originAddresses || []).length - 1;
                } else {
                    show = (idx || 0) < (data?.destinationAddresses || []).length - 1;
                }

                return <Stack.Item styles={{ root: { padding: '16px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} iconProps={{ iconName: "Delete" }} onClick={() => {
                            let _data = data || {
                                originAddresses: [],
                                destinationAddresses: []
                            };

                            if (item.target === 'origin') {
                                _data.originAddresses = _data.originAddresses.filter((addr) => addr.id !== item.id);
                            } else if (item.target === 'destination') {
                                _data.destinationAddresses = _data.destinationAddresses.filter((addr) => addr.id !== item.id);
                            }

                            _data = checkEmptyAddress(_data);
                            setData({ ..._data });
                        }} />
                    </Stack.Item>
                </Stack.Item>;
            }
        }
    ];

    const checkEmptyAddress = (_data: FormDataProps) => {
        const emptyOriginExist = _data.originAddresses.findIndex((addr) => addr.address.trim() === "") > -1;
        const emptyDestinationExist = _data.destinationAddresses.findIndex((addr) => addr.address.trim() === "") > -1;

        if (!emptyOriginExist) {
            _data.originAddresses.push({
                id: GeneralService.guid(),
                target: 'origin',
                type: "door",
                address: "",
                orderNumber: _data.originAddresses.length + 1
            })
        }

        if (!emptyDestinationExist) {
            _data.destinationAddresses.push({
                id: GeneralService.guid(),
                target: 'destination',
                type: "door",
                address: "",
                orderNumber: _data.destinationAddresses.length + 1
            })
        }

        return _data;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _freightCategories = await FreightCategoryService.retrieve();
        const _deliveryTypes = await DeliveryTypeService.retrieve();

        setFreightCategories(_freightCategories);
        setDeliveryTypes(_deliveryTypes);
        setData({
            ...data,
            deliveryType: { key: _deliveryTypes[0].id, text: _deliveryTypes[0].name },
            freightCategory: { key: _freightCategories[0].id, text: _freightCategories[0].name }
        });

        if (props.orderId) {
            const _order = await OrderService.get(props.orderId);
            setData({
                ...data,
                customer: _order.customer,
                subsidiary: _order.subsidiary,
                company: _order.company,
                deliveryType: { key: _order.deliveryType.id, text: _order.deliveryType.name },
                freightCategory: { key: _order.freightCategory.id, text: _order.freightCategory.name },
                goodsType: _order.goodsType,
                batch: _order.batch || "",
                notes: _order.notes || "",
                originAddresses: [..._order.originAddresses, {
                    id: GeneralService.guid(),
                    target: 'origin',
                    type: "door",
                    address: "",
                    orderNumber: _order.originAddresses.length + 1
                }],
                destinationAddresses: [..._order.destinationAddresses, {
                    id: GeneralService.guid(),
                    target: 'destination',
                    type: "door",
                    address: "",
                    orderNumber: _order.destinationAddresses.length + 1
                }]
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.customer || error.company || error.batch || error.notes) {
            return true;
        } else if (!data.customer || !data.company) {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const orderNumber = GeneralService.makeid(30, true, 'ORD');
            const {
                freightCategory,
                deliveryType,
                customer,
                company,
                goodsType,
                batch,
                notes,
                subsidiary,
                originAddresses,
                destinationAddresses
            } = data;

            // create form data
            let fd = new FormData();
            fd.append('orderNumber', orderNumber);

            company && fd.append('companyId', company.id);
            customer && fd.append('customerId', customer.id);
            freightCategory && fd.append('freightCategoryId', freightCategory.key as string);
            deliveryType && fd.append('deliveryTypeId', deliveryType.key as string);
            subsidiary && fd.append('subsidiaryId', subsidiary.id);

            fd.append('goodsType', goodsType);
            fd.append('batch', batch.toUpperCase());
            fd.append('notes', notes);

            originAddresses.filter((address) => address.address.trim() !== "").forEach((address) => {
                fd.append('originAddresses[]', JSON.stringify(address));
            });

            destinationAddresses.filter((address) => address.address.trim() !== "").forEach((address) => {
                fd.append('destinationAddresses[]', JSON.stringify(address));
            });

            if (user.roles.indexOf('sales') > -1 && user.data) {
                fd.append('sales[]', JSON.stringify({
                    salesId: user.data.id,
                    feePercentage: user.data.percentageFee
                }));
            }

            let order = undefined;
            if (props.orderId === undefined) {
                order = await OrderService.new(fd);
            } else {
                order = await OrderService.update(props.orderId, fd);
            }

            banner.add({
                key: 'create_order_success',
                variant: 'success',
                icon: faCheck,
                text: `Order "${orderNumber}" created successfully`
            });

            if (props.onDismissed) {
                props.onDismissed(true);
            } else if (order && route.route) {
                route.route.history.push("/orders/" + order.id);
            } else if (order) {
                window.location.href = "/orders/" + order.id;
            } else if (route.route) {
                route.route.history.push("/orders");
            } else {
                window.location.href = "/orders";
            }
        } catch (e) {
            banner.add({
                key: 'create_order_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to create order. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    const _onSearchCompanies = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await CompanyService.retrieve(qs.join("&"))).data.map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_companies_error",
                text: "Failed to search related companies. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchCity = async (keyword: string) => {
        const cities = searchCities(keyword)
        return cities.map((item) => ({
            key: item.toLowerCase().replace(/\s+/g, ''),
            name: item,
        }));
    }

    const _onSearchCustomers = async (keyword: string) => {
        try {
            if (data.company) {
                keyword = keyword.toLowerCase();
                const users = await CompanyService.getUsers(data.company.id);
                return users.filter((user, idx) => {
                    return user.email.toLowerCase().indexOf(keyword) > -1 ||
                        user.name.toLowerCase().indexOf(keyword) > -1 ||
                        (user.phoneNumber || "").toLowerCase().indexOf(keyword) > -1;
                }).map((user) => {
                    return {
                        id: user.id,
                        text: user.name,
                        secondaryText: user.email,
                        imageInitials: GeneralService.getInitial(user.name),
                        metadata: user,
                    };
                });
            } else {
                return [];
            }
        } catch (error) {
            banner.add({
                key: "search_customers_error",
                text: "Failed to search related customers. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchSubsidiaries = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await SubsidiariesService.retrieve(qs.join("&"));

            return results.filter((d, idx) => idx < 10).map((subsidiaries) => {
                return {
                    id: subsidiaries.id,
                    text: subsidiaries.name,
                    secondaryText: subsidiaries.npwp,
                    imageInitials: GeneralService.getInitial(subsidiaries.name),
                    metadata: subsidiaries,
                };
            });
        } catch (e) {
            banner.add({
                key: 'search_subsidiaries_error',
                text: 'Failed to search related subsidiaries. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
            return [];
        }
    }

    return <Panel headerText={mode === 'create' ? "Create Order" : "Update Order"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating order ..." : "Updating order ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Stack tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Label required={true}>Subsidiary</Label>
                                <NormalPeoplePicker
                                    onResolveSuggestions={_onSearchSubsidiaries}
                                    onEmptyResolveSuggestions={() => _onSearchSubsidiaries('')}
                                    itemLimit={1}
                                    selectedItems={data.subsidiary ? [
                                        {
                                            id: data.subsidiary.id,
                                            text: data.subsidiary.name,
                                            secondaryText: data.subsidiary.npwp,
                                            imageInitials: GeneralService.getInitial(data.subsidiary.name),
                                            metadata: data.subsidiary,
                                        } as any,
                                    ] : []}
                                    disabled={submitting}
                                    onChange={(item?: any) => {
                                        data.subsidiary = item[0] ? item[0].metadata : undefined;
                                        setData({ ...data });

                                        return null;
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack className={styles.divider}> </Stack>
                        <Stack.Item>
                            <Label required={true}>Customer (Company)</Label>
                            {!data.company ? <TagPicker
                                selectedItems={[]}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onItemSelected={(item: any) => {
                                    data.company = item?.metadata;
                                    data.customer = undefined;
                                    setData({ ...data });

                                    return null;
                                }}
                                onResolveSuggestions={_onSearchCompanies}
                                onEmptyResolveSuggestions={() => _onSearchCompanies('')}
                                disabled={submitting}
                            /> : null}
                            {data.company ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                <Stack>
                                    <Label size={'small'}>{data.company.name}</Label>
                                </Stack>
                                <ActionButton disabled={submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                    data.company = undefined;
                                    data.customer = undefined;
                                    setData({ ...data });
                                }} />
                            </Stack> : null}
                        </Stack.Item>
                        <Stack.Item>
                            <Label required={true}>Customer (PIC)</Label>
                            {!data.customer ? <NormalPeoplePicker
                                onResolveSuggestions={_onSearchCustomers}
                                onEmptyResolveSuggestions={() => _onSearchCustomers('')}
                                itemLimit={1}
                                selectedItems={[]}
                                disabled={submitting || data.company === undefined}
                                onChange={(item?: any) => {
                                    data.customer = item[0] ? item[0].metadata : undefined;
                                    setData({ ...data });
                                    return null;
                                }}
                            /> : null}
                            {data.customer ? <Stack className={styles.selected} horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign='center'>
                                <Stack>
                                    <Label size={'small'}>{data.customer.name}</Label>
                                    <Text size={'small'}>{data.customer.email}</Text>
                                </Stack>
                                <ActionButton disabled={submitting} className={styles.deleteButton} iconProps={{ iconName: "Delete" }} onClick={() => {
                                    data.customer = undefined;
                                    setData({ ...data });
                                }} />
                            </Stack> : null}
                        </Stack.Item>
                    </Stack>
                    <Stack className={styles.divider}> </Stack>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item styles={{ root: { width: '50%' } }}>
                                <Dropdown
                                    label='Freight Category'
                                    required
                                    options={freightCategories.map((opt) => {
                                        return { key: opt.id, text: opt.name };
                                    })}
                                    selectedKey={data.freightCategory?.key}
                                    disabled={submitting || mode === 'update'}
                                    onChange={(evt, opt) => {
                                        data.freightCategory = opt;
                                        setData({ ...data });
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: '50%' } }}>
                                <Dropdown
                                    label='Delivery Type'
                                    required
                                    options={deliveryTypes.map((opt) => {
                                        return { key: opt.id, text: opt.name };
                                    })}
                                    selectedKey={data.deliveryType?.key}
                                    disabled={submitting || mode === 'update'}
                                    onChange={(evt, opt) => {
                                        data.deliveryType = opt;
                                        data.goodsType = "";
                                        setData({ ...data });
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack.Item>
                            <Dropdown
                                label='Goods Type'
                                required
                                options={DeliveredGoodsType.filter((dgt) => {
                                    if ((data.deliveryType?.text || "").toLowerCase() === 'shipping') {
                                        return dgt.key !== 'ftl' && dgt.key !== 'ltl';
                                    } else {
                                        return true;
                                    }
                                })}
                                selectedKey={data.goodsType}
                                disabled={submitting || mode === 'update'}
                                onChange={(evt, opt) => {
                                    data.goodsType = opt ? opt.key as string : "";
                                    setData({ ...data });
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label={"Batch"}
                                value={data.batch}
                                onChange={(evt, value) => {
                                    data.batch = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.batch = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.batch}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label={"Notes"}
                                value={data.notes}
                                onChange={(evt, value) => {
                                    data.notes = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.notes = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.notes}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                    <Stack className={styles.divider}> </Stack>
                    <Stack>
                        <Label size={'small'}>Pick-up Location</Label>
                        <DetailsList items={data.originAddresses || []} columns={addressColumns} selectionMode={SelectionMode.none} />
                    </Stack>
                    <Stack>
                        <Label size={'small'}>Drop-off Location</Label>
                        <DetailsList items={data.destinationAddresses || []} columns={addressColumns} selectionMode={SelectionMode.none} />
                    </Stack>
                    {/*<Stack className={styles.divider}> </Stack>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item styles={{ root: { width: 150 } }}>
                                <Dropdown label={"Pick-up Location"}
                                    options={[
                                        { key: 'door', text: 'Door' },
                                        { key: 'yard', text: 'Container Yard' },
                                        { key: 'port', text: 'Port' }
                                    ]}
                                    selectedKey={data.originType}
                                    onChange={(evt, opt) => {
                                        data.originType = opt ? opt.key as string : "door";
                                        setData({ ...data });
                                    }}
                                    disabled={submitting} />
                            </Stack.Item>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow={1}>
                                    <TextField label={"Pick-up Address"}
                                        value={data.originAddress}
                                        required
                                        multiline
                                        rows={5}
                                        autoAdjustHeight
                                        resizable={false}
                                        onChange={(evt, value) => {
                                            data.originAddress = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                            error.originAddress = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }}
                                        errorMessage={error.originAddress}
                                        disabled={submitting} />
                                </Stack.Item>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <Stack styles={{ root: { width: '50%' } }}>
                                        <Label size={'small'}>Province</Label>
                                        <TagPicker selectedItems={(data.originProvince || "").trim() !== '' ? [{ key: data.originProvince || "", name: data.originProvince || "" }] : []}
                                            removeButtonAriaLabel='Remove'
                                            itemLimit={1}
                                            onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                                            onChange={(items) => {
                                                data.originProvince = items && items[0] ? items[0].name : "";
                                                setData({ ...data });
                                            }}
                                            onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')}
                                        />
                                    </Stack>
                                    <Stack styles={{ root: { width: '50%' } }}>
                                        <Label size={'small'}>City</Label>
                                        <TagPicker disabled={(data.originProvince || "").trim() === ""}
                                            selectedItems={(data.originCity || "").trim() !== '' ? [{ key: data.originCity || "", name: data.originCity || "" }] : []}
                                            removeButtonAriaLabel='Remove'
                                            itemLimit={1}
                                            onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [data.originProvince || ""])}
                                            onChange={(items) => {
                                                data.originCity = items && items[0] ? items[0].name : "";
                                                setData({ ...data });
                                            }}
                                            onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'city', [data.originProvince || ""])}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item styles={{ root: { width: 150 } }}>
                                <Dropdown label={"Drop-off Door/Port"}
                                    options={[
                                        { key: 'door', text: 'Door' },
                                        { key: 'yard', text: 'Container Yard' },
                                        { key: 'port', text: 'Port' }
                                    ]}
                                    selectedKey={data.destinationType}
                                    onChange={(evt, opt) => {
                                        data.destinationType = opt ? opt.key as string : "door";
                                        setData({ ...data });
                                    }}
                                    disabled={submitting} />
                            </Stack.Item>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow={1}>
                                    <TextField label={"Drop-off Address"}
                                        value={data.destinationAddress}
                                        required
                                        multiline
                                        rows={5}
                                        autoAdjustHeight
                                        resizable={false}
                                        onChange={(evt, value) => {
                                            data.destinationAddress = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                            error.destinationAddress = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }}
                                        errorMessage={error.destinationAddress}
                                        disabled={submitting} />
                                </Stack.Item>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <Stack styles={{ root: { width: '50%' } }}>
                                        <Label size={'small'}>Province</Label>
                                        <TagPicker selectedItems={(data.destinationProvince || "").trim() !== '' ? [{ key: data.destinationProvince || "", name: data.destinationProvince || "" }] : []}
                                            removeButtonAriaLabel='Remove'
                                            itemLimit={1}
                                            onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'province')}
                                            onChange={(items) => {
                                                data.destinationProvince = items && items[0] ? items[0].name : "";
                                                setData({ ...data });
                                            }}
                                            onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'province')}
                                        />
                                    </Stack>
                                    <Stack styles={{ root: { width: '50%' } }}>
                                        <Label size={'small'}>City</Label>
                                        <TagPicker disabled={(data.destinationProvince || "").trim() === ""}
                                            selectedItems={(data.destinationCity || "").trim() !== '' ? [{ key: data.destinationCity || "", name: data.destinationCity || "" }] : []}
                                            removeButtonAriaLabel='Remove'
                                            itemLimit={1}
                                            onResolveSuggestions={(keyword) => CityService.getOptions('indonesia', keyword, 'city', [data.destinationProvince || ""])}
                                            onChange={(items) => {
                                                data.destinationCity = items && items[0] ? items[0].name : "";
                                                setData({ ...data });
                                            }}
                                            onEmptyResolveSuggestions={() => CityService.getOptions('indonesia', '', 'city', [data.destinationProvince || ""])}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>*/}
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderForm;
