const PermissionsService = {
    hasPermission: (requiredRoles: string[], roles: string[]): boolean => {
        let has = false;

        for (var ctr=0; ctr<roles.length; ctr++) {
            const idx = requiredRoles.findIndex((rr) => rr.toLowerCase() === roles[ctr].toLowerCase());
            if (idx > -1) {
                has = true;
                break;
            }
        };

        return has;
    }
}

export default PermissionsService;