import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import NotificationList from '../../components/notifications/general/list';
import { useStore } from '../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';

type TypeNotificationPageProps = {
  children?: React.ReactNode;
};

const NotificationPage: React.FC<TypeNotificationPageProps> = (props) => {
  const { topbar, banner, user } = useStore();

  useEffect(() => {
    topbar.show("Notifications", [
      { key: "home", icon: faHome, href: "/" },
      { key: "notifications", text: 'Notifications' }
    ]);
  }, []);
  return <Stack>
    <NotificationList />
  </Stack>
};

export default NotificationPage;