import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';
import moment from 'moment';
import OutcomeTypes from '../../../../../../manifests/outcomeTypes';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import OutcomeService from '../../../../../../services/finance/outcomes';

// props
import { IOutcomeResourceShortProps } from '../../../../../../props/finance/outcomes';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import LoadingComponent from '../../../../../feedbacks/loading';
import ValidationService from '../../../../../../services/validation';

interface IActivateOutcomeInvoiceFormProps {
    invoice: IOutcomeResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const ActivateOutcomeInvoiceForm: React.FC<IActivateOutcomeInvoiceFormProps> = (props: IActivateOutcomeInvoiceFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            await OutcomeService.activate(props.invoice.id);

            banner.add({
                key: 'activate_outcome_invoice_success',
                variant: 'success',
                icon: faCheck,
                text: `Invoice "${props.invoice.name}" re-activated successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={"Activate Invoice"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Activate"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Activating outcomes ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Preparing form ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Text size={'small'}>Are you sure you want to re-activate invoice with details below?</Text>
                <Stack.Item>
                    <Label size={'xsmall'}>Outcome Details</Label>
                    <Text size={'small'}>{props.invoice.name}</Text>
                </Stack.Item>
                {props.invoice.invoiceNumber ? <Stack.Item>
                    <Label size={'xsmall'}>Invoice Number</Label>
                    <Text size={'small'}>{props.invoice.invoiceNumber}</Text>
                </Stack.Item> : null}
                <Stack.Item>
                    <Label size={'xsmall'}>Type</Label>
                    <Text size={'small'}>{OutcomeTypes.find((ot) => ot.key === props.invoice.type)?.text || "-"}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Amount</Label>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(props.invoice.amount))}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Due Date</Label>
                    <Text size={'small'}>{GeneralService.formatDate(moment(props.invoice.dueDate).toDate())}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Bank Account</Label>
                    <Text size={'small'}>{props.invoice.bankAccount ? `${props.invoice.bankAccount.accountName}` : null}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Notes</Label>
                    <Text size={'small'}>{props.invoice.notes || "-"}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label size={'xsmall'}>Cancellation Justification</Label>
                    <Text size={'small'}>{props.invoice.justification || "-"}</Text>
                </Stack.Item>
            </> : null}
        </Stack>
    </Panel>
};

export default ActivateOutcomeInvoiceForm;
