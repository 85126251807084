import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { useParams } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import CompanyDetails from '../../../components/users/companies/general/details';

interface CompanyDetailsPageProps {
}

const CompanyDetailsPage: React.FC<CompanyDetailsPageProps> = (props: CompanyDetailsPageProps) => {
    const {topbar} = useStore();
    const params: any = useParams();

    useEffect(() => {
        topbar.show("Companies", [
            {key: "home", icon: faHome, href: "/"},
            {key: "users", text: 'Users'},
            {key: "companies", text: 'Companies'}
        ]);
    }, []);

    return <Stack>
        <CompanyDetails companyId={params.companyId} />
    </Stack>;
};

export default CompanyDetailsPage;
