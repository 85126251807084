import { Checkbox, DefaultButton, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import React, { useEffect } from 'react';
import { IUserResourceShortProps } from '../../../../../../props/user';
import { useStore } from '../../../../../../stores/root';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import ValidationService from '../../../../../../services/validation';
import { EmployeeBankAccountService } from '../../../../../../services/users/bankAccount';
import Text from '../../../../../typography/text';
import { IEmployedBankAccountResourceShort } from '../../../../../../props/users/user';

type TypeEmployeeBankAccountFormProps = {
  children?: React.ReactNode;
  bankAccount?: IEmployedBankAccountResourceShort;
  employeeId: string
  onDismissed(refresh?: boolean): void;
};

type FormDataProps = {
  bankName: string;
  bankCity: string;
  accountNumber: string;
  isDefault?: '0' | '1'
}

type FormDataErrorProps = {
  bankName?: string;
  bankCity?: string;
  accountNumber?: string;
}

const EmployeeBankAccountForm: React.FC<TypeEmployeeBankAccountFormProps> = (props) => {
  const mode: 'create' | 'update' = props.bankAccount === undefined ? 'create' : 'update';
  const { banner } = useStore();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [data, setData] = React.useState<FormDataProps>({
    bankName: '',
    bankCity: '',
    accountNumber: '',
  });
  const [error, setError] = React.useState<FormDataErrorProps>({});

  useEffect(() => {
    getBankAccount()
    return () => { }
  }, [])

  const isSubmitButtonDisabled = () => {
    if (error.bankName || error.bankCity || error.accountNumber) {
      return true;
    } else if (data.bankName.trim() === '') {
      return true;
    } else if (data.bankCity.trim() === '') {
      return true;
    } else if (data.accountNumber.trim() === '') {
      return true;
    }
    // else if (data.employee === undefined) {
    //   return true;
    // }

    return false;
  }

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      const { bankName, accountNumber, bankCity, isDefault } = data;

      let fd = new FormData();
      fd.append("bankName", bankName);
      fd.append("accountNumber", accountNumber);
      fd.append("bankCity", bankCity);
      fd.append("employeeId", props.employeeId || '');
      isDefault && fd.append("isDefault", isDefault);

      if (props.bankAccount === undefined) {
        await EmployeeBankAccountService.new(props.employeeId, fd);
      } else {
        await EmployeeBankAccountService.update(props.employeeId, props.bankAccount.id, fd);
      }
      banner.add({
        key: mode + '_employee_success',
        variant: 'success',
        icon: faCheck,
        text: `Employee "${data.bankName}" ${mode}ed successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  const getBankAccount = async () => {
    if (props.bankAccount && props.employeeId) {
      try {
        const item = await EmployeeBankAccountService.read(props.employeeId, props.bankAccount.id)
        setData({
          bankName: item.bankName,
          accountNumber: item.accountNumber,
          bankCity: item.bankCity,
          isDefault: item.isDefault,
        })
        setLoaded(true)
      } catch (error) {

      }
    } else {
      setLoaded(true)
    }
  }

  return (
    <Panel headerText={mode === 'create' ? 'Create Employee Bank Account' : 'Update Employee Bank Account'}
      isOpen={true}
      type={PanelType.medium}
      onDismiss={() => props.onDismissed(false)}
      isFooterAtBottom={true}
      onRenderFooterContent={() => {
        return <Stack horizontal tokens={{ childrenGap: 10 }}>
          {
            !submitting ? (
              <>
                <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
              </>
            ) : null
          }
          {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating employee ..." : "Updating employee ..."} /> : null}
        </Stack>;
      }}>
      <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
        {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
        {
          loaded ? <>
            <Stack.Item>
              <TextField label={"Name"}
                required={true}
                value={data.bankName}
                onChange={(evt, value) => {
                  data.bankName = value || "";

                  const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                  error.bankName = validation.message;

                  setData({ ...data });
                  setError({ ...error });
                }}
                errorMessage={error.bankName}
                disabled={submitting}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField label={"Account Number"}
                required={true}
                value={data.accountNumber}
                onChange={(evt, value) => {
                  data.accountNumber = value || "";

                  const validation = ValidationService.combination(value, ['required', 'limit', 'number'], { maxChars: 100 });
                  error.accountNumber = validation.message;

                  setData({ ...data });
                  setError({ ...error });
                }}
                errorMessage={error.accountNumber}
                disabled={submitting}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField label={"City"}
                required={true}
                value={data.bankCity}
                onChange={(evt, value) => {
                  data.bankCity = value || "";

                  const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                  error.bankCity = validation.message;

                  setData({ ...data });
                  setError({ ...error });
                }}
                errorMessage={error.bankCity}
                disabled={submitting}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Checkbox checked={data.isDefault && data.isDefault === '1'} onChange={(e, c) => {
                  if (c) {
                    setData({ ...data, isDefault: '1' })
                  } else {
                    setData({ ...data, isDefault: '0' })
                  }
                }} />
                <Text>Set as default</Text>
              </Stack>
            </Stack.Item>
            {/* <Stack.Item>
              <Label required>Employee</Label>
              <TagPicker
                selectedItems={data.employee ? [({ key: data.employee.id, name: data.employee.name, metadata: data.employee }) as any] : []}
                removeButtonAriaLabel='Remove'
                inputProps={{
                  multiple: false
                }}
                onChange={(items?: any) => {
                  data.employee = (items || []).map((item: any) => item.metadata)[0];
                  setData({ ...data });
                }}
                onResolveSuggestions={_onSearchEmployees}
                onEmptyResolveSuggestions={() => _onSearchEmployees('')}
              />
            </Stack.Item> */}
          </> : null
        }
      </Stack>

    </Panel>
  )
};

export default EmployeeBankAccountForm;