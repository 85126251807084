import { IGPSModuleResourceShortProps } from '../../props/gps/modules';
import Request from './../request';

// import props

const GPSModuleService = {
    retrieve: async (qs?: string): Promise<IGPSModuleResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/gps/modules?${qs || ''}`);
            return results;
        } catch(e) {
            throw(e);
        }
    }
}

export default GPSModuleService;