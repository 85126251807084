import { Persona, Stack } from '@fluentui/react';
import React, {  } from 'react';

// assets

import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Heading from '../../typography/heading';
import Breadcrumb from '../breadcrumb';
import { useStore } from '../../../stores/root';
import { NavLink } from 'react-router-dom';

interface ITopbarProps {
}

const Topbar: React.FC<ITopbarProps> = observer((props: ITopbarProps) => {
    const store = useStore();
    const {title, breadcrumb} = store.topbar;

    return <Stack className={styles.container} horizontal horizontalAlign='space-between' verticalAlign='center'>
        <Stack className={styles.separator}> </Stack>
        <Stack className={styles.left}>
            {title ? <Heading variant='h2'>{title}</Heading> : null}
            {breadcrumb.length > 0 ? <Breadcrumb items={breadcrumb} /> : null}
        </Stack>
        <Stack className={styles.right}>
            <NavLink to={"/profile"}><Persona imageInitials='AT' hidePersonaDetails /></NavLink>
        </Stack>
    </Stack>;
});

export default Topbar;
