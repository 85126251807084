import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import RolesList from '../../../components/settings/roles/general/list';
import PermissionsService from '../../../services/permissions';
import NoAccess from '../../../components/uiframeworks/noAccess';
import OutcomeCategoriesList from '../../../components/finances/outcomes/categories/general/list';

type OutcomeCategoriesListPageProps = {
}

const OutcomeCategoriesListPage: React.FC<OutcomeCategoriesListPageProps> = (props: OutcomeCategoriesListPageProps) => {
    const {topbar, user} = useStore();

    useEffect(() => {
        topbar.show("Outcome Categories", [
            {key: "home", icon: faHome, href: "/"},
            {key: "settings", text: 'Settings', href: "/settings"},
            {key: "outcomeCategories", text: 'Outcome Categories'}
        ])
    }, []);

    const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.read.all'], user.permissions);

    return <Stack>
        {hasPermission ? <OutcomeCategoriesList /> : <NoAccess />}
    </Stack>;
};

export default OutcomeCategoriesListPage;
