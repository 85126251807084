import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IOrderResourcerShorterProps, IOrderResourceShortProps } from '../../../../../props/orders/order';

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import Label from '../../../../typography/label';
import { TSelectedFilterKey } from '.';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';

type OrdersDashboardChartFilterSubsidiaryProps = {
    orders: IOrderResourceShortProps[];
    filteredOrders: IOrderResourceShortProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: ISubsidiariesResourceShort[]): void;
}

const OrdersDashboardChartFilterSubsidiary: React.FC<OrdersDashboardChartFilterSubsidiaryProps> = (props: OrdersDashboardChartFilterSubsidiaryProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<ISubsidiariesResourceShort[]>([]);

    useEffect(() => {

    }, [props.orders]);

    const getUniqueSubsidiaries = (): ISubsidiariesResourceShort[] => {
        const subsidiaries: ISubsidiariesResourceShort[] = [];

        props.orders.forEach((order) => {
            const exist = subsidiaries.find((c) => c.id === order.subsidiary?.id);
            if (!exist && order.subsidiary) {
                subsidiaries.push(order.subsidiary);
            }
        });

        return subsidiaries;
    }

    const renderOption = (subsidiary: ISubsidiariesResourceShort) => {
        const isChecked = selected.findIndex((s) => s.id === subsidiary.id) > -1;
        const totalItems = props.filteredOrders.filter((o) => o.subsidiary.id === subsidiary.id).length;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, subsidiary)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{subsidiary.name} ({totalItems})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, subsidiary: ISubsidiariesResourceShort) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== subsidiary.id);
        } else {
            _selected.push(subsidiary);
        }

        setSelected([..._selected]);
        props.onChange('subsidiary', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Subsidiaries</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search subsidiary ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueSubsidiaries().filter((c) => c.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((subsidiary) => {
                return renderOption(subsidiary);
            })}
        </Stack>
    </Stack>;
};

export default OrdersDashboardChartFilterSubsidiary;
