import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

// assets

// services
import UserService from '../../../../../services/users/user';
import ErrorService from '../../../../../services/general/error';

// props
import { IUserResourceShortProps } from '../../../../../props/users/user';
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import Text from '../../../../typography/text';
import AdministratorForm from '../form';
import Tag from '../../../../uiframeworks/tag';
import ResetAdministratorPasswordForm from '../resetPassword';
import Pagination from '../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../config';
import VerifyUserForm from '../../../general/verify';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';

interface IAdministratorsListProps {
}

const AdministratorsList: React.FC<IAdministratorsListProps> = (props: IAdministratorsListProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState<IUserResourceShortProps[]>([]);
    const [activeUser, setActiveUser] = React.useState<IUserResourceShortProps | undefined>();
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['administrators.read.all'], user.permissions);

    const columns: IColumn[] = [
        {
            key: "email",
            fieldName: "email",
            name: "Email",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.email}>
                        <NavLink to={`/users/administrators/${item.id}`}>
                            <Text>{item.email}</Text>
                        </NavLink>
                    </TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IUserResourceShortProps) => {
                let menuItems: IContextualMenuItem[] = [];

                if (PermissionsService.hasPermission(['administrators.update.all'], user.permissions)) {
                    menuItems.push({
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveUser(item);
                            setActiveSurface('update');
                        }
                    });

                    menuItems.push({
                        key: "resetPassword", text: "Reset password", iconProps: { iconName: "Permissions" }, onClick: () => {
                            setActiveUser(item);
                            setActiveSurface('resetPassword');
                        }
                    });

                    if (!item.emailVerifiedAt) {
                        menuItems.push({
                            key: "verify", text: "Verify user", iconProps: { iconName: "CheckMark" }, onClick: () => {
                                setActiveUser(item);
                                setActiveSurface('verify');
                            }
                        })
                    }
                }

                return <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: "name",
            fieldName: "name",
            name: "Nama",
            minWidth: 200,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.name}><Text>{item.name}</Text></TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "address",
            fieldName: "address",
            name: "Alamat",
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.address}><Text>{item.address || "-"}</Text></TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "phoneNumber",
            fieldName: "phoneNumber",
            name: "No. Telepon",
            minWidth: 125,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <TooltipHost content={item.phoneNumber}><Text>{item.phoneNumber || "-"}</Text></TooltipHost>
                </Stack.Item>;
            }
        },
        {
            key: "subsidiaries",
            fieldName: "subsidiaries",
            name: "Subsidiaries",
            minWidth: 200,
            isMultiline: true,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                    {(item.subsidiaries || []).map((subsidiary) => <Tag key={subsidiary.id} text={subsidiary.name} />)}
                </Stack>;
            }
        },
        {
            key: "roles",
            fieldName: "roles",
            name: "Role",
            minWidth: 150,
            isMultiline: true,
            onRender: (item: IUserResourceShortProps) => {
                return <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
                    {item.roles.map((role) => <Tag key={role.id} text={role.name} />)}
                </Stack>;
            }
        }
    ];

    useEffect(() => {
        _onRetrieveUsers();
    }, [keyword]);

    const _onRetrieveUsers = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            const qs: string[] = [];
            qs.push(`rolesGroup=backoffice`);
            qs.push(`top=${GlobalConfig.defaultTop}`);
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const results = await UserService.retrieve(qs.join("&"));
            setUsers(results.data);
            setPagination(results.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'administrators_list_error',
                text: 'Failed to retrieve administrators list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        if (PermissionsService.hasPermission(['administrators.create'], user.permissions)) {
            farItems.push({
                key: "register",
                text: "Create Administrator",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Administrator"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveUser(undefined);

        if (refresh) { _onRetrieveUsers() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 10 }}>
                {pagination && users.length > 0 ? <Pagination goToPage={_onRetrieveUsers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " administrator(s) found"} /> : null}
                <Stack className={styles.list}>
                    {
                        !loaded || (loaded && users.length > 0) ? (
                            <>
                                <ShimmeredDetailsList
                                    setKey="items"
                                    items={users}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    enableShimmer={!loaded}
                                    onShouldVirtualize={() => false}
                                    ariaLabelForShimmer="Content is being fetched"
                                    ariaLabelForGrid="Item details" />
                            </>
                        ) : null
                    }
                    {loaded && users.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                        <Text>Administrator(s) not found</Text>
                    </Stack> : null}
                </Stack>
                {pagination && users.length > 0 ? <Pagination goToPage={_onRetrieveUsers} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
            </Stack>
            {activeSurface === 'create' ? <AdministratorForm onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'update' && activeUser ? <AdministratorForm userId={activeUser.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'resetPassword' && activeUser ? <ResetAdministratorPasswordForm user={activeUser} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'verify' && activeUser ? <VerifyUserForm user={activeUser} onDismissed={_onSurfaceDismissed} /> : null}
        </> : <NoAccess />}
    </Stack>;
};

export default AdministratorsList;
