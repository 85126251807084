import React, { useEffect, useState } from 'react';

// assets
import Logo from './../../../assets/logo/logo-dark.webp';

import styles from './style.module.scss';
import { Stack } from '@fluentui/react';

export type LabelProps = React.PropsWithChildren<{
    size?: 'xsmall' | 'small' | 'medium' | 'large';
    className?: string;
    style?: React.CSSProperties;
    required?: boolean;
    showLine?: boolean;
    onClick?(): void;
}>

const Label: React.FC<LabelProps> = (props: LabelProps) => {
    if (!props.showLine) {
        return <label onClick={props.onClick} className={`${styles.label} ${styles[props.size || 'medium']} ${props.required ? styles.required : ''} ${props.className || ""} ${props.onClick ? styles.clickable : ""}`} style={props.style}>
            {props.children}
        </label>
    } else {
        return <Stack tokens={{childrenGap: 10}} className={styles.labelContainer} horizontal verticalAlign='center'>
            <Stack className={styles.line} styles={{root: {width: 20}}}></Stack>
            <label onClick={props.onClick} className={`${styles.label} ${styles[props.size || 'medium']} ${props.required ? styles.required : ''} ${props.className || ""} ${props.onClick ? styles.clickable : ""}`} style={props.style}>
                {props.children}
            </label>
            <Stack className={styles.line} grow={1}></Stack>
        </Stack>
    }
};

export default Label;
