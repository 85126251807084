import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/root';
import styles from './styles.module.scss';

// assets

// props

// components
import { Stack } from '@fluentui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '../../typography/text';

interface IBannerProps {
}

export type BannerProps = {
    key?: string;
    variant?: 'success' | 'warning' | 'error' | 'default' | 'light';
    text?: string;
    icon?: IconProp;
    timeout?: number;
    content?: JSX.Element[] | (() => JSX.Element[]);
};

const Banner: React.FC<IBannerProps> = observer((props: IBannerProps) => {
    const { banner } = useStore();

    const renderBanner = (item: BannerProps) => {
        return <Stack horizontal key={item.key}>
            <Stack className={`${styles.item} ${styles[item.variant || 'default']}`} horizontal tokens={{ childrenGap: 8 }}>
                {item.icon ? <FontAwesomeIcon icon={item.icon} style={{marginTop: 4}} /> : null}
                {item.text && !item.content ? <Text style={{whiteSpace: 'pre-wrap'}}>{item.text}</Text> : null}
                {item.content ? item.content : null}
            </Stack>
        </Stack>;
    }

    return <>
        {banner.items.length > 0 ? <Stack className={styles.container} tokens={{ childrenGap: 10 }}>
            {banner.items.map(renderBanner)}
        </Stack> : null}
    </>;
});

export default Banner;
