import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';
import WeightTypes from '../../../../manifests/weightTypes';

// assets
import { faCheck, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../services/validation';
import GoodsService from '../../../../services/goods/goods';
import QuantityUnitService from '../../../../services/general/quantityUnit';
import CompanyService from '../../../../services/users/company';
import GeneralService from '../../../../services/general';
import ErrorService from '../../../../services/general/error';

// props
import { ICompanyResourceShort } from '../../../../props/users/company';
import { IUserResourceShortProps } from '../../../../props/users/user';
import { IWarehouseResourceShortProps } from '../../../../props/warehouses/warehouse';
import { IQuantityUnitResourceShortProps } from '../../../../props/general/quantityUnit';

// components
import { DatePicker, DefaultButton, Dropdown, NormalPeoplePicker, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import moment from 'moment';
import WarehouseService from '../../../../services/warehouses';
import { IGoodsResourceShortProps } from '../../../../props/goods/goods';

interface IGoodsFormProps {
    goodsId?: string;
    onDismissed(refresh?: boolean, goods?: IGoodsResourceShortProps): void;
}

type FormDataProps = {
    name: string;
    company?: ICompanyResourceShort;
    customer?: IUserResourceShortProps;
    notes: string;
    quantityAmount: string;
    quantityUnit: string;
    weightAmount: string;
    weightUnit: string;
    warehouse?: IWarehouseResourceShortProps;
    locationDetails: string;
    length: string;
    width: string;
    height: string;
    supplier: string;
    deliveryDate?: string;
}

type FormDataErrorProps = {
    name?: string;
    company?: string;
    customer?: string;
    notes?: string;
    quantityAmount?: string;
    quantityUnit?: string;
    weightAmount?: string;
    weightUnit?: string;
    warehouse?: string;
    length?: string;
    width?: string;
    height?: string;
    supplier?: string;
    deliveryDate?: string;
    locationDetails?: string;
}

const GoodsForm: React.FC<IGoodsFormProps> = (props: IGoodsFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [quantityUnits, setQuantityUnits] = React.useState<IQuantityUnitResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        notes: "",
        quantityAmount: "",
        quantityUnit: "",
        weightAmount: "",
        weightUnit: "kg",
        length: "",
        width: "",
        height: "",
        supplier: "",
        locationDetails: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.goodsId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _quantityUnits = await QuantityUnitService.retrieve();
        setQuantityUnits(_quantityUnits);

        if (props.goodsId) {
            const _goods = await GoodsService.get(props.goodsId);

            setData({
                name: _goods.name,
                company: _goods.company,
                customer: _goods.user,
                notes: _goods.notes || "",
                quantityAmount: _goods.quantityAmount != undefined ? _goods.quantityAmount + "" : "",
                quantityUnit: _goods.quantityUnit || "",
                weightAmount: _goods.weightAmount != undefined ? _goods.weightAmount + "" : "",
                weightUnit: _goods.weightUnit || "",
                warehouse: _goods.warehouse,
                length: _goods.length != undefined ? _goods.length + "" : "",
                width: _goods.width != undefined ? _goods.width + "" : "",
                height: _goods.height != undefined ? _goods.height + "" : "",
                supplier: _goods.supplier || "",
                deliveryDate: _goods.deliveryDate,
                locationDetails: _goods.locationDetails || ""
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.company || error.customer ||
            error.notes || error.quantityAmount || error.quantityUnit ||
            error.weightAmount || error.weightUnit || error.warehouse ||
            error.length || error.width || error.height || error.supplier ||
            error.deliveryDate || error.locationDetails) {
            return true;
        } else if (data.name === '' || !data.company || !data.customer || data.quantityAmount === '' || data.quantityUnit === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, company, customer, notes, quantityAmount, quantityUnit, weightAmount, weightUnit, length, width, height, warehouse, locationDetails, supplier, deliveryDate } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name.toUpperCase());
            if (company) {
                fd.append("companyId", company.id);
            }
            if (customer) {
                fd.append("userId", customer.id);
            }
            fd.append("notes", notes);
            fd.append("quantityAmount", quantityAmount);
            fd.append("quantityUnit", quantityUnit);
            fd.append("weightAmount", weightAmount);
            fd.append("weightUnit", weightUnit);
            fd.append("length", length);
            fd.append("width", width);
            fd.append("height", height);
            if (warehouse) {
                fd.append("warehouseId", warehouse.id);
            }
            fd.append("locationDetails", locationDetails);
            fd.append("supplier", supplier.toUpperCase());
            if (deliveryDate) {
                fd.append("deliveryDate", deliveryDate);
            }

            let goods = undefined;
            if (props.goodsId === undefined) {
                goods = await GoodsService.create(fd);
            } else {
                goods = await GoodsService.update(props.goodsId, fd);
            }

            banner.add({
                key: mode + '_goods_success',
                variant: 'success',
                icon: faCheck,
                text: `Goods "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true, goods);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const _onSearchWarehouse = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await WarehouseService.retrieve.get(qs.join("&"))).map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_warehouse_error",
                text: "Failed to search related warehouse. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchCompanies = async (keyword: string) => {
        try {
            const qs: string[] = [];
            if (keyword && keyword.trim() !== '') { qs.push(`search=${keyword}`) }

            const data = (await CompanyService.retrieve(qs.join("&"))).data.map((item) => ({
                key: item.id,
                name: item.name,
                metadata: item
            }));

            return data;
        } catch (error) {
            banner.add({
                key: "search_companies_error",
                text: "Failed to search related companies. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onSearchCustomers = async (keyword: string) => {
        try {
            if (data.company) {
                keyword = keyword.toLowerCase();
                const users = await CompanyService.getUsers(data.company.id);
                return users.filter((user, idx) => {
                    return user.email.toLowerCase().indexOf(keyword) > -1 ||
                        user.name.toLowerCase().indexOf(keyword) > -1 ||
                        (user.phoneNumber || "").toLowerCase().indexOf(keyword) > -1;
                }).map((user) => {
                    return {
                        id: user.id,
                        text: user.name,
                        secondaryText: user.email,
                        imageInitials: GeneralService.getInitial(user.name),
                        metadata: user,
                    };
                });
            } else {
                return [];
            }
        } catch (error) {
            banner.add({
                key: "search_customers_error",
                text: "Failed to search related customers. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Goods' : 'Update Goods'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating goods ..." : "Updating goods ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <Label required={true}>Name</Label>
                        <TextField value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label required={true}>Owned By (Company)</Label>
                            <TagPicker
                                selectedItems={data.company ? [
                                    {
                                        key: data.company.id,
                                        name: data.company.name,
                                        metadata: data.company
                                    } as any,
                                ] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onItemSelected={(item: any) => {
                                    data.company = item ? item.metadata : undefined;
                                    data.customer = undefined;
                                    setData({ ...data });

                                    return null;
                                }}
                                onChange={(items) => {
                                    if (items && items.length < 1) {
                                        data.company = undefined;
                                        data.customer = undefined;
                                        setData({ ...data });
                                    }
                                }}
                                onResolveSuggestions={_onSearchCompanies}
                                onEmptyResolveSuggestions={() => _onSearchCompanies('')}
                                disabled={submitting || mode === 'update'}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label required={true}>Customer (PIC)</Label>
                            <NormalPeoplePicker
                                onResolveSuggestions={_onSearchCustomers}
                                onEmptyResolveSuggestions={() => _onSearchCustomers('')}
                                itemLimit={1}
                                selectedItems={data.customer ? [
                                    {
                                        id: data.customer.id,
                                        text: data.customer.name,
                                        secondaryText: data.customer.email,
                                        imageInitials: GeneralService.getInitial(data.customer.name),
                                        metadata: data.customer,
                                    } as any,
                                ] : []}
                                disabled={submitting || data.company === undefined || mode === 'update'}
                                onChange={(item?: any) => {
                                    data.customer = item[0] ? item[0].metadata : undefined;
                                    setData({ ...data });

                                    return null;
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <TextField label={"Notes"}
                            multiline={true}
                            autoAdjustHeight
                            resizable={false}
                            value={data.notes}
                            rows={3}
                            onChange={(evt, value) => {
                                data.notes = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.notes = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.notes}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item grow={1}>
                            <TextField label={'Quantity'}
                                required={true}
                                value={data.quantityAmount}
                                onChange={(evt, value) => {
                                    if ((value || "") === "" || !isNaN(Number(value))) {
                                        data.quantityAmount = value || "";

                                        const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                        error.quantityAmount = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }
                                }}
                                errorMessage={error.quantityAmount}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: 150 } }}>
                            <Dropdown styles={{ root: { marginTop: 32 } }}
                                options={quantityUnits.map((qu) => {
                                    return { key: qu.name, text: qu.name }
                                })}
                                selectedKey={data.quantityUnit}
                                onChange={(evt, opt) => {
                                    const value = opt ? opt.key as string : "";
                                    data.quantityUnit = value;

                                    const validation = ValidationService.combination(value, ['required'], {});
                                    error.quantityUnit = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.quantityUnit}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item grow={1}>
                            <TextField label={'Weight (per quantity)'}
                                value={data.weightAmount}
                                onChange={(evt, value) => {
                                    if ((value || "") === "" || !isNaN(Number(value))) {
                                        data.weightAmount = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 15 });
                                        error.weightAmount = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }
                                }}
                                errorMessage={error.weightAmount}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: 150 } }}>
                            <Dropdown styles={{ root: { marginTop: 32 } }}
                                options={WeightTypes}
                                selectedKey={data.weightUnit}
                                onChange={(evt, opt) => {
                                    const value = opt ? opt.key as string : "";
                                    data.weightUnit = value;

                                    setData({ ...data });
                                }}
                                errorMessage={error.weightUnit}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <TextField label={'Length'}
                                value={data.length}
                                suffix={'cm'}
                                onChange={(evt, value) => {
                                    if ((value || "") === "" || !isNaN(Number(value))) {
                                        data.length = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 15 });
                                        error.length = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }
                                }}
                                errorMessage={error.length}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <TextField label={'Width'}
                                value={data.width}
                                suffix={'cm'}
                                onChange={(evt, value) => {
                                    if ((value || "") === "" || !isNaN(Number(value))) {
                                        data.width = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 15 });
                                        error.width = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }
                                }}
                                errorMessage={error.width}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '25%' } }}>
                            <TextField label={'Height'}
                                value={data.height}
                                suffix={'cm'}
                                onChange={(evt, value) => {
                                    if ((value || "") === "" || !isNaN(Number(value))) {
                                        data.height = value || "";

                                        const validation = ValidationService.combination(value, ['limit'], { maxChars: 15 });
                                        error.height = validation.message;

                                        setData({ ...data });
                                        setError({ ...error });
                                    }
                                }}
                                errorMessage={error.height}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack styles={{ root: { width: '25%', marginTop: 5 } }} tokens={{ childrenGap: 13 }}>
                            <Label>Volume (m3)</Label>
                            <Text>{GeneralService.getNumberWithSeparator(GeneralService.calculateVolume(data.length, data.width, data.height))} m3</Text>
                        </Stack>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Warehouse Location</Label>
                            <TagPicker
                                selectedItems={data.warehouse ? [
                                    {
                                        key: data.warehouse.id,
                                        name: data.warehouse.name,
                                        metadata: data.warehouse
                                    } as any,
                                ] : []}
                                removeButtonAriaLabel='Remove'
                                itemLimit={1}
                                onItemSelected={(item: any) => {
                                    data.warehouse = item ? item.metadata : undefined;
                                    setData({ ...data });

                                    return null;
                                }}
                                onChange={(items) => {
                                    if (items && items.length < 1) {
                                        data.warehouse = undefined;
                                        setData({ ...data });
                                    }
                                }}
                                onResolveSuggestions={_onSearchWarehouse}
                                onEmptyResolveSuggestions={() => _onSearchWarehouse('')}
                                disabled={submitting}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Label>Location Details</Label>
                            <TextField value={data.locationDetails}
                                onChange={(evt, value) => {
                                    data.locationDetails = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.locationDetails = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.locationDetails}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                    <Stack className={'divider'}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <TextField label={'Supplier'}
                                value={data.supplier}
                                onChange={(evt, value) => {
                                    data.supplier = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.supplier = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.supplier}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <DatePicker label={'Delivery Date'}
                                value={data.deliveryDate ? moment(data.deliveryDate).toDate() : undefined}
                                onSelectDate={(date) => {
                                    data.deliveryDate = date ? moment(date).toISOString() : undefined;
                                    setData({ ...data });
                                }}
                                formatDate={GeneralService.formatDate}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                </> : null
            }
        </Stack>
    </Panel>
};

export default GoodsForm;
