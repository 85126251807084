import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import OrderService from '../../../../services/orders/order';
import OrderStatusService from '../../../../services/orders/orderStatus';

// props
import { IOrderResourceShortProps } from '../../../../props/orders/order';
import { IOrderStatusResourceShortProps } from '../../../../props/orders/orderStatus';

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';

interface IUpdateOrderStatusFormProps {
    order: IOrderResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    status: string;
}

type FormDataErrorProps = {
    status?: string;
}

const UpdateOrderStatusForm: React.FC<IUpdateOrderStatusFormProps> = (props: IUpdateOrderStatusFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<IOrderStatusResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        status: props.order.status.id
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _status = await OrderStatusService.retrieve();
            setStatus(_status.filter((s) => s.key !== 'cancelled'));
            setLoaded(true);
        } catch (e) {

        }
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append('statusId', data.status);
            await OrderService.update(props.order.id, fd);

            banner.add({
                key: 'activate_order_success',
                variant: 'success',
                icon: faCheck,
                text: `Order "${OrderService.getOrderNumber(props.order.orderNumber)}" is now active`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.status) {
            return true;
        } else if (data.status === '') {
            return true;
        }

        return false;
    }

    return <Panel headerText={"Update Order Status"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"updating order status ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <LoadingComponent label='Preparing form ...' labelPosition='right' spinnerPosition='baseline' /> : null}
            {loaded ? <>
                <Stack.Item>
                    <Label>Order Number</Label>
                    <Text>{OrderService.getOrderNumber(props.order.orderNumber)}</Text>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Company</Label>
                        <Text>{props.order.company?.name || "-"}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <Label>Customer (PIC)</Label>
                        <Text>{props.order.customer.name || "-"}</Text>
                    </Stack.Item>
                </Stack>
                <Stack.Item>
                    <Dropdown label={"Status"}
                        required={true}
                        options={status.map((s) => {
                            return { key: s.id, text: s.name }
                        })}
                        disabled={submitting}
                        selectedKey={data.status}
                        onChange={(evt, opt) => {
                            data.status = opt ? opt.key as string : "";
                            setData({ ...data });
                        }} />
                </Stack.Item>
            </> : null}
        </Stack>
    </Panel>
};

export default UpdateOrderStatusForm;
