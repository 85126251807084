import { PrimaryButton, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../stores/root';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { observer } from 'mobx-react';
import OffersList from '../../components/offers/general/list';

interface OffersListPageProps {
}

const OffersListPage: React.FC<OffersListPageProps> = observer((props: OffersListPageProps) => {
    const { topbar, banner, user } = useStore();

    useEffect(() => {
        topbar.show("Offers", [
            { key: "home", icon: faHome, href: "/" },
            { key: "offers", text: 'Offers' }
        ]);
    }, []);

    return <Stack tokens={{ childrenGap: 20 }}>
        <OffersList />
    </Stack>;
});

export default OffersListPage;
