import { Checkbox, Icon, IconButton, ISelection, Stack, TooltipHost, Selection, ShimmeredDetailsList, SelectionMode, IColumn, ActionButton, CommandBar, ICommandBarItemProps } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

// assets

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import { faFile, faLock } from '@fortawesome/pro-light-svg-icons';
import GeneralService from '../../../../services/general';
import { IDocumentProps } from '../../../../props/general';
import moment from 'moment';
import { useStore } from '../../../../stores/root';
import LoadingDialogComponent from '../../../feedbacks/loadingDialog';
import RenderImage from '../renderImage';
import Tag from '../../tag';
import DocumentService from '../../../../services/documents/document';
import FilePreview from '../preview';

export type TTagVariant = 'default' | 'active' | 'success' | 'light' | 'error' | 'warning' | 'transparent';

export type DocumentModeView = 'list' | 'card'

type IFilesListProps = {
    files: IDocumentProps[];
    selected?: IDocumentProps[];
    loaded?: boolean;
    mode?: DocumentModeView
    onSelectFile?(selected?: IDocumentProps[]): void;
    hideCommandBar?: boolean;
    hideSelectViewButton?: boolean;
    size?: 'small' | 'medium' | 'large';
    hideTag?: boolean;
    hideVisibility?: boolean;
    documentTypes?: {
        key: string;
        text: string;
    }[];
}

const FilesList: React.FC<IFilesListProps> = (props: IFilesListProps) => {
    const { banner } = useStore();

    const inputDocumentRef = React.useRef<HTMLInputElement>(null);
    const [files, setFiles] = React.useState<IDocumentProps[]>(props.files);
    const [selected, setSelected] = React.useState<IDocumentProps[]>(props.selected || []);
    const [loaded, setLoaded] = React.useState<boolean>(props.loaded || false);
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [mode, setMode] = useState<DocumentModeView>(props.mode || 'card');
    const [selectedPreview, setSelectedPreview] = useState<IDocumentProps>();
    const [activeSurface, setActiveSurface] = useState<string | undefined>();

    const size = props.size || 'large';
    let gap = 20;
    if (size === 'medium') {
        gap = 15;
    } else if (size === 'small') {
        gap = 10;
    }

    const selection: ISelection = new Selection({
        onSelectionChanged: () => {
            setSelected(selection.getSelection() as IDocumentProps[])
            props.onSelectFile && props.onSelectFile(selection.getSelection() as IDocumentProps[])
        },
    });

    useEffect(() => {
        setMode(props.mode || 'card')
        return () => { }
    }, [props.mode])

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    useEffect(() => {
        setLoaded(props.loaded || false);
    }, [props.loaded]);

    useEffect(() => {
        setSelected(props.selected || []);
    }, [props.selected]);

    const _onPreview = (file: IDocumentProps) => {
        setSelectedPreview(file);
        setActiveSurface('preview');
    }

    const _onClick = async (file: IDocumentProps) => {
        let _selected = selected;
        const idx = _selected.findIndex((sel) => sel.path === file.path);
        if (idx > -1) {
            _selected.splice(idx, 1);
        } else {
            _selected.push(file);
        }

        if (props.onSelectFile) {
            props.onSelectFile(_selected);
        } else {
            setSelected([..._selected]);
        }
    }

    const _onDownload = async (file: any) => {
        try {
            const blobData = (await DocumentService.get(file.path)).data.data
            const byteCharacters = atob(blobData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);

            const blobURL = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = blobURL;
            downloadLink.download = file.basename;
            downloadLink.click();

            URL.revokeObjectURL(blobURL);
            /*const arrayBuffer = new Uint8Array([...atob(blobData)].map(char => char.charCodeAt(0)))
            const fileLink = document.createElement('a');
            
            fileLink.href = window.URL.createObjectURL(new Blob([arrayBuffer]))
            fileLink.setAttribute('download', "example.pdf")
            document.body.appendChild(fileLink)
            fileLink.click();
            URL.revokeObjectURL(blobURL);*/
        } catch (error) {
            console.log(error)
        }
    }

    const renderFileCard = (file: IDocumentProps) => {

        const type = GeneralService.getFileType(file.basename);
        const documentType = (props.documentTypes || []).find(f => (f.key || "").toLowerCase() === (file.type || "").toLowerCase());
        const isSelected = selected.find((sel) => sel.path === file.path) !== undefined;

        return <Stack key={file.basename} className={`${styles.card} ${styles[size]}`}>
            <Checkbox onChange={() => _onClick(file)} checked={isSelected} className={`${styles.checkbox} ${isSelected ? styles.selected : ''}`} styles={{ checkbox: { borderRadius: '50%', background: isSelected ? undefined : '#fff', width: 25, height: 25 } }} />
            <IconButton
                className={styles.download}
                iconProps={{ iconName: "Download" }}
                onClick={() => _onDownload(file)}
            />
            <Stack onClick={() => _onPreview(file)} className={`${styles.fileImage} ${styles["bg" + type]}`}>
                {type === 'word' ? <Icon className={styles.icon} iconName='WordDocument' /> : null}
                {type === 'excel' ? <Icon className={styles.icon} iconName='ExcelDocument' /> : null}
                {type === 'powerpoint' ? <Icon className={styles.icon} iconName='PowerPointDocument' /> : null}
                {type === 'pdf' ? <Icon className={styles.icon} iconName='PDF' /> : null}
                {type === 'video' ? <Icon className={styles.icon} iconName='Video' /> : null}
                {type === 'file' ? <FontAwesomeIcon className={styles.icon} icon={faFile} /> : null}
                {type === 'image' ? <RenderImage path={file.path} className={styles.image} /> : null}
            </Stack>
            <Stack className={styles.content} onClick={() => _onPreview(file)} tokens={{ childrenGap: 3 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'} verticalAlign='center'>
                    {file.uploadedAt ? <Text size={'xsmall'}>{moment(file.uploadedAt).format("DD/MM/YYYY HH:mm")}</Text> : null}
                    {size !== 'small' ? <Text size={'xsmall'}>{GeneralService.getFriendlyFileSizeText(file.size, true)}</Text> : null}
                    {size !== 'small' ? <Stack horizontal tokens={{ childrenGap: 5 }}>
                        {props.hideTag ? null : <Tag variant='default'>
                            <Stack>
                                <Text size={'xsmall'}>{documentType ? documentType.text : file.type}</Text>
                            </Stack>
                        </Tag>}
                        {props.hideVisibility || file.visibility !== 'private' ? null : <Tag variant='default'>
                            <Stack styles={{ root: { height: '100%', width: '100%' } }} horizontalAlign='center' verticalAlign='center'>
                                <FontAwesomeIcon icon={faLock} size='2xs' />
                            </Stack>
                        </Tag>}
                    </Stack> : null}
                </Stack>
                <TooltipHost content={<Text size={'small'}>{file.basename}</Text>}>
                    <Stack className={styles.name}>
                        {size === 'large' ? <Text className={styles.text} size={'medium'}>{file.basename}</Text> : null}
                        {size === 'medium' ? <Text className={styles.text} size={'small'}>{file.basename}</Text> : null}
                        {size === 'small' ? <Text className={styles.text} size={'xsmall'}>{file.basename}</Text> : null}
                    </Stack>
                </TooltipHost>
            </Stack>
        </Stack>
    }

    const columns: IColumn[] = [
        {
            key: "image",
            name: "",
            minWidth: 100,
            maxWidth: 100,
            onRender: (file: IDocumentProps) => {
                const type = GeneralService.getFileType(file.basename);
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Stack className={`${styles.fileImage} ${styles["bg" + type]}`}>
                        {type === 'word' ? <Icon iconName='WordDocument' /> : null}
                        {type === 'excel' ? <Icon iconName='ExcelDocument' /> : null}
                        {type === 'powerpoint' ? <Icon iconName='PowerPointDocument' /> : null}
                        {type === 'video' ? <Icon iconName='Video' /> : null}
                        {type === 'pdf' ? <Icon iconName='PDF' /> : null}
                        {type === 'file' ? <FontAwesomeIcon icon={faFile} /> : null}
                        {type === 'image' ? <RenderImage path={file.path} style={{
                            width: 50,
                            height: 50
                        }} /> : null}
                    </Stack>
                </Stack.Item>;
            }
        },
        {
            key: "ame",
            fieldName: "ame",
            name: "Name",
            minWidth: 400,
            maxWidth: 400,
            onRender: (file: IDocumentProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{file.basename}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "type",
            fieldName: "type",
            name: "Type",
            minWidth: 100,
            maxWidth: 100,
            onRender: (file: IDocumentProps) => {
                const type = (props.documentTypes || []).find(f => f.key === file.type);
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Stack horizontal>
                        <Tag variant='default'>
                            <Stack>
                                <Text size={'xsmall'}>{type ? type.text : file.type}</Text>
                            </Stack>
                        </Tag>
                    </Stack>
                </Stack.Item>;
            }
        },
        {
            key: "size",
            fieldName: "size",
            name: "Size",
            minWidth: 100,
            maxWidth: 100,
            onRender: (file: IDocumentProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{GeneralService.getFriendlyFileSizeText(file.size, true)}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "expery_date",
            fieldName: "expery_date",
            name: "Expired Date",
            minWidth: 100,
            maxWidth: 100,
            onRender: (file: IDocumentProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{file.expired_date && GeneralService.getFriendlyDateFormat(file.expired_date)}</Text>
                </Stack.Item>;
            }
        },
        {
            key: "download",
            fieldName: "download",
            name: "Download",
            minWidth: 200,
            maxWidth: 200,
            onRender: (file: IDocumentProps) => {
                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <IconButton
                        className={styles.download}
                        iconProps={{ iconName: "Download" }}
                        onClick={() => _onDownload(file)}
                    />
                </Stack.Item>;
            }
        },
    ];

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (files.length > 0) {
            items.push({
                key: 'selectView',
                onRender: () => {
                    return <ActionButton text={mode === 'card' ? 'Card view' : 'List view'} menuProps={{
                        items: [
                            {
                                key: 'emailMessage',
                                text: 'Card view',
                                onClick: () => {
                                    setMode('card')
                                }
                            },
                            {
                                key: 'calendarEvent',
                                text: 'List view',
                                onClick: () => {
                                    setMode('list')
                                }
                            },
                        ],
                    }} />
                }
            })
        }

        return { items, farItems };
    }

    return <Stack className={styles.container} tokens={{childrenGap: 20}}>
        {props.hideCommandBar ? null : <CommandBar styles={{root: {padding: 0, height: 'unset', backgroundColor: 'transparent'}}} {...getCommandBarItems()} />}
        {!loaded ? <LoadingComponent label='Retrieving files ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {mode === 'card' && loaded && files.length > 0 ? <Stack horizontal tokens={{ childrenGap: gap }} wrap>
            {files.map(renderFileCard)}
        </Stack> : null}
        {mode === 'list' && loaded && files.length > 0 ? <Stack tokens={{ childrenGap: 20 }} wrap>
            <ShimmeredDetailsList
                setKey="items"
                items={files}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                enableShimmer={!loaded}
                selection={selection}
                onShouldVirtualize={() => false}
                ariaLabelForShimmer="Sedang mengambil data"
                ariaLabelForGrid="Detail Item" />
        </Stack> : null}
        {loaded && files.length < 1 ? <Text>File(s) not found</Text> : null}
        {downloading ? <LoadingDialogComponent title='Downloading File' secondaryText="Please wait while we're downloading selected file ..." /> : null}
        {activeSurface === 'preview' && selectedPreview ? <FilePreview file={selectedPreview} onDismissed={() => {
            setSelectedPreview(undefined);
            setActiveSurface(undefined);
        }} /> : null}
    </Stack>;
};

export default FilesList;