import Request from './../request';

// import props
import {
    IRoleResourceShortProps
} from './../../props/roles/role';

const RoleService = {
    retrieve: async ():Promise<IRoleResourceShortProps[]> => {
        try {
            const results = await Request.get(`/api/roles`);
            return results;
        } catch(e) {
            throw(e);
        }
    },
    create: async (data:FormData):Promise<IRoleResourceShortProps> => {
        try {
            const result = await Request.post(`/api/roles`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    update: async (id:string, data:FormData):Promise<IRoleResourceShortProps> => {
        try {
            const result = await Request.post(`/api/roles/${id}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch(e) {
            throw(e);
        }
    },
    delete: async (id:string):Promise<void> => {
        try {
            await Request.delete(`/api/roles/${id}`);
            return;
        } catch(e) {
            throw(e);
        }
    }
}

export default RoleService;