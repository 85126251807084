import React, { useEffect } from 'react';

// assets

// services
import UserService from '../../../../../services/users/user';
import ErrorService from '../../../../../services/general/error';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';

// components
import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import RoleService from '../../../../../services/roles';
import ValidationService from '../../../../../services/validation';
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';
import PermissionsService from '../../../../../services/permissions';
import NoAccess from '../../../../uiframeworks/noAccess';
import { IUserResourceShortProps } from '../../../../../props/users/user';
import Label from '../../../../typography/label';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';
import ISubsidiariesResourceShort from '../../../../../props/data/subsidiaries';

interface IAdministratorFormProps {
    userId?: string;
    onDismissed(refresh?: boolean, user?: IUserResourceShortProps): void;
}

type FormDataProps = {
    subsidiaries: ISubsidiariesResourceShort[];
    email: string;
    name: string;
    roles: IDropdownOption[];
    password: string;
    address: string;
    phoneNumber: string;
    percentageFee: string;
}

type FormDataErrorProps = {
    email?: string;
    name?: string;
    password?: string;
    roles?: string;
    address?: string;
    phoneNumber?: string;
    percentageFee?: string;
    subsidiaries?: string;
}

const AdministratorForm: React.FC<IAdministratorFormProps> = (props: IAdministratorFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRoleResourceShortProps[]>([]);
    const [data, setData] = React.useState<FormDataProps>({
        email: "",
        name: "",
        roles: [],
        password: "",
        address: "",
        phoneNumber: "",
        percentageFee: "",
        subsidiaries: []
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.userId === undefined ? 'create' : 'update';

    let hasPermission = false;
    if (mode === 'create') {
        hasPermission = PermissionsService.hasPermission(['administrators.create'], user.permissions);
    } else if (mode === 'update') {
        hasPermission = PermissionsService.hasPermission(['administrators.update.all'], user.permissions);
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _roles = await RoleService.retrieve();
        if (props.userId) {
            const _user = await UserService.get(props.userId);
            setData({
                email: _user.email,
                name: _user.name,
                address: _user.address || "",
                roles: _user.roles.map((role) => {
                    return { key: role.id, text: role.name }
                }),
                password: "",
                phoneNumber: _user.phoneNumber || "",
                percentageFee: _user.percentageFee + "",
                subsidiaries: _user.subsidiaries || []
            });
        }

        setRoles(_roles);
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.email || error.address || error.phoneNumber || error.roles || error.password) {
            return true;
        } else if (data.name === '' || data.email === '' || (data.roles.length < 1)) {
            return true;
        } else if (mode === 'create' && data.password === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, roles, email, password, address, phoneNumber, percentageFee, subsidiaries } = data;

            // create form data
            let fd = new FormData();
            fd.append("rolesId", JSON.stringify(roles ? roles.map((role) => { return role.key as string; }) : []));
            fd.append("email", email);
            fd.append("name", name);
            if (password && password.length > 0) {
                fd.append("password", password);
            }
            fd.append("address", address);
            fd.append("phoneNumber", phoneNumber);
            if (percentageFee && percentageFee !== "") {
                fd.append("percentageFee", percentageFee);
            }
            
            subsidiaries.forEach((subsidiary) => {
                fd.append("subsidiariesId[]", subsidiary.id);
            })

            let result;
            if (props.userId === undefined) {
                result = await UserService.new(fd);
            } else {
                result = await UserService.update(props.userId, fd);
            }

            banner.add({
                key: 'create_administration_success',
                variant: 'success',
                icon: faCheck,
                text: `Administrator "${name}" created successfully`
            });

            props.onDismissed(true, result);
        } catch (e) {
            banner.add({
                key: 'create_administration_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? "Create Administrator" : "Update Administrator"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating administrator ..." : "Updating administrator ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack.Item>
                        <TextField label={"Email Address"}
                            required={true}
                            value={data.email}
                            onChange={(evt, value) => {
                                data.email = value || "";

                                const validation = ValidationService.combination(value, ['required', 'email', 'limit'], { maxChars: 100 });
                                error.email = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.email}
                            disabled={submitting} />
                    </Stack.Item>
                    {mode === 'create' ? <Stack.Item>
                        <TextField label={"Password"}
                            required={true}
                            value={data.password}
                            onChange={(evt, value) => {
                                data.password = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                error.password = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.password}
                            description={"This is a one-time password only. Password will be send to user's email and will need to update their password once logged in."}
                            disabled={submitting} />
                    </Stack.Item> : null}
                    <Stack className={'divider'} />
                    <Label size={'small'}>Employee Details</Label>
                    <Stack.Item>
                        <Dropdown label={"Roles"}
                            required={true}
                            selectedKeys={data.roles ? data.roles.map((role) => {
                                return role.key as string;
                            }) : undefined}
                            options={roles.map((role) => {
                                return {
                                    id: role.key,
                                    key: role.id,
                                    text: role.name
                                };
                            })}
                            disabled={submitting}
                            errorMessage={error.roles}
                            multiSelect
                            onChange={(evt, opt) => {
                                let selected = data.roles;
                                if (opt && !opt.selected && selected) {
                                    const index = selected.findIndex((role) => { return role.key === opt.key });
                                    selected.splice(index, 1);
                                } else if (opt && opt.selected && selected) {
                                    selected.push(opt);
                                } else if (opt && opt.selected && !selected) {
                                    selected = [opt];
                                }
                                data.roles = selected;

                                const validation = ValidationService.combination(selected, ['required'], {});
                                error.roles = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                    </Stack.Item>
                    <SelectSubsidiary label={'Related subsidiaries'}
                        required={true}
                        selecteds={data.subsidiaries}
                        errorMessage={error.subsidiaries}
                        multiple
                        disabled={submitting}
                        onChangeMultiple={(subsidiaries) => {
                            data.subsidiaries = subsidiaries || [];

                            const validation = ValidationService.combination(subsidiaries, ['required'], {});
                            error.subsidiaries = validation.message;

                            setData({ ...data });
                            setError({ ...error });
                        }} />
                    {/*<Stack.Item>
                        <TextField label={"Default Percentage Fee (per order)"}
                            required={false}
                            value={data.percentageFee}
                            errorMessage={error.percentageFee}
                            onChange={(evt, value) => {
                                if (value === "" || !isNaN(Number(value))) {
                                    data.percentageFee = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.percentageFee = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            disabled={submitting} />
                        </Stack.Item>*/}
                    <Stack className={'divider'} />
                    <Label size={'small'}>Personal Details</Label>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";

                                const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                error.name = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Address"}
                            required={false}
                            multiline
                            rows={5}
                            value={data.address}
                            errorMessage={error.address}
                            onChange={(evt, value) => {
                                data.address = value || "";

                                const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                error.address = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Phone Number"}
                            required={false}
                            value={data.phoneNumber}
                            errorMessage={error.phoneNumber}
                            onChange={(evt, value) => {
                                if (value === "" || !isNaN(Number(value))) {
                                    data.phoneNumber = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 100 });
                                    error.phoneNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default AdministratorForm;
