import DocumentService from '../documents/document';
import GeneralConfig from './../../config';

declare var window: any;

const FileService = {
    getPublicUrl: async (url: string) => {
        const blob = (await DocumentService.get(url)).data.data
        return `data:image/jpeg;base64,${blob}`;
    },
    arrayBufferToBase64: (buffer: ArrayBuffer): string => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    },
    getBase64: async (file: File) => {
        let results: string[] = [];
        const base64 = FileService.arrayBufferToBase64((await file.arrayBuffer()));
        const extension = file.name.toLowerCase().split(".").pop();

        if (extension === 'pdf') {
            results = await FileService.convertPdfToImage(base64);
        } else if (['jpg', 'jpeg', 'png'].indexOf(extension || "") > -1) {
            results = [`data:image/${extension};base64,${base64}`];
        }

        return results;
    },
    getImageBase64: async (base64: string, extension: string) => {
        let results: string[] = [];

        if (extension === 'pdf') {
            results = await FileService.convertPdfToImage(base64);
        } else if (['jpg', 'jpeg', 'png'].indexOf(extension || "") > -1) {
            results = [`data:image/${extension};base64,${base64}`];
        }

        return results;
    },
    convertPdfToImage: async (base64: string): Promise<string[]> => {
        const imagesList: string[] = [];
        const pdf = await window["pdfjs-dist/build/pdf"].getDocument({ data: atob(base64) }).promise;

        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");

        for (let i = 1; i <= pdf.numPages; i++) {
            var page = await pdf.getPage(i);
            var viewport = page.getViewport({ scale: 1.5 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport,
            };

            await page.render(render_context).promise;
            let img = canvas.toDataURL("image/png");
            imagesList.push(img);
        }

        return imagesList;
    }
};

export default FileService;