import React from 'react';
import { useStore } from '../../../../../../stores/root';
import { EmployeeContractService } from '../../../../../../services/users/employee';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { IEmployedContractResourceShort } from '../../../../../../props/users/user';
import moment from 'moment';

interface IDeleteContractFormProps {
  contract: IEmployedContractResourceShort;
  onDismissed(refresh?: boolean): void;
}

const DeleteContractForm: React.FC<IDeleteContractFormProps> = (props: IDeleteContractFormProps) => {
  const { banner } = useStore();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      await EmployeeContractService.delete(props.contract.employeeId, props.contract.id);

      banner.add({
        key: 'delete_contract_success',
        variant: 'success',
        icon: faCheck,
        text: `Contract number ${props.contract.contractNumber} for ${props.contract.employee?.name} deleted successfully`
      });
      props.onDismissed(true);
    } catch (e) {
      setSubmitting(false);
    }
  }

  return <Panel headerText={"Delete Contract"}
    isOpen={true}
    type={PanelType.medium}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting ? (
            <>
              <PrimaryButton text={"Delete"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Deleting contract ..."} /> : null}
      </Stack>;
    }}>
    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
      <Text>Are you sure you want to delete contract with details below?</Text>
      <Stack.Item>
        <Label>Contract Number</Label>
        <Text>{props.contract.contractNumber}</Text>
      </Stack.Item>
      <Stack.Item>
        <Label>Status</Label>
        <Text>{props.contract.status}</Text>
      </Stack.Item>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item styles={{ root: { width: '50%' } }}>
          <Label>Start From</Label>
          <Text>{moment(props.contract.startDate).format('DD/MM/YYYY')}</Text>
        </Stack.Item>
        <Stack.Item >
          <Label>Until</Label>
          <Text>{moment(props.contract.endDate).format('DD/MM/YYYY')}</Text>
        </Stack.Item>
      </Stack>
      <Stack className='divider'></Stack>
    </Stack>
  </Panel>
};

export default DeleteContractForm;
