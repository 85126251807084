import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

// assets

import { useStore } from '../../../stores/root';
import ClientsList from '../../../components/settings/clients/general/list';
import { faHome } from '@fortawesome/pro-light-svg-icons';

interface IClientsListPageProps {
}

const ClientsListPage: React.FC<IClientsListPageProps> = (props: IClientsListPageProps) => {
  const { topbar } = useStore();

  useEffect(() => {
    topbar.show("Public Client", [
      { key: "home", icon: faHome, href: "/" },
      { key: "settings", text: 'Settings', href: "/settings" },
      { key: "clients", text: 'Clients' }
    ])
  }, []);

  return (
    <Stack>
      <ClientsList />
    </Stack>
  );
};

export default ClientsListPage;
