import Request from './../../request';
import { IDocumentProps, IPaginationResourceShort } from '../../../props/general';
import { IOutcomeResourceProps, IOutcomeResourceShortProps } from '../../../props/finance/outcomes';
import { IVersionHistoryResourceShortProps } from '../../../props/general/versionHistory';
import { IVendorResourceShortProps } from '../../../props/data/vendors';

const OutcomeService = {
    retrieve: async (qs?: string): Promise<{ pagination: IPaginationResourceShort, data: IOutcomeResourceShortProps[] }> => {
        try {
            const result = await Request.get(`/api/outcomes/invoices?${qs || ''}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    extract: async (data: FormData): Promise<{
        invoiceNumber?: string;
        totalAmount?: number;
        subtotalAmount?: number;
        name?: string;
        dueDate?: string;
        createdDate?: string;
        ppnAmount?: number;
        pphAmount?: number;
        includePPn?: boolean;
        includePPh?: boolean;
        ppnPercentage?: number;
        pphPercentage?: number;
        vendor?: IVendorResourceShortProps;
    }> => {
        try {
            const response = await Request.post(`/api/outcomes/invoices/extract`, data, 'multipart/form-data');
            let result: {
                invoiceNumber?: string;
                totalAmount?: number;
                subtotalAmount?: number;
                name?: string;
                dueDate?: string;
                createdDate?: string;
                ppnAmount?: number;
                pphAmount?: number;
                includePPn?: boolean;
                includePPh?: boolean;
                ppnPercentage?: number;
                pphPercentage?: number;
                vendor?: IVendorResourceShortProps;
            } = response;

            if (result.totalAmount === result.subtotalAmount) {

            } else if (!result.includePPh && !result.includePPn) {

            } else if (result.includePPh || result.includePPn) {
                if ((result.subtotalAmount || 0) === (result.totalAmount || 0) - (result.pphAmount || 0)) {
                    result.includePPh = true;
                    result.includePPn = true;
                } else if ((result.subtotalAmount || 0) + (result.ppnAmount || 0) - (result.pphAmount || 0) === (result.totalAmount || 0)) {
                    result.includePPh = true;
                    result.includePPn = false;
                } else if ((result.subtotalAmount || 0) + (result.ppnAmount || 0) + (result.pphAmount || 0) === (result.totalAmount || 0)) {
                    result.includePPh = false;
                    result.includePPn = false;
                }
            }

            return result;
        } catch (e) {
            throw (e);
        }
    },
    calculatePrice: (data: Partial<IOutcomeResourceShortProps>) => {
        const _data = data;

        let originalPrice = "0";
        let totalAmount = "0";
        let amount = "0";

        if (_data.includePPn) {
            if (_data.ppnPercentage && data.ppnPercentage !== 'custom') {
                originalPrice = (Number(_data.originalAmount) * 100 / (100 + Number(_data.ppnPercentage))).toFixed();
            } else {
                originalPrice = (Number(_data.originalAmount) - Number(_data.ppn)).toFixed();
            }
        } else if (!_data.includePPn) {
            originalPrice = (_data.originalAmount || "") + "";
        }

        let ppn = "0";
        if (_data.includePPn && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(originalPrice) * (Number(_data.ppnPercentage) / 100)).toFixed();
        } else if (!_data.includePPn && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(_data.originalAmount) * (Number(_data.ppnPercentage) / 100)).toFixed();
        }
        _data.ppn = (ppn);

        let pph = "0";
        if (_data.pphPercentage && data.pphPercentage !== 'custom') {
            pph = (Number(originalPrice) * (Number(_data.pphPercentage) / 100)).toFixed();
        }
        _data.pph = (pph);

        let finalPrice = 0;
        if (_data.includePPn && _data.includePPh) {
            finalPrice = Number(_data.originalAmount);
        } else if (_data.includePPn && !_data.includePPh) {
            finalPrice = Number(_data.originalAmount) + Number(_data.pph);
        } else if (!_data.includePPn && _data.includePPh) {
            finalPrice = Number(_data.originalAmount) + Number(_data.ppn);
        } else {
            finalPrice = Number(_data.originalAmount) + Number(_data.ppn) + Number(_data.pph);
        }
        totalAmount = finalPrice.toFixed(2);

        let invoicePrice = finalPrice - Number(_data.pph);
        amount = invoicePrice.toFixed(2);

        return {
            amount: Number(amount),
            totalAmount: Number(totalAmount),
            ppn: Number(ppn),
            pph: Number(pph)
        }
    },
    get: async (outcomeId: string): Promise<IOutcomeResourceProps> => {
        try {
            const result = await Request.get(`/api/outcomes/invoices/${outcomeId}`);
            return result;
        } catch (e) {
            throw (e);
        }
    },
    create: async (data: FormData): Promise<IOutcomeResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/invoices`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    update: async (outcomeId: string, data: FormData): Promise<IOutcomeResourceShortProps> => {
        try {
            const result = await Request.post(`/api/outcomes/invoices/${outcomeId}?_method=PUT`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw (e);
        }
    },
    activate: async (id: string): Promise<void> => {
        try {
            await Request.post(`/api/outcomes/invoices/${id}/activate`);
            return;
        } catch (e) {
            throw (e);
        }
    },
    cancel: async (id: string, data: FormData): Promise<void> => {
        try {
            await Request.post(`/api/outcomes/invoices/${id}/cancel`, data);
            return;
        } catch (e) {
            throw (e);
        }
    },
    delete: async (id: string): Promise<void> => {
        try {
            await Request.delete(`/api/outcomes/invoices/${id}`);
            return;
        } catch (e) {
            throw (e);
        }
    },
    versions: {
        retrieve: async(id: string): Promise<IVersionHistoryResourceShortProps[]> => {
            try {
                const results = await Request.get(`/api/outcomes/invoices/${id}/versions`);
                return results;
            } catch (e) {
                throw (e);
            }
        }
    },
    documents: {
        retrieve: async (id: string, qs?: string): Promise<IDocumentProps[]> => {
            try {
                const result = await Request.get(`/api/outcomes/invoices/${id}/documents?${qs || ''}`);
                return result;
            } catch (e) {
                throw (e);
            }
        },
        new: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/outcomes/invoices/${id}/documents`, data, 'multipart/form-data');
                return result;
            } catch (e) {
                throw (e);
            }
        },
        delete: async (id: string, data: FormData): Promise<IDocumentProps> => {
            try {
                const result = await Request.post(`/api/outcomes/invoices/${id}/delete_documents`, data);
                return result;
            } catch (e) {
                throw (e);
            }
        }
    }
};


export default OutcomeService;