import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props
import { IPaginationResourceShort } from '../../../../../props/general';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, Persona, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from '../../../../typography/text';
import ClientForm from '../form';
import { IPublicClientResourceshort } from '../../../../../props/users/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import ClientService from '../../../../../services/users/clients';
import GeneralService from '../../../../../services/general';
import GlobalConfig from '../../../../../config';
import CustomPersona from '../../../../uiframeworks/persona';

interface IClientsListProps {
}

const ClientsList: React.FC<IClientsListProps> = (props: IClientsListProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [showingMore, setShowingMore] = React.useState<boolean>(false);
    const [clients, setClients] = React.useState<IPublicClientResourceshort[]>([]);
    const [activeClient, setActiveClient] = React.useState<IPublicClientResourceshort | undefined>();
    const [pagination, setPagingation] = React.useState<IPaginationResourceShort | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: 'prifilePicture',
            name: 'Profile Picture',
            minWidth: 250,
            maxWidth: 250,
            onRender: (item: IPublicClientResourceshort) => {
                return (
                    <Stack>
                        <CustomPersona imageInitials={GeneralService.getInitial(item.name)} imageUrl={item.image_url} />
                    </Stack>
                );
            },
        },
        {
            key: 'name',
            name: 'Name',
            minWidth: 250,
            maxWidth: 250,
            onRender: (item: IPublicClientResourceshort) => {
                return (
                    <Stack>
                        <Text>{item.name}</Text>
                    </Stack>
                );
            },
        },
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IPublicClientResourceshort) => {
                let menuItems: IContextualMenuItem[] = [
                    {
                        key: "update", text: "Update details", iconProps: { iconName: "Edit" }, onClick: () => {
                            setActiveClient(item);
                            setActiveSurface('update');
                        },
                    },
                    {
                        key: "delete",
                        text: "Delete",
                        iconProps: { iconName: "Delete" },
                        onClick: () => {
                            onDelete(item)
                        },
                    }
                ];

                return (
                    <Stack.Item styles={{ root: { padding: '2px 0px' } }}>
                        <Stack.Item className={"detailsListActionRow"}>
                            <ActionButton className={'detailsListActionButton'} menuProps={{
                                items: menuItems
                            }} />
                        </Stack.Item>
                    </Stack.Item>
                );
            }
        },
    ];

    const onDelete = async (item: IPublicClientResourceshort) => {
        if (window.confirm(`Hapus ${item.name}?`)) {
            setClients([...clients.filter(f => f.id !== item.id)])
            try {
                await ClientService.delete(item.id)
            } catch (error) {
            }
        }
    }

    useEffect(() => {
        _onRetrieveClients();
    }, []);

    useEffect(() => {
        _onRetrieveClients();
    }, [keyword]);

    const _onRetrieveClients = async (pageNumber?: number,) => {
        const qs: string[] = [];
        if (pageNumber) { qs.push(`page=${pageNumber}`); }
        if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }
        try {
            const items = await ClientService.retrieve(qs.join("&"))
            setClients(items)

            setPagingation(undefined);
            setLoaded(true);
        } catch (error) {

        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: () => {
                return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
            }
        });

        farItems.push({
            key: "register",
            text: "Create Client",
            iconProps: { iconName: "Add" },
            onRender: () => {
                return <PrimaryButton text={"Create Client"}
                    iconProps={{ iconName: "Add" }}
                    onClick={() => { setActiveSurface('create') }}
                    styles={{ root: { marginLeft: 10 } }} />;
            }
        });

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActiveClient(undefined);

        if (refresh) { _onRetrieveClients() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" />
        <Stack className={styles.list}>
            {
                !loaded || (loaded && clients.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={clients}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && clients.length < 1 ? <Stack styles={{ root: { padding: 10 } }}>
                <Text>Client(s) not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'create' ? <ClientForm onDismissed={_onSurfaceDismissed} /> : null}
        {activeSurface === 'update' && activeClient ? <ClientForm clientId={activeClient.id} onDismissed={_onSurfaceDismissed} /> : null}
    </Stack>;
};

export default ClientsList;
