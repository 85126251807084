import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../services/general/error';
import DeliveryTypeService from '../../../../services/orders/deliveryType';

// props
import { IDeliveryTypeResourceShortProps } from '../../../../props/deliveryType';

// components
import { Dropdown, SpinnerSize, Stack } from '@fluentui/react';
import LoadingComponent from '../../../feedbacks/loading';
import Label from '../../../typography/label';

type SelectDeliveryTypeProps = {
    selected?: IDeliveryTypeResourceShortProps;
    options?: IDeliveryTypeResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IDeliveryTypeResourceShortProps): void;
}

const SelectDeliveryType: React.FC<SelectDeliveryTypeProps> = (props: SelectDeliveryTypeProps) => {
    const { banner } = useStore();
    const [options, setOptions] = React.useState<IDeliveryTypeResourceShortProps[]>(props.options || []);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<IDeliveryTypeResourceShortProps | undefined>();

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            if (!props.options) {
                const _deliveryTypes = await DeliveryTypeService.retrieve();
                setOptions(_deliveryTypes);
            }

            setLoaded(true);
        } catch(e) {
            banner.add({
                key: 'retrieve_delivery_types_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to request offer. Error: ${ErrorService.getMessage(e)}`
            });
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{padding: '3px 0px'}} size={'xsmall'} required={props.required}>{props.label || "Delivery type"}</Label>}
        {!loaded ? <LoadingComponent size={SpinnerSize.xSmall} label={'Retrieving ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
        {loaded ? <Dropdown options={options.map((opt) => {
                return { key: opt.id, text: opt.name, metadata: opt };
            })}
            selectedKey={selected?.id}
            disabled={props.disabled}
            onChange={(evt, opt: any) => {
                const selected = opt ? opt.metadata : undefined;
                props.onChange(selected);
            }}
        /> : null}
    </Stack>;
};

export default SelectDeliveryType;
