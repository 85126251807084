import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, IContextualMenuItem, Persona, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import NotificationService from '../../../../services/notifications';
import { INotificationResourceShort } from '../../../../props/users/user';
import NotificationForm from '../form';


type TypeNotificationListProps = {
  children?: React.ReactNode;
};

const NotificationList: React.FC<TypeNotificationListProps> = (props) => {
  const [notifications, setNotifications] = useState<INotificationResourceShort[]>()
  const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

  useEffect(() => {
    notificationController()
    return () => { }
  }, [])


  const notificationController = async () => {
    try {
      const items = await NotificationService.retrieve()
      setNotifications(items)
    } catch (error) {

    }
  }


  const getCommandBarItems = () => {
    let items: ICommandBarItemProps[] = [];
    let farItems: ICommandBarItemProps[] = [];


    farItems.push({
      key: "register",
      text: "Create Ship",
      iconProps: { iconName: "Add" },
      onRender: () => {
        return <PrimaryButton text={"Create Notification"}
          iconProps={{ iconName: "Add" }}
          onClick={() => { setActiveSurface('create') }}
          styles={{ root: { marginLeft: 10 } }} />;
      }
    });

    return { items, farItems };
  }

  const _onSurfaceDismissed = () => {

  }

  return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
    <CommandBar
      items={getCommandBarItems().items}
      farItems={getCommandBarItems().farItems}
      styles={{
        root: {
          padding: 0,
          height: 'unset',
          backgroundColor: 'transparent'
        }
      }}
      ariaLabel="Use left and right arrow keys to navigate between commands" />
    {activeSurface === 'create' ? <NotificationForm onDismissed={_onSurfaceDismissed} /> : null}
  </Stack>
};

export default NotificationList;