import React from 'react';
import { useStore } from '../../../../../stores/root';

// assets

// services
import GeneralService from '../../../../../services/general';

// props
import { IOrderTruckResourceProps } from '../../../../../props/orders/trucks';

// components
import { Stack } from '@fluentui/react';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';
import OrderTruckGoodsList from '../../goods/list';

interface IOrderTruckDetailsPropertiesProps {
    truck: IOrderTruckResourceProps;
}

const OrderTruckDetailsProperties: React.FC<IOrderTruckDetailsPropertiesProps> = (props: IOrderTruckDetailsPropertiesProps) => {
    const {truck} = props;
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    return <Stack tokens={{ childrenGap: 15 }} styles={{root: {marginTop: 10}}}>
        <Stack horizontal tokens={{childrenGap: 20}}>
            <Stack.Item styles={{root: {width: '25%'}}}>
                <Label>Name</Label>
                <Text>{truck.name || "-"}</Text>
            </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{childrenGap: 20}}>
            <Stack.Item styles={{root: {width: '50%'}}}>
                <Label>Pick-up Address</Label>
                <Text>{truck.originAddress || "-"}</Text>
            </Stack.Item>
            <Stack.Item styles={{root: {width: '50%'}}}>
                <Label>Drop-off Address</Label>
                <Text>{truck.destinationAddress || "-"}</Text>
            </Stack.Item>
        </Stack>
        <Stack horizontal>
            <Stack styles={{root: {width: '25%'}}}>
                <Label>Subtotal</Label>
                <Stack>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(truck.price))}</Text>
                    <Text size={'small'}>({truck.price && truck.price !== 0 ? GeneralService.convertNumberToWords(Number(truck.price)) : ""})</Text>
                </Stack>
            </Stack>
            <Stack styles={{root: {width: '25%'}}}>
                <Label>PPN</Label>
                <Stack>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(truck.ppn))}</Text>
                    <Text size={'small'}>({truck.ppn && truck.ppn !== 0 ? GeneralService.convertNumberToWords(Number(truck.ppn)) : ""})</Text>
                </Stack>
            </Stack>
            <Stack styles={{root: {width: '25%'}}}>
                <Label>PPh</Label>
                <Stack>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(truck.pph))}</Text>
                    <Text size={'small'}>({truck.pph && truck.pph !== 0 ? GeneralService.convertNumberToWords(Number(truck.pph)) : ""})</Text>
                </Stack>
            </Stack>
            <Stack styles={{root: {width: '25%'}}}>
                <Label>Total Price</Label>
                <Stack>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(Number(truck.totalPrice))}</Text>
                    <Text size={'small'}>({truck.totalPrice && truck.totalPrice !== 0 ? GeneralService.convertNumberToWords(Number(truck.totalPrice)) : ""})</Text>
                </Stack>
            </Stack>
        </Stack>
        <Stack className='divider'></Stack>
        <Stack>
            <Label>Goods List</Label>
            <OrderTruckGoodsList orderId={props.truck.orderId} truckId={props.truck.id} goods={props.truck.goods} />
        </Stack>
    </Stack>
};

export default OrderTruckDetailsProperties;
