import React, { useEffect } from 'react';
import { useStore } from '../../../../../../../stores/root';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { Checkbox, Stack, TextField } from '@fluentui/react';
import Text from '../../../../../../typography/text';
import Label from '../../../../../../typography/label';
import { TSelectedFilterKey } from '.';
import { IOutcomeResourceShorterProps } from '../../../../../../../props/finance/outcomes';
import { IInternalBankAccountResourceShortProps } from '../../../../../../../props/data/bankaccounts/internal';

type OutcomeInvoicesSummaryFilterTransferFromProps = {
    invoices: IOutcomeResourceShorterProps[];
    hideSearch?: boolean;
    onChange(key: TSelectedFilterKey, selected: IInternalBankAccountResourceShortProps[]): void;
}

const OutcomeInvoicesSummaryFilterTransferFrom: React.FC<OutcomeInvoicesSummaryFilterTransferFromProps> = (props: OutcomeInvoicesSummaryFilterTransferFromProps) => {
    const { banner } = useStore();
    const [keyword, setKeyword] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IInternalBankAccountResourceShortProps[]>([]);

    useEffect(() => {

    }, [props.invoices]);

    const getUniqueVendors = (): IInternalBankAccountResourceShortProps[] => {
        const accounts: IInternalBankAccountResourceShortProps[] = [];

        props.invoices.forEach((invoice) => {
            const exist = accounts.find((c) => c.id === invoice.bankAccount?.id);
            if (!exist && invoice.bankAccount) {
                accounts.push(invoice.bankAccount);
            }
        });

        return accounts;
    }

    const renderOption = (account: IInternalBankAccountResourceShortProps) => {
        const isChecked = selected.findIndex((s) => s.id === account.id) > -1;

        return <Checkbox onChange={(ev, checked) => onChange(!checked, account)} checked={isChecked} onRenderLabel={() => {
            return <Stack styles={{root: {marginTop: 3, marginLeft: 5}}}>
                <Text size={'small'}>{account.accountName} ({account.accountNumber})</Text>
            </Stack>
        }} />
    }

    const onChange = (checked: boolean | undefined, account: IInternalBankAccountResourceShortProps) => {
        let _selected = selected;
        if (checked) {
            _selected = _selected.filter((s) => s.id !== account.id);
        } else {
            _selected.push(account);
        }

        setSelected([..._selected]);
        props.onChange('transferFrom', _selected);
    }

    return <Stack className={styles.container} tokens={{childrenGap: 10}}>
        <Label size={"small"}>Transfer From</Label>
        {props.hideSearch ? null : <TextField iconProps={{iconName: "Search"}}
            placeholder={'Search source account ...'}
            value={keyword}
            onChange={(ev, value) => setKeyword(value || "")} />}
        <Stack className={styles.options} tokens={{childrenGap: 8}}>
            {getUniqueVendors().filter((c) => c.accountNumber.toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((ba) => {
                return renderOption(ba);
            })}
        </Stack>
    </Stack>;
};

export default OutcomeInvoicesSummaryFilterTransferFrom;
