import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../../stores/root';

// assets
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../../services/general';
import ErrorService from '../../../../../../../services/general/error';
import IncomeInvoiceService from '../../../../../../../services/finance/incomes/invoices/invoice';

// props
import { IIncomePaymentResourceShortProps } from '../../../../../../../props/finance/incomes/payments/payment';

// components
import { ActionButton, CommandBar, IColumn, ICommandBarItemProps, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../../typography/text';
import PaymentMethods from '../../../../../../../manifests/paymentMethods';
import PermissionsService from '../../../../../../../services/permissions';
import { observer } from 'mobx-react';
import IncomeInvoicePaymentService from '../../../../../../../services/finance/incomes/invoices/payment';
import Tag, { TTagVariant } from '../../../../../../uiframeworks/tag';
import IncomeInvoicePaymentDetails from '../details';

interface IOrderInvoicePaymentsListProps {
    invoiceId?: string;
    qs?: string[];
    payments?: IIncomePaymentResourceShortProps[];
    columns?: TOrderInvoicePaymentColumn[];
    hideSearch?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    variant?: 'card' | 'plain';
}

export type TOrderInvoicePaymentColumn = 'proof' | 'method' | 'paymentDate' | 'notes' | 'amount' | 'actions' | 'status';

const IncomeInvoicePaymentList: React.FC<IOrderInvoicePaymentsListProps> = observer((props: IOrderInvoicePaymentsListProps) => {
    const shownColumns: TOrderInvoicePaymentColumn[] = props.columns || [
        'proof',
        'paymentDate',
        'actions',
        'method',
        'amount',
        'notes',
        'status'
    ];

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [payments, setPayments] = React.useState<IIncomePaymentResourceShortProps[]>(props.payments || []);
    const [activePayment, setActivePayment] = React.useState<IIncomePaymentResourceShortProps | undefined>();
    const [keyword, setKeyword] = React.useState<string>("");
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const columns: IColumn[] = [
        {
            key: "paymentDate",
            name: "Date",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Link onClick={() => {
                        setActivePayment(item);
                        setActiveSurface('view.details');
                    }}><Text>{moment(item.paymentDate).format("DD/MM/YYYY")}</Text></Link>
                </Stack>
            }
        },
        {
            key: "method",
            name: "Method",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                const method = PaymentMethods.find((pm) => pm.key === item.method)
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>{method?.text || 'Cash'}</Text>
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {(item.notes || "").trim() !== "" ? <Text>{item.notes}</Text> : null}
                    <Text size={'small'} style={{fontStyle: 'italic'}}>Recorded by: {item.recordedBy.name} on {moment(item.recordedAt).format("DD/MM/YYYY")}</Text>
                    {item.verifiedBy && item.verifiedAt ? <Text className={'color-green'} size={'small'} style={{fontStyle: 'italic'}}>Verified by: {item.verifiedBy.name} on {moment(item.verifiedAt).format("DD/MM/YYYY")}</Text> : null}
                    {item.rejectedBy && item.rejectedAt ? <Text className={'color-red'} size={'small'} style={{fontStyle: 'italic'}}>Rejected by: {item.rejectedBy.name} on {moment(item.rejectedAt).format("DD/MM/YYYY")}</Text> : null}
                </Stack>
            }
        },
        {
            key: "amount",
            name: "Amount",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text>Rp. {GeneralService.getNumberWithSeparator(item.amount)}</Text>
                </Stack>
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                let tagVariant: TTagVariant = 'success';
                let tagText: string = 'Verified';

                if (item.status === 'Pending') {
                    tagVariant = 'warning';
                    tagText = 'Pending Verification'
                } else if (item.status === 'Rejected') {
                    tagVariant = 'error';
                }

                return <Stack horizontal>
                    <Tag variant={tagVariant} text={tagText} />
                </Stack>
            }
        }/*,
        {
            key: "actions",
            name: "",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IIncomePaymentResourceShortProps) => {
                const hasDeletePermission = PermissionsService.hasPermission(['superuser', 'admin', 'finance'], user.roles);

                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    <Stack.Item className={"detailsListActionRow"}>
                        {hasDeletePermission ? <ActionButton className={'detailsListActionButton'} iconProps={{iconName: "Delete"}} onClick={() => {
                            setActivePayment(item);
                            setActiveSurface('delete');
                        }} /> : null}
                    </Stack.Item>
                </Stack.Item>;
            }
        }*/
    ];

    useEffect(() => {
        if (!props.payments) {
            _onRetrievePayments();
        } else {
            setLoaded(true);
        }
    }, []);

    const _onRetrievePayments = async (pageNumber?: number,) => {
        try {
            if (!pageNumber) {
                setLoaded(false);
            }

            const qs: string[] = props.qs || [];
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }

            const result = await IncomeInvoicePaymentService.retrieve(qs.join(""));
            setPayments(result.data);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_payments_error',
                text: 'Failed to retrieve payments list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setLoaded(true);
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} />
                }
            });
        }

        if (!props.hideCreateButton) {
            farItems.push({
                key: "register",
                text: "Create Payment",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Create Payment"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('create') }}
                        styles={{ root: { marginLeft: 10 } }} />;
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setActivePayment(undefined);

        if (refresh) { _onRetrievePayments() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {!props.hideCommandBar ? <CommandBar
            items={getCommandBarItems().items}
            farItems={getCommandBarItems().farItems}
            styles={{
                root: {
                    padding: 0,
                    height: 'unset',
                    backgroundColor: 'transparent'
                }
            }}
            ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
        <Stack className={styles[props.variant || 'card']}>
            {
                !loaded || (loaded && payments.length > 0) ? (
                    <>
                        <ShimmeredDetailsList
                            setKey="items"
                            items={payments.filter((payment) => {
                                const search = (keyword || "").trim().toLowerCase();
                                return (payment.method.toLowerCase()).indexOf(search) > -1 || (payment.notes || "").toLowerCase().indexOf(search) > -1
                            })}
                            columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderInvoicePaymentColumn) > -1)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!loaded}
                            onShouldVirtualize={() => false}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details" />
                    </>
                ) : null
            }
            {loaded && payments.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                <Text size={'small'} style={{fontStyle: 'italic'}}>Payments not found</Text>
            </Stack> : null}
        </Stack>
        {activeSurface === 'view.details' && activePayment ? <IncomeInvoicePaymentDetails paymentId={activePayment.id} onDismissed={_onSurfaceDismissed} /> : null}
        {/*activeSurface === 'delete' && activePayment ? <DeleteOrderInvoicePaymentForm payment={activePayment} onDismissed={_onSurfaceDismissed} /> : null*/}
    </Stack>;
});

export default IncomeInvoicePaymentList;
