import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import OrderContainerService from '../../../../../services/orders/orderContainer';
import GeneralService from '../../../../../services/general';

// props

// components
import { Checkbox, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import { PPNPercentageOptions, PPhPercentageOptions } from '../../../../../manifests/taxPercentage';
import Label from '../../../../typography/label';
import Text from '../../../../typography/text';

interface IOrderContainerFormProps {
    orderId: string;
    containerId?: string;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    containerNumber: string;
    size: string;
    sealNumber: string;
    roNumber: string;
    price: string;
    includePPN?: boolean;
    includePPh?: boolean;
    ppn: string;
    ppnPercentage: string;
    pph: string;
    pphPercentage: string;
    finalPrice: string;
    nettPrice: string;
    invoicePrice: string;
}

type FormDataErrorProps = {
    containerNumber?: string;
    size?: string;
    sealNumber?: string;
    roNumber?: string;
    price?: string;
    ppn?: string;
    pph?: string;
}

const OrderContainerForm: React.FC<IOrderContainerFormProps> = (props: IOrderContainerFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        containerNumber: "",
        size: "",
        sealNumber: "",
        roNumber: "",
        price: "",
        ppn: "0",
        pph: "0",
        ppnPercentage: "0",
        pphPercentage: "0",
        finalPrice: "0",
        nettPrice: "0",
        invoicePrice: "0"
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.containerId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.containerId) {
            const _container = await OrderContainerService.get(props.orderId, props.containerId);

            setData({
                containerNumber: _container.containerNumber || "",
                size: _container.size || "",
                sealNumber: _container.sealNumber || "",
                roNumber: _container.roNumber || "",
                price: _container.price || "",
                ppn: Number(_container.ppn) + "",
                pph: Number(_container.pph) + "",
                ppnPercentage: Number(_container.ppnPercentage) + "",
                pphPercentage: Number(_container.pphPercentage) + "",
                finalPrice: _container.totalPrice + "",
                nettPrice: _container.nettPrice + "",
                invoicePrice: _container.invoicePrice + "",
                includePPN: _container.includePPN,
                includePPh: _container.includePPh
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.containerNumber || error.size || error.sealNumber || error.roNumber || error.price) {
            return true;
        } else if (data.containerNumber === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { containerNumber, price, roNumber, sealNumber, size, ppn, pph, includePPN, includePPh, ppnPercentage, pphPercentage, finalPrice, nettPrice, invoicePrice } = data;

            // create form data
            let fd = new FormData();
            fd.append("containerNumber", containerNumber);
            fd.append("roNumber", roNumber);
            fd.append("size", size);
            fd.append("sealNumber", sealNumber);
            fd.append("totalPrice", finalPrice);
            fd.append("finalPrice", finalPrice);
            fd.append("nettPrice", nettPrice);
            fd.append("invoicePrice", invoicePrice);
            fd.append("price", price);
            fd.append("ppn", ppn);
            fd.append("pph", pph);
            fd.append("includePPN", includePPN + "");
            fd.append("includePPh", includePPh + "");
            fd.append("ppnPercentage", ppnPercentage + "");
            fd.append("pphPercentage", pphPercentage + "");

            if (props.containerId === undefined) {
                await OrderContainerService.new(props.orderId, fd);
            } else {
                await OrderContainerService.update(props.orderId, props.containerId, fd);
            }

            banner.add({
                key: mode + '_order_container_success',
                variant: 'success',
                icon: faCheck,
                text: `Container "${containerNumber}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    const calculateAllPrice = (_data: FormDataProps) => {
        let originalPrice = "0";
        
        if (_data.includePPN) {
            if (_data.ppnPercentage && data.ppnPercentage !== 'custom') {
                originalPrice = (Number(_data.price) * 100 / (100 + Number(_data.ppnPercentage))).toFixed();
            } else {
                originalPrice = (Number(_data.price) - Number(_data.ppn)).toFixed();
            }
        } else if (!_data.includePPN) {
            originalPrice = _data.price;
        }

        let ppn = "0";
        if (_data.includePPN && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(originalPrice) * (Number(_data.ppnPercentage) / 100)).toFixed();
        } else if (!_data.includePPN && _data.ppnPercentage && data.ppnPercentage !== 'custom') {
            ppn = (Number(_data.price) * (Number(_data.ppnPercentage) / 100)).toFixed();
        }
        _data.ppn = ppn;

        let pph = "0";
        if (_data.pphPercentage && data.pphPercentage !== 'custom') {
            pph = (Number(originalPrice) * (Number(_data.pphPercentage) / 100)).toFixed();
        }
        _data.pph = pph;

        let finalPrice = 0;
        if (_data.includePPN && _data.includePPh) {
            finalPrice = Number(_data.price);
        } else if (_data.includePPN && !_data.includePPh) {
            finalPrice = Number(_data.price) + Number(_data.pph);
        } else if (!_data.includePPN && _data.includePPh) {
            finalPrice = Number(_data.price) + Number(_data.ppn);
        } else  {
            finalPrice = Number(_data.price) + Number(_data.ppn) + Number(_data.pph);
        }
        _data.finalPrice = finalPrice + "";

        let nettPrice = finalPrice;
        let invoicePrice = finalPrice;
        if (_data.includePPN) {
            nettPrice = nettPrice - Number(_data.ppn);
        }
        if (_data.includePPh) {
            nettPrice = nettPrice - Number(_data.pph);
        }
        _data.invoicePrice = invoicePrice + "";

        setData({ ...data });
    }

    return <Panel headerText={mode === 'create' ? 'Create Container' : 'Update Container'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating container ..." : "Updating container ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <TextField label={"Container Number"}
                                required={true}
                                value={data.containerNumber}
                                onChange={(evt, value) => {
                                    data.containerNumber = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.containerNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.containerNumber}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <Dropdown label={"Size"}
                                selectedKey={data.size}
                                options={[
                                    { key: '20FT', text: '20FT' },
                                    { key: '40FT', text: '40FT' },
                                    { key: '20FT HC', text: '20FT HC' },
                                    { key: '40FT HC', text: '40FT HC' }
                                ]}
                                onChange={(evt, opt) => {
                                    data.size = opt ? opt.key as string : "";
                                    setData({ ...data });
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <TextField label={"Seal Number"}
                                value={data.sealNumber}
                                onChange={(evt, value) => {
                                    data.sealNumber = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.sealNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.sealNumber}
                                disabled={submitting} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '50%' } }}>
                            <TextField label={"RO Number"}
                                value={data.roNumber}
                                onChange={(evt, value) => {
                                    data.roNumber = value || "";

                                    const validation = ValidationService.combination(value, ['limit'], { maxChars: 100 });
                                    error.roNumber = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }}
                                errorMessage={error.roNumber}
                                disabled={submitting} />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <TextField label={"Price"}
                            value={data.price}
                            onChange={(evt, value) => {
                                if (!isNaN(Number(value))) {
                                    data.price = value || "";

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                    error.price = validation.message;

                                    setError({ ...error });

                                    calculateAllPrice(data);
                                }
                            }}
                            prefix={"Rp"}
                            description={data.price !== "" ? GeneralService.convertNumberToWords(Number(data.price)) : undefined}
                            errorMessage={error.price}
                            disabled={submitting}
                            required={true} />
                    </Stack.Item>
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <Stack.Item>
                            <Checkbox checked={data.includePPN}
                                label='Include PPN'
                                onChange={(ev, checked) => {
                                    data.includePPN = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox checked={data.includePPh}
                                label='Include PPh'
                                onChange={(ev, checked) => {
                                    data.includePPh = checked;
                                    setData({ ...data });
                                    calculateAllPrice(data);
                                }} />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPN</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPNPercentageOptions}
                                    selectedKey={data.ppnPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.ppn = (Number(opt.key) / 100 * Number(data.price)).toFixed();
                                        }
                                        data.ppnPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.ppn}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.ppn = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.ppn = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.ppn}
                                    disabled={submitting || data.ppnPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Label size={'small'}>PPh</Label>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item>
                                <Dropdown options={PPhPercentageOptions}
                                    selectedKey={data.pphPercentage}
                                    onChange={(evt, opt) => {
                                        if (opt && opt.key !== 'custom') {
                                            data.pph = (Number(opt.key) / 100 * Number(data.price)).toFixed();
                                        }
                                        data.pphPercentage = (opt?.key || "") as string;

                                        setData({ ...data });
                                        calculateAllPrice(data);
                                    }}
                                    styles={{ root: { width: 100 } }} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField required={true}
                                    value={data.pph}
                                    onChange={(evt, value) => {
                                        if (!isNaN(Number(value))) {
                                            data.pph = value || "";

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 15 });
                                            error.pph = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                            calculateAllPrice(data);
                                        }
                                    }}
                                    prefix={"Rp"}
                                    errorMessage={error.pph}
                                    disabled={submitting || data.pphPercentage !== 'custom'} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack.Item>
                        <Label size={'small'}>Final Price</Label>
                        <Text>Rp. {GeneralService.getNumberWithSeparator(data.finalPrice)}</Text>
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default OrderContainerForm;
