import React, { useEffect } from 'react';

import { Dropdown, Stack } from '@fluentui/react';
import { useStore } from '../../../../../../stores/root';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import { IFreightCategoryResourceShortProps } from '../../../../../../props/freightCategories';
import { IDeliveryTypeResourceShortProps } from '../../../../../../props/deliveryType';
import FreightCategoryService from '../../../../../../services/orders/freightCategory';
import DeliveryTypeService from '../../../../../../services/orders/deliveryType';
import DeliveredGoodsType from '../../../../../../manifests/deliveredGoodsType';
import LoadingComponent from '../../../../../feedbacks/loading';
import Text from '../../../../../typography/text';
import Label from '../../../../../typography/label';

type OfferFreightDetailsProps = {
    data?: FreightDetailsFormData;
    submitting?: boolean;
    mode?: string;
    onChange(data?: FreightDetailsFormData, error?: FreightDetailsFormDataError): void;
}

export type FreightDetailsFormData = {
    freightCategory?: IFreightCategoryResourceShortProps;
    deliveryType?: IDeliveryTypeResourceShortProps;
    goodsType?: string;
}

export type FreightDetailsFormDataError = {
    freightCategory?: string;
    deliveryType?: string;
    goodsType?: string;
}

const OfferFreightDetails: React.FC<OfferFreightDetailsProps> = (props: OfferFreightDetailsProps) => {
    const { banner } = useStore();
    const [error, setError] = React.useState<FreightDetailsFormDataError>({});
    const [data, setData] = React.useState<FreightDetailsFormData | undefined>(props.data);
    const [freightCategories, setFreightCategories] = React.useState<IFreightCategoryResourceShortProps[]>([]);
    const [deliveryTypes, setDeliveryTypes] = React.useState<IDeliveryTypeResourceShortProps[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setData({ ...props.data });
    }, [props.data]);

    const init = async () => {
        await _onRetrieveFreightCategories();
        await _onRetrieveDeliveryTypes();
        setLoaded(true);
    }

    const _onRetrieveFreightCategories = async () => {
        try {
            const _freightCategories = await FreightCategoryService.retrieve();
            setFreightCategories(_freightCategories);
        } catch (e) {
            banner.add({
                key: "retrieve_freight_categories_failed",
                text: "Failed to retrieve freight categories. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    const _onRetrieveDeliveryTypes = async () => {
        try {
            const _deliveryTypes = await DeliveryTypeService.retrieve();
            setDeliveryTypes(_deliveryTypes);
        } catch (e) {
            banner.add({
                key: "retrieve_delivery_types_failed",
                text: "Failed to retrieve delivery types. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });
        }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Working on it ...' labelPosition='right' spinnerPosition={'baseline'} /> : null}
        {loaded ? <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: '33.3%' } }}>
                {props.mode === 'view' ? <Stack>
                    <Text size={'small'}>Freight Category</Text>
                    <Label size={'small'}>{data?.freightCategory?.name}</Label>
                </Stack> : null}
                {props.mode !== 'view' ? <Dropdown label='Freight Category'
                    required
                    options={freightCategories.map((opt) => {
                        return { key: opt.id, text: opt.name, metadata: opt };
                    })}
                    selectedKey={data?.freightCategory?.id}
                    disabled={props.submitting}
                    onChange={(evt, opt: any) => {
                        let _data = data || {};
                        _data.freightCategory = opt ? opt.metadata : "";
                        props.onChange(_data);
                    }}
                /> : null}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '33.3%' } }}>
                {props.mode === 'view' ? <Stack>
                    <Text size={'small'}>Delivery Type</Text>
                    <Label size={'small'}>{data?.deliveryType?.name}</Label>
                </Stack> : null}
                {props.mode !== 'view' ? <Dropdown label='Delivery Type'
                    required
                    options={deliveryTypes.map((opt) => {
                        return { key: opt.id, text: opt.name, metadata: opt };
                    })}
                    selectedKey={data?.deliveryType?.id}
                    disabled={props.submitting}
                    onChange={(evt, opt: any) => {
                        let _data = data || {};
                        _data.deliveryType = opt ? opt.metadata : undefined;
                        props.onChange(_data);
                    }}
                /> : null}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: '33.3%' } }}>
                {props.mode === 'view' ? <Stack>
                    <Text size={'small'}>Goods Type</Text>
                    <Label size={'small'}>{DeliveredGoodsType.find((dg) => dg.key === data?.goodsType)?.text}</Label>
                </Stack> : null}
                {props.mode !== 'view' ? <Dropdown label='Goods Type'
                    required
                    options={DeliveredGoodsType.filter((dgt) => {
                        const deliveryType = deliveryTypes.find((dt) => dt.id === data?.deliveryType?.id);
                        if ((deliveryType?.key || "").toLowerCase() === 'shipping') {
                            return dgt.key !== 'ftl' && dgt.key !== 'ltl';
                        } else if ((deliveryType?.key || "").toLowerCase() === 'trucking') {
                            return dgt.key === 'ftl' || dgt.key === 'ltl';
                        } else {
                            return true;
                        }
                    })}
                    selectedKey={data?.goodsType}
                    disabled={props.submitting}
                    onChange={(evt, opt) => {
                        let _data = data || {};
                        _data.goodsType = opt ? opt.key as string : "";
                        props.onChange(_data);
                    }}
                /> : null}
            </Stack.Item>
        </Stack> : null}
    </Stack>;
}

export default OfferFreightDetails;

