import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import WarehouseService from '../../../../../services/warehouses';

// props
import { IWarehouseResourceShortProps } from '../../../../../props/warehouses/warehouse';

// components
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';

interface IWarehouseFormProps {
    warehouse?: IWarehouseResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
    name: string;
    address: string;
}

type FormDataErrorProps = {
    name?: string;
    address?: string;
}

const WarehouseForm: React.FC<IWarehouseFormProps> = (props: IWarehouseFormProps) => {
    const { banner } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<FormDataProps>({
        name: "",
        address: ""
    });
    const [error, setError] = React.useState<FormDataErrorProps>({});
    const mode: 'create' | 'update' = props.warehouse === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.warehouse) {
            setData({
                name: props.warehouse.name,
                address: props.warehouse.address || ""
            });
        }

        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (error.name || error.address) {
            return true;
        } else if (data.name === '') {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { name, address } = data;

            // create form data
            let fd = new FormData();
            fd.append("name", name);
            fd.append("address", address);

            if (props.warehouse === undefined) {
                await WarehouseService.create(fd);
            } else {
                await WarehouseService.update(props.warehouse.id, fd);
            }
            banner.add({
                key: 'form_warehouse_success',
                variant: 'success',
                icon: faCheck,
                text: `Warehouse "${name}" ${mode === 'create' ? 'created' : 'updated'} successfully`
            });
            props.onDismissed(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Panel headerText={mode === 'create' ? 'Create Warehouse' : 'Update Warehouse'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating warehouse ..." : "Updating warehouse ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 20 } }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack.Item>
                        <TextField label={"Name"}
                            required={true}
                            value={data.name}
                            onChange={(evt, value) => {
                                data.name = value || "";
                                
                                const validation = ValidationService.combination(value, ['required', 'limit'], {maxChars: 100});
                                error.name = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label={"Address"}
                            multiline
                            rows={5}
                            autoAdjustHeight
                            resizable={false}
                            value={data.address}
                            onChange={(evt, value) => {
                                data.address = value || "";
                                
                                const validation = ValidationService.combination(value, ['limit'], {maxChars: 100});
                                error.address = validation.message;

                                setData({...data});
                                setError({...error});
                            }}
                            errorMessage={error.name}
                            disabled={submitting} />
                    </Stack.Item>
                </> : null
            }
        </Stack>
    </Panel>
};

export default WarehouseForm;
